import s from 'underscore.string';
import { apiUrl } from './config';

function prefixWithOLSlug(url, org) {
  const _org = sessionStorage.getItem('org');
  const _loc = sessionStorage.getItem('loc');

  if (org) {
    return s.sprintf('/organisations/%s%s', _org, url);
  }

  return s.sprintf('/locations/%s/%s%s', _org, _loc, url);
}


function shouldPrefixUrl(url) {
  const excludedUrls = [
    '/pwreset/',
    '/account/',
    '/auth/user-session',
    '/auth/locations/',
    '/oauth/token',
    '/powertools'
  ];
  for (const index in excludedUrls) {
    if (url.startsWith(excludedUrls[index])) {
      return false;
    }
  }
  return true;
}

const AjaxUrlSetUp = {
  initialize(url) {
    this.url = url;
  },
  getUrl() {
    return this.url;
  }
};

function getApiUrl() {
  return AjaxUrlSetUp.getUrl();
}

export function initApiUrl(url) {
  AjaxUrlSetUp.initialize(url);
}

export function prefixAjaxUrl(url, org) {
  if (shouldPrefixUrl(url)) {
    url = prefixWithOLSlug(url, org);
  }
  return s.sprintf('%s/api%s', getApiUrl(), url);
}
