import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPaymentPreferences, savePaymentPreferences } from '../../../state/preferences-actions';
import PaymentConfigForm from './payment-config-form';

class PaymentConfig extends Component {
  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getPaymentConfig();
    }
  }

  render() {
    const initialValues = this.props.preferences ? this.props.preferences.toJS() : null;

    return (
      <PaymentConfigForm
        initialValues={initialValues}
        onSubmit={this.submitForm}
      />
    );
  }

  submitForm = (values) => {
    return this.props.savePaymentConfig(values);
  };
}

PaymentConfig.propTypes = {
  preferences: PropTypes.object
};

const mapStateToProps = (state) => {
  const { preferencesViewState } = state;

  return {
    preferences: preferencesViewState.get('paymentPreferences')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentConfig: () => {
      dispatch(getPaymentPreferences());
    },
    savePaymentConfig: (data) => {
      return dispatch(savePaymentPreferences(data));
    }
  };
};

const PaymentConfigContainer = connect(
   mapStateToProps,
   mapDispatchToProps
)(PaymentConfig);

export default PaymentConfigContainer;
