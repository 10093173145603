import * as R from 'ramda';
import moment from 'moment/moment';
import {
  fetchGet, fetchPut, fetchPatch, fetchDelete, checkStatus,
  prefixUrl, prefixOrgUrl, fetchErrorHandler
} from '../utils/ajax-util';
import { loading, loadingDone } from './network-actions';
import { extractSlotFinderParams } from '../utils/web-booking';
import * as PhoneUtil from '../../utils/phone-util';

export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES';
export const CHANGE_CONTACT_INFO = 'CHANGE_CONTACT_INFO';
export const RESET_RESOURCE_PREFERENCES = 'RESET_RESOURCE_PREFERENCES';


function generalPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      generalPreferences: data
    }
  };
}

function webBookingPreferencesSuccess(data) {
  data.cancelUpToHours = data.cancelUpToMinutes / 60;
  extractSlotFinderParams(data, data.slotFinderParams);

  return {
    type: CHANGE_PREFERENCES,
    state: {
      webBookingPreferences: data
    }
  };
}

function smsPreferencesSuccess(data) {
  if (PhoneUtil.isMobile(data.sender)) {
    data.sender = PhoneUtil.formatPhoneNumber(data.sender);
  }

  return {
    type: CHANGE_PREFERENCES,
    state: {
      smsPreferences: data
    }
  };
}

function emailPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      emailPreferences: data
    }
  };
}

function resourcePreferencesSuccess(data, id) {
  extractSlotFinderParams(data, data.preferences.webSlotFinderParams);

  return {
    type: CHANGE_PREFERENCES,
    resourceId: id,
    state: {
      resourcePreferences: data
    }
  };
}

function resetResourcePreferences() {
  return {
    type: RESET_RESOURCE_PREFERENCES
  };
}

function storagePreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      storagePreferences: data
    }
  };
}

function paymentPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      paymentPreferences: data
    }
  };
}

function companyInfoFetched(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      companyInfo: R.omit(['contacts'], data),
      contacts: data.contacts
    }
  };
}

function companyInfoSaved(orgLoc, data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      orgLoc,
      companyInfo: data
    }
  };
}

function contactInfoSaved(contactType, data) {
  return {
    type: CHANGE_CONTACT_INFO,
    contactType,
    contact: data
  };
}

function jsonPreferencesSuccess(data) {
  return (dispatch, getState) => {
    const { preferencesViewState } = getState();
    const jsonPreferences = preferencesViewState.get('jsonPreferences');

    dispatch({
      type: CHANGE_PREFERENCES,
      state: {
        jsonPreferences: jsonPreferences ? jsonPreferences.merge(data).toJS() : data
      }
    });
  };
}

export function getJsonPreferences() {
  const url = prefixUrl('/preferences/');
  return loadPreferences(url, jsonPreferencesSuccess);
}

export function getGeneralPreferences() {
  const url = prefixUrl('/preferences/general');
  return loadPreferences(url, generalPreferencesSuccess);
}

export function saveGeneralPreferences(data) {
  const url = prefixUrl('/preferences/general');
  return savePreferences(url, data, generalPreferencesSuccess);
}

export function getWebBookingPreferences() {
  const url = prefixUrl('/preferences/web-booking');
  return loadPreferences(url, webBookingPreferencesSuccess);
}

export function saveWebBookingPreferences(data) {
  const url = prefixUrl('/preferences/web-booking');
  return savePreferences(url, data, webBookingPreferencesSuccess);
}

export function getSmsPreferences() {
  const url = prefixUrl('/preferences/sms');
  return loadPreferences(url, smsPreferencesSuccess);
}

export function saveSmsPreferences(data) {
  const url = prefixUrl('/preferences/sms');

  if (PhoneUtil.isMobile(data.sender)) {
    data.sender = PhoneUtil.formatPhoneNumberE164(data.sender);
  }

  return savePreferences(url, data, smsPreferencesSuccess);
}

export function getEmailPreferences() {
  const url = prefixUrl('/preferences/email');
  return loadPreferences(url, emailPreferencesSuccess);
}

export function saveEmailPreferences(data) {
  const url = prefixUrl('/preferences/email');
  return savePreferences(url, data, emailPreferencesSuccess);
}

export function getResourcePreferences(resourceId) {
  return (dispatch) => {
    dispatch(resetResourcePreferences());
    const url = prefixUrl(`/resources/${resourceId}/preferences`);
    return dispatch(loadPreferences(url, resourcePreferencesSuccess));
  };
}

export function saveResourcePreferences(resourceId, data) {
  const url = prefixUrl(`/resources/${resourceId}/preferences`);
  return savePreferences(url, data, resourcePreferencesSuccess, resourceId);
}

export function getStoragePreferences() {
  const url = prefixUrl('/preferences/storage');
  return loadPreferences(url, storagePreferencesSuccess);
}

export function saveStoragePreferences(data) {
  const preferences = {
    ...data,
    preferencesUpdated: moment().toISOString()
  };
  const url = prefixUrl('/preferences/storage');
  return savePreferences(url, preferences, storagePreferencesSuccess);
}

export function getPaymentPreferences() {
  const url = prefixUrl('/preferences/payment');
  return loadPreferences(url, paymentPreferencesSuccess);
}

export function savePaymentPreferences(data) {
  const url = prefixUrl('/preferences/payment');
  return savePreferences(url, data, paymentPreferencesSuccess);
}

function loadPreferences(url, callback) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(res => dispatch(callback(res)))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function savePreferences(url, data, callback, id) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchPut(data))
         .then(res => dispatch(checkStatus(res)))
         .then(res => dispatch(callback(data, id)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveJsonPreferences(data) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(prefixUrl('/preferences/'), fetchPatch(data))
         .then(res => dispatch(checkStatus(res)))
         .then(res => dispatch(jsonPreferencesSuccess(data)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCompanyInfo() {
  return (dispatch) => {
    dispatch(loading());
    return fetch(prefixOrgUrl('/company-info/'), fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(companyInfoFetched(res)))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveCompanyInfo(orgLoc, data) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(prefixOrgUrl('/company-info/'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(companyInfoSaved(orgLoc, data)))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveContactInfo(contactType, data) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(prefixOrgUrl(`/contacts/${contactType}`), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(contactInfoSaved(contactType, data)))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteContactInfo(contactType) {
  return (dispatch) => {
    dispatch(loading());
    return fetch(prefixOrgUrl(`/contacts/${contactType}`), fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(contactInfoSaved(contactType, null)))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
