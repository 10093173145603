import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ResourceEntry extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    resourceTargetUrlResolver: PropTypes.func.isRequired
  };

  render() {
    const { id, name } = this.props.resource;
    const { entityType, entityId } = this.props.routeParams;

    const itemClasses = (entityType === 'resource' && id === entityId) ? 'list-group-item resource-item active' : 'list-group-item resource-item';
    const linkTarget = this.props.resourceTargetUrlResolver(id);

    return (
      <li>
        <Link className={itemClasses} to={linkTarget}>
          <span className="name">{name}</span>
        </Link>
      </li>
    );
  }
}
