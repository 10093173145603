import { defineMessages } from 'react-intl';

export const msgHandlebars = defineMessages({
  fallback: {
    id: 'hbs.global.fallback',
    defaultMessage: 'ERROR: missed text id'
  },
  closed: {
    id: 'hbs.global.closed',
    defaultMessage: 'Stängt'
  },
  roleRights: {
    id: 'hbs.global.roleRights',
    defaultMessage: 'Roll / Rättigheter'
  },
  userResource: {
    id: 'hbs.global.userResource',
    defaultMessage: 'Användarens resurs'
  },
  addUsers: {
    id: 'hbs.global.addUsers',
    defaultMessage: 'Lägg till användare'
  },
  addSchedule: {
    id: 'hbs.global.addSchedule',
    defaultMessage: 'Lägg till schema'
  },
  customerInformation: {
    id: 'hbs.global.customerInformation',
    defaultMessage: 'Kunduppgifter'
  },
  address: {
    id: 'hbs.global.address',
    defaultMessage: 'Adress'
  },
  customerLog: {
    id: 'hbs.global.customerLog',
    defaultMessage: 'Kundlogg'
  },
  reservations: {
    id: 'hbs.global.reservations',
    defaultMessage: 'Bokningar'
  },
  name: {
    id: 'hbs.global.name',
    defaultMessage: 'Namn'
  },
  phoneForSms: {
    id: 'hbs.global.phoneForSms',
    defaultMessage: 'Telefon (för SMS)'
  },
  phoneAlternative: {
    id: 'hbs.global.phoneAlternative',
    defaultMessage: 'Telefon (alternativ)'
  },
  email: {
    id: 'hbs.global.email',
    defaultMessage: 'E-post'
  },
  dateOfBirth: {
    id: 'hbs.global.dateOfBirth',
    defaultMessage: 'Födelsedatum'
  },
  gender: {
    id: 'hbs.global.gender',
    defaultMessage: 'Kön'
  },
  genderMale: {
    id: 'hbs.global.genderMale',
    defaultMessage: 'Man'
  },
  genderFemale: {
    id: 'hbs.global.genderFemale',
    defaultMessage: 'Kvinna'
  },
  genderNone: {
    id: 'hbs.global.genderNone',
    defaultMessage: 'Ej valt'
  },
  other: {
    id: 'hbs.global.other',
    defaultMessage: 'Övrigt'
  },
  settings: {
    id: 'hbs.global.settings',
    defaultMessage: 'Inställningar'
  },
  smsReminders: {
    id: 'hbs.global.smsReminders',
    defaultMessage: 'Påminnelser via SMS'
  },
  allowMarketing: {
    id: 'hbs.global.allowMarketing',
    defaultMessage: 'Tillåter reklamutskick'
  },
  stereetAddress: {
    id: 'hbs.global.stereetAddress',
    defaultMessage: 'Gatuadress'
  },
  postCode: {
    id: 'hbs.global.postCode',
    defaultMessage: 'Postnummer/ort'
  },
  country: {
    id: 'hbs.global.country',
    defaultMessage: 'Land'
  },
  add: {
    id: 'hbs.global.add',
    defaultMessage: 'Lägg till'
  },
  cancel: {
    id: 'hbs.global.cancel',
    defaultMessage: 'Avbryt'
  },
  remove: {
    id: 'hbs.global.remove',
    defaultMessage: 'Ta bort'
  },
  change: {
    id: 'hbs.global.change',
    defaultMessage: 'Ändra'
  },
  close: {
    id: 'hbs.global.close',
    defaultMessage: 'Stäng'
  },
  save: {
    id: 'hbs.global.save',
    defaultMessage: 'Spara'
  },
  saving: {
    id: 'hbs.global.saving',
    defaultMessage: 'Sparar...'
  },
  emailAddress: {
    id: 'hbs.global.emailAddress',
    defaultMessage: 'E-postadress'
  },
  roleAccountOwner: {
    id: 'hbs.global.roleAccountOwner',
    defaultMessage: 'Kontoägare'
  },
  roleAdministrator: {
    id: 'hbs.global.roleAdministrator',
    defaultMessage: 'Administratör'
  },
  roleFrontOfficeUser: {
    id: 'hbs.global.roleFrontOfficeUser',
    defaultMessage: 'Priviligerad användare'
  },
  roleUnprivilegedUser: {
    id: 'hbs.global.roleUnprivilegedUser',
    defaultMessage: 'Användare'
  },
  descriptionAccountOwner: {
    id: 'hbs.global.descriptionAccountOwner',
    defaultMessage: 'Äger kontot, har alla rättigheter'
  },
  descriptionAdministrator: {
    id: 'hbs.global.descriptionAdministrator',
    defaultMessage: 'Bokar alla och administrerar'
  },
  descriptionFrontOfficeUser: {
    id: 'hbs.global.descriptionFrontOfficeUser',
    defaultMessage: 'Bokar alla resurser'
  },
  descriptionUnprivilegedUser: {
    id: 'hbs.global.descriptionUnprivilegedUser',
    defaultMessage: 'Bokar egen resurs'
  },
  commonShedule: {
    id: 'hbs.sheduleMain.commonShedule',
    defaultMessage: 'Gemensamt schema för kontot'
  },
  commonSheduleTip: {
    id: 'hbs.sheduleMain.commonSheduleTip',
    defaultMessage: 'Tips! Om du vill använda ett individuellt schema för en resurs, gå till'
  },
  resourcesSchedule: {
    id: 'hbs.sheduleMain.resourcesSchedule',
    defaultMessage: 'Resurser » Schema'
  },
  emailNotification: {
    id: 'hbs.notifications.emailNotification',
    defaultMessage: 'E-postnotiser'
  },
  offNoMailing: {
    id: 'hbs.notifications.offNoMailing',
    defaultMessage: 'Av - Inga utskick via e-post'
  },
  webBookingMailing: {
    id: 'hbs.notifications.webBookingMailing',
    defaultMessage: 'Webbokningar - E-post vid nya webbokningar & avbokningar'
  },
  allBookingMailing: {
    id: 'hbs.notifications.allBookingMailing',
    defaultMessage: 'Alla bokningar - E-post vid alla nya bokningar & avbokningar'
  },
  sendEmailAfterAction: {
    id: 'hbs.notifications.sendEmailAfterAction',
    defaultMessage: 'Skicka e-post när en bokning skapas eller avbokas'
  },
  emailRecipient: {
    id: 'hbs.notifications.emailRecipient',
    defaultMessage: 'Välj vilken e-postadress notiser ska skickas till'
  },
  saveChanges: {
    id: 'hbs.notifications.saveChanges',
    defaultMessage: 'Spara ändringar'
  },
  userInvitationExplanation: {
    id: 'hbs.create-user.userInvitationExplanation',
    defaultMessage: 'Om användaren redan har ett konto hos Dashl läggs användaren till direkt. Annars skickas en inbjudan som användaren behöver acceptera.'
  },
  enterValidEmail: {
    id: 'hbs.create-user.enterValidEmail',
    defaultMessage: 'Ange en gitig e-postadress'
  },
  addingUsers: {
    id: 'hbs.create-user.addingUsers',
    defaultMessage: 'Lägger till användare...'
  },
  saveUsers: {
    id: 'hbs.edit-user-form.saveUsers',
    defaultMessage: 'Spara användare'
  },
  noResource: {
    id: 'hbs.user-list-item.noResource',
    defaultMessage: 'Ingen resurs'
  },
  invited: {
    id: 'hbs.user-list-item.invited',
    defaultMessage: 'Inbjuden'
  },
  selectCustomerOrClick: {
    id: 'hbs.no-item-details.selectCustomerOrClick',
    defaultMessage: 'Välj en kund i listan till vänster, eller klicka på “Lägg till kund” för att skapa en ny kund.'
  },
  customerNumber: {
    id: 'hbs.container.customerNumber',
    defaultMessage: 'Kundnr. #'
  },
  created: {
    id: 'hbs.container.created',
    defaultMessage: 'Skapad'
  },
  recentActivity: {
    id: 'hbs.container.recentActivity',
    defaultMessage: 'Senaste aktivitet'
  },
  removeCustomer: {
    id: 'hbs.container.removeCustomer',
    defaultMessage: 'Ta bort kund'
  },
  bookedPerson: {
    id: 'hbs.booking-item.bookedPerson',
    defaultMessage: 'Bokat person'
  },
  dropIn: {
    id: 'hbs.booking-item.dropIn',
    defaultMessage: 'Drop in'
  },
  earlier: {
    id: 'hbs.bookings.earlier',
    defaultMessage: 'Tidigare'
  },
  coming: {
    id: 'hbs.bookings.coming',
    defaultMessage: 'Kommande'
  },
  noBookingsForView: {
    id: 'hbs.bookings.noBookingsForView',
    defaultMessage: 'Det finns inga bokningar att visa'
  },
  saveNote: {
    id: 'hbs.log-item-edit.saveNote',
    defaultMessage: 'Spara anteckning'
  },
  changed: {
    id: 'hbs.log-item.changed',
    defaultMessage: 'ändrad'
  },
  addNote: {
    id: 'hbs.log.addNote',
    defaultMessage: 'Lägg till en anteckning'
  },
  clickToWrite: {
    id: 'hbs.log.clickToWrite',
    defaultMessage: 'Klicka för att skriva...'
  },
  thereAreNoPrevNotes: {
    id: 'hbs.log.thereAreNoPrevNotes',
    defaultMessage: 'Det finns inga tidigare anteckningar'
  },
  findCustomer: {
    id: 'hbs.list.findCustomer',
    defaultMessage: 'Sök kund'
  },
  noCustomersFound: {
    id: 'hbs.list.noCustomersFound',
    defaultMessage: 'Hittade inga kunder'
  },
  noDuplicates: {
    id: 'hbs.confirm.noDuplicates',
    defaultMessage: 'Inga dubbletter.'
  },
  noDuplicatesDescription: {
    id: 'hbs.confirm.noDuplicatesDescription',
    defaultMessage: 'Bekräfta borttagning av kunder med samma namn och mobilnummer. Bokningshistorik och kundlogg kommer flyttas till den kund som behålls.'
  },
  numberOfDuplicates: {
    id: 'hbs.confirm.numberOfDuplicates',
    defaultMessage: 'Antal dubbletter'
  },
  removeDuplicates: {
    id: 'hbs.confirm.removeDuplicates',
    defaultMessage: 'Ta bort dubbletter'
  },
  removingDuplicates: {
    id: 'hbs.confirm.removingDuplicates',
    defaultMessage: 'Tar bort dubbletter...'
  },
  checking: {
    id: 'hbs.merge.checking',
    defaultMessage: 'Kontrollerar...'
  },
  resourceUsesCommonShedule: {
    id: 'hbs.schedule.resourceUsesCommonShedule',
    defaultMessage: 'Resursen använder kontots gemensamma schema'
  },
  changeScheduleByAdministering: {
    id: 'hbs.schedule.changeScheduleByAdministering',
    defaultMessage: 'Ändra schemat genom att administrera kontots schema'
  },
  activateIndividualSchedule: {
    id: 'hbs.schedule.activateIndividualSchedule',
    defaultMessage: 'Om du vill använda ett individuellt schema så kan du aktivera det här'
  },
  useYourOwnSchedule: {
    id: 'hbs.schedule.useYourOwnSchedule',
    defaultMessage: 'Använd eget schema'
  },
  manageYourSchedule: {
    id: 'hbs.schedule.manageYourSchedule',
    defaultMessage: 'Administrera kontots schema'
  },
  individualScheduleEnabled: {
    id: 'hbs.schedule.individualScheduleEnabled',
    defaultMessage: 'Individuellt schema är aktiverat för den här resursen'
  },
  activateCommonSchedule: {
    id: 'hbs.schedule.activateCommonSchedule',
    defaultMessage: 'Om du vill använda kontots gemensamma schema så kan du aktivera det här'
  },
  useTheAccountSchedule: {
    id: 'hbs.schedule.useTheAccountSchedule',
    defaultMessage: 'Använd kontots schema'
  },
  scheduleExceptions: {
    id: 'hbs.schedule-exceptions.scheduleExceptions',
    defaultMessage: 'Undantag från schemat'
  },
  tipCanAddExceptions: {
    id: 'hbs.schedule-exceptions.tipCanAddExceptions',
    defaultMessage: 'Tips! Du kan lägga till undantag från schemat genom att klicka på schemaläge'
  },
  tipCalendar: {
    id: 'hbs.schedule-exceptions.tipCalendar',
    defaultMessage: 'i kalendern'
  },
  removeAllExceptions: {
    id: 'hbs.schedule-exceptions.removeAllExceptions',
    defaultMessage: 'Ta bort alla undantag'
  },
  date: {
    id: 'hbs.schedule-exceptions.date',
    defaultMessage: 'Datum'
  },
  bookable: {
    id: 'hbs.schedule-exceptions.bookable',
    defaultMessage: 'Bokningsbar'
  },
  notBookable: {
    id: 'hbs.schedule-exceptions.notBookable',
    defaultMessage: 'Ej Bokningsbar'
  },
  start: {
    id: 'hbs.item-modal.start',
    defaultMessage: 'Start'
  },
  final: {
    id: 'hbs.item-modal.final',
    defaultMessage: 'Slut'
  },
  mustBeLaterThanStart: {
    id: 'hbs.item-modal.mustBeLaterThanStart',
    defaultMessage: 'Måste vara senare än start'
  },
  reset: {
    id: 'hbs.schedule.reset',
    defaultMessage: 'Återställ'
  },
  saveScheduleChanges: {
    id: 'hbs.schedule.saveScheduleChanges',
    defaultMessage: 'Spara ändringar för schemat'
  },
  savingSchedule: {
    id: 'hbs.schedule.savingSchedule',
    defaultMessage: 'Sparar schema...'
  },
  description: {
    id: 'hbs.new-schedule-modal.description',
    defaultMessage: 'Beskrivning'
  },
  descriptionExample: {
    id: 'hbs.new-schedule-modal.descriptionExample',
    defaultMessage: '(tex. Sommarschema eller Julledigt)'
  },
  startDate: {
    id: 'hbs.new-schedule-modal.startDate',
    defaultMessage: 'Startdatum'
  },
  startDateExample: {
    id: 'hbs.new-schedule-modal.startDateExample',
    defaultMessage: '(måndag)'
  },
  endDate: {
    id: 'hbs.new-schedule-modal.endDate',
    defaultMessage: 'Slutdatum'
  },
  endtDateExample: {
    id: 'hbs.new-schedule-modal.endDateExample',
    defaultMessage: '(söndag)'
  },
  validUntilFurtherNotice: {
    id: 'hbs.new-schedule-modal.validUntilFurtherNotice',
    defaultMessage: 'Gäller tills vidare'
  },
  recurringWeeks: {
    id: 'hbs.new-schedule-modal.recurringWeeks',
    defaultMessage: 'Återkommande veckor'
  },
  weekSymbol: {
    id: 'hbs.new-schedule-modal.weekSymbol',
    defaultMessage: '{num}v'
  },
  select: {
    id: 'hbs.new-schedule-modal.select',
    defaultMessage: 'Välj'
  },
  forSameSchedule: {
    id: 'hbs.new-schedule-modal.forSameSchedule',
    defaultMessage: 'för samma schema varje vecka'
  },
  forDifferentSchedule: {
    id: 'hbs.new-schedule-modal.forDifferentSchedule',
    defaultMessage: 'för olika schema ojämna/jämna veckor osv'
  },
  untilFurtherNotice: {
    id: 'hbs.schedule.untilFurtherNotice',
    defaultMessage: 'tills vidare'
  },
  recurringWeek: {
    id: 'hbs.week-item.recurringWeek',
    defaultMessage: 'Återkommande vecka'
  },
  selectScheduleToChange: {
    id: 'hbs.timeline.selectScheduleToChange',
    defaultMessage: 'Välj schema att ändra'
  },
  removedCountDuplicates: {
    id: 'hbs.alertOverlay.removedCountDuplicates',
    defaultMessage: 'Tog bort {mergeCount} dubbletter i kundregister'
  },
  scheduleHasBeenSaved: {
    id: 'hbs.alertOverlay.scheduleHasBeenSaved',
    defaultMessage: 'Schemat har sparats'
  },
  nameHasBeenAdded: {
    id: 'hbs.alertOverlay.nameHasBeenAdded',
    defaultMessage: '{username} har lagts till'
  },
  deleteScheduleTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteScheduleTitle',
    defaultMessage: 'Ta bort schema'
  },
  deleteScheduleText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteScheduleText',
    defaultMessage: 'Vill du verkligen ta bort schemat? Detta går inte att ångra.'
  },
  deleteExceptionsTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteExceptionsTitle',
    defaultMessage: 'Ta bort alla undantag'
  },
  deleteExceptionsText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteExceptionsText',
    defaultMessage: 'Vill du ta rensa samtliga undantag för resursen? Detta går inte att ångra.'
  },
  removeExceptionsTitle: {
    id: 'hbs.ComfirmDeletePopovewView.removeExceptionsTitle',
    defaultMessage: 'Ta bort undantag'
  },
  removeExceptionsText: {
    id: 'hbs.ComfirmDeletePopovewView.removeExceptionsText',
    defaultMessage: 'Vill du ta bort undantag för {dateString}?'
  },
  deleteScheduleFromAccountTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteScheduleFromAccountTitle',
    defaultMessage: 'Använd schema från kontot'
  },
  deleteScheduleFromAccountText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteScheduleFromAccountText',
    defaultMessage: 'Samtliga egna scheman kommer att tas bort. Vill du använda scheman från kontot?'
  },
  deleteNoteTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteNoteTitle',
    defaultMessage: 'Ta bort anteckning'
  },
  deleteNoteText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteNoteText',
    defaultMessage: 'Vill du verkligen ta bort anteckningen? Detta går inte att ångra.'
  },
  deleteCustomerTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteCustomerTitle',
    defaultMessage: 'Ta bort kund'
  },
  deleteCustomerText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteCustomerText',
    defaultMessage: 'Vill du verkligen ta bort kunden? Detta går inte att ångra.'
  },
  deleteCustomerAdditionalText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteCustomerAdditionalText',
    defaultMessage: 'Kontrollerar kommande bokningar...'
  },
  cancelInvitationTitle: {
    id: 'hbs.ComfirmDeletePopovewView.cancelInvitationTitle',
    defaultMessage: 'Avbryt inbjudan'
  },
  cancelInvitationText: {
    id: 'hbs.ComfirmDeletePopovewView.cancelInvitationText',
    defaultMessage: 'Vill du avbryta och ta bort inbjudan för {username}?'
  },
  deleteUsersTitle: {
    id: 'hbs.ComfirmDeletePopovewView.deleteUsersTitle',
    defaultMessage: 'Ta bort användare'
  },
  deleteUsersText: {
    id: 'hbs.ComfirmDeletePopovewView.deleteUsersText',
    defaultMessage: 'Vill du verkligen ta bort användaren? Detta går inte att ångra.'
  },
  noUpcomingBookings: {
    id: 'hbs.container-view.noUpcomingBookings',
    defaultMessage: 'Det finns inga kommande bokningar.'
  },
  oneUpcomingBooking: {
    id: 'hbs.container-view.oneUpcomingBooking',
    defaultMessage: 'Det finns en kommande bokning.'
  },
  fewUpcomingBookings: {
    id: 'hbs.container-view.fewUpcomingBookings',
    defaultMessage: 'Det finns {futureBookings} kommande bokningar.'
  },
  thereIsAlreadyUser: {
    id: 'hbs.text.thereIsAlreadyUser',
    defaultMessage: 'Det finns redan en användare eller inbjudan för {email}'
  },
  userValidationText: {
    id: 'hbs.text.userValidationText',
    defaultMessage: 'Resurs för användaren behöver väljas för roll Användare. Välj Priviligerad användare om fler resurser skall vara synliga för användaren.'
  },
  show: {
    id: 'hbs.text.show',
    defaultMessage: 'SHOW'
  },
  noShow: {
    id: 'hbs.text.noShow',
    defaultMessage: 'NO SHOW'
  },
  cancelled: {
    id: 'hbs.text.cancelled',
    defaultMessage: 'AVBOKAD'
  }
});
