import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSectionUrl } from '../../../utils/navigate';
import { addServiceMapping, deleteServiceMapping, addMultipleServicesMapping, deleteMultipleServicesMapping, fetchAllResourceMappings, fetchServices } from '../../../state/services-actions';
import { CHANGE_RESOURCES, hasPermission } from '../../../utils/permissions';
import { getPermissions, enableDashlFeatures } from '../../../utils/selectors';
import ResourceServiceGroup from './resource-service-group';
import CustomServiceSettingsModal from '../../services/custom-service-settings-modal';

import { msgResourceServices as msg } from '../../../localization/messages/components/resources';

class ResourceServices extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    loadData: PropTypes.func.isRequired,
    filter: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      customServiceMapping: null,
      onlyShowMapped: true
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  selectService = (serviceId, active) => {
    if (active) {
      this.props.addServiceMapping(serviceId, this.props.resourceId);
    } else {
      this.props.deleteServiceMapping(serviceId, this.props.resourceId);
    }
  };

  selectServiceGroup = (serviceIds, active) => {
    if (active) {
      return this.props.addMultipleServicesMapping(serviceIds, this.props.resourceId);
    }

    return this.props.deleteMultipleServicesMapping(serviceIds, this.props.resourceId);
  }

  showCustomMappingModal = (serviceId) => {
    const mappingId = `${this.props.resourceId}:${serviceId}`;
    const serviceMapping = this.props.serviceMappings.get(mappingId);
    this.setState({ customServiceMapping: serviceMapping.set('resource', this.props.resource) });
  };

  hideCustomMappingModal = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ customServiceMapping: null });
  };

  toggleFilter = (ev) => {

  };

  render() {
    const { routeParams, permissions, resSrvCount, showCustomValues, intl: { formatMessage: f } } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { customServiceMapping, loading } = this.state;
    const servicesUrl = getSectionUrl(routeParams, 'services');
    const administratorSrt = f(msg.administrator);

    return (
      <div className="resource-services-container">
        { resSrvCount === 0 && !loading && (
          <div className="alert alert-danger" role="alert">
            <FormattedMessage {...msg.note} />
          </div>
        )}
        <h4><FormattedMessage {...msg.heading} /></h4>
        <div className="text-muted">
          {showCustomValues ? (
            <ul>
              {canChangeResource && (
              <li>
                <FormattedMessage {...msg.addNewServices} />
                <Link to={servicesUrl}>{administratorSrt}</Link>
              </li>)}
              <li>
                <FormattedMessage {...msg.setYourOwnValues} />
              </li>
            </ul>
          ) : (
            <ul>
              {canChangeResource && (

              <li>
                <FormattedMessage {...msg.changeTheOrder} />
                <Link to={servicesUrl}>{administratorSrt}</Link>
              </li>)}
            </ul>
          )}
        </div>
        <br /><br />
        {this.renderList()}
        {customServiceMapping && <CustomServiceSettingsModal serviceMapping={customServiceMapping} closeModal={this.hideCustomMappingModal} />}
      </div>
    );
  }

  renderList() {
    const { filteredServiceGroups, serviceMappings, resourceId, showCustomValues } = this.props;

    return filteredServiceGroups.map((group) => {
      return (
        <ResourceServiceGroup
          key={group.get('id')}
          groupId={group.get('id')}
          groupName={group.get('name')}
          services={this.groupServices(group)}
          serviceMappings={serviceMappings}
          resourceId={resourceId}
          selectService={this.selectService}
          selectServiceGroup={this.selectServiceGroup}
          setCustomValues={this.showCustomMappingModal}
          showCustomValues={showCustomValues}
        />
      );
    });
  }

  groupServices(group) {
    const { filteredServices } = this.props;
    const serviceIds = group.get('serviceIds');
    return serviceIds.map(id => filteredServices.get(id)).filter(v => v);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { servicesById, orderedServiceGroups, resourcesById, resourceServiceMappingsByCombinedId } = state;
  const { filter, onlyShowMapped, routeParams } = ownProps;
  const { entityId: resourceId } = ownProps.routeParams;

  const resSrvCount = resourceServiceMappingsByCombinedId.count((value, key) => {
    return key.startsWith(resourceId);
  });

  let filteredServices = filter ? servicesById.filter((srv) => {
    return srv.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  }) : servicesById;


  filteredServices = onlyShowMapped ?
    filteredServices.filter((srv) => {
      const mappingId = `${resourceId}:${srv.id}`;
      return resourceServiceMappingsByCombinedId.has(mappingId);
    }) :
    filteredServices;


  const filteredServiceGroups = filter ? orderedServiceGroups.filter((group) => {
    let match = false;
    group.get('serviceIds').forEach((srvId) => {
      if (filteredServices.has(srvId)) {
        match = true;
      }
    });
    return match;
  }) : orderedServiceGroups;

  return {
    resourceId,
    resource: resourcesById.get(resourceId),
    routeParams,
    filteredServices,
    filteredServiceGroups,
    serviceMappings: resourceServiceMappingsByCombinedId,
    resSrvCount,
    permissions: getPermissions(state, { routeParams }),
    showCustomValues: !enableDashlFeatures(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      return Promise.all([
        dispatch(fetchServices()),
        dispatch(fetchAllResourceMappings())
      ]);
    },
    addServiceMapping: (serviceId, resourceId) => {
      return dispatch(addServiceMapping(serviceId, resourceId));
    },
    deleteServiceMapping: (serviceId, resourceId) => {
      return dispatch(deleteServiceMapping(serviceId, resourceId));
    },
    addMultipleServicesMapping: (serviceIds, resourceId) => {
      return dispatch(addMultipleServicesMapping(serviceIds, resourceId));
    },
    deleteMultipleServicesMapping: (serviceIds, resourceId) => {
      return dispatch(deleteMultipleServicesMapping(serviceIds, resourceId));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResourceServices));
