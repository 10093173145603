import moment from 'moment';
import PT from 'prop-types';
import s from 'underscore.string';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import ReactDOM from 'react-dom';
import BookingDetails from './booking-details';

import { msgBookingInfo as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingInfo extends Component {
  static propTypes = {
    id: PT.oneOfType([PT.number, PT.string]).isRequired,
    status: PT.string.isRequired,
    bookedAs: PT.string,
    createdTime: PT.object,
    cancelledTime: PT.object,
    smsStatusUpdateTime: PT.object,
    lastUpdateTime: PT.object,
    channel: PT.string.isRequired,
    cancelledChannel: PT.string,
    smsStatus: PT.string.isRequired,
    type: PT.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      editTime: false
    };
  }

  focusDurationField = () => {
    if (this.duration) {
      const duration = ReactDOM.findDOMNode(this.duration);
      duration.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editTime: true }, () => this.focusDurationField());
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editTime: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  showDetails = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showDetails: true });
  };

  hideDetails = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showDetails: false });
  };

  render() {
    const { showDetails, editTime } = this.state;
    const { id, startTime, endTime, afterTime, status, intl: { formatMessage: f } } = this.props;
    const isCancelled = status === 'Cancelled';

    const isNew = id === 'DRAGGER';
    const hasAfterTime = afterTime > 0;
    const displayEndTime = hasAfterTime ? moment(endTime).subtract(afterTime, 'm') : endTime;

    if (editTime) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-6">
              <label className="control-label">{f(msg.lblLength)}</label>
              <label className="form-control-label">{f(msg.minutes)}</label>
              <Field name="serviceDuration" component="input" type="number" className="form-control" />
            </div>
            <div className="form-group col-xs-6">
              <label className="control-label">{f(msg.lblPauseAfter)}</label>
              <label className="form-control-label">{f(msg.minutes)}</label>
              <Field name="afterTime" component="input" type="number" className="form-control" />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="booking-form-block nopointer">
        {startTime && endTime && (
          <div>
            <div className="block-buttons">
              {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>{f(msgButtons.btnChange)}</button>}
            </div>
            <h4>
              {f(msg.heading, {
                startTime: startTime.format('HH:mm'),
                endTime: displayEndTime.format('HH:mm'),
                hasAfterTime,
                afterTime,
                small: (...chunks) => <small>{chunks}</small>
              })}
            </h4>
            {!isNew && (
              <div className="pull-right">
                {showDetails ?
                  <button type="button" className="btn-link" tabIndex={-1} onClick={this.hideDetails}>
                    <i className="fa fa-chevron-circle-down" /> {f(msg.btnHideDetails)}
                  </button> :
                  <button type="button" className="btn-link" tabIndex={-1} onClick={this.showDetails}>
                    <i className="fa fa-chevron-circle-right" /> {f(msg.btnShowDetails)}
                  </button>
                }
              </div>
            )}
            <small>
              {s.capitalize(startTime.format('dddd LL'))}
            </small>
          </div>
        )}
        {showDetails && <BookingDetails {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    initialValues: bkf.get('service'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    payment: bkf.get('payment'),
    ...bkf.get('attributes'),
    ...getFormValues('bkf-time')(state)
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-time',
  destroyOnUnmount: false
})(BookingInfo)));
