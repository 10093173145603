import { defineMessages } from 'react-intl';

export const msgButtons = defineMessages({
  btnSave: {
    id: 'button.Save',
    defaultMessage: 'Spara'
  },
  btnSaving: {
    id: 'button.Saving',
    defaultMessage: 'Sparar...'
  },
  btnSaved: {
    id: 'button.Saved',
    defaultMessage: 'Sparat!'
  },
  btnCancel: {
    id: 'button.Cancel',
    defaultMessage: 'Avbryt'
  },
  btnOpen: {
    id: 'button.Open',
    defaultMessage: 'Öppna'
  },
  btnClose: {
    id: 'button.Close',
    defaultMessage: 'Stäng'
  },
  btnAdd: {
    id: 'button.Add',
    defaultMessage: 'Lägg till'
  },
  btnPrint: {
    id: 'button.Print',
    defaultMessage: 'Skriv ut'
  },
  btnClear: {
    id: 'button.Clear',
    defaultMessage: 'Klar'
  },
  btnClear2: {
    id: 'button.Clear2',
    defaultMessage: 'Rensa'
  },
  btnBack: {
    id: 'button.btnBack',
    defaultMessage: 'Tillbaka'
  },
  btnAddCompany: {
    id: 'button.AddCompany',
    defaultMessage: 'Lägg till företag...'
  },
  btnAddContact: {
    id: 'button.AddContact',
    defaultMessage: 'Lägg till kontaktperson...'
  },
  btnAddCustomer: {
    id: 'button.AddCustomer',
    defaultMessage: 'Lägg till kund'
  },
  btnExportCustomers: {
    id: 'button.ExportCustomers',
    defaultMessage: 'Ladda ner kundregister'
  },
  btnChange: {
    id: 'button.btnChange',
    defaultMessage: 'Ändra'
  },
  btnChangeData: {
    id: 'button.ChangeData',
    defaultMessage: 'Ändra uppgifter'
  },
  btnSeeMore: {
    id: 'button.SeeMore',
    defaultMessage: 'Visa fler fält'
  },
  btnSend: {
    id: 'button.Send',
    defaultMessage: 'Skicka'
  },
  btnSending: {
    id: 'button.Sending',
    defaultMessage: 'Skickar...'
  },
  btnLogOut: {
    id: 'button.LogOut',
    defaultMessage: 'Logga ut'
  },
  btnRemove: {
    id: 'button.Remove',
    defaultMessage: 'Ta bort'
  },
  btnUndo: {
    id: 'button.Undo',
    defaultMessage: 'Ångra'
  }
});
