import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBKFCustomer } from '../../state/bkf-actions';
import { setCustomerFormState } from '../../state/cf-actions';
import PhoneUtil from '../../../utils/phone-util';
import CustomerFormView from '../../../views/customer/customer-form-view';
import CustomerModel from '../../../models/customer';

class ChaplinCustomerForm extends Component {

  render() {
    return (
      <div />
    );
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formVisible) {
      this.customerView = new CustomerFormView({
        model: new CustomerModel(nextProps.customer)
      });

      this.customerView.on('closed', this.handleFormClosed);
      this.customerView.on('saved', this.handleFormSaved);
    } else {
      this.customerView && this.customerView.dispose();
    }
  }

  handleFormClosed = () => {
    this.props.closeForm();
  };

  handleFormSaved = (cust) => {
    const customer = {
      ...this.props.customer,
      ...cust,
      phoneNumber: PhoneUtil.formatPhoneNumberE164(cust.phoneNumber),
      otherPhoneNumber: PhoneUtil.formatPhoneNumberE164(cust.otherPhoneNumber)
    };
    this.props.customerSaved(customer);
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeForm: () => {
      dispatch(setCustomerFormState(
        {
          formVisible: false
        }
      ));
    },
    customerSaved: (customer) => {
      dispatch(setBKFCustomer(customer));
    }
  };
};


const mapStateToProps = (state, ownProps) => {
  const { cf } = state;
  const formVisible = cf.get('formVisible');
  const customer = cf.get('customer');

  return { formVisible, customer };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChaplinCustomerForm);
