import { Map } from 'immutable';
import { omit } from 'ramda';
import {
  SET_BKF_BOOKING, RESET_BKF, SET_BKF_CUSTOMER, SET_BKF_COMPANY, SET_BKF_VEHICLE,
  RES_SRV_LOADED, RES_SRV_LOADING, SET_BKF_SERVICE, ADD_BKF_SERVICE, REMOVE_BKF_SERVICE,
  SET_BKF_RES_TIME, SET_BKF_ATTRIBUTES, TOGGLE_BKF_PRINT_MODAL, CLEAR_RES_SRV
} from './bkf-actions';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { formatVehicleRegNo } from '../../utils/vehicle-util';
import { formatOrgNo } from '../../utils/luhn-util';
import { formatPhoneNumber } from '../../utils/phone-util';

function clearBookingForm(state, action) {
  return state.clear().withMutations((map) => {
    map.set('state', 'NOT_LOADED');
    map.set('id', null);
    map.set('vehicle', null);
    map.set('company', null);
    map.set('customer', null);
    map.set('startTime', null);
    map.set('endTime', null);
    map.set('resourceId', null);
    map.set('attributes', null);
    map.set('payment', null);
    map.set('service', null);
    map.delete('services');
    map.set('showPrintModal', false);
  });
}

function setBooking(state, action) {
  const booking = action.booking;
  return clearBookingForm(state).withMutations((map) => {
    const attributes = omit(
      ['id', 'startTime', 'endTime', 'resourceId', 'companyId', 'orgName', 'orgNo',
        'customerId', 'customerName', 'customerPhoneNumber', 'customerOtherPhoneNumber',
        'customerEmail', 'customerDeleted', 'services', 'afterTime', 'description', 'price',
        'attributes', 'vehicleRegNo', 'vehicleDescription', 'payment'
      ], booking);

    map.set('attributes', { ...attributes, ...booking.attributes });
    map.set('id', booking.id);
    map.set('startTime', booking.startTime);
    map.set('endTime', booking.endTime);
    map.set('resourceId', booking.resourceId);
    map.set('payment', booking.dashlPayment);

    if (booking.vehicleRegNo) {
      map.set('vehicle', {
        vehicleRegNo: formatVehicleRegNo(booking.vehicleRegNo),
        vehicleDescription: booking.vehicleDescription
      });
    } else {
      map.set('vehicle', null);
    }

    if (booking.companyId) {
      map.set('company', {
        companyId: booking.companyId,
        orgName: booking.orgName,
        orgNo: formatOrgNo(booking.orgNo)
      });
    } else {
      map.set('company', null);
    }

    if (booking.customerId) {
      map.set('customer', {
        customerId: booking.customerId,
        name: booking.customerName,
        phoneNumber: formatPhoneNumber(booking.customerPhoneNumber),
        otherPhoneNumber: formatPhoneNumber(booking.customerOtherPhoneNumber),
        email: booking.customerEmail,
        deleted: booking.customerDeleted
      });
    } else {
      map.set('customer', null);
    }

    const { services, endTime, startTime, afterTime } = booking;
    const totalDuration = endTime.diff(startTime, 'minutes');
    const serviceDuration = totalDuration - (afterTime || 0);
    const hasServices = services && services.length > 0;
    const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';

    if (hasServices || booking.description) {
      const service = {
        name: booking.description || serviceDescriptions,
        afterTime: booking.afterTime,
        price: booking.price,
        serviceDuration,
        totalDuration
      };
      map.set('service', service);

      for (const service of services) {
        map.setIn(['services', service.id], service);
      }
    } else {
      map.set('service', null);
    }

    map.set('state', 'LOADED');
  });
}

function setResourceAndTime(state, action) {
  return state.withMutations((map) => {
    const service = map.get('service');
    const totalDuration = action.endTime.diff(action.startTime, 'minutes');
    const serviceDuration = service && totalDuration - (service.afterTime || 0);

    map.set('startTime', action.startTime);
    map.set('endTime', action.endTime);
    map.set('resourceId', action.resId);
    map.set('service', { ...service, serviceDuration, totalDuration });
  });
}

function setAttributes(state, action) {
  return state.set('attributes', { ...state.get('attributes'), ...action.attributes });
}

function setService(state, action) {
  return state.withMutations((map) => {
    if (action.service) {
      map.set('service', action.service);

      if (action.service.id) {
        map.delete('services').setIn(['services', action.service.id], action.service);
      }
    }
    if (!action.service || !action.service.name) {
      map.delete('services');
    }
  });
}

function setCustomer(state, action) {
  const customer = action.customer ? {
    ...action.customer,
    phoneNumber: formatPhoneNumber(action.customer.phoneNumber),
    otherPhoneNumber: formatPhoneNumber(action.customer.otherPhoneNumber)
  } : null;

  return state.set('customer', customer);
}

export function bkf(state = Map({
  state: 'NOT_LOADED',
  customer: null,
  service: null
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
    case RESET_BKF:
      return clearBookingForm(state, action);
    case SET_BKF_ATTRIBUTES:
      return setAttributes(state, action);
    case SET_BKF_RES_TIME:
      return setResourceAndTime(state, action);
    case SET_BKF_BOOKING:
      return setBooking(state, action);
    case SET_BKF_SERVICE:
      return setService(state, action);
    case ADD_BKF_SERVICE:
      return state.setIn(['services', action.service.id], action.service);
    case REMOVE_BKF_SERVICE:
      return state.deleteIn(['services', action.service.id]);
    case SET_BKF_VEHICLE:
      return state.set('vehicle', action.vehicle);
    case SET_BKF_COMPANY:
      return state.set('company', action.company);
    case SET_BKF_CUSTOMER:
      return setCustomer(state, action);
    case TOGGLE_BKF_PRINT_MODAL:
      return state.set('showPrintModal', action.show);
    default:
      return state;
  }
}

export function resourceServices(state = Map({
  state: 'NOT_LOADED'
}), action = null) {
  switch (action.type) {
    case CLEAR_RES_SRV:
    case CLEAR_LOCATION_STATE:
      return state.clear().set('state', 'NOT_LOADED');

    case RES_SRV_LOADING:
      return state.set('state', 'LOADING');

    case RES_SRV_LOADED:
      return state.set(action.resId, action.services).set('state', 'LOADED').set('timestamp', new Date());

    default:
      return state;
  }
}
