var ModalView = require('../../base/modal-view');
var FormUtil = require('../../../utils/form-util');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = ModalView.extend({

    initialize: function (options) {
        this.users = options.users;
        this.template = require('./create-user');
        ModalView.prototype.initialize.call(this, arguments);

        this.getResources();
    },

    events: {
        'submit form': 'createUser',
        'change .js-role-select': 'roleChange',
        'change .js-resource-select': 'resourceChange'
    },

    resourceChange: function(e){
        this.validate();
    },

    roleChange: function(e){
        this.validate();
    },

    createUser: function (e) {
        e.preventDefault();

        if (!this.validate()){
            return;
        }

        var formData = this.$('form').serializeJSON();
        formData.resourceId = parseInt(formData.resourceId);

        this.$('button[type=submit]').button('loading');

        $.ajax({
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            url: '/users/invite',
            data: JSON.stringify(formData),
            success: _.bind(this.userCreated, this),
            error: _.bind(this.error, this),
            suppressErrors: true
        });
    },

    userCreated: function (data) {
        this.trigger('userCreated', data);
        this.close();
    },

    error: function(xhr) {
        if(xhr.status === 400) {
            var content = formatMessage(msgHandlebars.thereIsAlreadyUser, { email: this.$('input[name=email]').val() });
            this.$('.js-email-section').addClass('has-error');
            this.$('#email-exists-help').text(content).show();
        }
        this.$('button[type=submit]').button('reset');
    },

    getResources: function(){
        $.ajax({
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            url: '/resources/',
            success: _.bind(this.loadResources, this)
        });
    },

    loadResources: function(resources) {
        var users = this.users;
        var select = this.$('.js-resource-select');
        select.empty().append(function() {
            var output = `<option value="">${formatMessage(msgHandlebars.noResource)}</option>`;
            $.each(resources, function(key, value) {
                if(users.where({resourceId: value.id}).length === 0) {
                    output += '<option value="' + value.id + '">' + value.name + '</option>';
                }
            });
            return output;
        });
        FormUtil.populate(this.$('form'), this.model.attributes);
    },

    validate: function() {
        var isUnprivilegedUser = this.$('.js-role-select').val() === 'UnprivilegedUser';
        var hasSelectedResource = this.$('.js-resource-select').val() !== '';
        console.log(isUnprivilegedUser, this.$('.js-role-select').val(), this.$('.js-resource-select').val());

        if (isUnprivilegedUser && !hasSelectedResource){
            var content = formatMessage(msgHandlebars.userValidationText);
            this.$('.js-resource-select-section').addClass('has-error');
            this.$('#no-selected-resource-help').text(content);
            return false;
        }

        this.$('.js-resource-select-section').removeClass('has-error');
        this.$('#no-selected-resource-help').text('');
        return true;
    },

    render: function(){
        ModalView.prototype.render.call(this, arguments);
        this.$('form').parsley();
    }
});
