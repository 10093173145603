import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import Feature from './feature';
import { msgFeatureConfig as msg } from '../../../localization/messages/components/account-config';

const FeaturesConfig = () => {
  const { formatMessage: f } = useIntl();

  return (
    <Fragment>
      <div className="feature-group">
        <Feature
          name="EnableStripePayments"
          title={f(msg.titleEnableStripePayments)}
          icon="fa fa-credit-card"
          description={f(msg.descriptionEnableStripePayments)}
          sysAdminRequired
        />
      </div>
      <div className="feature-group">
        <Feature
          name="EnableCompanyBooking"
          title={f(msg.titleEnableCompanyBooking)}
          icon="fa fa-building"
          description={f(msg.descriptionEnableCompanyBooking)}
        />
      </div>
      <div className="feature-group">
        <Feature
          name="EnableVehicleBooking"
          title={f(msg.titleEnableVehicleBooking)}
          icon="fa fa-car"
          description={f(msg.descriptionEnableVehicleBooking)}
        />
        <Feature
          name="EnableVehicleRegistrySearch"
          dependency="EnableVehicleBooking"
          title={f(msg.titleEnableVehicleRegistrySearch)}
          icon="fa fa-search"
          description={f(msg.descriptionEnableVehicleRegistrySearch)}
          sysAdminRequired
        />
      </div>
    </Fragment>
  );
};

export default FeaturesConfig;
