import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateViewDimensions } from '../state/view-actions';
import { initializePusher } from '../utils/pusher';
import { setLocale } from '../utils/locale';

import AccountStatusBanner from '../components/dialogs/account-status-banner';
import NoResourcePromptsContainer from '../components/dialogs/no-resource-prompts';
import AccountInfoAlertContainer from '../components/dialogs/account-info-alert';
import NetworkErrorAlertContainer from '../components/dialogs/network-error-alert';
import VersionMismatchAlert from '../components/dialogs/version-mismatch-alert';
import FeaturesOnboardingModal from '../components/onboarding/features-onboarding-modal';

import Nav from '../components/nav/nav';
import Calendar from '../components/calendar/calendar';
import Resources from '../components/resources/resources';
import Services from '../components/services/services';
import Customers from './customers';
import Reports from './reports';
import Campaign from './campaign';
import LocationLoader from '../components/location-selection/LocationLoader';
import AccountConfig from '../components/account-config/account-config';
import StorageConfigAlert from '../components/dialogs/storage-config-alert';
import { deployEnvironment } from '../utils/config';

class MainView extends Component {
  constructor(props) {
    super(props);
    this.setSentryUserFromProps(props);
    this.setPageTitleFromProps(props);
    setLocale(props.locale, props.timeZone);
    this.props.initializePusher();
    this.updateDimensions = _.debounce(this.props.updateDimensions, 200);
  }

  handleResize = () => {
    this.updateDimensions();
  };

  handleOrientationChange = () => {
    setTimeout(() => {
      this.props.updateDimensions();
    }, 300);
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.org !== nextProps.org || this.props.loc !== nextProps.loc || this.props.username !== nextProps.username) {
      setLocale(this.props.locale, this.props.timeZone);
      this.props.initializePusher();

      this.setPageTitleFromProps(nextProps);
      this.setSentryUserFromProps(nextProps);
    }
  }

  formatLocationName(location) {
    if (location.orgName === location.locName) {
      return location.orgName;
    }
    return `${location.orgName} (${location.locName})`;
  }

  setPageTitleFromProps(props) {
    const { locationName, organisationName } = props;
    const titleName = organisationName == locationName ? organisationName : `${organisationName} (${locationName})`;
    const env = deployEnvironment();
    const title = env === 'Production' ? `${titleName} | Dashl` : `${env} | ${titleName}`;
    document.title = title;
  }

  setSentryUserFromProps(props) {
    if (Sentry) {
      const { username, org, loc } = props;
      console.log('Setting user context', username, org, loc);
      Sentry.configureScope((scope) => {
        scope.setUser({ email: username, org, loc });
      });
    } else {
      console.warn('Sentry not found, not setting user context');
    }
  }

  render() {
    return (
      <div>
        <VersionMismatchAlert />
        <NoResourcePromptsContainer />
        <NetworkErrorAlertContainer />
        <FeaturesOnboardingModal />

        <Route path="/:org/:loc" component={AccountStatusBanner} />
        <Route path="/:org/:loc" component={AccountInfoAlertContainer} />
        <Route path="/:org/:loc/:section" component={StorageConfigAlert} />

        <Route path="/:org/:loc/:section/:viewMode?/:viewDate?/:entityType?/:entityId?" component={Nav} />

        <Switch>
          <Route path="/:org/:loc/calendar/" exact component={LocationLoader} />
          <Route path="/:org/:loc/:section(customers)/" component={Customers} />
          <Route path="/:org/:loc/:section(resources)/:id?/:subsection?" component={Resources} />
          <Route path="/:org/:loc/:section(services)/:id?/:subsection?" component={Services} />
          <Route path="/:org/:loc/:section(reports)" component={Reports} />
          <Route path="/:org/:loc/:section(campaign)" component={Campaign} />
          <Route path="/:org/:loc/:section(account-config)" component={AccountConfig} />
          <Route path="/:org/:loc/:section/:viewMode/:viewDate/:entityType/:entityId" component={Calendar} />
        </Switch>
      </div>
    );
  }
}

MainView.propTypes = {
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  initializePusher: PropTypes.func.isRequired,
  updateDimensions: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, authState, intl: { locale } } = state;
  const { org, loc } = ownProps.match.params;
  const username = authState.get('username');

  return {
    username,
    org,
    loc,
    timeZone: locationConfig.get('timeZone'),
    locale,
    locationName: locationConfig.get('locationName'),
    organisationName: locationConfig.get('organisationName')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { org, loc } = ownProps.match.params;

  return {
    updateDimensions: () => {
      dispatch(updateViewDimensions());
    },
    initializePusher: () => {
      initializePusher(dispatch, org, loc);
    }
  };
};

const MainViewContainer = connect(
      mapStateToProps,
      mapDispatchToProps
)(MainView);

export default (MainViewContainer);

