var View = require('./view');

module.exports = View.extend({
   noWrap: true,
   autoRender: true,
   container:'body',
   containerMethod: 'append',
   optionNames: View.prototype.optionNames.concat(['title', 'parent']),

   initialize: function () {
      View.prototype.initialize.call(this, arguments);
   },

   setTitle: function(title){
      this.$('.modal-title').text(title);
   },

   show: function() {
      this.delegate('hidden.bs.modal', this.closed);

      var that = this;
      _.defer(function() {
         that.$el.modal({backdrop: 'static', keyboard: false});

         // Prevent click to trigger body click event
         that.$el.click(function(e) { e.stopPropagation(); });
         that.$('.modal-backdrop').click(function(e) { e.stopPropagation(); });
      });
   },

   closed: function(){
      this.trigger('closed', this);
      this.dispose();
   },

   hide: function(){
      if (this.$el) {
         this.$el.modal('hide');
      }
   },

   close: function(){
      this.hide();
   },

   render: function(){
      View.prototype.render.call(this, arguments);
      this.show();
   }
});