var View = require('../base/view');
var MergeView = require('./merge/merge-view');

module.exports = View.extend({
   noWrap: true,
   autoRender:true,
   container: '#chaplin-view-container',
   containerMethod: 'html',

   events: {
      'click .js-merge-duplicates': 'mergeClick',
      'click .js-add-customer': 'addClick'
   },

   regions: {
      'customer-list-region': '#customer-list',
      'customer-item-region': '#customer-item'
   },

   initialize: function () {
      this.template = require('./main');
      View.prototype.initialize.call(this, arguments);
   },

   addClick: function(e) {
      e.preventDefault();
      this.publishEvent('customers:addNew');
      e.target.blur();
   },

   mergeClick: function(e){
      e.preventDefault();
      var mergeView = new MergeView({model:new Chaplin.Model({}), parent: $(e.currentTarget)});
      this.subview('merge', mergeView);
      return false;
   },

   getTemplateData: function(){
      return {};
   }
});