import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import CurrencyUtil from '../../../utils/currency-util';
import TextInput from '../inputs/text-input';
import { createValidator, required, number, minValue } from '../../utils/validation';
import { serviceMappingValues } from '../../state/services-actions';
import CheckBox from '../inputs/checkbox';

import { msgEditServiceForm as msg } from '../../localization/messages/components/services';
import { msgButtons } from '../../localization/messages/shared/buttons';
import { msgLabels } from '../../localization/messages/shared/labels';

class CustomServiceSettingsForm extends Component {
  render() {
    const { handleSubmit, submitting, onClose, customValues, intl: { formatMessage: f } } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <div className="form-group">
          <div className="checkbox">
            <label>
              <Field component="input" type="checkbox" name="customValues" /> {f(msg.useCustomSettings)}
            </label>
          </div>
          <p className="text-muted">
            {f(msg.settingsDescription)}
          </p>
        </div>
        <hr />

        {customValues && <div>
          <Field name="serviceDuration" component={TextInput} label={f(msg.lblLength)} width={120} unitLabel={f(msgLabels.minute)} helpText={f(msg.helpLength)} />
          <Field name="afterTime" component={TextInput} label={f(msg.lblPauseAfter)} width={120} unitLabel={f(msgLabels.minute)} helpText={f(msg.helpPauseAfter)} />
          <Field name="price" component={TextInput} label={f(msg.lblPrice)} width={120} unitLabel={CurrencyUtil.currencySymbol()} />
          <Field name="priceFrom" component={CheckBox} label={f(msg.lblShowFromPrice)} />

          <hr />

          <div className="form-group">
            <label className="control-label">{f(msg.webBookingSettings)}</label>
            <Field name="webEnabled" component={CheckBox} label={f(msg.lblReservation)} />
            <Field name="webShowDuration" component={CheckBox} label={f(msg.lblShowLength)} />
            <Field name="webShowPrice" component={CheckBox} label={f(msg.lblShowPrice)} />
          </div>
          <hr />
        </div>}

        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right">
              <button type="button" className="btn-cancel" tabIndex="3" onClick={onClose}>{f(msgButtons.btnCancel)}</button> &nbsp;
              <button type="submit" className="btn-save" tabIndex="2" disabled={submitting}>
                {submitting ? f(msgButtons.btnSaving) : f(msgButtons.btnSave)}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const validator = createValidator({
  name: [required],
  serviceDuration: [required, number, minValue(5)],
  afterTime: [number, minValue(0)],
  price: [number, minValue(0)]
});

const warnings = (values, { serviceFormValues }) => {
  if (!serviceFormValues) {
    return null;
  }

  const warnings = {};
  const message = 'Tjänsten har ett annat värde';
  for (let i = 0; i < serviceMappingValues.length; i++) {
    const prop = serviceMappingValues[i];
    if (String(values[prop]) !== String(serviceFormValues[prop])) {
      warnings[prop] = message;
    }
  }
  return warnings;
};

const selector = formValueSelector('custom-service-settings-form');

const mapStateToProps = (state) => {
  return {
    customValues: selector(state, 'customValues')
  };
};

export default reduxForm({
  form: 'custom-service-settings-form',
  validate: validator,
  warn: warnings,
  shouldWarn: () => true
})(injectIntl(connect(mapStateToProps)(CustomServiceSettingsForm)));
