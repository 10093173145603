import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import ConfirmSendDialog from './confirm-send-dialog';

import { msgSend as msg } from '../../localization/messages/components/campaign';

export default class Send extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  componentDidMount() {
    this.refreshRecipients();
  }

  makeInitialState(props) {
    return {
      name: '',
      sender: props.sender,
      message: '',
      messageCount: 1,
      resourceIds: [],
      lastBookedSince: 'all',
      lastBookedDate: moment(),
      bookedUntil: 'all',
      bookedUntilDate: moment(),
      sendAtTime: '',
      sendDate: moment(),
      sendTime: '9:00',
      showSendDialog: false,
      includeOrphaned: false
    };
  }

  render() {
    const { resourcesById, recipients, showList, smsBulkPrice } = this.props;
    const { sender, message, messageCount, showSendDialog, resourceIds, lastBookedSince, lastBookedDate,
         bookedUntil, bookedUntilDate, sendAtTime, sendDate, sendTime, includeOrphaned } = this.state;

    const senderInvalid = sender && (sender.length > 11 || !sender.match(/^[a-z0-9_]+$/i));
    const senderClass = senderInvalid ? 'form-group has-error' : 'form-group';
    const messageClass = messageCount > 6 ? 'label label-danger' : 'label label-success';
    const recipientClass = recipients === 0 ? 'label label-danger' : 'label label-success';
    const sendButtonEnabled = !senderInvalid && sender.length > 0 && message.length > 0 && recipients > 0 && sendAtTime.length > 0;
    const sendTimeRadioClass = sendAtTime === 'later' ? 'radio-inline' : 'radio-inline disabled';
    const allResourcesSelected = this.allResourcesSelected(resourcesById, resourceIds);
    const cost = recipients * messageCount * smsBulkPrice;

    return (
      <div>
        <div className="pull-right">
          <a href="#" onClick={showList} className="lead"><FormattedMessage {...msg.showList} /></a>
        </div>
        <h3><FormattedMessage {...msg.heading} /></h3>
        <br />
        <form className="form-horizontal" onSubmit={this.showConfirmSend}>
          <fieldset>
            <legend><FormattedMessage {...msg.groupMailingName} /></legend>
            <div className="form-group">
              <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblDescription} /></label>
              <div className="col-lg-5"><input className="form-control" onChange={this.nameChanged} /></div>
              <div className="col-lg-5 form-help-text">
                <FormattedMessage {...msg.helpDescription} />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend><FormattedMessage {...msg.groupSenderAndContent} /></legend>
            <div className={senderClass}>
              <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblSender} /></label>
              <div className="col-lg-5">
                <input className="form-control" maxLength="11" value={sender} onChange={this.senderChanged} />
                {senderInvalid ? <span className="help-block"><FormattedMessage {...msg.errorSender} /></span> : null}
              </div>
              <div className="col-lg-5 form-help-text">
                <FormattedMessage {...msg.helpSender} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblMessage} /></label>
              <div className="col-lg-5">
                <textarea rows="4" className="form-control" maxLength="916" onChange={this.messageChanged} />
                <p className="lead"><span className={messageClass}><FormattedMessage {...msg.infoMessage} values={{ length: message.length, messageCount }} /></span></p>
              </div>
              <div className="col-lg-5 form-help-text">
                <FormattedMessage {...msg.helpMessage} />
              </div>
            </div>
          </fieldset>

          <div id="recipient-selection">
            <fieldset>
              <legend><FormattedMessage {...msg.groupRecipient} /></legend>

              <div className="form-group">
                <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblBookedWith} /></label>

                <div className="col-lg-5">
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" checked={allResourcesSelected} onChange={this.selectAllResources} /> <FormattedMessage {...msg.lblSelectAllResources} />
                    </label>
                  </div><br />
                  {resourcesById.valueSeq().map((resource) => {
                    const checked = resourceIds.indexOf(resource.id) !== -1;
                    return (
                      <div key={resource.id} className="checkbox">
                        <label>
                          <input type="checkbox" checked={checked} value={resource.id} onChange={this.resourceChecked} /> {resource.name}
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="col-lg-5 form-help-text">
                  <FormattedMessage {...msg.helpSelectAllResources} />
                </div>
              </div>

              <div className="form-group">
                <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblOther} /></label>
                <div className="col-lg-5">
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" checked={includeOrphaned} onChange={this.selectOrphaned} /> <FormattedMessage {...msg.lblIncludeCustomers} />
                    </label>
                  </div>
                </div>
                <div className="col-lg-5 form-help-text" />
              </div>

              <hr />

              <div className="form-group">
                <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblHowLongAgo} /></label>
                <div className="col-lg-5">
                  <div className="radio">
                    <label>
                      <input type="radio" value="all" checked={lastBookedSince === 'all'} onChange={this.lastBookedChanged} />
                      <FormattedMessage {...msg.lblNoLimits} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="days-30" checked={lastBookedSince === 'days-30'} onChange={this.lastBookedChanged} />
                      <FormattedMessage {...msg.lblBookedDaysAgo} values={{ count: 30 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="months-3" checked={lastBookedSince === 'months-3'} onChange={this.lastBookedChanged} />
                      <FormattedMessage {...msg.lblBookedMonthsAgo} values={{ count: 3 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="months-6" checked={lastBookedSince === 'months-6'} onChange={this.lastBookedChanged} />
                      <FormattedMessage {...msg.lblBookedMonthsAgo} values={{ count: 6 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="year-1" checked={lastBookedSince === 'year-1'} onChange={this.lastBookedChanged} />
                      <FormattedMessage {...msg.lblBookedYearAgo} />
                    </label>
                  </div>

                  <div className="radio">
                    <label>
                      <input type="radio" value="date" checked={lastBookedSince === 'date'} onChange={this.lastBookedChanged} />
                      <DatePicker dateFormat="YYYY-MM-DD" locale="sv" className="form-control" selected={lastBookedDate} onFocus={this.setLastBookedSince} onChange={this.lastBookedDateChanged} />
                    </label>
                  </div>
                </div>
                <div className="col-lg-5 form-help-text">
                  <FormattedMessage {...msg.helpHowLongAgo} />
                </div>
              </div>

              <hr />

              <div className="form-group">
                <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblHowFar} /></label>
                <div className="col-lg-5">
                  <div className="radio">
                    <label>
                      <input type="radio" value="all" checked={bookedUntil === 'all'} onChange={this.bookedUntilChanged} />
                      <FormattedMessage {...msg.lblNoLimits} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="days-30" checked={bookedUntil === 'days-30'} onChange={this.bookedUntilChanged} />
                      <FormattedMessage {...msg.lblWithinDaysAgo} values={{ count: 30 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="months-3" checked={bookedUntil === 'months-3'} onChange={this.bookedUntilChanged} />
                      <FormattedMessage {...msg.lblWithinMonthsAgo} values={{ count: 3 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="months-6" checked={bookedUntil === 'months-6'} onChange={this.bookedUntilChanged} />
                      <FormattedMessage {...msg.lblWithinMonthsAgo} values={{ count: 6 }} />
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input type="radio" value="year-1" checked={bookedUntil === 'year-1'} onChange={this.bookedUntilChanged} />
                      <FormattedMessage {...msg.lblWithinYearAgo} />
                    </label>
                  </div>

                  <div className="radio">
                    <label>
                      <input type="radio" value="date" checked={bookedUntil === 'date'} onChange={this.bookedUntilChanged} />
                      <DatePicker dateFormat="YYYY-MM-DD" locale="sv" className="form-control" selected={bookedUntilDate} onFocus={this.setBookedUntil} onChange={this.bookedUntilDateChanged} />
                    </label>
                  </div>
                </div>
                <div className="col-lg-5 form-help-text">
                  <FormattedMessage {...msg.helpHowFar} />
                </div>
              </div>

              <div className="form-group">
                <div className="col-lg-2" />
                <div className="col-lg-10">
                  <p className="lead">
                    <span className={recipientClass}><FormattedMessage {...msg.sendingExplanation} values={{ recipients }} /></span>
                  </p>
                </div>
              </div>
            </fieldset>
          </div>

          <fieldset>
            <legend><FormattedMessage {...msg.groupSendingTime} /></legend>
            <div className="form-group">
              <label className="col-lg-2 control-label"><FormattedMessage {...msg.lblSelectTime} /></label>

              <div className="col-lg-5">
                <div className="radio">
                  <label>
                    <input type="radio" value="now" checked={sendAtTime === 'now'} onChange={this.sendAtTimeChanged} />
                    <FormattedMessage {...msg.lblSendDirectly} />
                  </label>
                </div>

                <div className="radio">
                  <label>
                    <input type="radio" value="later" checked={sendAtTime === 'later'} onChange={this.sendAtTimeChanged} />
                    <div>
                      <DatePicker dateFormat="YYYY-MM-DD" locale="sv" className="form-control" selected={sendDate} onFocus={this.setSendAtTime} onChange={this.sendDateChanged} /><br />
                      <div className={sendTimeRadioClass} style={{ paddingLeft: 0 }}>
                        <label>
                          <input type="radio" value="9:00" checked={sendTime === '9:00'} onChange={this.sendTimeChanged} /> 9:00
                        </label>
                      </div>
                      <div className={sendTimeRadioClass} style={{ paddingLeft: 0 }}>
                        <label>
                          <input type="radio" value="12:00" checked={sendTime === '12:00'} onChange={this.sendTimeChanged} /> 12:00
                        </label>
                      </div>
                      <div className={sendTimeRadioClass} style={{ paddingLeft: 0 }}>
                        <label>
                          <input type="radio" value="15:00" checked={sendTime === '15:00'} onChange={this.sendTimeChanged} /> 15:00
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-lg-5 form-help-text">
                <FormattedMessage {...msg.helpSelectTime} />
              </div>
            </div>
          </fieldset>

          <hr />
          <div className="form-group">
            <div className="col-lg-2" />
            <div className="col-lg-10">
              <button type="submit" className="btn btn-success btn-lg" disabled={!sendButtonEnabled}>
                <FormattedMessage {...msg.btnSubmit} />
              </button>
            </div>
          </div>
        </form>
        {showSendDialog ? <ConfirmSendDialog
          onClose={this.hideConfirmSend}
          onConfirm={this.createCampaign}
          message={message}
          recipients={recipients}
          cost={cost}
          sendAtTime={sendAtTime}
          sendDate={sendDate}
          sendTime={sendTime}
        /> : null}
      </div>
    );
  }

  allResourcesSelected(resourcesById, resourceIds) {
    let result = true;
    resourcesById.valueSeq().forEach((resource) => {
      if (resourceIds.indexOf(resource.id) === -1) {
        result = false;
      }
    });
    return result;
  }

  selectAllResources = (ev) => {
    const { resourcesById } = this.props;
    const resourceIds = [];

    if (ev.target.checked) {
      resourcesById.valueSeq().forEach((resource) => {
        resourceIds.push(resource.id);
      });
    }

    this.setState({ resourceIds }, this.refreshRecipients);
  };

  selectOrphaned = (ev) => {
    this.setState({ includeOrphaned: ev.target.checked }, this.refreshRecipients);
  };

  resourceChecked = (ev) => {
    const { resourceIds } = this.state;

    if (ev.target.checked) {
      resourceIds.push(parseInt(ev.target.value));
    } else {
      const index = resourceIds.indexOf(parseInt(ev.target.value));
      resourceIds.splice(index, 1);
    }

    this.setState({ resourceIds }, this.refreshRecipients);
  };

  lastBookedChanged = (ev) => {
    this.setState({ lastBookedSince: ev.target.value }, this.refreshRecipients);
  };

  setLastBookedSince = (ev) => {
    this.setState({ lastBookedSince: 'date' }, this.refreshRecipients);
  };

  lastBookedDateChanged = (date) => {
    this.setState({ lastBookedDate: date }, this.refreshRecipients);
  };

  getLastBookedDate() {
    const { lastBookedSince, lastBookedDate } = this.state;

    switch (lastBookedSince) {
      case 'days-30':
        return moment().subtract(30, 'days');
      case 'months-3':
        return moment().subtract(3, 'months');
      case 'months-6':
        return moment().subtract(6, 'months');
      case 'year-1':
        return moment().subtract(1, 'years');
      case 'all':
        return moment().subtract(10, 'years');
      case 'date':
        return lastBookedDate;
    }
  }

  bookedUntilChanged = (ev) => {
    this.setState({ bookedUntil: ev.target.value }, this.refreshRecipients);
  };

  setBookedUntil = (ev) => {
    this.setState({ bookedUntil: 'date' }, this.refreshRecipients);
  };

  bookedUntilDateChanged = (date) => {
    this.setState({ bookedUntilDate: date }, this.refreshRecipients);
  };

  getBookedUntilDate() {
    const { bookedUntil, bookedUntilDate } = this.state;

    switch (bookedUntil) {
      case 'days-30':
        return moment().add(30, 'days');
      case 'months-3':
        return moment().add(3, 'months');
      case 'months-6':
        return moment().add(6, 'months');
      case 'year-1':
        return moment().add(1, 'years');
      case 'all':
        return null;
      case 'date':
        return bookedUntilDate;
    }
  }

  refreshRecipients() {
    const { refreshRecipients } = this.props;
    const { resourceIds, includeOrphaned } = this.state;

    const lastBookedDate = this.getLastBookedDate().format('YYYY-MM-DD');
    const bookedUntilDate = this.getBookedUntilDate();
    const bookedUntil = bookedUntilDate ? bookedUntilDate.format('YYYY-MM-DD') : null;

    refreshRecipients(resourceIds, lastBookedDate, includeOrphaned, bookedUntil);
  }

  sendAtTimeChanged = (ev) => {
    this.setState({ sendAtTime: ev.target.value });
  };

  setSendAtTime = (ev) => {
    this.setState({ sendAtTime: 'later' });
  };

  sendDateChanged = (date) => {
    this.setState({ sendDate: date });
  };

  sendTimeChanged = (ev) => {
    this.setState({ sendTime: ev.target.value });
  };

  nameChanged = (ev) => {
    this.setState({ name: ev.target.value });
  };

  senderChanged = (ev) => {
    this.setState({ sender: ev.target.value });
  };

  messageChanged = (ev) => {
    const message = ev.target.value;
    const messageCount = message.length < 160 ? 1 : Math.ceil(message.length / 153);

    this.setState({ message, messageCount });
  };

  showConfirmSend = (ev) => {
    ev.preventDefault();
    this.setState({ showSendDialog: true });
  };

  hideConfirmSend = () => {
    this.setState({ showSendDialog: false });
  };

  createCampaign = () => {
    const { createCampaign } = this.props;
    const { resourceIds, name, sender, message, sendAtTime, sendDate, sendTime, includeOrphaned } = this.state;

    const scheduledDelivery = sendAtTime === 'later';
    const sendHour = sendTime.split(':')[0];
    // eslint-disable-next-line no-useless-escape
    const deliveryTime = moment(sendDate).startOf('day').hour(sendHour).format('YYYY-MM-DD\THH:mm:00');
    const lastBookedSince = this.getLastBookedDate().format('YYYY-MM-DD');
    const bookedUntilDate = this.getBookedUntilDate();
    const bookedUntil = bookedUntilDate ? bookedUntilDate.format('YYYY-MM-DD') : null;

    const data = {
      campaignName: name,
      scheduledDelivery,
      deliveryTime: scheduledDelivery ? deliveryTime : null,
      smsText: message,
      sender,
      resourceIds,
      lastBookedSince,
      includeOrphanedCustomers: includeOrphaned,
      bookedUntil
    };

    createCampaign(data);
  };
}

Send.propTypes = {
  resourcesById: PropTypes.object.isRequired,
  refreshRecipients: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
  showList: PropTypes.func.isRequired,
  sender: PropTypes.string.isRequired,
  smsBulkPrice: PropTypes.number.isRequired
};
