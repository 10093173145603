import Pusher from 'pusher-js';
import s from 'underscore.string';
import { pusherKey, pusherCluster } from './config';
import {
  bookingAdded,
  bookingChanged,
  bookingConfirmed,
  bookingCancelled,
  bookingRefunded,
  bookingDeleted,
  bookingFlagChanged,
  bookingMoved,
  bookingStatusChanged
} from '../state/booking-actions';
import { customerUpdated } from '../state/customer-actions';
import { configChanged } from '../state/account-actions';


export function initializePusher(dispatch, org, loc) {
  // NOTE! This is to ensure we disconnect when switching org&loc without reloading the webpage.
  //
  if (window.pusher) {
    window.pusher.disconnect();
  }
  const hostname = window.location.hostname;
  const pusher = window.pusher = new Pusher(pusherKey(hostname), { encrypted: true, cluster: pusherCluster(hostname) });

  pusher.connection.bind('connected', () => {
    if (window.pusherUnavailableSince) {
      const disconnectedTime = (new Date() - window.pusherUnavailableSince) / 1000;

      console.info(`Pusher reconnected after being unavailable or failed since ${window.pusherUnavailableSince}, for ${disconnectedTime} seconds`);
    }

    window.pusherUnavailableSince = null;
    window.pusherSocketId = pusher.connection.socket_id;

    console.info(`Pusher socket ID assigned, id: ${window.pusherSocketId}`);
  });

  pusher.connection.bind('failed', () => {
    window.pusherUnavailableSince = new Date();
    console.error('Pusher connection failed / This implies that WebSockets are not natively available and an HTTP-based transport could not be found.');
    Sentry.captureMessage('Pusher connection failed / This implies that WebSockets are not natively available and an HTTP-based transport could not be found.');
  });

  pusher.connection.bind('unavailable', () => {
    window.pusherUnavailableSince = new Date();
    console.error('Pusher connection unavailable / The connection is temporarily unavailable. In most cases this means that there is no internet connection. It could also mean that Pusher is down, or some intermediary is blocking the connection. In this state, Pusher will automatically retry the connection every ten seconds. connecting_in events will still be triggered.');
  });

  pusher.connection.bind('error', (err) => {
    // Yeah, this is ridiculous.. but sometimes err is something else than documented.
    //
    if (err && err.error && err.error.data && err.error.data.code === 4004) {
      console.error('Pusher connection limit reached');
      Sentry.captureMessage('Pusher connection limit reached');
    }
  });

  const channel = pusher.subscribe(`${org}_${loc}`);

  channel.bind('CREATE_BOOKING', (event) => {
    dispatch(bookingAdded(event.data.booking, 'remote'));
  });

  channel.bind('DELETE_BOOKING', (event) => {
    dispatch(bookingDeleted(event.entityId, 'remote'));
  });

  channel.bind('DELETE_UNCONFIRMED_BOOKING', (event) => {
    dispatch(bookingDeleted(event.entityId, 'remote'));
  });

  channel.bind('CANCEL_BOOKING', (event) => {
    dispatch(bookingCancelled(event.entityId, event.data.booking, 'remote'));
  });

  channel.bind('REFUND_BOOKING', (event) => {
    dispatch(bookingRefunded(event.entityId, event.data.refundResult, 'remote'));
  });

  channel.bind('MOVE_BOOKING', (event) => {
    dispatch(bookingMoved(event.data.moveEvent, 'remote'));
  });

  channel.bind('CONFIRM_BOOKING', (event) => {
    dispatch(bookingConfirmed(event.entityId, event.data.booking, 'remote'));
  });

  channel.bind('CHANGE_BOOKING_DETAILS', (event) => {
    dispatch(bookingChanged(event.entityId, event.data.booking, 'remote'));
  });

  channel.bind('CHANGE_BOOKING_STATUS', (event) => {
    const change = {
      bookingId: event.entityId,
      status: event.data.status
    };
    dispatch(bookingStatusChanged(change, 'remote'));
  });

  channel.bind('SET_BOOKING_FLAG', (event) => {
    const flags = [],
      flag = s.decapitalize(event.data.flag),
      bookingId = event.entityId;
    flags[flag] = true;

    dispatch(bookingFlagChanged({
      bookingId,
      flags
    }, 'remote'));
  });

  channel.bind('UNSET_BOOKING_FLAG', (event) => {
    const flags = [],
      flag = s.decapitalize(event.data.flag),
      bookingId = event.entityId;
    flags[flag] = false;

    dispatch(bookingFlagChanged({
      bookingId,
      flags
    }, 'remote'));
  });

  channel.bind('MODIFY_CUSTOMER', (event) => {
    dispatch(customerUpdated(event.entityId, event.data.customer, 'remote'));
  });

  channel.bind('CHANGE_PREFS', (event) => {
    dispatch(configChanged(event.data, 'remote'));
  });
}

