import Immutable from 'immutable';

export function convertExceptions(exceptions){

   const ff = Immutable.List(exceptions).sortBy(exception => exception.start);

   const grouped = ff.groupBy(function(e){
      return e.date;
   });
   const result = [];

   for(let k of grouped.keys()){
      let values = grouped.get(k);
      let eg = {
         date: k,
         closed: []
      };
      let closed = {
         start:null,
         end:null
      };

      values.forEach(function (value, idx) {
         if(closed.start){
            closed.end = value.start;
         }else{
            closed.start = value.end;
         }

         if(idx == values.size -1){
            eg.end = value.end;
            eg.type = value.type;
         }

         if (closed.start != null && closed.end != null) {
            eg.closed.push(closed);
            closed = {
               start: value.end,
               end: null
            };
         }

         eg.start = eg.start ? eg.start : value.start;
      });

      result.push(eg);
   }
   return result;
}


export function test() {

   const exceptions = [
      {
         type: "OPEN_PARTIAL",
         date: "2016-12-24",
         start: "08:00",
         end: "12:00"
      },
      {
         type: "OPEN_PARTIAL",
         date: "2016-12-24",
         start: "14:15",
         end: "15:00"
      },
      {
         type: "OPEN_PARTIAL",
         date: "2016-12-24",
         start: "13:00",
         end: "14:00"
      },
      {
         type: "CLOSED_ALLDAY",
         start: null,
         end: null,
         date: "2016-12-25"
      }];

   console.log(convertExceptions(exceptions));
}