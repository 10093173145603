var View = require('../../base/view');

var CustomerListItemView = View.extend({
   noWrap: true,
   autoRender: true,

   initialize: function() {
      this.template = require('./list-item');
      View.prototype.initialize.call(this, arguments);
      this.pass('.js-name','name');
      this.pass('.js-phone','phoneNumber');
   },

   itemClick: function(ev) {
      this.$el.parent().find('.list-group-item').removeClass('active');
      this.$el.addClass('active');

      this.publishEvent('customers:itemSelected', this.model);
      return false;
   },

   render: function(){
      View.prototype.render.call(this, arguments);
      this.delegate('click', this.itemClick);
   }
});

module.exports = CustomerListItemView;