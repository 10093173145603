import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { msgConfirmDeleteDialog as msg } from '../../localization/messages/components/campaign';

class ConfirmDeleteDialog extends Component {

  render() {
    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.heading} /></h2>
          <hr />
          <p><FormattedMessage {...msg.description} /></p>
          <hr />
          <div className="text-right">
            <button onClick={this.close} className="button" style={{ marginRight: 5 }}>
              <FormattedMessage {...msg.btnClose} />
            </button>
            <button onClick={this.confirm} className="button">
              <FormattedMessage {...msg.btnConfirm} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  close = (ev) => {
    ev.preventDefault();
    this.props.onClose();
  };

  confirm = (ev) => {
    ev.preventDefault();
    this.props.onConfirm();
  };
}

ConfirmDeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmDeleteDialog;
