import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { authenticate } from './actions';
import { navigate } from '../redux-dashl/utils/navigate';
import { isRunningLocal } from '../redux-dashl/utils/config';

import { msgLoginForm as msg } from '../redux-dashl/localization/messages/redux-login/redux-login';

class LoginForm extends Component {

  state = {
    username: '',
    password: '',
    authenticationFailed: false
  }

  handleUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  handlePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  handleLogin = (e) => {
    e.preventDefault();

    const { auth } = this.props;
    const { username, password } = this.state;

    auth({
      username,
      password
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.authFailed) {
      this.setState({ password: '' });
    }
    if (nextProps.authenticating) {
      this.setState({ authenticationFailed: false });
    }
    if (!nextProps.authenticating && (nextProps.authFailed || nextProps.networkFailed)) {
      this.setState({ authenticationFailed: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.authFailed && !prevProps.authFailed) {
      this.passwordInput.focus();
    }
  }

  render() {
    if (!isRunningLocal() || this.props.authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className="loginContainer">
        <div className="login">
          <p className="text-center">
            <img src="/dashl_logo_black.png" alt="Dashl" style={{ height: 25 }} />
          </p><br />
          {this.loginForm()}
        </div>
      </div>
    );
  }

  loginForm() {
    const { authenticating, authFailed, networkFailed, lastTrymessage, intl: { formatMessage: f } } = this.props;
    const { username, password, authenticationFailed } = this.state;

    const loginText = authenticating ? f(msg.loggingIn) : f(msg.logIn);
    const formFilled = username.length > 0 && password.length > 0;
    const formClassName = authenticationFailed ? 'form animated shake' : 'form';
    const buttonClassName = (authenticating || !formFilled) ? 'btn btn-primary disabled' : 'btn btn-primary';

    let message;
    if (authenticationFailed && authFailed && !formFilled) {
      message = <p className="text-danger"><i className="fa fa-fw fa-exclamation-circle" /> {f(msg.missAuthData)}</p>;
    } else if (authenticationFailed && networkFailed) {
      message = <p className="text-danger"><i className="fa fa-fw fa-exclamation-circle" /> {f(msg.connectionError, { lastTrymessage })}</p>;
    } else {
      message = '';
    }

    return (
      <div className={formClassName}>
        {message}<br />
        <form onSubmit={this.handleLogin}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder={f(msg.email)}
              ref={obj => this.usernameInput = obj}
              tabIndex="1"
              onChange={this.handleUsername}
              value={username}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder={f(msg.password)}
              ref={obj => this.passwordInput = obj}
              tabIndex="2"
              onChange={this.handlePassword}
              value={password}
            />
          </div><br />
          <button type="submit" className={buttonClassName} tabIndex="4">{loginText}</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ authState }) => {
  const authData = authState.toJS();

  return {
    ...authData
  };
};

const mapDispatchToProps = dispatch => ({
  auth: data => dispatch(authenticate(data)).then(() => navigate('/'))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
