import { useIntl } from 'react-intl';

let intl;

export function IntlContextInterseptor({ children }) {
  intl = useIntl();
  return children;
}

export function formatMessage(...args) {
  return intl && intl.formatMessage(...args);
}

export const getLocale = () => intl && intl.locale;
