var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "       <em> - "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"changed",{"name":"i18n","hash":{},"data":data}))
    + " "
    + alias3((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.lastUpdate : depth0),{"name":"DateTime","hash":{},"data":data}))
    + "</em>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div>\n   <hr>\n   <div class=\"pull-right\">\n      <a href=\"#\" class=\"js-edit\"><i class=\"fa fa-pencil text-muted\"></i></a>\n      <a href=\"#\" class=\"js-delete\"><i class=\"fa fa-trash text-muted\"></i></a>\n   </div>\n   <p class=\"text-wrap\">"
    + ((stack1 = ((helper = (helper = helpers.comment || (depth0 != null ? depth0.comment : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"comment","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n   <span class=\"text-muted\">\n     <em>"
    + container.escapeExpression((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),{"name":"DateTime","hash":{},"data":data}))
    + "</em>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isUpdated : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "   </span>\n</div>\n";
},"useData":true});