import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleBKFPrintModal } from '../../state/bkf-actions';
import { formatPhoneNumber } from '../../../utils/phone-util';
import Modal from '../common/modal';

import { msgBookingPrintModal as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingPrintModal extends Component {
  componentDidMount() {
    document.body.classList.add('print-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('print-hidden');
  }

  onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  render() {
    const { show, onClose, companyName, icsLocation, contactNumber, resourceName,
       startTime, endTime, service, customer, company, note, intl: { formatMessage: f } } = this.props;
    const title = f(msg.bookingAt, { companyName });
    const serviceName = service && service.service;

    return show && startTime ? (
      <Modal
        titleText={title}
        focusDialog
        underlayClickExits
        mounted={!!show}
        onExit={onClose}
        underlayClass="dialog-container"
        dialogClass="dialog-content"
      >
        <div className="print-visible">
          <h2>{title}</h2>
          {startTime.format('LLLL')} - {endTime.format('HH:mm')}
          <hr />
          <p>
            {serviceName && <span>{f(msg.service, { serviceName })}<br /></span>}
            {f(msg.resource, { resourceName })}
          </p>
          {customer && (
            <p>
              <br />
              <strong>{f(msg.customerDetails)}</strong><br />
              {customer.name && <span>{f(msg.name, { name: customer.name })}<br /></span>}
              {customer.phoneNumber && <span>{f(msg.phoneNumber, { phoneNumber: formatPhoneNumber(customer.phoneNumber) })}<br /></span>}
              {customer.email && <span>{f(msg.email, { email: customer.email })}<br /></span>}
              {company && company.orgName && <span>{f(msg.orgName, { orgName: company.orgName })}</span>}
            </p>
          )}
          {note && (
            <p>
              <br />
              <strong>{f(msg.note)}</strong><br />
              <span className="text-wrap">{note}</span>
            </p>
          )}
          {(icsLocation || contactNumber) && (
            <p>
              <br />
              <strong>{companyName}</strong><br />
              {icsLocation && <span>{icsLocation}<br /></span>}
              {contactNumber && <span>{f(msg.phoneNumber, { phoneNumber: contactNumber })}</span>}
            </p>
          )}
        </div>
        <div className="print-hidden">
          <hr />
          <button onClick={this.props.onClose} className="button pull-right">{f(msgButtons.btnClose)}</button>
          <button onClick={this.onPrint} className="button">{f(msgButtons.btnPrint)}</button>
        </div>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig } = state;

  return {
    show: bkf.get('showPrintModal'),
    companyName: locationConfig && locationConfig.get('companyName'),
    contactNumber: locationConfig && locationConfig.get('contactNumber'),
    icsLocation: locationConfig && locationConfig.get('icsLocation'),
    resourceName: bkf.get('resourceName'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    customer: bkf.get('customer'),
    company: bkf.get('company'),
    service: bkf.get('service'),
    note: bkf.get('note'),
    ...bkf.get('attributes')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(toggleBKFPrintModal(false))
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BookingPrintModal));
