import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { msgBookingDetails as msg } from '../../localization/messages/components/booking';

class BookingDetails extends Component {
  render() {
    const { id, customer, channel, cancelledChannel, smsStatus, smsStatusUpdateTime, createdTime, lastUpdateTime,
      cancelledTime, bookedAs, payment, intl: { formatMessage: f } } = this.props;
    const { paidTs, refundTs } = payment || {};
    const { name } = customer || {};
    const isNew = id === 'DRAGGER';

    return (
      <div className="booking-form-details">
        {!isNew && (
          <div>
            <small className="text-muted">
              <i className="fa fa-info-circle" /> {f(msg.reservationNumber, { id })}
            </small>
          </div>
        )}
        {bookedAs && bookedAs !== name && (
          <div>
            <small className="text-warning">
              <i className="fa fa-user" /> {f(msg.bookedAs, { bookedAs })}
            </small>
          </div>
        )}
        {createdTime && (
          <div>
            <small className="text-muted">
              {this.channelIcon()} {f(msg.bookingAction, { action: 'create', dateTime: createdTime.format('LLL'), channel })}
            </small>
          </div>
        )}
        {lastUpdateTime && (
          <div>
            <small className="text-muted">
              <i className="fa fa-clock-o" /> {f(msg.updated, { lastUpdateTime: lastUpdateTime.format('LLL') })}
            </small>
          </div>
        )}
        {this.smsSent() && (
          <div>
            <small className="text-muted">
              <i className="fa fa-check-circle" /> {f(msg.smsStatus, { smsStatus, smsStatusUpdateTime: smsStatusUpdateTime.format('LLL') })}
            </small>
          </div>
        )}
        {cancelledTime && (
          <div>
            <small className="text-danger">
              <i className="fa fa-exclamation-circle" /> {f(msg.bookingAction, { action: 'cancel', dateTime: cancelledTime.format('LLL'), channel: cancelledChannel })}
            </small>
          </div>
        )}
        {paidTs && (
          <div>
            <small className="text-muted">
              <i className="fa fa-check-circle" /> {f(msg.paid, { paidTs: moment(paidTs).format('LLL') })}
            </small>
          </div>
        )}
        {refundTs && (
          <div>
            <small className="text-muted">
              <i className="fa fa-arrow-circle-left" /> {f(msg.refunded, { refundTs: moment(refundTs).format('LLL') })}
            </small>
          </div>
        )}
      </div>
    );
  }

  channelIcon() {
    switch (this.props.channel) {
      case 'App':
      case 'Web':
      case 'WebDashl':
      case 'WebDashlPopup':
        return <i className="fa fa-globe" />;
      default:
        return <i className="fa fa-clock-o" />;
    }
  }

  smsSent() {
    switch (this.props.smsStatus) {
      case 'SENT_PENDING':
      case 'DELIVERED':
        return true;
      default:
        return false;
    }
  }
}

export default injectIntl(BookingDetails);
