import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CurrencyUtil from '../../../utils/currency-util';

import { msgConfirmSendDialog as msg } from '../../localization/messages/components/campaign';
import { msgButtons } from '../../localization/messages/shared/buttons';

class ConfirmSendDialog extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      confirmed: false
    };
  }

  render() {
    const { message, recipients, cost, sendAtTime, sendDate, sendTime } = this.props;
    const { confirmed } = this.state;

    const sendHour = sendTime.split(':')[0];
    const deliveryTime = moment(sendDate).startOf('day').hour(sendHour).format('LLLL');

    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.heading} /></h2>
          <hr />
          <p><FormattedMessage {...msg.sendMessageQuestion} /></p>
          <div className="well well-sm text-wrap"><strong>{message}</strong></div>
          <p>
            <FormattedMessage
              {...msg.explanationText}
              values={{
                recipients,
                deliveryTime,
                isNow: sendAtTime === 'now',
                b: (...chunks) => <strong>{chunks}</strong> }}
            />
          </p>
          <div className="text-center">
            <h2>
              <span className="label label-success">
                <FormattedMessage {...msg.amount} values={{ cost: CurrencyUtil.accountCurrency(cost) }} />
              </span>
            </h2>
          </div>
          <br />
          <div className="checkbox">
            <label>
              <input type="checkbox" onChange={this.confirmCheckboxChanged} />
              <FormattedMessage {...msg.lblAgree} />
            </label>
          </div>
          <hr />
          <div className="text-right">
            <button onClick={this.close} className="button" style={{ marginRight: 5 }}><FormattedMessage {...msgButtons.btnCancel} /></button>
            <button onClick={this.confirm} className="button arrow" disabled={!confirmed}><FormattedMessage {...msg.btnConfirm} /></button>
          </div>
        </div>
      </div>
    );
  }

  confirmCheckboxChanged = (ev) => {
    this.setState({ confirmed: ev.target.checked });
  };

  close = (ev) => {
    ev.preventDefault();
    this.props.onClose();
  };

  confirm = (ev) => {
    ev.preventDefault();
    this.props.onConfirm();
  };
}

ConfirmSendDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  sendAtTime: PropTypes.string.isRequired,
  sendDate: PropTypes.object.isRequired,
  sendTime: PropTypes.string.isRequired
};

export default ConfirmSendDialog;
