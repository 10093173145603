import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal from '../common/modal';
import FeaturesConfig from '../account-config/features-config';
import { dismissOnboarding } from '../../state/account-actions';

import { msgFeaturesOnboardingModal as msg } from '../../localization/messages/components/onboarding';

const FeaturesOnboardingModal = ({ showOnboarding, dismissOnboarding }) => {
  return (
    <Modal
      titleText="Error"
      underlayClickExits={false}
      includeDefaultStyles={false}
      dialogClass="dialog-content onboarding"
      underlayClass="dialog-container"
      initialFocus=".dialog-content"
      onExit={dismissOnboarding}
      mounted={showOnboarding}
    >
      <div>
        <h2><FormattedMessage {...msg.welcome} /></h2>
        <p className="lead">
          <FormattedMessage {...msg.chooseOption} />
        </p>
        <FeaturesConfig />
        <p className="text-muted">
          <FormattedMessage {...msg.hardToDecide} />
        </p>
        <div className="text-center">
          <br />
          <button onClick={dismissOnboarding} className="arrow">
            <FormattedMessage {...msg.btnStartUsing} />
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { locationConfig, locationFeatures } = state;

  return {
    showOnboarding: !locationConfig.get('onboarded') && locationFeatures.get('ShowAddons')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissOnboarding: () => {
      dispatch(dismissOnboarding());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesOnboardingModal);
