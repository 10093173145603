export function clearSettings() {
  localStorage.removeItem('highContrast');
  localStorage.removeItem('gridSize');
}

export function saveGridSize(size) {
  localStorage.setItem('gridSize', size);
}

export function getGridSize() {
  const gridSize = localStorage.getItem('gridSize');
  if (gridSize == null) {
    return 'small';
  }
  return gridSize;
}

export function saveHighContrastEnabled(enabled) {
  localStorage.setItem('highContrast', enabled);
}

export function isHighContrastEnabled() {
  return localStorage.getItem('highContrast') === 'true';
}

export function saveExternalKeyboardEnabled(enabled) {
  localStorage.setItem('externalKeyboard', enabled);
}

export function isExternalKeyboardEnabled() {
  return localStorage.getItem('externalKeyboard') === 'true';
}

