const moment = require('moment');
const DirtyTrackedFormView = require('../../base/dirty-tracked-form-view');
const ConfirmDeletePopoverView = require('../../base/confirm-popover-view');
const BookingsView = require('./booking-history/bookings-view');
const CustomerLogView = require('./log/log-view');
const PhoneUtil = require('../../../utils/phone-util');

const { formatMessage, getLocale } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

const formatTime = (createdDate, updatedDate) => {
  const locale = getLocale() || 'sv';
  let str = '';

  const created = moment(createdDate).locale(locale);
  const updated = moment(updatedDate).locale(locale);

  if (locale === 'de') {
    const format = (date) => {
      const weekday = date.format('dddd').toLowerCase();
      const day = date.format('D').toLowerCase();
      const month = date.format('MMMM').toLowerCase();
      const year = date.format('YYYY');
      const time = date.format('HH:mm');

      return `${weekday}, den ${day}. ${month} ${year} um ${time} uhr`;
    };

    const str = `erstellt am ${format(created)}, letzte aktivität am ${format(updated)}`;
  } else {
    const createdTime = moment(createdDate).format('LLLL');
    const updatedTime = moment(updatedDate).format('LLLL');
    str = `${formatMessage(msgHandlebars.created)} ${createdTime} - ${formatMessage(msgHandlebars.recentActivity)} ${updatedTime}`;
  }

  return str;
};

module.exports = DirtyTrackedFormView.extend({
  noWrap: true,
  autoRender: true,
  region: 'customer-item-region',

  events: {
    'click .js-delete': 'confirmDelete'
  },

  initialize() {
    this.template = require('./container');
    DirtyTrackedFormView.prototype.initialize.call(this, arguments);
  },

  render() {
    DirtyTrackedFormView.prototype.render.call(this, arguments);
    this._renderSubviews();
  },

  _renderSubviews() {
    if (this.model.id) {
      this.subview(
        'customer-log',
        new CustomerLogView({
          model: this.model,
          container: this.$('#customer-log-container')
        })
      );
      this.subview(
        'customer-bookings',
        new BookingsView({
          model: this.model,
          container: this.$('#customer-bookings-container')
        })
      );
    }
  },

  save(e) {
    e.preventDefault();
    const id = this.model.get('id');
    const customer = this.getFormData();

    if (id) {
      this.publishEvent('redux:updateCustomer', id, customer);
    } else {
      this.publishEvent('redux:createCustomer', customer);
    }
    this.saved(customer);

    return false;
  },

  getFormData() {
    const formData = this.$('form').serializeJSON({ parseNumbers: false });
    return {
      name: formData.name,
      phoneNumber: PhoneUtil.formatPhoneNumberE164(formData.phoneNumber),
      smsReminders: formData.smsReminders,
      emailReminders: formData.emailReminders,
      allowMarketing: formData.allowMarketing,
      email: formData.email,
      gender: formData.gender,
      otherPhoneNumber: PhoneUtil.formatPhoneNumberE164(formData.otherPhoneNumber),
      dateOfBirth: formData.dateOfBirth,
      address1: formData.address1,
      address2: formData.address2,
      address3: formData.address3,
      area: formData.area,
      postCode: formData.postCode,
      country: formData.country,
      notes: formData.notes
    };
  },

  show(item) {
    this.model = item;
    this.populate(item);

    if (item.id) {
      this.subview('customer-log').show(item);
      this.subview('customer-bookings').show(item);
    }
  },

  populate(item) {
    DirtyTrackedFormView.prototype.populate.call(this, item);

    const created = item.get('created');
    const updated = item.get('lastUpdate');
    this.$('#customer-id').text(item.id);
    this.$('#customer-updated').text(formatTime(created, updated));
  },

  saved(data) {
    this.model.set(data);
    this.resetDirty();
  },

  confirmDelete(e) {
    e.preventDefault();

    this.confirmationView = new ConfirmDeletePopoverView({
      title: formatMessage(msgHandlebars.deleteCustomerTitle),
      confirmText: formatMessage(msgHandlebars.deleteCustomerText),
      additionalText: formatMessage(msgHandlebars.deleteCustomerAdditionalText),
      buttonText: formatMessage(msgHandlebars.deleteCustomerTitle),
      parent: $(e.currentTarget)
    });
    this.subview('popover', this.confirmationView);

    $.ajax({
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      url: `/customers/${this.model.id}/futurebookings`,
      success: _.bind(this.refreshConfirmDelete, this)
    });

    return false;
  },

  refreshConfirmDelete(data) {
    let additionalText = formatMessage(msgHandlebars.noUpcomingBookings);

    if (data.futureBookings == 1) {
      additionalText = formatMessage(msgHandlebars.oneUpcomingBooking);
    }
    if (data.futureBookings > 1) {
      additionalText = formatMessage(msgHandlebars.fewUpcomingBookings, {
        futureBookings: data.futureBookings
      });
    }

    this.confirmationView.update({
      additionalText,
      callback: _.bind(this.delete, this)
    });
  },

  delete() {
    this.confirmationView.close();
    this.publishEvent('customers:delete', this.model);
  }
});
