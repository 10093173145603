import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { msgResourceSubNav as msg } from '../../localization/messages/components/resources';

const NavItem = ({ navId, name, org, loc, subsection, id }) => {
  const url = `/${org}/${loc}/resources/${id}/${navId}`;
  const classes = subsection === navId ? 'active' : '';

  return (
    <li className={classes}>
      <Link to={url}>{name}</Link>
    </li>
  );
};

NavItem.propTypes = {
  navId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  subsection: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

class ResourceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  sections() {
    const { intl: { formatMessage: f }, locationFeatures } = this.props;
    const features = locationFeatures.toJS();
    const sections = [];

    features.ShowGeneralSettings && sections.push({ navId: 'settings', name: f(msg.settings) });
    features.ShowServicesSettings && sections.push({ navId: 'services', name: f(msg.services) });
    features.ShowScheduleSettings && sections.push({ navId: 'schedule', name: f(msg.shedule) });

    return sections;
  }

  render() {
    const { routeParams } = this.props;

    return (
      <nav className="navbar navbar-static-top navbar-tabs">
        <ul className="nav nav-tabs">
          {this.sections().map(section => (
            <NavItem key={section.navId} {...section} {...routeParams} />
          ))}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  const { locationFeatures } = state;

  return {
    locationFeatures
  };
};

export default injectIntl(connect(mapStateToProps)(ResourceSubNav));

