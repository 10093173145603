import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getSectionUrl } from '../../utils/navigate';
import { shouldShowStorageConfigAlert, setStorageConfigDismissed } from '../../utils/account';
import { hasPermission, CHANGE_LOCATION_SETTINGS } from '../../utils/permissions';
import { getPermissions } from '../../utils/selectors';

import { msgStorageConfigAlert as msg } from '../../localization/messages/components/dialogs';

class StorageConfigAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShow: shouldShowStorageConfigAlert()
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({
        shouldShow: shouldShowStorageConfigAlert()
      });
    }
  }

  dismiss = (ev) => {
    ev.preventDefault();
    setStorageConfigDismissed();
    this.setState({ shouldShow: false });
  };

  render() {
    const { shouldShow } = this.state;
    const { isAccountConfig, preferencesUpdated, permissions, match } = this.props;
    const storageConfigUrl = getSectionUrl(match.params, 'account-config', 'storage');
    const isPermitted = hasPermission(permissions, CHANGE_LOCATION_SETTINGS);

    return shouldShow && isPermitted && !preferencesUpdated && !isAccountConfig && (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.heading} /></h2>
          <hr />
          <p>
            <FormattedMessage {...msg.newRegulation} />
          </p><br />
          <p>
            <FormattedMessage {...msg.checkSettings} />
          </p><br />
          <p className="fine-print">
            <FormattedMessage {...msg.hiddenMessage} values={{ greaterThan: <Fragment>&gt;</Fragment> }} />
          </p>
          <hr />
          <a href="#" className="alternative-link pull-right" onClick={this.dismiss}><FormattedMessage {...msg.btnRemindLater} /></a>
          <Link to={storageConfigUrl} className="button arrow"><FormattedMessage {...msg.btnCheckSettings} /></Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { match }) => {
  return {
    isAccountConfig: match.params.section === 'account-config',
    preferencesUpdated: !!state.locationConfig.get('preferencesUpdated'),
    permissions: getPermissions(state, { routeParams: match.params })
  };
};

export default connect(mapStateToProps)(StorageConfigAlert);
