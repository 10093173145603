import { defineMessages } from 'react-intl';

export const msgFeaturesOnboardingModal = defineMessages({
  welcome: {
    id: 'onboarding.FeaturesOnboardingModal.welcome',
    defaultMessage: 'Välkommen till Dashl!'
  },
  chooseOption: {
    id: 'onboarding.FeaturesOnboardingModal.chooseOption',
    defaultMessage: 'Välj tillval för att  anpassa Dashl till din verksamhet.'
  },
  hardToDecide: {
    id: 'onboarding.FeaturesOnboardingModal.hardToDecide',
    defaultMessage: 'Svårt att bestämma nu? Slå på eller av tillvalen senare under Inställningar - Tillval.'
  },
  btnStartUsing: {
    id: 'onboarding.FeaturesOnboardingModal.btnStartUsing',
    defaultMessage: 'Börja använda Dashl'
  }
});
