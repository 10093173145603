import { defineMessages } from 'react-intl';

export const msgTemplates = defineMessages({
  defaultSmsConfirmTemplate: {
    id: 'utils.templates.defaultSmsConfirmTemplate',
    defaultMessage: 'Tack för din bokning hos #RESURS #TIDPUNKT. Välkommen!'
  },
  defaultSmsReminderTemplate: {
    id: 'utils.templates.defaultSmsReminderTemplate',
    defaultMessage: 'Hej! Glöm inte din din bokning hos #RESURS #TIDPUNKT. Välkommen!'
  }
});

export const msgValidation = defineMessages({
  required: {
    id: 'utils.validation.required',
    defaultMessage: 'Måste fyllas i'
  },
  minLength: {
    id: 'utils.validation.minLength',
    defaultMessage: 'Måste vara minst {min} tecken'
  },
  maxLength: {
    id: 'utils.validation.maxLength',
    defaultMessage: 'Får inte vara mer än {max} tecken'
  },
  pattern: {
    id: 'utils.validation.pattern',
    defaultMessage: 'Måste matcha {pattern}'
  },
  minDuration: {
    id: 'utils.validation.minDuration',
    defaultMessage: 'Måste vara minst {min}'
  },
  maxDuration: {
    id: 'utils.validation.maxDuration',
    defaultMessage: 'Får inte vara mer än {max}'
  },
  number: {
    id: 'utils.validation.number',
    defaultMessage: 'Måste vara ett nummer'
  },
  maxValue: {
    id: 'utils.validation.maxValue',
    defaultMessage: 'Får vara högst {max}'
  },
  symbols: {
    id: 'utils.validation.symbols',
    defaultMessage: 'Får endast innehålla bokstäver (ej åäö), siffror och understreck (_)'
  },
  resourceWarnings: {
    id: 'utils.validation.resourceWarnings',
    defaultMessage: 'En eller flera resurser har egna inställningar med andra värden'
  }
});
