import axios from 'axios';
import CurrencyUtil from '../../utils/currency-util';
import PhoneNumberParser from '../../utils/phone-number-parser';
import {
  axiosDefault, axiosErrorHandler, checkStatus, checkStatusAxios, fetchErrorHandler, fetchGet,
  prefixUrl
} from '../utils/ajax-util';
import { loading, loadingDone } from './network-actions';
import { setUserPreferences } from './user-actions';

export const ACCOUNT_STATUS = 'ACCOUNT_STATUS';
export const LOC_CONFIG_CHANGED = 'LOC_CONFIG_CHANGED';
export const LOADING_CONFIG = 'LOADING_CONFIG';
export const RECEIVE_LOC_OPTIONS = 'RECEIVE_LOC_OPTIONS';
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';
export const FEATURES_LOADED = 'FEATURES_LOADED';

export function changeAccountStatus(accountStatus, trialStatus, trialUntil, features) {
  return {
    type: ACCOUNT_STATUS,
    state: {
      accountStatus,
      trialStatus,
      trialUntil,
      features
    }
  };
}

export function receiveOptions(locations) {
  return {
    type: RECEIVE_LOC_OPTIONS,
    state: {
      locations
    }
  };
}

export function fetchLocationOptions() {
  const url = prefixUrl('/auth/locations/');

  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(res => dispatch(receiveOptions(res)))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function loadingConfig() {
  return {
    type: LOADING_CONFIG
  };
}

export function clearLocationState() {
  return {
    type: CLEAR_LOCATION_STATE
  };
}
export function featuresLoaded(features, source = 'local') {
  return {
    type: FEATURES_LOADED,
    features,
    source
  };
}


export function configChanged(prefs, source = 'local') {
  if (prefs.preferredE164CountryCode) {
    PhoneNumberParser.initialize(prefs.preferredE164CountryCode, '0', '00');
  }

  // This will set the account currency used for chaplin code (handlebars)
  if (prefs.currencyCode) {
    CurrencyUtil.initialize(prefs.currencyCode);
  }

  return {
    type: LOC_CONFIG_CHANGED,
    state: prefs,
    source
  };
}


export function fetchLocationConfig() {
  const url = prefixUrl('/location/configuration');
  const config = axiosDefault();

  return (dispatch) => {
    dispatch(loadingConfig());

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        dispatch(featuresLoaded(res.features));
        dispatch(configChanged(res.configuration));
        dispatch(setUserPreferences(res.clientPrefs));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function dismissOnboarding() {
  const url = prefixUrl('/location/onboarded');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, null, config)
      .then(() => dispatch(configChanged({ onboarded: true })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
