module.exports = {

    populate:function(frm, data) {
      _.each(data, function(value, key){
         if (_.keys(value).length > 0) {
            _.each(value, function(subValue, subKey) {
               this._populateField(frm, key + '.' + subKey, subValue);
            }, this);
         }
         else{
            this._populateField(frm, key, value);
         }
      }, this);
   },

   _populateField: function(frm, key, value){
      var $ctrl = $('[name="'+key+'"]', frm);
      switch($ctrl.attr("type"))
      {
         case "text" :
         case "email":
         case "hidden":
            $ctrl.val(value);
            break;
         case "radio" :
            $ctrl.each(function() {
               var strVal = (value === null || value === undefined) ? '' : value;
               var checked = $(this).val() == strVal.toString();
               $(this).prop('checked', checked);
            });
            break;
         case "checkbox":
            $ctrl.each(function(){
               $(this).prop('checked', value);
            });
            break;
         default:
            $ctrl.val(value);
      }
   },

	reset: function($form) {
      // todo reset value for email input
		$form.find('input:text, textarea').val('');
		$form.find('input:radio, input:checkbox')
			.removeAttr('checked').removeAttr('selected');
	}

};
