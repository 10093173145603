import moment from 'moment/moment';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getDateUrl } from '../../utils/navigate';

import { msgDateNavigator as msg } from '../../localization/messages/components/calendar';

export default class DateNavigator extends React.Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    viewDateStartEnd: PropTypes.object.isRequired
  };

  render() {
    const { routeParams } = this.props;

    return (
      <div className="navbar-date-navigator">
        <Link to={getDateUrl('back', routeParams)} className="btn no-outline btn-label">
          <i className="fa fa-chevron-left" />
        </Link>
        <Link to={getDateUrl('today', routeParams)} className="btn no-outline btn-label today">
          <FormattedMessage {...msg.today} />
        </Link>
        <Link to={getDateUrl('forward', routeParams)} className="btn no-outline btn-label">
          <i className="fa fa-chevron-right" />
        </Link>
      </div>
    );
  }

  checkIfToday() {
    const { viewDateStartEnd } = this.props;
    const currentView = moment.range(viewDateStartEnd.start, viewDateStartEnd.end);
    return currentView.contains(moment());
  }
}
