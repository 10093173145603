var View = require('../../../base/view');

const { formatMessage } = require('../../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = View.extend({
  noWrap: true,
  autoRender: true,

  initialize() {
    this.template = require('./booking-item');
    View.prototype.initialize.call(this, arguments);
  },

  render() {
    var statusClassNames;
    var statusDescription;

    switch (this.model.attributes.status) {
      case "Booked":
        statusClassNames = 'label label-default';
        statusDescription = '';
        break;
      case "Show":
        statusClassNames = 'label label-default active label-status-show';
        statusDescription = formatMessage(msgHandlebars.show);
        break;
      case "NoShow":
        statusClassNames = 'label label-default active label-status-no-show';
        statusDescription = formatMessage(msgHandlebars.noShow);
        break;
      case "Cancelled":
        statusClassNames = 'label label-default';
        statusDescription = formatMessage(msgHandlebars.cancelled);
        break;
    }

    // Map services list to description field
    //
    var services = this.model.attributes.services;
    if (services && services.length > 0) {
      this.model.attributes.description = services[0].name;
    }

    this.model.attributes.statusClassNames = statusClassNames;
    this.model.attributes.statusDescription = statusDescription;

    View.prototype.render.call(this, arguments);
  }

});
