var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return " <div>\n   <form>\n      <div class=\"well well-sm form-well bg-info js-no-duplicates\">\n         "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"noDuplicates",{"name":"i18n","hash":{},"data":data}))
    + "\n      </div>\n      <div class=\"well well-sm form-well bg-info js-duplicates\">\n         "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"noDuplicatesDescription",{"name":"i18n","hash":{},"data":data}))
    + "\n      </div>\n      <div class=\"well well-sm js-duplicates\">\n         <div><strong>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"numberOfDuplicates",{"name":"i18n","hash":{},"data":data}))
    + ": </strong>"
    + alias3(((helper = (helper = helpers.duplicateCount || (depth0 != null ? depth0.duplicateCount : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"duplicateCount","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n      <div class=\"pull-right\">\n         <button type=\"button\" class=\"btn-cancel js-cancel\" tabindex=\"2\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"close",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n         <button type=\"submit\" class=\"btn-save js-duplicates\" tabindex=\"1\" data-loading-text=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"removingDuplicates",{"name":"i18n","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"removeDuplicates",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n      </div>\n   </form>\n</div>\n\n\n";
},"useData":true});