import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { createValidator, required, timePeriod } from '../../../utils/validation';
import { getSectionUrl } from '../../../utils/navigate';
import PeriodInput from '../../inputs/period-input';
import SubmitButton from '../../common/submit-button';
import { msgStorageConfigForm as msg } from '../../../localization/messages/components/account-config';

const StorageConfigForm = (props) => {
  const { handleSubmit, pristine, autoPurgeEnabled, preferencesUpdated, purgeLogsEnabled, match } = props;
  const contactsUrl = getSectionUrl(match.params, 'account-config', 'contacts');
  const { formatMessage: f } = useIntl();

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      {!preferencesUpdated && (
      <div className="alert alert-warning lead">
        {f(msg.alert)}
      </div>
        )}
      <br />

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-9">
          <div className="checkbox">
            <label>
              <Field name="autoPurgeEnabled" component="input" type="checkbox" />
              <strong>{f(msg.lblAutoPurgeEnabled)}</strong>
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-offset-3 col-sm-6">
          <p className="text-muted">
            {f(msg.explanationFirst)}
          </p>
          <p className="text-muted">
            {f(msg.explanationSecond)}
          </p>
          <p className="text-muted">
            {f(msg.explanationThird)} <Link to={contactsUrl}>{f(msg.explanationLink)}</Link>.
          </p>
        </div>
      </div>
      <br /> <br />

      {autoPurgeEnabled && (
      <div>
        <Field
          name="bookingMaxAge"
          label={f(msg.lblBookingMaxAge)}
          labelAfter={f(msg.lblAfterBookingMaxAge)}
          description={f(msg.descriptionBookingMaxAge)}
          component={PeriodInput}
        /><br />
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-9">
            <div className="checkbox">
              <label>
                <Field name="purgeLogsEnabled" component="input" type="checkbox" />
                {f(msg.lblPurgeLogsEnabled)}
                <br />
              </label>
            </div>
          </div>
        </div>
        <br />

        {purgeLogsEnabled && (
        <Fragment>
          <Field
            name="customerLogMaxAge"
            label={f(msg.lblCustomerLogMaxAge)}
            labelAfter={f(msg.lblAfterCustomerLogMaxAge)}
            description={f(msg.descriptionCustomerLogMaxAge)}
            component={PeriodInput}
          /> <br />
        </Fragment>
            )}

        <Field
          name="inactiveCustomerMaxAge"
          label={f(msg.lblInactiveCustomerMaxAge)}
          labelAfter={f(msg.lblAfterInactiveCustomerMaxAge, { purgeLogsEnabled: !!purgeLogsEnabled })}
          description={f(msg.descriptionInactiveCustomerMaxAge, { purgeLogsEnabled: !!purgeLogsEnabled })}
          component={PeriodInput}
        />
        <br />
      </div>
        )}
      <hr />

      <div className="row">
        <div className="col-sm-offset-3 col-sm-6">
          <div className="alert alert-info">
            {f(msg.alertFooter, { br: <br />, b: (...chunks) => <strong>{chunks}</strong> })}
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-5">
          <SubmitButton {...props} pristine={pristine && preferencesUpdated}>
            {f(msg.btnSubmit, { preferencesUpdated: !!preferencesUpdated })}
          </SubmitButton>
        </div>
      </div>
    </form>
  );
};

const validator = createValidator({
  bookingMaxAge: [required, timePeriod('P2W', 'P10Y')],
  customerLogMaxAge: [required, timePeriod('P2W', 'P10Y')],
  inactiveCustomerMaxAge: [required, timePeriod('P1D', 'P10Y')]
});

const selector = formValueSelector('storage-config');

const mapStateToProps = (state) => {
  return {
    preferencesUpdated: !!state.locationConfig.get('preferencesUpdated'),
    autoPurgeEnabled: selector(state, 'autoPurgeEnabled'),
    purgeLogsEnabled: selector(state, 'purgeLogsEnabled')
  };
};

export default reduxForm({
  form: 'storage-config',
  validate: validator
})(connect(mapStateToProps)(StorageConfigForm));
