import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';

import { msgBookingNotes as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

const renderNotesField = ({ input: { onChange, value } }) => (
  <TextareaAutosize
    onChange={onChange}
    defaultValue={value}
    className="form-control"
    minRows={3}
    maxRows={8}
  />
);

class BookingNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editNotes: false
    };
  }

  componentDidMount() {
    if (this.props.isReservation || this.props.fullScreen) {
      this.focusEditField();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isReservation && this.props.isReservation) {
      this.focusEditField();
    }
  }

  focusEditField = () => {
    if (this.notes) {
      const notes = ReactDOM.findDOMNode(this.notes);
      notes.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  editNotes = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();

    if (this.props.isModal) {
      this.props.onEditNotes();
    } else {
      this.setState({ editNotes: true }, this.focusEditField);
    }
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editNotes: false });
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  render() {
    const { editNotes } = this.state;
    const { fullScreen, isReservation, maxHeight, intl: { formatMessage: f } } = this.props;

    if (fullScreen) {
      return (
        <div className="booking-form">
          <div className="booking-form-header">
            <div className="cancel">
              <a href="#" onClick={this.handleCancel}><i className="fa fa-chevron-left" /> {f(msgButtons.btnBack)}</a>
            </div>
            <h4 className="title">{f(msg.note)}</h4>
            <div className="save">
              <a href="#" onClick={this.handleCancel}>{f(msgButtons.btnClear)}</a>
            </div>
          </div>
          <div className="booking-form-body" style={{ maxHeight }}>
            <Field
              name="note"
              component="textarea"
              style={{ height: maxHeight > 220 ? 200 : maxHeight - 20 }}
              className="form-control"
              ref={(ref) => { this.notes = ref; }}
            />
          </div>
        </div>
      );
    }

    if (isReservation || editNotes) {
      return (
        <div className="booking-form-block form">
          <div className="row">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.note)}</label>
              <Field
                name="note"
                component={renderNotesField}
                normalize={v => v && v.trim()}
                ref={(ref) => { this.notes = ref; }}
              />
            </div>
          </div>
          {!isReservation && (
            <div className="text-right">
              <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
            </div>
          )}
        </div>
      );
    }

    if (!this.props.note) {
      return (
        <button className="booking-form-block button" onClick={this.editNotes}>
          <i className="fa fa-commenting" /> {f(msg.addNote)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className="booking-form-block notes" onClick={this.editNotes}>
        <div className="text-wrap">
          {this.props.note}
        </div>
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { note, type } = bkf.get('attributes');

  const initialValues = {
    note
  };

  return {
    initialValues,
    ...getFormValues('bkf-notes')(state),
    isReservation: type === 'Reservation'
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-notes',
  destroyOnUnmount: false
})(BookingNotes)));
