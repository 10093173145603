import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResourceEntry from './resource-entry';

export default class GroupEntry extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    groupTargetUrlResolver: PropTypes.func.isRequired
  };

  render() {
    const { group, resources } = this.props;
    const groupResources = group.resourceIds.map(id => resources[id]);

    return (
      <li>
        {this.getGroupItem()}
        <ul className="list-group">
          {groupResources.map(resource => (
            <ResourceEntry
              key={resource.id}
              resource={resource}
              {...this.props}
            />
          ))}
        </ul>
      </li>
    );
  }

  getGroupItem() {
    const { id, name } = this.props.group;
    const { entityType, entityId } = this.props.routeParams;

    const itemClasses = (entityType === 'group' && id === entityId) ? 'list-group-item group-item active' : 'list-group-item group-item';
    const linkTarget = this.props.groupTargetUrlResolver(id);

    return (
      <Link className={itemClasses} to={linkTarget}>
        <span className="name">{name}</span>
      </Link>
    );
  }
}
