import { defineMessages } from 'react-intl';

export const msgResourceListToolbar = defineMessages({
  btnSort: {
    id: 'resources.ResourceListToolbar.btnSort',
    defaultMessage: 'Sortera'
  },
  btnNewResource: {
    id: 'resources.ResourceListToolbar.btnNewResource',
    defaultMessage: 'Ny resurs'
  },
  btnNewGroup: {
    id: 'resources.ResourceListToolbar.btnNewGroup',
    defaultMessage: 'Ny grupp'
  },
  placeholderEnterName: {
    id: 'resources.ResourceListToolbar.placeholderEnterName',
    defaultMessage: 'Ange namn på resursen'
  }
});

export const msgResourceSubNav = defineMessages({
  settings: {
    id: 'resources.ResourceSubNav.settings',
    defaultMessage: 'Inställningar'
  },
  services: {
    id: 'resources.ResourceSubNav.services',
    defaultMessage: 'Tjänster'
  },
  shedule: {
    id: 'resources.ResourceSubNav.shedule',
    defaultMessage: 'Schema'
  }
});

export const msgResourceServiceGroup = defineMessages({
  valuesForService: {
    id: 'resources.ResourceServiceGroup.valuesForService',
    defaultMessage: '= Egna värden inställda för tjänsten'
  },
  chooseAll: {
    id: 'resources.ResourceServiceGroup.chooseAll',
    defaultMessage: 'Välj alla'
  }
});

export const msgResourceServiceItem = defineMessages({
  canBookedOnline: {
    id: 'resources.ResourceServiceItem.canBookedOnline',
    defaultMessage: 'kan bokas online'
  },
  btnSettings: {
    id: 'resources.ResourceServiceItem.btnSettings',
    defaultMessage: 'Inställningar'
  }
});

export const msgResourceServices = defineMessages({
  note: {
    id: 'resources.ResourceServices.note',
    defaultMessage: 'OBS! Inga tjänster är valda för den här resursen. För att kunna bokas online behöver du välja vilka tjänster som ska kunna bokas.'
  },
  heading: {
    id: 'resources.ResourceServices.heading',
    defaultMessage: 'Välj de tjänster som ska kunna bokas för den här resursen'
  },
  administrator: {
    id: 'resources.ResourceServices.administrator',
    defaultMessage: 'Administrera kontots tjänster'
  },
  addNewServices: {
    id: 'resources.ResourceServices.addNewServices',
    defaultMessage: 'Lägg till nya tjänster och ändra ordning genom att '
  },
  setYourOwnValues: {
    id: 'resources.ResourceServices.setYourOwnValues',
    defaultMessage: 'Ställ in egna värden om du exempelvis har andra priser på en tjänst än vad som är inställt för kontot'
  },
  changeTheOrder: {
    id: 'resources.ResourceServices.changeTheOrder',
    defaultMessage: 'Ändra ordning genom att '
  }
});

export const msgResourceSettingsForm = defineMessages({
  settingsFor: {
    id: 'resources.ResourceSettingsForm.settingsFor',
    defaultMessage: 'Inställningar för {name}'
  },
  useCustomSettings: {
    id: 'resources.ResourceSettingsForm.useCustomSettings',
    defaultMessage: 'Använd egna inställningar'
  },
  withYourOwnSettings: {
    id: 'resources.ResourceSettingsForm.withYourOwnSettings',
    defaultMessage: 'Med egna inställningar kan du ändra resursens inställningar separat från kontots inställningar. {br} Kontots inställningar hittar du här: '
  },
  accountSettings: {
    id: 'resources.ResourceSettingsForm.accountSettings',
    defaultMessage: 'Kontoinställningar'
  },
  name: {
    id: 'resources.ResourceSettingsForm.name',
    defaultMessage: 'Namn'
  },
  webBooking: {
    id: 'resources.ResourceSettingsForm.webBooking',
    defaultMessage: 'Webbokning'
  },
  activateWebBooking: {
    id: 'resources.ResourceSettingsForm.activateWebBooking',
    defaultMessage: 'Aktivera publik webbokning'
  },
  joinWebBooking: {
    id: 'resources.ResourceSettingsForm.joinWebBooking',
    defaultMessage: 'Om du vill att resursen ska gå att boka online från kontots gemensamma webbokning.'
  },
  linkToWebBooking: {
    id: 'resources.ResourceSettingsForm.linkToWebBooking',
    defaultMessage: 'Länk till publik bokning:'
  },
  activateVipBooking: {
    id: 'resources.ResourceSettingsForm.activateVipBooking',
    defaultMessage: 'Aktivera VIP-bokning'
  },
  explainVipBooking: {
    id: 'resources.ResourceSettingsForm.explainVipBooking',
    defaultMessage: 'VIP-bokning är en privat webbokning där kunder enbart kan boka dig, och bara om de har länken. Du kan t.ex. mejla eller smsa länken till dina kunder.'
  },
  linkToVipBooking: {
    id: 'resources.ResourceSettingsForm.linkToVipBooking',
    defaultMessage: 'Länk till din VIP-bokning:'
  },
  webBookingName: {
    id: 'resources.ResourceSettingsForm.webBookingName',
    defaultMessage: 'Namn i webbokningen'
  },
  algorithm: {
    id: 'resources.ResourceSettingsForm.algorithm',
    defaultMessage: 'Algoritm'
  },
  allowBookingLatest: {
    id: 'resources.ResourceSettingsForm.allowBookingLatest',
    defaultMessage: 'Tillåt bokning senast'
  },
  minutesBefore: {
    id: 'resources.ResourceSettingsForm.minutesBefore',
    defaultMessage: 'minuter innan'
  },
  allowBookingUpTo: {
    id: 'resources.ResourceSettingsForm.allowBookingUpTo',
    defaultMessage: 'Tillåt bokning upp till'
  },
  daysBefore: {
    id: 'resources.ResourceSettingsForm.daysBefore',
    defaultMessage: 'dagar innan'
  },
  confirmation: {
    id: 'resources.ResourceSettingsForm.confirmation',
    defaultMessage: 'Bokningsbekräftelse'
  },
  sendEmailBooking: {
    id: 'resources.ResourceSettingsForm.sendEmailBooking',
    defaultMessage: 'Skicka E-post vid bokning online'
  },
  sendEmailCanceling: {
    id: 'resources.ResourceSettingsForm.sendEmailCanceling',
    defaultMessage: 'Skicka E-post vid avbokning online'
  },
  sendSmsBooking: {
    id: 'resources.ResourceSettingsForm.sendSmsBooking',
    defaultMessage: 'Skicka SMS vid bokning online'
  },
  sendSmsCancelling: {
    id: 'resources.ResourceSettingsForm.sendSmsCancellling',
    defaultMessage: 'Skicka SMS vid avbokning online'
  },
  extraInformation: {
    id: 'resources.ResourceSettingsForm.extraInformation',
    defaultMessage: 'Skicka SMS vid avbokning online'
  },
  forBookingConfirmation: {
    id: 'resources.ResourceSettingsForm.forBookingConfirmation',
    defaultMessage: 'i bokningsbekräftelse via e-post'
  },
  addExtraInformation: {
    id: 'resources.ResourceSettingsForm.addExtraInformation',
    defaultMessage: 'Lägg till extra information i bokningsbekräftelse, som till exempel öppettider eller avbokningsregler.'
  },
  sendReminders: {
    id: 'resources.ResourceSettingsForm.sendReminders',
    defaultMessage: 'Skicka SMS-påminnelser'
  },
  remindersSentTo: {
    id: 'resources.ResourceSettingsForm.remindersSentTo',
    defaultMessage: 'Påminnelse skickas tidigast {smsReminderWithin} timmar innan bokningen'
  },
  textInConfirmation: {
    id: 'resources.ResourceSettingsForm.textInConfirmation',
    defaultMessage: 'Text i bekräftelse'
  },
  textInReminder: {
    id: 'resources.ResourceSettingsForm.textInReminder',
    defaultMessage: 'Text i påminnelse'
  },
  areYouSureText: {
    id: 'resources.ResourceSettingsForm.areYouSureText',
    defaultMessage: 'Är du säker på att du vill ta bort resursen? Detta går inte att ångra!'
  },
  areYouSureConfirm: {
    id: 'resources.ResourceSettingsForm.areYouSureConfirm',
    defaultMessage: 'Ta bort resurs'
  }
});
