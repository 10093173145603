var Schedule = require('./schedule');

module.exports = Chaplin.Model.extend({

   urlRoot: '/schedules/location/',

   defaults: {
      schedules: new Chaplin.Collection({model: Schedule, parse: true})
   },

   parse: function(data, options) {
      return {schedules: data};
   },

   set: function(attributes, options) {
      if (attributes.schedules !== undefined && !(attributes.schedules instanceof Chaplin.Collection)) {
         attributes.schedules = new Chaplin.Collection(attributes.schedules, {model: Schedule, comparator: 'validFrom', parse: true});
      }
      return Backbone.Model.prototype.set.call(this, attributes, options);
   }
});
