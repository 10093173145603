var View = require('../base/view');

module.exports = View.extend({
   noWrap: true,
   autoRender: true,
   containerMethod: 'html',

   initialize: function(){
      this.template = require('./config');
      View.prototype.initialize.call(this, arguments);
   },

   regions:{
     'resource-config-nav' : '#resource-config-nav',
     'resource-config-content' : '#resource-config-content'
   },

   listen: {
      'change:name model':'nameChange'
   },

   events: {
      'click #edit-resource-cancel':'cancel'
   },

   nameChange:function(){
      this.setTitle(this.model.get('name'));
   },

   setTitle:function(title){
      this.$('#resource-config-title').text(title);
   },

   cancel:function(){
      this.publishEvent('calendar:cancel');
      return false;
   },

   render:function(){
      View.prototype.render.call(this, arguments);
      this.setTitle(this.model.get('name'));
   }
});