import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountInfoDismissed, setAccountInfoDismissed } from '../../utils/account';
import { getOrganisationsUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';

import { msgAccountInfoAlert as msg } from '../../localization/messages/components/dialogs';

class AccountInfoAlert extends Component {

  render() {
    const { trialStatus, trialUntil, accountStatus } = this.props;

    if (trialStatus === 'TrialExpired') {
      return this.renderTrialExpired();
    } else if (accountStatus === 'BlockedManual') {
      return this.renderBlockedManual();
    } else if (accountStatus === 'BlockedPaymentRequired') {
      return this.renderBlockedPaymentRequired();
    } else if (trialStatus && trialUntil && !getAccountInfoDismissed()) {
      return this.renderFirstTrialStatus(trialUntil);
    }
    return null;
  }

  renderFirstTrialStatus(trialUntil) {
    const { intl: { formatMessage: f } } = this.props;
    const daysLeft = trialUntil.diff(moment(), 'days');
    const daysLeftText = f(msg.daysLeft, { daysLeft });

    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.trialStatusHeading} /></h2>
          <hr />
          <p>
            <FormattedMessage
              {...msg.trialStatusContent}
              values={{ daysLeftText, organisationsLink: this.renderOrganisationsLink() }}
            />
          </p>
          <hr />
          <a href="#" onClick={this.close} className="button arrow">
            <FormattedMessage {...msg.trialStatusLink} />
          </a>
        </div>
      </div>
    );
  }

  renderTrialExpired() {
    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.trialExpiredHeading} /></h2>
          <hr />
          <p>
            <FormattedMessage {...msg.creaditCardMessage} />
          </p>
          <p>
            <FormattedMessage
              {...msg.getMore}
              values={{ organisationsLink: this.renderOrganisationsLink() }}
            />
          </p>
          <hr />
          {this.renderOrganisationsButton()}
        </div>
      </div>
    );
  }

  renderBlockedManual() {
    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.blockedHeading} /></h2>
          <hr />
          <p><FormattedMessage {...msg.creaditCardMessage} /></p>
          <p>
            <FormattedMessage
              {...msg.getMore}
              values={{ organisationsLink: this.renderOrganisationsLink() }}
            />
          </p>
          <hr />
          {this.renderOrganisationsButton()}
        </div>
      </div>
    );
  }

  renderBlockedPaymentRequired() {
    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.blockedHeading} /></h2>
          <hr />
          <p><FormattedMessage {...msg.creaditCardMessage} /></p>
          <p>
            <FormattedMessage
              {...msg.getMore}
              values={{ organisationsLink: this.renderOrganisationsLink() }}
            />
          </p>
          <hr />
          {this.renderOrganisationsButton()}
        </div>
      </div>
    );
  }

  renderOrganisationsLink() {
    const { intl: { formatMessage: f } } = this.props;
    return <a href={getOrganisationsUrl(translateRouteParams(this.props.match.params))}>{f(msg.linkSubscription)}</a>;
  }

  renderOrganisationsButton() {
    const { intl: { formatMessage: f } } = this.props;
    return <a href={getOrganisationsUrl(translateRouteParams(this.props.match.params))} className="button arrow">{f(msg.btnSubscription)}</a>;
  }

  close = (ev) => {
    ev.preventDefault();
    setAccountInfoDismissed();
    this.forceUpdate();
  };
}

AccountInfoAlert.propTypes = {
  trialStatus: PropTypes.string,
  trialUntil: PropTypes.object,
  accountStatus: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    trialStatus: state.appState.get('trialStatus'),
    trialUntil: state.appState.get('trialUntil'),
    accountStatus: state.appState.get('accountStatus')
  };
};

export default injectIntl(connect(
      mapStateToProps
)(AccountInfoAlert));
