export const getTopLevelDomain = (hostname) => {
  const matches = hostname.match(/dashl\.(.*)+$/);
  return matches && matches.length > 1 ? matches[1] : 'se';
};

export const domainLocaleMapping = (domain) => {
  if (domain === 'de') return 'de';
  if (domain === 'se') return 'sv';
  if (domain === 'nl') return 'nl';
  if (domain === 'co.uk') return 'en';
  return null;
};

export const getLocaleFromUrl = (hostname, fallback = 'sv') => {
  const domain = getTopLevelDomain(hostname);
  const locale = domainLocaleMapping(domain) || fallback;
  return locale;
};
