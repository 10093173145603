var mediator = Chaplin.mediator;

var AlertOverlayView = Backbone.View.extend({
   tag: 'div',
   className: 'alert-floating-container',
   alertClass: null,
   content: null,

   initialize: function () {
      mediator.subscribe('alertOverlay:showSuccess', _.bind(this.showSuccess, this));
      mediator.subscribe('alertOverlay:showError', _.bind(this.showError, this));
   },

   render: function() {
      $('body').append(this.$el);
      this.setContent();
      this.$el.fadeIn(200);
      return this;
   },

   showSuccess: function(content) {
      this.alertClass = 'alert-success';
      this.content = content;
      this.render();

      setTimeout(_.bind(this._close, this), 2500);
   },

   showError: function(content) {
      this.alertClass = 'alert-danger';
      this.content = content;
      this.render();

      setTimeout(_.bind(this._close, this), 2500);
   },

   setContent: function() {
      this.$el.empty();
      this.$el.append('<div class="alert-floating ' + this.alertClass + '">' + this.content + '</div>');
   },

   _close: function() {
      this.$el.fadeOut(500, function () { this.remove(); });
   }

});

module.exports = AlertOverlayView;
