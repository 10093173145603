var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\"#\" class=\"btn btn-default\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"recurringWeek",{"name":"i18n","hash":{},"data":data}))
    + " "
    + alias3(((helper = (helper = helpers.week || (depth0 != null ? depth0.week : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"week","hash":{},"data":data}) : helper)))
    + "</a>";
},"useData":true});