import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeParams } from '../../utils/selectors';
import { translateRouteParams } from '../../utils/time-util';

import AccountConfigController from '../../../controllers/account-config-controller';

class Chaplin extends Component {

  render() {
    return (
      <div>
        <div id="config-content-region" />
      </div>
    );
  }

  componentDidMount() {
    this.accountConfigController = new AccountConfigController();
    this.accountConfigController.showSection(this.props.routeParams.subsection);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.subsection !== prevProps.routeParams.subsection) {
      this.accountConfigController.showSection(this.props.routeParams.subsection);
    }
  }

  componentWillUnmount() {
    this.accountConfigController.dispose();
  }
}

Chaplin.propTypes = {
  routeParams: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const rp = routeParams(translateRouteParams(ownProps.match.params));

  return {
    routeParams: rp
  };
};

export default connect(
   mapStateToProps
)(Chaplin);
