var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <h4>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"commonShedule",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n   <br/>\n   <div class=\"alert alert-info\">\n      <i class=\"fa fa-info-circle\"></i> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"commonSheduleTip",{"name":"i18n","hash":{},"data":data}))
    + " <strong>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"resourcesSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</strong>.\n   </div>\n   <div id=\"account-schedule-view\"></div>\n</div>\n";
},"useData":true});