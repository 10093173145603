var View = require('../../base/view');
var ScheduleView = require('../../schedule/main-view');
var ScheduleExceptionsView = require('./schedule-exceptions-view');
var ScheduleExceptionsCollection = require('../../../collections/schedule-exceptions');
var ConfirmDeletePopoverView = require('../../base/confirm-popover-view');
var Navigate = require('../../../redux-dashl/utils/navigate');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = View.extend({
	noWrap: true,
	autoRender: true,

	events: {
		'click .js-schedule-location': 'confirmLocationSchedule',
		'click .js-schedule-custom': 'setCustomSchedule',
		'click .js-account-config': 'navigateToAccountConfig'
	},

	initialize: function (options) {
	  this.params = options.params;
    this.useLocationSchedule = this.model.get('useLocationSchedule');
    this.template = require('./main');
		View.prototype.initialize.call(this, arguments);
	},

  navigateToAccountConfig: function(e) {
    e.preventDefault();
    Navigate.navigate(Navigate.getSectionUrl(this.params, 'account-config', 'schedule'));
  },

	confirmLocationSchedule: function(e) {
		e.preventDefault();

		this.confirmationView = new ConfirmDeletePopoverView({
			placement: 'bottom',
			title: formatMessage(msgHandlebars.deleteScheduleFromAccountTitle),
			confirmText: formatMessage(msgHandlebars.deleteScheduleFromAccountText),
			buttonText: formatMessage(msgHandlebars.deleteScheduleFromAccountTitle),
			callback: _.bind(this.setLocationSchedule, this),
			parent: $(e.currentTarget)
		});
		this.subview('popover', this.confirmationView);
	},

	setLocationSchedule: function () {
		$.ajax({
			type: 'PUT',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: this.model.url() + '/preferences',
         data: JSON.stringify({useLocationSchedule: true}),
			success: _.bind(this.setLocationScheduleSuccess, this)
		});
	},

	setLocationScheduleSuccess: function() {
		this.toggleLocationSchedule(true);
		this.confirmationView.close();
		this.fetchModel();
	},

	setCustomSchedule: function(e) {
		e.preventDefault();
      $.ajax({
         type: 'PUT',
         contentType: 'application/json; charset=utf-8',
         dataType: 'json',
         url: this.model.url() + '/preferences',
         data: JSON.stringify({useLocationSchedule: false}),
         success: _.bind(this.setCustomScheduleSuccess, this)
      });
   },

   setCustomScheduleSuccess: function() {
		this.toggleLocationSchedule(false);
	},

	toggleLocationSchedule: function(useLocationSchedule) {
		this.useLocationSchedule = useLocationSchedule;
		this.$('.js-schedule-location-section').toggleClass('hidden', this.useLocationSchedule);
		this.$('.js-schedule-custom-section').toggleClass('hidden', !this.useLocationSchedule);
		if(this.scheduleView) {
			this.scheduleView.toggleEnableEdit(!this.useLocationSchedule);
		}
	},

	saveSchedule: function(data) {
		this.$('.js-save-schedule').button('loading');
		var url = this.model.url() + '/customSchedule/' + data.id;
		$.ajax({
			type: 'PUT',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: url,
			data: JSON.stringify(data.schedule),
			success: _.bind(this.saveSuccess, this),
			error: _.bind(this.saveError, this)
		});
	},

	saveSuccess: function() {
		this.scheduleView.scheduleSaved();
		this.$('.js-save-schedule').button('reset');
		this.publishEvent('alertOverlay:showSuccess', formatMessage(msgHandlebars.scheduleHasBeenSaved));
	},

	saveError: function() {
		this.$('.js-save-schedule').button('reset');
	},

	createSchedule: function(data) {
		$.ajax({
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: this.model.url() + '/customSchedule',
			data: JSON.stringify(data),
			success: _.bind(this.createSuccess, this)
		});
	},

	createSuccess: function() {
		this.scheduleView.closeModal();
		this.fetchModel();
	},

	deleteSchedule: function(scheduleId) {
		$.ajax({
			type: 'DELETE',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: this.model.url() + '/customSchedule/' + scheduleId,
			success: _.bind(this.deleteSuccess, this)
		});
	},

	deleteSuccess: function() {
		this.scheduleView.closeDeletePopover();
		this.fetchModel();
	},

	fetchModel: function() {
		this.model.fetch({
			contentType: 'application/json; charset=utf-8',
			success: _.bind(this.renderSchedule, this)
		});
	},

	renderSchedule: function() {
		this.useLocationSchedule = this.model.get('useLocationSchedule');

		this.scheduleView = new ScheduleView({model: this.model, editMode: !this.useLocationSchedule, container: this.$('#resource-schedule-view')});
		this.listenTo(this.scheduleView, 'schedule:create', _.bind(this.createSchedule, this));
		this.listenTo(this.scheduleView, 'schedule:delete', _.bind(this.deleteSchedule, this));
		this.listenTo(this.scheduleView, 'schedule:save', _.bind(this.saveSchedule, this));
		this.subview('content', this.scheduleView);
		this.toggleLocationSchedule(this.useLocationSchedule);

		var exceptionsCollection = new ScheduleExceptionsCollection();
		exceptionsCollection.fetch(this.model.id);
		this.scheduleExceptionsView = new ScheduleExceptionsView({model: this.model, collection: exceptionsCollection, container: this.$('#resource-schedule-exceptions-view')});
		this.subview('exceptions', this.scheduleExceptionsView);
	},

	render: function () {
		View.prototype.render.call(this, arguments);
		this.renderSchedule();
   }
});
