import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PulseLoader from 'halogenium/lib/PulseLoader';

import { msgButtons } from '../../localization/messages/shared/buttons';

export default class SubmitButton extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitting && !nextProps.submitting && nextProps.submitSucceeded) {
      this.setState({ saved: true }, this.reset);
    }
  }

  reset = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  render() {
    const { saved } = this.state;
    const { pristine, submitting, children } = this.props;

    return (
      <button type="submit" className={`btn btn-save btn-submit ${saved ? 'saved' : ''}`} disabled={submitting || pristine}>
        {submitting && <PulseLoader color="#fff" size="7px" margin="4px 3px 0 3px" />}
        {!submitting && saved && <span><i className="fa fa-lg fa-check" /></span>}
        {!submitting && !saved && <span>{children || <FormattedMessage {...msgButtons.btnSave} /> }</span>}
      </button>
    );
  }
}
