var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n    <h5>"
    + ((stack1 = (helpers.DayOfWeek || (depth0 && depth0.DayOfWeek) || alias2).call(alias1,(depth0 != null ? depth0.day : depth0),{"name":"DayOfWeek","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h5>\n\n    <div class=\"schedule-items\"></div>\n    <div class=\"schedule-no-items\">\n        <div class=\"schedule-item-closed\">\n            <em>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"closed",{"name":"i18n","hash":{},"data":data}))
    + "</em>\n        </div>\n    </div>\n    <div class=\"js-edit-mode hide\">\n        <a href=\"#\" class=\"add-item btn btn-block btn-default\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"add",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});