import axios from 'axios';
import { destroy, initialize } from 'redux-form';
import { prefixUrl, axiosDefault, checkStatusAxios, axiosErrorHandler } from '../utils/ajax-util';
import { resourceIdFromColIdx } from '../components/grid/grid-state-helper';
import {
  bookingAdded, bookingChanged, bookingDeleted,
  revertBooking
} from './booking-actions';
import PhoneUtil from '../../utils/phone-util';

export const RESET_BKF = 'RESET_BKF';
export const SET_BKF_VEHICLE = 'SET_BKF_VEHICLE';
export const SET_BKF_COMPANY = 'SET_BKF_COMPANY';
export const SET_BKF_CUSTOMER = 'SET_BKF_CUSTOMER';
export const SET_BKF_BOOKING = 'SET_BKF_BOOKING';
export const SET_BKF_SERVICE = 'SET_BKF_SERVICE';
export const ADD_BKF_SERVICE = 'ADD_BKF_SERVICE';
export const REMOVE_BKF_SERVICE = 'REMOVE_BKF_SERVICE';
export const SET_BKF_TIME = 'SET_BKF_TIME';
export const SET_BKF_RES_TIME = 'SET_BKF_RES_TIME';
export const SET_BKF_ATTRIBUTES = 'SET_BKF_ATTRIBUTES';
export const TOGGLE_BKF_PRINT_MODAL = 'TOGGLE_BKF_PRINT_MODAL';

export const RES_SRV_LOADED = 'RES_SRV_LOADED';
export const RES_SRV_LOADING = 'RES_SRV_LOADING';
export const CLEAR_RES_SRV = 'CLEAR_RES_SRV';

export function clearFormsBKF() {
  return (dispatch) => {
    dispatch(destroy(
      'bkf-company', 'bkf-customer', 'bkf-service', 'bkf-address', 'bkf-notes',
      'bkf-confirmation', 'bkf-place', 'bkf-vehicle', 'bkf-price', 'bkf-time'
    ));
  };
}

export function clearAndCloseBKF() {
  return (dispatch) => {
    dispatch({ type: RESET_BKF });
    dispatch(clearFormsBKF());
  };
}

export function setBKFBooking(booking) {
  return {
    type: SET_BKF_BOOKING,
    booking
  };
}

export function setBKFAttributes(attributes) {
  return {
    type: SET_BKF_ATTRIBUTES,
    attributes
  };
}

export function updateBKFCoords(coords, routeParams) {
  return (dispatch, getState) => {
    const { orderedGroups } = getState();
    const { id, startTime, endTime, colIdx } = coords;

    const { entityType, entityId } = routeParams;
    const resId = resourceIdFromColIdx(orderedGroups, entityType, entityId, colIdx);

    dispatch(destroy('bkf-time'));
    dispatch({
      type: SET_BKF_RES_TIME,
      id,
      startTime,
      endTime,
      resId
    });
  };
}

export function setBKFBookingTime(newTime) {
  return {
    type: SET_BKF_TIME,
    ...newTime
  };
}

export function loadBKFBooking(bkId) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(bkId);
    dispatch(clearFormsBKF());
    dispatch(setBKFBooking(booking));
  };
}

export function setBKFVehicle(vehicle) {
  return (dispatch) => {
    dispatch(initialize('bkf-vehicle', vehicle));
    dispatch({ type: SET_BKF_VEHICLE, vehicle });
  };
}

export function addBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: ADD_BKF_SERVICE, service });
  };
}

export function removeBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: REMOVE_BKF_SERVICE, service });
  };
}

export function setBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: SET_BKF_SERVICE, service });
  };
}

export function setBKFCompany(company) {
  return (dispatch) => {
    dispatch(destroy('bkf-company'));
    dispatch({ type: SET_BKF_COMPANY, company });
  };
}

export function setBKFCustomer(customer) {
  return (dispatch) => {
    const initialValues = customer && {
      ...customer,
      phoneNumber: PhoneUtil.formatPhoneNumber(customer.phoneNumber),
      otherPhoneNumber: PhoneUtil.formatPhoneNumber(customer.otherPhoneNumber)
    };
    dispatch(initialize('bkf-customer', initialValues));
    dispatch({ type: SET_BKF_CUSTOMER, customer });
  };
}

export function toggleBKFPrintModal(show) {
  return {
    type: TOGGLE_BKF_PRINT_MODAL,
    show
  };
}

function loadingResourceServices() {
  return {
    type: RES_SRV_LOADING
  };
}
function resourceServicesLoaded(resId, services) {
  return {
    type: RES_SRV_LOADED,
    resId,
    services
  };
}

export function clearResourceServices() {
  return {
    type: CLEAR_RES_SRV
  };
}

export function addBooking(booking) {
  const url = prefixUrl('/bookings/');
  const config = axiosDefault();
  return (dispatch) => {
    return axios.post(url, booking, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(bookingAdded({ ...booking, ...res.data }, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateBooking(booking) {
  const url = prefixUrl(`/bookings/${booking.id}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, booking, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(bookingChanged(booking.id, { ...booking, ...res.data }, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch, () => dispatch(revertBooking(booking.id))));
  };
}

export function deleteBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(req => dispatch(bookingDeleted(id, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch, () => dispatch(revertBooking(id))));
  };
}

export function fetchResourceServices(resId, clearCache) {
  const url = prefixUrl(`/resources/${resId}/services`);
  const config = axiosDefault();

  return (dispatch) => {
    if (clearCache) {
      dispatch(clearResourceServices());
    }
    dispatch(loadingResourceServices());

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        dispatch(resourceServicesLoaded(resId, res));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function fetchBeautyBarOfficeSuggestions(query) {
  const queryParam = `?query=${encodeURIComponent(query)}`;
  const url = prefixUrl(`/search-dashl-user${queryParam}`);
  const config = axiosDefault();

  const unifyEntriesObject = ({ userEntries }) => {
    return userEntries.map(user => ({
      ...user,
      type: 'Customer',
      name: `${user.firstName} ${user.lastName}`,
      phoneNumber: user.mobilePhone,
      email: user.username
    }));
  };

  return axios.get(url, config)
  .then(({ data }) => unifyEntriesObject(data))
  .catch((error) => {
    console.error('Could not get suggestions', error);
    return [];
  });
}

function fetchOtherSuggestions(query, scope, distinct) {
  const queryParam = `?query=${encodeURIComponent(query)}`;
  const scopeParam = `&scope=${scope.join(',')}`;
  const distinctParam = distinct ? '&distinct=true' : '';

  const url = prefixUrl(`/search${queryParam}${scopeParam}${distinctParam}`);
  const config = axiosDefault();

  return axios.get(url, config)
  .then(({ data }) => data.customerEntries)
  .catch((error) => {
    console.error('Could not get suggestions', error);
    return [];
  });
}

export function fetchSuggestions(query, scope, distinct, stylistType) {
  if (!query || !scope || query.length <= 2) {
    return Promise.resolve([]);
  }

  switch (stylistType) {
    case 'Office':
    case 'BeautyBar':
      return fetchBeautyBarOfficeSuggestions(query);
    default:
      return fetchOtherSuggestions(query, scope, distinct);
  }
}

export function fetchVehicleInfo(regNo) {
  const url = prefixUrl(`/search-vehicle?regNo=${encodeURIComponent(regNo)}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.get(url, config)
      .then(({ data }) => {
        dispatch(setBKFVehicle({
          vehicleRegNo: regNo,
          vehicleDescription: data.summary
        }));
      });
  };
}
