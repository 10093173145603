import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { updateCustomValues, deleteCustomValues, serviceMappingValues } from '../../state/services-actions';
import Modal from '../common/modal';
import CustomServiceSettingsForm from './custom-service-settings-form';

import { msgCustomServiceSettingsModal as msg } from '../../localization/messages/components/services';

class CustomServiceSettingsModal extends Component {
  static propTypes = {
    serviceMapping: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const { closeModal, serviceMapping, serviceFormValues, intl: { formatMessage: f } } = this.props;
    const resource = serviceMapping.get('resource');

    const initialValues = {
      customValues: serviceMapping.get('customValues'),
      serviceDuration: serviceMapping.get('serviceDuration'),
      afterTime: serviceMapping.get('afterTime'),
      price: serviceMapping.get('price'),
      priceFrom: serviceMapping.get('priceFrom'),
      webEnabled: serviceMapping.get('webEnabled'),
      webShowDuration: serviceMapping.get('webShowDuration'),
      webShowPrice: serviceMapping.get('webShowPrice')
    };

    return (
      <Modal
        titleText={f(msg.settings)}
        underlayClickExits
        dialogClass="modal-dialog"
        dialogStyle={{ width: 400 }}
        mounted={this.state.showForm}
        initialFocus=".modal-content"
        onExit={this.props.closeModal}
      >
        <div className="modal-content">
          <div className="modal-body">
            {this.renderMappingWarning()}
            <h4>{f(msg.settingsFor, { name: resource.name })}</h4>
            <CustomServiceSettingsForm
              onClose={closeModal}
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              serviceFormValues={serviceFormValues}
            />
          </div>
        </div>
      </Modal>
    );
  }

  renderMappingWarning() {
    const { intl: { formatMessage: f } } = this.props;
    if (this.props.hasCustomDiffs) {
      return (
        <div className="alert alert-warning" role="alert">
          {f(msg.markedField, { icon: <Fragment><i className="fa fa-fw fa-info-circle text-highlight-warn" /></Fragment> })}
        </div>
      );
    }
    return null;
  }

  handleSubmit = (values) => {
    const { serviceMapping } = this.props;
    const { serviceId, resource } = serviceMapping.toJS();

    if (values.customValues) {
      return this.props.updateCustomValues(serviceId, resource.id, values)
        .then(() => this.props.closeModal());
    }
    return this.props.deleteCustomValues(serviceId, resource.id)
      .then(() => this.props.closeModal());
  }
}

const hasDiffs = (values, mappings) => {
  if (!values || !mappings.customValues) {
    return false;
  }
  for (let i = 0; i < serviceMappingValues.length; i++) {
    const prop = serviceMappingValues[i];
    if (String(values[prop]) !== String(mappings[prop])) {
      return true;
    }
  }
};

const mapStateToProps = (state) => {
  const serviceFormValues = getFormValues('edit-service-form')(state);
  const mappingFormValues = getFormValues('custom-service-settings-form')(state);
  const hasCustomDiffs = mappingFormValues && hasDiffs(serviceFormValues, mappingFormValues);

  return {
    serviceFormValues,
    hasCustomDiffs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCustomValues: (serviceId, resourceId, customValues) => {
      return dispatch(updateCustomValues(serviceId, resourceId, customValues));
    },
    deleteCustomValues: (serviceId, resourceId) => {
      return dispatch(deleteCustomValues(serviceId, resourceId));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomServiceSettingsModal));
