import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../../utils/selectors';
import ResourceList from './resource-list';
import { fetchGroupsAndResources } from '../../../state/resource-actions';
import { navigate } from '../../../utils/navigate';

class ResourceSelector extends Component {
  static propTypes = {
    permissions: PropTypes.array.isRequired,
    orderedGroups: PropTypes.object.isRequired,
    resourcesById: PropTypes.object.isRequired,
    entityType: PropTypes.oneOf(['resource', 'group']),
    entityId: PropTypes.number.isRequired,
    resourceTargetUrlResolver: PropTypes.func.isRequired,
    groupTargetUrlResolver: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadData().then(() => this.loadingCompleted());
  }

  loadingCompleted = () => {
    const { entityType, entityId, resourcesById, orderedGroups } = this.props;

    if (entityType === 'group') {
      const group = orderedGroups.find(g => g.get('id') === parseInt(entityId));

      if (group == null) {
        if (orderedGroups.first()) {
          this.changeGroup(orderedGroups.first().get('id'));
        }
      }
    } else if (entityType === 'resource') {
      if (!resourcesById.has(parseInt(entityId))) {
        let resId = null;
        orderedGroups.find((g) => {
          if (g.get('resourceIds').size > 0) {
            resId = g.get('resourceIds').first();
            return true;
          }
          return false;
        });
        this.changeResource(resId);
      }
    }
  };

  render() {
    return (
      <div className="resource-selector" style={this.props.customStyle}>
        {this.resourceList()}
      </div>
    );
  }

  resourceList() {
    const { orderedGroups, resourcesById } = this.props;

    return (
      <ResourceList
        {...this.props}
        resources={resourcesById.toJS()}
        groups={orderedGroups.toJS()}
      />);
  }

  changeGroup = (groupId) => {
    navigate(this.props.groupTargetUrlResolver(groupId));
  };

  changeResource = (resId) => {
    navigate(this.props.resourceTargetUrlResolver(resId));
  };
}

const mapStateToProps = (state, ownProps) => {
  const { orderedGroups, resourcesById, locationFeatures } = state;
  const filteredGroups = orderedGroups.filter((group) => {
    return group.get('resourceIds').count() > 0;
  });

  const { entityType, entityId } = ownProps.routeParams;
  return {
    permissions: getPermissions(state, ownProps),
    locationFeatures,
    orderedGroups: filteredGroups,
    resourcesById,
    entityType,
    entityId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      return dispatch(fetchGroupsAndResources());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(ResourceSelector);
