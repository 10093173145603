import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountInfoDismissed, setAccountInfoDismissed, shouldShowAccountInfo } from '../../utils/account';
import { getOrganisationsUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';

import { msgAccountInfoAlert, msgAccountStatus as msg } from '../../localization/messages/components/dialogs';

class AccountStatus extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      showAccountInfo: getAccountInfoDismissed() && shouldShowAccountInfo(props.trialStatus, props.trialUntil, props.accountStatus)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showAccountInfo: getAccountInfoDismissed() && shouldShowAccountInfo(nextProps.trialStatus, nextProps.trialUntil, nextProps.accountStatus)
    });
  }

  render() {
    // console.warn("AccountStatus.render");
    const { showAccountInfo } = this.state;

    return showAccountInfo ? (
      <div className="alert-floating-container">
        <div className="alert-floating alert-info">
          <a href="#" className="pull-right" onClick={this.close}><i className="fa fa-times" /></a>
          {this.renderContent()}
        </div>
      </div>
      ) : null;
  }

  renderContent() {
    const { trialStatus, trialUntil, accountStatus } = this.props;

    if (trialStatus == 'TrialExpired') {
      return this.renderTrialExpired();
    } else if (trialStatus === 'Trial') {
      return this.renderTrial(trialUntil);
    } else if (accountStatus === 'ActivePaymentRequired') {
      return this.renderPaymentRequired();
    } else if (accountStatus === 'Terminated') {
      return this.renderTerminated();
    }
  }

  renderTrial(trialUntil) {
    const { intl: { formatMessage: f } } = this.props;
    const daysLeft = trialUntil.diff(moment(), 'days');
    const daysLeftText = f(msgAccountInfoAlert.daysLeft, { daysLeft });

    return (
      <p>
        <FormattedMessage
          {...msgAccountInfoAlert.trialStatusContent}
          values={{ daysLeftText, organisationsLink: this.renderOrganisationsLink() }}
        />
      </p>
    );
  }

  renderTrialExpired() {
    return (
      <p>
        <FormattedMessage
          {...msg.trialExpired}
          values={{ organisationsLink: this.renderOrganisationsLink() }}
        />
      </p>
    );
  }

  renderPaymentRequired() {
    return (
      <p>
        <FormattedMessage
          {...msg.paymentRequired}
          values={{ organisationsLink: this.renderOrganisationsLink() }}
        />
      </p>
    );
  }

  renderTerminated() {
    return (
      <p style={{ color: 'white', fontWeight: 700 }}>
        <FormattedMessage {...msg.terminated} />
      </p>
    );
  }

  renderOrganisationsLink() {
    const { intl: { formatMessage: f } } = this.props;
    return <a href={getOrganisationsUrl(translateRouteParams(this.props.match.params))}>{f(msgAccountInfoAlert.linkSubscription)}</a>;
  }

  close = (ev) => {
    ev.preventDefault();
    setAccountInfoDismissed();
    this.setState({ showAccountInfo: false });
  };
}

AccountStatus.propTypes = {
  trialStatus: PropTypes.string,
  trialUntil: PropTypes.object,
  accountStatus: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { appState } = state;

  return {
    trialStatus: appState.get('trialStatus'),
    trialUntil: appState.get('trialUntil'),
    accountStatus: appState.get('accountStatus')
  };
};

export default injectIntl(connect(
   mapStateToProps
)(AccountStatus));
