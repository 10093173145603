/* eslint-disable newline-per-chained-call */
import color from 'color';

export const colors = {
  booking: color().rgb(10, 159, 240),
  cancelled: color().rgb(100, 100, 100),
  reservation: color().rgb(250, 210, 0),
  show: color().rgb(54, 170, 100),
  noShow: color().rgb(229, 70, 0),
  dropIn: color().rgb(60, 121, 204).lighten(0.1),
  askedForPerson: color().rgb(255, 210, 170).darken(0.3)
};


export const visualStylesHC = {
  askedForPerson: {
    background: colors.askedForPerson.rgbString(),
    border: `1px solid ${colors.askedForPerson.clone().lighten(1.0).rgbString()}`
  },

  dropIn: {
    background: colors.dropIn.rgbString(),
    border: `1px solid ${colors.dropIn.clone().lighten(1.0).rgbString()}`
  },

  pending: {
    background: colors.booking.clone().lighten(0.1).desaturate(0.2).alpha(0.5).rgbString(),
    color: colors.booking.clone().lighten(1.0).rgbString()
  },

  statuses: {
    Booked: {
      background: colors.booking.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.booking.clone().lighten(1.0).rgbString(),
      borderLeft: `2px solid ${colors.booking.clone().saturate(1.0).darken(0.4).rgbString()}`

    },
    BookedDrag: {
      background: colors.booking.clone().lighten(0.2).alpha(0.8).rgbString()
    },

        // TOOD: Fix
    Cancelled: {
      background: colors.cancelled.clone().alpha(0.6).rgbString(),
      color: colors.cancelled.clone().lighten(1.5).rgbString(),
      borderLeft: `2px solid ${colors.cancelled.clone().darken(0.4).rgbString()}`

    },

    Show: {
      background: colors.show.clone().lighten(0.1).rgbString(),
      color: colors.show.clone().lighten(1.3).rgbString(),
      borderLeft: `2px solid ${colors.show.clone().saturate(1.0).darken(0.5).rgbString()}`
    },
    ShowDrag: {
      background: colors.show.clone().lighten(0.2).alpha(0.8).rgbString()
    },

    NoShow: {
      background: colors.noShow.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.noShow.clone().lighten(1.3).rgbString(),
      borderLeft: `2px solid ${colors.noShow.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    NoShowDrag: {
      background: colors.noShow.clone().lighten(0.2).alpha(0.8).rgbString()
    },

    Reservation: {
      background: colors.reservation.clone().lighten(0.1).desaturate(0.1).rgbString(),
      color: colors.reservation.clone().darken(0.8).rgbString(),
      borderLeft: `2px solid ${colors.reservation.clone().darken(0.4).rgbString()}`
    },
    ReservationDrag: {
      background: colors.reservation.clone().lighten(0.2).alpha(0.8).rgbString()
    }
  }
};

export const visualStylesNonHC = {
  askedForPerson: {
    background: colors.askedForPerson.rgbString()
  },

  dropIn: {
    background: colors.dropIn.rgbString()
  },

  pending: {
    background: colors.booking.clone().lighten(0.8).alpha(0.5).rgbString(),
    color: colors.booking.clone().darken(0.6).rgbString()
  },

  statuses: {
    Booked: {
      background: colors.booking.clone().lighten(0.8).alpha(0.85).rgbString(),
      color: colors.booking.clone().darken(0.6).rgbString(),
      borderLeft: `2px solid ${colors.booking.clone().darken(0.1).rgbString()}`
    },
    BookedDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.booking.clone().lighten(0.1).rgbString(),
      borderLeft: `2px solid ${colors.booking.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Cancelled: {
      background: colors.cancelled.clone().lighten(0.7).alpha(0.4).rgbString(),
      color: colors.cancelled.clone().darken(0.2).rgbString(),
      borderLeft: `2px solid ${colors.cancelled.clone().darken(0.1).rgbString()}`
    },

    Show: {
      background: colors.show.clone().lighten(0.8).alpha(0.85).rgbString(),
      color: colors.show.clone().darken(0.7).rgbString(),
      borderLeft: `2px solid ${colors.show.clone().darken(0.1).rgbString()}`
    },
    ShowDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.show.clone().lighten(0.2).rgbString(),
      borderLeft: `2px solid ${colors.show.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    NoShow: {
      background: colors.noShow.clone().lighten(0.9).alpha(0.85).rgbString(),
      color: colors.noShow.clone().darken(0.7).rgbString(),
      borderLeft: `2px solid ${colors.noShow.clone().darken(0.1).rgbString()}`
    },
    NoShowDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.noShow.clone().lighten(0.1).rgbString(),
      borderLeft: `2px solid ${colors.noShow.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Reservation: {
      background: colors.reservation.clone().lighten(0.6).alpha(0.8).rgbString(),
      color: colors.reservation.clone().darken(0.8).rgbString(),
      borderLeft: `2px solid ${colors.reservation.clone().darken(0.1).rgbString()}`
    },
    ReservationDrag: {
      fontWeight: 700,
      background: colors.reservation.clone().lighten(0.2).rgbString(),
      borderLeft: `2px solid ${colors.reservation.clone().darken(0.2).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    }
  }
};

export const styles = {

  image: {
    height: 10,
    width: 10,
    marginRight: 3,
    marginTop: -3
  },

  icon: {
    marginRight: 3,
    marginTop: -2
  },

  label: {
    float: 'right',
    height: 10,
    width: 10,
    marginRight: 2,
    marginTop: 3,
    borderRadius: 10
  },

  chips: {
    base: {
      borderRadius: 3,
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box'
    },

    placeholder: {
      borderRadius: 3,
      boxSizing: 'border-box',
      boxShadow: 'none',
      border: '1px dashed grey',
      background: color().rgb(255, 255, 255).alpha(0.5).rgbString(),
      color: color().rgb(0, 0, 0).alpha(0.5).rgbString(),
      float: 'right'
    },

    dragging: {
      zIndex: 9999,
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)'
    }
  }
};
