import { defineMessages } from 'react-intl';

export const msgLinks = defineMessages({
  lnkTermsConditions: {
    id: 'links.termsConditions',
    defaultMessage: 'Allmänna villkor'
  },
  lnkPersonalData: {
    id: 'links.personalData',
    defaultMessage: 'Behandling av personuppgifter'
  }
});
