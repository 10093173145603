import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2/es';
import { formatVehicleRegNo } from '../../../utils/vehicle-util';
import { formatPhoneNumber } from '../../../utils/phone-util';
import CurrencyUtil from '../../../utils/currency-util';

import { msgLabels } from '../../localization/messages/shared/labels';
import { msgBookings as msg } from '../../localization/messages/components/reports';

class Bookings extends Component {

  print = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  render() {
    const { reportsViewState, enableVehicleBooking, enableCompanyBooking } = this.props;
    const bookings = reportsViewState.get('bookings');

    return bookings && !bookings.isEmpty() ?
      <div>
        {this.renderSummary(bookings)}
        {this.renderBookings(bookings, enableVehicleBooking, enableCompanyBooking)}
      </div> :
         this.renderNoData(bookings);
  }

  renderSummary(bookings) {
    const { intl: { formatMessage: f } } = this.props;
    const options = {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      title: { display: false },
      legend: { display: false },
      tooltips: { enabled: false }
    };

    const channelCount = bookings.countBy((item) => { return item.get('channel'); });
    const channels = [
         { color: '#EC449B', value: channelCount.get('Cal') || 0, label: f(msgLabels.calendar) },
         { color: '#5B5B95', value: channelCount.get('Web') || 0, label: f(msgLabels.web) },
         { color: '#D3FF00', value: channelCount.get('WebKlippTid') || 0, label: 'klipptid.nu' }
    ];

    const statusCount = bookings.countBy((item) => { return item.get('status'); });
    const statuses = [
         { color: '#3BBA6E', value: statusCount.get('Show') || 0, label: f(msgLabels.show) },
         { color: '#E65719', value: statusCount.get('NoShow') || 0, label: f(msgLabels.noShow) },
         { color: '#9D9D9D', value: statusCount.get('Cancelled') || 0, label: f(msgLabels.cancelled) },
         { color: '#33A4E1', value: statusCount.get('Booked') || 0, label: f(msgLabels.other) }
    ];

    const recurringCount = bookings.countBy((item) => {
      if (item.get('askedForPerson')) {
        return 'AskedForPerson';
      }
      if (item.get('dropIn')) {
        return 'DropIn';
      }
      return 'Other';
    });
    const recurring = [
         { color: '#FF8D29', value: recurringCount.get('AskedForPerson') || 0, label: f(msgLabels.askedForPerson) },
         { color: '#5187D2', value: recurringCount.get('DropIn') || 0, label: f(msgLabels.dropIn) },
         { color: '#36C5AA', value: recurringCount.get('Other') || 0, label: f(msgLabels.other) }
    ];

    return (
      <div className="hidden-print">
        <div className="row">
          <div className="col-xs-2">
            <Pie data={this.getChartData(channels)} options={options} />
          </div>
          <div className="col-xs-2">
            <h4>{f(msgLabels.source)}</h4>
            {this.renderLabels(channels)}
          </div>
          <div className="col-xs-2">
            <Pie data={this.getChartData(statuses)} options={options} />
          </div>
          <div className="col-xs-2">
            <h4>{f(msgLabels.status)}</h4>
            {this.renderLabels(statuses)}
          </div>
          <div className="col-xs-2">
            <Pie data={this.getChartData(recurring)} options={options} />
          </div>
          <div className="col-xs-2">
            <h4>{f(msgLabels.reservation)}</h4>
            {this.renderLabels(recurring)}
          </div>
        </div>
        <br /><hr /><br />
      </div>
    );
  }

  renderLabels(slices) {
    let sum = 0;
    slices.forEach((item) => { sum += item.value; });

    const sorted = slices.concat().sort((a, b) => { return b.value - a.value; });

    return (
      <table className="table table-condensed">
        <tbody>
          {
               sorted.map((slice, index) => {
                 const { color, label, value } = slice;
                 const percent = parseInt(value / sum * 100);
                 return (
                   <tr key={index}>
                     <td style={{ width: 1 }}>
                       <div style={{ height: 15, width: 15, backgroundColor: color }} />
                     </td>
                     <td>{label}</td>
                     <td className="text-right">{value} ({percent}%)</td>
                   </tr>
                 );
               })
            }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2"><strong><FormattedMessage {...msgLabels.total} /></strong></td>
            <td className="text-right"><strong>{sum}</strong></td>
          </tr>
        </tfoot>
      </table>
    );
  }

  renderBookings(bookings, showVehicle, showCompany) {
    let count = 0,
      sum = 0,
      columns = 8,
      cancelledCount = 0;

    if (showVehicle) {
      columns += 1;
    }
    if (showCompany) {
      columns += 1;
    }

    return (
      <table className="table">
        <thead>
          <tr>
            <th><FormattedMessage {...msgLabels.time} /></th>
            <th><FormattedMessage {...msgLabels.service} /></th>
            <th><FormattedMessage {...msgLabels.price} /></th>
            {showVehicle && <th><FormattedMessage {...msgLabels.vehicle} /></th>}
            {showCompany && <th><FormattedMessage {...msgLabels.business} /></th>}
            <th>{showCompany ? <FormattedMessage {...msgLabels.contact} /> : <FormattedMessage {...msgLabels.customer} />}</th>
            <th><FormattedMessage {...msgLabels.phone} /></th>
            <th><FormattedMessage {...msgLabels.source} /></th>
            <th><FormattedMessage {...msgLabels.status} /></th>
            <th><FormattedMessage {...msgLabels.others} /></th>
          </tr>
        </thead>
        {
               bookings.sortBy(b => b.get('startTime')).map((booking, index) => {
                 const {
                   startTime, description, services, customerName, vehicleRegNo, orgName,
                   customerPhoneNumber, channel, status, note, price, currency
                 } = booking.toJS();
                 const date = moment(startTime).format('LLLL');
                 let service = description || '';
                 if (services && services.length > 0) {
                   service = services[0].name;
                 }

                 if (status != 'Cancelled') {
                   sum += price;
                   count++;
                 } else {
                   cancelledCount++;
                 }

                 const noteRow = note ? <tr className="no-top-border"><td colSpan="7"><em>{note}</em></td></tr> : null;

                 return (
                   <tbody key={index}>
                     <tr>
                       <td>{date}</td>
                       <td>{service}</td>
                       <td>{price ? (CurrencyUtil.accountCurrency(price, 2, currency)) : '-'}</td>
                       {showVehicle && <td>{formatVehicleRegNo(vehicleRegNo)}</td>}
                       {showCompany && <td>{orgName}</td>}
                       <td>{customerName}</td>
                       <td>{formatPhoneNumber(customerPhoneNumber)}</td>
                       <td>{this.channelText(channel)}</td>
                       <td>{this.statusText(status)}</td>
                       <td>{this.flagsText(booking)}</td>
                     </tr>
                     {noteRow}
                   </tbody>
                 );
               })
            }
        <tfoot>
          <tr>
            <td colSpan={columns}>
              <strong>
                <FormattedMessage {...msg.reservations} values={{ count }} /><br />
                <FormattedMessage {...msg.canceled} values={{ cancelledCount }} /><br />
                <FormattedMessage {...msg.summPrice} values={{ sum: CurrencyUtil.accountCurrency(sum) }} />
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }

  renderNoData(bookings) {
    return bookings ?
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> <FormattedMessage {...msg.noBookings} />
      </div> : null;
  }

  getChartData(items) {
    const data = {
      labels: [],
      datasets: [{ data: [], backgroundColor: [] }]
    };
    for (const item of items) {
      data.labels.push(item.label);
      data.datasets[0].data.push(item.value);
      data.datasets[0].backgroundColor.push(item.color);
    }
    return data;
  }

  channelText(channel) {
    const { intl: { formatMessage: f } } = this.props;
    switch (channel) {
      case 'Cal':
        return f(msgLabels.calendar);
      case 'Web':
        return f(msgLabels.web);
      case 'WebHitta':
        return 'hitta.se';
      case 'WebKlippTid':
        return 'klipptid.nu';
      default:
        return '';
    }
  }

  statusText(status) {
    const { intl: { formatMessage: f } } = this.props;
    switch (status) {
      case 'Show':
        return f(msgLabels.show);
      case 'NoShow':
        return f(msgLabels.noShow);
      case 'Cancelled':
        return f(msgLabels.cancelled);
      default:
        return '';
    }
  }

  flagsText(item) {
    const { intl: { formatMessage: f } } = this.props;
    if (item.get('askedForPerson')) {
      return f(msgLabels.askedForPerson);
    }
    if (item.get('dropIn')) {
      return f(msgLabels.dropIn);
    }
    return '';
  }
}

Bookings.propTypes = {
  reportsViewState: PropTypes.object.isRequired
};

export default injectIntl(Bookings);
