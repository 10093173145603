import { defineMessages } from 'react-intl';

export const msgConfigButton = defineMessages({
  groupSettings: {
    id: 'calendar.ConfigButton.groupSettings',
    defaultMessage: 'Inställningar för grupp'
  },
  resourceSettings: {
    id: 'calendar.ConfigButton.resourceSettings',
    defaultMessage: 'Inställningar för resurs'
  }
});

export const msgCurrentDateLabel = defineMessages({
  label: {
    id: 'calendar.CurrentDateLabel.label',
    defaultMessage: 'v{week}'
  }
});

export const msgDateNavigator = defineMessages({
  today: {
    id: 'calendar.DateNavigator.today',
    defaultMessage: 'IDAG'
  }
});

export const msgScheduleToggle = defineMessages({
  changeShedule: {
    id: 'calendar.ScheduleToggle.changeShedule',
    defaultMessage: 'Ändra schema'
  }
});

export const msgSearchResults = defineMessages({
  resource: {
    id: 'calendar.SearchResults.resource',
    defaultMessage: '{resourceName} {resourceDeleted, select, true {(borttagen)} other {}}'
  },
  matchQuery: {
    id: 'calendar.SearchResults.matchQuery',
    defaultMessage: '{matches} träffar på {apos}{query}{apos}'
  },
  searching: {
    id: 'calendar.SearchResults.searching',
    defaultMessage: 'Söker...'
  }
});

export const msgViewModeSwitcher = defineMessages({
  day: {
    id: 'calendar.ViewModeSwitcher.day',
    defaultMessage: 'Dag'
  },
  week: {
    id: 'calendar.ViewModeSwitcher.week',
    defaultMessage: 'Vecka'
  },
  group: {
    id: 'calendar.ViewModeSwitcher.group',
    defaultMessage: 'Grupp'
  }
});
