import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { visualStylesHC, visualStylesNonHC, styles } from '../grid/chip-styles';

export default class ClipboardDragger extends PureComponent {
  static propTypes = {
    highContrast: PropTypes.bool.isRequired,
    gridProps: PropTypes.object.isRequired,
    initialTop: PropTypes.number.isRequired,
    initialLeft: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  };

  state = {
    isDragging: false,
    hide: false
  };

  styles() {
    const { top, left, hide, isDragging } = this.state;
    const { highContrast, initialTop, initialLeft } = this.props;
    const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;

    const baseStyles = {
      display: hide ? 'none' : 'block',
      position: 'fixed',
      zIndex: 9999
    };

    const position = isDragging ? {
      top,
      left
    } : {
      top: initialTop,
      left: initialLeft
    };

    return {
      ...baseStyles,
      ...position,
      ...styles.chips.base,
      ...visualStyles.statuses.Booked
    };
  }

  render() {
    return (
      <div className="btn btn-sm zoom" style={this.styles()}>
        <span> {this.props.label} </span>
      </div>
    );
  }
}
