import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Popover } from '../common/popover';
import { setUndoableBooking, undoMove } from '../../state/booking-actions';
import { setUserPreference } from '../../state/user-actions';
import { translateRouteParams } from '../../utils/time-util';

import { msgButtons } from '../../localization/messages/shared/buttons';
import { msgUndoButton as msg } from '../../localization/messages/components/undo-button';

class UndoButton extends Component {

  static propTypes = {
    hideHelpTipFromStart: PropTypes.bool.isRequired,
    onUndoLastMove: PropTypes.func.isRequired,
    onToggleHelpTip: PropTypes.func.isRequired,
    onClearUndoable: PropTypes.func.isRequired,
    tabletMode: PropTypes.bool.isRequired,
    phoneMode: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      hideHelpTipChecked: props.hideHelpTipFromStart,
      showHelpTip: false
    };
  }

  render() {
    const { undoableBooking, confirmMoveEnabled, phoneMode, tabletMode } = this.props;
    const { showHelpTip } = this.state;
    const iconClasses = 'fa fa-fw fa-reply';
    const buttonStyles = undoableBooking != null ? { color: '#39f', minWidth: 'auto' } : {
      color: '#ccc',
      minWidth: 'auto'
    };
    const buttonClasses = undoableBooking != null ? 'btn btn-label-o animated pulse' : 'btn btn-label-o disabled';
    const targetClasses = this.state.showHelpTip ? 'navbar-button helpTipFocus target' : 'navbar-button';
    const buttonText = !phoneMode && !tabletMode ? <FormattedMessage {...msgButtons.btnUndo} /> : '';

    if (confirmMoveEnabled) {
      return null;
    }

    return (
      <Popover
        isOpen={showHelpTip}
        body={this.popoverContent()}
        onOuterAction={this.closePopover}
        style={{ zIndex: 1000 }}
      >
        <div className={targetClasses}>
          <button type="button" onClick={this.undo} className={buttonClasses} style={buttonStyles}><i
            className={iconClasses}
          /> {buttonText}</button>
        </div>
      </Popover>
    );
  }

  popoverContent() {
    const iconClasses = 'fa fa-fw fa-reply';
    const iconStyles = { color: '#39f' };
    return (
      <div className="Popover-content">
        <a href="#" onClick={this.closePopover} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        <FormattedMessage {...msg.durationChanged} values={{ icon: <i style={iconStyles} className={iconClasses} /> }} />
        <a className="checkbox" href="#" onClick={this.toggleHelpTip}>
          {this.state.hideHelpTipChecked ? <i className="fa fa-fw fa-check-square-o" /> :
          <i className="fa fa-fw fa-square-o" />}
          <FormattedMessage {...msg.doNotShowAgain} />
        </a>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { undoableBooking } = this.props;

    if (undoableBooking != null) {
      this.clearTimerIfSet();
      this.currentTimer = setTimeout(this.removeUndoable, 5000);
    }
  }

  componentWillUnmount() {
    this.clearTimerIfSet();
  }

  componentWillReceiveProps(nextProps) {
    const { hideHelpTipFromStart, undoableBooking } = this.props;
    const undoableBookingChanged = nextProps.undoableBooking != null && !nextProps.undoableBooking.equals(undoableBooking);
    const showHelpTip = nextProps.undoableBooking != null && !hideHelpTipFromStart;

    if (undoableBookingChanged) {
      this.setState({ showHelpTip });
    }
  }

  clearTimerIfSet() {
    if (this.currentTimer) {
      clearTimeout(this.currentTimer);
    }
  }

  removeUndoable = () => {
    this.clearTimerIfSet();
    this.closePopover();
  };

  closePopover = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ showHelpTip: false });
  };

  undo = (ev) => {
    if (this.props.undoableBooking) {
      this.clearTimerIfSet();
      this.closePopover();
      this.props.onUndoLastMove();
    }

    if (ev) {
      ev.preventDefault();
      ev.currentTarget.blur();
    }
  };

  toggleHelpTip = (ev) => {
    ev.preventDefault();
    const newState = !this.state.hideHelpTipChecked;
    this.props.onToggleHelpTip(newState);
    this.setState({ hideHelpTipChecked: newState });
  };
}

const mapStateToProps = (state) => {
  return {
    undoableBooking: state.gridViewState.get('undoableBooking'),
    confirmMoveEnabled: state.locationConfig.get('confirmMoveEnabled'),
    hideHelpTipFromStart: state.userClientPreferences.getIn(['user', 'hideUndoMoveHelpTip']) === true
  };
};

const mapDispatchToProps = (dispatch, { match }) => {
  return {

    onClearUndoable: () => {
      dispatch(setUndoableBooking(null));
    },

    onUndoLastMove: () => {
      dispatch(undoMove(translateRouteParams(match.params)));
    },

    onToggleHelpTip: (value) => {
      dispatch(setUserPreference(false, { hideUndoMoveHelpTip: value }));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoButton));
