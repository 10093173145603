var View = Chaplin.View.extend({

   optionNames: Chaplin.View.prototype.optionNames.concat(['template']),

   initialize: function () {
      Chaplin.View.prototype.initialize.call(this, arguments);
   },

   getTemplateData: function () {
      return this.model.attributes;
   },

   getTemplateFunction: function () {
      return this.template;
   },

   pass: function(selector, attribute){
      var $el = this.$(selector);
      if ($el) {
         $el.text(this.model.get(attribute));
      }
      this.listenTo(this.model, "change:" + attribute, function(model, value){
         this.$(selector).text(model.get(attribute));
      });
   }
});
module.exports = View;