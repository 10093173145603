import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SocialContactContent from './social-contact-content';

import { msgVersionMismatchAlert as msg } from '../../localization/messages/components/dialogs';

class VersionMismatchAlert extends Component {

  render() {
    return this.props.currentVersion !== this.props.requiredVersion ? (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2><FormattedMessage {...msg.heading} /></h2>
          <hr />
          <p>
            <FormattedMessage {...msg.description} values={{ b: (...chunks) => <strong>{chunks}</strong> }} />
          </p>
          <br />
          <SocialContactContent />
          <hr />
          <button onClick={this.refresh} className="button arrow"><FormattedMessage {...msg.btnReload} /></button>
        </div>
      </div>
      ) : null;
  }

  refresh = (e) => {
    e.preventDefault();
    window.location.reload(true);
  };
}


const mapStateToProps = (state) => {
  return {
    currentVersion: state.appState.get('currentVersion'),
    requiredVersion: state.appState.get('requiredVersion')
  };
};

const VersionMismatchAlertContainer = connect(
      mapStateToProps
)(VersionMismatchAlert);


export default VersionMismatchAlertContainer;
