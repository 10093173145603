import moment from 'moment';

export function trialExpired(trialStatus) {
  return trialStatus === 'TrialExpired';
}

export function accountNotActive(accountStatus) {
  return accountStatus !== 'Active';
}

export function accountBlocked(trialStatus, accountStatus) {
  return trialStatus === 'TrialExpired' || accountStatus === 'BlockedPaymentRequired' || accountStatus === 'BlockedManual';
}

export function actionRequired(trialStatus, accountStatus) {
  return trialExpired(trialStatus) || accountNotActive(accountStatus);
}

export function setAccountInfoDismissed() {
  localStorage.setItem('accountInfoDismissed', moment().valueOf().toString());
}

export function getAccountInfoDismissed() {
  const value = localStorage.getItem('accountInfoDismissed');
  return value ? moment(parseInt(value)) : null;
}

export function shouldShowAccountInfo(trialStatus, trialUntil, accountStatus) {
  const dismissed = getAccountInfoDismissed();
  const accountInactive = accountNotActive(accountStatus);
  const accountIsBlocked = accountBlocked(trialStatus, accountStatus);
  let showAccountInfo = true;


  if (dismissed) {
    const yesterday = moment().subtract(1, 'days');
    const oneWeekAgo = moment().subtract(7, 'days');
    const twoWeeksAgo = moment().subtract(14, 'days');

    if (accountInactive && dismissed.isAfter(yesterday)) {
      showAccountInfo = false;
    }

    if (trialUntil) {
      const daysLeft = trialUntil.diff(moment(), 'days');

      if (daysLeft >= 14 && dismissed.isAfter(twoWeeksAgo)) {
        showAccountInfo = false;
      } else if (daysLeft > 3 && dismissed.isAfter(oneWeekAgo)) {
        showAccountInfo = false;
      } else if (dismissed.isAfter(yesterday)) {
        showAccountInfo = false;
      }
    }
  }
  return showAccountInfo && (trialUntil || accountInactive) && !accountIsBlocked;
}

export function setStorageConfigDismissed() {
  localStorage.setItem('storageConfigDismissed', moment().valueOf().toString());
}

export function getStorageConfigDismissed() {
  const value = localStorage.getItem('storageConfigDismissed');
  return value ? moment(parseInt(value)) : null;
}

export function shouldShowStorageConfigAlert() {
  const dismissed = getStorageConfigDismissed();
  const dismissPeriod = moment().subtract(8, 'hours');

  return dismissed ? dismissed.isBefore(dismissPeriod) : true;
}
