import Cookies from 'universal-cookie';
import { authCookieName } from './config';

const LEGACY_AUTH_TOKEN = 'AUTH_TOKEN';

const cookies = new Cookies();

const CookieDomain = {
  initialize(domain) {
    this.domain = domain;
  },
  getDomain() {
    return this.domain;
  }
};

function setCookie(name, value) {
  const domain = getCookieDomain();
  const secure = !!domain;

  if (value) {
    cookies.set(name, value, {
      expires: new Date(2034, 7, 26),
      domain,
      secure,
      path: '/'
    });
  } else {
    cookies.remove(name, {
      domain,
      secure,
      path: '/'
    });
  }
}

function migrateAuthToken(legacyToken) {
  if (legacyToken) {
    setCookie(LEGACY_AUTH_TOKEN, null);
    setCookie(authCookieName(), legacyToken);
  }
}

function getCookieDomain() {
  return CookieDomain.getDomain();
}

export function initCookieDomain(domain) {
  CookieDomain.initialize(domain);
}

export function getAuthTokenCookie() {
  migrateAuthToken(cookies.get(LEGACY_AUTH_TOKEN));
  return cookies.get(authCookieName());
}

export function setAuthTokenCookie(token) {
  setCookie(authCookieName(), token);
}
