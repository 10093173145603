import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

class TimeRangeInput extends Component {

  render() {
    const { input: { value }, label, helpText, meta: { touched, error } } = this.props;

    const values = value ? value.split('-') : ['09:00', '20:00'];

    return (
      <div className={touched && error ? 'form-group has-error' : 'form-group'}>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-4">
          {this.timeRangeList('startTime', values[0], this.changeStartTime)}
          {this.timeRangeList('endTime', values[1], this.changeEndTime)}
          {touched && error ? <span className="help-block">{error}</span> : null}
        </div>
      </div>
    );
  }

  timeRangeList(name, value, onChange) {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      options.push(`${(hour < 10 ? '0' : '') + hour}:00`);
    }

    return (
      <select name={name} value={value} className="form-control inline-sm" onChange={onChange}>
        {options.map((hour) => {
          return <option key={hour} value={hour}>{hour}</option>;
        })}
      </select>
    );
  }

  changeStartTime = (ev) => {
    ev.preventDefault();

    const values = this.props.input.value.split('-');
    this.props.input.onChange(`${ev.target.value}-${values[1]}`);
  };

  changeEndTime = (ev) => {
    ev.preventDefault();

    const values = this.props.input.value.split('-');
    this.props.input.onChange(`${values[0]}-${ev.target.value}`);
  };
}

TimeRangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string
};

export default TimeRangeInput;
