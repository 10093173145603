import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import {
  prefixUrl, axiosDefault, axiosErrorHandler, checkStatusAxios, isNetworkOrSafeRequestError
} from '../utils/ajax-util';
import { saveHighContrastEnabled, saveGridSize, saveExternalKeyboardEnabled } from '../utils/local-settings';
import { getElementDimensions } from '../utils/dimensions';
import { resourceIdFromColIdx } from '../components/grid/grid-state-helper';
import { setBKFBooking } from './bkf-actions';
import { addTempBooking } from './booking-actions';


export const SELECT_DATE = 'SELECT_DATE';
export const RECEIVE_VIEWDATA = 'RECEIVE_VIEWDATA';

export const TOGGLE_GRIDMARKER = 'TOGGLE_GRIDMARKER';
export const UPDATE_GRIDMARKER = 'UPDATE_GRIDMARKER';
export const TOGGLE_DATEPICKER = 'TOGGLE_DATEPICKER';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST';
export const SET_EXTERNAL_KEYBOARD = 'SET_EXTERNAL_KEYBOARD';
export const SET_CALENDAR_ROWS_PER_HOUR = 'SET_CALENDAR_ROWS_PER_HOUR';
export const SET_GRID_SIZE = 'SET_GRID_SIZE';
export const TOGGLE_SORT_RESOURCES = 'TOGGLE_SORT_RESOURCES';

export const TOGGLE_SCHEDULE_EDIT_MODE = 'TOGGLE_SCHEDULE_EDIT_MODE';

export const TOGGLE_GRID_SCROLLABILITY = 'TOGGLE_GRID_SCROLLABILITY';

export const STORE_VIEW_STATE = 'STORE_VIEW_STATE';


export function storeViewState(viewState) {
  return {
    type: STORE_VIEW_STATE,
    viewState
  };
}

export function toggleScheduleEditMode(state) {
  return {
    type: TOGGLE_SCHEDULE_EDIT_MODE,
    state
  };
}

export function updateViewDimensions() {
  const dimensions = getElementDimensions();

  const phoneMode = window.matchMedia('(max-width: 600px)').matches;
  const tabletMode = window.matchMedia('(min-width: 600px) and (max-width: 1000px)').matches;
  const useWideLabel = window.matchMedia('(min-width: 768px)').matches;
  const orientation = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';

  const update = {
    type: UPDATE_DIMENSIONS,
    ...dimensions,
    phoneMode,
    tabletMode,
    useWideLabel,
    orientation
  };

  return update;
}

export function toggleSortResource(sorting) {
  return {
    type: TOGGLE_SORT_RESOURCES,
    sorting
  };
}

export function toggleDatePicker(visible) {
  return {
    type: TOGGLE_DATEPICKER,
    visible
  };
}

export function toggleGridScrollability(scrollable) {
  return {
    type: TOGGLE_GRID_SCROLLABILITY,
    scrollable
  };
}


export function dragSelected(event, routeParams) {
  return (dispatch, getState) => {
    const { orderedGroups } = getState();
    const { entityType, entityId } = routeParams;
    const resourceId = resourceIdFromColIdx(orderedGroups, entityType, entityId, event.colIdx);

    const gridMarkerState = {
      visible: false,
      duration: event.duration
    };

    // createdTime,
    //   cancelledTime,
    //   lastUpdateTime,
    //   channel,
    //   cancelledChannel

    const tempBooking = {
      id: 'DRAGGER',
      type: 'SimpleBooking',
      dropIn: false,
      askedForPerson: false,
      startTime: event.startTime,
      endTime: event.endTime,
      resourceId,
      status: 'BookedDrag',
      smsStatus: 'NEW',
      channel: 'Dashl',
      pending: false,
      reservation: false
    };
    dispatch(setBKFBooking(tempBooking));
    dispatch(addTempBooking(tempBooking));
    dispatch(toggleGridMarker(gridMarkerState));
    dispatch(toggleGridScrollability(false));

    try {
         // dispatch(showForm(true, Object.assign({}, tempBooking, {chipId: 'DRAGGER'})));

         // Chaplin.mediator.publish('booking:dragselect', {
         //    parent: $(event.chipEl),
         //    startTime: event.startTime,
         //    endTime: event.endTime,
         //    resourceId
         // });

    } catch (e) {
      console.error(e);
      dispatch(toggleGridMarker({
        visible: false,
        duration: 60
      }));
    }
  };
}

export function updateGridMarker(update) {
  return {
    type: UPDATE_GRIDMARKER,
    update
  };
}

export function toggleGridMarker(state) {
  return {
    type: TOGGLE_GRIDMARKER,
    state
  };
}

export function setHighContrast(enabled) {
  saveHighContrastEnabled(enabled);
  return {
    type: SET_HIGH_CONTRAST,
    enabled
  };
}

export function setCalendarGridRowsPerHour(rowsPerHour) {
  return {
    type: SET_CALENDAR_ROWS_PER_HOUR,
    rowsPerHour
  };
}

export function setGridSize(size) {
  saveGridSize(size);
  return {
    type: SET_GRID_SIZE,
    size
  };
}

export function setExternalKeyboard(enabled) {
  saveExternalKeyboardEnabled(enabled);
  return {
    type: SET_EXTERNAL_KEYBOARD,
    enabled
  };
}

function receiveViewData(viewData) {
  return {
    type: RECEIVE_VIEWDATA,
    viewData,
    receivedAt: Date.now()
  };
}

const axiosWithRetry = axios.create();
axiosRetry(axiosWithRetry, { retryDelay: exponentialDelay, retries: 5, retryCondition: isNetworkOrSafeRequestError });

export function fetchViewData(viewMode, viewDate, entityType, entityId) {
  return (dispatch) => {
    const loadData = entityId > 0;
    if (loadData) {
      const url = prefixUrl(`/${viewMode}/${viewDate}/${entityType}/${entityId}`);

      return axiosWithRetry.get(url, axiosDefault())
        .then(res => dispatch(checkStatusAxios(res)))
        .then(res => res.data)
        .then(viewData => dispatch(receiveViewData(viewData)))
        .catch(error => axiosErrorHandler(error, dispatch));
    }
  };
}
