import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CurrencyUtil from '../../../utils/currency-util';

import { msgBookingService as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editService: props.initialValues && props.initialValues.isNew
    };
  }

  componentDidMount() {
    if (this.state.editService) {
      this.focusServiceField();
    }
  }

  focusServiceField = () => {
    if (this.service) {
      const service = ReactDOM.findDOMNode(this.service);
      service.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { services } = this.props;
    const hasMultipleServices = services && services.size > 1;

    if (hasMultipleServices) {
      this.props.onSearchStart();
    } else {
      this.focusHiddenField();
      this.setState({ editService: true }, () => this.focusServiceField());
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editService: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onClearService();
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  render() {
    const { editService } = this.state;
    const { services, name, status, intl: { formatMessage: f } } = this.props;
    const isCancelled = status === 'Cancelled';
    const hasServices = services && services.size > 0;
    const hasOneService = services && services.size === 1;
    const description = hasOneService && name;

    if (isCancelled && !(hasServices || name)) {
      return null;
    }

    if (editService) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblDescription)}</label>
              <Field name="name" component="input" type="text" className="form-control" ref={(ref) => { this.service = ref; }} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!hasServices && !name) {
      return (
        <button className="booking-form-block button" onClick={this.props.onSearchStart}>
          <i className="fa fa-cog" /> {f(msg.btnAddService)}
        </button>
      );
    }

    return (
      <div className={isCancelled ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isCancelled ? this.handleClick : null}>
        {!isCancelled && (
          <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
            <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>{f(msgButtons.btnChange)}</button><br />
            <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{f(msgButtons.btnClear2)}</button>
          </div>
        )}
        {hasServices ? (
          <div className="service-list">
            {services.valueSeq().map(({ id, name, duration, serviceDuration, price, priceFrom, currency }) => {
              return (
                <div key={id} className="service-item">
                  <h4>{description || name}</h4>
                  {f(msg.duration, { duration: serviceDuration || duration })}
                  {price > 0 ? `, ${f(msg.price, { priceFrom, price: CurrencyUtil.accountCurrency(price, 0, currency) })}` : null}
                </div>
              );
            })}
          </div>
        ) : (
          <h4>{name}</h4>
        )}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    initialValues: bkf.get('service'),
    services: bkf.get('services'),
    ...bkf.get('attributes'),
    ...getFormValues('bkf-service')(state)
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-service',
  destroyOnUnmount: false
})(BookingService)));
