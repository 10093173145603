import { defineMessages } from 'react-intl';

export const msgLabels = defineMessages({
  calendar: {
    id: 'label.calendar',
    defaultMessage: 'Kalender'
  },
  web: {
    id: 'label.web',
    defaultMessage: 'Webb'
  },
  show: {
    id: 'label.show',
    defaultMessage: 'Show'
  },
  noShow: {
    id: 'label.noShow',
    defaultMessage: 'No show'
  },
  cancelled: {
    id: 'label.cancelled',
    defaultMessage: 'Avbokade'
  },
  other: {
    id: 'label.other',
    defaultMessage: 'Övriga'
  },
  others: {
    id: 'label.others',
    defaultMessage: 'Övrigt'
  },
  askedForPerson: {
    id: 'label.askedForPerson',
    defaultMessage: 'Bokat person'
  },
  dropIn: {
    id: 'label.dropIn',
    defaultMessage: 'Drop in'
  },
  total: {
    id: 'label.total',
    defaultMessage: 'Totalt'
  },
  time: {
    id: 'label.time',
    defaultMessage: 'Tid'
  },
  service: {
    id: 'label.service',
    defaultMessage: 'Tjänst'
  },
  price: {
    id: 'label.price',
    defaultMessage: 'Pris'
  },
  vehicle: {
    id: 'label.vehicle',
    defaultMessage: 'Fordon'
  },
  business: {
    id: 'label.business',
    defaultMessage: 'Företag'
  },
  contact: {
    id: 'label.contact',
    defaultMessage: 'Kontaktperson'
  },
  customer: {
    id: 'label.customer',
    defaultMessage: 'Kund'
  },
  phone: {
    id: 'label.phone',
    defaultMessage: 'Telefon'
  },
  source: {
    id: 'label.source',
    defaultMessage: 'Källa'
  },
  status: {
    id: 'label.status',
    defaultMessage: 'Status'
  },
  reservation: {
    id: 'label.reservation',
    defaultMessage: 'Bokning'
  },
  minute: {
    id: 'label.minute',
    defaultMessage: 'min'
  },
  durationSrt: {
    id: 'label.durationSrt',
    defaultMessage: '{isAfterTime, select, true {{duration} min + {afterTime} min paus} other {{duration} min}}'
  },
  priceSrt: {
    id: 'label.priceSrt',
    defaultMessage: '{priceFrom, select, true {Från } other {}}{price}'
  }
});
