var CollectionView = require('../base/collection-view');
var WeekView = require('./week-view');

module.exports = CollectionView.extend({
   noWrap: true,
   autoRender: true,

   initialize: function() {
      this.initCollection();

      this.template = require('./weeks');
      CollectionView.prototype.initialize.call(this, arguments);

      this.model.on('change:weeks', _.bind(this.initCollection, this));
   },

   initItemView: function (item) {
      var itemView = new WeekView({model: item});
      this.listenTo(itemView, 'item:click', _.bind(this.itemClick, this));
      return itemView;
   },

   itemClick: function(item) {
      this.currentWeek = item.model.get('week');
      this.trigger('week:changed', this.currentWeek);

      this.$('.btn').removeClass('active');
      item.$el.addClass('active');
   },

   resetCurrentWeek: function() {
      this.currentWeek = 1;
      this.trigger('week:changed', this.currentWeek);

      this.$('.btn').removeClass('active');
      this.$('.btn:first-child').addClass('active');
   },

   initCollection: function() {
      var weeks = parseInt(this.model.get('weeks'));

      var array = [];
      for(var i = 1; i <= weeks; i++) {
         array.push({ week: i });
      }

      this.collection = new Chaplin.Collection();
      if(array.length > 1) {
         this.collection.reset(array);
      }
      this.render();
      this.resetCurrentWeek();
   }
});