var PhoneUtil = require('../utils/phone-util');
var CustomerLog = require('../collections/customer-log');

module.exports = Chaplin.Model.extend({
	idAttribute:'customerId',
	urlRoot: '/customers',

	defaults:{
    'logEntries': new CustomerLog(),
    'allowMarketing': 'true',
    'smsReminders': 'true'
	},

	initialize: function () {
	},

	parse: function(data, options) {
		if (data.phoneNumber) {
			data.phoneNumber = PhoneUtil.formatPhoneNumber(data.phoneNumber);
		}

		if (data.otherPhoneNumber) {
			data.otherPhoneNumber = PhoneUtil.formatPhoneNumber(data.otherPhoneNumber);
		}

		if (data.id) {
			data.customerId = data.id;
		}

		if (data.logEntries){
			this.attributes.logEntries.reset(data.logEntries);
		}

      return data;
	},

	set: function (attributes, options) {
		if (attributes.phoneNumber !== undefined) {
			attributes.phoneNumber = PhoneUtil.formatPhoneNumber(attributes.phoneNumber);
		}

		if (attributes.otherPhoneNumber !== undefined) {
			attributes.otherPhoneNumber = PhoneUtil.formatPhoneNumber(attributes.otherPhoneNumber);
		}

		if (attributes.logEntries !== undefined && !(attributes.logEntries instanceof CustomerLog)) {
			attributes.logEntries = new CustomerLog(attributes.logEntries);
		}

		if (attributes.id !== undefined) {
			attributes.customerId = attributes.id;
		}

		return Backbone.Model.prototype.set.call(this, attributes, options);
	}
});
