var moment = require('moment');

module.exports = Chaplin.Collection.extend({
   url: function() {
      return '/schedules/resource/' + this.resourceId + '/exceptions/';
   },

   comparator: function( collection ){
      return moment(collection.get('date'));
   },

   parse: function (data) {
      var Util = require('../views/schedule/util');
      var parsedData = Util.convertExceptions(data);
      return parsedData;
   },

   fetch: function(resourceId){
      this.resourceId = resourceId;
      Chaplin.Collection.prototype.fetch.call(this, arguments);
   }
});
