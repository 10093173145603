import moment from 'moment';
import { formatMessage } from '../intlContextInterseptor';

import { msgValidation as msg } from '../localization/messages/utils/utils';

const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];

export function required(value) {
  if (isEmpty(value)) {
    return formatMessage(msg.required);
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return formatMessage(msg.minLength, { min });
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return formatMessage(msg.maxLength, { max });
    }
  };
}

export function pattern(pattern, error) {
  return (value) => {
    const regexp = new RegExp(pattern);
    if (!isEmpty(value) && !value.match(pattern)) {
      return error || formatMessage(msg.pattern, { pattern: regexp.toString() });
    }
  };
}

export function timePeriod(minPeriodString, maxDurationString) {
  return (value) => {
    const duration = moment.duration(value);
    const minDuration = moment.duration(minPeriodString);
    const maxDuration = moment.duration(maxDurationString);

    if (minDuration && duration < minDuration) {
      return formatMessage(msg.minDuration, { min: minDuration.humanize() });
    }
    if (maxDuration && duration > maxDuration) {
      return formatMessage(msg.maxDuration, { max: maxDuration.humanize() });
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export const number = value =>
  value && isNaN(Number(value)) ? formatMessage(msg.number) : undefined;

export const minValue = min => value =>
  value && value < min ? formatMessage(msg.minDuration, { min }) : undefined;

export const maxValue = max => value =>
  value && value > max ? formatMessage(msg.maxValue, { max }) : undefined;
