var View = require('./view');
var FormUtil = require('../../utils/form-util');

module.exports = View.extend({

   noWrap: false,
   autoRender: true,
   containerMethod: 'html',

   events:{
      'submit form':'save',
      'click .js-cancel':'cancel',
		'dirty.areYouSure form': 'dirty',
		'clean.areYouSure form': 'clean'
   },

   listen: {
      'change model':'populateForm'
   },

   initialize: function(){
      View.prototype.initialize.call(this, arguments);
   },

	dirty: function(){
		this.$('button[type="submit"]').removeClass('disabled');
		this.$('.js-cancel').removeClass('disabled');
	},

	clean: function(){
		this.$('button[type="submit"]').addClass('disabled');
		this.$('.js-cancel').addClass('disabled');
	},

   serializeForm: function () {
      return this.$('form').serializeJSON();
   },

   save: function(e){
      e.preventDefault();
      throw new Error('Save must be implemented!');
   },

	cancel: function(){
		this.populateForm();
		this.trigger('cancel');
		return false;
	},

	render: function(){
		View.prototype.render.call(this, arguments);
      this.populate(this.model);
      this.initDirtyTracking();
	},

	initDirtyTracking: function(){
		this.$('form').areYouSure({silent:true});
	},

    checkForm: function() {
       this.$('form').trigger('checkform.areYouSure');
    },

	resetDirty: function(){
		this.$('form').trigger('reinitialize.areYouSure');
		this.$('button[type="submit"]').addClass('disabled');
		this.$('.js-cancel').addClass('disabled');
	},

   populate: function(item){
      this.model = item;
      this.populateForm();
	},

   populateForm:function(){
      FormUtil.populate(this.$('form'), this.model.attributes);
      this.resetDirty();
   }
});
