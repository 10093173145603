import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { msgCurrentDateLabel as msg } from '../../localization/messages/components/calendar';

class CurrentDateLabel extends Component {
  render() {
    const { viewDateStart } = this.props;

    return (
      <div className="navbar-left navbar-date-label">
        <span className={this.classNames()}>
          {this.dateLabel()}
        </span>
        <span className="toolbar-week-label">
          <FormattedMessage {...msg.label} values={{ week: viewDateStart.format('W') }} />
        </span>
      </div>
    );
  }

  weekLabel() {
    const { phoneMode, tabletMode, viewDateStart } = this.props;
    const viewDateEnd = moment(viewDateStart).endOf('isoWeek');

    return phoneMode || tabletMode ?
      `${viewDateStart.format('D')} - ${viewDateEnd.format('D MMM -YY')}` :
      `${viewDateStart.format('D MMM')} - ${viewDateEnd.format('D MMM, YYYY')}`;
  }

  dayLabel() {
    // TODO: Adjust content if width is smaller than 375px (iphone 6/7/8)
    const { phoneMode, viewDateStart } = this.props;

    return phoneMode ? viewDateStart.format('D MMM, YYYY') : viewDateStart.format('ddd D MMM, YYYY');
    // return viewDateStart.format('ddd D MMM, YYYY');
  }

  dateLabel() {
    const { viewMode } = this.props;
    return viewMode === 'week' ? this.weekLabel() : this.dayLabel();
  }

  classNames() {
    let classes = 'toolbar-date-label';

    if (this.checkIfPast()) {
      classes += ' past';
    }

    return classes;
  }

  checkIfPast() {
    const { viewMode, viewDateStart } = this.props;

    const now = viewMode === 'week' ? moment().startOf('isoWeek') : moment().startOf('day');

    return moment(viewDateStart).isBefore(now);
  }
}


CurrentDateLabel.propTypes = {
  viewMode: PropTypes.oneOf(['week', 'day']).isRequired,
  phoneMode: PropTypes.bool.isRequired,
  tabletMode: PropTypes.bool.isRequired,
  viewDateStart: PropTypes.object.isRequired
};

export default CurrentDateLabel;
