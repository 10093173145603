import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { msgCompanyInfo as msg } from '../../../localization/messages/components/account-config';
import { msgButtons } from '../../../localization/messages/shared/buttons';

const CompanyInfoForm = ({ handleSubmit, submitting, onCancel }) => {
  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={handleSubmit} className="form-horizontal">
      <div className="form-group">
        <label className="control-label col-xs-3">{f(msg.orgName)}</label>
        <div className="col-xs-5">
          <Field name="orgName" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label className="control-label col-xs-3">{f(msg.companyRegNo)}</label>
        <div className="col-xs-5">
          <Field name="companyRegNo" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <div className="col-xs-offset-3 col-xs-9">
          <button type="submit" className="btn btn-success" disabled={submitting}>{f(msgButtons.btnSave)}</button> &nbsp;
          <button type="button" className="btn btn-default" disabled={submitting} onClick={onCancel}>{f(msgButtons.btnCancel)}</button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'company-info-form'
})(CompanyInfoForm);
