import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { blockClosed } from './grid-util';

import { msgColumnWebIndicator as msg } from '../../localization/messages/components/grid';

class ColumnWebIndicator extends Component {
  static propTypes = {
    scheduleBlocks: PropTypes.array.isRequired,
    column: PropTypes.object.isRequired
  };

  toolTipText = ({ webOpen, vipOpen, closedMax, bookingMaxDaysInAdvance }, dayPast) => {
    const { intl: { formatMessage: f } } = this.props;
    if (!webOpen && !vipOpen) {
      return f(msg.disabledForResource);
    }
    if (dayPast) {
      return f(msg.dayPast);
    }
    if (closedMax) {
      return f(msg.closedMax, { bookingMaxDaysInAdvance });
    }
    if (webOpen && vipOpen) {
      return f(msg.webAndVipOpen);
    }
    if (webOpen) {
      return f(msg.webOpen);
    }
    if (vipOpen) {
      return f(msg.vipOpen);
    }
  }

  render() {
    const { scheduleBlocks, column, intl: { formatMessage: f } } = this.props;

    const block = scheduleBlocks.find((value) => {
      return value.day === column.date
        && value.resId === column.resId
        && value.blockIndex === 0;
    });

    let closed = true,
      dayPast = false,
      text = f(msg.allClosed);

    if (block) {
      const { day } = block;
      closed = blockClosed(block);
      dayPast = moment(day).isBefore(moment(), 'day');
      text = this.toolTipText(block, dayPast);
    }

    return (
      <div className="web-indicator-container" data-tip={text}>
        <div className={closed ? 'web-indicator closed' : 'web-indicator open'} />
      </div>
    );
  }
}

export default injectIntl(ColumnWebIndicator);
