import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { msgBookingCompany as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editCompany: props.company && !props.company.companyId
    };
  }

  componentDidMount() {
    if (this.state.editCompany) {
      this.focusEditField();
    }
  }

  handleClick = (ev) => {
    ev.preventDefault();

    const hidden = ReactDOM.findDOMNode(this.hidden);
    hidden.style.visibility = 'visible';
    hidden.focus();
    hidden.style.visibility = 'hidden';

    this.setState({ editCompany: true }, () => this.focusEditField());
  };

  focusEditField = () => {
    if (this.orgName) {
      const input = ReactDOM.findDOMNode(this.orgName);
      input && input.focus();
    }
  };

  handleSearch = (ev) => {
    ev.preventDefault();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editCompany: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    this.props.onClearCompany();
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  render() {
    const { editCompany } = this.state;
    const { company, orgName, orgNo, status, intl: { formatMessage: f } } = this.props;
    const savedWithOrgNo = company && company.companyId && company.orgNo && company.orgNo.length > 0;
    const isCancelled = status === 'Cancelled';

    if (isCancelled && !company) {
      return null;
    }

    if (editCompany) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblOrgName)}</label>
              <Field autoComplete="off" name="orgName" component="input" type="text" className="form-control" ref={(ref) => { this.orgName = ref; }} />
            </div>
          </div>
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblOrgNo)}</label>
              <Field autoComplete="off" name="orgNo" component="input" type="text" className="form-control" disabled={savedWithOrgNo} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!company) {
      return (
        <button className="booking-form-block button" onClick={this.handleSearch}>
          <i className="fa fa-building" /> {f(msgButtons.btnAddCompany)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className={isCancelled ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isCancelled ? this.handleClick : null}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{F(msgButtons.btnClear2)}</button>}
        </div>
        <h4>{orgName}</h4>
        {orgNo}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const company = bkf.get('company');

  return {
    company,
    initialValues: company,
    ...bkf.get('attributes'),
    ...getFormValues('bkf-company')(state)
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-company',
  destroyOnUnmount: false
})(BookingCompany)));
