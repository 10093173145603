var DirtyTrackedFormView = require('../../base/dirty-tracked-form-view');

module.exports = DirtyTrackedFormView.extend({

   initialize: function () {
      this.template = require('./notifications');
      DirtyTrackedFormView.prototype.initialize.call(this, arguments);
   },

   render: function(){
      DirtyTrackedFormView.prototype.render.call(this, arguments);
      this.$('form').parsley();
   },

   save: function(e) {
      e.preventDefault();

      var data = this.serializeForm();

      $.ajax({
         type: 'PUT',
         contentType: 'application/json; charset=utf-8',
         dataType: 'json',
         url: '/notifications',
         data: JSON.stringify(data),
         success: _.bind(this.saveSuccess, this, data)
      });
   },

   saveSuccess: function(formData) {
      this.model.set(formData);
      this.resetDirty();
   }
});