export function getElementDimensions() {
  const gridEl = document.getElementById('grid');
  const gridClientWidth = gridEl != null ? gridEl.clientWidth : 0;

  const windowHeight = $(window).height();
  const navHeight = $('nav.navbar-static-top').outerHeight();
  const tabsHeight = $('nav.navbar-tabs.calendar').outerHeight();
  const bannerHeight = $('#gridbanner').outerHeight();
  const clipboardHeight = $('#clipboardbanner').outerHeight();
  const columnsHeight = $('#columncontainer').outerHeight();
  const gridClientHeight = windowHeight - navHeight - tabsHeight - bannerHeight - clipboardHeight - columnsHeight;

  return {
    gridClientWidth,
    gridClientHeight
  };
}
