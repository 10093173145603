import 'babel-polyfill';
import 'whatwg-fetch';
import 'backbone';
import 'chaplin';
import 'underscore';
import 'bootstrap';
import 'twbs-pagination';
import 'jquery.are-you-sure';
import 'jquery-serializejson';
import 'jquery-timepicker/jquery.timepicker';
import 'parsleyjs';
import 'parsleyjs/dist/i18n/sv';

import Immutable from 'immutable';
import React from 'react';
import ReactDOM from 'react-dom';
import UAParser from 'ua-parser-js';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-intl-redux';
import { IntlContextInterseptor } from './intlContextInterseptor';

import '../../vendor/js/bootstrap-datepicker';
import '../../vendor/styles/vendor.less';
import '../styles/index.less';

import { buildChaplinToReduxBridges } from './chaplin-bridges/chaplin-to-redux-support';
import { calculateScrollbarWidth } from './components/grid/grid-util';
import { getGridSize, isHighContrastEnabled, isExternalKeyboardEnabled } from './utils/local-settings';
import { getHistory } from './utils/navigate';
import { initApiUrl } from './utils/url-util';
import { initCookieDomain } from './utils/cookies';
import { apiUrl } from './utils/config';
import { getLocaleFromUrl, getTopLevelDomain } from './utils/localization-util';
import { clearSession } from '../redux-login/actions';
import configureStore from './configureStore';
import LocationConfigEnforcer from './location-config-enforcer';
import Login from '../redux-login/login-form';
import App from './app';

let messageFile = {};
const hostname = window.location.hostname;
const locale = getLocaleFromUrl(hostname);
const tld = getTopLevelDomain(hostname);

import(`../../vendor/js/locales/bootstrap-datepicker.${locale}`);
import(`./localization/${locale}.json`).then((module) => {
  messageFile = module.default;
});

const load = () => {
  const initialState = createInitialState();
  const store = configureStore(initialState);
  initApiUrl(apiUrl(hostname));
  initCookieDomain($`.dashl.${tld}`);
  buildChaplinToReduxBridges(store);
  initLegacyStuff();

  ReactDOM.render(
    <Provider store={store}>
      <IntlContextInterseptor>
        <Router history={getHistory()}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" exact render={logOut} />
            <Route path="/:org?/:loc?/" render={props => <LocationConfigEnforcer {...props} component={App} />} />
          </Switch>
        </Router>
      </IntlContextInterseptor>
    </Provider>,
    document.getElementById('react-root')
  );
};

function logOut() {
  clearSession();
  location.href = '/';
}

function createInitialState() {
  const sbw = calculateScrollbarWidth();
  const scrollBars = sbw > 0;
  const scrollBarWidth = sbw;
  // TODO: Det här behöver uppdateras när storleken ändras, t.ex. resize, eller när man flippar telefonen.
  const phoneMode = window.matchMedia('(max-width: 600px)').matches;
  const tabletMode = window.matchMedia('(min-width: 600px) and (max-width: 1000px)').matches;
  const useWideLabel = window.matchMedia('(min-width: 768px)').matches;
  const externalKeyboard = isExternalKeyboardEnabled();
  const highContrast = isHighContrastEnabled();
  const gridSize = getGridSize();
  const parser = new UAParser();
  const deviceType = parser.getDevice().type || 'desktop';
  const deviceOs = parser.getOS().name;

  const initialState = {
    intl: {
      locale,
      messages: messageFile
    },
    gridViewState: Immutable.Map({
      pixelsPerRow: 15,
      rowsPerHour: 6,
      gridMarkerDuration: 60,
      gridClientWidth: 500,
      gridClientHeight: 500,
      highContrast,
      externalKeyboard,
      largeCalendar: false,
      showGridMarker: false,
      clipBoardDragger: null,
      scheduleEditMode: false,
      undoableBooking: null,
      gridSize,
      gridScrollable: true, // if set to true, prevent scrolling of the grid view, when popups are open etc..
      scrollBars,
      scrollBarWidth
    }),
    mainViewState: Immutable.Map({
      phoneMode,
      tabletMode,
      useWideLabel,
      deviceType,
      deviceOs
    })
  };

  return initialState;
}

/*
 * THIS IS THE THINGS THAT IS REQUIRED FOR CHAPLIN CODEBASE
 */

const Application = require('../utils/application');
const HandlebarsHelpers = require('../utils/handlebars-helpers');
const Validation = require('../utils/validation');
const AlertOverlayView = require('../views/alert');
const AjaxConfig = require('../ajax/ajax-config');

/* eslint-disable no-new */
function initLegacyStuff() {
  AjaxConfig.configure();
  new AlertOverlayView();

  HandlebarsHelpers.initialize();
  Validation.initialize();

  $.serializeJSON.defaultOptions.checkboxUncheckedValue = 'false';
  $.serializeJSON.defaultOptions.parseBooleans = true;

  new Application({
    title: 'Dashl',
    controllerSuffix: '-controller',
    routeLinks: false
  });
}
/* eslint-enable no-new */

if (document.readyState !== 'complete') {
  window.addEventListener('load', load);
} else {
  load();
}
