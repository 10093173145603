import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { addServiceMapping, deleteServiceMapping, serviceMappingValues, setServiceMappings } from '../../state/services-actions';
import { Popover } from '../common/popover';
import CustomServiceSettingsModal from './custom-service-settings-modal';

import { msgResourceServiceMappings as msg } from '../../localization/messages/components/services';
import { msgButtons } from '../../localization/messages/shared/buttons';

class ResourceServiceMappings extends Component {
  static propTypes = {
    resourcesById: PropTypes.object.isRequired,
    serviceId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      saved: false,
      showConfirmSelectAll: false,
      customServiceMapping: null
    };
  }

  showConfirmSelectAll = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmSelectAll: true });
  };

  hideConfirmSelectAll = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showConfirmSelectAll: false });
  };

  selectAll = (ev) => {
    ev.preventDefault();
    this.props.setServiceMappings(this.props.serviceId, this.props.resourcesById.keySeq().toArray())
      .then(() => this.hideConfirmSelectAll());
  };

  selectResource = (ev, resourceId) => {
    if (ev.target.checked) {
      this.addMapping(resourceId);
    } else {
      this.deleteMapping(resourceId);
    }
  };

  addMapping = (resourceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.addServiceMapping(this.props.serviceId, resourceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  deleteMapping = (resourceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.deleteServiceMapping(this.props.serviceId, resourceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  resetSaved = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  showCustomMappingModal = (ev, resource) => {
    ev.preventDefault();
    const serviceMapping = this.getMappedService(resource);
    this.setState({ customServiceMapping: serviceMapping.set('resource', resource) });
  };

  hideCustomMappingModal = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ customServiceMapping: null });
  };

  showConfirmSelectAll = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmSelectAll: true });
  };

  hideConfirmSelectAll = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showConfirmSelectAll: false });
  };

  render() {
    const { loading, saved, customServiceMapping } = this.state;
    const { orderedGroups, resourcesById, hasCustomValues } = this.props;

    if (!resourcesById) {
      return null;
    }

    const selectedResourceIds = this.getSelectedResourceIds();
    const isAllSelected = selectedResourceIds.length === resourcesById.count();
    const btnClasses = isAllSelected ? 'btn-label-o disabled' : 'btn-label-o';

    return (
      <div>
        <h4><FormattedMessage {...msg.selectResources} /></h4>
        <span className="help-block">
          <FormattedMessage {...msg.selectResourcesHelp} />
        </span><br />

        <div>
          <Popover
            isOpen={this.state.showConfirmSelectAll}
            body={this.popoverContent()}
            onOuterAction={this.hideConfirmSelectAll}
            preferPlace="below"
          >
            <button onClick={this.showConfirmSelectAll} className={btnClasses} disabled={isAllSelected}><FormattedMessage {...msg.chooseAll} /></button>
          </Popover>
          &nbsp; &nbsp;
          {loading && <strong className="text-muted"><FormattedMessage {...msgButtons.btnSave} /></strong>}
          {saved && <strong className="text-success"><FormattedMessage {...msgButtons.btnSaved} /></strong>}
          <br /><br />
          <div className="resource-mappings">
            {orderedGroups.map((group) => {
              return group.get('resourceIds').map(id => this.renderMapping(resourcesById.get(id)));
            })}
          </div>
          {hasCustomValues && (
            <div>
              <br />
              <i className="fa fa-info-circle text-highlight-warn" /> <FormattedMessage {...msg.ownValues} />
            </div>
          )}
        </div>
        {customServiceMapping && <CustomServiceSettingsModal serviceMapping={customServiceMapping} closeModal={this.hideCustomMappingModal} />}
      </div>
    );
  }

  hasCustomDiffs = (formValues, mappedService) => {
    if (!mappedService || !mappedService.get('customValues')) {
      return false;
    }
    for (let i = 0; i < serviceMappingValues.length; i++) {
      const prop = serviceMappingValues[i];
      if (String(mappedService.get(prop)) !== String(formValues[prop])) {
        return true;
      }
    }
    return false;
  };

  renderMapping(resource) {
    const { loading } = this.state;
    const { formValues, showCustomValues } = this.props;
    const mappedService = this.getMappedService(resource);
    const hasCustomDiffs = this.hasCustomDiffs(formValues, mappedService);
    const btnClasses = mappedService ? 'btn-label-o' : 'btn-label-o disabled';
    const inputId = `resource-${resource.id}`;

    return (
      <div className="resource-mapping" key={resource.id}>
        <input id={inputId} type="checkbox" value="true" checked={!!mappedService} disabled={loading} onChange={ev => this.selectResource(ev, resource.id)} />
        <label htmlFor={inputId}>{resource.name}</label>
        <div>
          {hasCustomDiffs && <i className="fa fa-info-circle text-highlight-warn" />}
          {showCustomValues && <button type="button" className={btnClasses} onClick={ev => this.showCustomMappingModal(ev, resource)}><FormattedMessage {...msg.settings} /></button>}
        </div>
      </div>
    );
  }

  popoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmSelectAll} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        <FormattedMessage {...msg.selectAllQuestion} /><br /><br />
        <button className="btn-label-o btn-block" onClick={this.selectAll} disabled={this.state.progress}><FormattedMessage {...msg.chooseAll} /></button>
      </div>
    );
  }

  getSelectedResourceIds = () => {
    const resourceIds = [];
    this.props.resourcesById.valueSeq().forEach((resource) => {
      if (this.getMappedService(resource)) {
        resourceIds.push(resource.id);
      }
    });
    return resourceIds;
  };

  getMappedService(resource) {
    const combinedId = `${resource.id}:${this.props.serviceId}`;
    return this.props.resourceServiceMappingsByCombinedId.get(combinedId);
  }
}

const mapStateToProps = (state) => {
  const { orderedGroups, resourcesById, resourceServiceMappingsByCombinedId } = state;
  const formValues = getFormValues('edit-service-form')(state);

  return {
    orderedGroups,
    resourcesById,
    resourceServiceMappingsByCombinedId,
    formValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addServiceMapping: (serviceId, resourceId) => {
      return dispatch(addServiceMapping(serviceId, resourceId));
    },
    deleteServiceMapping: (serviceId, resourceId) => {
      return dispatch(deleteServiceMapping(serviceId, resourceId));
    },
    setServiceMappings: (serviceId, resourceIds) => {
      return dispatch(setServiceMappings(serviceId, resourceIds));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceServiceMappings);
