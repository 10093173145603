import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChangePassword from '../components/user-settings/change-password';
import Notifications from '../components/user-settings/notifications';

import { fetchGroupsAndResources } from '../state/resource-actions';
import { fetchPasswordPolicy, changeNotifications, changePassword, receiveNotifications } from '../state/user-actions';

class UserConfig extends Component {

  componentDidMount() {
    if (this.props.resourcesById.isEmpty()) {
      this.props.fetchGroupsAndResources();
    }

    this.props.fetchPasswordPolicy();
    this.props.receiveNotifications();
  }

  render() {
    const { userConfigViewState, showPerUserNotifications } = this.props;
    const {
      passwordPolicy, wrongPassword, changePasswordSuccess, isChangingPassword,
      isChangingNotifications, bookingEmailPreference
    } = userConfigViewState.toJS();

    const associations = this.associatedAccounts();

    return (
      <div className="user-dialog-container">
        <nav className="navbar navbar-static-top navbar-inverse">
          <div className="navbar-right">
            <ul className="nav navbar-nav">
              <li>
                <a href="#" className="navbar-icon" onClick={this.close}>
                  <div className="icon-container">
                    <div><i className="fa fa-times-circle fa-2x" /></div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div style={{ marginTop: 20 }}>
          <div className="container">

            <ChangePassword
              passwordPolicy={passwordPolicy}
              wrongPassword={wrongPassword}
              changePasswordSuccess={changePasswordSuccess}
              isChangingPassword={isChangingPassword}
              onChangePassword={this.onChangePassword}
            />

            <br />

            {showPerUserNotifications ? (
              <Notifications
                username={this.props.username}
                associations={associations}
                bookingEmailPreference={bookingEmailPreference}
                isChangingNotifications={isChangingNotifications}
                onChangeNotifications={this.onChangeNotifications}
              />
                  ) : null }

          </div>
        </div>
      </div>
    );
  }

  associatedAccounts() {
    const { locationOptions, resourcesById } = this.props;
    if (resourcesById.isEmpty()) {
      return [];
    }
    return locationOptions.map((loc) => {
      const res = resourcesById.get(loc.resourceId);
      const resName = res ? res.name : '';

      return {
        orgName: loc.orgName,
        locName: loc.locName,
        resName
      };
    });
  }

  close = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  onChangePassword = (request) => {
    this.props.changePassword(request);
  };

  onChangeNotifications = (request) => {
    this.props.changeNotifications(request);
  };
}

UserConfig.propTypes = {
  username: PropTypes.string.isRequired,
  locationOptions: PropTypes.object.isRequired,
  userConfigViewState: PropTypes.object.isRequired,
  resourcesById: PropTypes.object.isRequired,
  showPerUserNotifications: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const { authState, locationOptions, locationFeatures, userConfigViewState, resourcesById } = state;
  return {
    showPerUserNotifications: locationFeatures.get('ShowPerUserNotifications'),
    username: authState.get('username'),
    locationOptions,
    userConfigViewState,
    resourcesById
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupsAndResources: () => {
      dispatch(fetchGroupsAndResources());
    },
    fetchPasswordPolicy: () => {
      dispatch(fetchPasswordPolicy());
    },
    receiveNotifications: () => {
      dispatch(receiveNotifications());
    },
    changePassword: (request) => {
      dispatch(changePassword(request));
    },
    changeNotifications: (request) => {
      dispatch(changeNotifications(request));
    }
  };
};


const UserConfigContainer = connect(
   mapStateToProps,
   mapDispatchToProps
)(UserConfig);

export default UserConfigContainer;
