var View = require('../base/view');

var ConfigNavView = View.extend({
   region: 'resource-config-nav',
   noWrap: true,
   autoRender: true,

   events:{
      'click .js-nav-item': 'navClick'
   },

   initialize: function () {
      this.template = require('./nav');
      View.prototype.initialize.call(this, arguments);
   },

   navClick: function(ev){
      this.trigger('navigate', $(ev.target).attr('data-view-name'));
      return false;
   },

   setActive: function(viewId){
      this.$el.find('button').removeClass('active');
      this.$el.find("button[data-view-name='" + viewId + "']").addClass('active');
   },

   hide: function(){
      this.$el.addClass('hide');
   },

   show: function(){
      this.$el.removeClass('hide');
   }
});

module.exports = ConfigNavView;