import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { msgSortableListBanner as msg } from '../../localization/messages/components/admin';
import { msgButtons } from '../../localization/messages/shared/buttons';

const SortableListBanner = ({ onToggleSorting }) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="columns-header sortable-list-banner">
      <div className="pull-right">
        <button className="btn btn-label-success" onClick={onToggleSorting}>{f(msgButtons.btnClear)}</button>
      </div>
      {f(msg.description)}
    </div>
  );
};

SortableListBanner.propTypes = {
  onToggleSorting: PropTypes.func.isRequired
};

export default SortableListBanner;
