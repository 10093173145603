import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useIntl } from 'react-intl';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import TimeRangeInput from '../../inputs/time-range-input';
import SmsTemplateInput from '../../inputs/sms-template-input';
import { formatMessage } from '../../../intlContextInterseptor';
import { createValidator, required, maxLength, pattern } from '../../../utils/validation';
import { defaultSmsConfirmTemplate, defaultSmsReminderTemplate } from '../../../utils/templates';
import SubmitButton from '../../common/submit-button';
import CurrencyUtil from '../../../../utils/currency-util';

import { msgSmsConfig as msg } from '../../../localization/messages/components/account-config';
import { msgValidation } from '../../../localization/messages/utils/utils';

const SmsConfigForm = (props) => {
  const { handleSubmit, smsConfirmPrice, features, previewValues } = props;
  const { formatMessage: f } = useIntl();

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <br />

      {features.sender && (
      <div>
        <Field name="sender" component={TextInputHorizontal} label={f(msg.lblSender)} />
        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              {f(msg.senderDescription, { br: <br /> })}
            </p>
          </div>
        </div>
        <hr />
      </div>
        )}

      {(features.smsOnBookOnline || features.smsOnCancelOnline || features.defaultOptionSmsConfirm) && features.EnableSmsConfirmations && (
      <div>
        {(features.smsOnBookOnline || features.smsOnCancelOnline) && (
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblConfirmation)}</label>
          <div className="col-sm-4">
            {features.smsOnBookOnline && (
            <div className="checkbox">
              <label>
                <Field name="smsOnBookOnline" component="input" type="checkbox" />
                {f(msg.lblSmsOnBookOnline, { smsConfirmPrice: CurrencyUtil.accountCurrency(smsConfirmPrice) })}
              </label>
            </div>
                  )}
            {features.smsOnCancelOnline && (
            <div className="checkbox">
              <label>
                <Field name="smsOnCancelOnline" component="input" type="checkbox" />
                {f(msg.lblSmsOnCancelOnline, { smsConfirmPrice: CurrencyUtil.accountCurrency(smsConfirmPrice) })}
              </label>
            </div>
                  )}
          </div>
        </div>
            )}

        {features.defaultOptionSmsConfirm && (
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-7">
            <div className="checkbox">
              <label>
                <Field name="defaultOptionSmsConfirm" component="input" type="checkbox" />
                {f(msg.lblDefaultOptionSmsConfirm)}
              </label>
            </div>
          </div>
        </div>
            )}
        <hr />
      </div>
        )}

      {features.reminderSendTimes && (
      <div>
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblSmsReminderWithin)}</label>
          <div className="col-sm-4">
            <Field name="smsReminderWithin" component="input" type="number" className="form-control inline-sm" min={1} max={120} />
            <p className="form-control-static inline-md">{f(msg.hoursBefore)}</p>
          </div>
        </div>
        <Field name="reminderSendTimes" component={TimeRangeInput} label={f(msg.lblReminderSendTimes)} />
        <hr />
      </div>
        )}

      {features.smsConfirmTemplate && features.EnableSmsConfirmations && (
      <div>
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblSmsConfirmTemplate)}</label>
          <div className="col-sm-9">
            <Field
              name="smsConfirmTemplate"
              component={SmsTemplateInput}
              defaultTemplate={defaultSmsConfirmTemplate()}
              replaceValues={previewValues}
            />
          </div>
        </div>
        <hr />
      </div>
        )}

      {features.smsReminderTemplate && (
      <div>
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblSmsReminderTemplate)}</label>
          <div className="col-sm-9">
            <Field
              name="smsReminderTemplate"
              component={SmsTemplateInput}
              defaultTemplate={defaultSmsReminderTemplate()}
              replaceValues={previewValues}
            />
          </div>
        </div>
        <hr />
      </div>
        )}

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-5">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const validator = createValidator({
  sender: [required, maxLength(11), pattern(/^[a-z0-9_]+$/i, formatMessage(msgValidation.symbols))]
});

export default reduxForm({
  form: 'sms-config',
  validate: validator
})(SmsConfigForm);
