import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { getSmsEnabled } from '../../utils/selectors';
import PhoneUtil from '../../../utils/phone-util';
import { msgBookingConfirmation as msg } from '../../localization/messages/components/booking';

const BookingConfirmation = ({ id, email, phoneNumber, smsEnabled }) => {
  const sendSmsEnabled = Boolean(smsEnabled && phoneNumber);
  const { formatMessage: f } = useIntl();

  return (
    <div className="booking-form-block confirmations">
      <p>
        {id !== 'DRAGGER' ?
          <strong>{f(msg.confirmQuestionExtended)}</strong> :
          <strong>{f(msg.confirmQuestion)}</strong>
          }
      </p>
      <div className="form-group">
        <div className={sendSmsEnabled ? 'checkbox' : 'checkbox disabled'}>
          <label>
            <Field name="sendSmsConfirmation" component="input" type="checkbox" value="true" />
            {f(msg.lblSendSmsConfirmation, { sendSmsEnabled, phoneNumber: PhoneUtil.formatPhoneNumber(phoneNumber) })}
          </label>
        </div>
        <div className={email ? 'checkbox' : 'checkbox disabled'}>
          <label>
            <Field name="sendEmailConfirmation" component="input" type="checkbox" value="true" />
            {f(msg.lblSendEmailConfirmation, { emailExist: Boolean(email), email })}
          </label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { bkf, locationConfig } = state;
  const customer = bkf.get('customer');
  const customerForm = getFormValues('bkf-customer')(state);
  const { phoneNumber, email } = customerForm || customer || {};
  const smsEnabled = getSmsEnabled(state);

  const initialValues = {
    sendSmsConfirmation: phoneNumber && locationConfig.get('defaultOptionSmsConfirm') && smsEnabled,
    sendEmailConfirmation: email && locationConfig.get('defaultOptionEmailConfirm')
  };

  return {
    initialValues,
    id: bkf.get('id'),
    phoneNumber,
    email,
    smsEnabled,
    ...bkf.get('attributes')
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-confirmation',
  destroyOnUnmount: false
})(BookingConfirmation));

