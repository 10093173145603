import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit } from 'ramda';
import { getIsSysAdmin } from '../../../utils/selectors';
import { getGeneralPreferences, saveGeneralPreferences, getJsonPreferences, saveJsonPreferences } from '../../../state/preferences-actions';
import { setExternalKeyboard, setGridSize, setHighContrast } from '../../../state/view-actions';
import { calendar } from '../../../../utils/preference-keys';
import GeneralForm from './general-form';

class General extends Component {

  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getGeneralConfig();
    }
    if (!this.props.jsonPreferences) {
      this.props.getJsonConfig();
    }
  }

  render() {
    const features = this.props.locationFeatures.toJS();
    const preferences = this.props.preferences ? this.props.preferences.toJS() : null;
    const { highContrast, gridSize, externalKeyboard, stableId, deviceType, jsonPreferences, isSysAdmin } = this.props;
    const showWebBookingIndicator = jsonPreferences && jsonPreferences.get(calendar.showWebBookingIndicator);
    const darkenClosedWebBookingDays = jsonPreferences && jsonPreferences.get(calendar.darkenClosedWebBookingDays);
    const servicesSortOrderManual = jsonPreferences && jsonPreferences.get('servicesSortOrder') === 'SortIdx';

    const initialValues = preferences ? {
      ...preferences,
      highContrast,
      gridSizeLarge: gridSize === 'large',
      externalKeyboard,
      showWebBookingIndicator,
      darkenClosedWebBookingDays,
      servicesSortOrderManual
    } : null;

    return (
      <GeneralForm
        initialValues={initialValues}
        onSubmit={this.submitForm}
        stableId={stableId}
        deviceType={deviceType}
        features={features}
        isSysAdmin={isSysAdmin}
      />
    );
  }

  submitForm = (values) => {
    this.props.setHighContrast(values.highContrast);
    this.props.setGridSizeLarge(values.gridSizeLarge);
    this.props.setExternalKeyboard(values.externalKeyboard);

    const omittedValues = [
      'highContrast', 'gridSizeLarge', 'externalKeyboard',
      'showWebBookingIndicator', 'darkenClosedWebBookingDays',
      'servicesSortOrderManual'
    ];

    const jsonValues = {};
    jsonValues[calendar.showWebBookingIndicator] = values.showWebBookingIndicator;
    jsonValues[calendar.darkenClosedWebBookingDays] = values.darkenClosedWebBookingDays;
    jsonValues.servicesSortOrder = values.servicesSortOrderManual ? 'SortIdx' : 'Alphabetical';

    return Promise.all([
      this.props.saveGeneralConfig(omit(omittedValues, values)),
      this.props.saveJsonConfig(jsonValues)
    ]);
  };
}

General.propTypes = {
  preferences: PropTypes.object,
  jsonPreferences: PropTypes.object,
  locationFeatures: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { preferencesViewState, mainViewState, gridViewState, locationFeatures } = state;

  return {
    preferences: preferencesViewState.get('generalPreferences'),
    jsonPreferences: preferencesViewState.get('jsonPreferences'),
    highContrast: gridViewState.get('highContrast'),
    gridSize: gridViewState.get('gridSize'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    deviceType: mainViewState.get('deviceType'),
    locationFeatures,
    isSysAdmin: getIsSysAdmin(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHighContrast: (enabled) => {
      dispatch(setHighContrast(enabled));
    },
    setGridSizeLarge: (large) => {
      dispatch(setGridSize(large ? 'large' : 'small'));
    },
    setExternalKeyboard: (enabled) => {
      dispatch(setExternalKeyboard(enabled));
    },
    getGeneralConfig: () => {
      dispatch(getGeneralPreferences());
    },
    getJsonConfig: () => {
      dispatch(getJsonPreferences());
    },
    saveGeneralConfig: (data) => {
      return dispatch(saveGeneralPreferences(data));
    },
    saveJsonConfig: (data) => {
      return dispatch(saveJsonPreferences(data));
    }
  };
};

const GeneralContainer = connect(
   mapStateToProps,
   mapDispatchToProps
)(General);

export default GeneralContainer;
