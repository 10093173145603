import { fetchPost, checkStatus, prefixUrl, logError, fetchErrorHandler } from '../utils/ajax-util';
import { getBooking, getbookingExtendedWithKey } from '../utils/selectors';
import { networkFailure, loadingDone } from './network-actions';
import { resourceIdFromColIdx } from '../components/grid/grid-state-helper';
import { bookingDeleted, bookingAdded, setUndoableBooking } from './booking-actions';
import PhoneUtil from '../../utils/phone-util';

export const ADD_BOOKING_TO_CLIPBOARD = 'ADD_BOOKING_TO_CLIPBOARD';
export const REMOVE_BOOKING_FROM_CLIPBOARD = 'REMOVE_BOOKING_FROM_CLIPBOARD';
export const PASTE_BOOKING = 'PASTE_BOOKING';
export const ADD_CLIPBOARD_DRAGGER = 'ADD_CLIPBOARD_DRAGGER';
export const REMOVE_CLIPBOARD_DRAGGER = 'REMOVE_CLIPBOARD_DRAGGER';


export function addClipboardDragger(dragger) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_CLIPBOARD_DRAGGER,
      dragger
    });
  };
}

export function removeClipboardDragger() {
  return {
    type: REMOVE_CLIPBOARD_DRAGGER
  };
}

export function addToClipboard(id, copy) {
  return (dispatch, getState) => {
    const baseBooking = copy ?
      getBooking(getState(), { id }) :
      getbookingExtendedWithKey(getState(), { id, key: 'locationId' });

      // If we're making a copy, then we reset a couple of fields that are unlikely to make sense to copy across
      //
    const changes = copy ? {
      copyOnPaste: true,
      channel: 'Cal',
      dropIn: false,
      askedForPerson: false,
      status: 'Booked'
    } : {
      copyOnPaste: false
    };


    dispatch({
      type: ADD_BOOKING_TO_CLIPBOARD,
      booking: Object.assign({}, baseBooking, changes)
    });
  };
}

export function pasteBooking(bk, routeParams) {
  return (dispatch, getState) => {
    const { orderedGroups, bookingsById } = getState();
    const { viewDate, viewMode, entityType, entityId } = routeParams;

    const resId = resourceIdFromColIdx(orderedGroups, entityType, entityId, bk.colIdx);

    const booking = Object.assign({}, bk, {
      resourceId: resId,
      pendingMove: false
    });

    const undoState = bookingsById.get(bk.id);
    dispatch(bookingDeleted('DRAGGER', 'local'));


    if (booking.copyOnPaste) {
      dispatch(copyPastedBooking(booking));
    } else {
      dispatch(movePastedBooking(booking, undoState));
    }
  };
}

export function addPasteDragger(dragger, routeParams) {
  return (dispatch, getState) => {
    const { orderedGroups } = getState();
    const { viewDate, viewMode, entityType, entityId } = routeParams;

    const resId = resourceIdFromColIdx(orderedGroups, entityType, entityId, dragger.colIdx);

    const booking = Object.assign({}, dragger, {
      id: 'DRAGGER',
      resourceId: resId,
      pasteDragger: true
    });

    dispatch(bookingDeleted('DRAGGER', 'local'));
    dispatch(bookingAdded(booking));
  };
}

export function removePasteDragger() {
  return bookingDeleted('DRAGGER', 'local');
}

export function cancelCopyPaste(bookingId) {
  return (dispatch, getState) => {
    dispatch(bookingDeleted('DRAGGER', 'local'));
    dispatch({
      type: REMOVE_BOOKING_FROM_CLIPBOARD,
      bookingId
    });
  };
}

function removeFromClipboard(bookingId) {
  return {
    type: REMOVE_BOOKING_FROM_CLIPBOARD,
    bookingId
  };
}

function movePastedBooking(booking, undoState) {
  const url = prefixUrl(`/bookings/${booking.id}/move`);
  return (dispatch, getState) => {
    const { locationConfig } = getState();
    const isUndoable = !locationConfig.get('confirmMoveEnabled');
    const toLocationId = locationConfig.get('locationId');

    dispatch(bookingAdded(booking), 'local');
    dispatch(removeFromClipboard(booking.id));

    const body = {
      fromLocationId: booking.locationId,
      toLocationId,
      resourceId: booking.resourceId,
      endTimeEpoch: booking.endTime.valueOf(),
      startTimeEpoch: booking.startTime.valueOf(),
      notificationOptions: isUndoable ? {} : {
        sendSmsConfirmation: booking.sendSmsConfirmation,
        sendEmailConfirmation: booking.sendEmailConfirmation
      }
    };

    return fetch(url, fetchPost(body))
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(bookingPasted(booking)))
            .then(req => dispatch(bookingDeleted('DRAGGER', 'local')))
            .then(req => dispatch(setUndoableBooking(isUndoable ? undoState : null)))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}


function copyPastedBooking(booking) {
  const url = prefixUrl('/bookings/');

  return (dispatch) => {
    dispatch(removeFromClipboard(booking.id));

    const { attributes } = booking;
    const newAttributes = attributes && attributes.isDashlBooking ? {
      ...attributes,
      addDashlBooking: true
    } : attributes;

    const add = Object.assign({}, booking, {
      customerPhoneNumber: PhoneUtil.formatPhoneNumberE164(booking.customerPhoneNumber),
      customerOtherPhoneNumber: PhoneUtil.formatPhoneNumberE164(booking.customerOtherPhoneNumber),
      customerEmail: booking.customerEmail,
      channel: 'Cal',
      dropIn: false,
      askedForPerson: false,
      copyOnPaste: false,
      attributes: newAttributes
    });

    return fetch(url, fetchPost(add))
            .then(res => dispatch(checkStatus(res)))
            .then(req => req.json())
            .then(json => dispatch(bookingAdded({ ...booking, ...json }, 'local')))
            .then(req => dispatch(bookingDeleted('DRAGGER', 'local')))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function bookingPasted(booking) {
  return {
    type: PASTE_BOOKING,
    booking
  };
}
