import { fetchGet, fetchPost, fetchDelete, checkStatus, prefixUrl, fetchErrorHandler } from '../utils/ajax-util';
import { loading, loadingDone } from './network-actions';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGN_RECIPIENTS = 'GET_CAMPAIGN_RECIPIENTS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

function getCampaignsSuccess(data) {
  return {
    type: GET_CAMPAIGNS,
    state: {
      campaigns: data
    }
  };
}

function getCampaignRecipientsSuccess(data) {
  return {
    type: GET_CAMPAIGN_RECIPIENTS,
    state: {
      recipients: data.count
    }
  };
}

function createCampaignSuccess(data) {
  return {
    type: CREATE_CAMPAIGN,
    campaign: data
  };
}

function deleteCampaignSuccess(id) {
  return {
    type: DELETE_CAMPAIGN,
    campaignId: id
  };
}

export function getCampaigns() {
  const url = prefixUrl('/smscampaign/');
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(res => dispatch(getCampaignsSuccess(res)))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getCampaignRecipients(resourceIds, lastBookedSince, includeOrphanedCustomers, bookedUntil) {
  if (resourceIds.length === 0) {
    return (dispatch) => {
      dispatch(getCampaignRecipientsSuccess({ count: 0 }));
    };
  }

  let query = `?resourceIds=${resourceIds.join()
       }&lastBookedSince=${lastBookedSince
       }&includeOrphanedCustomers=${includeOrphanedCustomers}`;

  if (bookedUntil) {
    query += `&bookedUntil=${bookedUntil}`;
  }

  const url = prefixUrl(`/smscampaign/recipient-count${query}`);
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
         .then(res => dispatch(checkStatus(res)))
         .then(res => res.json())
         .then(res => dispatch(getCampaignRecipientsSuccess(res)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function createCampaign(data) {
  const url = prefixUrl('/smscampaign/create/');
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchPost(data))
         .then(res => dispatch(checkStatus(res)))
         .then(res => res.json())
         .then(res => dispatch(createCampaignSuccess(res)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteCampaign(id) {
  const url = prefixUrl(`/smscampaign/${id}`);
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchDelete())
         .then(res => dispatch(checkStatus(res)))
         .then(res => dispatch(deleteCampaignSuccess(id)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
