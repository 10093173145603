import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { fetchLocationConfig } from '../../state/account-actions';
import { viewDateFromDate } from '../../utils/time-util';

const SwitchLocation = props => <Redirect push={false} to={getCalendarStartPath(props)} />;

function getCalendarStartPath(props) {
   // TODO: props.resourceid seems to belong to the wrong org&loc here..


  const { org, loc } = props.match.params;
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');

  const url = props.resourceId == null ? `/${org}/${loc}/calendar/day/${today}/group/0` :
         `/${org}/${loc}/calendar/week/${thisWeek}/resource/${props.resourceId}`;

  return url;
}

const mapStateToProps = (state, ownProps) => {
  return {
    resourceId: state.locationConfig.get('resourceId'),
    locationRole: state.locationConfig.get('locationRole')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      // TODO: set loadingConfig = true when starting fetch, set it to false when arrived.
    fetchConfig: () => {
      dispatch(fetchLocationConfig());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(SwitchLocation);
