import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Popover } from '../common/popover';

import {
  addClipboardDragger,
  addPasteDragger,
  cancelCopyPaste,
  pasteBooking,
  removeClipboardDragger,
  removePasteDragger
} from '../../state/clipboard-actions';
import { setUserPreference } from '../../state/user-actions';
import { translateRouteParams } from '../../utils/time-util';
import { getGridProps } from '../grid/grid-selectors';
import ClipboardDragger from './clipboard-dragger';

import ClipboardItem from './clipboard-item';

import { msgClipboard as msg } from '../../localization/messages/components/clipboard';


class Clipboard extends Component {

  static propTypes = {
    clipboardBookings: PropTypes.object.isRequired,
    clipboardDragger: PropTypes.object,
    highContrast: PropTypes.bool.isRequired,
    gridProps: PropTypes.object.isRequired,
    hideHelpTipFromStart: PropTypes.bool.isRequired,
    onToggleHelpTip: PropTypes.func.isRequired,
    onCancelItem: PropTypes.func.isRequired,
    onAddChipDragger: PropTypes.func.isRequired,
    onRemoveChipDragger: PropTypes.func.isRequired,
    onAddClipboardDragger: PropTypes.func.isRequired,
    onRemoveClipboardDragger: PropTypes.func.isRequired,
    onPasteBooking: PropTypes.func.isRequired
  };


  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      hideHelpTipChecked: props.hideHelpTipFromStart,
      showHelpTip: false
    };
  }

  render() {
    try {
      const { clipboardBookings } = this.props;
      const { showHelpTip } = this.state;

      return (
        <Popover isOpen={showHelpTip} body={this.popoverContent()} onOuterAction={this.closePopover} style={{ zIndex: 1000 }}>
          {!clipboardBookings.isEmpty() ? this.clipboardContent() : <div /> }
        </Popover>
      );
    } catch (e) {
      console.error(e);
    }
  }

  popoverContent() {
    return (
      <div className="Popover-content">
        <a href="#" onClick={this.closePopover} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        <FormattedMessage {...msg.content} /><br />
        <a className="checkbox" href="#" onClick={this.toggleHelpTip}>
          {this.state.hideHelpTipChecked ? <i className="fa fa-fw fa-check-square-o" /> : <i className="fa fa-fw fa-square-o" />}
          <FormattedMessage {...msg.dontShow} />
        </a>
      </div>
    );
  }

  clipboardContent() {
    const { clipboardBookings, highContrast, gridProps, clipboardDragger } = this.props;
    const averageClipSize = gridProps.gridClientWidth / clipboardBookings.size;
    const targetClass = this.state.showHelpTip ? 'helpTipFocus target' : '';

    return (<div id="clipboardbanner" className={targetClass} style={this.style()}>
      {clipboardBookings.valueSeq().map((booking) => {
        return (<ClipboardItem
          key={booking.id}
          booking={booking}
          highContrast={highContrast}
          gridProps={gridProps}
          onClosePopover={this.closePopover}
          averageClipSize={averageClipSize}
          {...this.props}
        />);
      })}
      {clipboardDragger && (
        <ClipboardDragger
          ref={clipboardDragger.refCallback}
          highContrast={highContrast}
          gridProps={gridProps}
          initialLeft={clipboardDragger.initialLeft}
          initialTop={clipboardDragger.initialTop}
          label={clipboardDragger.label}
        />
      )}
    </div>
    );
  }


  style() {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 35
    };
  }

  componentWillReceiveProps(nextProps) {
    const { clipboardBookings, hideHelpTipFromStart } = this.props;
    const itemAddedToEmptyClipboard = clipboardBookings.isEmpty() && !nextProps.clipboardBookings.isEmpty();

    if (itemAddedToEmptyClipboard && !hideHelpTipFromStart) {
      this.setState({ showHelpTip: true });
    } else if (nextProps.clipboardBookings.isEmpty()) {
      this.setState({ showHelpTip: false });
    }
  }

  closePopover = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ showHelpTip: false });
  };

  toggleHelpTip = (ev) => {
    ev.preventDefault();
    const newState = !this.state.hideHelpTipChecked;
    this.props.onToggleHelpTip(newState);
    this.setState({ hideHelpTipChecked: newState });
  };
}


const mapStateToProps = (state, ownProps) => {
  try {
    return {
      clipboardBookings: state.bookingsClipboard,
      clipboardDragger: state.clipboardState.get('clipboardDragger'),
      highContrast: state.gridViewState.get('highContrast'),
      hideHelpTipFromStart: state.userClientPreferences.getIn(['user', 'hideClipboardHelpTip']) === true,
      gridProps: getGridProps(state, ownProps)
    };
  } catch (e) {
    console.error(e);
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
   // const routeParams = translateRouteParams(ownProps.match.params);

  const routeParams = translateRouteParams(ownProps.routeParams);

  return {
    onCancelItem: (bookingId) => {
      dispatch(cancelCopyPaste(bookingId));
    },

    onAddChipDragger: (dragger) => {
      dispatch(addPasteDragger(dragger, routeParams));
    },

    onRemoveChipDragger: (dragger) => {
      dispatch(removePasteDragger());
    },

    onAddClipboardDragger: (dragger) => {
      dispatch(addClipboardDragger(dragger));
    },

    onRemoveClipboardDragger: () => {
      dispatch(removeClipboardDragger());
    },

    onPasteBooking: (booking) => {
      dispatch(pasteBooking(booking, routeParams));
    },

    onToggleHelpTip: (value) => {
      dispatch(setUserPreference(false, { hideClipboardHelpTip: value }));
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(Clipboard);
