import React from 'react';
import { useIntl } from 'react-intl';
import PhoneUtil from '../../../utils/phone-util';

import { msgSendConfirmation as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

const SendConfirmation = ({ onCancel, onConfirm, progress, customer, locationConfig, smsEnabled }) => {
  const { formatMessage: f } = useIntl();
  const { phoneNumber, email } = customer;
  const { defaultOptionSmsConfirm, defaultOptionEmailConfirm } = locationConfig.toJS();
  const canSendConfirmation = (phoneNumber && smsEnabled) || email;

  return (
    <form onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      {canSendConfirmation ? (
        <div>
          <p>
            <strong>{f(msg.heading)}</strong>
          </p>
          {phoneNumber && smsEnabled && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="sms" value="true" defaultChecked={defaultOptionSmsConfirm} /> {PhoneUtil.formatPhoneNumber(phoneNumber)}
              </label>
            </div>
          )}
          {email && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="email" value="true" defaultChecked={defaultOptionEmailConfirm} /> {email}<br />
              </label>
            </div>
          )}
        </div>
      ) : (
        <p>
          {f(msg.sendError)}
        </p>
      )}
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{f(msgButtons.btnCancel)}</button>{' '}
        <button type="submit" className="btn-save" tabIndex="3">{progress ? f(msgButtons.btnSending) : f(msgButtons.btnSend)}</button>
      </div>
    </form>
  );
};

export default SendConfirmation;
