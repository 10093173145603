var View = require('../../base/view');
var ListView = require('./users-list-view');

module.exports = View.extend({
    noWrap: true,
    autoRender: true,
    containerMethod: 'html',

    initialize: function () {
        this.template = require('./users-main');
        View.prototype.initialize.call(this, arguments);
    },

    getTemplateData: function () {
        return {};
    },

    render: function () {
        View.prototype.render.call(this, arguments);
        this.renderSubviews();
    },

    renderSubviews: function () {
        this.listView = new ListView({
            model: new Chaplin.Model({}),
            collection: this.collection,
            container: this.$('.js-list-view')
        });
        this.subview('list', this.listView);
    }
});