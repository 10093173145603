import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getOrganisationsUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';

import { msgFeatureNotAvailable as msg } from '../../localization/messages/components/dialogs';

export default class FeatureNotAvailable extends Component {

  static propTypes = {
    leadText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    showLink: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const { leadText, subText, showLink } = this.props;
    const routeParams = translateRouteParams(this.context.router.route.match.params);

    return (
      <div className="alert alert-warning">
        <p className="lead">
          {leadText}
        </p>
        <p>
          {subText || <FormattedMessage {...msg.subText} />}
        </p>
        {showLink ?
          <p>
            <br />
            <a href={getOrganisationsUrl(routeParams)} className="btn btn-default">
              <FormattedMessage {...msg.linkGoToSubscription} />
            </a>
          </p> : null}
      </div>
    );
  }
}
