import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import shallowEqual from 'fbjs/lib/shallowEqual';
import { calcWeekViewTimeOffsetForDay, pixelMultiplier } from '../../utils/time-util';
import { getColumnsSelector, getGridProps } from './grid-selectors';
import ChipColumn from './chip-column';

class Chips extends Component {

  static propTypes = {
    noRender: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    gridProps: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    schedulesByResource: PropTypes.object.isRequired,
    bookingsById: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
  }

  componentDidMount() {
    this.scrollOnBookingRefresh = true;
  }

  componentDidUpdate(prevProps) {
    const { schedulesByResource, bookingsById, gridProps } = this.props;

    if (!shallowEqual(this.props.routeParams, prevProps.routeParams) || prevProps.gridProps.gridSize !== gridProps.gridSize) {
      this.scrollOnBookingRefresh = true;
    }
      // Only scroll if new route, and after schedules&bookings arrive
      // And if gridSize has changed
      //
    if (this.scrollOnBookingRefresh &&
            (
              prevProps.schedulesByResource !== schedulesByResource ||
              prevProps.bookingsById !== bookingsById ||
              prevProps.gridProps.gridSize !== gridProps.gridSize
              )
            ) {
      this.scrollToFirstElement();
      this.scrollOnBookingRefresh = false;
    }
  }

  firstSchedule() {
    const { schedulesByResource } = this.props;

    let arr = [];
    schedulesByResource.forEach((foo) => { arr = arr.concat(foo.blocks); });
    if (arr.length === 0) {
      return null;
    }
    return moment(Immutable.List(arr).sortBy(a => a.start).first().start, 'HH:mm');
  }

  firstBooking() {
    if (this.props.bookingsById.size === 0) {
      return null;
    }

    return moment(this.props.bookingsById.sortBy(b => b.startTime.hour() * 60 + b.startTime.minutes()).first().startTime);
  }

  scrollToFirstElement() {
    const { gridProps } = this.props;

    const firstSchedule = this.firstSchedule();
    const firstBooking = this.firstBooking();

    if (firstBooking === null && firstSchedule === null) {
      return;
    }

    const { rowsPerHour, pixelsPerRow, viewDateStartEnd, isWeekView } = gridProps;
    const hourHeight = rowsPerHour * pixelsPerRow;
    const compTime = calcWeekViewTimeOffsetForDay(isWeekView, viewDateStartEnd.start) * 1000;
    const minToPixelFactor = (hourHeight) / 60;
    const multiplier = pixelMultiplier(rowsPerHour);

    let firstTime;

    if (firstSchedule === null) {
      firstTime = firstBooking;
    } else if (firstBooking === null) {
      firstTime = firstSchedule;
    } else {
      firstBooking.dayOfYear(firstSchedule.dayOfYear());
      firstTime = firstBooking.valueOf() > firstSchedule.valueOf() ? firstSchedule : firstBooking;
    }

    const startPx = (((firstTime.valueOf() - firstTime.startOf('day').valueOf() + compTime) / (1000 * 60)) * minToPixelFactor);
    // TODO: Decide if this is final?
    const scrollTo = startPx - (pixelsPerRow * multiplier);

    document.getElementById('gridcontainer').scrollTop = scrollTo;
  }

  render() {
    // console.warn('Chips.render');

    const { noRender } = this.props;

    return (
      <div
        id="eventOwner"
        ref="eventOwner"
        style={{
          zIndex: 1,
          height: '100%',
          background: 'transparent'
        }}
      >
        {noRender ? <div /> : this.renderChips()}

      </div>
    );
  }

  renderChips() {
    const { columns, gridProps } = this.props;

    return (
      <div style={{ height: '100%' }}>
        {columns.map((column, i) => {
          return (
            <ChipColumn
              key={column.id}
              gridProps={gridProps}
              columnIndex={i}
              column={column}
              routeParams={this.props.routeParams}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const getScheduleEditMode = state => state.gridViewState.get('scheduleEditMode');
  const columns = getColumnsSelector(state, ownProps);
  const gridProps = getGridProps(state, ownProps);

  return {
    columns,
    gridProps,
    schedulesByResource: state.schedulesByResource,
    bookingsById: state.bookingsById,
    noRender: getScheduleEditMode(state)
  };
};

export default connect(
      mapStateToProps
)(Chips);
