import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { msgContact as msg } from '../../../localization/messages/components/account-config';
import { msgButtons } from '../../../localization/messages/shared/buttons';

const ContactForm = ({ isOptional, useContactInfo, handleSubmit, submitting, onCancel }) => {
  const showForm = !isOptional || useContactInfo === 'true';
  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={handleSubmit} className="form-horizontal">
      {isOptional && (
      <div className="form-group">
        <div className="col-xs-offset-3 col-xs-9">
          <div className="radio">
            <label>
              <Field name="useContactInfo" value="false" component="input" type="radio" />
              {f(msg.nameSameContact)}
            </label>
          </div>
          <div className="radio">
            <label>
              <Field name="useContactInfo" value="true" component="input" type="radio" />
              {f(msg.nameOtherContact)}
            </label>
          </div>
        </div>
      </div>
        )}
      {showForm && (
      <Fragment>
        <div className="form-group">
          <label className="control-label col-xs-3">{f(msg.name)}</label>
          <div className="col-xs-5">
            <Field name="name" component="input" type="text" className="form-control" required />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-xs-3">{f(msg.email)}</label>
          <div className="col-xs-5">
            <Field name="email" component="input" type="email" className="form-control" required />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-xs-3">{f(msg.phone)}</label>
          <div className="col-xs-5">
            <Field name="phone" component="input" type="phone" className="form-control" required />
          </div>
        </div>
      </Fragment>
        )}
      <div className="form-group">
        <div className="col-xs-offset-3 col-xs-9">
          <button type="submit" className="btn btn-success" disabled={submitting}>{f(msgButtons.btnSave)}</button> &nbsp;
          <button type="button" className="btn btn-default" disabled={submitting} onClick={onCancel}>{f(msgButtons.btnCancel)}</button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    useContactInfo: selector(state, 'useContactInfo')
  };
};

export default reduxForm()(connect(mapStateToProps)(ContactForm));

