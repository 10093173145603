var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <div class=\"btn-group btn-group-justified\">\n      <a href=\"#\" class=\"btn btn-sm btn-tab js-previous active\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"earlier",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n      <a href=\"#\" class=\"btn btn-sm btn-tab js-upcoming\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"coming",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n   </div>\n   <div id=\"customer-no-bookings-container\">\n      <hr>\n      <em>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"noBookingsForView",{"name":"i18n","hash":{},"data":data}))
    + "</em>\n   </div>\n   <div class=\"js-customer-bookings\"></div>\n</div>\n";
},"useData":true});