var s = require('underscore.string');
var PhoneNumber = require('./phone-number');

module.exports = {
   initialize: function (countryCode, nddPrefix, iddPrefix) {
      this.countryCode = countryCode;
      this.nddPrefix = nddPrefix;
      this.iddPrefix = iddPrefix;
   },

   isMobile: function(number) {
      var prefix = `(${this.countryCode}|${this.nddPrefix}|${this.iddPrefix})`;
      return number && number.match(new RegExp(`^\\+?${prefix}7[0-9]{8}$`));
   },

   parseNumber: function (n) {
      var number = this._normalize(n);

      if (this._isInternational(number)) {
         if (s.startsWith(number, this.iddPrefix)) {
            return new PhoneNumber(number.substring(2), "", "", true);
         } else {
            return new PhoneNumber(number, "", "", true);
         }
      } else {
         if (s.startsWith(number, this.iddPrefix)) {
            number = number.substring(this.iddPrefix.length);
         }

         if (s.startsWith(number, this.nddPrefix)) {
            number = number.substring(this.nddPrefix.length);
         }

         if (s.startsWith(number, this.countryCode)) {
            number = number.substring(this.countryCode.length);
         }

         return new PhoneNumber(number, this.countryCode, this.nddPrefix, false);
      }
   },

   _isInternational: function (normalizedNumber) {
      if (!s.startsWith(normalizedNumber, this.iddPrefix) && !s.startsWith(normalizedNumber, this.nddPrefix) && !s.startsWith(normalizedNumber, this.countryCode)) {
         return true;
      }

      return s.startsWith(normalizedNumber, this.iddPrefix) && !s.startsWith(normalizedNumber, this.iddPrefix + this.countryCode);
   },

   _normalize: function (n) {

      var number = s.strip(n);
      number = number.replace(/\+/g, this.iddPrefix);
      number = number.replace(/\D/g, "");

      return number;
   }
};
