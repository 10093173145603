var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <form>\n                <div class=\"modal-header\">\n                    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">&times;</button>\n                    <h4 class=\"modal-title\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n                </div>\n                <div class=\"modal-body\">\n                    <div class=\"row\">\n                        <div class=\"col-sm-12\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"description",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <small class=\"text-muted\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"descriptionExample",{"name":"i18n","hash":{},"data":data}))
    + "</small>\n                                <input name=\"name\" type=\"text\" class=\"form-control\" data-parsley-required=\"true\" />\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"row\">\n                        <div class=\"col-sm-4\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"startDate",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <small class=\"text-muted\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"startDateExample",{"name":"i18n","hash":{},"data":data}))
    + "</small>\n                                <div class=\"input-group date\" id=\"new-schedule-start-date\">\n                                    <input name=\"start\" type=\"text\" class=\"form-control\" />\n                                    <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-sm-4\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"endDate",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <small class=\"text-muted\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"endtDateExample",{"name":"i18n","hash":{},"data":data}))
    + "</small>\n                                <div class=\"input-group date\" id=\"new-schedule-end-date\">\n                                    <input name=\"end\" type=\"text\" class=\"form-control\" />\n                                    <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-sm-4\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label\"></label>\n                                <div class=\"checkbox\">\n                                    <label>\n                                        <input type=\"checkbox\" class=\"js-no-end-date\" /> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"validUntilFurtherNotice",{"name":"i18n","hash":{},"data":data}))
    + "\n                                    </label>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                   <div class=\"row\">\n                        <div class=\"col-sm-6\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"recurringWeeks",{"name":"i18n","hash":{},"data":data}))
    + "</label><br />\n                                <div class=\"btn-group\">\n                                    <button class=\"btn btn-default js-weeks active\" data-weeks=\"1\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"1"},"data":data}))
    + "</button>\n                                    <button class=\"btn btn-default js-weeks\" data-weeks=\"2\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"2"},"data":data}))
    + "</button>\n                                    <button class=\"btn btn-default js-weeks\" data-weeks=\"3\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"3"},"data":data}))
    + "</button>\n                                    <button class=\"btn btn-default js-weeks\" data-weeks=\"4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"4"},"data":data}))
    + "</button>\n                                    <button class=\"btn btn-default js-weeks\" data-weeks=\"5\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"5"},"data":data}))
    + "</button>\n                                </div>\n                            </div>\n                            <small class=\"text-muted\">\n                                "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"select",{"name":"i18n","hash":{},"data":data}))
    + " <strong>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"1"},"data":data}))
    + "</strong> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"forSameSchedule",{"name":"i18n","hash":{},"data":data}))
    + ", \n                                <strong>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"weekSymbol",{"name":"i18n","hash":{"num":"2"},"data":data}))
    + "</strong> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"forDifferentSchedule",{"name":"i18n","hash":{},"data":data}))
    + ".\n                            </small>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"modal-footer\">\n                    <div class=\"pull-right\">\n                        <a href=\"#\" class=\"btn-cancel\" data-dismiss=\"modal\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n                        <button type=\"submit\" class=\"btn-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"save",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});