var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n  <div class=\"col-xs-3\">\n     "
    + alias3((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"dddd LL",{"name":"DateTime","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"col-xs-3\">\n     "
    + ((stack1 = (helpers.SchedExceptionOpen || (depth0 && depth0.SchedExceptionOpen) || alias2).call(alias1,depth0,{"name":"SchedExceptionOpen","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"col-xs-3\">\n     <span class=\"text-warning\">"
    + alias3((helpers.SchedExceptionClosed || (depth0 && depth0.SchedExceptionClosed) || alias2).call(alias1,depth0,{"name":"SchedExceptionClosed","hash":{},"data":data}))
    + "</span>\n  </div>\n  <div class=\"col-xs-3 text-right\">\n     <a class=\"js-delete\" href=\"#\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"remove",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n  </div>\n</div>";
},"useData":true});