var s = require('underscore.string');
var CollectionView = require('../../base/collection-view');
var CustomerListItem = require('../../customers/list/list-item-view');
var PagerView = require('../pager/pager-view');

var CustomerListView = CollectionView.extend({
   region: 'customer-list-region',
   noWrap: true,
   autoRender: true,
   listSelector: '#customer-list-container',
   fallbackSelector: '#customer-empty-list-container',

   events:{
      'click .customer-list-add-btn': 'addNew'
   },

   listen:{
      'change:totalCount model': 'renderPager'
   },

   addNew: function(){
      this.publishEvent('customers:addNew');
      return false;
   },

   search: function(ev){
      ev.preventDefault();
      var keyCode = ev.which;
      if(!this.isSpecialKey(keyCode)){
         var text = $('#customers-search').val();
         if(s.clean(text) === ""){
            this.publishEvent('customers:list');
         }
         else{
            this.publishEvent('customers:search', text, 0);
         }
      }
      return false;
   },

   isSpecialKey: function (keyCode) {
      /*
         9 = tab
         13 = enter
         17 = ctrl
         18 = alt
         20 = caps
         27 = escape
         91, 93, 224 = cmd/windows key
       */

      var specialKeyCodes = [9, 13, 17, 18, 20, 27, 91, 93, 224];
      return _.contains(specialKeyCodes, keyCode);
   },

   debounce: function(fn, delay) {
      var timeout;
      return function() {
         var self = this;
         var args = arguments;
         window.clearTimeout(timeout);
         timeout = window.setTimeout(function() {
            fn.apply(self, args);
         }, delay);
      };
   },

   initialize: function () {
      this.template = require('./list');
      CollectionView.prototype.initialize.call(this, arguments);
   },

   initItemView: function(item){
      var itemView = new CustomerListItem({model:item});
      return itemView;
   },

   getTemplateData: function(){
      return {};
   },

   renderPager: function(){
      this.subview('pager', new PagerView({container:this.$('.js-pagination'), model: this.model}));
   },

   render: function () {
      CollectionView.prototype.render.call(this, arguments);
      this.delegate('keyup','#customers-search',this.debounce(this.search,300));
      this.renderPager();
   },

   deselect: function() {
      this.$('.list-group-item').removeClass('active');
   },

   selectFirst: function() {
      this.deselect();
      this.$('.list-group-item').first().addClass('active');
   }
});

module.exports = CustomerListView;
