import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getGridProps } from './grid-selectors';

class RowMarker extends Component {
  static propTypes = {
    viewMode: PropTypes.string.isRequired,
    gridScrollHeight: PropTypes.number.isRequired,
    columnWidth: PropTypes.number.isRequired,
    pixelsPerRow: PropTypes.number.isRequired,
    todayInView: PropTypes.bool.isRequired
  };

  render() {
    if (!this.props.todayInView) {
      return null;
    }

    const { gridScrollHeight, columnWidth, viewMode } = this.props;
    const secondsPassed = (moment().hour() * 3600) + (moment().minute() * 60) + moment().second();
    const secondsInDay = 24 * 60 * 60;
    const pctOfDay = secondsPassed / secondsInDay;
    const lineTop = pctOfDay * gridScrollHeight;
    const colIdx = viewMode === 'week' ? moment().isoWeekday() - 1 : 0;

    const ballStyle = {
      position: 'absolute',
      display: 'inline-block',
      zIndex: 1000,
      borderRadius: '50%',
      border: '1px solid white',
      background: '#e28f6f',
      width: 12, /* This needs to be adjustet for phoneMode */
      height: 12, /* This needs to be adjustet for phoneMode */
      top: lineTop - 6,
      left: columnWidth * colIdx - 6,
      paddingTop: '0.2em' /* This needs to be adjustet for phoneMode */
    };

    const lineStyle = {
      zIndex: 1000,
      top: lineTop,
      borderTop: '1px solid rgba(252, 98, 72, 0.8)'
    };

    return (
      <Fragment>
        <div className="hrule print-hidden" style={lineStyle} />
        <div style={ballStyle} />
      </Fragment>
    );
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.forceUpdate();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { viewMode } = ownProps.routeParams;
  const { viewDateStartEnd, pixelsPerRow, gridScrollHeight, columnWidth } = getGridProps(state, ownProps);
  const todayInView = moment().isBetween(viewDateStartEnd.start, viewDateStartEnd.end, 'day', '[]');

  return {
    viewMode,
    gridScrollHeight,
    columnWidth,
    pixelsPerRow,
    todayInView
  };
};

export default connect(mapStateToProps)(RowMarker);
