import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getFeatures } from '../../utils/selectors';

import { msgTagsInfo as msg } from '../../localization/messages/components/inputs';

const TagsInfo = ({ time, resource, service, customer, booking, location, features }) => {
  const company = features.EnableCompanyBooking;
  const vehicle = features.EnableVehicleBooking;
  const withSampTag = { samp: (...chunks) => <samp>{chunks}</samp> };

  return (
    <div className="tags-info">
      <div><strong><FormattedMessage {...msg.tagsWithInformation} /></strong></div>
      {time && <div><FormattedMessage {...msg.dateAndTime} values={withSampTag} /></div>}
      {resource && <div><FormattedMessage {...msg.nameOfResource} values={withSampTag} /></div>}
      {service && <div><FormattedMessage {...msg.nameOfService} values={withSampTag} /></div>}
      {booking && <div><FormattedMessage {...msg.bookingReference} values={withSampTag} /></div>}
      {location && <div><FormattedMessage {...msg.companyName} values={withSampTag} /></div>}
      {location && <div><FormattedMessage {...msg.phoneNumber} values={withSampTag} /></div>}
      {customer && <div><FormattedMessage {...msg.customerName} values={withSampTag} /></div>}
      {company && <div><FormattedMessage {...msg.customerNum} values={withSampTag} /></div>}
      {company && <div><FormattedMessage {...msg.customerCompanyName} values={withSampTag} /></div>}
      {vehicle && <div><FormattedMessage {...msg.vehicleReg} values={withSampTag} /></div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    features: getFeatures(state)
  };
};

export default connect(mapStateToProps)(TagsInfo);
