import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { destroy, getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';
import { enableDashlFeatures } from '../../utils/selectors';
import { firstItemIdInGroup } from '../../state/action-helpers';

import {
  fetchServices, fetchAllResourceMappings, addServiceGroup, addService,
  updateService, deleteService, serviceMappingValues, isServiceMappingDiff
} from '../../state/services-actions';
import { fetchGroupsAndResources } from '../../state/resource-actions';
import { getSectionUrl, navigate } from '../../utils/navigate';
import SortableListBanner from '../admin/sortable-list-banner';
import ServiceList from './service-list';
import ServiceListToolbar from './service-list-toolbar';
import EditServiceForm from './edit-service-form';
import ResourceServiceMappings from './resource-service-mappings';
import Loader from '../common/loader';

import { msgServiceConfig as msg } from '../../localization/messages/components/services';

class ServiceConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: '',
      sortable: false
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceId !== this.props.serviceId) {
      this.props.resetForm();
    }
  }

  filterChange = (ev) => {
    this.setState({ filter: ev.target.value });
  };

  handleToggleSorting = (ev) => {
    this.setState({ sortable: !this.state.sortable });
  };

  render() {
    const { loading, filter } = this.state;
    const { servicesById, serviceId, service, orderedServiceGroups, routeParams, resourceMappings, hasCustomDiffs, readOnly } = this.props;

    if (loading && servicesById.isEmpty()) {
      return <Loader withNavbar />;
    }

    if (!servicesById.isEmpty() && (!serviceId || serviceId && !service)) {
      return <Redirect to={getSectionUrl(routeParams, 'services', firstItemIdInGroup(orderedServiceGroups, 'serviceIds', servicesById))} />;
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          { this.state.sortable ? <SortableListBanner sortable={this.state.sortable} onToggleSorting={this.handleToggleSorting} /> : this.renderNonSortable() }
          <ServiceList
            routeParams={routeParams}
            selectedId={serviceId}
            filter={filter}
            sortable={this.state.sortable}
            readOnly={readOnly}
          />
        </div>
        <div className="columns-content">
          <div className="columns-content-container">
            <div className="columns-content-body">
              {service && this.renderMappingsWarning()}
              {service && <h4>{service.name}</h4>}
              {service && (
                <EditServiceForm
                  onSubmit={this.props.updateService}
                  onDelete={this.props.deleteService}
                  resourceMappings={resourceMappings}
                  serviceId={serviceId}
                  initialValues={service}
                  readOnly={readOnly}
                />
              )}
            </div>
            <div className="columns-content-sidebar">
              {service && (
                <ResourceServiceMappings
                  showCustomValues={!readOnly}
                  hasCustomDiffs={hasCustomDiffs}
                  serviceId={serviceId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNonSortable() {
    const { intl: { formatMessage: f } } = this.props;
    return (
      <div className="columns-header">
        <ServiceListToolbar onAddGroup={this.props.addServiceGroup} onAddService={this.props.addService} onToggleSorting={this.handleToggleSorting} />
        <div className="columns-filter">
          <input type="text" className="form-control" placeholder={f(msg.filterByName)} onChange={this.filterChange} />
        </div>
      </div>
    );
  }

  renderMappingsWarning() {
    const { intl: { formatMessage: f } } = this.props;
    if (this.props.resourceMappings.size === 0 && !this.state.loading) {
      return (
        <div className="alert alert-danger" role="alert">
          {f(msg.note)}
        </div>
      );
    }
    if (this.props.hasCustomDiffs) {
      return (
        <div className="alert alert-warning" role="alert">
          {f(msg.withIcon, { icon: <Fragment><i className="fa fa-fw fa-info-circle text-highlight-warn" /></Fragment> })}
        </div>
      );
    }
    return null;
  }
}

const hasDiffs = (values, mappings) => {
  if (mappings.size === 0) {
    return false;
  }
  for (let i = 0; i < serviceMappingValues.length; i++) {
    if (isServiceMappingDiff(values, mappings, serviceMappingValues[i])) {
      return true;
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const { servicesById, resourcesById, orderedServiceGroups, resourceServiceMappingsByCombinedId } = state;
  const routeParams = ownProps.match.params;
  const serviceId = parseInt(ownProps.match.params.id);
  const service = servicesById && serviceId && servicesById.get(serviceId);
  const readOnly = enableDashlFeatures(state);

  const resourceMappings = resourceServiceMappingsByCombinedId.filter((value, key) => {
    return key.endsWith(`:${serviceId}`);
  });

  const formValues = getFormValues('edit-service-form')(state);
  const hasCustomDiffs = !readOnly && formValues && hasDiffs(formValues, resourceMappings);

  return {
    orderedServiceGroups,
    serviceId,
    routeParams,
    servicesById,
    resourcesById,
    service,
    resourceMappings,
    hasCustomDiffs,
    formValues,
    readOnly
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const routeParams = ownProps.match.params;

  return {
    loadData: () => {
      return Promise.all([
        dispatch(fetchServices()),
        dispatch(fetchGroupsAndResources()),
        dispatch(fetchAllResourceMappings())
      ]);
    },
    addServiceGroup: (name) => {
      return dispatch(addServiceGroup(name));
    },
    addService: (service) => {
      return dispatch(addService(service))
        .then(service => navigate(getSectionUrl(routeParams, 'services', service.id)));
    },
    updateService: (service) => {
      return dispatch(updateService(service));
    },
    deleteService: (serviceId) => {
      return dispatch(deleteService(serviceId));
    },
    resetForm: () => {
      dispatch(destroy('edit-service-form'));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ServiceConfig));
