import React from 'react';
import RingLoader from 'halogenium/lib/RingLoader';

const Loader = ({ withNavbar }) => {
  return (
    <div className={withNavbar ? 'loader-container with-navbar' : 'loader-container'}>
      <RingLoader color="#D1A789" />
    </div>
  );
};

export default Loader;
