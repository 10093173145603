import { formatMessage } from '../intlContextInterseptor';

import { msgNetworkActions as msg } from '../localization/messages/state/state';

export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const NETWORK_FAILED = 'NETWORK_FAILED';
export const LOADING_DONE = 'LOADING_DONE';
export const VERSION_MISMATCH = 'VERSION_MISMATCH';

export function loading() {
  return {
    type: LOADING
  };
}

export function loadingDone() {
  return {
    type: LOADING_DONE
  };
}

export function networkFailure(message, title) {
  return {
    type: NETWORK_FAILED,
    title: title || formatMessage(msg.error),
    message: message || formatMessage(msg.errorDescription)
  };
}

export function resetNetworkFailure() {
  return {
    type: NETWORK_FAILED,
    message: null
  };
}

export function success(message) {
  return {
    type: SUCCESS,
    message
  };
}

export function versionMismatch(currentVersion, requiredVersion) {
  return {
    type: VERSION_MISMATCH,
    state: {
      currentVersion,
      requiredVersion
    }
  };
}
