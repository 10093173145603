import { defineMessages } from 'react-intl';

export const msgSearchField = defineMessages({
  search: {
    id: 'nav.SearchField.search',
    defaultMessage: 'Sök...'
  }
});

export const msgSections = defineMessages({
  more: {
    id: 'nav.Sections.more',
    defaultMessage: 'Mer...'
  },
  calendar: {
    id: 'nav.Sections.calendar',
    defaultMessage: 'Kalender'
  },
  customers: {
    id: 'nav.Sections.customers',
    defaultMessage: 'Kunder'
  },
  resources: {
    id: 'nav.Sections.resources',
    defaultMessage: 'Resurser'
  },
  services: {
    id: 'nav.Sections.services',
    defaultMessage: 'Tjänster'
  },
  reports: {
    id: 'nav.Sections.reports',
    defaultMessage: 'Rapporter'
  },
  campaign: {
    id: 'nav.Sections.campaign',
    defaultMessage: 'SMS-utskick'
  },
  accountConfig: {
    id: 'nav.Sections.accountConfig',
    defaultMessage: 'Inställningar'
  },
  organisations: {
    id: 'nav.Sections.organisations',
    defaultMessage: 'Inställningar'
  }
});

export const msgUserMenu = defineMessages({
  account: {
    id: 'nav.UserMenu.account',
    defaultMessage: 'KONTO'
  },
  searchAccount: {
    id: 'nav.UserMenu.searchAccount',
    defaultMessage: 'Sök kontonamn...'
  },
  subscription: {
    id: 'nav.UserMenu.subscription',
    defaultMessage: 'Abonnemang'
  },
  userSettings: {
    id: 'nav.UserMenu.userSettings',
    defaultMessage: 'Användarinställningar'
  },
  increaseContrast: {
    id: 'nav.UserMenu.increaseContrast',
    defaultMessage: 'Ökad kontrast'
  },
  increaseCalendarSize: {
    id: 'nav.UserMenu.increaseCalendarSize',
    defaultMessage: 'Ökad kalenderstorlek'
  },
  externalKeyboard: {
    id: 'nav.UserMenu.externalKeyboard',
    defaultMessage: 'Externt tangentbord'
  }
});
