module.exports = Chaplin.Model.extend({

   urlRoot: '/users',

   initialize: function () {
      this.setIsOwner();
      this.on('change:userRole', this.updateInterval, this);
   },

   setIsOwner: function () {
      this.set('isOwner', this.get('organisationRole') === 'OWNER');
   }

});

