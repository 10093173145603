import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { viewDateToStartEnd } from '../../utils/time-util';
import DateNavigator from './date-navigator';
import ViewModeSwitcher from './viewmode-switcher';
import ScheduleToggle from '../calendar/schedule-toggle';
import UndoButton from '../undo-button/undo-button';
import CurrentDateLabel from './current-date-label';
import MobileResourceSelector from '../calendar/mobile-resource-selector';

class CalendarTabBar extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    viewDate: PropTypes.string.isRequired,
    viewMode: PropTypes.oneOf(['week', 'day']).isRequired,
    entityType: PropTypes.oneOf(['resource', 'group']).isRequired,
    entityId: PropTypes.number.isRequired,
    gridClientWidth: PropTypes.number.isRequired,
    highContrast: PropTypes.bool.isRequired,
    phoneMode: PropTypes.bool.isRequired,
    tabletMode: PropTypes.bool.isRequired,
    resourcesById: PropTypes.object.isRequired,
    orderedGroups: PropTypes.object.isRequired
  };

  render() {
    const { routeParams, gridClientWidth, phoneMode, tabletMode } = this.props;
    const { viewMode, viewDate } = routeParams;
    const viewDateStartEnd = viewDateToStartEnd(viewMode, viewDate);

    return (
      <nav className="navbar navbar-static-top navbar-tabs calendar">

        {tabletMode && <MobileResourceSelector routeParams={routeParams} />}

        <DateNavigator routeParams={routeParams} viewDateStartEnd={viewDateStartEnd} />

        {!phoneMode && (
          <CurrentDateLabel
            viewMode={viewMode}
            phoneMode={phoneMode}
            tabletMode={tabletMode}
            viewDateStart={viewDateStartEnd.start}
            gridClientWidth={gridClientWidth}
          />
        )}

        <ViewModeSwitcher routeParams={routeParams} />

        {!phoneMode && (
          <div className="toolbar-controls navbar-right">
            <UndoButton phoneMode={phoneMode} tabletMode={tabletMode} />
            <ScheduleToggle phoneMode={phoneMode} tabletMode={tabletMode} />
          </div>
        )}
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, gridViewState, orderedGroups, resourcesById } = state;
  const { viewDate, viewMode, entityType, entityId } = ownProps.routeParams;

  return {
    viewDate,
    viewMode,
    entityType,
    entityId: parseInt(entityId),
    gridClientWidth: gridViewState.get('gridClientWidth'),
    highContrast: gridViewState.get('highContrast'),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    resourcesById,
    orderedGroups
  };
};

export default connect(
  mapStateToProps
)(CalendarTabBar);

