import { omit } from 'ramda';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { intlReducer } from 'react-intl-redux';
import Immutable from 'immutable';
import moment from 'moment';
import authState from '../../redux-login/reducers';
import { bkf, resourceServices } from './bkf-reducer';
import { cf } from './cf-reducer';
import {
  servicesById,
  orderedServiceGroups,
  resourceServiceMappingsByCombinedId
} from './services-reducer';
import {
  orderedGroups, resourcesById
} from './resource-reducer';

import {
  ADD_BOOKING, CHANGE_BOOKING, CHANGE_BOOKING_FLAG, CHANGE_BOOKING_STATUS,
  CHANGE_BOOKING_TYPE, CHANGE_BOOKING_PAYMENT, CONFIRM_BOOKING, DELETE_BOOKING, MOVE_BOOKING,
  REQUEST_BOOKINGS, REVERT_BOOKING, CANCEL_BOOKING, REFUND_BOOKING,
  SET_UNDOABLE_BOOKING, RESET_SEARCH, SEARCH_BOOKINGS, SHOW_SEARCH
} from './booking-actions';

import {
      SELECT_DATE, RECEIVE_VIEWDATA, TOGGLE_GRIDMARKER, UPDATE_GRIDMARKER,
      UPDATE_DIMENSIONS, SET_HIGH_CONTRAST, SET_CALENDAR_ROWS_PER_HOUR, SET_EXTERNAL_KEYBOARD,
      SET_GRID_SIZE, TOGGLE_SCHEDULE_EDIT_MODE, TOGGLE_GRID_SCROLLABILITY, STORE_VIEW_STATE
} from './view-actions';

import {
      SET_SCHEDULE_BLOCKS
} from './schedule-actions';

import {
      CHANGE_PASSWORD, SET_PASSWORD_POLICY, RECEIVE_NOTIFICATIONS, CHANGE_NOTIFICATIONS, SET_CLIENT_PREF
} from './user-actions';

import {
      ADD_CLIPBOARD_DRAGGER, REMOVE_CLIPBOARD_DRAGGER, ADD_BOOKING_TO_CLIPBOARD, PASTE_BOOKING, REMOVE_BOOKING_FROM_CLIPBOARD
} from './clipboard-actions';

import {
   GET_BOOKING_REPORT, GET_SMS_REPORT
} from './report-actions';

import {
   GET_CAMPAIGNS, GET_CAMPAIGN_RECIPIENTS, CREATE_CAMPAIGN, DELETE_CAMPAIGN
} from './campaign-actions';

import {
   VERSION_MISMATCH, NETWORK_FAILED
} from './network-actions';

import {
   ACCOUNT_STATUS, LOC_CONFIG_CHANGED, RECEIVE_LOC_OPTIONS, LOADING_CONFIG, CLEAR_LOCATION_STATE, FEATURES_LOADED
} from './account-actions';

import {
  CHANGE_CONTACT_INFO, CHANGE_PREFERENCES, RESET_RESOURCE_PREFERENCES
} from './preferences-actions';

import {
      UPDATE_CUSTOMER
} from './customer-actions';

import {
  SET_LOCATION_FEATURE
} from './features-actions';


function appState(state = Immutable.Map({
  currentVersion: 0,
  requiredVersion: 0,
  trialUntil: null,
  trialStatus: '',
  accountStatus: 'Active',
  networkError: '',
  networkErrorTitle: '',
  features: ''
}), action = null) {
  switch (action.type) {
    case ACCOUNT_STATUS:
    case VERSION_MISMATCH:
      return state.merge(action.state);

    case NETWORK_FAILED:
      return state.merge({ networkError: action.message, networkErrorTitle: action.title });

    default :
      return state;
  }
}

function mainViewState(state = Immutable.Map({
  phoneMode: false,
  tabletMode: false,
  useWideLabel: true,
  forceUpdateTs: new Date(),
  deviceType: '' // Look at initial state for default setting
}), action = null) {
  switch (action.type) {

    case UPDATE_DIMENSIONS:
      return state.merge({
        phoneMode: action.phoneMode,
        tabletMode: action.tabletMode,
        useWideLabel: action.useWideLabel,
        forceUpdateTs: new Date()
      });
    default :
      return state;
  }
}

function gridViewState(state = Immutable.Map({
  pixelsPerRow: 15,
  rowsPerHour: 6,
  gridMarkerDuration: 60,
  gridClientWidth: 500,
  gridClientHeight: 500,
  highContrast: false,
  largeCalendar: false,
  showGridMarker: false,
  clipBoardDragger: null,
  scheduleEditMode: false,
  undoableBooking: null,
  externalKeyboard: false,
  scrollBars: false,
  scrollBarWidth: 0,
  gridSize: 'small',
  gridScrollable: true // if set to true, prevent scrolling of the grid view, when popups are open etc..
}), action = null) {
  switch (action.type) {

    case CLEAR_LOCATION_STATE:
      return state.merge({
        undoableBooking: null
      });

    case CHANGE_PREFERENCES:

      if (action.state.generalPreferences && action.state.generalPreferences.gridRowsPerHour) {
        return state.merge({
          rowsPerHour: parseInt(action.state.generalPreferences.gridRowsPerHour)
        });
      }
      return state;


    case LOC_CONFIG_CHANGED:
      if (action.state.gridRowsPerHour) {
        return state.merge({
          rowsPerHour: parseInt(action.state.gridRowsPerHour)
        });
      }
      return state;


    case TOGGLE_GRID_SCROLLABILITY:
      return state.merge({
        gridScrollable: action.scrollable
      });

    case TOGGLE_SCHEDULE_EDIT_MODE:
      return state.merge({
        undoableBooking: null,
        scheduleEditMode: action.state
      });

    case UPDATE_DIMENSIONS:
      return state.merge({
        gridClientWidth: action.gridClientWidth,
        gridClientHeight: action.gridClientHeight
      });

    case SET_HIGH_CONTRAST:
      return state.merge({
        highContrast: action.enabled
      });

    case SET_CALENDAR_ROWS_PER_HOUR:
      return state.merge({
        rowsPerHour: action.rowsPerHour
      });

    case SET_GRID_SIZE:
      return state.merge({
        gridSize: action.size
      });

    case SET_EXTERNAL_KEYBOARD:
      return state.merge({
        externalKeyboard: action.enabled
      });

    case TOGGLE_GRIDMARKER:
      return state.merge({
        showGridMarker: action.state.visible,
        gridMarkerDuration: action.state.duration
      });

    case UPDATE_GRIDMARKER: {
      return state.merge({
        gridMarkerDuration: action.update.duration
      });
    }

    case SET_UNDOABLE_BOOKING:
      return state.merge({
        undoableBooking: action.booking
      });

    case SELECT_DATE:
    case RECEIVE_VIEWDATA:
      return state.merge({
        undoableBooking: null
      });

    case ADD_BOOKING:
    case DELETE_BOOKING:
    case PASTE_BOOKING:
    case CANCEL_BOOKING:
    case UPDATE_CUSTOMER:
    case SET_SCHEDULE_BLOCKS:
      return state.merge({
        undoableBooking: null
      });

    default :
      return state;
  }
}


/**
 * viewMode = 'week|day', entityType = 'resource|group',  entityId =  id,  viewDate = '2014-39|2014-09-01'
 *
 * viewMode and viewDate could be combined into say: 2014-09-12 = day, 2014W39 = week, 2014M09 = month
 *
 */
function calendarViewState(state = Immutable.Map({
  lastView: null
}), action = null) {
  switch (action.type) {

    case CLEAR_LOCATION_STATE:
      return state.merge({
        lastView: null
      });
    case SELECT_DATE:
      return state.set('viewDate', action.viewDate);

    case STORE_VIEW_STATE:
      return state.set('lastView', action.viewState);

    default :
      return state;
  }
}


function schedulesByResource(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SET_SCHEDULE_BLOCKS:
      {
        const { resourceId, type, ...scheduleData } = action;
        return state.set(resourceId, {
          resourceId,
          ...scheduleData
        });
      }

    case RECEIVE_VIEWDATA:
      {
        const newState = state.clear().withMutations((map) => {
          for (const sc of action.viewData.schedules) {
            map.set(sc.resourceId, sc);
          }
        });
        return newState;
      }
    default:
      return state;
  }
}

function bookingsById(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();
    case CHANGE_BOOKING_FLAG:
      {
        const { bookingId, flags } = action.change;
        const booking = state.get(bookingId);
        if (!booking) {
          console.error(`No booking found for id: ${bookingId}`);
          return state;
        }
        const newBooking = Object.assign({}, booking, flags);
        return state.set(bookingId, newBooking);
      }

    case CHANGE_BOOKING_STATUS:
      {
        const bkId = action.change.bookingId,
          change = action.change;
        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }

        const newBooking = Object.assign({}, booking, {
          status: change.status
        });
        return state.set(bkId, newBooking);
      }

    case CHANGE_BOOKING_TYPE:
      {
        const bkId = action.change.bookingId,
          change = action.change;
        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }
        let newBooking = Object.assign({}, booking, {
          type: change.type
        });

        // Strip all props that's not part of a Reservation
        //
        if (change.type === 'Reservation') {
          newBooking = omit(
            [
              'customerId', 'customerName', 'customerPhoneNumber', 'customerEmail',
              'services', 'description', 'serviceDuration', 'afterTime'
            ], newBooking);
        }
        return state.set(bkId, newBooking);
      }

    case CHANGE_BOOKING_PAYMENT:
      {
        const { bookingId, paymentStatus } = action.change;
        const booking = state.get(bookingId);
        if (!booking) {
          console.error(`No booking found for id: ${bookingId}`);
          return state;
        }
        const newBooking = Object.assign({}, booking, {
          dashlPayment: { ...booking.dashlPayment, paymentStatus }
        });

        return state.set(bookingId, newBooking);
      }

    case CANCEL_BOOKING:
      {
        const bkId = action.id;
        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }

        const newBooking = Object.assign({}, booking, {
          status: 'Cancelled',
          cancelledTime: moment(action.changes.cancelledTime),
          cancelled: true,
          cancelledChannel: action.changes.cancelledChannel
        });
        return state.set(bkId, newBooking);
      }

    case REFUND_BOOKING:
      {
        const bkId = action.id;
        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }

        const payment = Object.assign({}, booking.payment, {
          paymentStatus: 'Refunded',
          refundTs: moment(action.refund.created)
        });
        const newBooking = Object.assign({}, booking, { payment });
        return state.set(bkId, newBooking);
      }

    case ADD_BOOKING:
      {
        return state.set(
               action.booking.id,
               Object.assign({}, action.booking, datesAsMoments(action.booking), calcProperties(action.booking))
         );
      }

    case PASTE_BOOKING:
      {
        return action.booking.copyOnPaste ?
             state :
             state.set(
                action.booking.id,
                Object.assign({}, action.booking, datesAsMoments(action.booking)
             )
         );
      }

    case CONFIRM_BOOKING:
      {
        const bkId = action.id;
        const {
          customerId, customerName, customerPhoneNumber, customerEmail, orgNo,
          orgName, companyId, vehicleRegNo, vehicleDescription, payment, attributes
        } = action.booking;

        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }

        const newBooking = Object.assign({}, booking, {
          customerId,
          customerName,
          customerPhoneNumber,
          customerEmail,
          companyId,
          orgNo,
          orgName,
          vehicleRegNo,
          vehicleDescription,
          pending: false,
          pendingUntil: null,
          payment,
          attributes
        });

        return state.set(bkId, newBooking);
      }

    case DELETE_BOOKING:
      {
        return state.delete(action.id);
      }
    case CHANGE_BOOKING:
      {
        const bkId = action.id;
        const {
          customerId, customerName, customerPhoneNumber, customerOtherPhoneNumber,
          customerEmail, type, description, note, afterTime, price,
          serviceDuration, startTime, endTime, services, orgNo, orgName,
          companyId, vehicleRegNo, vehicleDescription, attributes
        } = action.booking;

        const booking = state.get(bkId);
        if (!booking) {
          console.error(`No booking found for id: ${bkId}`);
          return state;
        }

        const newBooking = Object.assign({}, booking, {
          customerId,
          customerName,
          customerPhoneNumber,
          customerOtherPhoneNumber,
          customerEmail,
          type,
          services,
          description,
          serviceDuration,
          afterTime,
          price,
          note,
          companyId,
          orgNo,
          orgName,
          vehicleRegNo,
          vehicleDescription,
          endTime: moment(endTime),
          startTime: moment(startTime),
          reservation: type === 'Reservation',
          attributes
        });

        return state.set(bkId, newBooking);
      }

    case MOVE_BOOKING:
      {
         // TODO: Ensure this only contains datetime and resourceId
         //
        const { id, startTime, endTime, resourceId } = action.booking;

        const booking = state.get(id);
        if (!booking) {
          console.error(`No booking found for id: ${id}`);
          return state;
        }

        const newBooking = Object.assign({}, booking, {
          resourceId: resourceId || booking.resourceId,
          startTime: moment(startTime),
          endTime: moment(endTime),
          undone: action.isUndo
        });
        return state.set(id, newBooking);
      }
    case UPDATE_CUSTOMER:
      {
        const cId = action.id;
        const { name, phoneNumber, otherPhoneNumber, email } = action.customer;

        const booking = state.find(b => b.customerId === cId);
        if (!booking) {
            // No booking mathed the changed customer
          return state;
        }

        const bkId = booking.id;

         // Only update fields that are allowed on a UPDATE_CUSTOMER.
         //
        const newBooking = Object.assign({}, booking, {
          customerName: name,
          customerPhoneNumber: phoneNumber,
          customerOtherPhoneNumber: otherPhoneNumber,
          customerEmail: email
        });

        return state.set(bkId, newBooking);
      }

    case REVERT_BOOKING:
      return state;

    case REQUEST_BOOKINGS:
      return state;

    case RECEIVE_VIEWDATA:
      const newState = state.clear().withMutations((map) => {
        for (const booking of action.viewData.bookings) {
          map.set(booking.id, Object.assign({}, booking, datesAsMoments(booking), calcProperties(booking)));
        }
      });
      return newState;
    default:
      return state;
  }
}

function bookingSearchResults(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case RESET_SEARCH:
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SHOW_SEARCH:
      return state.set('showSearch', true);

    case SEARCH_BOOKINGS:
      return state.merge({ query: action.query, bookings: action.bookings });

    default:
      return state;
  }
}

function clipboardState(state = Immutable.Map({
  clipboardDragger: null
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();
    case ADD_CLIPBOARD_DRAGGER:
      return state.set('clipboardDragger', Object.assign({}, action.dragger));
    case REMOVE_CLIPBOARD_DRAGGER:
      return state.delete('clipboardDragger');
    default:
      return state;
  }
}

function bookingsClipboard(state = Immutable.OrderedMap({}), action = null) {
  switch (action.type) {
    case ADD_BOOKING_TO_CLIPBOARD:
      return state.set(action.booking.id, Object.assign({}, action.booking));

    case PASTE_BOOKING:
      return state.delete(action.booking.id);

    case REMOVE_BOOKING_FROM_CLIPBOARD:
      return state.delete(action.bookingId);

    default:
      return state;
  }
}

function calcProperties(bk) {
  return {
    cancelled: bk.status === 'Cancelled',
    reservation: bk.type === 'Reservation'
  };
}

function datesAsMoments(bk) {
  return {
    startTime: moment.isMoment(bk.startTime) ? bk.startTime : moment(bk.startTime),
    endTime: moment(bk.endTime),
    createdTime: moment(bk.createdTime),
    lastUpdateTime: moment(bk.lastUpdateTime),
    cancelledTime: bk.cancelledTime ? moment(bk.cancelledTime) : bk.cancelledTime,
    pendingUntil: bk.pendingUntil ? moment(bk.pendingUntil) : bk.pendingUntil,
    smsStatusUpdateTime: bk.smsStatusUpdateTime ? moment(bk.smsStatusUpdateTime) : bk.smsStatusUpdateTime
  };
}


function userConfigViewState(state = Immutable.Map({
  passwordPolicy: null,
  wrongPassword: false,
  changePasswordSuccess: false,
  isChangingPassword: false,
  isChangingNotifications: false,
  bookingEmailPreference: ''
}), action = null) {
  switch (action.type) {
    case CHANGE_PASSWORD:
    case RECEIVE_NOTIFICATIONS:
    case CHANGE_NOTIFICATIONS:
      return state.merge(action.state);

    case SET_PASSWORD_POLICY:
      return state.merge({ passwordPolicy: action.passwordPolicy });

    default:
      return state;
  }
}

function preferencesViewState(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case CHANGE_PREFERENCES:
      return state.merge(action.state);

    case CHANGE_CONTACT_INFO: {
      if (action.contact) {
        return state.setIn(['contacts', action.contactType], Immutable.Map(action.contact));
      }
      return state.deleteIn(['contacts', action.contactType]);
    }

    case RESET_RESOURCE_PREFERENCES:
      return state.delete('resourcePreferences');

    default:
      return state;
  }
}

function reportsViewState(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();
    case GET_BOOKING_REPORT:
    case GET_SMS_REPORT:
      return state.merge(action.state);

    default:
      return state;
  }
}

function campaignViewState(state = Immutable.Map({
  recipients: 0
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();
    case GET_CAMPAIGNS:
    case GET_CAMPAIGN_RECIPIENTS:
      return state.merge(action.state);

    case CREATE_CAMPAIGN: {
      const newCampaigns = [];
      newCampaigns.push(action.campaign);
      state.get('campaigns').forEach((campaign) => {
        newCampaigns.push(campaign.toJS());
      });
      return state.merge({ campaigns: newCampaigns });
    }

    case DELETE_CAMPAIGN: {
      const campaigns = state.get('campaigns');
      const index = campaigns.findIndex((campaign) => {
        return campaign.get('id') === action.campaignId;
      });
      const newCampaigns = campaigns.delete(index);
      return state.merge({ campaigns: newCampaigns });
    }

    default:
      return state;
  }
}

function userClientPreferences(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case SET_CLIENT_PREF:
      return state.mergeDeep(action.state);
    default:
      return state;
  }
}

function locationConfig(state = Immutable.Map({
  state: 'NOT_LOADED'
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case LOC_CONFIG_CHANGED:
      return state.mergeDeep(action.state);

    case CHANGE_PREFERENCES:
      if (action.state.jsonPreferences) {
        return state.mergeDeep(action.state.jsonPreferences);
      }
      if (action.state.generalPreferences) {
        return state.mergeDeep(action.state.generalPreferences);
      }
      if (action.state.webBookingPreferences) {
        return state.mergeDeep(action.state.webBookingPreferences);
      }
      if (action.state.smsPreferences) {
        return state.mergeDeep(action.state.smsPreferences);
      }
      if (action.state.emailPreferences) {
        return state.mergeDeep(action.state.emailPreferences);
      }
      if (action.state.storagePreferences) {
        return state.mergeDeep(action.state.storagePreferences);
      }
      return state;

    default:
      return state;
  }
}

/*
 features, configuration
 */
function locationFeatures(state = Immutable.Map({
  state: 'NOT_LOADED'
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear().set('state', 'NOT_LOADED');

    case LOADING_CONFIG:
      return state.clear().set('state', 'LOADING');

    case FEATURES_LOADED:
      return state.mergeDeep(action.features).set('state', 'LOADED');

    case SET_LOCATION_FEATURE:
      return state.set(action.name, action.enabled);

    default:
      return state;
  }
}

function locationOptions(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case RECEIVE_LOC_OPTIONS:
      const newState = state.clear().withMutations((map) => {
        for (const location of action.state.locations) {
          map.set(location.orgLoc, location);
        }
      });
      return newState;

    case CHANGE_PREFERENCES:
      if (action.state.orgLoc && action.state.companyInfo) {
        const { orgName } = action.state.companyInfo;
        const location = state.get(action.state.orgLoc);
        const newLocation = location.orgName === location.locName ?
          { ...location, orgName, locName: orgName } :
          { ...location, orgName };
        return state.set(action.state.orgLoc, newLocation);
      }
      return state;

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  appState,
  authState,
  orderedGroups, /* resource groups ordered in a List */
  resourcesById, /* resources in a Map keyed by id */
  bookingsById,  /* bookings in a Map keyed by id */
  bookingSearchResults,
  bookingsClipboard,
  clipboardState,
  schedulesByResource,
  calendarViewState,
  mainViewState,
  gridViewState,
  reportsViewState,
  campaignViewState,
  userConfigViewState,
  preferencesViewState,
  userClientPreferences,
  form: formReducer,
  locationConfig,
  intl: intlReducer,
  locationOptions,
  locationFeatures,
  bkf,
  resourceServices, /* ONLY USED BY BKF - resourceServices in a Map keyed by resource id , K: resourceId, V: List<Services>  */
  cf,
  servicesById,
  orderedServiceGroups,
  resourceServiceMappingsByCombinedId /* resourceServices in a Map keyed by resId combined with srvId, K: resId:srvId, V: ServiceMapping */
});

export default rootReducer;

