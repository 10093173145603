var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <form class=\"form-horizontal\">\n                <div class=\"modal-header\">\n                    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n                        <span aria-hidden=\"true\">&times;</span>\n                        <span class=\"sr-only\">Close</span></button>\n                    <h4 class=\"modal-title\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addUsers",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n                </div>\n                <div class=\"modal-body\">\n                    <p>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"userInvitationExplanation",{"name":"i18n","hash":{},"data":data}))
    + "</p><br />\n                    <div class=\"form-group js-email-section\">\n                        <label class=\"control-label col-md-4 text-left\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailAddress",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                        <div class=\"col-md-6\">\n                            <input type=\"email\" name=\"email\" class=\"form-control\"\n                                   placeholder=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailAddress",{"name":"i18n","hash":{},"data":data}))
    + "\"\n                                   data-parsley-required-message=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"enterValidEmail",{"name":"i18n","hash":{},"data":data}))
    + "\"\n                                   required />\n                            <span id=\"email-exists-help\" class=\"help-block\" style=\"display: none;\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label class=\"control-label col-md-4 text-left\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"roleRights",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                        <div class=\"col-md-6\">\n                            <select name=\"locationRole\" class=\"form-control js-role-select\">\n                                <option value=\"Administrator\">"
    + ((stack1 = (helpers.RoleDescription || (depth0 && depth0.RoleDescription) || alias2).call(alias1,"Administrator",{"name":"RoleDescription","hash":{},"data":data})) != null ? stack1 : "")
    + "</option>\n                                <option value=\"FrontOfficeUser\">"
    + ((stack1 = (helpers.RoleDescription || (depth0 && depth0.RoleDescription) || alias2).call(alias1,"FrontOfficeUser",{"name":"RoleDescription","hash":{},"data":data})) != null ? stack1 : "")
    + "</option>\n                                <option value=\"UnprivilegedUser\" selected=\"selected\">"
    + ((stack1 = (helpers.RoleDescription || (depth0 && depth0.RoleDescription) || alias2).call(alias1,"UnprivilegedUser",{"name":"RoleDescription","hash":{},"data":data})) != null ? stack1 : "")
    + "</option>\n                            </select>\n                        </div>\n                    </div>\n                    <div class=\"form-group js-resource-select-section\">\n                        <label class=\"col-md-4 text-left control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"userResource",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                        <div class=\"col-md-6\">\n                            <select name=\"resourceId\" class=\"form-control js-resource-select\"></select>\n                            <span id=\"no-selected-resource-help\" class=\"help-block\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"modal-footer\">\n                    <div class=\"pull-right\">\n                        <button type=\"submit\" class=\"btn-save\" data-loading-text=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addingUsers",{"name":"i18n","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addUsers",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                        <a href=\"#\" class=\"btn-cancel\" data-dismiss=\"modal\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>\n";
},"useData":true});