var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression((helpers.DateTime || (depth0 && depth0.DateTime) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.validUntil : depth0),"LL",{"name":"DateTime","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"untilFurtherNotice",{"name":"i18n","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n    <h4>\n        "
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n        <small>\n            "
    + alias3((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.validFrom : depth0),"LL",{"name":"DateTime","hash":{},"data":data}))
    + " - \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.validUntil : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </small>\n    </h4>\n    <div id=\"schedule-week-container\"></div>\n    <div id=\"schedule-item-container\" class=\"schedule-table\"></div>\n</div>\n";
},"useData":true});