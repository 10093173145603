var s = require('underscore.string');

module.exports = Chaplin.Model.extend({

	urlRoot: function(){
		return s.sprintf('/customers/%s/logentries', this.attributes.customerId);
	},

	initialize: function () {
	},

  set: function (attributes, options) {
    if (attributes.lastUpdate !== undefined && attributes.created !== undefined) {
      attributes.isUpdated = attributes.lastUpdate && attributes.lastUpdate !== attributes.created;
    }
    return Backbone.Model.prototype.set.call(this, attributes, options);
  }
});
