import Immutable from 'immutable';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { doMoveGroup, doMoveItem } from './action-helpers';
import { CHANGE_PREFERENCES } from './preferences-actions';
import {
  ADD_GROUP, ADD_RESOURCE, DELETE_GROUP, DELETE_RESOURCE,
  RECEIVE_GROUPS_AND_RESOURCES,
  RENAME_GROUP,
  SORT_GROUPS,
  UPDATE_RESOURCE,
  GROUP_MOVED,
  RESOURCE_MOVED
} from './resource-actions';

export function orderedGroups(state = Immutable.List(), action = null) {
  switch (action.type) {

    case CLEAR_LOCATION_STATE:
      return state.clear();

    case DELETE_RESOURCE:
      {
        const resourceId = action.id;
        let resIdx = -1;

        const gIdx = state.findIndex((g) => {
          resIdx = g.get('resourceIds').indexOf(resourceId);
          return resIdx != -1;
        });

        const oldGroup = state.get(gIdx);
        const resources = oldGroup.get('resourceIds');
        const newGroup = oldGroup.set('resourceIds', resources.delete(resIdx));
        return state.set(gIdx, newGroup);
      }
    case ADD_RESOURCE:
      {
        const gIdx = state.findIndex(g => g.get('id') === action.groupId);
        const oldGroup = state.get(gIdx);
        const resources = oldGroup.get('resourceIds');
        const newGroup = oldGroup.set('resourceIds', resources.unshift(action.resource.id));
        return state.set(gIdx, newGroup);
      }

    case RENAME_GROUP:
      {
        const gIdx = state.findIndex(g => g.get('id') === action.id);
        const oldGroup = state.get(gIdx);
        const newGroup = oldGroup.set('name', action.name);
        return state.set(gIdx, newGroup);
      }
    case DELETE_GROUP:
      {
        const delIdx = state.findIndex(g => g.get('id') === action.groupId);
        return (delIdx === -1) ? state : state.delete(delIdx);
      }
    case SORT_GROUPS:
      {
        return state.clear().withMutations((list) => {
          for (const group of action.groups) {
          // find the name of the group
          //
            const name = state.find(g => g.get('id') === group.groupId).get('name');

            list.push(Immutable.fromJS({
              id: group.groupId,
              resourceIds: group.itemIds,
              name
            }));
          }
        });
      }

    case GROUP_MOVED:
      return doMoveGroup(state, action.moveAction);
    case RESOURCE_MOVED:
      return doMoveItem(state, action.moveAction, 'resourceIds');


    case ADD_GROUP:
      // Using unshift to add it to the top of the list
      //
      return state.unshift(Immutable.fromJS(action.group));

    case RECEIVE_GROUPS_AND_RESOURCES:
      return Immutable.fromJS(action.groups);

    default:
      return state;
  }
}

export function resourcesById(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case ADD_RESOURCE: {
      const resource = action.resource;
      return state.set(resource.id, resource);
    }
    case UPDATE_RESOURCE: {
      const resource = action.resource;
      return state.set(resource.id, resource);
    }
    case DELETE_RESOURCE:
      return state.delete(action.id);

    case RECEIVE_GROUPS_AND_RESOURCES:
      const newState = state.clear().withMutations((map) => {
        for (const resource of action.resources) {
          map.set(resource.id, resource);
        }
      });
      return newState;

    case CHANGE_PREFERENCES:
      const { resourceId } = action;

      if (resourceId) {
        const res = state.get(resourceId);
        const newRes = Object.assign({}, res, { name: action.state.resourcePreferences.name });
        return state.set(resourceId, newRes);
      }

      return state;

    default:
      return state;
  }
}
