const ModalView = require('../base/modal-view');
const LogView = require('../customers/item/log/log-view');
const BookingsView = require('../customers/item/booking-history/bookings-view');
const FormUtil = require('../../utils/form-util');
const PhoneUtil = require('../../utils/phone-util');

module.exports = ModalView.extend({
  newItemTitle: 'Ny kund',

  initialize(options) {
    this.template = require('./customer-form');
    this.model.fetch();
    ModalView.prototype.initialize.call(this, arguments);
  },

  events: {
    'click a[data-toggle]': 'toggleTab'
  },

  listen: {
    'change model': 'populate'
  },

  save(e) {
    e.preventDefault();

    if (this.subview('customer-log').isCommentUnsaved()) {
      this.subview('customer-log').saveComment(
        e,
        _.bind(this.saveCustomer, this)
      );
    } else {
      this.saveCustomer();
    }
  },

  saveCustomer() {
    const id = this.model.get('id');
    const customer = this.getFormData();
    this.publishEvent('redux:updateCustomer', id, customer);
    this.saved(customer);
  },

  getFormData() {
    const formData = this.$('form').serializeJSON({ parseNumbers: false });
    return {
      name: formData.name,
      phoneNumber: PhoneUtil.formatPhoneNumberE164(formData.phoneNumber),
      smsReminders: formData.smsReminders,
      emailReminders: formData.emailReminders,
      allowMarketing: formData.allowMarketing,
      email: formData.email,
      gender: formData.gender,
      otherPhoneNumber: PhoneUtil.formatPhoneNumberE164(
        formData.otherPhoneNumber
      ),
      dateOfBirth: formData.dateOfBirth,
      address1: formData.address1,
      address2: formData.address2,
      address3: formData.address3,
      area: formData.area,
      postCode: formData.postCode,
      country: formData.country,
      notes: formData.notes
    };
  },

  saved(formData) {
    this.model.set(formData);
    this.trigger('saved', formData);
    this.close();
  },

  populate() {
    const frm = this.$('form');
    const data = this.model.attributes;
    FormUtil.populate(frm, data);
  },

  toggleTab(e) {
    e.preventDefault();
    $(e.currentTarget).tab('show');
  },

  render() {
    ModalView.prototype.render.call(this, arguments);

    this.model.attributes.phoneNumber = PhoneUtil.formatPhoneNumber(
      this.model.attributes.phoneNumber
    );
    this.model.attributes.otherPhoneNumber = PhoneUtil.formatPhoneNumber(
      this.model.attributes.otherPhoneNumber
    );

    this.populate();
    this.delegate('submit', 'form', this.save);

    this.setTitle(
      this.model.isNew()
        ? this.newItemTitle
        : `${this.model.get('name')} - #${this.model.id}`
    );

    this.subview(
      'customer-log',
      new LogView({
        model: this.model,
        container: this.$('#customer-log-container')
      })
    );

    this.subview(
      'customer-bookings',
      new BookingsView({
        model: this.model,
        container: this.$('#customer-bookings-container')
      })
    );
  }
});
