import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import { setHighContrast, setGridSize, setExternalKeyboard } from '../../state/view-actions';
import { getSectionUrl, getOrganisationsUrl } from '../../utils/navigate';
import { hasPermission, CHANGE_LOCATION_SETTINGS } from '../../utils/permissions';
import { getCurrentLocation, getFeatures, getPermissions } from '../../utils/selectors';

import { msgUserMenu as msg } from '../../localization/messages/components/nav';
import { msgButtons } from '../../localization/messages/shared/buttons';


const UserMenuLocationOption = (props) => {
  const { org, loc, orgLoc, currentOrgLoc, orgName, locName } = props;
  const className = currentOrgLoc === orgLoc ? 'active' : '';
  const url = `/${org}/${loc}/`;
  const orgLocName = orgName === locName ? orgName : `${orgName} (${locName})`;

  return (
    <li className={className}><Link to={url}>{orgLocName}</Link></li>
  );
};

UserMenuLocationOption.propTypes = {
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  orgLoc: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  locName: PropTypes.string.isRequired,
  currentOrgLoc: PropTypes.string.isRequired
};


class UserMenu extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      locationFilter: ''
    };
  }

  toggleHighContrast = (event) => {
    event.preventDefault();
    const highContrast = !this.props.highContrast;
    this.props.setHighContrast(highContrast);
  };

  toggleGridSize = (event) => {
    event.preventDefault();
    const newSize = this.props.gridSize === 'large' ? 'small' : 'large';
    this.props.setGridSize(newSize);
  };

  toggleExternalKeyboard = (event) => {
    event.preventDefault();
    const externalKeyboard = !this.props.externalKeyboard;
    this.props.setExternalKeyboard(externalKeyboard);
  };

  formatLocationName(location) {
    if (location.orgName === location.locName) {
      return location.orgName;
    }
    return `${location.orgName} (${location.locName})`;
  }

  filterLocations = (event) => {
    this.setState({ locationFilter: event.target.value.toLowerCase() });
  };

  render() {
    const { routeParams, currentLocation, username, sortedOptions, phoneMode, highContrast, gridSize,
      externalKeyboard, features, permissions, showLocationFilter, deviceType, intl: { formatMessage: f } } = this.props;
    const showAccountSettings = phoneMode && features.ShowPayments && hasPermission(permissions, CHANGE_LOCATION_SETTINGS);

    const { locationFilter } = this.state;

    const filtered = locationFilter ?
             sortedOptions.filter((value) => {
               return value.orgName.toLowerCase().indexOf(locationFilter) !== -1 || value.locName.toLowerCase().indexOf(locationFilter) !== -1;
             }) : sortedOptions;

    const locName = currentLocation ? this.formatLocationName(currentLocation) : 'NO CURRENT LOCATION';

    return (
      <div className="navbar-right">
        <ul className="nav navbar-nav">

          <li className="dropdown">

            <a href="#" className="dropdown-toggle navbar-icon" data-toggle="dropdown" style={{ touchAction: 'manipulation' }}>
              { !phoneMode ?
                <div className="stacked-container">
                  <div>
                    <span className="location">{locName}</span><br />
                    <span className="username">{username}</span>
                  </div>
                  <div>
                    <b className="caret" />
                  </div>
                </div>
                          :
                <div className="stacked-container">
                  <div>
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x" />
                      <i className="fa fa-user fa-stack-1x fa-inverse" />
                    </span>
                  </div>
                </div>
                          }
            </a>

            <ul className="dropdown-menu" id="user-menu" style={{ minWidth: 210 }}>
              <li className="dropdown-header">{f(msg.account)}</li>
              {showLocationFilter ?
                <li className="search">
                  <input type="text" onChange={this.filterLocations} placeholder={f(msg.searchAccount)} />
                </li> : null}
              {filtered.map((option, i) =>
                (<UserMenuLocationOption
                  key={option.orgLoc}
                  org={option.orgCtxName}
                  loc={option.locCtxName}
                  orgLoc={option.orgLoc}
                  orgName={option.orgName}
                  locName={option.locName}
                  currentOrgLoc={currentLocation.orgLoc}
                />)
                          )}
              {showAccountSettings ? <li className="divider" /> : null}
              {showAccountSettings ? <li><a href={getOrganisationsUrl(routeParams)}><i className="fa fa-fw fa-cloud" /> {f(msg.subscription)}</a></li> : null}
              <li className="divider" />


              {features.ShowUserMenuSettings && (<li><Link to={getSectionUrl(routeParams, 'user-config')}><i className="fa fa-fw fa-user" /> {f(msg.userSettings)}</Link></li>)}
              {features.ShowUserMenuSettings && (<li className="divider" />)}


              <li><a href="#" onClick={this.toggleHighContrast}>
                {highContrast ? <i className="fa fa-fw fa-check text-info" style={{ float: 'right', marginTop: 3 }} /> : null}
                <i className="fa fa-fw fa-adjust" /> {f(msg.increaseContrast)}</a>
              </li>
              <li><a href="#" onClick={this.toggleGridSize}>
                {gridSize === 'large' ? <i className="fa fa-fw fa-check text-info" style={{ float: 'right', marginTop: 3 }} /> : null}
                <i className="fa fa-fw fa-search" /> {f(msg.increaseCalendarSize)}</a>
              </li>
              {deviceType === 'tablet' && (
                <li><a href="#" onClick={this.toggleExternalKeyboard}>
                  {externalKeyboard ? <i className="fa fa-fw fa-check text-info" style={{ float: 'right', marginTop: 3 }} /> : null}
                  <i className="fa fa-fw fa-keyboard-o" /> {f(msg.externalKeyboard)}</a>
                </li>
              )}

              {features.ShowUserMenuLogout && (<li className="divider" />)}
              {features.ShowUserMenuLogout && (<li><a href="/logout"><i className="fa fa-fw fa-lock" /> {f(msgButtons.btnLogOut)}</a></li>)}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

UserMenu.propTypes = {
  sortedOptions: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  features: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  currentLocation: PropTypes.object,
  username: PropTypes.string.isRequired,
  highContrast: PropTypes.bool.isRequired,
  gridSize: PropTypes.oneOf(['xsmall', 'small', 'large']),
  phoneMode: PropTypes.bool.isRequired,
  setHighContrast: PropTypes.func.isRequired,
  setGridSize: PropTypes.func.isRequired
};

const getOptions = state => state.locationOptions;
const getRouteParams = (state, props) => props.routeParams;
const getShowFilter = (state) => {
  return state.locationOptions.size > 10;
};

const getSortedOptions = createSelector(
      getOptions,
      (options) => {
        return options.valueSeq().sort((a, b) => {
          const aStr = (a.orgName + a.locName);
          const bStr = (b.orgName + b.locName);
          return aStr.localeCompare(bStr, 'sv');
        });
      });

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, gridViewState, authState } = state;

  return {
    sortedOptions: getSortedOptions(state, ownProps),
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state),
    routeParams: getRouteParams(state, ownProps),
    currentLocation: getCurrentLocation(state, ownProps),
    showLocationFilter: getShowFilter(state),
    username: authState.get('username'),
    highContrast: gridViewState.get('highContrast'),
    gridSize: gridViewState.get('gridSize'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    deviceType: mainViewState.get('deviceType')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHighContrast: (enabled) => {
      dispatch(setHighContrast(enabled));
    },
    setGridSize: (newSize) => {
      dispatch(setGridSize(newSize));
    },
    setExternalKeyboard: (enabled) => {
      dispatch(setExternalKeyboard(enabled));
    }
  };
};

export default injectIntl(connect(
      mapStateToProps,
      mapDispatchToProps
)(UserMenu));
