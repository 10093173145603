import { defineMessages } from 'react-intl';

export const msgBookings = defineMessages({
  reservations: {
    id: 'reports.Bookings.reservations',
    defaultMessage: 'Bokningar: {count, number}'
  },
  canceled: {
    id: 'reports.Bookings.canceled',
    defaultMessage: 'Avbokade: {cancelledCount, number}'
  },
  summPrice: {
    id: 'reports.Bookings.sumPrice',
    defaultMessage: 'Summa pris: {sum}'
  },
  noBookings: {
    id: 'reports.Bookings.noBookings',
    defaultMessage: 'Det finns inga bokningar för vald resurs och period.'
  }
});

export const msgSms = defineMessages({
  resource: {
    id: 'reports.Sms.Resurs',
    defaultMessage: 'Resource'
  },
  sentSms: {
    id: 'reports.Sms.sentSms',
    defaultMessage: 'Skickade SMS'
  },
  cost: {
    id: 'reports.Sms.cost',
    defaultMessage: 'Kostnad'
  },
  noResource: {
    id: 'reports.Sms.noResource',
    defaultMessage: 'Ingen resurs'
  },
  noSentSms: {
    id: 'reports.Sms.noSentSms',
    defaultMessage: 'Det finns inga skickade SMS för vald period.'
  },
  confirm: {
    id: 'reports.Sms.confirm',
    defaultMessage: 'Bekräftelser'
  },
  reminder: {
    id: 'reports.Sms.reminder',
    defaultMessage: 'Påminnelser'
  },
  bulk: {
    id: 'reports.Sms.bulk',
    defaultMessage: 'Utskick'
  },
  pin: {
    id: 'reports.Sms.pin',
    defaultMessage: 'Bekräftelse med SMS-kod'
  },
  free: {
    id: 'reports.Sms.free',
    defaultMessage: 'Gratis SMS'
  },
  sms: {
    id: 'reports.Sms.sms',
    defaultMessage: 'SMS'
  }
});
