var CollectionView = require('../../base/collection-view');
var ExceptionItemView = require('./schedule-exception-item-view');
var ConfirmDeletePopoverView = require('../../base/confirm-popover-view');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = CollectionView.extend({
	noWrap: true,
	autoRender: true,
	listSelector: '.js-schedule-exceptions-container',

	initialize: function () {
      this.template = require('./schedule-exceptions');
		this.listenTo(this.collection, 'add', this.updateGridVisibility);
		this.listenTo(this.collection, 'reset', this.updateGridVisibility);
		this.listenTo(this.collection, 'remove', this.updateGridVisibility);
		CollectionView.prototype.initialize.call(this, arguments);
	},

	events: {
		'click .js-delete-all-exceptions': 'confirmDeleteAll'
	},

	updateGridVisibility: function(){
		if (this.collection.length === 0){
			this.$('.js-exceptions').addClass('hidden');
		}
		else{
			this.$('.js-exceptions').removeClass('hidden');
		}
	},

	initItemView: function (model) {
		var itemView  = new ExceptionItemView({model: model});
		this.listenTo(itemView, 'delete', this.delete);
		return itemView;
	},

	delete: function (item) {
		$.ajax({
			type: 'DELETE',
			url: '/schedules/resource/' + this.model.id + '/exceptions/' + item.attributes.date,
			success: _.bind(function(result) {
				item.destroy();
			}, this)
		});

		return false;
	},

	confirmDeleteAll: function (e) {
		e.preventDefault();

		this.confirmationView = new ConfirmDeletePopoverView({
			title: formatMessage(msgHandlebars.deleteExceptionsTitle),
			confirmText: formatMessage(msgHandlebars.deleteExceptionsText),
			buttonText: formatMessage(msgHandlebars.deleteExceptionsTitle),
			callback: _.bind(this.deleteAll, this),
			parent: $(e.currentTarget)
		});
		this.subview('popover', this.confirmationView);
	},

	deleteAll: function () {
		this.confirmationView.close();
		$.ajax({
			type: 'DELETE',
			url: '/schedules/resource/' + this.model.id + '/exceptions/ALL',
			success: _.bind(function(result) {
				this.collection.reset();
			}, this)
		});

		return false;
	},

	render: function () {
		CollectionView.prototype.render.call(this, arguments);
		this.updateGridVisibility();
	}
});
