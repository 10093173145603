var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<form class=\"form-horizontal\" role=\"form\">\n   <fieldset>\n      <legend>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailNotification",{"name":"i18n","hash":{},"data":data}))
    + "</legend>\n\n      <div class=\"form-group\">\n         <label class=\"col-sm-2 control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailNotification",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n         <div class=\"col-sm-4\">\n             <select name=\"bookingEmailPreference\" class=\"form-control\">\n                 <option value=\"None\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"offNoMailing",{"name":"i18n","hash":{},"data":data}))
    + "</option>\n                 <option value=\"Web\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"webBookingMailing",{"name":"i18n","hash":{},"data":data}))
    + "</option>\n                 <option value=\"All\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"allBookingMailing",{"name":"i18n","hash":{},"data":data}))
    + "</option>\n             </select>\n         </div>\n         <p class=\"col-sm-6 form-help-text\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"sendEmailAfterAction",{"name":"i18n","hash":{},"data":data}))
    + "</p>\n      </div>\n\n       <div class=\"form-group\">\n           <label class=\"col-sm-2 control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailAddress",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n           <div class=\"col-sm-4\">\n               <input type=\"email\" class=\"form-control\" name=\"email\" />\n           </div>\n           <p class=\"col-sm-6 form-help-text\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"emailRecipient",{"name":"i18n","hash":{},"data":data}))
    + "</p>\n       </div>\n   </fieldset>\n\n   <div class=\"form-group\">\n      <div class=\"col-sm-offset-2 col-sm-5\">\n         <button type=\"submit\" class=\"btn btn-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"saveChanges",{"name":"i18n","hash":{},"data":data}))
    + "</button>&nbsp;\n      </div>\n   </div>\n</form>\n";
},"useData":true});