import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { ChipDragHandler } from '../grid/paste-chip-draghandler';
import { visualStylesHC, visualStylesNonHC, styles } from '../grid/chip-styles';

import { msgClipboard as msg } from '../../localization/messages/components/clipboard';

class ClipboardItem extends Component {

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(this.props);
  }

  makeInitialState() {
    return {
      isDragging: false
    };
  }

  render() {
    const { booking } = this.props;
    const iconClass = booking.copyOnPaste ? 'fa fa-copy' : 'fa fa-scissors';
    const closeClass = this.state.isDragging ? 'fa fa-close invisible' : 'fa fa-close tap-close';

    return (
      <div className="btn btn-sm animated pulse" style={this.styles()}>
        {this.state.isDragging ? '' : <a
          href="#"
          className="tap-close"
          style={{ float: 'right', padding: '5px 10px', margin: '-5px -10px -5px 0' }}
          onClick={this.cancelPasteBooking}
        ><i className={closeClass} /></a> }

        <span>{ this.cramped() ? '' : <i className={iconClass} /> } {this.label()}</span>
      </div>
    );
  }

  cramped() {
    return this.props.averageClipSize < 150;
  }

  label() {
    const { booking, intl: { formatMessage: f } } = this.props;
    const { bookedAs, customerName, service, startTime } = booking;
    const time = startTime.format('DD/MM HH:mm');

    return `${bookedAs || customerName || service || time} ${f(msg.minutes, { duration: this.duration() })}`;
  }

  duration() {
    return this.props.booking.endTime.diff(this.props.booking.startTime, 'minutes');
  }

  styles() {
    const visualStyles = this.props.highContrast ? visualStylesHC : visualStylesNonHC;

    return Object.assign({},
      this.state.isDragging ? styles.chips.placeholder : styles.chips.base,
      this.state.isDragging ? { width: this.width } : visualStyles.statuses.Booked,
      { paddingLeft: this.cramped() ? 4 : 8 }
    );
  }

  cancelPasteBooking = (ev) => {
    ev.preventDefault();
    this.props.onCancelItem(this.props.booking.id);
    ev.target.blur();
  };

  draggable() {
    if (!this.chipDragHandler) {
      this.chipDragHandler = new ChipDragHandler(this);
    }
  }

  undraggable() {
    if (this.chipDragHandler) {
      this.chipDragHandler.dispose();
      this.chipDragHandler = undefined;
    }
  }

  componentDidMount() {
    this.draggable(this);
    const chipEl = ReactDOM.findDOMNode(this);
    const rect = chipEl.getBoundingClientRect();
    this.width = rect.width;
  }

  componentDidUpdate(prevProps, prevState) {
    const chipEl = ReactDOM.findDOMNode(this);
    const rect = chipEl.getBoundingClientRect();

    if (!this.state.isDragging) {
      this.width = rect.width;
    }
  }


  componentWillUnmount() {
    this.undraggable();
  }

  componentWillReceiveProps(nextProps) {
    if (this.chipDragHandler) {
      this.chipDragHandler.componentWillReceiveProps(nextProps);
    }
  }
}

ClipboardItem.propTypes = {
  highContrast: PropTypes.bool.isRequired,
  averageClipSize: PropTypes.number.isRequired,
  booking: PropTypes.object.isRequired,
  gridProps: PropTypes.object.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onCancelItem: PropTypes.func.isRequired,
  onAddChipDragger: PropTypes.func.isRequired,
  onRemoveChipDragger: PropTypes.func.isRequired,
  onAddClipboardDragger: PropTypes.func.isRequired,
  onPasteBooking: PropTypes.func.isRequired
};

export default injectIntl(ClipboardItem);
