import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';

import TextInputHorizontal from '../../inputs/text-input-horizontal';
import SmsTemplateInput from '../../inputs/sms-template-input';
import { createValidator, required, maxLength } from '../../../utils/validation';
import { defaultSmsConfirmTemplate, defaultSmsReminderTemplate } from '../../../utils/templates';
import { getSectionUrl } from '../../../utils/navigate';
import WebBookingStrategy from '../../inputs/web-booking-strategy';
import EmailIcsConfig from '../../inputs/email-ics-config';
import RichTextEditorField from '../../inputs/rich-text-editor';
import { translateRouteParams } from '../../../utils/time-util';
import { vipBookingUrl, webBookingUrl } from '../../../utils/config';
import SubmitButton from '../../common/submit-button';
import { Popover } from '../../common/popover';

import { msgResourceSettingsForm as msg } from '../../../localization/messages/components/resources';
import { msgButtons } from '../../../localization/messages/shared/buttons';

class ResourceSettingsForm extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    resource: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    deleteResource: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    useCustomValues: PropTypes.bool.isRequired,
    smsReminderWithin: PropTypes.number.isRequired,
    previewValues: PropTypes.object.isRequired
  };

  state = {
    confirmDelete: false,
    deleteProgress: false
  };

  render() {
    const { routeParams, handleSubmit, features, name, intl: { formatMessage: f },
      useCustomValues, smsReminderWithin, resource, previewValues, showCustomValues, showDelete } = this.props;
    const showSmsEmailSettings = features.emailOnBookOnline || features.emailOnCancelOnline || features.smsOnBookOnline || features.smsOnCancelOnline;
    const webBookingLink = `${webBookingUrl()}${routeParams.org}/${routeParams.loc}/`;
    const vipBookingLink = `${vipBookingUrl()}${resource.hashId}`;

    return (
      <form className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h4><FormattedMessage {...msg.settingsFor} values={{ name }} /></h4>
          </div>
        </div>

        {showCustomValues && (
          <Fragment>
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <div className="checkbox">
                  <label>
                    <Field name="preferences.useCustomValues" component="input" type="checkbox" />
                    <FormattedMessage {...msg.useCustomSettings} />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-9">
                <p className="text-muted">
                  <FormattedMessage {...msg.withYourOwnSettings} values={{ br: <br /> }} />
                  <Link to={getSectionUrl(translateRouteParams(routeParams), 'account-config')}><FormattedMessage {...msg.accountSettings} /></Link>
                </p>
              </div>
            </div>
          </Fragment>
        )}

        <hr />

        <Field name="name" component={TextInputHorizontal} label={f(msg.name)} />

        {showCustomValues && (
          <Fragment>
            <hr />
            <div className="form-group">
              <label className="col-sm-3 control-label"><FormattedMessage {...msg.webBooking} /></label>
              <div className="col-sm-6">
                <div className="checkbox">
                  <label>
                    <Field name="preferences.webBookingEnabled" component="input" type="checkbox" />
                    <FormattedMessage {...msg.activateWebBooking} />
                  </label>
                </div>
                <p className="text-muted" style={{ marginTop: 5, marginBottom: 10 }}>
                  <FormattedMessage {...msg.joinWebBooking} />
                </p>
                <p className="text-muted" style={{ marginTop: 5, marginBottom: 10 }}>
                  <FormattedMessage {...msg.linkToWebBooking} /> <a href={webBookingLink} target="_blank">{webBookingLink}</a>
                </p>
                <div className="checkbox">
                  <label>
                    <Field name="preferences.vipBookingEnabled" component="input" type="checkbox" />
                    <FormattedMessage {...msg.activateVipBooking} />
                  </label>
                </div>
                <p className="text-muted" style={{ marginTop: 5, marginBottom: 10 }}>
                  <FormattedMessage {...msg.explainVipBooking} />
                </p>
                <p className="text-muted" style={{ marginTop: 5, marginBottom: 10 }}>
                  <FormattedMessage {...msg.linkToVipBooking} /> <a href={vipBookingLink} target="_blank">{vipBookingLink}</a>
                </p>
              </div>
            </div>
          </Fragment>
        )}

        <Field name="preferences.webName" component={TextInputHorizontal} label={f(msg.webBookingName)} />

        {showCustomValues && (
          <Fragment>
            {useCustomValues && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label"><FormattedMessage {...msg.algorithm} /></label>
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-sm-9">
                        <WebBookingStrategy propertyName="preferences.webSlotFinderStrategy" {...this.props} />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group">
                  <label className="col-sm-3 control-label"><FormattedMessage {...msg.allowBookingLatest} /></label>
                  <div className="col-sm-3">
                    <Field name="preferences.webMinTimeBeforeBooking" component="input" type="number" className="form-control" />
                  </div>
                  <p className="col-sm-3 form-control-static"><FormattedMessage {...msg.minutesBefore} /></p>
                </div>

                <div className="form-group">
                  <label className="col-sm-3 control-label"><FormattedMessage {...msg.allowBookingUpTo} /></label>
                  <div className="col-sm-3">
                    <Field name="preferences.webBookingMaxDaysInAdvance" component="input" type="number" className="form-control" />
                  </div>
                  <p className="col-sm-3 form-control-static"><FormattedMessage {...msg.daysBefore} /></p>
                </div>

                {showSmsEmailSettings && (
                  <div className="form-group">
                    <label className="col-sm-3 control-label"><FormattedMessage {...msg.confirmation} /></label>
                    <div className="col-sm-9">
                      {features.emailOnBookOnline && (
                        <div className="checkbox">
                          <label>
                            <Field name="preferences.emailOnBookOnline" component="input" type="checkbox" />
                            <FormattedMessage {...msg.sendEmailBooking} />
                          </label>
                        </div>
                      )}
                      {features.emailOnCancelOnline && (
                        <div className="checkbox">
                          <label>
                            <Field name="preferences.emailOnCancelOnline" component="input" type="checkbox" />
                            <FormattedMessage {...msg.sendEmailCanceling} />
                          </label>
                        </div>
                      )}
                      {features.smsOnBookOnline && features.EnableSmsConfirmations && (
                        <div className="checkbox">
                          <label>
                            <Field name="preferences.smsOnBookOnline" component="input" type="checkbox" />
                            <FormattedMessage {...msg.sendSmsBooking} />
                          </label>
                        </div>
                      )}
                      {features.smsOnCancelOnline && features.EnableSmsConfirmations && (
                        <div className="checkbox">
                          <label>
                            <Field name="preferences.smsOnCancelOnline" component="input" type="checkbox" />
                            <FormattedMessage {...msg.sendSmsCancelling} />
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(features.emailConfirmExtraInfo) && (
                  <div>
                    <hr />
                    <div className="form-group">
                      <label className="col-sm-3 control-label">{f(msg.extraInformation)}<br /><small className="text-warning">{f(msg.forBookingConfirmation)}</small></label>
                      <div className="col-sm-6">
                        <Field name="preferences.emailConfirmExtraInfo" component={RichTextEditorField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-offset-3 col-sm-5">
                        <p className="text-muted">
                          <FormattedMessage {...msg.addExtraInformation} />
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {(features.emailIcsConfirm) && (
                  <div>
                    <hr />
                    <div className="form-group">
                      <div className="col-sm-offset-3 col-sm-9">
                        <EmailIcsConfig prefix="preferences" {...this.props} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <hr />

            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <div className="checkbox">
                  <label>
                    <Field name="preferences.useSmsReminders" component="input" type="checkbox" />
                    <FormattedMessage {...msg.sendReminders} />
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-offset-3 col-sm-9">
                <p className="text-muted">
                  <FormattedMessage {...msg.remindersSentTo} values={{ smsReminderWithin }} />
                </p><br />
              </div>
            </div>

            {useCustomValues && (
              <div>
                {features.smsConfirmTemplate && features.EnableSmsConfirmations && (
                  <div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label"><FormattedMessage {...msg.textInConfirmation} /></label>
                      <div className="col-sm-9">
                        <Field
                          name="preferences.smsConfirmTemplate"
                          component={SmsTemplateInput}
                          defaultTemplate={defaultSmsConfirmTemplate()}
                          replaceValues={previewValues}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {features.smsReminderTemplate && (
                  <div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label"><FormattedMessage {...msg.textInReminder} /></label>
                      <div className="col-sm-9">
                        <Field
                          name="preferences.smsReminderTemplate"
                          component={SmsTemplateInput}
                          defaultTemplate={defaultSmsReminderTemplate()}
                          replaceValues={previewValues}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )}

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} />  &nbsp;
            {showDelete && (
              <Popover isOpen={this.state.confirmDelete} body={this.confirmDeleteContent()} onOuterAction={this.hideConfirmDelete}>
                <a href="#" className="btn btn-delete" onClick={this.showConfirmDelete}><FormattedMessage {...msgButtons.btnRemove} /></a>
              </Popover>
            )}
          </div>
        </div>
      </form>
    );
  }

  showConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ confirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = (ev) => {
    if (ev) { ev.preventDefault(); }
    this.setState({ confirmDelete: false });
  };

  confirmDeleteContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        <p><FormattedMessage {...msg.areYouSureText} /></p>

        <div className="text-right">
          <button type="button" className="btn-block btn-delete" onClick={this.deleteResource} disabled={this.state.deleteProgress}>
            <FormattedMessage {...msg.areYouSureConfirm} />
          </button>
        </div>
      </div>
    );
  }

  deleteResource = (ev) => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props.deleteResource()
      .catch(() => this.setState({ deleteProgress: false }));
  }
}


const validator = createValidator({
  name: [required, maxLength(50)]
});

const selector = formValueSelector('resource-preferences');

const mapStateToProps = (state) => {
  return {
    name: selector(state, 'name'),
    useCustomValues: selector(state, 'preferences.useCustomValues'),
    slotFinderStrategy: selector(state, 'preferences.webSlotFinderStrategy'),
    includeIcs: selector(state, 'preferences.includeIcs'),
    icsHeader: selector(state, 'preferences.icsHeader'),
    icsLocation: selector(state, 'preferences.icsLocation'),
    icsNotes: selector(state, 'preferences.icsNotes'),
    smsReminderWithin: state.locationConfig.get('smsReminderWithin')
  };
};

export default reduxForm({
  form: 'resource-preferences',
  validate: validator
})(injectIntl(connect(mapStateToProps)(ResourceSettingsForm)));
