import { pick } from 'ramda';
import { fromJS, Map, List } from 'immutable';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { doMoveGroup, doMoveItem } from './action-helpers';
import {
  SERVICES_LOADED,
  RES_SRV__MAPPINGS_LOADED,
  CUSTOM_VALUES_UPDATED,
  SERVICE_GROUP_ADDED,
  SERVICE_GROUP_UPDATED,
  SERVICE_GROUP_DELETED,
  SERVICE_ADDED,
  SERVICE_UPDATED,
  SERVICE_DELETED,
  SERVICE_MOVED,
  SERVICE_GROUP_MOVED,
  SERVICE_MAPPING_ADDED,
  SERVICE_MAPPING_DELETED,
  SERVICE_MAPPINGS_SET,
  serviceMappingValues
} from './services-actions';

export function servicesById(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICES_LOADED: {
      return state.clear().withMutations((map) => {
        for (const service of action.services.services) {
          map.set(service.id, service);
        }
      });
    }
    case SERVICE_ADDED:
      return state.set(action.service.id, action.service);

    case SERVICE_UPDATED:
      return state.set(action.service.id, action.service);

    case SERVICE_DELETED:
      return state.delete(action.serviceId);
    default:
      return state;
  }
}

export function orderedServiceGroups(state = List(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICES_LOADED:
      return fromJS(action.services.groups);

    case SERVICE_GROUP_MOVED:
      return doMoveGroup(state, action.moveAction);

    case SERVICE_MOVED:
      return doMoveItem(state, action.moveAction, 'serviceIds');

    case SERVICE_GROUP_ADDED:
      return state.unshift(fromJS(action.group));

    case SERVICE_GROUP_UPDATED: {
      const index = state.findIndex(g => g.get('id') === action.group.id);
      return state.setIn([index, 'name'], action.group.name);
    }

    case SERVICE_GROUP_DELETED: {
      const index = state.findIndex(g => g.get('id') === action.groupId);
      return state.delete(index);
    }

    case SERVICE_ADDED: {
      const index = state.findIndex(g => g.get('id') === action.groupId);
      const serviceIds = state.getIn([index, 'serviceIds']).unshift(action.service.id);
      return state.setIn([index, 'serviceIds'], serviceIds);
    }

    case SERVICE_DELETED: {
      const { serviceId } = action;

      let serviceIndex = -1;
      const groupIndex = state.findIndex((g) => {
        serviceIndex = g.get('serviceIds').indexOf(serviceId);
        return serviceIndex !== -1;
      });

      const serviceIds = state.getIn([groupIndex, 'serviceIds']).delete(serviceIndex);
      return state.setIn([groupIndex, 'serviceIds'], serviceIds);
    }
    default:
      return state;
  }
}

export function resourceServiceMappingsByCombinedId(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case RES_SRV__MAPPINGS_LOADED:
      return state.merge(action.serviceMappings);

    case SERVICE_MAPPING_ADDED: {
      const { serviceId, resourceId, mapping } = action;
      return state.set(`${resourceId}:${serviceId}`, fromJS(mapping));
    }

    case SERVICE_MAPPING_DELETED: {
      const { serviceId, resourceId } = action;
      return state.delete(`${resourceId}:${serviceId}`);
    }

    case SERVICE_MAPPINGS_SET:
      return setServiceMappings(state, action);

    case CUSTOM_VALUES_UPDATED: {
      const { serviceId, resourceId, mapping } = action;
      return state.set(`${resourceId}:${serviceId}`, fromJS(mapping));
    }

    case SERVICE_UPDATED:
      return setServiceMappingDefaultValues(state, action);

    default:
      return state;
  }
}

function setServiceMappings(state, action) {
  const { serviceId, resourceIds, mapping } = action;
  const keys = resourceIds.map(resourceId => `${resourceId}:${serviceId}`);

  const newState = state.withMutations((state) => {
    state.forEach((value, key, iterator) => {
      if (value.get('serviceId') === serviceId && !keys.includes(key)) {
        iterator.delete(key);
      }
    });
  });

  return newState.withMutations((state) => {
    keys.forEach((key) => {
      if (!state.has(key)) {
        state.set(key, fromJS(mapping));
      }
    });
  });
}

function setServiceMappingDefaultValues(state, action) {
  const { service } = action;
  const values = pick(serviceMappingValues, service);

  return state.withMutations((mappings) => {
    mappings.forEach((mapping, key) => {
      if (mapping.get('serviceId') === service.id && !mapping.get('customValues')) {
        mappings.set(key, mapping.merge(values));
      }
    });
  });
}
