import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleScheduleEditMode } from '../../state/view-actions';

import { msgScheduleEditBanner as msg } from '../../localization/messages/components/grid';
import { msgButtons } from '../../localization/messages/shared/buttons';

class ScheduleEditBanner extends Component {

  static propTypes = {
    scheduleEditMode: PropTypes.bool.isRequired,
    phoneMode: PropTypes.bool.isRequired,
    toggleScheduleEditMode: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { scheduleEditMode, phoneMode, intl: { formatMessage: f } } = this.props;

    const style = {
      fontSize: phoneMode ? '0.8em' : '1.0em'
    };

    const text = phoneMode ? f(msg.phoneModeText) : f(msg.basicText);

    return (
            scheduleEditMode ?
              <div
                id="gridbanner"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 35,
                  justifyContent: 'center'
                }}
              >
                {phoneMode ? <span className="text-muted" style={style}>{text}</span> : <span>{f(msg.shedule)}<span className="text-muted" style={style}>&nbsp;-&nbsp;{text}</span></span>}

                {phoneMode ? '' : <button className="btn btn-sm btn-default" onClick={this.toggleScheduleEditMode}>{f(msgButtons.btnClear)}</button>}
              </div> : <div />
    );
  }

  toggleScheduleEditMode = (ev) => {
    this.props.toggleScheduleEditMode(false);
  };
}

ScheduleEditBanner.propTypes = {
  scheduleEditMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  toggleScheduleEditMode: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    scheduleEditMode: state.gridViewState.get('scheduleEditMode'),
    phoneMode: state.mainViewState.get('phoneMode')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScheduleEditMode: (state) => {
      dispatch(toggleScheduleEditMode(state));
    }
  };
};

export default injectIntl(connect(
      mapStateToProps,
      mapDispatchToProps
)(ScheduleEditBanner));
