var View = require('../base/view');

var CustomerNoItemDetailsView = View.extend({
   region: 'customer-item-region',
   noWrap: true,
   autoRender:true,

   initialize: function () {
      this.template = require('./no-item-details');
      View.prototype.initialize.call(this, arguments);
   },

   getTemplateData: function(){
      return {};
   }
});

module.exports = CustomerNoItemDetailsView;