import { defineMessages } from 'react-intl';

export const msgAccountInfoAlert = defineMessages({
  daysLeft: {
    id: 'dialogs.AccountInfoAlert.daysLeft',
    defaultMessage: '{daysLeft, plural, one {en dag} other {# dagar}}'
  },
  trialStatusHeading: {
    id: 'dialogs.AccountInfoAlert.trialStatusHeading',
    defaultMessage: 'Välkommen till Dashl!'
  },
  trialStatusContent: {
    id: 'dialogs.AccountInfoAlert.trialStatusContent',
    defaultMessage: 'Hej! Du har {daysLeftText} kvar av din provperiod. Du kan aktivera ditt konto när som helst under {organisationsLink}.'
  },
  trialStatusLink: {
    id: 'dialogs.AccountInfoAlert.trialStatusLink',
    defaultMessage: 'Kom igång'
  },
  trialExpiredHeading: {
    id: 'dialogs.AccountInfoAlert.trialExpiredHeading',
    defaultMessage: 'Din gratis provperiod är slut'
  },
  creaditCardMessage: {
    id: 'dialogs.AccountInfoAlert.creaditCardMessage',
    defaultMessage: 'För att fortsätta använda Dashl behöver du ange ett kreditkort så vi kan debitera dig för ditt användande.'
  },
  getMore: {
    id: 'dialogs.AccountInfoAlert.getMore',
    defaultMessage: 'Gå till {organisationsLink} för mer information om våra priser, eller kontakta oss om du har några frågor.'
  },
  blockedHeading: {
    id: 'dialogs.AccountInfoAlert.blockedHeading',
    defaultMessage: 'Ditt konto är låst'
  },
  linkSubscription: {
    id: 'dialogs.AccountInfoAlert.linkSubscription',
    defaultMessage: 'Abonnemang'
  },
  btnSubscription: {
    id: 'dialogs.AccountInfoAlert.btnSubscription',
    defaultMessage: 'Gå till Abonnemang'
  }
});

export const msgAccountStatus = defineMessages({
  trialExpired: {
    id: 'dialogs.AccountStatus.trialExpired',
    defaultMessage: 'Din gratis provperiod är slut. Gå till {organisationsLink} för att aktivera ditt konto.'
  },
  paymentRequired: {
    id: 'dialogs.AccountStatus.paymentRequired',
    defaultMessage: 'Betalning saknas, vänligen kontrollera betalningsinformationen som är kopplad till kontot under {organisationsLink}.'
  },
  terminated: {
    id: 'dialogs.AccountStatus.terminated',
    defaultMessage: 'Kontot är avslutat. Vänligen kontakta  hello@dashl.se för att aktivera kontot igen.'
  }
});

export const msgAuthRequired = defineMessages({
  logIn: {
    id: 'dialogs.AuthRequired.logIn',
    defaultMessage: 'Logga in'
  },
  info: {
    id: 'dialogs.AuthRequired.info',
    defaultMessage: 'Vi kunde inte verifiera ditt användarnamn och lösenord. {linebreak}{linebreak} Vänligen logga in på nytt'
  }
});

export const msgFeatureNotAvailable = defineMessages({
  subText: {
    id: 'dialogs.FeatureNotAvailable.subText',
    defaultMessage: 'Du kan uppgradera ditt konto när som helst för att få tillgång till funktionen'
  },
  linkGoToSubscription: {
    id: 'dialogs.FeatureNotAvailable.linkGoToSubscription',
    defaultMessage: 'Gå till Abonnemang'
  }
});

export const msgAboutDialog = defineMessages({
  heading: {
    id: 'dialogs.AboutDialog.heading',
    defaultMessage: 'Nyheter i Dashl'
  },
  version: {
    id: 'dialogs.AboutDialog.version',
    defaultMessage: 'Version {clientBuildNumber}'
  }
});


export const msgNoResourcePrompts = defineMessages({
  noResourceHeading: {
    id: 'dialogs.NoResourcePrompts.noResourceHeading',
    defaultMessage: 'Det finns ingen resurs kopplad till ditt inlogg'
  },
  phoneModeUsage: {
    id: 'dialogs.NoResourcePrompts.phoneModeUsage',
    defaultMessage: 'Mobil-läget går bara att använda om det finns en resurs kopplad till ditt inlogg.'
  },
  phoneModeConnectByOther: {
    id: 'dialogs.NoResourcePrompts.phoneModeConnectByOther',
    defaultMessage: 'För att koppla ihop en resurs med ditt inlogg måste du använda en dator, ipad eller annan utrustning med en större skärm.'
  },
  addResourceHeading: {
    id: 'dialogs.NoResourcePrompts.addResourceHeading',
    defaultMessage: 'Lägg till en resurs för att komma igång'
  },
  addResourceDescription: {
    id: 'dialogs.NoResourcePrompts.addResourceDescription',
    defaultMessage: 'För att använda Dashl behöver du först lägga till en eller flera resurser. En resurs är kopplad till ett inlogg och har en kalender, ett schema samt ett antal tjänster som kan bokas.'
  },
  addResourceLogIn: {
    id: 'dialogs.NoResourcePrompts.addResourceLogIn',
    defaultMessage: 'Börja med att skapa en resurs som vi kopplar till ditt inlogg:'
  },
  yourName: {
    id: 'dialogs.NoResourcePrompts.yourName',
    defaultMessage: 'Ditt namn'
  },
  noAccessDescription: {
    id: 'dialogs.NoResourcePrompts.noAccessDescription',
    defaultMessage: ' Det finns ingen resurs kopplad till ditt inlogg och du har inte tillräcklig behörighet för att skapa en. Vänligen kontakta administratören av kontot som kan hjälpa dig med detta.'
  }
});

export const msgSocialContactContent = defineMessages({
  followUs: {
    id: 'dialogs.SocialContactContent.followUs',
    defaultMessage: 'Följ oss i sociala medier för att få information om uppdateringar och andra nyheter.'
  }
});

export const msgStorageConfigAlert = defineMessages({
  heading: {
    id: 'dialogs.StorageConfigAlert.heading',
    defaultMessage: 'Kontrollera inställningar för lagring'
  },
  newRegulation: {
    id: 'dialogs.StorageConfigAlert.newRegulation',
    defaultMessage: 'Den 25 maj börjar den nya dataskyddsförordningen (GDPR) gälla. Därför har vi lagt till nya inställningar för att hantera lagring av personuppgifter.'
  },
  checkSettings: {
    id: 'dialogs.StorageConfigAlert.checkSettings',
    defaultMessage: 'Ni behöver kontrollera dessa inställningar så att vi hanterar era kunders uppgifter korrekt.'
  },
  hiddenMessage: {
    id: 'dialogs.StorageConfigAlert.hiddenMessage',
    defaultMessage: 'Detta meddelande kommer döljas när inställningarna sparats. Ni kan när som helst uppdatera inställningarna under Inställningar {greaterThan} Lagring.'
  },
  btnRemindLater: {
    id: 'dialogs.StorageConfigAlert.btnRemindLater',
    defaultMessage: 'Påminn mig senare'
  },
  btnCheckSettings: {
    id: 'dialogs.StorageConfigAlert.btnCheckSettings',
    defaultMessage: 'Kontrollera inställningar'
  }
});

export const msgVersionMismatchAlert = defineMessages({
  heading: {
    id: 'dialogs.VersionMismatchAlert.heading',
    defaultMessage: 'Ny version tillgänglig!'
  },
  description: {
    id: 'dialogs.VersionMismatchAlert.description',
    defaultMessage: 'Det finns en ny version av Dashl, vänligen klicka <b>Ladda om</b> för att uppdatera.'
  },
  btnReload: {
    id: 'dialogs.VersionMismatchAlert.btnReload',
    defaultMessage: 'Ladda om'
  }
});
