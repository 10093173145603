import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment-timezone';

export function setLocale(locale, timeZone) {
  moment.locale(locale || 'sv');
  moment.tz.setDefault(timeZone || 'Europe/Stockholm');
}

