import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fetchGroupsAndResources } from '../state/resource-actions';
import { getCampaigns, getCampaignRecipients, createCampaign, deleteCampaign } from '../state/campaign-actions';
import Send from '../components/campaign/send';
import List from '../components/campaign/list';
import SendNotAllowedDialog from '../components/campaign/send-not-allowed-dialog';
import FeatureNotAvailable from '../components/dialogs/feature-not-available';
import { msgCampaign as msg } from '../localization/messages/containers/containers';

class Campaign extends Component {

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      showSend: false
    };
  }

  componentDidMount() {
    const { dispatch, resourcesById } = this.props;

    if (resourcesById.isEmpty()) {
      dispatch(fetchGroupsAndResources());
    }
    dispatch(getCampaigns());
  }

  render() {
    const { enableSms, intl: { formatMessage: f } } = this.props;

    if (enableSms) {
      return this.renderFeature();
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <FeatureNotAvailable leadText={f(msg.featureUnavailable)} showLink />
      </div>
    );
  }

  renderFeature() {
    const { campaignViewState, resourcesById, accountStatus, trialStatus, smsBillingEnabled, sender, smsBulkPrice } = this.props;
    const { recipients } = campaignViewState.toJS();
    const { showSend } = this.state;
    const accountNotActive = accountStatus !== 'Active' || trialStatus !== null;
    const smsBillingDisabled = !smsBillingEnabled;

    return (
      <div className="container" style={{ marginTop: 20 }}>
        {showSend ?
          <Send
            resourcesById={resourcesById}
            recipients={recipients}
            smsBulkPrice={smsBulkPrice}
            sender={sender}
            refreshRecipients={this.refreshRecipients}
            createCampaign={this.createCampaign}
            showList={this.showList}
          /> :
          <List
            campaignViewState={campaignViewState}
            resourcesById={resourcesById}
            deleteCampaign={this.deleteCampaign}
            showSend={this.showSend}
          />
            }
        {showSend && (accountNotActive || smsBillingDisabled) ?
          <SendNotAllowedDialog
            accountNotActive={accountNotActive}
            smsBillingDisabled={smsBillingDisabled}
          /> : null}
      </div>
    );
  }

  refreshRecipients = (resourceIds, lastBookedSince, includeOrphanedCustomers, bookedUntil) => {
    const { dispatch } = this.props;

    dispatch(getCampaignRecipients(resourceIds, lastBookedSince, includeOrphanedCustomers, bookedUntil));
  };

  createCampaign = (data) => {
    const { dispatch } = this.props;

    dispatch(createCampaign(data));

    this.setState({ showSend: false });
  };

  deleteCampaign = (id) => {
    const { dispatch } = this.props;

    dispatch(deleteCampaign(id));
  };

  showSend = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showSend: true });
  };

  showList = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showSend: false });
  };
}

Campaign.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mainViewState: PropTypes.object.isRequired,
  campaignViewState: PropTypes.object.isRequired,
  resourcesById: PropTypes.object.isRequired,
  enableSms: PropTypes.bool.isRequired,
  accountStatus: PropTypes.string.isRequired,
  trialStatus: PropTypes.string,
  sender: PropTypes.string.isRequired,
  smsBillingEnabled: PropTypes.bool.isRequired,
  smsBulkPrice: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const { appState, locationConfig, mainViewState, campaignViewState, resourcesById, locationFeatures } = state;

  const smsBulkPrice = locationConfig.get('smsPrices').find((smsPrice) => {
    return smsPrice.get('priceType') === 'Bulk';
  });

  return {
    mainViewState,
    campaignViewState,
    resourcesById,
    enableSms: locationFeatures.get('EnableSms'),
    accountStatus: appState.get('accountStatus'),
    trialStatus: appState.get('trialStatus'),
    sender: locationConfig.get('sender'),
    smsBillingEnabled: locationConfig.get('smsBillingEnabled'),
    smsBulkPrice: smsBulkPrice ? smsBulkPrice.get('price') : null
  };
};

export default injectIntl(connect(mapStateToProps)(Campaign));

