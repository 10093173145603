import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { msgAddGroupForm as msg } from '../../localization/messages/components/admin';
import { msgButtons } from '../../localization/messages/shared/buttons';

class EditGroupForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onEditGroup: PropTypes.func.isRequired
  };

  state = {
    name: this.props.name,
    progress: false
  };

  componentDidMount() {
    this.input.focus();
  }

  handleSave = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ progress: true });
    this.props.onEditGroup(this.state.name)
      .catch(() => this.setState({ progress: false }));
  };

  render() {
    const { name, progress } = this.state;
    const { onClosePopover, intl: { formatMessage: f } } = this.props;
    const disabled = !name || progress;

    return (
      <div className="Popover-content-small" onClick={ev => ev.stopPropagation()}>
        <form onSubmit={this.handleSave}>
          <div className="form-group">
            <label className="control-label">{f(msg.lblName)}</label>
            <input
              type="text"
              className="form-control"
              placeholder={f(msg.placeholderName)}
              ref={(ref) => { this.input = ref; }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
          </div>

          <div className="text-right">
            <button type="button" className="btn btn-label-o" onClick={onClosePopover}>{f(msgButtons.btnCancel)}</button> &nbsp;
            <button type="submit" className="btn btn-label-success" onClick={this.handleSave} disabled={disabled}>{f(msgButtons.btnSave)}</button>
          </div>
        </form>
      </div>
    );
  }
}

export default injectIntl(EditGroupForm);
