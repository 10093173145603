
export const INTERVAL = 'INTERVAL';
export const BEFORE_AFTER = 'BEFORE_AFTER';
export const FIRST_AVAILABLE = 'FIRST_AVAILABLE';

function getValue(value) {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }
  return value;
}

export function extractSlotFinderParams(data, params) {
  data.interval = 90;
  data.breakPoints = '00:00';
  data.excludeLastInDay = false;
  data.useIntervalFallback = true;
  data.intervalFallbackThreshold = 6;

  if (params) {
    for (const param of params.split(';')) {
      const items = param.split('=');
      data[items[0]] = getValue(items[1]);
    }
  }
  return data;
}

export function getSlotFinderParams(data, slotFinderStrategy) {
  let params = '';
  switch (slotFinderStrategy) {
    case INTERVAL:
      params = `interval=${data.interval}`;
      break;
    case BEFORE_AFTER:
      params = `excludeLastInDay=${data.excludeLastInDay};useIntervalFallback=${data.useIntervalFallback
             };interval=${data.interval};intervalFallbackThreshold=${data.intervalFallbackThreshold}`;
      break;
    case FIRST_AVAILABLE:
      params = `findGaps=true;breakPoints=${data.breakPoints}`;
      break;
  }
  delete data.interval;
  delete data.breakPoints;
  delete data.excludeLastInDay;
  delete data.useIntervalFallback;
  delete data.intervalFallbackThreshold;
  return params;
}
