import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';

import LocationSelection from './components/location-selection';
import SwitchLocation from './components/location-selection/SwitchLocation';
import MainViewContainer from './containers/main-view';
import UserConfig from './containers/user-config';

const App = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/" exact component={LocationSelection} />
        <Route path="/:org/:loc/" exact component={SwitchLocation} />
        <Route path="/:org/:loc/user-config" component={UserConfig} />
        <Route path="/:org/:loc/:section" component={MainViewContainer} />
      </Switch>
    </Fragment>
  );
};

export default hot(App);
