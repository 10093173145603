import axios from 'axios';
import moment from 'moment';
import s from 'underscore.string';
import { resourceIdFromColIdx } from '../components/grid/grid-state-helper';
import {
  axiosDefault, axiosErrorHandler, checkStatus, fetchDelete,
  fetchErrorHandler, fetchPost, fetchPut, prefixUrl
} from '../utils/ajax-util';
import { formatMessage } from '../intlContextInterseptor';
import { loading, loadingDone, success } from './network-actions';
import { toggleGridScrollability } from './view-actions';

import { msgBookingActions as msg } from '../localization/messages/state/state';

export const REQUEST_BOOKINGS = 'REQUEST_BOOKINGS';
export const MOVE_BOOKING = 'MOVE_BOOKING';
export const REVERT_BOOKING = 'REVERT_BOOKING';
export const CHANGE_BOOKING = 'CHANGE_BOOKING';
export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const ADD_BOOKING = 'ADD_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const REFUND_BOOKING = 'REFUND_BOOKING';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_FLAG = 'CHANGE_BOOKING_FLAG';
export const CHANGE_BOOKING_TYPE = 'CHANGE_BOOKING_TYPE';
export const CHANGE_BOOKING_PAYMENT = 'CHANGE_BOOKING_PAYMENT';
export const SET_UNDOABLE_BOOKING = 'SET_UNDOABLE_BOOKING';
export const SEARCH_BOOKINGS = 'SEARCH_BOOKINGS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SHOW_SEARCH = 'SHOW_SEARCH';

export function deleteBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchDelete())
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(loadingDone()))
            .then(req => dispatch(bookingDeleted(id, 'local')))
            .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(id)))));
  };
}

export function bookingDeleted(id, source) {
  return {
    type: DELETE_BOOKING,
    id,
    source
  };
}

export function changeBookingFlag(change) {
  const { bookingId, flags } = change;

  let flag = Object.keys(flags)[0];
  const flagOn = flags[flag];

  flag = s.capitalize(flag);

  const url = prefixUrl(`/bookings/${bookingId}/flags/${flag}`);
  const method = flagOn ? fetchPut() : fetchDelete();

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, method)
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(bookingFlagChanged(change, 'local')))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bookingId)))));
  };
}

export function bookingFlagChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_FLAG,
    change,
    source
  };
}

export function cancelBooking(data) {
  const bkId = data.bookingId;
  const options = data.options;

  const changes = {
    cancelledTime: moment(),
    cancelledChannel: 'Cal'
  };

  const url = prefixUrl(`/bookings/${bkId}/cancelled`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPut(options))
            .then(res => dispatch(checkStatus(res)))
            .then(() => {
              if (options.deleteBooking) {
                dispatch(bookingDeleted(bkId));
              } else {
                dispatch(bookingCancelled(bkId, changes));
              }
            })
            .then(res => dispatch(success(formatMessage(msg.booked))))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bkId)))));
  };
}

export function bookingCancelled(id, changes, source = 'local') {
  return {
    type: CANCEL_BOOKING,
    id,
    changes,
    source
  };
}

export function bookingRefunded(id, refund, source = 'local') {
  return {
    type: REFUND_BOOKING,
    id,
    refund,
    source
  };
}

export function sendBookingConfirmation(data) {
  const bkId = data.bookingId;
  const options = data.options;

  const url = prefixUrl(`/bookings/${bkId}/send-confirmation`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost(options))
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(success(formatMessage(msg.confirmationSent))))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function sendBookingReceipt({ paymentRef, toName, toEmail }) {
  const url = prefixUrl(`/receipts/${paymentRef}/email-copy`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost({ toName, toEmail }))
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(success(formatMessage(msg.receiptSent, { email: toEmail }))))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function markBookingAsPaid(data) {
  const { bookingId, paymentId, paymentStatus } = data;
  const url = prefixUrl(`/bookings/${bookingId}/payment/${paymentId}/${paymentStatus}`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPut())
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(bookingPaymentChanged(data, 'local')))
            .then(res => dispatch(success(formatMessage(msg.markedAsPaid))))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function changeBookingStatus(change) {
  const bkId = change.bookingId;

  const url = prefixUrl(`/bookings/${bkId}/status/${change.status}`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPut())
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(bookingStatusChanged(change, 'local')))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bkId)))));
  };
}

export function bookingStatusChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_STATUS,
    change,
    source
  };
}

export function bookingTypeChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_TYPE,
    change,
    source
  };
}


export function bookingPaymentChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_PAYMENT,
    change,
    source
  };
}

export function bookingChanged(id, booking, source = 'local') {
  return {
    type: CHANGE_BOOKING,
    id,
    booking,
    source
  };
}

export function bookingConfirmed(id, booking, source = 'local') {
  return {
    type: CONFIRM_BOOKING,
    id,
    booking,
    source
  };
}

export function removeTempBooking(id) {
  return bookingDeleted(id, 'local', true);
}

export function addTempBooking(booking, source = 'local') {
  return {
    type: ADD_BOOKING,
    booking,
    source,
    hideBookingForm: false
  };
}

export function bookingAdded(booking, source = 'local') {
  return {
    type: ADD_BOOKING,
    booking,
    source
  };
}

export function bookingOpened(event) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(event.chipId);

    dispatch(toggleGridScrollability(false));

    Chaplin.mediator.publish('booking:open', {
      parent: $(event.chipEl),
      booking
    });
  };
}

export function setUndoableBooking(booking) {
  return {
    type: SET_UNDOABLE_BOOKING,
    booking
  };
}

export function undoMove(routeParams) {
  return (dispatch, getState) => {
    const undoState = getState().gridViewState.get('undoableBooking');
    if (undoState != null) {
      dispatch(setUndoableBooking(null));
      dispatch(moveBooking(undoState.toJS(), routeParams, true));
    }
  };
}

export function moveBooking(move, routeParams, isUndo = false) {
  return (dispatch, getState) => {
    const { orderedGroups, bookingsById, locationConfig } = getState();
    const isUndoable = !locationConfig.get('confirmMoveEnabled');

    const { entityType, entityId } = routeParams;
    const undoState = isUndo ? null : bookingsById.get(move.id);
    const resId = isUndo ? move.resourceId : resourceIdFromColIdx(orderedGroups, entityType, entityId, move.colIdx);
    const moveEvent = Object.assign({}, move, {
      resourceId: resId
    });

    const url = prefixUrl(`/bookings/${moveEvent.id}/move`);

    const body = {
      resourceId: moveEvent.resourceId,
      endTimeEpoch: moveEvent.endTime.valueOf(),
      startTimeEpoch: moveEvent.startTime.valueOf(),
      notificationOptions: isUndoable ? {} : {
        sendSmsConfirmation: isUndo ? false : moveEvent.sendSmsConfirmation,
        sendEmailConfirmation: isUndo ? false : moveEvent.sendEmailConfirmation
      }
    };

    return fetch(url, fetchPost(body))
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(bookingMoved(moveEvent, isUndo)))
            .then(req => dispatch(setUndoableBooking(isUndoable ? undoState : null)))
            .then(req => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(moveEvent.id)))));
  };
}

export function revertBooking(bookingId) {
  return {
    type: REVERT_BOOKING,
    bookingId
  };
}

export function bookingMoved(moveEvent, isUndo) {
  return {
    type: MOVE_BOOKING,
    booking: moveEvent,
    isUndo
  };
}

export function showSearch() {
  return {
    type: SHOW_SEARCH
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH
  };
}

export function searchBookings(query) {
  return (dispatch) => {
    if (query && query.length > 0) {
      dispatch(showSearch());
    }
    if (!query || query.length <= 2) {
      dispatch({ type: SEARCH_BOOKINGS, query, bookings: [] });
      return Promise.resolve();
    }

    const url = prefixUrl(`/search/bookings?query=${encodeURIComponent(query)}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(({ data }) => {
        dispatch({ type: SEARCH_BOOKINGS, query, bookings: data.result });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
