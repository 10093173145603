import { globals } from '../../utils/globals';


export function clientBuildNumber() {
  if (globals.CLIENT_BUILD_NUMBER.includes('PLACEHOLDER')) {
    return 'LOCAL';
  }
  return globals.CLIENT_BUILD_NUMBER;
}

export function isRunningLocal() {
  return clientBuildNumber() === 'LOCAL';
}

export function sentryDSN() {
  if (globals.SENTRY_DSN.includes('PLACEHOLDER')) {
    return 'LOCAL_DEV';
  }
  return globals.SENTRY_DSN;
}

export function apiUrl(url) {
  return url === 'localhost' ? 'https://pre-app.dashl.se' : `https://${url}`;
}

export function webBookingUrl() {
  if (globals.WEB_BOOKING_URL.includes('PLACEHOLDER')) {
    return '';
  }
  return globals.WEB_BOOKING_URL;
}

export function vipBookingUrl() {
  if (globals.VIP_BOOKING_URL.includes('PLACEHOLDER')) {
    return '';
  }
  return globals.VIP_BOOKING_URL;
}

export function widgetBaseUrl() {
  if (globals.WIDGET_BASE_URL.includes('PLACEHOLDER')) {
    return '';
  }
  return globals.WIDGET_BASE_URL;
}

export function pusherKey(url) {
  if (globals.PUSHER_KEY.includes('PLACEHOLDER')) {
    return 'fca8c03d28df7666457e'; // Use "Preview" pusher
  }
  return globals.PUSHER_KEY;
}

export function pusherCluster(url) {
  if (globals.PUSHER_CLUSTER.includes('PLACEHOLDER')) {
    return 'eu'; // Use "Preview" pusher
  }
  return globals.PUSHER_CLUSTER;
}

export function stripeKey() {
  if (globals.STRIPE_KEY.includes('PLACEHOLDER')) {
    return 'pk_test_8RCjP6aLCQRK9Dzpu1lY3B2U';
  }
  return globals.STRIPE_KEY;
}

export function authCookieName() {
  if (globals.AUTH_COOKIE_NAME.includes('PLACEHOLDER')) {
    return '_dt_local';
  }
  return globals.AUTH_COOKIE_NAME;
}

export function deployEnvironment() {
  if (globals.DEPLOY_ENVIRONMENT.includes('PLACEHOLDER')) {
    return 'Local';
  }
  return globals.DEPLOY_ENVIRONMENT;
}
