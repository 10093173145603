import { defineMessages } from 'react-intl';

export const msgBookingActions = defineMessages({
  booked: {
    id: 'state.booking-actions.booked',
    defaultMessage: 'Bokningen har bokats av'
  },
  confirmationSent: {
    id: 'state.booking-actions.confirmationSent',
    defaultMessage: 'Bokningsbekräftelse har skickats'
  },
  receiptSent: {
    id: 'state.booking-actions.receiptSent',
    defaultMessage: 'Kvitto har skickats till {email}'
  },
  markedAsPaid: {
    id: 'state.booking-actions.markedAsPaid',
    defaultMessage: 'Bokningen har markerats som betald'
  }
});

export const msgNetworkActions = defineMessages({
  error: {
    id: 'state.network-actions.error',
    defaultMessage: 'Ett fel inträffade'
  },
  errorDescription: {
    id: 'state.network-actions.errorDescription',
    defaultMessage: 'Ett fel inträffade vid kontakt med servern. Försök igen eller kontakta oss om felet kvarstår.'
  }
});

export const msgResourceActions = defineMessages({
  deleteError: {
    id: 'state.resource-actions.deleteError',
    defaultMessage: 'Framtida bokningar finns'
  },
  deleteErrorDescription: {
    id: 'state.network-actions.deleteErrorDescription',
    defaultMessage: 'Resursen kan inte tas bort eftersom det finns framtida bokningar för resursen. Flytta eller ta bort befintliga bokningar för att kunna ta bort resursen.'
  },
  addError: {
    id: 'state.resource-actions.addError',
    defaultMessage: 'Uppgradering krävs'
  },
  addErrorDescription: {
    id: 'state.network-actions.addErrorDescription',
    defaultMessage: 'Kunde inte lägga till resurs. Max antal resurser har uppnåtts. För att lägga till fler behöver abonnemanget uppgraderas.'
  }
});
