var moment = require('moment');
var CollectionView = require('../../../base/collection-view');
var CustomerBookings = require('../../../../collections/customer-bookings');
var BookingItemView = require('./booking-item-view');

module.exports = CollectionView.extend({
  noWrap: true,
  autoRender: true,
  itemView: BookingItemView,
  listSelector: '.js-customer-bookings',
  fallbackSelector: '#customer-no-bookings-container',

  events: {
    'click .js-previous': 'showPrevious',
    'click .js-upcoming': 'showUpcoming'
  },

  initialize: function () {
    this.startDate = null;
    this.endDate = moment();
    this.template = require('./bookings');
    this.collection = new CustomerBookings();
    this.collection.fetch(this.model.id);
    CollectionView.prototype.initialize.call(this, arguments);
  },

  show: function (item) {
    this.collection.fetch(item.id);
  },

  filterer: function (item, index) {
    if (this.startDate === null) {
      return moment(item.get('startTime')) < this.endDate;
    } else if (this.endDate === null) {
      return moment(item.get('startTime')) > this.startDate;
    }
    return true;
  },

  showPrevious: function (e) {
    e.preventDefault();
    this.$('.js-previous')
      .toggleClass('active', true);
    this.$('.js-upcoming')
      .toggleClass('active', false);

    this.startDate = null;
    this.endDate = moment();
    this.collection.sortByStartTimeDesc();
    this.filter();
  },

  showUpcoming: function (e) {
    e.preventDefault();
    this.$('.js-previous')
      .toggleClass('active', false);
    this.$('.js-upcoming')
      .toggleClass('active', true);

    this.startDate = moment();
    this.endDate = null;
    this.collection.sortByStartTimeAsc();
    this.filter();
  }
});
