export const globals = {
  WEB_BOOKING_URL: 'WEB_BOOKING_URL_PLACEHOLDER',
  VIP_BOOKING_URL: 'VIP_BOOKING_URL_PLACEHOLDER',
  WIDGET_BASE_URL: 'WIDGET_BASE_URL_PLACEHOLDER',
  PUSHER_KEY: 'PUSHER_KEY_PLACEHOLDER',
  PUSHER_CLUSTER: 'PUSHER_CLUSTER_PLACEHOLDER',
  STRIPE_KEY: 'STRIPE_KEY_PLACEHOLDER',
  CLIENT_BUILD_NUMBER: 'BUILD_NUMBER_PLACEHOLDER',
  SENTRY_DSN: 'SENTRY_DSN_PLACEHOLDER',
  AUTH_COOKIE_NAME: 'AUTH_COOKIE_NAME_PLACEHOLDER',
  DEPLOY_ENVIRONMENT: 'DEPLOY_ENVIRONMENT_PLACEHOLDER'
};
