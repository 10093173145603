import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getGroupUrl, getModeUrl, getResourceUrl } from '../../utils/navigate';
import { hasPermission, VIEW_ALL_BOOKINGS } from '../../utils/permissions';
import { getPermissions, getFeatures } from '../../utils/selectors';

import { msgViewModeSwitcher as msg } from '../../localization/messages/components/calendar';

class ViewModeSwitcher extends React.Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    orderedGroups: PropTypes.object.isRequired
  };

  render() {
    const { routeParams, orderedGroups, permissions, enableGroupView, intl: { formatMessage: f } } = this.props;
    const { viewMode, entityType } = routeParams;
    const btnClasses = 'view-mode-tab';
    const dayClasses = viewMode === 'day' && entityType === 'resource' ? `${btnClasses} active` : btnClasses;
    const weekClasses = viewMode === 'week' ? `${btnClasses} active` : btnClasses;
    const groupClasses = entityType === 'group' ? `${btnClasses} active` : btnClasses;
    const showGroupButton = enableGroupView && hasPermission(permissions, VIEW_ALL_BOOKINGS);

    return (
      <ul className="nav nav-tabs" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <li className={dayClasses}>
          <Link to={resourceUrl(routeParams, orderedGroups, 'day')}>{f(msg.day)}</Link>
        </li>
        <li className={weekClasses}>
          <Link to={resourceUrl(routeParams, orderedGroups, 'week')}>{f(msg.week)}</Link>
        </li>
        {showGroupButton && (
          <li className={groupClasses}>
            <Link to={groupUrl(routeParams, orderedGroups)}>{f(msg.group)}</Link>
          </li>
        )}
      </ul>
    );
  }
}

function resourceUrl(matchParams, orderedGroups, viewMode) {
  const { entityType, entityId } = matchParams;

  if (entityType === 'group') {
    let group = orderedGroups.find(g => g.get('id') === parseInt(entityId));
    if (group != null) {
      // If group has no reosources, find first group with reosources
      //
      if (group.get('resourceIds').size === 0) {
        group = orderedGroups.find(g => g.get('resourceIds').size > 0);
      }

      return getResourceUrl(group.get('resourceIds').first(), viewMode, matchParams);
    }
    return '';
  }
  return getModeUrl(viewMode, matchParams);
}

function groupUrl(matchParams, orderedGroups) {
  const { entityType, entityId } = matchParams;
  if (orderedGroups.size === 0) return '';

  if (entityType === 'group') {
    return getModeUrl('day', matchParams);
  }
  const group = orderedGroups.find(g => g.get('resourceIds').includes(parseInt(entityId)));
  if (group == null) return '';

  return getGroupUrl(group.get('id'), matchParams);
}

const mapStateToProps = (state, ownProps) => {
  const { orderedGroups } = state;
  const features = getFeatures(state);

  return {
    orderedGroups,
    permissions: getPermissions(state, ownProps),
    enableGroupView: features.EnableGroupView
  };
};

export default injectIntl(connect(
  mapStateToProps
)(ViewModeSwitcher));
