import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { webBookingUrl, widgetBaseUrl } from '../../../utils/config';
import { msgWebBookingInfo as msg } from '../../../localization/messages/components/account-config';

const WebBookingInfo = ({ org, loc, stableId, features }) => {
  const bookingUrl = `${webBookingUrl()}${org}/${loc}/`;
  const widgetUrl = `${widgetBaseUrl()}dashl.js`;
  const { formatMessage: f } = useIntl();

  return (
    <div>

      {features.ShowBookingSite ? (
        <fieldset>
          <legend>{f(msg.legendShowBookingSite, { a: chunks => <a href={bookingUrl} target="_blank">{chunks}</a> })}</legend>
          <p className="text-muted">{f(msg.textShowBookingSite)}</p>
          <div className="form-group">
            <div className="well well-sm" style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>{bookingUrl}</div>
          </div>
        </fieldset>
                  ) : null}
      {features.ShowBookingWidget ? (
        <fieldset>
          <legend>{f(msg.legendShowBookingWidget)}</legend>
          <p className="text-muted">{f(msg.textShowBookingWidget)}</p>
          <div className="well well-sm" style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
            {'<script>'}<br />
            {'(function(c,l,i,e,n,t,o){if(c.cbk){return;}c.cbk=function(){'}<br />
            {'c.cbk.p.push(arguments);};c.cbk.p=[];e=l.createElement("script");e.async=1;'}<br />
            {'e.src=i;l.head.appendChild(e);n=l.createElement("div");n.id="dashl-booking";'}<br />
            {'t=l.getElementsByTagName("script");o=t[t.length-1];o.parentNode.insertBefore(n,o);'}<br />
            {`})(window,document,"${widgetUrl}");`}<br />
            {`cbk("id", "${stableId}");`}<br />
            {'</script>'}
          </div>
        </fieldset>
                  ) : null}

      {features.ShowBookingIntegration ? (
        <fieldset>
          <legend>{f(msg.legendShowBookingIntegration)}</legend>
          <p className="text-muted">{f(msg.textShowBookingIntegration)}</p>
          <div className="well well-sm" style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>{stableId}</div>
        </fieldset>
            ) : null}

    </div>
  );
};

WebBookingInfo.propTypes = {
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  stableId: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired
};

export default WebBookingInfo;
