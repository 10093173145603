import axios from 'axios/index';
import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  checkStatus,
  prefixUrl,
  logError,
  fetchErrorHandler,
  axiosErrorHandler, axiosDefault, checkStatusAxios
} from '../utils/ajax-util';
import { formatMessage } from '../intlContextInterseptor';
import { networkFailure, loading } from './network-actions';

import { msgResourceActions as msg } from '../localization/messages/state/state';

export const RECEIVE_GROUPS_AND_RESOURCES = 'RECEIVE_GROUPS_AND_RESOURCES';

export const SORT_GROUPS = 'SORT_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const RENAME_GROUP = 'RENAME_GROUP';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

export const GROUP_MOVED = 'GROUP_MOVED';
export const RESOURCE_MOVED = 'RESOURCE_MOVED';


function receiveGroupsAndResources(json) {
  return {
    type: RECEIVE_GROUPS_AND_RESOURCES,
    ...json
  };
}

export function fetchGroupsAndResources() {
  return (dispatch, getState) => {
    const url = prefixUrl('/resources/with-groups/');

    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(json => dispatch(receiveGroupsAndResources(json)))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupRenamed(group, source = 'local') {
  return {
    type: RENAME_GROUP,
    ...group,
    source
  };
}

export function renameGroup(group) {
  const url = prefixUrl(`/resourcegroups/${group.id}`);
  const data = { name: group.name };

  return (dispatch) => {
    return fetch(url, fetchPut(data))
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(groupRenamed(group, 'local')))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupDeleted(groupId, source = 'local') {
  return {
    type: DELETE_GROUP,
    groupId,
    source
  };
}

export function deleteGroup(groupId) {
  const url = prefixUrl(`/resourcegroups/${groupId}`);

  return (dispatch) => {
    return fetch(url, fetchDelete())
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(groupDeleted(groupId, 'local')))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupAdded(group, source = 'local') {
  return {
    type: ADD_GROUP,
    group,
    source
  };
}

export function addGroup(group) {
  const { name } = group;

  const url = prefixUrl('/resourcegroups/');
  const data = { name };

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost(data))
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(json => dispatch(groupAdded(json, 'local')))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupsSorted(groups, source) {
  return {
    type: SORT_GROUPS,
    source,
    groups
  };
}

export function saveGroupOrder(sortedGroups) {
  const url = prefixUrl('/resources/sort');

  return (dispatch) => {
    return fetch(url, fetchPost(sortedGroups))
            .then(res => dispatch(checkStatus(res)))
            .then(req => dispatch(groupsSorted(sortedGroups, 'local')))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}


function resourceDeleted(id, source = 'local') {
  return {
    type: DELETE_RESOURCE,
    id,
    source
  };
}

export function deleteResource(resourceId) {
  const url = prefixUrl(`/resources/${resourceId}/deleted`);
  const data = {
    cancelFutureBookings: false
  };

  return (dispatch, getState) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(resourceDeleted(resourceId, 'local')))
      .catch((error) => {
        logError(error);
        const title = error.response.status === 400 ? formatMessage(msg.deleteError) : null;
        const message = error.response.status === 400
            ? formatMessage(msg.deleteErrorDescription)
            : null;
        dispatch(networkFailure(message, title));
      });
  };
}


export function resourceUpdated(resource, source = 'local') {
  return {
    type: UPDATE_RESOURCE,
    resource,
    source
  };
}

function resourceAdded(resource, groupId, source = 'local') {
  return {
    type: ADD_RESOURCE,
    groupId,
    resource
  };
}

export function addResource(_resource, initialSetup = false) {
  const { groupId, name, webName } = _resource;

  const resource = {
    groupId: parseInt(groupId),
    name,
    webName
  };

  const url = initialSetup ? prefixUrl(`/resources/group/${groupId}/initialsetup`) : prefixUrl(`/resources/group/${groupId}`);

  return (dispatch) => {
    return fetch(url, fetchPost(resource))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then((resource) => {
        dispatch(resourceAdded(resource, parseInt(groupId)));
        return resource;
      })
      .catch((error) => {
        logError(error);
        const title = error.response.status === 400 ? formatMessage(msg.addError) : null;
        const message = error.response.status === 400
          ? formatMessage(msg.addErrorDescription)
          : null;
        dispatch(networkFailure(message, title));
      });
  };
}

export function moveGroup(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resourcegroups/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveResource(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resources/move');
    const config = axiosDefault();

    // Apply the change locally first - as required by the dnd component
    // (will revert the change if a network error occurs)
    //
    dispatch({ type: RESOURCE_MOVED, moveAction });

    const { srcGrpId, destGrpId: dstGrpId, srcPos: srcIdx, destPos: dstIdx, itemId } = moveAction;
    const body = {
      resourceId: itemId, srcGrpId, dstGrpId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { srcGrpId, destGrpId, srcPos, destPos, itemId } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          resourceId: itemId
        };

        dispatch({ type: RESOURCE_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}
