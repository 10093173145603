var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n  <div class=\"js-schedule-custom-section\">\n    <h4>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"resourceUsesCommonShedule",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n    <p class=\"text-muted\">\n      "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"changeScheduleByAdministering",{"name":"i18n","hash":{},"data":data}))
    + "<br />\n      "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"activateIndividualSchedule",{"name":"i18n","hash":{},"data":data}))
    + "\n    </p><br />\n    <button class=\"btn btn-label-success js-schedule-custom\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"useYourOwnSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n    <button class=\"btn btn-label-o js-account-config\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"manageYourSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n  </div>\n  <div class=\"js-schedule-location-section\">\n    <h4>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"individualScheduleEnabled",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n    <p class=\"text-muted\">\n      "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"activateCommonSchedule",{"name":"i18n","hash":{},"data":data}))
    + "\n    </p><br />\n    <button class=\"btn btn-label-success js-schedule-location\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"useTheAccountSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n  </div>\n  <hr />\n  <div id=\"resource-schedule-view\"></div>\n  <div id=\"resource-schedule-exceptions-view\"></div>\n</div>\n";
},"useData":true});