import { defineMessages } from 'react-intl';

export const msgCampaign = defineMessages({
  featureUnavailable: {
    id: 'Campaign.featureUnavailable',
    defaultMessage: 'SMS-utskick är inte tillgängligt för ditt abonnemang'
  }
});

export const msgReports = defineMessages({
  bookingReport: {
    id: 'Reports.bookingReport',
    defaultMessage: 'Bokningsrapport'
  },
  smsReport: {
    id: 'Reports.smsReport',
    defaultMessage: 'SMS-rapport'
  },
  reportsNotAvailable: {
    id: 'Reports.reportsNotAvailable',
    defaultMessage: 'Rapporter och statistik är inte tillgängligt för ditt abonnemang'
  },
  thisMonth: {
    id: 'Reports.thisMonth',
    defaultMessage: 'Denna månaden'
  },
  thisWeek: {
    id: 'Reports.thisWeek',
    defaultMessage: 'Denna veckan'
  },
  today: {
    id: 'Reports.today',
    defaultMessage: 'Idag'
  }
});
