import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { translateRouteParams } from '../utils/time-util';
import { chaplinPublish } from '../chaplin-bridges/chaplin-to-redux-support';
import { fetchGroupsAndResources } from '../state/resource-actions';
import { getPermissions } from '../utils/selectors';
import { EXPORT_CUSTOMERS, hasPermission } from '../utils/permissions';
import CustomersController from '../../controllers/customers-controller';
import CustomerExportDialog from '../components/customer/customer-export-dialog';
import { msgButtons as msg } from '../localization/messages/shared/buttons';

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showExport: false
    };
  }

  componentDidMount() {
    this.customersController = new CustomersController(translateRouteParams(this.props.match.params));
    this.customersController.show();

    if (this.props.resourcesById.isEmpty()) {
      this.props.fetchGroupsAndResources();
    }
  }

  componentWillUnmount() {
    this.customersController.dispose();
  }

  addCustomer = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    chaplinPublish('customers:addNew');
  };

  exportCustomers = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showExport: true });
  };

  hideExport = () => {
    this.setState({ showExport: false });
  };

  render() {
    const { showExport } = this.state;
    const { permissions, resourcesById, intl: { formatMessage: f } } = this.props;

    const canExport = hasPermission(permissions, EXPORT_CUSTOMERS);

    return (
      <div className="container customer-container">
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-xs-4">
            <button className="btn btn-success" style={{ marginRight: 8 }} onClick={this.addCustomer}>{f(msg.btnAddCustomer)}</button>
            {canExport && <button className="btn btn-default" onClick={this.exportCustomers}>{f(msg.btnExportCustomers)}</button>}
          </div>
        </div>
        <div id="chaplin-view-container" />
        {showExport && <CustomerExportDialog resourcesById={resourcesById} onClose={this.hideExport} />}
      </div>
    );
  }
}

const mapStateToProps = (state, { match }) => {
  const { resourcesById } = state;

  return {
    resourcesById,
    permissions: getPermissions(state, { routeParams: match.params })
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupsAndResources: () => {
      dispatch(fetchGroupsAndResources());
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Customers));
