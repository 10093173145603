import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enableDashlFeatures } from '../../../utils/selectors';
import { getResourcePreferences, saveResourcePreferences } from '../../../state/preferences-actions';
import { getLongestName, templatePreviewValues } from '../../../utils/templates';
import { deleteResource } from '../../../state/resource-actions';
import { getSlotFinderParams } from '../../../utils/web-booking';
import ResourceSettingsForm from './resource-settings-form';

class ResourceSettings extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    preferences: PropTypes.object,
    locationFeatures: PropTypes.object.isRequired,
    getResourcePreferences: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    saveResourcePreferences: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.getResourcePreferences(this.props.resourceId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resourceId !== nextProps.resourceId) {
      this.props.getResourcePreferences(nextProps.resourceId);
    }
  }

  render() {
    const { showCustomValues, showDelete } = this.props;
    const initialValues = this.props.preferences ? this.props.preferences.toJS() : null;
    const features = this.props.locationFeatures.toJS();

    return initialValues && (
      <ResourceSettingsForm
        {...this.props}
        features={features}
        initialValues={initialValues}
        onSubmit={this.submitForm}
        showCustomValues={showCustomValues}
        showDelete={showDelete}
      />
    );
  }

  submitForm = (values) => {
    this.props.saveResourcePreferences(values);
  };
}


const mapStateToProps = (state, ownProps) => {
  const { preferencesViewState, locationFeatures, locationConfig, resourcesById, servicesById } = state;
  const resourceId = ownProps.routeParams.entityId;
  const resource = resourcesById.get(resourceId);
  const firstResourceId = Math.min(...resourcesById.keySeq().toJS());

  const resourceName = resource && resource.name;
  const serviceName = getLongestName(servicesById);
  const companyName = locationConfig.get('companyName');
  const phoneNumber = locationConfig.get('contactNumber');
  const previewValues = templatePreviewValues({ resourceName, serviceName, companyName, phoneNumber });

  return {
    resourceId,
    resource,
    previewValues,
    preferences: preferencesViewState.get('resourcePreferences'),
    locationFeatures,
    showCustomValues: !enableDashlFeatures(state),
    showDelete: firstResourceId !== resourceId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resourceId = ownProps.routeParams.entityId;
  return {
    getResourcePreferences: (resourceId) => {
      dispatch(getResourcePreferences(resourceId));
    },
    saveResourcePreferences: (data) => {
      data.preferences.webSlotFinderParams = getSlotFinderParams(data, data.preferences.webSlotFinderStrategy);
      return dispatch(saveResourcePreferences(resourceId, data));
    },
    deleteResource: () => {
      return dispatch(deleteResource(resourceId));
    }
  };
};

const GeneralContainer = connect(
   mapStateToProps,
   mapDispatchToProps
)(ResourceSettings);

export default GeneralContainer;
