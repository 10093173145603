var CollectionView = Chaplin.CollectionView.extend({

   optionNames: Chaplin.View.prototype.optionNames.concat(['template']),
   animationDuration: 0,

   initialize: function () {
      Chaplin.CollectionView.prototype.initialize.call(this, arguments);
   },

   getTemplateData: function () {
      return this.model.attributes;
   },

   getTemplateFunction: function () {
      return this.template;
   }
});
module.exports = CollectionView;