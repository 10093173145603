var View = require('./view');

var EditableContentView = View.extend({
   noWrap: true,
   autoRender: true,
   containerMethod: 'html',

   events: {
      'click .js-edit': 'edit',
      'click .js-cancel': 'cancel',
      'submit form': 'save'
   },

   edit: function (e) {
      e.preventDefault();
      this.trigger('edit');
   },

   cancel: function (e) {
      e.preventDefault();
      this.trigger('cancel');
   },

   save: function (e) {
      e.preventDefault();
      this.trigger('save');
   }
});

module.exports = View.extend({
   noWrap: false,
   autoRender: true,
   containerMethod: 'html',

   optionNames: View.prototype.optionNames.concat(['detailsTemplate', 'editTemplate']),

   initialize: function () {
      this.template = {};
      View.prototype.initialize.call(this, arguments);
   },

   render: function () {
      View.prototype.render.call(this, arguments);
      this.renderDetails();
   },

   renderDetails: function () {
      this.subview('content', new EditableContentView({
         model: this.model,
         container: this.el,
         template: this.detailsTemplate
      }));
      this.listenTo(this.subview('content'), 'edit', this.edit);
   },

   renderEdit: function () {
      this.subview('content', new EditableContentView({
         model: this.model,
         container: this.el,
         template: this.editTemplate
      }));
      this.listenTo(this.subview('content'), 'save', this.save);
      this.listenTo(this.subview('content'), 'cancel', this.cancel);
   },

   edit: function () {
      this.renderEdit();
   },

   cancel: function () {
      this.renderDetails();
   },

   save: function () {
      this.renderDetails();
   }
});