import moment from 'moment';
import { scheduleBlockToMomentDates } from '../../utils/time-util';

export function isTimeInSchedule(schedulesByResource, time, resourceId) {
  const schedule = schedulesByResource.get(resourceId);
  if (schedule == null) return false;

  for (const block of schedule.blocks) {
    const mb = scheduleBlockToMomentDates(block);
    const range = moment.range(mb.blStart, mb.blEnd);
    if (rangeContains(range, time)) {
      return true;
    }
  }
  return false;
}

function rangeContains(range, time) {
  // NOTE! range.contains() is broken in moment-range, so doing my own implementation here..
  // Issue at GH: https://github.com/rotaready/moment-range/issues/154

  const start = range.start;
  const end = range.end;
  return time.isBetween(start, end, null, '[)');
}

export function resourceIdFromColIdx(orderedGroups, entityType, entityId, colIdx) {
  switch (entityType) {
    case 'resource':
      return parseInt(entityId);
    case 'group':
      return orderedGroups.find((group) => {
        return parseInt(group.get('id')) === parseInt(entityId);
      }).getIn(['resourceIds', colIdx]);

    default:
      console.error(`unknown entity type: ${entityType}`);
  }
}

export function calcGridScrollHeight(pixelsPerRow, rowsPerHour) {
  return (pixelsPerRow * rowsPerHour * 24);
}

export function calculateGridPixelsPerRow(rowsPerHour, gridSize) {
  let sizeFactor = 1;

  switch (gridSize) {
    case 'xsmall':
      sizeFactor = 0.5;
      break;
    case 'small':
      sizeFactor = 1;
      break;
    case 'large':
      sizeFactor = 2;
      break;
  }

  const rowsPerHourFactor = (Math.log10(rowsPerHour + 1) ** 4) + 0.016667;
  const gridPixelsPerRow = Math.round((rowsPerHour / rowsPerHourFactor + 2) * sizeFactor / 5) * 5;

  return gridPixelsPerRow;
}

/**
 * Returns an array of moment days for the given week string, eg. 2016-23, 2016-24
 *
 * @param viewDate
 * @returns {Array}
 */
function weekDates(viewDate) {
  const days = [];
  const startOfWeek = moment(viewDate, 'GGGG-WW').startOf('isoWeek'); // will be 00:00

  for (let i = 0; i < 7; i++) {
    days.push(moment(startOfWeek)); // Defensive copy as the add() call is mutating the instance
    startOfWeek.add(1, 'd');
  }
  return days;
}

export function resourceColumns(viewDate, resId) {
  const momentDate = moment(viewDate, 'YYYY-MM-DD');

  return [{
    id: `${resId}:${momentDate.format('YYYY-MM-DD')}`,
    resId,
    date: momentDate.format('YYYY-MM-DD'), // Format date as string as it's used to compare the column.date to the schedule.day which is a string
    momentDate
  }];
}

const defaultArray = [];

export function groupColumns(viewDate, entityId, orderedGroups) {
  const groupId = entityId;
  const group = orderedGroups.find(g => g.get('id') === groupId);
  if (group === undefined) {
    return defaultArray;
  }
  const resourceIds = group.get('resourceIds');
  const momentDate = moment(viewDate, 'YYYY-MM-DD');


   // const date = moment(viewDateStartEnd.start).format('YYYY-MM-DD');
  return resourceIds.map((resId) => {
    return {
      id: `${resId}:${momentDate.format('YYYY-MM-DD')}`,
      resId,
      date: momentDate.format('YYYY-MM-DD'), // Format date as string as it's used to compare the column.date to the schedule.day which is a string
      momentDate
    };
  }).toJS();
}

export function dayColumns(viewDate, entityType, entityId, orderedGroups) {
  switch (entityType) {
    case 'group':
      return groupColumns(viewDate, entityId, orderedGroups);
    case 'resource':
      return resourceColumns(viewDate, entityId);
    default:
      console.error(`unknown entityType: ${entityType}`);
      return defaultArray;
  }
}

export function weekColumns(viewDate, resId) {
  const dates = weekDates(viewDate);

  return dates.map((date) => {
    return {
      id: `${resId}:${date.format('YYYY-MM-DD')}`,
      resId,
      date: date.format('YYYY-MM-DD'), // Format date as string as it's used to compare the column.date to the schedule.day which is a string
      momentDate: date
    };
  });
}
