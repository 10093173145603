import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourceConfigUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';
import { getFeatures } from '../../utils/selectors';
import { msgNav as msg } from '../../localization/messages/components/account-config';

const NavItem = (props) => {
  const { id, name, notification, org, loc, subsection, overrideUrl } = props;
  const url = overrideUrl || `/${org}/${loc}/account-config/${id}`;
  const classes = subsection === id ? 'active' : '';

  return (
    <li className={classes}>
      <Link to={url}>
        {notification ? <span className="notification">{notification}</span> : null}
        {name}
      </Link>
    </li>
  );
};

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

class Nav extends Component {

  getFirstResourceConfigUrl() {
    const firstId = this.props.resourcesById.keySeq().first();
    return getResourceConfigUrl(this.props.match.params, firstId);
  }

  sections() {
    const { features, intl: { formatMessage: f } } = this.props;

    const sections = [];

    if (features.ShowGeneralSettings) {
      sections.push({ id: 'general', name: f(msg.general), notification: null });
    }
    if (features.ShowBookingSettings) {
      sections.push({ id: 'web-booking', name: f(msg.webBooking), notification: null });
    }
    if (features.ShowSMSSettings) {
      sections.push({ id: 'sms', name: f(msg.sms), notification: null });
    }
    if (features.ShowEmailSettings) {
      sections.push({ id: 'email', name: f(msg.email), notification: null });
    }
    if (features.ShowScheduleSettings) {
      sections.push({ id: 'schedule', name: f(msg.shedule), notification: null });
    }
    if (features.ShowNotificationSettings) {
      sections.push({ id: 'notifications', name: f(msg.notifications), notification: null });
    }
    if (features.ShowUserSettings) {
      sections.push({ id: 'users', name: f(msg.users), notification: null });
    }
    if (features.ShowAddons) {
      sections.push({ id: 'features', name: f(msg.features), notification: null });
    }
    if (features.EnableStripePayments) {
      sections.push({ id: 'payment', name: f(msg.payment), notification: null });
    }
    if (features.ShowGeneralSettings) {
      sections.push({ id: 'storage', name: f(msg.storage), notification: null });
    }
    if (features.ShowGeneralSettings) {
      sections.push({ id: 'contacts', name: f(msg.contacts), notification: null });
    }
    return sections;
  }

  render() {
    const routeParams = translateRouteParams(this.props.match.params);

    return (
      <nav className="navbar navbar-static-top navbar-tabs">
        <div className="container">
          <ul className="nav nav-tabs">
            {this.sections().map((section, i) =>
              (<NavItem
                key={section.id}
                {...section}
                {...routeParams}
              />)
                     )}
          </ul>
        </div>
      </nav>
    );
  }
}

Nav.propTypes = {
  features: PropTypes.object.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  tabletMode: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const { mainViewState, resourcesById } = state;

  return {
    resourcesById,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    features: getFeatures(state)
  };
};

export default injectIntl(connect(mapStateToProps)(Nav));

