import moment from 'moment';
import { createSelector } from 'reselect';
import { calcGridScrollHeight, calculateGridPixelsPerRow, dayColumns, weekColumns } from './grid-state-helper';
import { calcWeekViewTimeOffsetForDay, scheduleBlockToMomentDates, viewDateToStartEnd } from '../../utils/time-util';
import { getRouteParams } from '../../utils/selectors';


const getGroups = (state, props) =>
      state.orderedGroups;

export const getColumnsSelector = createSelector(
      getRouteParams,
      getGroups,
      (routeParams, orderedGroups) => {
        // console.warn('RECALC getColumnsSelector');
        const { viewMode, viewDate, entityType, entityId } = routeParams;

        const columns = viewMode === 'week' ? weekColumns(viewDate, entityId) : dayColumns(viewDate, entityType, entityId, orderedGroups);
        return columns;
      }
);

const getScrollbars = state => state.gridViewState.get('scrollBars');
const getScrollbarWidth = state => state.gridViewState.get('scrollBarWidth');
const getRowsPerHour = state => state.gridViewState.get('rowsPerHour');
const getGridClientWidth = state => state.gridViewState.get('gridClientWidth');
const getGridClientHeight = state => state.gridViewState.get('gridClientHeight');
const getGridSize = state => state.gridViewState.get('gridSize');

export const getGridProps = createSelector(
      getColumnsSelector,
      getRouteParams,
      getScrollbars,
      getScrollbarWidth,
      getRowsPerHour,
      getGridClientWidth,
      getGridClientHeight,
      getGridSize,
      (columns, routeParams, scrollBars, scrollBarWidth, rowsPerHour, gridClientWidth, gridClientHeight, gridSize) => {
        // console.warn('RECALC getGridProps');
        const columnCount = columns.length;
        const chipIndentPixels = 3;
        const pixelsPerRow = calculateGridPixelsPerRow(rowsPerHour, gridSize);
        const gridScrollHeight = calcGridScrollHeight(pixelsPerRow, rowsPerHour);
        const viewDateStartEnd = viewDateToStartEnd(routeParams.viewMode, routeParams.viewDate);
        const columnWidth = columnCount === 0 ? gridClientWidth : gridClientWidth / columnCount;

        return {
          gridSize,
          pixelsPerRow,
          rowsPerHour,
          scrollBars,
          scrollBarWidth,
          gridClientWidth,
          gridClientHeight,
          gridScrollHeight,
          columnWidth,
          columnCount,
          chipIndentPixels,
          viewDateStartEnd,
          isWeekView: routeParams.viewMode === 'week'
        };
      }
);


const getSchedules = (state, props) =>
      state.schedulesByResource;

const getGroupById = (state, props) => {
  const entityId = props.routeParams.entityId;
  return state.orderedGroups.find(g => g.get('id') === entityId);
};

export const getSchedulesSelector = createSelector(
      getRouteParams,
      getSchedules,
      getGroupById,
      (routeParams, schedulesByResource, group) => {
        // console.warn('RECALC getSchedulesSelector');

        const { entityType, entityId } = routeParams;

        if (entityType === 'group') {
          if (group === undefined) {
            return null;
          }
          const resourceIds = group.get('resourceIds');

          return schedulesByResource.filter((schedule, resId) => {
            return resourceIds.includes(resId);
          });
        }
        return schedulesByResource.filter((schedule, resId) => {
          return resId === entityId;
        });
      }
);

const emptyArray = [];
export const getScheduleBlockSelector = createSelector(
      getColumnsSelector,
      getSchedulesSelector,
      getGridProps,
      (columns, schedules, gridProps) => {
        // console.warn('RECALC getScheduleBlockSelector');
        const { pixelsPerRow, rowsPerHour, isWeekView } = gridProps;
        if (schedules === null || columns === null) {
          return emptyArray;
        }
        const columnWidthPct = 100 / columns.length;
        const hourHeight = pixelsPerRow * rowsPerHour;
        const scheduleBlocks = [];
        let columnIndex = 0;


         // This loop iterates over columns and scheduls to figure out which schedule blocks to render for the current view
         //
        columns.forEach((column) => {
          let blockIndex = 0;
          const resourceSchedule = schedules.get(column.resId);

          if (resourceSchedule && resourceSchedule.blocks) {
            resourceSchedule.blocks.sort((a, b) => {
              const aSt = moment(`${a.day}T${a.start}`);
              const bSt = moment(`${b.day}T${b.start}`);
              return aSt.valueOf() - bSt.valueOf();
            }).forEach((block) => {
              if (block.day === column.date) {
                let pb = makePositionedBlock(block, hourHeight, columnIndex, columnWidthPct, isWeekView);
                const { resId } = column;
                const { webOpen, vipOpen, closedMax } = block;
                const { bookingMaxDaysInAdvance } = resourceSchedule;
                if (!bookingMaxDaysInAdvance) {
                  console.error('NO bookingMaxDaysInAdvance', resourceSchedule);
                }
                pb = Object.assign(pb, { resId, webOpen, vipOpen, closedMax, blockIndex, bookingMaxDaysInAdvance });
                scheduleBlocks.push(pb);
                blockIndex++;
              }
            });
          }

          columnIndex++;
        });
        return scheduleBlocks;
      }
);

function makePositionedBlock(block, hourHeight, columnIndex, columnWidthPct, isWeekView) {
  const blockMoments = scheduleBlockToMomentDates(block);

  const compTime = calcWeekViewTimeOffsetForDay(isWeekView, blockMoments.dayStart) * 1000; // TimeUtil.getCompTime(dayStart) * 1000;
  const minToPixelFactor = (hourHeight) / 60;
  const startPx = (((blockMoments.blStart - blockMoments.dayStart + compTime) / (1000 * 60)) * minToPixelFactor);
  const heightPx = ((blockMoments.blEnd - blockMoments.blStart) / (1000 * 60) * minToPixelFactor);

  return {
    leftPosPct: columnIndex * columnWidthPct,
    widthPct: columnWidthPct,
    topPx: startPx,
    heightPx,
    day: block.day
  };
}

