import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { BEFORE_AFTER, FIRST_AVAILABLE, INTERVAL } from '../../utils/web-booking';

import { msgWebBookingStrategy as msg } from '../../localization/messages/components/inputs';

class WebBookingStrategy extends Component {

  render() {
    const { slotFinderStrategy, propertyName, intl: { formatMessage: f } } = this.props;

    const isInterval = !slotFinderStrategy || slotFinderStrategy === INTERVAL;
    const isFirstAvailable = slotFinderStrategy === FIRST_AVAILABLE;
    const isBeforeAfter = slotFinderStrategy === BEFORE_AFTER;

    return (
      <div>
        <p className="form-control-static" style={{ marginBottom: 5 }}>
          <FormattedMessage {...msg.suggestTime} />
        </p>
        <div className="well well-sm" style={{ marginBottom: 0, paddingLeft: 15 }}>

          <label className="radio-inline" style={{ fontWeight: 400 }}>
            <Field name={propertyName} component="input" type="radio" value={BEFORE_AFTER} />
            <FormattedMessage {...msg.lblSmartBooking} values={{ b: (...chunks) => <strong>{chunks}</strong> }} />
          </label>

          <div className="checkbox-description" >
            <FormattedMessage {...msg.descriptionSmartBooking} />

            {isBeforeAfter &&
            <div>
              <div className="checkbox" style={{ marginTop: 10 }}>
                <label>
                  <Field name="excludeLastInDay" component="input" type="checkbox" />
                  <FormattedMessage {...msg.lblExcludeLastInDay} />
                </label>
              </div>
              <div className="checkbox" style={{ marginTop: 5 }}>
                <label>
                  <Field name="useIntervalFallback" component="input" type="checkbox" />
                  <FormattedMessage {...msg.lblUseIntervalFallback} />
                </label>
                <Field name="interval" component="select" className="form-control" style={{ marginTop: 10 }}>
                  <option value="180">{f(msg.minutesInterval, { number: 180 })}</option>
                  <option value="120">{f(msg.minutesInterval, { number: 120 })}</option>
                  <option value="90">{f(msg.minutesInterval, { number: 90 })}</option>
                  <option value="75">{f(msg.minutesInterval, { number: 75 })}</option>
                  <option value="60">{f(msg.minutesInterval, { number: 60 })}</option>
                  <option value="45">{f(msg.minutesInterval, { number: 45 })}</option>
                  <option value="40">{f(msg.minutesInterval, { number: 40 })}</option>
                  <option value="30">{f(msg.minutesInterval, { number: 30 })}</option>
                  <option value="20">{f(msg.minutesInterval, { number: 20 })}</option>
                  <option value="15">{f(msg.minutesInterval, { number: 15 })}</option>
                  <option value="10">{f(msg.minutesInterval, { number: 10 })}</option>
                </Field>
              </div>
            </div>}
          </div>


          <label className="radio-inline" style={{ fontWeight: 400 }}>
            <Field name={propertyName} component="input" type="radio" value={FIRST_AVAILABLE} />
            <FormattedMessage {...msg.lblFirstVacancies} />
          </label>
          <div className="checkbox-description">
            <FormattedMessage {...msg.descriptionFirstVacancies} />

            {isFirstAvailable ?
              <Field name="breakPoints" component="select" className="form-control" style={{ marginTop: 10 }}>
                <option value="00:00">{f(msg.optionTodayFirstFreeTime)}</option>
                <option value="12:00">{f(msg.optionFirstTimes)}</option>
              </Field> : null}
          </div>


          <label className="radio-inline" style={{ fontWeight: 400 }}>
            <Field name={propertyName} component="input" type="radio" value={INTERVAL} />
            <FormattedMessage {...msg.lblInterval} />
          </label>
          <div className="checkbox-description">
            <FormattedMessage {...msg.descriptionInterval} />

            {isInterval ?
              <Field name="interval" component="select" className="form-control" style={{ marginTop: 10 }}>
                <option value="180">{f(msg.minutesInterval, { number: 180 })}</option>
                <option value="120">{f(msg.minutesInterval, { number: 120 })}</option>
                <option value="90">{f(msg.minutesInterval, { number: 90 })}</option>
                <option value="75">{f(msg.minutesInterval, { number: 75 })}</option>
                <option value="60">{f(msg.minutesInterval, { number: 60 })}</option>
                <option value="45">{f(msg.minutesInterval, { number: 45 })}</option>
                <option value="40">{f(msg.minutesInterval, { number: 40 })}</option>
                <option value="30">{f(msg.minutesInterval, { number: 30 })}</option>
                <option value="20">{f(msg.minutesInterval, { number: 20 })}</option>
                <option value="15">{f(msg.minutesInterval, { number: 15 })}</option>
                <option value="10">{f(msg.minutesInterval, { number: 10 })}</option>
              </Field> : null}
          </div>
        </div>
      </div>
    );
  }
}

WebBookingStrategy.propTypes = {
  slotFinderStrategy: PropTypes.string,
  propertyName: PropTypes.string.isRequired
};

export default injectIntl(WebBookingStrategy);
