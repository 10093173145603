import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchLocationConfig, clearLocationState, fetchLocationOptions } from './state/account-actions';
import { tryExistingAccessToken } from '../redux-login/actions';
import AuthRequired from './components/dialogs/AuthRequired';
import Loader from './components/common/loader';
import { fetchGroupsAndResources } from './state/resource-actions';

class LocationConfigEnforcer extends Component {
  constructor(props) {
    super(props);
    const { org, loc } = props.match.params;
    sessionStorage.setItem('org', org);
    sessionStorage.setItem('loc', loc);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    // Remove static loader
    document.body.removeAttribute('style');

    // Authenticate if not
    if (!this.props.authenticated && !this.props.authenticating) {
      this.props.tryExistingAccessToken()
        .then(() => this.fetchConfig(this.props));
    } else {
      this.fetchConfig(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orgLoc !== nextProps.orgLoc) {
      if (nextProps.orgLoc) {
        const { org, loc } = nextProps.match.params;
        sessionStorage.setItem('org', org);
        sessionStorage.setItem('loc', loc);

        this.props.clearLocationState();
        this.fetchConfig(nextProps);
      }
    }
  }

  fetchConfig = (props) => {
    if (props.orgLoc) {
      this.setState({ loaded: false });
      this.props.fetchConfig()
        .then(() => this.setState({ loaded: true }));
    }
  };

  render() {
    const { component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (this.props.noAuthToken) {
            return <AuthRequired />;
          }

          return this.props.authenticated && (this.state.loaded || !this.props.orgLoc) ?
                     React.createElement(component, props) :
                     <Loader />;
        }}
      />
    );
  }
}

/*
 <h1>authenticated: {this.props.authenticated ? 'YES' : 'NO'},
 configState: {this.props.configState}, orgLoc: {this.props.orgLoc} </h1>
 */
const mapStateToProps = (state, ownProps) => {
  const { org, loc } = ownProps.match.params;

  return {
    noAuthToken: state.authState.get('noAuthToken'),
    authenticated: state.authState.get('authenticated'),
    orgLoc: org !== undefined && loc !== undefined ? `${org}/${loc}` : undefined,
    configState: state.locationConfig.get('state'),
    resourceId: state.locationConfig.get('resourceId'),
    locationRole: state.locationConfig.get('locationRole')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tryExistingAccessToken: () => {
      return dispatch(tryExistingAccessToken());
    },
    clearLocationState: () => {
      dispatch(clearLocationState());
    },
    fetchConfig: () => {
      return Promise.all([
        dispatch(fetchLocationConfig()),
        dispatch(fetchLocationOptions()),
        dispatch(fetchGroupsAndResources())
      ]);
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(LocationConfigEnforcer);
