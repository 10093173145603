import moment from 'moment';
import { viewDateFromDate } from '../../../redux-dashl/utils/time-util';
import { navigate, getCalendarUrl } from '../../../redux-dashl/utils/navigate';

const Datepicker = Chaplin.View.extend({
  viewMode: 'day',
  defaultDate: null,

  initialize(params) {
    this.config = {
      calendarWeeks: true, // Display week numbers
      startView: 0, // ??
      todayBtn: 'linked',
      todayHighlight: true,
      language: params.locale,
      weekStart: 1, // 0=sunday, 1=monday
      selectWeek: false // Ability to select whole week,
    };

    this.defaultDate = params.defaultDate;
    this.viewMode = params.viewMode;

    this.subscribeEvent('chaplin:datepicker:show', this._show);
    this.subscribeEvent('chaplin:datepicker:hide', this._hide);

    this.setView(this.viewMode);
    Chaplin.View.prototype.initialize.call(this, arguments);

      // set default date
    if (this.defaultDate && this.defaultDate.isValid()) {
      this.setDate(this.defaultDate, true);
    }
  },
  getTemplateFunction() {
    return this.template;
  },

  setView(view) {
    switch (view) {
      case 'day':
        this.viewMode = 'day';
        this.config.selectWeek = false;
        break;
      case 'week':
        this.viewMode = 'week';
        this.config.selectWeek = true;
        break;
      default:
        console.error(`Unknown view mode: ${view}`);
    }

      // Keep same date, or default to NOW
    let currentDate = this._currentDate();
    if (!currentDate.isValid()) {
      currentDate = moment().startOf('day');
    }


    this._reRender(currentDate);
  },


  changeViewDate(viewState) {
    this.viewState = viewState;

    let newDate;

    switch (viewState.viewMode) {
      case 'day':
        this.viewMode = 'day';
        this.config.selectWeek = false;
        newDate = moment(viewState.viewDate, 'YYYY-MM-DD').startOf('day');
        break;
      case 'week':
        this.viewMode = 'week';
        this.config.selectWeek = true;
        newDate = moment(viewState.viewDate, 'GGGG-WW').startOf('isoWeek');
        break;
      default:
        console.error(`Unknown view mode: ${viewMode}`);
    }
    this._reRender(newDate);
  },

  setDate(date, noEvent) {
    this._setDateInternal(date);
    if (!noEvent) {
      this._triggerDateChange();
    }
  },

  _show() {
    this.$el.show();
  },
  _hide() {
    this.$el.hide();
  },

  _reRender(date) {
    this.$el.datepicker('remove').off('changeDate');
    this.$el.datepicker(this.config).on('changeDate', _.bind(this._triggerDateChange, this));

    this._setDateInternal(date);
  },

  _setDateInternal(momentDate) {
      /*
       NOTE! Important to pass in as string, a 'native' JS date, will be converted to local time,
       and hence break if the account and browser have different timezones
       */
    this.$el.datepicker('update', momentDate.format('"YYYY-MM-DD"'));
  },

  _currentDate() {
      // NOTE!! If the time part is not stripped, the datepicker wont work....
    return moment(this.$el.datepicker('getDate')).startOf('day');
  },

  _triggerDateChange() {
    const event = {};
    if (!this._currentDate().isValid()) {
      return;
    }
    if (this.viewMode == 'week') {
      event.start = this._currentDate().startOf('isoWeek').toDate();
      event.end = this._currentDate().endOf('isoWeek').toDate();
    } else {
      event.start = this._currentDate().startOf('day').toDate();
      event.end = this._currentDate().endOf('day').toDate();
    }

    const viewDate = viewDateFromDate(this.viewMode, event.start);
    navigate(getCalendarUrl(viewDate, this.viewState));
  },

  dispose() {
    this.$el.datepicker('remove');
    Chaplin.View.prototype.dispose.call(this, arguments);
  }
});

export default Datepicker;
