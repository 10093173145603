/* eslint-disable */

export function checkLuhn(num) {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let len = num.length;
  let bit = 1;
  let sum = 0;
  let val;

  while (len) {
    val = parseInt(num.charAt(--len), 10);
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return sum && sum % 10 === 0;
}

export function getLuhnNumber(num) {
  const digits = num.replace(/[^0-9]/, '');
  return digits.length === 12 ? digits.substring(2) : digits;
}

export function checkPnoPattern(pno) {
  const pattern = /^(19|20)*\d{6}-*\d{4}$/;
  return pattern.test(pno);
}

export function checkOrgNoPattern(orgNo) {
  const pattern = /^(16)*\d{6}-*\d{4}$/;
  return pattern.test(orgNo);
}

export function getFullPno(pno) {
  const digits = pno.replace(/[^0-9]/, '');
  return digits.length === 10 ? '19' + digits : digits;
}

export function isValidPno(pno) {
  return checkPnoPattern(pno) && checkLuhn(getLuhnNumber(pno));
}

export function isValidOrgNo(orgNo) {
  return checkOrgNoPattern(orgNo) && checkLuhn(getLuhnNumber(orgNo));
}

export function formatOrgNo(orgNo) {
  const normalized = getLuhnNumber(orgNo);
  return /^[0-9]{10}$/.test(normalized)
    ? `${normalized.substr(0, 6)}-${normalized.substr(6)}`
    : normalized;
}
