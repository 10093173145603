import { fetchGet, fetchPost, fetchPut, fetchDelete, checkStatus, prefixUrl, logError, fetchErrorHandler } from '../utils/ajax-util';
import { networkFailure, loading, loadingDone } from './network-actions';
import { authenticate } from '../../redux-login/actions';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_PASSWORD_POLICY = 'SET_PASSWORD_POLICY';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const CHANGE_NOTIFICATIONS = 'CHANGE_NOTIFICATIONS';
export const SET_CLIENT_PREF = 'SET_CLIENT_PREF';


function changePasswordProgress() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: true,
      wrongPassword: false,
      changePasswordSuccess: false
    }
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: false,
      wrongPassword: false,
      changePasswordSuccess: true
    }
  };
}

function changePasswordWrongPassword() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: false,
      wrongPassword: true,
      changePasswordSuccess: false
    }
  };
}

function fetchPasswordPolicySuccess(passwordPolicy) {
  return {
    type: SET_PASSWORD_POLICY,
    passwordPolicy
  };
}

function receiveNotificationsSuccess(state) {
  return {
    type: RECEIVE_NOTIFICATIONS,
    state
  };
}

function changeNotificationsProgress(state) {
  return {
    type: CHANGE_NOTIFICATIONS,
    state: {
      isChangingNotifications: true
    }
  };
}

function changeNotificationsSuccess(state) {
  return {
    type: CHANGE_NOTIFICATIONS,
    state: {
      isChangingNotifications: false,
      bookingEmailPreference: state.bookingEmailPreference
    }
  };
}

function changeNotificationsFailure(state) {
  return {
    type: CHANGE_NOTIFICATIONS,
    state: {
      isChangingNotifications: false
    }
  };
}

export function setUserPreferences(prefs) {
  return {
    type: SET_CLIENT_PREF,
    state: prefs
  };
}

export function setUserPreference(forLocation, prefs) {
  return (dispatch, getState) => {
    const { userClientPreferences } = getState();

    const state = {};
    const _org = sessionStorage.getItem('org');
    const _loc = sessionStorage.getItem('loc');

    if (forLocation) {
      const locKey = {},
        key = `${_org}/${_loc}`;
      locKey[key] = prefs;
      state.location = locKey;
    } else {
      state.user = prefs;
    }
    const newPrefs = userClientPreferences.mergeDeep(state);

    dispatch({
      type: SET_CLIENT_PREF,
      state
    });

    const url = prefixUrl('/account/client-prefs/');
    return fetch(url, fetchPut(newPrefs.toJS()))
              .then(res => dispatch(checkStatus(res)))
              .then(req => dispatch(loadingDone()))
              .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function changePassword(request) {
  const url = prefixUrl('/account/chpasswd');
  return (dispatch, getState) => {
    const authRequest = {
      username: getState().authState.get('username'),
      password: request.new
    };
    dispatch(changePasswordProgress());
    return fetch(url, fetchPost(request))
      .then((response) => {
        if (!response.ok) {
          const error = new Error();
          error.status = response.status;
          error.response = response;
          throw error;
        }
      })
      .then(() => dispatch(authenticate(authRequest)))
      .then(() => dispatch(changePasswordSuccess()))
      .catch((error) => {
        if (error.status === 401) {
          dispatch(changePasswordWrongPassword());
        } else {
          logError(error);
          dispatch(networkFailure());
        }
      });
  };
}

export function fetchPasswordPolicy() {
  const url = prefixUrl('/account/minimum-pw-policy');
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(req => req.json())
      .then(({ policy }) => dispatch(fetchPasswordPolicySuccess(policy)))
      .then(() => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function receiveNotifications() {
  const url = prefixUrl('/account/notifications');
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(req => req.json())
            .then(json => dispatch(receiveNotificationsSuccess(json)))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function changeNotifications(preferences) {
  const url = prefixUrl('/account/notifications');
  return (dispatch) => {
    dispatch(changeNotificationsProgress());
    return fetch(url, fetchPut(preferences))
            .then(res => dispatch(checkStatus(res)))
            .then(res => dispatch(changeNotificationsSuccess(preferences)))
            .catch((error) => {
              logError(error);
              dispatch(networkFailure());
              dispatch(changeNotificationsFailure());
            });
  };
}
