var moment = require("moment");
var UrlUtil = require('../redux-dashl/utils/url-util');

module.exports = {
   configure: function(){
      //console.log("ajax-config.configure");

      $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
         //console.log("ajax-config.prefiltering", options);
         options.url = UrlUtil.prefixAjaxUrl(options.url);

         var pusherSocketId = window.pusherSocketId ? window.pusherSocketId : '';
         var accessToken = localStorage.getItem('accessToken');

         //console.log("AJAX - SETUP HEADERS - accessToken: "+accessToken);

         if ( !options.beforeSend) {
            options.beforeSend = function (xhr) {
               //console.log('XHR', options);

               // Don't set auth header if it's already set
               //
               if(!options.headers || !options.headers.Authorization){
                  xhr.setRequestHeader('X-Client-Id', pusherSocketId);
                  xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
               }

            };
         }

         var originalComplete = options.complete;
         options.complete = function (response) {
            var features = response.getResponseHeader('X-Dashl-Features');
            var trialUntil = response.getResponseHeader('X-Dashl-Trial-Until');
            var trialStatus = response.getResponseHeader('X-Dashl-Trial-Status'); // Trial, TrialExpired
            var accountStatus = response.getResponseHeader('X-Dashl-Account-Status'); // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled

            if(accountStatus) {
               var trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
               Chaplin.mediator.publish('redux:changeAccountStatus', {
                  accountStatus: accountStatus,
                  trialStatus: trialStatus,
                  trialUntil: trialUntilMoment,
                  features: features
               });
            }

            if (originalComplete) {
               originalComplete(request);
            }
         };
      });

      $(document).ajaxStart(function() {
         Chaplin.mediator.publish('loadingOverlay:show');
      });

      $(document).ajaxStop(function() {
         Chaplin.mediator.publish('loadingOverlay:hide');
      });

      $(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {
         Chaplin.mediator.publish('loadingOverlay:hide');

         if(ajaxSettings.suppressErrors) {
            return;
         }

         if(jqXHR.status === 401) {
            Chaplin.mediator.publish('login:required');
         }
         else {

            console.error("AJAX ERROR", arguments);

            Chaplin.mediator.publish('redux:networkError');
            Sentry.withScope(function(scope) {
               scope.setExtra('httpStatus', jqXHR.status);
               Sentry.captureMessage("Network call failed "+ajaxSettings.type+" "+ajaxSettings.url);
            });
         }
      });
   }
};
