import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchLocationOptions } from '../../state/account-actions';
import Loader from '../common/loader';
import Locations from './Locations';

import { msgLocationSelection as msg } from '../../localization/messages/components/location-selection';

class LocationSelection extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.props.onLoad()
      .then(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.props.locations.size === 0) {
      return <h1><FormattedMessage {...msg.noAccounts} /></h1>;
    }

    const sortedOptions = this.props.locations.valueSeq().sort((a, b) => {
      const aStr = (a.orgName + a.locName);
      const bStr = (b.orgName + b.locName);

      return aStr.localeCompare(bStr, 'sv');
    });

    return <Locations locations={sortedOptions} />;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    locations: state.locationOptions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoad: () => {
      return dispatch(fetchLocationOptions());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(LocationSelection);
