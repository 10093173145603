var moment = require('moment');

module.exports = Chaplin.Model.extend({

   // attributes start, end, day, week

   initialize: function () {
   },

   defaults: {
      start: moment(),
      end: moment()
   },

   copy: function(){
      return this.clone();
   },

   set: function(attributes, options) {
      if (attributes.start !== undefined && (typeof(attributes.start) === 'string')) {
         var startTime = attributes.start.split(':');
         attributes.start = moment({hour: startTime[0], minute: startTime[1]});
      }
      if (attributes.end !== undefined && (typeof(attributes.end) === 'string')) {
         var endTime = attributes.end.split(':');
         attributes.end = moment({hour: endTime[0], minute: endTime[1]});
      }
      return Backbone.Model.prototype.set.call(this, attributes, options);
   }
});
