var moment = require('moment');
var s = require('underscore.string');
var Handlebars = require('handlebars/runtime');
var CollectionView = require('../base/collection-view');
var DayScheduleView = require('./day-view');
var WeeksView = require('./weeks-view');

module.exports = CollectionView.extend({
	noWrap: true,
	autoRender: true,
	currentWeek: 1,
	scheduleModel: null,
	containerMethod: 'html',
	listSelector: '#schedule-item-container',

	events: {
		'click .js-weeks': 'weeksSelected'
	},

	initialize: function (options) {
		this.scheduleModel = options.scheduleModel;
		this.model = this.scheduleModel.copy();
		this.collection = this.model.get('days');
		this.editMode = options.editMode !== false;
		this.template = require('./schedule');
		CollectionView.prototype.initialize.call(this, arguments);
		Handlebars.registerHelper("DayOfWeek", _.bind(this.getDayName, this));
		Handlebars.registerHelper("TimeDiff", _.bind(this.getDiffString, this));
		Handlebars.registerHelper("GetHeight", _.bind(this.getHeight, this));

		this.subscribeEvent('schedule:select', this.scheduleSelected);
	},

	initItemView: function (model) {
		var dayScheduleView  = new DayScheduleView({model: model, collection:  model.get('blocks')});
		this.listenTo(dayScheduleView, 'changed', this.dayChanged);
		return dayScheduleView;
	},

	filterer: function(item, index) {
		return item.get('week') === this.currentWeek;
	},

	filterCallback: function(view, included) {
		view.$el.toggleClass('hide', !included);
	},

	render: function () {
		CollectionView.prototype.render.call(this, arguments);

		var weeksView = new WeeksView({ model: this.model, container: this.$('#schedule-week-container') });
		this.listenTo(weeksView, 'week:changed', this.setCurrentWeek);
		this.subview('weeks', weeksView);
		this.setActiveWeeks(this.model.get('weeks'));
		this.toggleEnableEdit(this.editMode);
	},

	weeksSelected: function(e) {
		e.preventDefault();
		var weeks = parseInt($(e.currentTarget).data('weeks'));
		this.model.set('weeks', weeks);
		this.setActiveWeeks(weeks);
	},

	setActiveWeeks: function(weeks) {
		this.$('.js-weeks').removeClass('active');
		this.$('.js-weeks[data-weeks=' + weeks + ']').addClass('active');
	},

	setCurrentWeek: function(week) {
		this.currentWeek = week;
		this.filter();
	},

	resetCurrentWeek: function(){
		this.currentWeek = 1;
	},

	scheduleSelected: function(schedule) {
		this.trigger('selected', schedule);
		this.scheduleModel = schedule;
		this.model = schedule.copy();
		this.collection = this.model.get('days');
		this.resetCurrentWeek();
		this.render();
	},

	toggleEnableEdit: function(enable){
		this.editMode = enable;
		if (enable){
			this.$('.js-edit-mode').removeClass('hide');
			this.$('.schedule-table').removeClass('disabled');
			this.$('.schedule-item').addClass('edit-mode');
		}
		else{
			this.$('.js-edit-mode').addClass('hide');
			this.$('.schedule-table').addClass('disabled');
			this.$('.schedule-item').removeClass('edit-mode');
		}
	},

	dayChanged: function(){
		this.trigger('changed');
	},

	getDayName: function (day) {
		return s.capitalize(moment(day == 7 ? 0 : day, 'd').format('dddd'));
	},

	getScheduleData: function(){
		var schedule = this.model.copy().attributes;
		var self = this;
		var blocks = [];
		_.each(schedule.days.models, function(day){
			_.each(day.attributes.blocks.models, function(block){
				var b = block.attributes;
				b.start = self.formatTime(b.start);
				b.end = self.formatTime(b.end);
				blocks.push(b);
			});
		});
		delete schedule.days;
		schedule.blocks = blocks;
		return schedule;
	},

	formatTime: function(value){
		if (value) {
			return moment(value).format('HH:mm');
		}
	},

	resetModel: function() {
		this.scheduleSelected(this.scheduleModel);
	},

	commitModel: function() {
		this.scheduleModel.set(this.model.attributes);
	},

	getMinutesDiff: function (item) {
		var start = moment(item.start, 'HH:mm');
		var end = moment(item.end, 'HH:mm');
		return end.diff(start, 'minutes');
	},

	getDiffString: function (item) {
		var diff = this.getMinutesDiff(item);
		var minutes = diff % 60;
		var hours = (diff - minutes) / 60;
		var result = '';

		if (hours > 0) {
			result += hours + " h ";
		}
		if (minutes > 0) {
			result += minutes + " min";
		}
		return result;
	},

	getHeight: function (item) {
		return (this.getMinutesDiff(item) * 0.5) + 'px';
	}
});
