import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import CurrencyUtil from '../../../../utils/currency-util';

import { msgResourceServiceItem as msg } from '../../../localization/messages/components/resources';
import { msgLabels } from '../../../localization/messages/shared/labels';

class ResourceServiceItem extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    serviceDuration: PropTypes.number.isRequired,
    afterTime: PropTypes.number.isRequired,
    webEnabled: PropTypes.bool.isRequired,
    webShowDuration: PropTypes.bool.isRequired,
    webShowPrice: PropTypes.bool.isRequired,
    price: PropTypes.number,
    priceFrom: PropTypes.bool.isRequired
  };

  selectService = (ev) => {
    this.props.selectService(this.props.id, ev.target.checked);
  };

  setCustomValues = (ev) => {
    ev.preventDefault();
    this.props.setCustomValues(this.props.id);
  };

  render() {
    const { active, name, customValues, serviceDuration: duration, afterTime, price, currency, priceFrom, webEnabled, showCustomValues, intl: { formatMessage: f } } = this.props;

    const durationStr = f(msgLabels.durationSrt, { isAfterTime: afterTime > 0, duration, afterTime });
    const priceStr = f(msgLabels.priceSrt, { priceFrom, price: CurrencyUtil.accountCurrency(price, 0, currency) });

    return (
      <tr>
        <td>
          <input type="checkbox" checked={active} onChange={this.selectService} />
        </td>
        <td>
          {name}
          <br />
          <span className="text-muted">
            {durationStr}, {priceStr}
          </span>
        </td>
        <td className="text-muted">
          {webEnabled ? <span><i className="fa fa-check-circle" aria-hidden="true" /> &nbsp;{f(msg.canBookedOnline)}</span> : ''}
        </td>
        {showCustomValues && (
          <td className="text-right">
            {customValues && <i className="fa fa-exclamation-circle text-info" />}&nbsp;&nbsp;
            <button type="button" className="btn-label-o" disabled={!active} onClick={this.setCustomValues}>{msg.btnSettings}</button>
          </td>
        )}
      </tr>
    );
  }
}

export default injectIntl(ResourceServiceItem);
