var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <form id=\"log-form\" class=\"compact-form\">\n      <div class=\"form-group\">\n         <label>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addNote",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n         <textarea class=\"form-control\" id=\"log-comment\" rows=\"1\" placeholder=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"clickToWrite",{"name":"i18n","hash":{},"data":data}))
    + "\"></textarea>\n      </div>\n      <button type=\"submit\" id=\"log-save\" class=\"btn-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"add",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n   </form>\n   <div id=\"customer-no-log\">\n      <hr>\n      <span class=\"text-muted\"><em>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"thereAreNoPrevNotes",{"name":"i18n","hash":{},"data":data}))
    + "</em></span>\n   </div>\n   <div class=\"customer-log\"></div>\n</div>\n";
},"useData":true});