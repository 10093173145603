import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toggleScheduleEditMode } from '../../state/view-actions';

import { msgScheduleToggle as msg } from '../../localization/messages/components/calendar';


class ScheduleToggle extends Component {

  render() {
    const { scheduleEditMode, phoneMode, tabletMode, intl: { formatMessage: f } } = this.props;

    const iconClasses = scheduleEditMode ? 'fa fa-fw fa-calendar-check-o' : 'fa fa-fw fa-calendar';
    const buttonClasses = scheduleEditMode ? 'btn btn-label-o active' : 'btn btn-label-o';
    const buttonTitle = f(msg.changeShedule);
    const buttonText = !phoneMode && !tabletMode ? buttonTitle : '';
    const buttonStyles = scheduleEditMode ? { color: '#39f', minWidth: 'auto' } : { minWidth: 'auto' };

    return (
      <div className="navbar-button">
        <button onClick={this.dayView} type="button" title={buttonTitle} className={buttonClasses} style={buttonStyles}>
          <i className={iconClasses} /> {buttonText}
        </button>
      </div>
    );
  }

  dayView = (ev) => {
    this.props.toggleScheduleEditMode(!this.props.scheduleEditMode);
    ev.currentTarget.blur();
  };
}

ScheduleToggle.propTypes = {
  scheduleEditMode: PropTypes.bool.isRequired,
  toggleScheduleEditMode: PropTypes.func.isRequired
};


const mapStateToProps = (state) => {
  return {
    scheduleEditMode: state.gridViewState.get('scheduleEditMode')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScheduleEditMode: (state) => {
      dispatch(toggleScheduleEditMode(state));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ScheduleToggle));
