var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n   <div class=\"modal-dialog\">\n      <div class=\"modal-content\">\n         <form class=\"form-horizontal\">\n            <div class=\"modal-header\">\n               <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n                  <span aria-hidden=\"true\">&times;</span>\n                  <span class=\"sr-only\">Close</span></button>\n               <h4 class=\"modal-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n            </div>\n            <div class=\"modal-body\">\n               <div class=\"row\">\n                  <div class=\"col-xs-12\">\n                     <ul class=\"nav nav-tabs customer-nav-tabs\" style=\"margin-top: 0;\">\n                        <li class=\"active\"><a href=\"#customer-info-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"customerInformation",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n                        <li><a href=\"#customer-address-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"address",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n                        <li><a href=\"#customer-log-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"customerLog",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n                        <li><a href=\"#customer-bookings-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"reservations",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n                     </ul>\n                     <div class=\"tab-content customer-tab-content\" style=\"border: none; padding: 20px 0 0 0\">\n                        <div class=\"tab-pane active\" id=\"customer-info-tab\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"name",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control js-new-customer-name\" name=\"name\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"phoneForSms",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"phoneNumber\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"phoneAlternative",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"otherPhoneNumber\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"email",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"email\"/></div>\n                            </div>\n                            <br>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"dateOfBirth",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"dateOfBirth\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"gender",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\">\n                                    <label class=\"radio-inline\"><input name=\"gender\" value=\"Male\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderMale",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                    <label class=\"radio-inline\"><input name=\"gender\" value=\"Female\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderFemale",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                    <label class=\"radio-inline\"><input name=\"gender\" value=\"None\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderNone",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                </div>\n                            </div>\n                            <br>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"other",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><textarea rows=\"3\" class=\"form-control\" name=\"notes\"></textarea></div>\n                            </div>\n                            <br>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"settings",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\">\n                                    <input type=\"hidden\" value=\"true\" name=\"emailReminders\" />\n                                    <label class=\"checkbox-inline\"><input name=\"smsReminders\" type=\"checkbox\" value=\"true\"> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"smsReminders",{"name":"i18n","hash":{},"data":data}))
    + "</label><br>\n                                    <label class=\"checkbox-inline\"><input name=\"allowMarketing\" type=\"checkbox\" value=\"true\"> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"allowMarketing",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"tab-pane\" id=\"customer-address-tab\">\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"stereetAddress",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"address1\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <div class=\"col-sm-6 col-sm-offset-4\"><input class=\"form-control\" name=\"address2\"/></div>\n                            </div>\n                            <div class=\"form-group hide\">\n                                <div class=\"col-sm-6 col-sm-offset-4\"><input class=\"form-control\" name=\"address3\"/></div>\n                            </div>\n                            <div class=\"form-group\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"postCode",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\">\n                                    <div class=\"row\">\n                                        <div class=\"col-xs-4\" style=\"padding-right: 0\"><input class=\"form-control\" name=\"postCode\"/></div>\n                                        <div class=\"col-xs-8\"><input class=\"form-control\" name=\"area\"/></div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"form-group hide\">\n                                <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"country",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                                <div class=\"col-sm-6\"><input class=\"form-control\" name=\"country\"/></div>\n                            </div>\n                        </div>\n                        <div class=\"tab-pane\" id=\"customer-log-tab\">\n                           <div id=\"customer-log-container\"></div>\n                        </div>\n                        <div class=\"tab-pane\" id=\"customer-bookings-tab\">\n                           <div id=\"customer-bookings-container\"></div>\n                        </div>\n                     </div>\n                  </div>\n               </div>\n            </div>\n            <div class=\"modal-footer\">\n               <div class=\"pull-right\">\n                  <a href=\"#\" class=\"btn-cancel\" data-dismiss=\"modal\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"close",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n                  <button type=\"submit\" class=\"btn-save\" data-loading-text=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"saving",{"name":"i18n","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"save",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n               </div>\n            </div>\n         </form>\n      </div>\n   </div>\n</div>\n";
},"useData":true});