import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { fetchVehicleInfo } from '../../state/bkf-actions';

import { msgBookingVehicle as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editVehicle: false,
      searchError: false
    };
  }

  handleClick = (ev) => {
    ev.preventDefault();

    const hidden = ReactDOM.findDOMNode(this.hidden);
    hidden.style.visibility = 'visible';
    hidden.focus();
    hidden.style.visibility = 'hidden';

    this.setState({ editVehicle: true }, () => {
      const vehicleRegNo = ReactDOM.findDOMNode(this.vehicleRegNo);
      vehicleRegNo.focus();
    });
  };

  handleSearch = (ev) => {
    ev.preventDefault();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editVehicle: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    this.props.onClearVehicle();
  };

  handleRefresh = (ev) => {
    ev.preventDefault();
    this.props.fetchVehicleInfo(this.props.vehicleRegNo)
      .catch(() => {
        this.setState({ searchError: true }, () => {
          setTimeout(() => this.setState({ searchError: false }), 1500);
        });
      });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  normalize = value => value && value.toUpperCase();

  render() {
    const { editVehicle, searchError } = this.state;
    const { vehicle, vehicleRegNo, vehicleDescription, status, enableVehicleRegistrySearch, intl: { formatMessage: f } } = this.props;
    const showVehicleSearch = enableVehicleRegistrySearch && vehicleRegNo;
    const isCancelled = status === 'Cancelled';

    if (isCancelled && !vehicle) {
      return null;
    }

    if (editVehicle) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblRegNo)}</label>
              <Field autoComplete="off" name="vehicleRegNo" component="input" type="text" className="form-control" normalize={this.normalize} ref={(ref) => { this.vehicleRegNo = ref; }} />
            </div>
          </div>
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblDescription)}</label>
              <Field autoComplete="off" name="vehicleDescription" component="input" type="text" className="form-control" />
            </div>
          </div>
          {showVehicleSearch && (
            <div className="pull-left">
              <button className={searchError ? 'btn-label error' : 'btn-label'} tabIndex={-1} onClick={this.handleRefresh}>
                {searchError ? f(msg.searchNotFound) : f(msg.searchInformation)}
              </button>
            </div>
          )}
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!vehicle) {
      return (
        <button className="booking-form-block button" onClick={this.handleSearch}>
          <i className="fa fa-car" /> {f(msg.addVehicle)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className={isCancelled ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isCancelled ? this.handleClick : null}>
        {!isCancelled && (
          <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
            <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{f(msgButtons.btnClear2)}</button><br />
          </div>
        )}
        <h4><i className="fa fa-car" /> {vehicleRegNo}</h4>
        {vehicleDescription}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationFeatures } = state;
  const vehicle = bkf.get('vehicle');

  return {
    vehicle,
    initialValues: vehicle,
    ...bkf.get('attributes'),
    ...getFormValues('bkf-vehicle')(state),
    enableVehicleRegistrySearch: locationFeatures.get('EnableVehicleRegistrySearch')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleInfo: regNo => dispatch(fetchVehicleInfo(regNo))
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-vehicle',
  destroyOnUnmount: false
})(BookingVehicle)));
