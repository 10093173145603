import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getOrganisationsUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';

import { msgSendNotAllowedDialog as msg } from '../../localization/messages/components/campaign';

class SendNotAllowedDialog extends Component {

  render() {
    return (
      <div className="dialog-container with-nav">
        <div className="dialog-content">
          {this.props.accountNotActive ? this.renderAccountNotActive() : this.renderSmsBillingDisabled()}
          <hr />
          {this.renderOrganisationsButton()}
        </div>
      </div>
    );
  }

  renderAccountNotActive() {
    return (
      <div>
        <h2><FormattedMessage {...msg.notActiveHeading} /></h2>
        <hr />
        <p><FormattedMessage {...msg.notActiveDescription} /></p>
        <p><FormattedMessage {...msg.notActiveLink} values={{ link: this.renderOrganisationsLink() }} /></p>
      </div>
    );
  }

  renderSmsBillingDisabled() {
    return (
      <div>
        <h2><FormattedMessage {...msg.billingDisabledHeading} /></h2>
        <hr />
        <p><FormattedMessage {...msg.billingDisabledDescription} /></p>
        <p><FormattedMessage {...msg.billingDisabledLink} values={{ link: this.renderOrganisationsLink() }} /></p>
      </div>
    );
  }

  renderOrganisationsLink() {
    const { intl: { formatMessage: f } } = this.props;
    return <a href={getOrganisationsUrl(translateRouteParams(this.props.match.params))}>{f(msg.linkSubscription)}</a>;
  }

  renderOrganisationsButton() {
    const { intl: { formatMessage: f } } = this.props;
    return <a href={getOrganisationsUrl(translateRouteParams(this.props.match.params))} className="button arrow">{f(msg.btnSubscription)}</a>;
  }

  componentWillMount() {
    document.body.style.setProperty('overflow', 'hidden');
  }

  componentWillUnmount() {
    document.body.style.removeProperty('overflow');
  }
}

SendNotAllowedDialog.propTypes = {
  accountNotActive: PropTypes.bool.isRequired,
  smsBillingDisabled: PropTypes.bool.isRequired
};

export default injectIntl(withRouter(SendNotAllowedDialog));
