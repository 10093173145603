var View = require('../../base/view');
var ScheduleView = require('../../schedule/main-view');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = View.extend({
	noWrap: true,
	autoRender: true,

	initialize: function () {
		this.template = require('./schedule-main');
		View.prototype.initialize.call(this, arguments);
	},

	saveSchedule: function(data) {
		this.$('.js-save-schedule').button('loading');
		var url = '/schedules/location/' + data.id;
		$.ajax({
			type: 'PUT',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: url,
			data: JSON.stringify(data.schedule),
			success: _.bind(this.saveSuccess, this),
			error: _.bind(this.saveError, this)
		});
	},

	saveSuccess: function() {
		this.scheduleView.scheduleSaved();
		this.$('.js-save-schedule').button('reset');
		this.publishEvent('alertOverlay:showSuccess', formatMessage(msgHandlebars.scheduleHasBeenSaved));
	},

	saveError: function() {
		this.$('.js-save-schedule').button('reset');
	},

	createSchedule: function(data) {
		$.ajax({
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: '/schedules/location/',
			data: JSON.stringify(data),
			success: _.bind(this.createSuccess, this)
		});
	},

	createSuccess: function() {
		this.scheduleView.closeModal();
		this.fetchModel();
	},

	deleteSchedule: function(scheduleId) {
		$.ajax({
			type: 'DELETE',
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			url: '/schedules/location/' + scheduleId,
			success: _.bind(this.deleteSuccess, this)
		});
	},

	deleteSuccess: function() {
		this.scheduleView.closeDeletePopover();
		this.fetchModel();
	},

	fetchModel: function() {
		this.model.fetch({
			contentType: 'application/json; charset=utf-8',
			success: _.bind(this.scheduleView.renderSchedule, this.scheduleView)
		});
	},

	getTemplateData: function () {
		return {};
	},

	render: function () {
		View.prototype.render.call(this, arguments);
		this.scheduleView = new ScheduleView({model: this.model, editMode: true, container: this.$el});
		this.listenTo(this.scheduleView, 'schedule:create', _.bind(this.createSchedule, this));
		this.listenTo(this.scheduleView, 'schedule:delete', _.bind(this.deleteSchedule, this));
		this.listenTo(this.scheduleView, 'schedule:save', _.bind(this.saveSchedule, this));
		this.subview('content', this.scheduleView);
	}
});
