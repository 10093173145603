import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { getPreviewText } from '../../utils/templates';
import TagsInfo from './tags-info';

import { msgEmailIcsConfig as msg } from '../../localization/messages/components/inputs';

class EmailIcsConfig extends Component {

  render() {
    const { includeIcs, icsHeader, icsLocation, icsNotes, previewValues } = this.props;

    return (
      <div className="form-group" style={{ marginBottom: 0 }}>
        <div className="col-sm-6">
          <div className="checkbox form-control-static">
            <label>
              <Field name={this.getName('includeIcs')} component="input" type="checkbox" />
              <FormattedMessage {...msg.attachInvitation} />
            </label>
          </div>
          <p className="text-muted">
            <FormattedMessage {...msg.invitationDescription} />
          </p>
          {includeIcs ?
            <div className="well well-sm" style={{ marginBottom: 0 }}>
              <label className="control-label"><FormattedMessage {...msg.title} /></label>
              <Field name={this.getName('icsHeader')} component="input" type="text" className="form-control" />

              <label className="control-label"><FormattedMessage {...msg.address} /></label>
              <Field name={this.getName('icsLocation')} component="input" type="text" className="form-control" />

              <label className="control-label"><FormattedMessage {...msg.extra} /></label>
              <Field name={this.getName('icsNotes')} component={this.textArea} />

              <div style={{ marginTop: 10 }}>
                <TagsInfo resource service location />
              </div>
            </div> : null}
        </div>
        {includeIcs ?
          <div className="col-sm-6">
            <div className="form-control-static">&nbsp;</div>
            <p>
              <br />
              <strong><FormattedMessage {...msg.preview} /></strong>
            </p>
            <div className="alert alert-info">
              <p><strong>{getPreviewText(icsHeader, previewValues)}</strong></p>
              <p>{getPreviewText(icsLocation, previewValues)}</p>
              <hr />
              <p className="text-wrap">{getPreviewText(icsNotes, previewValues)}</p>
            </div>
          </div> : null}
      </div>
    );
  }

  getName(name) {
    return this.props.prefix ? `${this.props.prefix}.${name}` : name;
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={3} className="form-control" />
   );
}

export default EmailIcsConfig;
