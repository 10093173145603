import createHistory from 'history/createBrowserHistory';
import moment from 'moment';
import { viewDateFromDate, viewDateToStartEnd, viewDateToViewDate } from './time-util';


const history = createHistory();

export function getHistory() {
  return history;
}

export function getSectionUrl(routeParams, section, subsection) {
  const { org, loc } = routeParams;

  if (subsection) {
    return `/${org}/${loc}/${section}/${subsection}`;
  }
  return `/${org}/${loc}/${section}/`;
}

export function getOrganisationsUrl(routeParams) {
  const { org } = routeParams;

  return `/organisations/${org}/`;
}

export function getResourceConfigUrl(routeParams, id, subsection) {
  const { org, loc } = routeParams;
  const newSubsection = subsection || 'settings';

  return `/${org}/${loc}/resources/${id}/${newSubsection}`;
}

export function getConfigUrl(routeParams) {
  const { org, loc, entityType, entityId } = routeParams;
  const section = entityType == 'resource' ? 'resource-config' : 'group-config';

  return `/${org}/${loc}/${section}/${entityId}`;
}

export function getResourceUrl(resId, newViewMode, routeParams) {
  const { org, loc, viewDate, viewMode } = routeParams;
  const newViewDate = viewDateToViewDate(newViewMode, viewMode, viewDate);


  return `/${org}/${loc}/calendar/${newViewMode}/${newViewDate}/resource/${resId}`;
}

export function getGroupUrl(groupId, routeParams) {
  const { org, loc, viewDate, viewMode } = routeParams;
  const newViewDate = viewDateToViewDate('day', viewMode, viewDate);

  return `/${org}/${loc}/calendar/day/${newViewDate}/group/${groupId}`;
}

export function getCalendarUrl(newViewDate, routeParams) {
  const { org, loc, viewMode, entityType, entityId } = routeParams;

  return `/${org}/${loc}/calendar/${viewMode}/${newViewDate}/${entityType}/${entityId}`;
}

export function getModeUrl(newViewMode, routeParams) {
  const { org, loc, viewDate, viewMode, entityType, entityId } = routeParams;
  const newViewDate = viewDateToViewDate(newViewMode, viewMode, viewDate);

  return `/${org}/${loc}/calendar/${newViewMode}/${newViewDate}/${entityType}/${entityId}`;
}

export function getDateUrl(where, routeParams) {
  const { viewMode, viewDate } = routeParams;
  const viewDateStartEnd = viewDateToStartEnd(viewMode, viewDate);
  const currentDate = moment(viewDateStartEnd.start);
  let newDate;

  switch (where) {
    case 'back':
      newDate = moment(currentDate).subtract(1, viewMode);
      break;
    case 'forward':
      newDate = moment(currentDate).add(1, viewMode);
      break;
    case 'today':
      newDate = moment().startOf('day');
      break;
  }

  const newViewDate = where === 'today' ? 'today' : viewDateFromDate(viewMode, newDate);
  return getCalendarUrl(newViewDate, routeParams);
}

export function navigate(url) {
  history.push(url);
}
