var DaySchedule = require('./schedule-day');


// Week Schedule
module.exports = Chaplin.Model.extend({

   idAttribute: 'key',

   defaults: {
      days: new Chaplin.Collection({model: DaySchedule})
   },

   initialize: function (options) {
      options.parse = true;
      Chaplin.Model.prototype.initialize.call(this, arguments);
   },

   parse: function (data, options) {
      var days = [];
      for(var week = 1; week <= parseInt(data.weeks); week++) {
         for(var day = 1; day <= 7; day++) {
            days.push({day: day, week: parseInt(week), blocks: []});
         }
      }

      _.each(data.blocks, function (block, index, list) {
         var day = _.find(days, {day: block.day, week: block.week});
         day.blocks.push(block);
      });

      delete data.blocks;
      data.days = days;
      return data;
   },

   copy: function () {
      var copy = this.clone();
      var days = _.map(this.attributes.days.models, function (day) {
         return day.copy();
      });
      copy.attributes.days = new Chaplin.Collection(days, {model: DaySchedule});
      return copy;
   },

   set: function (attributes, options) {
      if (attributes.days !== undefined && !(attributes.days instanceof Chaplin.Collection)) {
         attributes.days = new Chaplin.Collection(attributes.days, {model: DaySchedule});
      }
      return Backbone.Model.prototype.set.call(this, attributes, options);
   }
});
