import { defineMessages } from 'react-intl';

export const msgCustomServiceSettingsModal = defineMessages({
  settings: {
    id: 'services.CustomServiceSettingsModal.settings',
    defaultMessage: 'Inställningar'
  },
  settingsFor: {
    id: 'services.CustomServiceSettingsModal.settingsFor',
    defaultMessage: 'Inställningar för {name}'
  },
  markedField: {
    id: 'services.CustomServiceSettingsModal.markedField',
    defaultMessage: 'Fält markerade med {icon} har ett annat värde på tjänsten.'
  }
});

export const msgEditServiceForm = defineMessages({
  useCustomSettings: {
    id: 'services.EditServiceForm.useCustomSettings',
    defaultMessage: 'Använd egna inställningar'
  },
  settingsDescription: {
    id: 'services.EditServiceForm.settingsDescription',
    defaultMessage: 'Med egna inställningar kan du ange egna värden för den här tjänsten. T.ex. pris, längd och inställningar för webbokning.'
  },
  lblLength: {
    id: 'services.EditServiceForm.lblLength',
    defaultMessage: 'Längd'
  },
  helpLength: {
    id: 'services.EditServiceForm.helpLength',
    defaultMessage: 'Ange hur lång tid som ska reserveras vid bokning av den här tjänsten. (minst 5 min)'
  },
  lblPauseAfter: {
    id: 'services.EditServiceForm.lblPauseAfter',
    defaultMessage: 'Paus efter'
  },
  helpPauseAfter: {
    id: 'services.EditServiceForm.helpPauseAfter',
    defaultMessage: 'Reservera extra tid efter bokningen, tex för städning'
  },
  lblPrice: {
    id: 'services.EditServiceForm.lblPrice',
    defaultMessage: 'Pris'
  },
  lblShowFromPrice: {
    id: 'services.EditServiceForm.lblShowFromPrice',
    defaultMessage: 'Visa som från-pris'
  },
  webBookingSettings: {
    id: 'services.EditServiceForm.webBookingSettings',
    defaultMessage: 'Inställningar för webbokning'
  },
  lblReservation: {
    id: 'services.EditServiceForm.lblReservation',
    defaultMessage: 'Bokningsbar'
  },
  lblShowLength: {
    id: 'services.EditServiceForm.lblShowLength',
    defaultMessage: 'Visa längd'
  },
  lblShowPrice: {
    id: 'services.EditServiceForm.lblShowPrice',
    defaultMessage: 'Visa pris'
  },
  lblName: {
    id: 'services.EditServiceForm.lblName',
    defaultMessage: 'Namn'
  },
  description: {
    id: 'services.EditServiceForm.description',
    defaultMessage: 'Beskrivning'
  },
  confirmDelete: {
    id: 'services.EditServiceForm.confirmDelete',
    defaultMessage: 'Är du säker på att du vill ta bort tjänsten? Detta går inte att ångra!'
  },
  btnDeleteService: {
    id: 'services.EditServiceForm.btnDeleteService',
    defaultMessage: 'Ta bort tjänst'
  }
});

export const msgResourceServiceMappings = defineMessages({
  selectResources: {
    id: 'services.ResourceServiceMappings.selectResources',
    defaultMessage: 'Välj resurser som utför tjänsten'
  },
  selectResourcesHelp: {
    id: 'services.ResourceServiceMappings.selectResourcesHelp',
    defaultMessage: 'Du kan ställa in egna värden för respektive resurs, t.ex. om en tjänst har olika pris beroende på vem som utför den.'
  },
  chooseAll: {
    id: 'services.ResourceServiceMappings.chooseAll',
    defaultMessage: 'Välj alla'
  },
  ownValues: {
    id: 'services.ResourceServiceMappings.ownValues',
    defaultMessage: '= Egna värden inställda för resursen (t.ex. tid/pris)'
  },
  settings: {
    id: 'services.ResourceServiceMappings.settings',
    defaultMessage: 'Inställningar'
  },
  selectAllQuestion: {
    id: 'services.ResourceServiceMappings.selectAllQuestion',
    defaultMessage: 'Vill du välja samtliga resurser som bokningsbara för denna tjänst?'
  }
});

export const msgServiceListToolbar = defineMessages({
  sort: {
    id: 'services.ServiceListToolbar.sort',
    defaultMessage: 'Sortera'
  },
  newService: {
    id: 'services.ServiceListToolbar.newService',
    defaultMessage: 'Ny tjänst'
  },
  newGroup: {
    id: 'services.ServiceListToolbar.newGroup',
    defaultMessage: 'Ny Grupp'
  },
  enterTheName: {
    id: 'services.ServiceListToolbar.enterTheName',
    defaultMessage: 'Ange namn på tjänsten'
  }
});

export const msgServiceConfig = defineMessages({
  filterByName: {
    id: 'services.ServicesConfig.filterByName',
    defaultMessage: 'Filtrera tjänster på namn...'
  },
  note: {
    id: 'services.ServicesConfig.note',
    defaultMessage: 'OBS! Inga resurser är valda för den här tjänsten. För att kunna bokas online behöver du välja vilka resurser som utför tjänsten i listan till höger.'
  },
  withIcon: {
    id: 'services.ServicesConfig.withIcon',
    defaultMessage: 'Fält markerade med {icon} har ett annat värde för en eller flera resurser. Se inställningar i listan till höger.'
  }
});
