import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PasswordPolicy from './password-policy';
import PasswordStrength from './password-strength';

import { msgChangePassword as msg } from '../../localization/messages/components/user-settings';

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  render() {
    const { wrongPassword, passwordPolicy, isChangingPassword, intl: { formatMessage: f } } = this.props;
    const { currentPassword, newPassword, validationError, changePasswordSuccess, isValidating, showForm, showPassword } = this.state;

    const wrongPasswordText = isValidating && wrongPassword ? f(msg.wrongPasswordText) : '';
    const passwordPolicyText = isValidating && validationError ? f(msg.passwordPolicyText) : '';

    const currentPasswordClass = isValidating && wrongPassword ? 'form-group has-error' : 'form-group';
    const newPasswordClass = isValidating && validationError ? 'form-group has-error' : 'form-group';

    const submitClass = (isChangingPassword || currentPassword.length == 0 || newPassword.length == 0) ? 'btn btn-save disabled' : 'btn btn-save';
    const submitText = isChangingPassword ? f(msg.changingPassword) : f(msg.changePassword);

    return (
      <fieldset>
        <legend>{f(msg.changePassword)}</legend>
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            {changePasswordSuccess && (
              <p className="text-success lead">
                <i className="fa fa-check" /> {f(msg.passwordChanged)}
              </p>
            )}
            {showForm ?
              <form onSubmit={this.handleSubmit}>
                <PasswordPolicy passwordPolicy={passwordPolicy} validationError={validationError} isValidating={isValidating} />
                <div className={newPasswordClass}>
                  {newPassword && (
                    <a href="#" className="form-control-button" onClick={this.toggleShowPassword}>
                      <i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} />
                    </a>
                  )}
                  <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder={f(msg.chooseNewPassword)} value={newPassword} onChange={this.handleNewPassword} ref={(ref) => { this.newPassword = ref; }} />
                  <span className="help-block">{passwordPolicyText}</span>
                </div>
                {!passwordPolicy && <PasswordStrength newPassword={newPassword} />}
                <br />
                <p className="text-muted small">{f(msg.enterCurrentPassword)}</p>
                <div className={currentPasswordClass}>
                  <input type="password" className="form-control" placeholder={f(msg.currentPassword)} value={currentPassword} onChange={this.handleCurrentPassword} />
                  <span className="help-block">{wrongPasswordText}</span>
                </div>
                <button type="submit" className={submitClass}>{submitText}</button>
              </form> :
              <a href="#" className="btn btn-save" onClick={this.handleShowForm}>{f(msg.changePassword)}</a>
                   }
          </div>
        </div>
      </fieldset>
    );
  }

  handleShowForm = (ev) => {
    ev.preventDefault();
    this.setState({
      changePasswordSuccess: false,
      showForm: true
    });
    this.validatePassword();
  };

  toggleShowPassword = (ev) => {
    ev.preventDefault();
    this.setState(({ showPassword }) => ({
      showPassword: !showPassword
    }), () => this.newPassword.focus());
  };

  handleCurrentPassword = (ev) => {
    this.setState({
      currentPassword: ev.target.value
    });
  };

  handleNewPassword = (ev) => {
    this.setState({
      newPassword: ev.target.value
    });
    this.validatePassword(ev.target.value);
  };

  validatePassword = (password) => {
    const { passwordPolicy } = this.props;
    if (!passwordPolicy) {
      return true;
    }

    const errors = [];
    const { minLength, caps, digits, specials } = passwordPolicy;

    if (minLength && !this.validate(password, /./g, minLength)) {
      errors.push('minLength');
    }
    if (caps && !this.validate(password, /[A-ZÅÄÖ]/g, caps)) {
      errors.push('caps');
    }
    if (digits && !this.validate(password, /[0-9]/g, digits)) {
      errors.push('digits');
    }
    if (specials && !this.validate(password, /[^0-9 a-zåäö]/gi, specials)) {
      errors.push('specials');
    }

    this.setState({ validationError: errors.length > 0 ? errors : null });
    return errors.length === 0;
  }

  getPolicyDescription = (key) => {
    const { passwordPolicy, intl: { formatMessage: f } } = this.props;
    const value = passwordPolicy.get(key);

    if (key == 'minLength') {
      return f(msg.policyMinLength, { value });
    }
    if (key == 'caps') {
      return f(msg.policyCaps, { value });
    }
    if (key == 'digits') {
      return f(msg.policyDigits, { value });
    }
    if (key == 'specials') {
      return f(msg.policySpecials, { value });
    }
  };

  validate = (password, regexp, count) => {
    const match = password && password.match(regexp);
    return match && match.length >= count;
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    const { onChangePassword } = this.props;
    const { currentPassword, newPassword } = this.state;

    this.setState({ isValidating: true });

    if (!this.validatePassword(newPassword)) {
      return;
    }

    onChangePassword({
      current: currentPassword,
      new: newPassword
    });
  };

  makeInitialState(props) {
    return {
      showForm: false,
      showPassword: false,
      newPassword: '',
      currentPassword: '',
      validationError: null,
      changePasswordSuccess: false,
      isValidating: false
    };
  }

  componentWillUpdate(nextProps, nextState) {
    const { changePasswordSuccess } = this.props;

    if (!changePasswordSuccess && nextProps.changePasswordSuccess) {
      const state = this.makeInitialState(this.props);
      state.changePasswordSuccess = true;
      this.setState(state);
    }
  }
}

ChangePassword.propTypes = {
  isChangingPassword: PropTypes.bool.isRequired,
  wrongPassword: PropTypes.bool.isRequired,
  changePasswordSuccess: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired
};

export default injectIntl(ChangePassword);

