import React from 'react';
import { useIntl } from 'react-intl';

import { msgSendReceipt as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

const SendReceipt = ({ onCancel, onConfirm, progress, customer }) => {
  const { formatMessage: f } = useIntl();
  const { name, email } = customer;

  return (
    <form onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{f(msg.heading)}</strong>
      </p>
      <div className="form-group">
        <input type="text" className="form-control" name="name" placeholder={f(msg.name)} defaultValue={name} required />
      </div>
      <div className="form-group">
        <input type="email" className="form-control" name="email" placeholder={f(msg.email)} defaultValue={email} required />
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{f(msgButtons.btnCancel)}</button>{' '}
        <button type="submit" className="btn-save" tabIndex="3">{progress ? f(msgButtons.btnSending) : f(msgButtons.btnSend)}</button>
      </div>
    </form>
  );
};

export default SendReceipt;
