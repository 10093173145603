var moment = require('moment');
var CollectionView = require('../../../base/collection-view');
var LogItemView = require('./log-item-view');
var CustomerLogItem = require('../../../../models/customer-log-item');
var CustomerLogCollection = require('../../../../collections/customer-log');

module.exports = CollectionView.extend({
   noWrap: true,
   autoRender:true,
	itemView: LogItemView,
	listSelector: '.customer-log',
	fallbackSelector: '#customer-no-log',

	events: {
		'focus #log-comment': 'showCreate',
		'keyup #log-comment': 'commentKeyUp',
		'submit #log-form': 'saveComment'

	},

   initialize: function () {
		this.collection = new CustomerLogCollection();
		this.collection.comparator = this.comparator;
		this.collection.fetch(this.model.id);
		this.template = require('./log');
		CollectionView.prototype.initialize.call(this, arguments);
	},

	show: function (item) {
		this.collection.fetch(item.id);
      this.resetForm();
	},

	render: function () {
      CollectionView.prototype.render.call(this, arguments);
		this.resetForm();
	},

	showCreate: function() {
		this.$('#log-comment').attr('rows', 5);
		this.$('#log-save').show();
		this.enableOrDisableSaveLog();
	},

	commentKeyUp: function(ev) {
		this.enableOrDisableSaveLog();
	},

	enableOrDisableSaveLog: function() {
		var charCount = this.$('#log-comment').val().length;
		if (charCount === 0){
			this.$('#log-save').addClass('disabled');
		}
		else{
			this.$('#log-save').removeClass('disabled');
		}
	},

	resetForm: function() {
		this.$('#log-comment').val('');
		this.resizeForm();
	},

   resizeForm: function() {
      this.$('#log-comment').attr('rows', 1);
      this.$('#log-save').hide();
   },

	isCommentUnsaved: function() {
   	return this.$('#log-comment').val() !== '';
	},

	saveComment: function(e, callback) {
		e.preventDefault();

      var data = {comment: this.$('#log-comment').val()};
      $.ajax({
         type: 'POST',
         contentType: 'application/json; charset=utf-8',
         dataType: 'json',
         url: '/customers/' + this.model.id + '/logentries',
         url: this.collection.url(),
         data: JSON.stringify(data),
         success: _.bind(this.saveSuccess, this, callback)
      });
	},

	saveSuccess: function (callback, data) {
      this.collection.add(new CustomerLogItem(data), {at:0});
		this.resetForm();

		if(callback) {
			callback();
		}
	},

	comparator: function(item) {
		return -moment(item.get('created'));
	}
});
