import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { msgBookingPlace as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingPlace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editAddress: false
    };
  }

  handleClick = (ev) => {
    ev.preventDefault();

    const hidden = ReactDOM.findDOMNode(this.hidden);
    hidden.style.visibility = 'visible';
    hidden.focus();
    hidden.style.visibility = 'hidden';

    this.setState({ editAddress: true }, () => {
      const address = ReactDOM.findDOMNode(this.address);
      address.focus();
    });

    this.props.onChangePlace('Home');
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editAddress: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    this.props.onChangePlace('Salon');
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  render() {
    const { editAddress } = this.state;
    const { place, address1, postCode, area, intl: { formatMessage: f } } = this.props;
    const hasAddress = !!address1 || !!postCode || !!area;

    if (editAddress) {
      return (
        <div className="booking-form-block form">
          <div className="row">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblAddress)}</label>
              <Field name="address1" component="input" type="text" className="form-control" ref={(ref) => { this.address = ref; }} />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-xs-4" style={{ paddingRight: 0 }}>
              <label className="control-label">{f(msg.lblPostCode)}</label>
              <Field name="postCode" component="input" type="text" className="form-control" />
            </div>
            <div className="form-group col-xs-8">
              <label className="control-label">{f(msg.lblArea)}</label>
              <Field name="area" component="input" type="text" className="form-control" />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (place !== 'Home') {
      return (
        <button className="booking-form-block button" onClick={this.handleClick}>
          <i className="fa fa-home" /> {f(msg.btnMakeHomeVisit)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className="booking-form-block" onClick={this.handleClick}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{f(msgButtons.btnClear2)}</button>
        </div>
        <h4>{f(msg.homeVisit)}</h4>
        {hasAddress ? (
          <span>
            {address1}<br />
            {postCode} {area}
          </span>
        ) : (
          <span className="text-muted">
            <em>{f(msg.addressMissing)}</em>
          </span>
        )}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const attributes = bkf.get('attributes');

  const initialValues = {
    address1: attributes.address1,
    postCode: attributes.postCode,
    area: attributes.area
  };

  return {
    initialValues,
    place: attributes.place,
    ...getFormValues('bkf-address')(state)
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-address',
  destroyOnUnmount: false
})(BookingPlace)));
