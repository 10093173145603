import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';

import {
  hasPermission,
  CHANGE_LOCATION_SETTINGS,
  CHANGE_RESOURCES,
  CHANGE_LOCATION_SERVICES
} from '../../utils/permissions';
import { actionRequired } from '../../utils/account';
import { getOrganisationsUrl } from '../../utils/navigate';
import { getFeatures, getPermissions, getRouteParams, getIsSysAdmin } from '../../utils/selectors';

import { msgSections as msg } from '../../localization/messages/components/nav';

const SectionItem = (props) => {
  const { id, name, iconMode, iconName, notification, url, org, loc, section } = props;

  const iconClasses = `fa fa-lg ${iconName}`;
  const label = iconMode ?
    (<div>
      <i className={iconClasses} />
      <small>{name}</small>
    </div>) : <span>{name}</span>;

  const className = 'main-nav-item';
  const active = section === id ? 'active' : '';
  const note = notification ? <span className="notification">{notification}</span> : null;

  return (
    <li className={active}>
      {url ?
        <a href={url} className={className}>{note} {label}</a> :
        <Link to={`/${org}/${loc}/${id}/`} className={className}>{note} {label}</Link>
            }
    </li>
  );
};


SectionItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconMode: PropTypes.bool.isRequired,
  notification: PropTypes.string
};


class SectionList extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { phoneMode, tabletMode } = this.props;

    if (phoneMode) {
      return null;
    }
    if (tabletMode) {
      return this.renderCollapsedMenu(4);
    }
    return this.renderFullMenu();
  }

  renderFullMenu() {
    const { sections, routeParams } = this.props;

    return (
      <ul className="nav navbar-nav">
        {sections.map((section, i) =>
          (<SectionItem
            key={section.id}
            {...section}
            iconMode
            {...routeParams}
          />)
               )}
      </ul>
    );
  }

  renderCollapsedMenu(items) {
    const { sections, routeParams, intl: { formatMessage: f } } = this.props;

    return (
      <ul className="nav navbar-nav">
        {sections.slice(0, items).map((section, i) =>
          (<SectionItem
            key={section.id}
            {...section}
            iconMode
            {...routeParams}
          />)
               )}
        <li className="dropdown">
          <a href="#" className="dropdown-toggle main-nav-item" data-toggle="dropdown">
            <div><i className="fa fa-lg fa-bars" /><small>{f(msg.more)}</small></div>
          </a>
          <ul className="dropdown-menu">
            {sections.slice(items).map((section, i) =>
              (<SectionItem
                key={section.id}
                {...section}
                iconMode={false}
                {...routeParams}
              />)
                     )}
          </ul>
        </li>
      </ul>
    );
  }
}

SectionList.propTypes = {
  sections: PropTypes.array.isRequired,
  routeParams: PropTypes.object.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  tabletMode: PropTypes.bool.isRequired
};

const getTrialStatus = state => state.appState.get('trialStatus');
const getAccountStatus = state => state.appState.get('accountStatus');
const getIntlFunction = (_, props) => props.intl && props.intl.formatMessage;

const getLocalizedSections = createSelector(
      getRouteParams,
      getPermissions,
      getTrialStatus,
      getAccountStatus,
      getFeatures,
      getIsSysAdmin,
      getIntlFunction,
      (routeParams, permissions, trialStatus, accountStatus, locationFeatures, isSysAdmin, formatMessage) => {
        const { ShowPayments, ShowSmsCampaign, ShowReports, ShowSettings, ShowResourcesSettings, ShowServicesSettings } = locationFeatures;

        const sections = [
            { id: 'calendar', name: formatMessage(msg.calendar), iconName: 'fa-calendar-o', notification: null, url: null },
            { id: 'customers', name: formatMessage(msg.customers), iconName: 'fa-book', notification: null, url: null }
        ];

        if (ShowResourcesSettings && hasPermission(permissions, CHANGE_RESOURCES)) {
          sections.push({ id: 'resources', name: formatMessage(msg.resources), iconName: 'fa-user', notification: null, url: null });
        }

        if (isSysAdmin || (ShowServicesSettings && hasPermission(permissions, CHANGE_LOCATION_SERVICES))) {
          sections.push({ id: 'services', name: formatMessage(msg.services), iconName: 'fa-scissors', notification: null, url: null });
        }

        if (ShowReports) {
          sections.push({ id: 'reports', name: formatMessage(msg.reports), iconName: 'fa-pie-chart', notification: null, url: null });
        }

        if (ShowSmsCampaign) {
          sections.push({ id: 'campaign', name: formatMessage(msg.reports), iconName: 'fa-send', notification: null, url: null });
        }

        if (ShowSettings && hasPermission(permissions, CHANGE_LOCATION_SETTINGS)) {
          sections.push({ id: 'account-config', name: formatMessage(msg.accountConfig), iconName: 'fa-cog', notification: null, url: null });
        }

        if (ShowPayments && hasPermission(permissions, CHANGE_LOCATION_SETTINGS)) {
          const notification = actionRequired(trialStatus, accountStatus) ? '!' : null;
          sections.push({ id: 'organisations', name: formatMessage(msg.organisations), iconName: 'fa-cloud', notification, url: getOrganisationsUrl(routeParams) });
        }

        return sections;
      }
);

const mapStateToProps = (state, ownProps) => {
  const { mainViewState } = state;
  return {
    sections: getLocalizedSections(state, ownProps),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode')
  };
};

export default injectIntl(connect(
      mapStateToProps
)(SectionList));
