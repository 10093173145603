import { defineMessages } from 'react-intl';

export const msgConfirmDeleteDialog = defineMessages({
  heading: {
    id: 'campaign.ConfirmDeleteDialog.heading',
    defaultMessage: 'Avbryt utskick'
  },
  description: {
    id: 'campaign.ConfirmDeleteDialog.description',
    defaultMessage: 'Är du säker på att du vill avbryta och ta bort utskicket?'
  },
  btnClose: {
    id: 'campaign.ConfirmDeleteDialog.btnClose',
    defaultMessage: 'Nej, gå tillbaka'
  },
  btnConfirm: {
    id: 'campaign.ConfirmDeleteDialog.btnConfirm',
    defaultMessage: 'Ja, avbryt utskicket'
  }
});

export const msgConfirmSendDialog = defineMessages({
  heading: {
    id: 'campaign.ConfirmSendDialog.heading',
    defaultMessage: 'Bekräfta utskick'
  },
  sendMessageQuestion: {
    id: 'campaign.ConfirmSendDialog.sendMessageQuestion',
    defaultMessage: 'Vill du skicka följande meddelande till valda kunder?'
  },
  explanationText: {
    id: 'campaign.ConfirmSendDialog.explanationText',
    defaultMessage: 'Ditt utskick kommer skickas till <b>{recipients}</b> mottagare <b>{isNow, select, true {direkt} other {{deliveryTime}}}</b>.'
  },
  amount: {
    id: 'campaign.ConfirmSendDialog.amount',
    defaultMessage: 'Total kostnad: {cost}'
  },
  lblAgree: {
    id: 'campaign.ConfirmSendDialog.lblAgree',
    defaultMessage: 'Jag godkänner att SMS skickas till valda kunder samt att mitt konto debiteras enligt ovan.'
  },
  btnConfirm: {
    id: 'campaign.ConfirmSendDialog.btnConfirm',
    defaultMessage: 'Skapa SMS-utskick'
  }
});

export const msgList = defineMessages({
  createMessage: {
    id: 'campaign.List.createMessage',
    defaultMessage: 'Skapa ett nytt SMS-utskick'
  },
  heading: {
    id: 'campaign.List.heading',
    defaultMessage: 'Skickade och kommande SMS-utskick'
  },
  campaignsExplanation: {
    id: 'campaign.List.campaignsExplanation',
    defaultMessage: 'Avsändare: {sender}, Antal mottagare: {recipientCount, number}'
  },
  cancelAndDelete: {
    id: 'campaign.List.cancelAndDelete',
    defaultMessage: 'Avbryt {ampersand} ta bort'
  },
  isSent: {
    id: 'campaign.List.isSent',
    defaultMessage: 'Skickat {date}'
  },
  notSent: {
    id: 'campaign.List.notSent',
    defaultMessage: 'Skickas {date}'
  },
  noCampaigns: {
    id: 'campaign.List.noCampaigns',
    defaultMessage: 'Få fler bokningar, gör ditt första SMS-utskick idag!'
  }
});

export const msgSend = defineMessages({
  showList: {
    id: 'campaign.Send.showList',
    defaultMessage: 'Tillbaka till skickade och kommande SMS-utskick'
  },
  heading: {
    id: 'campaign.Send.heading',
    defaultMessage: 'Skapa ett nytt SMS-utskick'
  },
  groupMailingName: {
    id: 'campaign.Send.groupMailingName',
    defaultMessage: 'Namnge utskicket'
  },
  lblDescription: {
    id: 'campaign.Send.lblDescription',
    defaultMessage: 'Beskrivning'
  },
  helpDescription: {
    id: 'campaign.Send.helpDescription',
    defaultMessage: 'Ange ett namn på utskicket för att lättare kunna hitta tidigare utskick'
  },
  groupSenderAndContent: {
    id: 'campaign.Send.groupSenderAndContent',
    defaultMessage: 'Avsändare och innehåll'
  },
  lblSender: {
    id: 'campaign.Send.lblSender',
    defaultMessage: 'Avsändare'
  },
  errorSender: {
    id: 'campaign.Send.errorSender',
    defaultMessage: 'Max 11 tecken, enbart bokstäver (ej åäö), siffror och understreck (_)'
  },
  helpSender: {
    id: 'campaign.Send.helpSender',
    defaultMessage: 'Detta är det namn som kommer synas i mottagarens mobiltelefon'
  },
  lblMessage: {
    id: 'campaign.Send.lblMessage',
    defaultMessage: 'Meddelande'
  },
  infoMessage: {
    id: 'campaign.Send.infoMessage',
    defaultMessage: 'Antal tecken: {length, number} (Antal SMS per mottagare: {messageCount, number})'
  },
  helpMessage: {
    id: 'campaign.Send.helpMessage',
    defaultMessage: 'Skriv ditt meddelande. Meddelandet kan vara upp till 916 tecken. Observera att om meddelandet överstiger 160 tecken så kommer mer än ett meddelande per mottagare att skickas.'
  },
  groupRecipient: {
    id: 'campaign.Send.groupRecipient',
    defaultMessage: 'Vilka kunder ska få SMS?'
  },
  lblBookedWith: {
    id: 'campaign.Send.lblBookedWith',
    defaultMessage: 'Kunder bokade hos'
  },
  lblSelectAllResources: {
    id: 'campaign.Send.lblSelectAllResources',
    defaultMessage: 'Välj alla resurser'
  },
  helpSelectAllResources: {
    id: 'campaign.Send.helpSelectAllResources',
    defaultMessage: 'Bocka för de resursers kunder du vill skicka till. Om en kund varit bokad hos flera resurser skickas bara ett meddelande till denna kund.'
  },
  lblOther: {
    id: 'campaign.Send.lblOther',
    defaultMessage: 'Övrigt'
  },
  lblIncludeCustomers: {
    id: 'campaign.Send.lblIncludeCustomers',
    defaultMessage: 'Inkludera kunder som inte är kopplade till en resurs'
  },
  lblHowLongAgo: {
    id: 'campaign.Send.lblHowLongAgo',
    defaultMessage: 'Hur länge sen?'
  },
  lblNoLimits: {
    id: 'campaign.Send.lblNoLimits',
    defaultMessage: 'Ingen begränsning'
  },
  lblBookedDaysAgo: {
    id: 'campaign.Send.lblBookedDaysAgo',
    defaultMessage: 'Bokat senaste {count, number} dagarna'
  },
  lblBookedMonthsAgo: {
    id: 'campaign.Send.lblBookedMonthsAgo',
    defaultMessage: 'Bokat senaste {count, number} månaderna'
  },
  lblBookedYearAgo: {
    id: 'campaign.Send.lblBookedYearAgo',
    defaultMessage: 'Bokat senaste året'
  },
  helpHowLongAgo: {
    id: 'campaign.Send.helpHowLongAgo',
    defaultMessage: 'Används för att begränsa utskicket till de kunder som har haft en bokning t.ex. inom de senaste 6 månaderena.'
  },
  lblHowFar: {
    id: 'campaign.Send.lblHowFar',
    defaultMessage: 'Hur långt fram?'
  },
  lblWithinDaysAgo: {
    id: 'campaign.Send.lblWithinDaysAgo',
    defaultMessage: 'Inom närmaste {count, number} dagarna'
  },
  lblWithinMonthsAgo: {
    id: 'campaign.Send.lblWithinMonthsAgo',
    defaultMessage: 'Inom närmaste {count, number} månaderna'
  },
  lblWithinYearAgo: {
    id: 'campaign.Send.lblWithinYearAgo',
    defaultMessage: 'Inom närmaste året'
  },
  helpHowFar: {
    id: 'campaign.Send.helpHowFar',
    defaultMessage: 'Används för att begränsa utskicket till de kunder som har en kommande bokning t.ex. inom de närmaste 30 dagarna.'
  },
  sendingExplanation: {
    id: 'campaign.Send.sendingExplanation',
    defaultMessage: 'Med detta urval kommer SMS att skickas till {recipients} mottagare.'
  },
  groupSendingTime: {
    id: 'campaign.Send.groupSendingTime',
    defaultMessage: 'När ska utskicket skickas?'
  },
  lblSelectTime: {
    id: 'campaign.Send.lblSelectTime',
    defaultMessage: 'Välj tidpunkt'
  },
  lblSendDirectly: {
    id: 'campaign.Send.lblSendDirectly',
    defaultMessage: 'Skicka direkt'
  },
  helpSelectTime: {
    id: 'campaign.Send.helpSelectTime',
    defaultMessage: 'Välj om meddelandet ska skickas direkt eller vid en senare tidpunkt.'
  },
  btnSubmit: {
    id: 'campaign.Send.btnSubmit',
    defaultMessage: 'Gå vidare till nästa steg'
  }
});

export const msgSendNotAllowedDialog = defineMessages({
  notActiveHeading: {
    id: 'campaign.SendNotAllowedDialog.notActiveHeading',
    defaultMessage: 'Kreditkort saknas'
  },
  notActiveDescription: {
    id: 'campaign.SendNotAllowedDialog.notActiveDescription',
    defaultMessage: 'Kreditkort saknas'
  },
  notActiveLink: {
    id: 'campaign.SendNotAllowedDialog.notActiveLink',
    defaultMessage: 'Gå till {link} för att lägga till kreditkort och aktivera kontot.'
  },
  billingDisabledHeading: {
    id: 'campaign.SendNotAllowedDialog.billingDisabledHeading',
    defaultMessage: 'SMS är inte aktiverat'
  },
  billingDisabledDescription: {
    id: 'campaign.SendNotAllowedDialog.billingDisabledDescription',
    defaultMessage: 'För att kunna skapa SMS-utskick krävs att SMS aktiveras. Kostnader för SMS tillkommer.'
  },
  billingDisabledLink: {
    id: 'campaign.SendNotAllowedDialog.billingDisabledLink',
    defaultMessage: 'Gå till {link} för att aktivera SMS.'
  },
  linkSubscription: {
    id: 'campaign.SendNotAllowedDialog.linkSubscription',
    defaultMessage: 'Abonnemang'
  },
  btnSubscription: {
    id: 'campaign.SendNotAllowedDialog.btnSubscription',
    defaultMessage: 'Gå till Abonnemang'
  }
});
