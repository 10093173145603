import React, { Component } from 'react';
import { Modal } from 'react-overlays';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isRunningLocal } from '../../utils/config';
import { getLoginUrl, clearSession } from '../../../redux-login/actions';

import { msgAuthRequired as msg } from '../../localization/messages/components/dialogs';

class AuthRequired extends Component {
  componentDidMount() {
    clearSession();
  }

  handleLogin = (ev) => {
    ev.preventDefault();

    // Make sure session is cleared before returning to login
    clearSession();

    if (isRunningLocal()) {
      window.location.href = '/login/';
    } else {
      window.location.href = getLoginUrl();
    }
  };

  render() {
    return (
      <Modal className="dialog-container" show autoFocus={false} enforceFocus={false}>
        <div className="dialog-content">
          <h2><i className="fa fa-sign-in" /> <FormattedMessage {...msg.logIn} /></h2>
          <hr />

          <p>
            <FormattedMessage {...msg.info} values={{ linebreak: <br /> }} />
          </p>

          <hr />

          <button onClick={ev => this.handleLogin(ev)} className="button arrow">
            <FormattedMessage {...msg.logIn} />
          </button>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(AuthRequired);
