var PhoneNumberParser = require('./phone-number-parser');

module.exports = {

   initialize: function(){
   },

   isMobile: function(phNo) {
      return phNo && PhoneNumberParser.isMobile(phNo);
   },

   formatPhoneNumber: function (phNo) {
      if (!phNo) {
         return '';
      }

      return PhoneNumberParser.parseNumber(phNo).localized();
   },

   formatPhoneNumberE164: function (phNo) {
      if (!phNo) {
         return '';
      }

      return PhoneNumberParser.parseNumber(phNo).e164formatted();
   }
};