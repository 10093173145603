import { defineMessages } from 'react-intl';

export const msgNav = defineMessages({
  general: {
    id: 'account-config.Nav.general',
    defaultMessage: 'Allmänt'
  },
  webBooking: {
    id: 'account-config.Nav.webBooking',
    defaultMessage: 'Webbokning'
  },
  sms: {
    id: 'account-config.Nav.sms',
    defaultMessage: 'SMS'
  },
  email: {
    id: 'account-config.Nav.email',
    defaultMessage: 'E-post'
  },
  shedule: {
    id: 'account-config.Nav.shedule',
    defaultMessage: 'Schema'
  },
  notifications: {
    id: 'account-config.Nav.notifications',
    defaultMessage: 'Notiser'
  },
  users: {
    id: 'account-config.Nav.users',
    defaultMessage: 'Användare'
  },
  features: {
    id: 'account-config.Nav.features',
    defaultMessage: 'Tillval'
  },
  payment: {
    id: 'account-config.Nav.payment',
    defaultMessage: 'Kortbetalning'
  },
  storage: {
    id: 'account-config.Nav.storage',
    defaultMessage: 'Lagring'
  },
  contacts: {
    id: 'account-config.Nav.contacts',
    defaultMessage: 'Kontakter & villkor'
  }
});

export const msgCompanyInfo = defineMessages({
  heading: {
    id: 'account-config.contacts-config.CompanyInfo.heading',
    defaultMessage: 'Företagsuppgifter'
  },
  text: {
    id: 'account-config.contacts-config.CompanyInfo.text',
    defaultMessage: 'Namn och organisationsnummer på den (juridiska) person som är betalningsansvarig för avtalet med Dashl AB. {br} Uppgifterna visas även för dina kunder under "Information om personuppgiftsbehandling", som finns tillgänglig vid webbokning och i mejlbekräftelser.'
  },
  orgName: {
    id: 'account-config.contacts-config.CompanyInfo.orgName',
    defaultMessage: 'Företagsnamn'
  },
  companyRegNo: {
    id: 'account-config.contacts-config.CompanyInfo.companyRegNo',
    defaultMessage: 'Organisationsnr'
  }
});

export const msgContact = defineMessages({
  name: {
    id: 'account-config.contacts-config.Contact.name',
    defaultMessage: 'Kontaktperson'
  },
  email: {
    id: 'account-config.contacts-config.Contact.email',
    defaultMessage: 'E-post'
  },
  phone: {
    id: 'account-config.contacts-config.Contact.phone',
    defaultMessage: 'Telefon'
  },
  nameSameContact: {
    id: 'account-config.contacts-config.Contact.nameSameContact',
    defaultMessage: 'Samma som kontoägare'
  },
  nameOtherContact: {
    id: 'account-config.contacts-config.Contact.nameOtherContact',
    defaultMessage: 'Annan kontakt'
  }
});

export const msgContactsConfig = defineMessages({
  ownerTitle: {
    id: 'account-config.contacts-config.ContactsConfig.ownerTitle',
    defaultMessage: 'Kontoägare'
  },
  ownerDescription: {
    id: 'account-config.contacts-config.ContactsConfig.ownerDescription',
    defaultMessage: 'Kontaktuppgifter till den som är ansvarig för Dashl hos er.'
  },
  policyTitle: {
    id: 'account-config.contacts-config.ContactsConfig.policyTitle',
    defaultMessage: 'Personuppgiftsansvarig'
  },
  policyDescription: {
    id: 'account-config.contacts-config.ContactsConfig.policyDescription',
    defaultMessage: 'Ange dessa uppgifter om ni har en annan kontakt för personuppgiftshantering.'
  },
  billingTitle: {
    id: 'account-config.contacts-config.ContactsConfig.billingTitle',
    defaultMessage: 'Fakturor och kvitton'
  },
  billingDescription: {
    id: 'account-config.contacts-config.ContactsConfig.billingDescription',
    defaultMessage: 'Ange dessa uppgifter om ni har en annan kontakt för kvitton och fakturor.'
  },
  legalHeading: {
    id: 'account-config.contacts-config.ContactsConfig.legalHeading',
    defaultMessage: 'Allmänna Villkor'
  },
  legalLink: {
    id: 'account-config.contacts-config.ContactsConfig.legalLink',
    defaultMessage: 'Allmänna villkor för Dashl (PDF)'
  },
  legalContent: {
    id: 'account-config.contacts-config.ContactsConfig.legalContent',
    defaultMessage: 'Här hittar du bland annat information om uppsägningstid, avslut och personuppgiftsbiträdesavtal. Genom att använda tjänsten så godkänner du dessa villkor.'
  },
  informationHeading: {
    id: 'account-config.contacts-config.ContactsConfig.informationHeading',
    defaultMessage: 'Information till dina kunder'
  },
  informationLink: {
    id: 'account-config.contacts-config.ContactsConfig.informationLink',
    defaultMessage: 'Information om personuppgiftsbehandling'
  },
  informationContent: {
    id: 'account-config.contacts-config.ContactsConfig.informationContent',
    defaultMessage: 'Här hittar du information till dina kunder om vilken behandling av personuppgifter som utförs i Dashl. Bl.a ändamål, laglig grund och lagringsperiod.'
  },
  moreHeading: {
    id: 'account-config.contacts-config.ContactsConfig.moreHeading',
    defaultMessage: 'Läs mer om GDPR'
  },
  moreLink: {
    id: 'account-config.contacts-config.ContactsConfig.moreLink',
    defaultMessage: 'Läs mer om GDPR på Verksamt.se'
  },
  moreContent: {
    id: 'account-config.contacts-config.ContactsConfig.moreContent',
    defaultMessage: ' Här hittar du mer information om GDPR samt en guide framtagen av datainspektionen för att hjälpa små företag att förbereda sig inför den nya dataskyddslagen {br}{br} Guiden ger information om hur företag ska följa kraven som den nya lagstiftningen ställer när det gäller till exempel kundregister, bokningssystem, och e-post. Guiden är framtagen för verksamt.se av Datainspektionen och Tillväxtverket.'
  }
});

export const msgEmailConfig = defineMessages({
  confirmation: {
    id: 'account-config.email-config.EmailConfig.confirmation',
    defaultMessage: 'Bokningsbekräftelse'
  },
  extra: {
    id: 'account-config.email-config.EmailConfig.extra',
    defaultMessage: 'Extra information'
  },
  emailOnBookOnline: {
    id: 'account-config.email-config.EmailConfig.emailOnBookOnline',
    defaultMessage: 'Skicka e-post vid bokning online'
  },
  emailOnCancelOnline: {
    id: 'account-config.email-config.EmailConfig.emailOnCancelOnline',
    defaultMessage: 'Skicka e-post vid avbokning online'
  },
  defaultOptionEmailConfirm: {
    id: 'account-config.email-config.EmailConfig.defaultOptionEmailConfirm',
    defaultMessage: 'Kryssa i e-postbekräftelse vid bokning/avbokning i kalendern'
  },
  extraText: {
    id: 'account-config.email-config.EmailConfig.extraText',
    defaultMessage: 'Lägg till extra information i bokningsbekräftelse, som till exempel öppettider eller avbokningsregler.'
  }
});

export const msgFeature = defineMessages({
  lblEnabled: {
    id: 'account-config.feature-config.Feature.lblEnabled',
    defaultMessage: 'Aktiverad'
  },
  btnTurnOff: {
    id: 'account-config.feature-config.Feature.btnTurnOff',
    defaultMessage: 'Stäng av'
  },
  btnTurnOn: {
    id: 'account-config.feature-config.Feature.btnTurnOn',
    defaultMessage: 'Aktivera'
  },
  contentEnable: {
    id: 'account-config.feature-config.Feature.contentEnable',
    defaultMessage: 'Vill du aktivera funktionen <b>{title}</b>? Du kan när som helst stänga av det igen.'
  },
  contentDisable: {
    id: 'account-config.feature-config.Feature.contentDisable',
    defaultMessage: 'Vill du stänga av funktionen <b>{title}</b>? Du kan när som helst aktivera det igen.'
  }
});

export const msgFeatureConfig = defineMessages({
  titleEnableStripePayments: {
    id: 'account-config.feature-config.FeatureConfig.titleEnableStripePayments',
    defaultMessage: 'Kortbetalning'
  },
  descriptionEnableStripePayments: {
    id: 'account-config.feature-config.FeatureConfig.descriptionEnableStripePayments',
    defaultMessage: 'Aktivera kortbetalning för att ta betalt av kunder vid bokning. Vänligen kontakta support för mer information.'
  },
  titleEnableCompanyBooking: {
    id: 'account-config.feature-config.FeatureConfig.titleEnableCompanyBooking',
    defaultMessage: 'Företagsbokning'
  },
  descriptionEnableCompanyBooking: {
    id: 'account-config.feature-config.FeatureConfig.descriptionEnableCompanyBooking',
    defaultMessage: 'Aktivera företagsbokning för att samla in och använda organisationsnummer samt företagsnamn i bokningen.'
  },
  titleEnableVehicleBooking: {
    id: 'account-config.feature-config.FeatureConfig.titleEnableVehicleBooking',
    defaultMessage: 'Fordonsbokning'
  },
  descriptionEnableVehicleBooking: {
    id: 'account-config.feature-config.FeatureConfig.descriptionEnableVehicleBooking',
    defaultMessage: 'Aktivera fordonsbokning för att samla in och använda registreringsnummer och fordonsinformation i bokningen.'
  },
  titleEnableVehicleRegistrySearch: {
    id: 'account-config.feature-config.FeatureConfig.titleEnableVehicleRegistrySearch',
    defaultMessage: 'Sökning i bilregistret'
  },
  descriptionEnableVehicleRegistrySearch: {
    id: 'account-config.feature-config.FeatureConfig.descriptionEnableVehicleRegistrySearch',
    defaultMessage: 'Sök ägare och fordonsuppgifter direkt i bilregistret. Extra kostnad tillkommer, vänligen kontakta support för mer information.'
  }
});

export const msgGeneralForm = defineMessages({
  lblCompanyName: {
    id: 'account-config.general.GeneralForm.lblCompanyName.',
    defaultMessage: 'Företagsnamn'
  },
  lblContactNumber: {
    id: 'account-config.general.GeneralForm.lblContactNumber',
    defaultMessage: 'Telefonnummer'
  },
  lblCalendarLines: {
    id: 'account-config.general.GeneralForm.lblCalendarLines',
    defaultMessage: 'Linjer i kalender'
  },
  lblComfirmMove: {
    id: 'account-config.general.GeneralForm.lblComfirmMove',
    defaultMessage: 'Bekräfta flytt av bokning'
  },
  lblManualSort: {
    id: 'account-config.general.GeneralForm.lblManualSort',
    defaultMessage: 'Sortera tjänster manuellt'
  },
  lblSettingDeviceGroup: {
    id: 'account-config.general.GeneralForm.lblSettingDeviceGroup',
    defaultMessage: 'Inställningar för denna enhet'
  },
  lblHighContrast: {
    id: 'account-config.general.GeneralForm.lblHighContrast',
    defaultMessage: 'Ökad kontrast mellan bokningar och schema'
  },
  lblGridSizeLarge: {
    id: 'account-config.general.GeneralForm.lblGridSizeLarge',
    defaultMessage: 'Ökad kalenderstorlek (för stora skärmar)'
  },
  lblExternalKeyboard: {
    id: 'account-config.general.GeneralForm.lblExternalKeyboard',
    defaultMessage: 'Externt tangentbord (gäller endast surfplattor)'
  },
  featuresMesssage: {
    id: 'account-config.general.GeneralForm.featuresMesssage',
    defaultMessage: 'Informationen visas när kunder bokar online, och i bokningsbekräftelser via mejl och sms'
  },
  countCalendarLines: {
    id: 'account-config.general.GeneralForm.countCalendarLines',
    defaultMessage: '{itemCount, number} {itemCount, plural, one {linje} other {linjer}}'
  },
  perHour: {
    id: 'account-config.general.GeneralForm.perHour',
    defaultMessage: 'per timme'
  },
  perHourDescription: {
    id: 'account-config.general.GeneralForm.perHourExplanation',
    defaultMessage: 'Ställ in hur många linjer som visas per timme. T.ex. 6 linjer ger en linje var tionde minut'
  },
  confirmMoveDescription: {
    id: 'account-config.general.GeneralForm.confirmMoveDescription',
    defaultMessage: 'Detta innebär även möjlighet att skicka bekräftelse till kund via sms och/eller e-post. {br} Se flikarna sms och e-post för att sätta standardval för bekräftelser.'
  },
  manualSortDescription: {
    id: 'account-config.general.GeneralForm.manualSortDescription',
    defaultMessage: 'För att ändra ordning, gå till fliken Tjänster.'
  },
  footnotes: {
    id: 'account-config.general.GeneralForm.footnotes',
    defaultMessage: 'Dessa inställningar gäller endast för den här enheten, så det går att ha olika inställningar för {br} exempelvis en dator med stor skärm, en telefon eller en surfplatta med externt tangentbord. {br} Inställningarna nås även från användarmenyn i övre högra hörnet.'
  }
});

export const msgPaymentConfigForm = defineMessages({
  lblSellerName: {
    id: 'account-config.payment-config.PaymentConfigForm.lblSellerName',
    defaultMessage: 'Företagsnamn'
  },
  lblSellerVatNo: {
    id: 'account-config.payment-config.PaymentConfigForm.lblSellerVatNo',
    defaultMessage: 'Momsreg.nr'
  },
  lblWeb: {
    id: 'account-config.payment-config.PaymentConfigForm.lblWeb',
    defaultMessage: 'Webbplats'
  },
  lblEmail: {
    id: 'account-config.payment-config.PaymentConfigForm.lblEmail',
    defaultMessage: 'E-post'
  },
  lblLogoUrl: {
    id: 'account-config.payment-config.PaymentConfigForm.lblLogoUrl',
    defaultMessage: 'Adress till logotyp'
  },
  lblAddressLine: {
    id: 'account-config.payment-config.PaymentConfigForm.lblAddressLine',
    defaultMessage: 'Adress'
  },
  lblPostCode: {
    id: 'account-config.payment-config.PaymentConfigForm.lblPostCode',
    defaultMessage: 'Postnummer'
  },
  lblArea: {
    id: 'account-config.payment-config.PaymentConfigForm.lblArea',
    defaultMessage: 'Postort'
  },
  lblStatementDescriptor: {
    id: 'account-config.payment-config.PaymentConfigForm.lblStatementDescriptor',
    defaultMessage: 'Text på kontoutdrag'
  },
  lblChargeDescription: {
    id: 'account-config.payment-config.PaymentConfigForm.lblChargeDescription',
    defaultMessage: 'Beskrivning för bokföring'
  },
  lblVatPct: {
    id: 'account-config.payment-config.PaymentConfigForm.lblVatPct',
    defaultMessage: 'Momssats'
  },
  lblPricesExVat: {
    id: 'account-config.payment-config.PaymentConfigForm.lblPricesExVat',
    defaultMessage: 'Priser anges exklusive moms'
  },
  sellerInformation: {
    id: 'account-config.payment-config.PaymentConfigForm.sellerInformation',
    defaultMessage: 'Uppgifterna visas i sidfoten på kvitton. Företagsnamn och momsreg.nr måste anges.'
  },
  logoUrlInformation: {
    id: 'account-config.payment-config.PaymentConfigForm.logoUrlInformation',
    defaultMessage: 'Logotypen måste vara i JPG- eller PNG-format och bör vara minst 500 pixlar bred. {br} Om ingen logotyp anges visas Dashls logotyp.'
  },
  addressInformation: {
    id: 'account-config.payment-config.PaymentConfigForm.addressInformation',
    defaultMessage: 'Adressen där företaget är registerat, visas i sidfoten på kvitton.'
  },
  statementInformation: {
    id: 'account-config.payment-config.PaymentConfigForm.statementInformation',
    defaultMessage: 'Visas på kundens kontoutdrag. Max 22 tecken, kan inte innehålla specialtecken som {lt}, {gt}, {guot}, {apos}, och får inte innehålla endast siffror.'
  }
});

export const msgSmsConfig = defineMessages({
  notAvailable: {
    id: 'account-config.sms-config.SmsConfig.notAvailable',
    defaultMessage: 'SMS-inställningar är inte tillgängligt för ditt abonnemang'
  },
  notActivated: {
    id: 'account-config.sms-config.SmsConfig.notActivated',
    defaultMessage: 'SMS är inte aktiverat för kontot'
  },
  hoursBefore: {
    id: 'account-config.sms-config.SmsConfig.hoursBefore',
    defaultMessage: 'timmar innan'
  },
  lblSender: {
    id: 'account-config.sms-config.SmsConfig.lblSender',
    defaultMessage: 'Avsändare'
  },
  lblConfirmation: {
    id: 'account-config.sms-config.SmsConfig.lblConfirmation',
    defaultMessage: 'Bokningsbekräftelse'
  },
  lblSmsOnBookOnline: {
    id: 'account-config.sms-config.SmsConfig.lblSmsOnBookOnline',
    defaultMessage: 'Skicka SMS vid bokning online ({smsConfirmPrice})'
  },
  lblSmsOnCancelOnline: {
    id: 'account-config.sms-config.SmsConfig.lblSmsOnCancelOnline',
    defaultMessage: 'Skicka SMS vid avbokning online ({smsConfirmPrice})'
  },
  lblDefaultOptionSmsConfirm: {
    id: 'account-config.sms-config.SmsConfig.lblDefaultOptionSmsConfirm',
    defaultMessage: 'Kryssa i SMS-bekräftelse vid bokning/avbokning i kalendern'
  },
  lblSmsReminderWithin: {
    id: 'account-config.sms-config.SmsConfig.lblSmsReminderWithin',
    defaultMessage: 'Skicka påminnelse innan bokning'
  },
  lblReminderSendTimes: {
    id: 'account-config.sms-config.SmsConfig.lblReminderSendTimes',
    defaultMessage: 'Skicka påminnelser under dessa tider'
  },
  lblSmsConfirmTemplate: {
    id: 'account-config.sms-config.SmsConfig.lblSmsConfirmTemplate',
    defaultMessage: 'Text i bekräftelse'
  },
  lblSmsReminderTemplate: {
    id: 'account-config.sms-config.SmsConfig.lblSmsReminderTemplate',
    defaultMessage: 'Text i påminnelse'
  },
  senderDescription: {
    id: 'account-config.sms-config.SmsConfig.senderDescription',
    defaultMessage: 'Max 11 tecken, enbart bokstäver (ej åäö), siffror och understreck (_) {br} Kan även vara ett telefonnummer som mottagaren kan svara till'
  }
});

export const msgStorageConfigForm = defineMessages({
  alert: {
    id: 'account-config.storage-config.StorageConfigForm.alert',
    defaultMessage: 'Den 25 maj börjar den nya dataskyddsförordningen (GDPR) gälla. Därför har vi lagt till nya inställningar för att hantera lagring av personuppgifter. Det enda ni behöver göra är att kontrollera och godkänna inställningarna nedan, vi sköter resten!'
  },
  alertFooter: {
    id: 'account-config.storage-config.StorageConfigForm.alertFooter',
    defaultMessage: '<b>Vad händer nu?</b> {br} Automatisk gallring av uppgifter kommer utföras varje natt enligt inställningarna ovan {br}{br} För att ni ska känna er trygga så kommer vi under en övergångsperiod lagra backuper av er data.'
  },
  explanationFirst: {
    id: 'account-config.storage-config.StorageConfigForm.explanationFirst',
    defaultMessage: 'För att uppfylla kraven i dataskyddsförordningen (GDPR) får personuppgifter inte sparas längre än nödvändigt. Personuppgifter måste gallras bort efter en viss tid om det inte längre finns nån laglig grund för lagringen.'
  },
  explanationSecond: {
    id: 'account-config.storage-config.StorageConfigForm.explanationSecond',
    defaultMessage: 'Observera att vårdgivare inom hälso- och sjukvården regleras av bestämmelser i patientdatalagen som har andra krav på lagring än GDPR.'
  },
  explanationThird: {
    id: 'account-config.storage-config.StorageConfigForm.explanationThird',
    defaultMessage: 'Läs mer om GDPR och gällande villkor under'
  },
  explanationLink: {
    id: 'account-config.storage-config.StorageConfigForm.explanationLink',
    defaultMessage: 'Kontakter & villkor'
  },
  lblAutoPurgeEnabled: {
    id: 'account-config.storage-config.StorageConfigForm.lblAutoPurgeEnabled',
    defaultMessage: 'Aktivera automatisk gallring av personuppgifter'
  },
  lblPurgeLogsEnabled: {
    id: 'account-config.storage-config.StorageConfigForm.lblPurgeLogsEnabled',
    defaultMessage: 'Aktivera gallring av anteckningar i kundloggen'
  },
  lblBookingMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblBookingMaxAge',
    defaultMessage: 'Radera bokningar'
  },
  lblAfterBookingMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblAfterBookingMaxAge',
    defaultMessage: 'efter att tiden för bokningen har passerats'
  },
  descriptionBookingMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.descriptionBookingMaxAge',
    defaultMessage: 'Bokningar visas i kalendern och används även för kundhistorik och rapporter'
  },
  lblCustomerLogMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblCustomerLogMaxAge',
    defaultMessage: 'Radera anteckningar i kundloggen'
  },
  lblAfterCustomerLogMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblAfterCustomerLogMaxAge',
    defaultMessage: 'efter att anteckningen har lagts till eller ändrats'
  },
  descriptionCustomerLogMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.descriptionCustomerLogMaxAge',
    defaultMessage: 'Obs! Den här inställningen raderar anteckningar i kundloggen. Om du har t.ex. recept som lagts till för länge sedan så kan dessa raderas även om kunden varit bokad nyligen'
  },
  lblInactiveCustomerMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblInactiveCustomerMaxAge',
    defaultMessage: 'Radera kunduppgifter senast'
  },
  lblAfterInactiveCustomerMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.lblAfterInactiveCustomerMaxAge',
    defaultMessage: '{purgeLogsEnabled, select, true {efter att den sista bokningen eller anteckningen raderats} other {efter att kundens sista bokning har raderats}}'
  },
  descriptionInactiveCustomerMaxAge: {
    id: 'account-config.storage-config.StorageConfigForm.descriptionInactiveCustomerMaxAge',
    defaultMessage: '{purgeLogsEnabled, select, true {En kund raderas aldrig så länge det finns bokningar eller anteckningar i kundloggen} other {En kund raderas aldrig om det finns en bokning kvar i systemet}}'
  },
  btnSubmit: {
    id: 'account-config.storage-config.StorageConfigForm.btnSubmit',
    defaultMessage: '{preferencesUpdated, select, true {Spara ändringar} other {Godkänn inställningar}}'
  }
});

export const msgWebBookingInfo = defineMessages({
  legendShowBookingSite: {
    id: 'account-config.web-booking.WebBookingInfo.legendShowBookingSite',
    defaultMessage: 'Länk till webbokning (<a>visa webbokning</a>)'
  },
  textShowBookingSite: {
    id: 'account-config.web-booking.WebBookingInfo.textShowBookingSite',
    defaultMessage: 'Kopiera denna länk för att öppna bokningen i ett eget fönster'
  },
  legendShowBookingWidget: {
    id: 'account-config.web-booking.WebBookingInfo.legendShowBookingWidget',
    defaultMessage: 'Integrera webbokning på hemsida'
  },
  textShowBookingWidget: {
    id: 'account-config.web-booking.WebBookingInfo.textShowBookingWidget',
    defaultMessage: 'Kopiera denna kod och klistra in på er hemsida där bokningen ska visas'
  },
  legendShowBookingIntegration: {
    id: 'account-config.web-booking.WebBookingInfo.ShowBookingIntegration',
    defaultMessage: 'Integration'
  },
  textShowBookingIntegration: {
    id: 'account-config.web-booking.WebBookingInfo.textShowBookingIntegration',
    defaultMessage: 'Detta ID används för integration med till exempel klipptid.nu'
  }
});

export const msgWebBookingForm = defineMessages({
  groupAlgorithm: {
    id: 'account-config.web-booking.WebBookingForm.groupAlgorithm',
    defaultMessage: 'Algoritm'
  },
  groupFeatures: {
    id: 'account-config.web-booking.WebBookingForm.groupFeatures',
    defaultMessage: 'Funktioner'
  },
  groupCustomerDetails: {
    id: 'account-config.web-booking.WebBookingForm.groupCustomerDetails',
    defaultMessage: 'Kunduppgifter'
  },
  groupMessage: {
    id: 'account-config.web-booking.WebBookingForm.groupMessage',
    defaultMessage: 'Meddelande'
  },
  groupLinkToExisting: {
    id: 'account-config.web-booking.WebBookingForm.groupLinkToExisting',
    defaultMessage: 'Koppla ny bokning till befintlig kund'
  },
  lblMinTimeBeforeBooking: {
    id: 'account-config.web-booking.WebBookingForm.lblMinTimeBeforeBooking',
    defaultMessage: 'Tillåt bokning senast'
  },
  lblBookingMaxDaysInAdvance: {
    id: 'account-config.web-booking.WebBookingForm.lblBookingMaxDaysInAdvance',
    defaultMessage: 'Tillåt bokning upp till'
  },
  lblAllowCancellation: {
    id: 'account-config.web-booking.WebBookingForm.lblAllowCancellation',
    defaultMessage: 'Tillåt avbokning via webben'
  },
  lblCancelUpToHours: {
    id: 'account-config.web-booking.WebBookingForm.lblCancelUpToHours',
    defaultMessage: 'Avbokning senast'
  },
  lblResourceLabel: {
    id: 'account-config.web-booking.WebBookingForm.lblResourceLabel',
    defaultMessage: 'Benämning på resurs'
  },
  lblUseSmsVerification: {
    id: 'account-config.web-booking.WebBookingForm.lblUseSmsVerification',
    defaultMessage: 'Bekräfta bokning med SMS-kod ({smsPinPrice})'
  },
  lblAllowMultipleServices: {
    id: 'account-config.web-booking.WebBookingForm.lblAllowMultipleServices',
    defaultMessage: 'Tillåt bokning av flera tjänster'
  },
  lblAutoResourceSelection: {
    id: 'account-config.web-booking.WebBookingForm.lblAutoResourceSelection',
    defaultMessage: 'Välj resurs automatiskt'
  },
  lblAlwaysShowDescription: {
    id: 'account-config.web-booking.WebBookingForm.lblAlwaysShowDescription',
    defaultMessage: 'Visa alltid beskrivning för tjänster'
  },
  lblCollapseGroups: {
    id: 'account-config.web-booking.WebBookingForm.lblCollapseGroups',
    defaultMessage: 'Kollapsa grupper (tjänster)'
  },
  lblRequirePno: {
    id: 'account-config.web-booking.WebBookingForm.lblRequirePno',
    defaultMessage: 'Personnummer måste anges vid bokning'
  },
  lblRequireEmail: {
    id: 'account-config.web-booking.WebBookingForm.lblRequireEmail',
    defaultMessage: 'E-post måste anges vid bokning'
  },
  lblHideNotes: {
    id: 'account-config.web-booking.WebBookingForm.lblHideNotes',
    defaultMessage: 'Dölj meddelandefältet'
  },
  lblRequireNotes: {
    id: 'account-config.web-booking.WebBookingForm.lblRequireNotes',
    defaultMessage: 'Meddelande måste anges vid bokning'
  },
  lblCustomMessageLabel: {
    id: 'account-config.web-booking.WebBookingForm.lblCustomMessageLabel',
    defaultMessage: 'Rubrik'
  },
  lblMessageExtraInfo: {
    id: 'account-config.web-booking.WebBookingForm.lblMessageExtraInfo',
    defaultMessage: 'Beskrivning'
  },
  lblBookingExtraInfo: {
    id: 'account-config.web-booking.WebBookingForm.lblBookingExtraInfo',
    defaultMessage: 'Villkor / Extra information'
  },
  lblShowTCBox: {
    id: 'account-config.web-booking.WebBookingForm.lblShowTCBox',
    defaultMessage: 'Bokningsvillkor måste godkännas'
  },
  lblShowExtraInfoOnConfirmPage: {
    id: 'account-config.web-booking.WebBookingForm.lblShowExtraInfoOnConfirmPage',
    defaultMessage: 'Visa även i steget efter att bokningen slutförts'
  },
  lblMatchingPhoneNumber: {
    id: 'account-config.web-booking.WebBookingForm.lblMatchingPhoneNumber',
    defaultMessage: 'Om telefonnummer matchar'
  },
  lblMatchingPhoneNumberAndName: {
    id: 'account-config.web-booking.WebBookingForm.lblMatchingPhoneNumberAndName',
    defaultMessage: 'Om både telefonnummer och namn matchar'
  },
  minutesBefore: {
    id: 'account-config.web-booking.WebBookingForm.minutesBefore',
    defaultMessage: 'minuter innan'
  },
  daysBefore: {
    id: 'account-config.web-booking.WebBookingForm.daysBefore',
    defaultMessage: 'dagar innan'
  },
  hoursBefore: {
    id: 'account-config.web-booking.WebBookingForm.hoursBefore',
    defaultMessage: 'timmar innan'
  },
  descriptionResourceLabel: {
    id: 'account-config.web-booking.WebBookingForm.descriptionResourceLabel',
    defaultMessage: 'Ändra benämningen på resurser i webbokningen, t.ex. om du vill att det ska stå Frisör istället för Person'
  },
  descriptionAutoResourceSelection: {
    id: 'account-config.web-booking.WebBookingForm.descriptionAutoResourceSelection',
    defaultMessage: 'Gömmer valet av resurs och väljer automatiskt en resurs som är ledig vid vald tid'
  },
  descriptionAlwaysShowDescription: {
    id: 'account-config.web-booking.WebBookingForm.descriptionAlwaysShowDescription',
    defaultMessage: 'Tar bort möjligheten att gömma beskrivningen för tjänster'
  },
  descriptionCollapseGroups: {
    id: 'account-config.web-booking.WebBookingForm.descriptionCollapseGroups',
    defaultMessage: 'Döljer tjänsterna i grupperna tills användaren väljer att visa dem, om du har många tjänster så får man en bättre överblick med kollapsade grupper.'
  },
  descriptionCustomMessageLabel: {
    id: 'account-config.web-booking.WebBookingForm.descriptionCustomMessageLabel',
    defaultMessage: 'Ändra benämningen på meddelandefältet i webbokningen, t.ex. "Särskilda önskemål"'
  },
  descriptionMessageExtraInfo: {
    id: 'account-config.web-booking.WebBookingForm.descriptionMessageExtraInfo',
    defaultMessage: 'Lägg till information ovanför meddelandefältet, t.ex. instruktioner om vad som ska fyllas i'
  },
  descriptionBookingExtraInfo: {
    id: 'account-config.web-booking.WebBookingForm.descriptionBookingExtraInfo',
    defaultMessage: 'Lägg till en text i sista steget på bokningen, t.ex. avbokningsregler och villkor'
  },
  descriptionShowTCBox: {
    id: 'account-config.web-booking.WebBookingForm.descriptionShowTCBox',
    defaultMessage: 'Lägger till en ruta med texten "Jag godkänner villkoren" som måste bockas i för att kunna boka'
  }
});
