import React from 'react';
import { useIntl } from 'react-intl';
import PhoneUtil from '../../../utils/phone-util';

import { msgConfirmCancel as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

const ConfirmCancel = ({ onCancel, onConfirm, progress, customer, locationConfig, smsEnabled, isSysAdmin, isPaid, payment }) => {
  const { formatMessage: f } = useIntl();
  const { phoneNumber, email } = customer || {};
  const { defaultOptionSmsConfirm, defaultOptionEmailConfirm } = locationConfig.toJS();
  const showConfirmation = (phoneNumber && smsEnabled) || email;

  return (
    <form onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{f(msg.question)}</strong>
      </p>
      {showConfirmation && (
        <div>
          <p>
            <strong>{f(msg.sendConfirmationTo)}</strong>
          </p>
          {phoneNumber && smsEnabled && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyBySms" value="true" defaultChecked={defaultOptionSmsConfirm} /> {PhoneUtil.formatPhoneNumber(phoneNumber)}
              </label>
            </div>
          )}
          {email && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyByEmail" value="true" defaultChecked={defaultOptionEmailConfirm} /> {email}<br />
              </label>
            </div>
          )}
        </div>
      )}
      {(isSysAdmin || !customer) && (
        <div className="checkbox">
          <label>
            <input type="checkbox" name="deleteBooking" value="true" /> {f(msg.deleteBooking)}
          </label>
        </div>
      )}
      {isPaid && payment.amount > 0 && (
        <div className="checkbox">
          <label>
            <input type="checkbox" name="refund" value="true" /> {f(msg.refundPayment)}
          </label>
        </div>
      )}
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{f(msgButtons.btnCancel)}</button>{' '}
        <button type="submit" className="btn-save" tabIndex="3">{progress ? f(msg.btnSubmitting) : f(msg.btnSubmit)}</button>
      </div>
    </form>
  );
};

export default ConfirmCancel;
