var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <hr>\n   <form class=\"compact-form\">\n      <div class=\"form-group\">\n         <textarea class=\"form-control js-comment\" rows=\"5\"></textarea>\n      </div>\n      <a href=\"#\" class=\"btn-cancel pull-right js-cancel\"><span>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</span></a>\n      <button type=\"submit\" class=\"btn-save js-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"saveNote",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n   </form>\n</div>\n";
},"useData":true});