import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSmsEnabled } from '../../../utils/selectors';
import { getLongestName, templatePreviewValues } from '../../../utils/templates';
import { getSmsPreferences, saveSmsPreferences } from '../../../state/preferences-actions';
import SmsConfigForm from './sms-config-form';
import FeatureNotAvailable from '../../dialogs/feature-not-available';
import { msgSmsConfig as msg } from '../../../localization/messages/components/account-config';

class SmsConfig extends Component {

  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getSmsConfig();
    }
  }

  render() {
    const features = this.props.locationFeatures.toJS();
    const { intl: { formatMessage: f } } = this.props;

    if (features.EnableSms) {
      return this.renderFeature();
    }

    return <FeatureNotAvailable leadText={f(msg.notAvailable)} showLink />;
  }

  renderFeature() {
    return this.props.smsEnabled ?
      this.renderConfigForm() :
      this.renderSmsDisabledInfo();
  }

  renderConfigForm() {
    const { smsConfirmPrice, locationFeatures, preferences, previewValues } = this.props;
    const initialValues = preferences ? preferences.toJS() : null;

    return (<SmsConfigForm
      initialValues={initialValues}
      smsConfirmPrice={smsConfirmPrice}
      features={locationFeatures.toJS()}
      previewValues={previewValues}
      onSubmit={this.submitForm}
    />);
  }

  renderSmsDisabledInfo() {
    const { intl: { formatMessage: f } } = this.props;
    return <FeatureNotAvailable leadText={f(msg.notActivated)} showLink />;
  }

  submitForm = (values) => {
    return this.props.saveSmsConfig(values);
  };
}

SmsConfig.propTypes = {
  locationFeatures: PropTypes.object.isRequired,
  smsEnabled: PropTypes.bool.isRequired,
  preferences: PropTypes.object
};

const mapStateToProps = (state) => {
  const { preferencesViewState, locationConfig, locationFeatures, resourcesById, servicesById } = state;

  const smsConfirmPrice = locationConfig.get('smsPrices').find((smsPrice) => {
    return smsPrice.get('priceType') === 'Confirm';
  });

  const resourceName = getLongestName(resourcesById);
  const serviceName = getLongestName(servicesById);
  const previewValues = templatePreviewValues({ resourceName, serviceName });

  return {
    preferences: preferencesViewState.get('smsPreferences'),
    locationFeatures,
    smsConfirmPrice: smsConfirmPrice ? smsConfirmPrice.get('price') : null,
    smsEnabled: getSmsEnabled(state),
    previewValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSmsConfig: () => {
      dispatch(getSmsPreferences());
    },
    saveSmsConfig: (data) => {
      return dispatch(saveSmsPreferences(data));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SmsConfig));
