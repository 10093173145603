
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSmsEnabled } from '../../utils/selectors';
import PhoneUtil from '../../../utils/phone-util';

import { msgConfirmMove as msg } from '../../localization/messages/components/grid';
import { msgButtons } from '../../localization/messages/shared/buttons';

class MoveResize extends React.Component {

  static propTypes = {

    startTime: PropTypes.object.isRequired,
    customerEmail: PropTypes.string,
    customerPhoneNumber: PropTypes.string,
    copyOnPaste: PropTypes.bool,

    enableSmsConfirmations: PropTypes.bool.isRequired,
    enableEmailConfirmations: PropTypes.bool.isRequired,
    defaultOptionSmsConfirm: PropTypes.bool.isRequired,
    defaultOptionEmailConfirm: PropTypes.bool.isRequired,

    onClosePopover: PropTypes.func.isRequired,
    onConfirmMove: PropTypes.func.isRequired

  };

  constructor(props) {
    super(props);
    this.state = {
      sendSmsConfirmation: props.smsEnabled && props.defaultOptionSmsConfirm,
      sendEmailConfirmation: props.defaultOptionEmailConfirm
    };
  }

  capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleChangeField = field => (ev) => {
    this.setState({ [field]: ev.target.checked });
  }

  handleConfirmMove = (ev) => {
    ev.preventDefault();
    const { sendSmsConfirmation, sendEmailConfirmation } = this.state;
    this.props.onConfirmMove({
      sendSmsConfirmation: this.shouldRenderSms() && sendSmsConfirmation,
      sendEmailConfirmation: this.shouldRenderEmail() && sendEmailConfirmation
    });
  };

  render() {
      // These comes from ownProps
    const { startTime, customerEmail, customerPhoneNumber, intl: { formatMessage: f } } = this.props;
    const { sendSmsConfirmation, sendEmailConfirmation } = this.state;

    const newTime = startTime.format('LLLL');
    const verbLc = this.props.copyOnPaste ? f(msg.copy) : f(msg.move);

    return (
      <div className="Popover-content">
        <div className="booking-form-block  nopointer">
          <p><strong>{f(msg.question, { verbLc, newTime })}</strong></p>

          {this.shouldRenderSms() && (
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={sendSmsConfirmation} onChange={this.handleChangeField('sendSmsConfirmation')} />
              {f(msg.sendSmsTo, { customerPhoneNumber: PhoneUtil.formatPhoneNumber(customerPhoneNumber) })}
            </label>
          </div>)}

          {this.shouldRenderEmail() && (
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={sendEmailConfirmation} onChange={this.handleChangeField('sendEmailConfirmation')} />
              {f(msg.sendEmailTo, { customerEmail })}
            </label>
          </div>)}
        </div>

        <div className="text-right">
          <button type="button" className="btn btn-default" onClick={this.props.onClosePopover}>{f(msgButtons.btnCancel)}</button> &nbsp;
          <button type="button" className="btn btn-success" onClick={this.handleConfirmMove}>{this.capitalizeFirstLetter(verbLc)}</button>
        </div>
      </div>
    );
  }

  shouldRenderSms() {
    return this.props.customerPhoneNumber && this.props.enableSmsConfirmations;
  }

  shouldRenderEmail() {
    return this.props.customerEmail && this.props.enableEmailConfirmations;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, locationFeatures } = state;
  const smsEnabled = getSmsEnabled(state);
  return {
    ...ownProps,
    smsEnabled,
    enableEmailConfirmations: locationFeatures.get('EnableEmailConfirmations'),
    enableSmsConfirmations: smsEnabled && locationFeatures.get('EnableSmsConfirmations'),
    defaultOptionSmsConfirm: locationConfig.get('defaultOptionSmsConfirm'),
    defaultOptionEmailConfirm: locationConfig.get('defaultOptionEmailConfirm')
  };
};

export default injectIntl(connect(mapStateToProps)(MoveResize));
