var View = require('../../base/view');

module.exports = View.extend({
   noWrap: true,
   autoRender: true,

   initialize: function() {
      this.template = require('./timeline-item');
      View.prototype.initialize.call(this, arguments);
   },

   itemClick: function(e) {
      e.preventDefault();
      this.publishEvent('schedule:select', this.model);
   },

   render: function(){
      View.prototype.render.call(this, arguments);
      this.delegate('click', this.itemClick);
   }
});
