import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { getSmsEnabled, getIsSysAdmin } from '../../utils/selectors';
import { formatPhoneNumberE164 } from '../../../utils/phone-util';
import { cancelBooking, deleteBooking, sendBookingConfirmation, sendBookingReceipt, markBookingAsPaid } from '../../state/booking-actions';
import { addToClipboard } from '../../state/clipboard-actions';
import { clearAndCloseBKF, toggleBKFPrintModal } from '../../state/bkf-actions';
import ConfirmDelete from './confirm-delete';
import ConfirmCancel from './confirm-cancel';
import MarkAsPaid from './mark-as-paid';
import SendConfirmation from './send-confirmation';
import SendReceipt from './send-receipt';

import { msgBookingOptions as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'Menu',
      progress: false
    };
  }

  deleteBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    this.props.deleteBooking(this.props.id)
      .catch(() => this.setState({ progress: false }));
  };

  showView = view => (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view });
  }

  cancelBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      notifyBySms: form.notifyBySms && form.notifyBySms.checked,
      notifyByEmail: form.notifyByEmail && form.notifyByEmail.checked,
      deleteBooking: form.deleteBooking && form.deleteBooking.checked,
      refund: form.refund && form.refund.checked
    };
    this.setState({ progress: true });
    this.props.cancelBooking({ bookingId: this.props.id, options })
      .catch(() => this.setState({ progress: false }));
  };

  sendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      sms: form.sms && form.sms.checked,
      email: form.email && form.email.checked
    };

    if (!options.sms && !options.email) return;

    this.setState({ progress: true });
    this.props.sendBookingConfirmation({ bookingId: this.props.id, options })
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  sendReceipt = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const data = {
      paymentRef: this.props.payment.paymentRef,
      toName: form.name && form.name.value,
      toEmail: form.email && form.email.value
    };

    this.setState({ progress: true });
    this.props.sendBookingReceipt(data)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  markAsPaid = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    const { payment, id } = this.props;

    const data = {
      bookingId: id,
      paymentId: payment && payment.paymentId,
      paymentStatus: 'Paid'
    };

    this.setState({ progress: true });
    this.props.markBookingAsPaid(data)
      .catch(() => this.setState({ progress: false }));
  }

  printBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.printBooking(this.props.id);
    this.props.onClose();
  };

  copyBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.copyBooking(this.props.id);
  };

  moveBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.moveBooking(this.props.id);
  };

  onClose = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onClose();
  };

  renderContent() {
    const { view, progress } = this.state;
    const { startTime, status, type, customerDeleted, customer, intl: { formatMessage: f },
      payment, locationConfig, smsEnabled, isSysAdmin, isDashlBooking, service } = this.props;
    const isBooking = type === 'SimpleBooking';
    const isReservation = type === 'Reservation';
    const isCancelled = status === 'Cancelled';
    const isPastBooking = isBooking && moment().isAfter(startTime);
    const isLocked = isCancelled || isPastBooking || customerDeleted;
    const canDelete = isPastBooking || isCancelled;
    const isPaid = payment && payment.paymentStatus === 'Paid';

    if (isReservation) {
      return <ConfirmDelete onConfirm={this.deleteBooking} onCancel={this.onClose} progress={progress} />;
    }
    if (view === 'ConfirmDelete') {
      return <ConfirmDelete onConfirm={this.deleteBooking} onCancel={this.showView('Menu')} progress={progress} />;
    }
    if (view === 'ConfirmCancel') {
      return (<ConfirmCancel
        onConfirm={this.cancelBooking}
        onCancel={this.showView('Menu')}
        progress={progress}
        customer={customer}
        locationConfig={locationConfig}
        smsEnabled={smsEnabled}
        isSysAdmin={isSysAdmin}
        isPaid={isPaid}
        payment={payment}
      />);
    }
    if (view === 'SendConfirmation') {
      return (<SendConfirmation
        onConfirm={this.sendConfirmation}
        onCancel={this.showView('Menu')}
        progress={progress}
        customer={customer}
        locationConfig={locationConfig}
        smsEnabled={smsEnabled}
      />);
    }
    if (view === 'SendReceipt') {
      return (<SendReceipt
        onConfirm={this.sendReceipt}
        onCancel={this.showView('Menu')}
        progress={progress}
        customer={customer}
        locationConfig={locationConfig}
        smsEnabled={smsEnabled}
      />);
    }
    if (view === 'MarkAsPaid') {
      return (<MarkAsPaid
        onConfirm={this.markAsPaid}
        onCancel={this.showView('Menu')}
        progress={progress}
        locationConfig={locationConfig}
      />);
    }

    return (
      <div>
        {isPastBooking && <p className="text-muted"><em>{f(msg.isPastBooking)}</em></p>}
        {customerDeleted && <p className="text-muted"><em>{f(msg.customerDeleted)}</em></p>}

        {isBooking && (
          <Fragment>
            {!isLocked && <button className="btn-option" onClick={this.showView('SendConfirmation')}><i className="fa fa-send" /> {f(msg.btnSendConfirmation)}</button>}
            {isPaid && !isDashlBooking && <button className="btn-option" onClick={this.showView('SendReceipt')}><i className="fa fa-file-text" /> {f(msg.btnSendReceipt)}</button>}
            {!isLocked && payment && !isPaid && service && <button className="btn-option" onClick={this.showView('MarkAsPaid')}><i className="fa fa-credit-card" /> {f(msg.btnMarkAsPaid)}</button>}
            {this.renderMobileButtons()}
            <p style={{ marginTop: 15 }} />
            <button className="btn-option" onClick={this.printBooking}><i className="fa fa-print" /> {f(msg.btnPrint)}</button>
            <button className="btn-option" onClick={this.copyBooking}><i className="fa fa-copy" /> {f(msg.btnCopy)}</button>
            {!isLocked && <button className="btn-option" onClick={this.moveBooking}><i className="fa fa-scissors" /> {f(msg.btnMove)}</button>}
            {!isLocked && <button className="btn-option btn-danger" onClick={this.showView('ConfirmCancel')}><i className="fa fa-user-times" /> {f(msg.btnCancel)}</button>}
          </Fragment>
        )}
        {canDelete && (isSysAdmin || !customer) && <button className="btn-option btn-danger" onClick={this.showView('ConfirmDelete')}><i className="fa fa-trash" /> {f(msg.btnDelete)}</button>}
        <p style={{ marginTop: 15 }} />
        <button className="btn-option" onClick={this.onClose}>{f(msgButtons.btnCancel)}</button>
      </div>
    );
  }

  renderMobileButtons() {
    const { deviceType, customer, intl: { formatMessage: f } } = this.props;
    const isMobile = deviceType === 'mobile';
    const phoneNumber = customer && customer.phoneNumber;
    const e164phoneNumber = formatPhoneNumberE164(phoneNumber);

    return isMobile ? (
      <Fragment>
        {phoneNumber && <a href={`tel:${e164phoneNumber}`} className="btn-option"><i className="fa fa-phone" /> {f(msg.callTo, { phoneNumber })}</a>}
      </Fragment>
    ) : null;
  }

  render() {
    const { intl: { formatMessage: f } } = this.props;

    return (
      <div className="booking-options-container">
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.onClose}><i className="fa fa-chevron-left" /> {f(msgButtons.btnBack)}</a>
          </div>
          <h4 className="title">{f(msg.alternative)}</h4>
          <div className="save" />
        </div>
        <div className="booking-options-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig, mainViewState } = state;

  return {
    locationConfig,
    id: bkf.get('id'),
    startTime: bkf.get('startTime'),
    customer: bkf.get('customer'),
    payment: bkf.get('payment'),
    service: bkf.get('service'),
    deviceType: mainViewState.get('deviceType'),
    customerDeleted: bkf.get('customerDeleted'),
    smsEnabled: getSmsEnabled(state),
    isSysAdmin: getIsSysAdmin(state),
    ...bkf.get('attributes')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendBookingConfirmation: data => dispatch(sendBookingConfirmation(data)),
    sendBookingReceipt: data => dispatch(sendBookingReceipt(data)),
    printBooking: () => dispatch(toggleBKFPrintModal(true)),
    copyBooking: (id) => {
      dispatch(addToClipboard(id, true));
      dispatch(clearAndCloseBKF());
    },
    moveBooking: (id) => {
      dispatch(addToClipboard(id, false));
      dispatch(clearAndCloseBKF());
    },
    markBookingAsPaid: (data) => {
      return dispatch(markBookingAsPaid(data))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    cancelBooking: (data) => {
      return dispatch(cancelBooking(data))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    deleteBooking: (data) => {
      return dispatch(deleteBooking(data))
        .then(() => dispatch(clearAndCloseBKF()));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BookingOptions));
