import Immutable from 'immutable';

import {
    AUTHENTICATING, AUTH_FAILED, USER_AUTHENTICATED, CONNECT_FAILED, NO_AUTH_TOKEN
} from './actions';

export default function authState(state = Immutable.Map({
   username: '',
   systemRole: '',
   authenticating: false,
   authenticated: false,
   lastTrymessage: '',
   networkFailed: false,
   authFailed: false,
   noAuthToken: false
}), action = null) {

   switch (action.type) {
      case NO_AUTH_TOKEN:
         return state.merge({
            noAuthToken: true,
            authenticating: false,
            authenticated: false,
            authFailed: false,
            networkFailed: true,
            lastTrymessage: action.response
         });

      case AUTHENTICATING:
         return state.merge({
            authenticating: true,
            authFailed: false
         });
      case AUTH_FAILED:
         return state.merge({
            authenticating: false,
            authenticated: false,
            authFailed: true,
            networkFailed: false
         });
      case USER_AUTHENTICATED:
         return state.merge({
            username: action.userSession.username,
            systemRole: action.userSession.systemRole,
            authenticating: false,
            authenticated: true,
            authFailed: false,
            networkFailed: false
         });
      case CONNECT_FAILED:
         return state.merge({
            authenticating: false,
            authenticated: false,
            authFailed: false,
            networkFailed: true,
            lastTrymessage: action.response
         });
      default :
         return state;
   }

}
