var ScheduleConfigView = require('../views/account-config/schedule/schedule-main-view');
var NotificationConfigView = require('../views/account-config/notifications/notifications-view');
var UsersConfigView = require('../views/account-config/users/users-main-view');
var AccountUsers = require('../collections/users');
var AccountSchedule = require('../models/account-schedule');
var AccountNotifications = require('../models/account-notifications');

module.exports = Chaplin.Controller.extend({

   show: function (params) {
      this.section = (params.section === undefined) ? "general" : params.section;

      this.showSection(this.section);
   },

   createScheduleConfigView: function () {
         this.accountSchedule = new AccountSchedule();
         this.accountSchedule.fetch({
            contentType: 'application/json; charset=utf-8',
            success: _.bind(this.renderScheduleConfigView, this)
         });
   },

   renderScheduleConfigView: function() {
      this.contentView = new ScheduleConfigView({model: this.accountSchedule, container: '#config-content-region'});
   },

   createNotificationConfigView: function () {
         this.accountNotificationsModel = new AccountNotifications();
         this.accountNotificationsModel.fetch({
            success: _.bind(this.renderNotificationConfigView, this)
         });
   },

   createUsersConfigView: function () {
         this.accoountUsersCollection = new AccountUsers();
         this.accoountUsersCollection.fetch({
            success: _.bind(this.renderUsersConfigView, this)
         });
   },

   renderUsersConfigView: function() {
      this.contentView = new UsersConfigView({collection: this.accoountUsersCollection, container: '#config-content-region'});
   },

   renderNotificationConfigView: function() {
      this.contentView = new NotificationConfigView({model: this.accountNotificationsModel, container: '#config-content-region'});
   },

   showSection: function (section) {
      this.section = section;

      if (this.contentView) {
         this.contentView.dispose();
         this.contentView = null;
      }

      switch(section) {
         case 'schedule':
            this.createScheduleConfigView();
            break;
         case 'users':
            this.createUsersConfigView();
            break;
         case 'notifications':
            this.createNotificationConfigView();
            break;
      }
   }
});
