var ResourceConfigView = require('./config-view');
var ResourceConfigNavView = require('./nav-view');
var ResourceScheduleMainView = require('./schedule/main-view');
var ResourceSchedule = require('../../models/resource-schedule');
var Navigate = require('../../redux-dashl/utils/navigate');

var ResourceConfigController = Chaplin.Controller.extend({

	initialize: function (options) {
      this.params = options;

		this.subscribeEvent('redux:event:DELETE_RESOURCE', this.resourceDeleted);
	},

   resourceDeleted: function () {
      this.contentView.close();
      this.view.cancel();

      Navigate.navigate(Navigate.getSectionUrl(this.params, 'calendar'));
   },

	open: function (options) {
      this.model = new Chaplin.Model({ id: options.id });
		this.section = (!options || !options.section) ? "settings" : options.section;
		if (!this.view) {
			this.createView();
		}
		else {
			this.view.$el.show();
			this.showSection(this.section);
		}
	},

	hide: function () {
		if (this.view) {
			this.view.$el.hide();
		}
	},

	createView: function (model, response, options) {
		this.view = new ResourceConfigView({
			model: this.model,
         container: '#chaplin-view-container'
		});

		var nav = new Chaplin.Model({
			items: [
				{viewId: 'schedule', viewName: 'Schema', hidden: true, createView: _.bind(this.createScheduleConfigView, this)}
			]
		});
      //
		this.navView = new ResourceConfigNavView({model: nav});
		this.showSection(this.section);
	},

	createScheduleConfigView: function () {
		if(!this.scheduleModel || this.scheduleModel.id !== this.model.id) {
			this.scheduleModel = new ResourceSchedule({id: this.model.id, resourceName:this.model.get('name')});
			this.scheduleModel.fetch({
				success: _.bind(this.renderScheduleConfigView, this)
			});
		}
		else {
			this.renderScheduleConfigView();
		}
	},

	renderScheduleConfigView: function() {
		this.contentView = new ResourceScheduleMainView({ model: this.scheduleModel, region: 'resource-config-content', params: this.params });
	},

	showSection: function (section) {
		this.section = section;

		var viewNavItem = _.find(this.navView.model.get('items'), function (item) {
			return item.viewId === this.section;
		}, this);

		if (!viewNavItem) {
			throw Error('Nav item not found for section: ', section);
		}

		if (this.contentView) {
			this.contentView.dispose();
			this.contentView = null;
		}
		viewNavItem.createView();
	}
});

module.exports = ResourceConfigController;
