import { fetchGet, checkStatus, prefixUrl, fetchErrorHandler } from '../utils/ajax-util';
import { loading, loadingDone } from './network-actions';

export const GET_BOOKING_REPORT = 'GET_BOOKING_REPORT';
export const GET_SMS_REPORT = 'GET_SMS_REPORT';

function getBookingReportSuccess(data) {
  return {
    type: GET_BOOKING_REPORT,
    state: {
      bookings: data
    }
  };
}

function getSmsReportSuccess(data) {
  return {
    type: GET_SMS_REPORT,
    state: {
      sms: data
    }
  };
}

export function getBookingReport(resourceId, start, end) {
  const url = prefixUrl(`/reports/bookings/${resourceId}/${start}/${end}/`);
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
            .then(res => dispatch(checkStatus(res)))
            .then(res => res.json())
            .then(res => dispatch(getBookingReportSuccess(res)))
            .then(res => dispatch(loadingDone()))
            .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getSmsReport(start, end) {
  const url = prefixUrl(`/reports/sms/${start}/${end}/`);
  return (dispatch) => {
    dispatch(loading());
    return fetch(url, fetchGet())
         .then(res => dispatch(checkStatus(res)))
         .then(res => res.json())
         .then(res => dispatch(getSmsReportSuccess(res)))
         .then(res => dispatch(loadingDone()))
         .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
