import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TextInput extends Component {

  render() {
    const { input, label, unitLabel, helpText, readOnly, width, meta: { touched, error, warning } } = this.props;

    return (
      <div className={touched && error ? 'form-group has-error' : 'form-group'}>
        <label className="control-label">{label} {warning && <i className="fa fa-fw fa-info-circle text-highlight-warn" title={warning} />}</label>
        <div className="form-control-container" style={{ width }}>
          {unitLabel && <label className="form-control-label">{unitLabel}</label>}
          <input type="text" className="form-control" readOnly={readOnly} {...input} />
        </div>
        {touched && error && <span className="help-block">{error}</span>}
        {helpText && <span className="form-control-description">{helpText}</span>}
      </div>
    );
  }
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string
};

export default TextInput;
