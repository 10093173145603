import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { routeParams } from '../../utils/selectors';
import { translateRouteParams, viewDateToStartEnd } from '../../utils/time-util';

import UserMenu from './user-menu';
import SearchField from './search-field';
import Sections from './sections';
import MobileResourceSelector from '../calendar/mobile-resource-selector';
import UndoButton from '../undo-button/undo-button';
import ScheduleToggle from '../calendar/schedule-toggle';
import CurrentDateLabel from '../calendar/current-date-label';

const Nav = (props) => {
  const { phoneMode, tabletMode, routeParams } = props;
  const viewDateStartEnd = viewDateToStartEnd(routeParams.viewMode, routeParams.viewDate);
  const isCalendar = routeParams.section === 'calendar';

  return (
    <nav className="navbar navbar-static-top navbar-inverse">
      {!phoneMode && (
        <div className="navbar-header">
          <span className="navbar-brand">
            <img src="/dashl_logo_white.png" alt="dashl-logo" className="logo" />
          </span>
        </div>
      )}
      <Sections routeParams={routeParams} />
      {phoneMode && <MobileResourceSelector routeParams={routeParams} />}
      {phoneMode && (
        <CurrentDateLabel
          viewMode={routeParams.viewMode}
          phoneMode={phoneMode}
          tabletMode={tabletMode}
          viewDateStart={viewDateStartEnd.start}
        />
      )}
      <UserMenu routeParams={routeParams} />
      {phoneMode && (
        <div className="navbar-right">
          <UndoButton phoneMode={phoneMode} tabletMode={tabletMode} />
          <ScheduleToggle phoneMode={phoneMode} tabletMode={tabletMode} />
        </div>
      )}
      {!phoneMode && isCalendar && <SearchField />}
    </nav>
  );
};

Nav.propTypes = {
  routeParams: PropTypes.object.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  tabletMode: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { mainViewState } = state;

  return {
    routeParams: routeParams(translateRouteParams(ownProps.match.params)),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode')
  };
};

export default connect(mapStateToProps)(Nav);
