import { defineMessages } from 'react-intl';

export const msgChip = defineMessages({
  duration: {
    id: 'grid.Chip.duration',
    defaultMessage: '{duration} min'
  },
  home: {
    id: 'grid.Chip.home',
    defaultMessage: 'Hembesök'
  },
  smsSent: {
    id: 'grid.Chip.smsSent',
    defaultMessage: 'SMS skickat'
  },
  webboking: {
    id: 'grid.Chip.webboking',
    defaultMessage: 'Webbokning'
  },
  ongoingBooking: {
    id: 'grid.Chip.ongoingBooking',
    defaultMessage: 'Pågående webbokning'
  },
  canceled: {
    id: 'grid.Chip.canceled',
    defaultMessage: 'Avbokad'
  }
});

export const msgColumnWebIndicator = defineMessages({
  allClosed: {
    id: 'grid.ColumnWebIndicator.allClosed',
    defaultMessage: 'Webbokning stängd, schemat är stängt'
  },
  disabledForResource: {
    id: 'grid.ColumnWebIndicator.disabledForResource',
    defaultMessage: 'Webbokning avstängd för denna resurs'
  },
  dayPast: {
    id: 'grid.ColumnWebIndicator.dayPast',
    defaultMessage: 'Webbokning stängd, dagen är passerad'
  },
  closedMax: {
    id: 'grid.ColumnWebIndicator.closedMax',
    defaultMessage: 'Webbokning stängd, över {bookingMaxDaysInAdvance} dagar bort'
  },
  webAndVipOpen: {
    id: 'grid.ColumnWebIndicator.webAndVipOpen',
    defaultMessage: 'Web & VIP öppen, dagen är bokningsbar'
  },
  webOpen: {
    id: 'grid.ColumnWebIndicator.webOpen',
    defaultMessage: 'Webbokning öppen, dagen är bokningsbar'
  },
  vipOpen: {
    id: 'grid.ColumnWebIndicator.vipOpen',
    defaultMessage: 'VIP-bokning öppen, dagen är bokningsbar'
  }
});

export const msgConfirmMove = defineMessages({
  copy: {
    id: 'grid.ConfirmMove.copy',
    defaultMessage: 'kopiera'
  },
  move: {
    id: 'grid.ConfirmMove.move',
    defaultMessage: 'flytta'
  },
  question: {
    id: 'grid.ConfirmMove.question',
    defaultMessage: 'Vill du {verbLc} denna bokning till {newTime}?'
  },
  sendSmsTo: {
    id: 'grid.ConfirmMove.sendSmsTo',
    defaultMessage: 'Skicka SMS-bekräftelse till {customerPhoneNumber}'
  },
  sendEmailTo: {
    id: 'grid.ConfirmMove.sendEmailTo',
    defaultMessage: 'Skicka mejlbekräftelse till {customerEmail}'
  }
});

export const msgConfirmResize = defineMessages({
  question: {
    id: 'grid.ConfirmResize.question',
    defaultMessage: 'Vill du ändra bokningens längd till {duration, number} minuter?'
  }
});

export const msgScheduleEditBanner = defineMessages({
  basicText: {
    id: 'grid.ScheduleEditBanner.basicText',
    defaultMessage: 'Blockera eller öppna schemat genom att markera tid nedan. Ändringarna sparas automatiskt.'
  },
  phoneModeText: {
    id: 'grid.ScheduleEditBanner.phoneModeText',
    defaultMessage: 'Blockera eller öppna schemat genom att markera tid nedan'
  },
  shedule: {
    id: 'grid.ScheduleEditBanner.shedule',
    defaultMessage: 'Schemaläge'
  }
});
