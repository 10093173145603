var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <br><br>\n   <h4>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"scheduleExceptions",{"name":"i18n","hash":{},"data":data}))
    + "</h4>\n   <div class=\"alert alert-info\">\n      <i class=\"fa fa-info-circle\"></i> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"tipCanAddExceptions",{"name":"i18n","hash":{},"data":data}))
    + " ( <i class=\"fa fa-fw fa-calendar\"></i> ) "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"tipCalendar",{"name":"i18n","hash":{},"data":data}))
    + "\n   </div>\n   <div class=\"pull-right js-exceptions\">\n      <button class=\"btn-link js-delete-all-exceptions\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"removeAllExceptions",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n   </div>\n   <div class=\"table table-striped js-exceptions\">\n       <div class=\"row header\">\n           <div class=\"col-xs-3\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"date",{"name":"i18n","hash":{},"data":data}))
    + "</div>\n           <div class=\"col-xs-3\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"bookable",{"name":"i18n","hash":{},"data":data}))
    + "</div>\n           <div class=\"col-xs-3\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"notBookable",{"name":"i18n","hash":{},"data":data}))
    + "</div>\n       </div>\n       <div class=\"js-schedule-exceptions-container\"></div>\n   </div>\n</div>\n";
},"useData":true});