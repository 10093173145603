import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SortableListBanner from '../admin/sortable-list-banner';
import ResourceListToolbar from './resource-list-toolbar';
import ResourceList from './resource-list';

export default class ResourcesSidebar extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    addGroup: PropTypes.func.isRequired,
    addResource: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      sortable: false
    };
  }

  filterChange = (ev) => {
    this.setState({ filter: ev.target.value });
  };

  handleToggleSorting = (ev) => {
    ev.preventDefault();
    this.setState({ sortable: !this.state.sortable });
  };

  render() {
    const { routeParams } = this.props;
    return (
      <div className="columns-sidebar">
        { this.state.sortable ? this.renderSortable() : this.renderNonSortable() }
        <ResourceList routeParams={routeParams} filter={this.state.filter} sortable={this.state.sortable} />
      </div>
    );
  }

  renderSortable() {
    return (
      <SortableListBanner onToggleSorting={this.handleToggleSorting} />
    );
  }

  renderNonSortable() {
    const { permissions, addGroup, addResource } = this.props;
    return (
      <div className="columns-header">
        <ResourceListToolbar permissions={permissions} onAddGroup={addGroup} onAddResource={addResource} onToggleSorting={this.handleToggleSorting} />
      </div>
    );
  }
}
