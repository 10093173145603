const Customer = require('../models/customer');
const CustomersCollection = require('../collections/customers');
const MainView = require('../views/customers/main-view');
const ListView = require('../views/customers/list/list-view');
const CustomerItemView = require('../views/customers/item/container-view');
const NoItemDetailsView = require('../views/customers/no-item-details-view');

const { formatMessage } = require('../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = Chaplin.Controller.extend({
  limit: 15,

  initialize(options) {
    this.params = options;
  },

  show(params) {
    this.customers = new CustomersCollection();

    this.list();

    this.subscribeEvent('customers:itemSelected', _.bind(this.showItem, this));
    this.subscribeEvent('customers:search', _.bind(this.search, this));
    this.subscribeEvent('customers:addNew', _.bind(this.addNew, this));
    this.subscribeEvent('customers:delete', _.bind(this.delete, this));
    this.subscribeEvent('customers:list', _.bind(this.list, this));
    this.subscribeEvent('customers:getPage', _.bind(this.getPage, this));
    this.subscribeEvent('customers:merged', _.bind(this.merged, this));
    this.subscribeEvent('redux:event:CREATE_CUSTOMER', _.bind(this.customerCreated, this));
  },

  addNew() {
    this.disposeNoItemDetailsView();
    this.disposeItemView();
    this.showNewCustomer();

    if (this.listView) {
      this.listView.deselect();
    }
  },

  delete(item) {
    $.ajax({
      type: 'DELETE',
      url: `/customers/${item.id}`,
      success: _.bind(this.deleteSuccess, this, item)
    });
  },

  deleteSuccess(item) {
    this.showNoItemDetails();
    this.listView.collection.remove(item);
  },

  customerCreated(event) {
    if (event.source === 'local') {
      this.disposeNewItemView();
      const customer = new Customer(event.customer);
      this.listView.collection.add(customer, { at: 0 });
      this.listView.selectFirst();
      this.showCustomer(customer);
    }
  },

  getPage(pageNumber) {
    $.ajax({
      type: 'GET',
      url: `/customers?offset=${pageNumber}&limit=${this.limit}`,
      success: _.bind(this.showPage, this)
    });
  },

  showPage(result) {
    this.customers.reset(result.customerEntries);
  },

  loadSuccess(result) {
      // exit if disposed
      //
    if (!this.customers) {
      return;
    }

    if (!this.view) {
      this.createView(result);
    } else {
      this.listView.model.set('totalCount', result.totalCount);
      this.customers.reset(result.customerEntries);
    }
  },

  search(text) {
    this.listView.model.set('searchQuery', text);
    $.ajax({
      type: 'GET',
      url: `/search?scope=Customer&query=${encodeURIComponent(text)}`,
      success: _.bind(this.loadSuccess, this)
    });
  },

  merged(mergeCount) {
    this.publishEvent('alertOverlay:showSuccess', formatMessage(msgHandlebars.removedCountDuplicates, { mergeCount }));
    this.list();
  },

  list() {
    if (this.listView) {
      this.listView.model.set('searchQuery', undefined);
    }
    $.ajax({
      type: 'GET',
      url: `/customers?offset=0&limit=${this.limit}`,
      success: _.bind(this.loadSuccess, this)
    });
  },

  showItem(item) {
    this.disposeNoItemDetailsView();
    this.disposeNewItemView();
    item.fetch({
      success: _.bind(this.showCustomer, this)
    });
  },

  showCustomer(item) {
    if (!this.itemView) {
      this.itemView = new CustomerItemView({ model: item });
    }
    this.itemView.show(item);
  },

  showNewCustomer() {
    if (!this.newItemView) {
      this.newItemView = new CustomerItemView({ model: new Customer() });
    }
  },

  createView(result) {
    const model = new Chaplin.Model({ totalCount: result.totalCount });
    this.customers.reset(result.customerEntries);
    this.view = new MainView();
    this.listView = new ListView({ collection: this.customers, model });
    this.showNoItemDetails();
  },

  showNoItemDetails() {
    this.disposeItemView();
    this.disposeNewItemView();
    this.noItemDetailsView = new NoItemDetailsView();
  },

  disposeItemView() {
    if (this.itemView) {
      this.itemView.dispose();
      this.itemView = null;
    }
  },

  disposeNewItemView() {
    if (this.newItemView) {
      this.newItemView.dispose();
      this.newItemView = null;
    }
  },

  disposeNoItemDetailsView() {
    if (this.noItemDetailsView) {
      this.noItemDetailsView.dispose();
      this.noItemDetailsView = null;
    }
  }
});
