var moment = require('moment');
var View = require('../base/view');
var TimeLineView = require('./timeline/timeline-view');
var ScheduleView = require('./schedule-view');
var NewScheduleModal = require('./new-schedule-modal-view');
var ConfirmDeletePopoverView = require('../base/confirm-popover-view');

const { formatMessage } = require('../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = View.extend({
	noWrap: true,
	autoRender: true,

	events: {
		'click .js-new-schedule': 'showNewScheduleModal',
		'click .js-save-schedule': 'saveSchedule',
		'click .js-cancel-schedule': 'cancelEdit',
		'click .js-delete-schedule': 'confirmDelete'
	},

	initialize: function (options) {
		this.editMode = options.editMode !== false;
		this.template = require('./main');
		View.prototype.initialize.call(this, arguments);
	},

	saveSchedule: function (ev) {
		ev.preventDefault();
		var data = {
			id: this.scheduleView.model.id,
			schedule: this.scheduleView.getScheduleData()
		};
		this.trigger('schedule:save', data);
	},

	confirmDelete: function(ev) {
		ev.preventDefault();

		this.confirmationView = new ConfirmDeletePopoverView({
			title: formatMessage(msgHandlebars.deleteScheduleTitle),
			confirmText: formatMessage(msgHandlebars.deleteScheduleText),
			buttonText: formatMessage(msgHandlebars.deleteScheduleTitle),
			callback: _.bind(this.deleteSchedule, this),
			parent: $(ev.currentTarget)
		});
		this.subview('popover', this.confirmationView);
	},

	deleteSchedule: function () {
		this.trigger('schedule:delete', this.scheduleView.model.id);
	},

	closeDeletePopover: function() {
		this.subview('popover').close();
		this.removeSubview('popover');
	},

	cancelEdit: function (ev) {
		ev.preventDefault();
		this.disableSaveCancel(true);
		this.scheduleView.resetModel();
	},

	showNewScheduleModal: function (e) {
		e.preventDefault();
		var modal = new NewScheduleModal({model: this.model });
		this.listenTo(modal, 'schedule:create', _.bind(this.createSchedule, this));
		this.subview('modal', modal);
	},

	createSchedule: function (data) {
		this.trigger('schedule:create', data);
	},

	closeModal: function() {
		this.subview('modal').close();
		this.removeSubview('modal');
	},

	scheduleSaved: function() {
		this.disableSaveCancel(true);
		this.scheduleView.commitModel();
	},

	scheduleChanged: function() {
		this.disableSaveCancel(false);
	},

	disableSaveCancel: function(disabled) {
		this.$('.js-cancel-schedule').toggleClass('disabled', disabled === true);
	},

	toggleEnableEdit: function(enable) {
		// try hiding the whole schedule when using account schedule
		if (enable){
			this.$el.show();
		}
		else{
			this.$el.hide();
		}
		// --

		this.$('.js-new-schedule, .js-save-schedule, .js-cancel-schedule, .js-delete-schedule').toggleClass('hide', !enable);
		if(this.scheduleView) {
			this.scheduleView.toggleEnableEdit(enable);
		}
	},

	toggleEnableDelete: function(schedule) {
		var schedules = this.model.get('schedules');
		var index = schedules.indexOf(schedule);
		var enabled = schedules.length > 1 && index > 0 && schedule.get('validUntil');
		this.$('.js-delete-schedule').toggleClass('hide', !enabled);
	},

	render: function() {
		View.prototype.render.call(this, arguments);
		this.toggleEnableEdit(this.editMode);
		this.renderSchedule();
	},

	renderSchedule: function () {
		this.currentSchedule = this.getCurrentSchedule();

		if(this.currentSchedule && this.currentSchedule.has('days')) {
			this.scheduleView = new ScheduleView({ scheduleModel: this.currentSchedule, editMode: this.editMode, container: this.$('#schedule-container') });
			this.listenTo(this.scheduleView, 'selected', _.bind(this.toggleEnableDelete, this));
			this.listenTo(this.scheduleView, 'changed', _.bind(this.scheduleChanged, this));
			this.subview('content', this.scheduleView);
			this.toggleEnableDelete(this.currentSchedule);
			this.disableSaveCancel(true);
		}

    var timeLineView = new TimeLineView({ model: this.model, currentSchedule: this.currentSchedule, collection: this.model.get('schedules'), container: this.$('#schedule-timeline-container') });
    this.subview('timeLine', timeLineView);
	},

	getCurrentSchedule: function() {
		var now = moment();
		for(var index in this.model.get('schedules').models) {
			var schedule = this.model.get('schedules').models[index];
			var validFrom = moment(schedule.get('validFrom'));
			var validUntil = schedule.get('validUntil') && moment(schedule.get('validUntil'));
			if(validFrom.isBefore(now) && (!validUntil || validUntil.isAfter(now))) {
				return schedule;
			}
		}
		return this.model.get('schedules').models[0];
	}
});
