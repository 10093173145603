var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n   <div class=\"form-search\">\n      <div class=\"input-group\">\n         <input id=\"customers-search\" type=\"text\" class=\"form-control\" placeholder=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"findCustomer",{"name":"i18n","hash":{},"data":data}))
    + "\" autocomplete=\"off\">\n         <span class=\"input-group-addon\">\n            <i class=\"fa fa-search\"></i>\n         </span>\n      </div>\n   </div>\n   <div class=\"customer-search-list\">\n      <div id=\"customer-list-container\"></div>\n      <div id=\"customer-empty-list-container\">\n         <div class=\"list-group-item customer-list-item\">\n            <em>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"noCustomersFound",{"name":"i18n","hash":{},"data":data}))
    + "</em>\n         </div>\n      </div>\n   </div>\n   <div class=\"text-center\">\n      <div class=\"js-pagination\"></div>\n   </div>\n</div>\n";
},"useData":true});