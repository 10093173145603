import { defineMessages } from 'react-intl';

export const msgEmailIcsConfig = defineMessages({
  attachInvitation: {
    id: 'inputs.EmailIcsConfig.attachInvitation',
    defaultMessage: 'Bifoga kalenderinbjudan i bokningsbekräftelser'
  },
  invitationDescription: {
    id: 'inputs.EmailIcsConfig.invitationDescription',
    defaultMessage: 'Kalenderinbjudan i bokningsbekräftelsen gör så att dina kunder kan lägga till bokningen i sin kalender.'
  },
  title: {
    id: 'inputs.EmailIcsConfig.title',
    defaultMessage: 'Titel'
  },
  address: {
    id: 'inputs.EmailIcsConfig.address',
    defaultMessage: 'Plats/adress'
  },
  extra: {
    id: 'inputs.EmailIcsConfig.extra',
    defaultMessage: 'Extra information'
  },
  preview: {
    id: 'inputs.EmailIcsConfig.preview',
    defaultMessage: 'Förhandsgranskning'
  }
});

export const msgPeriodInput = defineMessages({
  day: {
    id: 'inputs.PeriodInput.day',
    defaultMessage: 'dag'
  },
  days: {
    id: 'inputs.PeriodInput.days',
    defaultMessage: 'dagar'
  },
  week: {
    id: 'inputs.PeriodInput.week',
    defaultMessage: 'vecka'
  },
  weeks: {
    id: 'inputs.PeriodInput.weeks',
    defaultMessage: 'veckor'
  },
  month: {
    id: 'inputs.PeriodInput.month',
    defaultMessage: 'månad'
  },
  months: {
    id: 'inputs.PeriodInput.months',
    defaultMessage: 'månader'
  },
  year: {
    id: 'inputs.PeriodInput.year',
    defaultMessage: 'år'
  }
});

export const msgRichTextEditorField = defineMessages({
  bold: {
    id: 'inputs.RichTextEditorField.bold',
    defaultMessage: 'Fet'
  },
  italic: {
    id: 'inputs.RichTextEditorField.italic',
    defaultMessage: 'Kursiv'
  },
  underline: {
    id: 'inputs.RichTextEditorField.underline',
    defaultMessage: 'Understruken'
  },
  unstyled: {
    id: 'inputs.RichTextEditorField.unstyled',
    defaultMessage: 'Brödtext'
  },
  header: {
    id: 'inputs.RichTextEditorField.header',
    defaultMessage: 'Rubrik {number}'
  },
  unorderedList: {
    id: 'inputs.RichTextEditorField.unorderedList',
    defaultMessage: 'UL'
  },
  orderedList: {
    id: 'inputs.RichTextEditorField.orderedList',
    defaultMessage: 'OL'
  }
});

export const msgSmsTemplateInput = defineMessages({
  useStandard: {
    id: 'inputs.SmsTemplateInput.useStandard',
    defaultMessage: 'Använd standardtext'
  },
  preview: {
    id: 'inputs.SmsTemplateInput.preview',
    defaultMessage: 'Förhandsgranskning:'
  },
  numberOfCharacters: {
    id: 'inputs.SmsTemplateInput.numberOfCharacters',
    defaultMessage: 'Antal tecken: {messageLength, number} (Antal SMS: {messageCount, number})'
  },
  warning: {
    id: 'inputs.SmsTemplateInput.warning',
    defaultMessage: '<b>OBS!</b> Din SMS-text skickas med två SMS om den är längre än 160 tecken. {linebreak} Antal tecken varierar beroende på resursens och tjänstens namn samt datumets längd.'
  }
});

export const msgTagsInfo = defineMessages({
  tagsWithInformation: {
    id: 'inputs.SmsTagsInfo.tagsWithInformation',
    defaultMessage: 'Taggar med information från bokningen:'
  },
  dateAndTime: {
    id: 'inputs.SmsTagsInfo.dateAndTime',
    defaultMessage: '<samp>#TIDPUNKT</samp> = Datum och tid för bokningen'
  },
  nameOfResource: {
    id: 'inputs.SmsTagsInfo.nameOfResource',
    defaultMessage: '<samp>#RESURS</samp> = Namn på resursen'
  },
  nameOfService: {
    id: 'inputs.SmsTagsInfo.nameOfService',
    defaultMessage: '<samp>#TJÄNST</samp> = Namn på tjänsten'
  },
  bookingReference: {
    id: 'inputs.SmsTagsInfo.bookingReference',
    defaultMessage: '<samp>#BOKNINGSREF</samp> = Bokningsreferens'
  },
  companyName: {
    id: 'inputs.SmsTagsInfo.companyName',
    defaultMessage: '<samp>#FÖRETAG</samp> = Ert företagsnamn'
  },
  phoneNumber: {
    id: 'inputs.SmsTagsInfo.phoneNumber',
    defaultMessage: '<samp>#TEL</samp> = Ert telefonnummer'
  },
  customerName: {
    id: 'inputs.SmsTagsInfo.customerName',
    defaultMessage: '<samp>#KUNDNAMN</samp> = Kundens namn'
  },
  customerNum: {
    id: 'inputs.SmsTagsInfo.customerNum',
    defaultMessage: '<samp>#KUNDORGNR</samp> = Kundens org.nr'
  },
  customerCompanyName: {
    id: 'inputs.SmsTagsInfo.customerCompanyName',
    defaultMessage: '<samp>#KUNDORGNAMN</samp> = Kundens företagsnamn'
  },
  vehicleReg: {
    id: 'inputs.SmsTagsInfo.vehicleReg',
    defaultMessage: '<samp>#FORDONREG</samp> = Fordonets reg.nr'
  }
});

export const msgWebBookingStrategy = defineMessages({
  suggestTime: {
    id: 'inputs.WebBookingStrategy.suggestTime',
    defaultMessage: 'Välj hur Dashl ska föreslå tider i webbokningen'
  },
  lblSmartBooking: {
    id: 'inputs.WebBookingStrategy.lblSmartBooking',
    defaultMessage: 'Smart bokning <b>(Rekommenderas)</b>'
  },
  descriptionSmartBooking: {
    id: 'inputs.WebBookingStrategy.descriptionSmartBooking',
    defaultMessage: 'Minimerar luckor och obokad tid genom att föreslå tider innan och efter befintliga bokningar, samt första och sista tiden på dagen'
  },
  lblExcludeLastInDay: {
    id: 'inputs.WebBookingStrategy.lblExcludeLastInDay',
    defaultMessage: 'Föreslå inte sista tiden för dagen'
  },
  lblUseIntervalFallback: {
    id: 'inputs.WebBookingStrategy.lblUseIntervalFallback',
    defaultMessage: 'Föreslå tider med intervall på dagar med få bokningar'
  },
  minutesInterval: {
    id: 'inputs.WebBookingStrategy.minutesInterval',
    defaultMessage: '{number} minuters intervall'
  },
  lblFirstVacancies: {
    id: 'inputs.WebBookingStrategy.lblFirstVacancies',
    defaultMessage: 'Första lediga'
  },
  descriptionFirstVacancies: {
    id: 'inputs.WebBookingStrategy.descriptionFirstVacancies',
    defaultMessage: 'Föreslår första lediga tid på dagen eller för- och eftermiddag, samt även luckor som passar bokningens längd.'
  },
  optionTodayFirstFreeTime: {
    id: 'inputs.WebBookingStrategy.optionTodayFirstFreeTime',
    defaultMessage: 'Dagens första lediga tid'
  },
  optionFirstTimes: {
    id: 'inputs.WebBookingStrategy.optionFirstTimes',
    defaultMessage: 'Första tiderna på för- och eftermiddag'
  },
  lblInterval: {
    id: 'inputs.WebBookingStrategy.lblInterval',
    defaultMessage: 'Intervall'
  },
  descriptionInterval: {
    id: 'inputs.WebBookingStrategy.descriptionInterval',
    defaultMessage: 'Föreslår tider enligt ett intervall, t.ex. var 60:e minut, fungerar bäst om du främst har bokningar som passar intervallet.'
  }
});
