import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { enableDashlFeatures } from '../../utils/selectors';
import AddGroupForm from '../admin/add-group-form';
import AddItemForm from '../admin/add-item-form';
import { Popover } from '../common/popover';

import { msgServiceListToolbar as msg } from '../../localization/messages/components/services';

class ServiceListToolbar extends Component {

  static propTypes = {
    onAddGroup: PropTypes.func.isRequired,
    onAddService: PropTypes.func.isRequired,
    onToggleSorting: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      addGroup: false,
      addService: false
    };
  }

  render() {
    return (
      <div className="columns-toolbar">
        {this.renderAddServiceButton()}
        {this.renderAddGroupButton()}
        <button className="btn btn-label-o pull-right" onClick={this.handleToggleSortable}>
          <FormattedMessage {...msg.sort} />
        </button>
      </div>
    );
  }

  renderAddServiceButton() {
    const { enableDashlFeatures } = this.props;

    if (enableDashlFeatures) {
      return null;
    }

    const popoverContent = this.addServiceContent();
    return (
      <Popover
        isOpen={this.state.addService}
        body={popoverContent}
        preferPlace="below"
        onOuterAction={this.handleCloseServicePopover}
        style={{ zIndex: 1000 }}
        enterExitTransitionDurationMs={0}
        refreshIntervalMs={150}
      >
        <button className="btn btn-label-success" onClick={this.handleOpenServicePopover}>
          <FormattedMessage {...msg.newService} />
        </button>
      </Popover>
    );
  }

  renderAddGroupButton() {
    const { enableDashlFeatures } = this.props;

    if (enableDashlFeatures) {
      return null;
    }

    const popoverContent = this.addGroupContent();
    return (
      <Popover
        isOpen={this.state.addGroup}
        body={popoverContent}
        preferPlace="below"
        onOuterAction={this.handleCloseGroupPopover}
        style={{ zIndex: 1000 }}
        enterExitTransitionDurationMs={0}
        refreshIntervalMs={150}
      >
        <button className="btn btn-label-o" onClick={this.handleOpenGroupPopover}>
          <FormattedMessage {...msg.newGroup} />
        </button>
      </Popover>
    );
  }

  handleToggleSortable = () => {
    this.props.onToggleSorting();
  };

  handleOpenGroupPopover = () => {
    this.setState({ addGroup: true });
  };

  handleCloseGroupPopover = () => {
    this.setState({ addGroup: false });
  };

  handleOpenServicePopover = () => {
    this.setState({ addService: true });
  };

  handleCloseServicePopover = () => {
    this.setState({ addService: false });
  };

  handleAddGroup = (groupName) => {
    return this.props.onAddGroup({ name: groupName })
      .then(() => this.handleCloseGroupPopover());
  };

  handleAddService = (groupId, name) => {
    return this.props.onAddService({ groupId, name, description: '', serviceDuration: 60 })
      .then(() => this.handleCloseServicePopover());
  };

  addServiceContent() {
    const { intl: { formatMessage: f } } = this.props;
    return (
      <AddItemForm
        onClosePopover={this.handleCloseServicePopover}
        onAddItem={this.handleAddService}
        placeholder={f(msg.enterTheName)}
        groups={this.props.orderedServiceGroups}
      />
    );
  }

  addGroupContent() {
    return (
      <AddGroupForm
        onClosePopover={this.handleCloseGroupPopover}
        onAddGroup={this.handleAddGroup}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { orderedServiceGroups } = state;
  return {
    orderedServiceGroups,
    enableDashlFeatures: enableDashlFeatures(state)
  };
};

export default injectIntl(connect(
  mapStateToProps
)(ServiceListToolbar));
