var CollectionView = require('../../base/collection-view');

module.exports = CollectionView.extend({
   noWrap: true,
   autoRender: true,
   listSelector: '#customer-pages-container',
   limit: 15,

   initialize: function () {
      this.collection = new Chaplin.Collection();
      this.updatePages();
      this.template = require('./pager');
      CollectionView.prototype.initialize.call(this, arguments);
   },

   totalCountChange: function(){
      this.updatePages();
      this.setUpPagination();
   },

   getPage: function(pageIndex){
      if(this.searchQuery()){
         this.publishEvent('customers:search', this.searchQuery(), pageIndex * this.limit);
      }
      else{
         this.publishEvent('customers:getPage', pageIndex * this.limit);
      }
      return false;
   },

   searchQuery: function () {
      return this.model.attributes.searchQuery;
   },

   setUpPagination: function () {
      var that = this;
      this.$('#customer-pages-container').twbsPagination({
         totalPages: that.model.attributes.totalPages,
         next: '>',
         prev: '<',
         first: '',
         last: '',
         onPageClick: function (event, page) {
            that.getPage(page - 1);
         }
      });
   },

   render: function () {
      CollectionView.prototype.render.call(this, arguments);
      if(Math.ceil(this.model.attributes.totalCount / this.limit) > 1){
         this.updatePages();
         this.setUpPagination();
      }
   },

   updatePages: function(){
      this.model.set('totalPages', Math.ceil(this.model.attributes.totalCount / this.limit));
   }
});
