import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { createValidator, required, number, minValue, maxValue, maxLength } from '../../../utils/validation';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import SubmitButton from '../../common/submit-button';
import TagsInfo from '../../inputs/tags-info';
import { msgPaymentConfigForm as msg } from '../../../localization/messages/components/account-config';

const PaymentConfigForm = (props) => {
  const { handleSubmit } = props;
  const { formatMessage: f } = useIntl();
  const normalizeStatement = (value) => {
    return value && value.replace(/['"<>]*/, '');
  };

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <br />

      <Field name="sellerName" component={TextInputHorizontal} label={f(msg.lblSellerName)} />
      <Field name="sellerVatNo" component={TextInputHorizontal} label={f(msg.lblSellerVatNo)} />
      <Field name="sellerWeb" component={TextInputHorizontal} label={f(msg.lblWeb)} />
      <Field name="sellerEmail" component={TextInputHorizontal} label={f(msg.lblEmail)} />
      <div className="row">
        <div className="col-sm-offset-3 col-sm-9">
          <p className="text-muted">
            {f(msg.sellerInformation)}
          </p>
        </div>
      </div>

      <hr />
      <Field name="logoUrl" component={TextInputHorizontal} label={f(msg.lblLogoUrl)} />
      <div className="row">
        <div className="col-sm-offset-3 col-sm-9">
          <p className="text-muted">
            {f(msg.logoUrlInformation, { br: <br /> })}
          </p>
        </div>
      </div>

      <hr />

      <Field name="addressLine1" component={TextInputHorizontal} label={f(msg.lblAddressLine)} />
      <Field name="addressLine2" component={TextInputHorizontal} label="" />
      <Field name="postCode" component={TextInputHorizontal} label={f(msg.lblPostCode)} />
      <Field name="area" component={TextInputHorizontal} label={f(msg.lblArea)} />
      <div className="row">
        <div className="col-sm-offset-3 col-sm-9">
          <p className="text-muted">
            {f(msg.addressInformation)}
          </p>
        </div>
      </div>

      <hr />

      <Field
        name="statementDescriptor"
        component={TextInputHorizontal}
        label={f(msg.lblStatementDescriptor)}
        normalize={normalizeStatement}
      />
      <div className="row">
        <div className="col-sm-offset-3 col-sm-5">
          <p className="text-muted">
            {f(msg.statementInformation, {
              lt: <Fragment>&lt;</Fragment>,
              gt: <Fragment>&gt;</Fragment>,
              quot: <Fragment>&quot;</Fragment>,
              apos: <Fragment>&apos;</Fragment>
            })}
          </p>
        </div>
      </div>

      <hr />

      <Field
        name="chargeDescription"
        component={TextInputHorizontal}
        label={f(msg.lblChargeDescription)}
      />
      <div className="row">
        <div className="col-sm-offset-3 col-sm-5">
          <TagsInfo booking customer />
        </div>
      </div>

      <hr />

      <div className="form-group">
        <label className="col-sm-3 control-label">{f(msg.lblVatPct)}</label>
        <div className="col-sm-4">
          <Field
            name="vatPct"
            component="input"
            type="number"
            className="form-control inline-sm"
            parse={val => val && parseInt(val)}
            min={0}
            max={100}
          />
          <p className="form-control-static inline-md">%</p>
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-4">
          <div className="checkbox">
            <label>
              <Field name="pricesExVat" component="input" type="checkbox" />
              {f(msg.lblPricesExVat)}
            </label>
          </div>
        </div>
      </div>

      <hr />

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-5">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const validator = createValidator({
  sellerName: [required],
  sellerVatNo: [required],
  addressLine1: [required],
  postCode: [required],
  area: [required],
  statementDescriptor: [required, maxLength(22)],
  vatPct: [required, number, minValue(0), maxValue(100)]
});

export default reduxForm({
  form: 'payment-config',
  validate: validator
})(PaymentConfigForm);
