import { defineMessages } from 'react-intl';

export const msgClipboard = defineMessages({
  content: {
    id: 'clipboard.Clipboard.content',
    defaultMessage: 'Här visas dina urklippta bokningar. Navigera till en ny dag eller vecka, dra och släpp sedan bokningen för att byta tid.'
  },
  dontShow: {
    id: 'clipboard.Clipboard.dontShow',
    defaultMessage: 'Visa inte detta meddelande igen'
  },
  minutes: {
    id: 'clipboard.Clipboard.minutes',
    defaultMessage: '({duration} min)'
  }
});
