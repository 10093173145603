module.exports = {
  initialize(currencyCode) {
    this.currencyCode = currencyCode;
  },

  accountCurrency(amount, decimals = 2, currencyCode = this.currencyCode) {
    const fractionDigits = parseInt(decimals);
    const properties = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits };
    const value = parseFloat(amount || 0).toLocaleString('sv-SE', properties);

    switch (currencyCode) {
      case 'EUR':
        return `€${value}`;

      case 'GBP':
        return `£${value}`;

      default:
        return `${value} kr`;
    }
  },

  currencySymbol() {
    switch (this.currencyCode) {
      case 'EUR':
        return '€';

      case 'GBP':
        return '£';

      default:
        return 'kr';
    }
  }
};
