import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { createValidator, required, maxLength } from '../../../utils/validation';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import SubmitButton from '../../common/submit-button';
import { msgGeneralForm as msg } from '../../../localization/messages/components/account-config';

const GeneralForm = (props) => {
  const { formatMessage: f } = useIntl();
  const { handleSubmit, features, deviceType, isSysAdmin } = props;

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <br />

      {(features.companyName || features.contactNumber) && (
      <div>
        {features.companyName ? <Field name="companyName" component={TextInputHorizontal} label={f(msg.lblCompanyName)} /> : null }
        {features.contactNumber ? <Field name="contactNumber" component={TextInputHorizontal} label={f(msg.lblContactNumber)} /> : null }
        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              {f(msg.featuresMesssage)}
            </p>
          </div>
        </div>
        <hr />
      </div>
        )}

      {features.gridRowsPerHour && (
      <div>
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblCalendarLines)}</label>
          <div className="col-sm-2">
            <Field name="gridRowsPerHour" component="select" className="form-control">
              <option value="12">{f(msg.countCalendarLines, { itemCount: 12 })}</option>
              <option value="6">{f(msg.countCalendarLines, { itemCount: 6 })}</option>
              <option value="4">{f(msg.countCalendarLines, { itemCount: 4 })}</option>
              <option value="3">{f(msg.countCalendarLines, { itemCount: 3 })}</option>
              <option value="2">{f(msg.countCalendarLines, { itemCount: 2 })}</option>
              <option value="1">{f(msg.countCalendarLines, { itemCount: 1 })}</option>
            </Field>
          </div>
          <p className="col-sm-2 form-control-static"> {f(msg.perHour)}</p>
        </div>
        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              {f(msg.perHourDescription)}
            </p>
          </div>
        </div>
        <hr />
      </div>
        )}

      {features.confirmMoveEnabled && (
      <div>
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-6">
            <div className="checkbox">
              <label>
                <Field name="confirmMoveEnabled" component="input" type="checkbox" />
                {f(msg.lblComfirmMove)}
              </label>
            </div>
            <div className="checkbox-description">
              {f(msg.confirmMoveDescription, { br: <br /> })}
            </div>
          </div>
        </div>
        <hr />
      </div>
        )}

      {isSysAdmin && (
      <div>
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-6">
            <div className="checkbox">
              <label>
                <Field name="servicesSortOrderManual" component="input" type="checkbox" />
                {f(msg.lblManualSort)}
              </label>
            </div>
            <div className="checkbox-description">
              {f(msg.manualSortDescription)}
            </div>
          </div>
        </div>
        <hr />
      </div>
        )}

      <div>
        <div className="form-group">
          <label className="col-sm-3 control-label">{f(msg.lblSettingDeviceGroup)}</label>
          <div className="col-sm-6">
            <div className="checkbox">
              <label>
                <Field name="highContrast" component="input" type="checkbox" />
                {f(msg.lblHighContrast)}
              </label>
            </div>
            <div className="checkbox">
              <label>
                <Field name="gridSizeLarge" component="input" type="checkbox" />
                {f(msg.lblGridSizeLarge)}
              </label>
            </div>
            {deviceType === 'tablet' && (
            <div className="checkbox">
              <label>
                <Field name="externalKeyboard" component="input" type="checkbox" />
                {f(msg.lblExternalKeyboard)}
              </label>
            </div>
              )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              {f(msg.footnotes, { br: <br /> })}
            </p>
          </div>
        </div>
        <hr />
      </div>

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-5">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const validator = createValidator({
  companyName: [required, maxLength(50)],
  contactNumber: [required, maxLength(15)]
});

export default reduxForm({
  form: 'general-preferences',
  enableReinitialize: true,
  validate: validator
})(GeneralForm);
