var moment = require('moment');
var Handlebars = require('handlebars/runtime');
var CollectionView = require('../../base/collection-view');
var ResourceScheduleListItemView = require('./timeline-item-view');

module.exports = CollectionView.extend({
   noWrap: true,
   autoRender: true,
   containerMethod: 'html',
   listSelector: '.schedule-timeline',
   itemView: ResourceScheduleListItemView,

   initialize: function (options) {
      this.currentSchedule = options.currentSchedule;
      this.setYear(moment());

      this.template = require('./timeline');
      CollectionView.prototype.initialize.call(this, arguments);

      Handlebars.registerHelper("GetItemOffset", _.bind(this.getItemOffset, this));
      Handlebars.registerHelper("GetItemWidth", _.bind(this.getItemWidth, this));

      this.subscribeEvent('schedule:select', _.bind(this.scheduleSelected, this));
   },

   events: {
      'click .js-nav-previous': 'navigatePrevious',
      'click .js-nav-next': 'navigateNext'
   },

   filterer: function(item, index) {
      var validFrom = moment(item.get('validFrom'));
      var validUntil = item.get('validUntil') ? moment(item.get('validUntil')) : null;

      if(validUntil && validUntil.isBefore(this.displayStart)) {
         return false;
      }
      if(validFrom.isAfter(this.displayEnd.format('YYYY-MM-DD'))) {
         return false;
      }
      return true;
   },

   navigate: function(year) {
      this.setYear(year);
      this.render();

      var items = this.collection.clone();
      this.collection.reset();
      this.collection.reset(items.models);
      this.selectCurrentItem();
   },

   render: function() {
      CollectionView.prototype.render.call(this, arguments);
      this.selectCurrentItem();
      this.showToday();
   },

   scheduleSelected: function(schedule) {
      this.currentSchedule = schedule;
      this.selectCurrentItem();
   },

   selectCurrentItem: function() {
      this.$('.schedule-timeline-item.active').removeClass('active');

      _.each(this.getItemViews(), function(view) {
         if(view.model === this.currentSchedule) {
            view.$el.addClass('active');
         }
      }, this);
   },

   setYear: function(year) {
      this.displayStart = year.startOf('year');
      this.model.set('year', parseInt(this.displayStart.format('YYYY')));
      this.daysInYear = parseInt(this.displayStart.isLeapYear() ? 366 : 365);
      this.displayEnd = moment(this.displayStart).add(this.daysInYear, 'day');
      this.generateHeaders(this.model.get('year'));
   },

   generateHeaders: function(year) {
      var firstDayOfYear = moment(year, 'YYYY');
      var months = [];
      for(var month = 0; month <= 11; month++) {
         var firstDayOfMonth = firstDayOfYear.month(month);
         months.push({
            month: firstDayOfMonth.format('MMM'),
            left: this.getPercentage(firstDayOfMonth.dayOfYear() - 1),
            width: this.getPercentage(firstDayOfMonth.daysInMonth())
         });
      }
      this.model.set('months', months);
   },

   showToday: function() {
      var thisMoment = moment();
      var $today = this.$('.schedule-timeline-today');

      if(thisMoment.isSame(this.displayStart, 'year')) {
         var days = this.getDaysBetween(this.displayStart, thisMoment);
         $today.css('left', this.getPercentage(days));
         $today.show();
      }
      else {
         $today.hide();
      }
   },

   navigatePrevious: function(e) {
      e.preventDefault();
      this.navigate(this.displayStart.clone().subtract(1, 'year'));
   },

   navigateNext: function(e) {
      e.preventDefault();
      this.navigate(this.displayStart.clone().add(1, 'year'));
   },

   getItemOffset: function(item) {
      var days = this.getDaysBetween(this.displayStart, item.validFrom);
      return this.getPercentage(days);
   },

   getItemWidth: function(item) {
      var validUntil = item.validUntil ? moment(item.validUntil) : null;

      if(!validUntil || this.displayEnd.isBefore(validUntil)) {
         var remainingDays = this.getRemainingDays(item.validFrom);
         return this.getPercentage(remainingDays);
      }

      var days = this.getDaysBetween(item.validFrom, item.validUntil);
      return this.getPercentage(days + 1);
   },

   getDaysBetween: function (start, end) {
      var startMoment = !moment.isMoment(start) ? moment(start, 'YYYY-MM-DD') : start;
      var endMoment = !moment.isMoment(end) ? moment(end, 'YYYY-MM-DD') : end;

      if(startMoment.isBefore(this.displayStart)) {
         startMoment = this.displayStart;
      }
      if(endMoment.isAfter(this.displayEnd)) {
         endMoment = this.displayEnd;
      }
      return endMoment.diff(startMoment, 'days');
   },

   getRemainingDays: function (date) {
      return this.getDaysBetween(date, this.displayEnd);
   },

   getPercentage: function(days){
      var percentage = (days / this.daysInYear) * 100;

      if(percentage < 0) {
         percentage = 0;
      }
      if(percentage > 100) {
         percentage = 100;
      }
      return percentage + '%';
   }
});
