var Block = require('./schedule-block');

module.exports = Chaplin.Model.extend({

   defaults: {
      blocks: new Chaplin.Collection({model: Block})
   },

   initialize: function () {
   },

   copy: function(){
      var copy = this.clone();
      var blocks = _.map(this.attributes.blocks.models, function(slot){
         return slot.copy();
      });
      copy.attributes.blocks = new Chaplin.Collection(blocks, {model: Block});
      return copy;
   },

   set: function(attributes, options) {
      if (attributes.blocks !== undefined && !(attributes.blocks instanceof Chaplin.Collection)) {
         attributes.blocks = new Chaplin.Collection(attributes.blocks, {model: Block});
      }
      return Backbone.Model.prototype.set.call(this, attributes, options);
   }
});