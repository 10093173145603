import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';

import { msgNotifications as msg } from '../../localization/messages/components/user-settings';

export default class Notifications extends Component {

  notificationsChanged = (ev) => {
    ev.preventDefault();

    const { onChangeNotifications } = this.props;

    onChangeNotifications({
      bookingEmailPreference: ev.target.value
    });
  };

  render() {
    const { username, bookingEmailPreference, isChangingNotifications } = this.props;

    const radioClass = isChangingNotifications ? 'radio disabled' : 'radio';
    const boldProps = { b: (...chunks) => <strong>{chunks}</strong> };

    return (
      <fieldset>
        <legend><FormattedMessage {...msg.emailNotifications} /></legend>
        <div className="row">
          <div className="col-sm-6">

            <form>
              <div className={radioClass} style={{ marginBottom: 15 }}>
                <label>
                  <input
                    type="radio"
                    value="None"
                    checked={bookingEmailPreference == 'None'}
                    onChange={this.notificationsChanged}
                  />
                  <FormattedMessage {...msg.noMailing} values={boldProps} />
                </label>
              </div>
              <div className={radioClass} style={{ marginBottom: 15 }}>
                <label>
                  <input
                    type="radio"
                    value="Web"
                    checked={bookingEmailPreference == 'Web'}
                    onChange={this.notificationsChanged}
                  />
                  <FormattedMessage {...msg.emailToWebBookings} values={boldProps} />
                </label>
              </div>
              <div className={radioClass} style={{ marginBottom: 15 }}>
                <label>
                  <input
                    type="radio"
                    value="All"
                    checked={bookingEmailPreference == 'All'}
                    onChange={this.notificationsChanged}
                  />
                  <FormattedMessage {...msg.emailToAll} values={boldProps} />
                </label>
              </div>
            </form><br />
            <p>
              <FormattedMessage {...msg.noticesWillBeSentTo} values={{ username, ...boldProps }} />
            </p>
          </div>
        </div>
      </fieldset>
    );
  }
}

Notifications.propTypes = {
  username: PropTypes.string.isRequired,
  bookingEmailPreference: PropTypes.string.isRequired,
  isChangingNotifications: PropTypes.bool.isRequired,
  onChangeNotifications: PropTypes.func.isRequired
};
