var View = require('../base/view');

module.exports = View.extend({
   noWrap: true,
   autoRender: true,

   initialize: function() {
      this.template = require('./week-item');
      View.prototype.initialize.call(this, arguments);
   },

   events: {
     'click': 'itemClick'
   },

   itemClick: function(e) {
      e.preventDefault();
      this.trigger('item:click', this);
   }
});
