import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './state/reducers';
import { chaplinEventDispatcher } from './chaplin-bridges/chaplin-event-middleware';

const loggerMiddleware = createLogger();

const createStoreWithMiddleware = applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware,
      chaplinEventDispatcher
)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
