import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import { fetchResourceServices } from '../../state/bkf-actions';
import ChunkyCheckBox from '../inputs/checkbox-chunky';
import Bouncefix from './bouncefix';

import CurrencyUtil from '../../../utils/currency-util';

import { msgSearchService as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class SearchService extends Component {
  static propTypes = {
    resourceId: PropTypes.number.isRequired,
    services: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired,
    fetchResourceServices: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      multiple: props.selectedServices && props.selectedServices.size > 1,
      suggestions: props.services,
      isLoading: false,
      inputFocused: true
    };
  }

  componentDidMount() {
    const input = ReactDOM.findDOMNode(this.searchField);
    if (input) input.focus();
    this.loadServicesIfRequired(this.props.resourceId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.services !== nextProps.services) {
      this.setState({
        suggestions: this.getSuggestions('', nextProps.services)
      });
    }
  }

  loadServicesIfRequired(resourceId) {
    const lastLoaded = this.props.resourceServices.get('timestamp');
    const cacheExpired = lastLoaded && (new Date() - lastLoaded) > (1000 * 30); /* Expire the cache after 60 sec */

    // The cacheExpiry is only a safeguard so that the cache gets r eloaded eventually.
    // even if the computer has been in sleep mode while pusher events are sent
    // The cache is mainly used so that we dont issue multiple cache load everytime the BKF is updated
    // The grid will clear the cache on mount
    if (
      resourceId
      && this.props.resourceServices.get('state') !== 'LOADING'
      && (!this.props.resourceServices.has(resourceId) || cacheExpired)
    ) {
      this.props.fetchResourceServices(resourceId, cacheExpired);
    }
  }

  escapeRegexCharacters(str) {
    return str ? str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : '';
  }

  getSuggestions(value, services) {
    const escapedValue = this.escapeRegexCharacters(value).trim();
    if (!value || value.trim().length === 0) {
      return services;
    }
    if (escapedValue.length === 0) {
      return [];
    }

    const regex = new RegExp(`${escapedValue}`, 'i');
    const filteredServices = services.filter(s => regex.test(s.name));
    return [{ name: value }, ...filteredServices];
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.getSuggestions('', this.props.services)
    });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return;
    }

    this.setState({
      suggestions: this.getSuggestions(value, this.props.services)
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectSuggestion(suggestion);
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      ev.preventDefault();
      this.selectSuggestion(this.highlightedSuggestion);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  toggleMultiple = (ev, multiple) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ multiple });
  };

  selectSuggestion = (suggestion) => {
    if (suggestion && suggestion.id) {
      if (this.state.multiple) {
        const { selectedServices } = this.props;
        const selected = selectedServices && selectedServices.has(suggestion.id);

        if (selected) {
          this.props.onRemove(suggestion);
        } else {
          this.props.onAdd(suggestion);
        }
      } else {
        this.props.onSelected(suggestion);
      }
    } else {
      this.createServiceFromSearch();
    }
  };

  createServiceFromSearch = () => {
    const { startTime, endTime } = this.props;
    const serviceDuration = endTime.diff(startTime, 'minutes');

    this.props.onSelected({
      price: 0,
      afterTime: 0,
      name: this.state.value,
      serviceDuration,
      isNew: true
    });
  };

  renderInputComponent = (inputProps) => {
    return (
      <input
        {...inputProps}
        className="form-control"
        onFocus={(ev) => { inputProps.onFocus(ev); this.setState({ inputFocused: true }); }}
        /* setTimeout required on iOS, otherwise the 'select' event is ignored */
        onBlur={() => { setTimeout(() => { this.setState({ inputFocused: false }); }, 10); }}
        /* Important! Dont remove inputProps.ref call, subtle bug will be happening... */
        ref={(ref) => { inputProps.ref(ref); this.searchField = ref; }}
      />
    );
  };

  renderService = (suggestion) => {
    const { intl: { formatMessage: f } } = this.props;
    const search = [this.state.value];
    const afterTime = suggestion ? suggestion.afterTime : 0;
    const duration = suggestion ? suggestion.totalDuration - afterTime : 0;
    const priceStr = f(msg.price, { priceFrom: suggestion.priceFrom, price: CurrencyUtil.accountCurrency(suggestion.price, 0, suggestion.currency) });

    return (
      <div>
        <strong><Highlighter searchWords={search} textToHighlight={suggestion.name} autoEscape /></strong>
        <div>
          {f(msg.duration, { isAfterTimePositive: afterTime > 0, duration, afterTime })}
          {suggestion.price > 0 ? `, ${priceStr}` : null}
        </div>
      </div>
    );
  };

  renderToggle = (selected) => {
    return this.state.multiple && (
      <div className={selected ? 'toggle selected' : 'toggle'}>
        <i className="fa fa-check" />
      </div>
    );
  };

  renderCreateItem = () => {
    return (
      <section>
        <i className="fa fa-fw fa-plus" /> <strong><FormattedMessage {...msg.addService} /></strong>
      </section>
    );
  };

  renderSuggestion = (suggestion) => {
    const { selectedServices } = this.props;
    const selected = this.state.multiple && selectedServices && selectedServices.has(suggestion.id);

    return suggestion.id ? (
      <div className={selected ? 'booking-form-suggestion selected' : 'booking-form-suggestion'}>
        {this.renderService(suggestion)}
        {this.renderToggle(selected)}
      </div>
    ) : (
      <div className="booking-form-suggestion new-item">
        {this.renderCreateItem()}
      </div>
    );
  };

  render() {
    const { height, maxHeight, intl: { formatMessage: f } } = this.props;
    const { multiple, value, suggestions } = this.state;
    const iosWithOSKOpen = this.props.deviceOs === 'iOS' && !this.props.externalKeyboard && this.state.inputFocused !== false;

    const inputProps = {
      placeholder: f(msg.searchOrAdd),
      onKeyDown: this.handleKeyDown,
      onChange: this.onChange,
      value
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: iosWithOSKOpen ? 'booking-form-suggestions-container-ios' : 'booking-form-suggestions-container'
    };

    return (
      <div className="booking-form">
        <div className="booking-form-header">
          <div className="cancel">
            { multiple ? (<a href="#" onClick={this.handleCancel}><i className="fa fa-chevron-left" /> {f(msgButtons.btnClear)}</a>) :
            <a href="#" onClick={this.handleCancel}><i className="fa fa-chevron-left" /> {f(msgButtons.btnBack)}</a>}

          </div>
          <div className="multi-service-toggle" onClick={ev => this.toggleMultiple(ev, !multiple)}>
            {f(msg.selectMultiple)} <ChunkyCheckBox checked={multiple} />
          </div>
        </div>

        <Bouncefix className="booking-form-body" style={{ height, maxHeight }}>
          <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            getSuggestionValue={() => value}
            alwaysRenderSuggestions
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}
          />
        </Bouncefix>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, resourceServices } = state;
  const resourceId = bkf.get('resourceId');

  return {
    id: bkf.get('id'),
    service: bkf.get('service'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    resourceId,
    resourceServices,
    services: resourceServices.get(resourceId) || [],
    selectedServices: bkf.get('services')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResourceServices: (resId, clearCache) => {
      return dispatch(fetchResourceServices(resId, clearCache));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchService));
