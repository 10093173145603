import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { fetchLocationConfig } from '../../state/account-actions';
import { viewDateFromDate } from '../../utils/time-util';


const LocationLoader = (props) => {
  const url = lastCalendarRoute(props);
  return <Redirect push={false} to={url} />;
};

function lastCalendarRoute(props) {
  const { lastView } = props;
  if (lastView == null) {
    return getCalendarStartPath(props);
  }

  const { org, loc, viewDate, viewMode, entityType, entityId } = lastView;

  if (lastEntityIdExists(props)) {
    return `/${org}/${loc}/calendar/${viewMode}/${viewDate}/${entityType}/${entityId}`;
  }
  return `/${org}/${loc}/calendar/${viewMode}/${viewDate}/${entityType}/0`;
}

function lastEntityIdExists(props) {
  const { lastView, orderedGroups, resourcesById } = props;
  if (lastView == null) {
    return false;
  }

  if (lastView.entityType == 'group') {
    const group = orderedGroups.find(g => g.get('id') === parseInt(lastView.entityId));
    return group != undefined;
  }

  return resourcesById.has(parseInt(lastView.entityId));
}

function getCalendarStartPath(props) {
  const { org, loc } = props.match.params;
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');
  return props.resourceId == null ? `/${org}/${loc}/calendar/day/${today}/group/0` :
         `/${org}/${loc}/calendar/week/${thisWeek}/resource/${props.resourceId}`;
}

const mapStateToProps = (state, ownProps) => {
  const { orderedGroups, resourcesById } = state;

  return {
    orderedGroups,
    resourcesById,
    lastView: state.calendarViewState.get('lastView'),
    resourceId: state.locationConfig.get('resourceId'),
    locationRole: state.locationConfig.get('locationRole')

  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoad: () => {
      dispatch(fetchLocationConfig());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(LocationLoader);
