import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getFeatures } from '../../utils/selectors';
import { hasPermission, CHANGE_GROUPS, CHANGE_RESOURCES } from '../../utils/permissions';
import AddGroupForm from '../admin/add-group-form';
import AddItemForm from '../admin/add-item-form';
import { Popover } from '../common/popover';

import { msgResourceListToolbar as msg } from '../../localization/messages/components/resources';

class ResourceListToolbar extends Component {

  static propTypes = {
    onAddGroup: PropTypes.func.isRequired,
    onAddResource: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    features: PropTypes.object.isRequired,
    onToggleSorting: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      addGroup: false,
      addResource: false
    };
  }

  render() {
    return (
      <div className="columns-toolbar">
        {this.renderAddResourceButton()}
        {this.renderAddGroupButton()}
        {this.renderSortButton()}
      </div>
    );
  }

  renderSortButton() {
    const { permissions, features } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { ShowEditGroupResource } = features;

    if (!canChangeResource || !ShowEditGroupResource) {
      return null;
    }

    return <button className="btn btn-label-o pull-right" onClick={this.props.onToggleSorting}><FormattedMessage {...msg.btnSort} /></button>;
  }


  renderAddResourceButton() {
    const { permissions, features } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { ShowEditGroupResource } = features;

    if (!canChangeResource || !ShowEditGroupResource) {
      return null;
    }

    const popoverContent = this.addResourceContent();
    return (
      <Popover
        isOpen={this.state.addResource}
        body={popoverContent}
        preferPlace="below"
        onOuterAction={this.handleCloseResourcePop}
      >
        <button className="btn btn-label-success" onClick={this.handleOpenResourcePop}>
          <FormattedMessage {...msg.btnNewResource} />
        </button>
      </Popover>
    );
  }

  renderAddGroupButton() {
    const { permissions, features } = this.props;
    const canChangeGroups = hasPermission(permissions, CHANGE_GROUPS);
    const { ShowEditGroupResource } = features;

    if (!canChangeGroups || !ShowEditGroupResource) {
      return null;
    }

    const popoverContent = this.addGroupContent();
    return (
      <Popover
        isOpen={this.state.addGroup}
        body={popoverContent}
        preferPlace="below"
        onOuterAction={this.handleCloseGroupPop}
      >
        <button className="btn btn-label-o" onClick={this.handleOpenGroupPop}>
          <FormattedMessage {...msg.btnNewGroup} />
        </button>
      </Popover>
    );
  }

  handleOpenGroupPop = () => {
    this.setState({ addGroup: true });
  };

  handleCloseGroupPop = () => {
    this.setState({ addGroup: false });
  };

  handleOpenResourcePop = () => {
    this.setState({ addResource: true });
  };

  handleCloseResourcePop = () => {
    this.setState({ addResource: false });
  };

  handleAddGroup = (groupName) => {
    return this.props.onAddGroup({ name: groupName })
      .then(() => this.handleCloseGroupPop());
  };

  handleAddResource = (groupId, name) => {
    return this.props.onAddResource({ groupId, name, webName: name })
      .then(() => this.handleCloseResourcePop());
  };

  addResourceContent() {
    const { intl: { formatMessage: f } } = this.props;
    return (
      <AddItemForm
        onClosePopover={this.handleCloseResourcePop}
        onAddItem={this.handleAddResource}
        placeholder={f(msg.placeholderEnterName)}
        groups={this.props.orderedGroups}
      />
    );
  }

  addGroupContent() {
    return (
      <AddGroupForm
        onClosePopover={this.handleCloseGroupPop}
        onAddGroup={this.handleAddGroup}
      />
    );
  }
}


const mapStateToProps = (state) => {
  const { orderedGroups } = state;
  return {
    orderedGroups,
    features: getFeatures(state)
  };
};

export default injectIntl(connect(
  mapStateToProps
)(ResourceListToolbar));

