
export const SET_CF_STATE = 'SET_CF_STATE';

export function setCustomerFormState(state) {
  return {
    type: SET_CF_STATE,
    ...state
  };
}

