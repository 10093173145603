import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import RichTextEditor, { createValueFromString, createEmptyValue } from 'react-rte';

import { msgRichTextEditorField as msg } from '../../localization/messages/components/inputs';

class RichTextEditorField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: createEmptyValue()
    };
  }

  componentDidMount() {
    const { value } = this.props.input;

    if (value) {
      this.setValue(value);
    }
  }

  componentWillReceiveProps(nextProps) {
    const valueChanged = this.props.input.value !== nextProps.input.value;

    if (valueChanged && nextProps.meta.pristine) {
      this.setValue(nextProps.input.value);
    }
  }

  setValue = (value) => {
    this.setState({ value: createValueFromString(value, 'markdown') });
  };

  onChange = (value) => {
    this.setState({ value });
    this.props.input.onChange(value.toString('markdown'));
  };

  toolbarConfig = () => {
    const { intl: { formatMessage: f } } = this.props;
    return {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
      INLINE_STYLE_BUTTONS: [
            { label: f(msg.bold), style: 'BOLD' },
            { label: f(msg.italic), style: 'ITALIC' },
            { label: f(msg.underline), style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
            { label: f(msg.unstyled), style: 'unstyled' },
            { label: f(msg.header, { number: 1 }), style: 'header-one' },
            { label: f(msg.header, { number: 2 }), style: 'header-two' },
            { label: f(msg.header, { number: 3 }), style: 'header-three' }
      ],
      BLOCK_TYPE_BUTTONS: [
            { label: f(msg.unorderedList), style: 'unordered-list-item' },
            { label: f(msg.orderedList), style: 'ordered-list-item' }
      ]
    };
  };

  render() {
    return (
      <div>
        <RichTextEditor toolbarConfig={this.toolbarConfig()} value={this.state.value} onChange={this.onChange} />
      </div>
    );
  }
}

export default injectIntl(RichTextEditorField);
