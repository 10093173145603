import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';
import CurrencyUtil from '../../../utils/currency-util';

import { msgLabels } from '../../localization/messages/shared/labels';
import { msgSms as msg } from '../../localization/messages/components/reports';

class Sms extends Component {

  render() {
    const { reportsViewState } = this.props;
    const smsReport = reportsViewState.get('sms');
    const sentSmsPerResource = smsReport ? smsReport.get('sentSmsPerResource') : null;

    return (
      <div>
        {sentSmsPerResource && !sentSmsPerResource.isEmpty() ? this.renderTable(smsReport) : this.renderNoData(sentSmsPerResource)}
      </div>
    );
  }

  renderTable(smsReport) {
    const { resourcesById, intl: { formatMessage: f } } = this.props;
    const { sentSmsPerResource } = smsReport.toJS();

    let totalCount = 0;
    let totalCost = 0;
    const that = this;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{f(msg.resource)}</th>
            <th>{f(msg.sentSms)}</th>
            <th className="text-right">{f(msg.cost)}</th>
          </tr>
        </thead>
        {
               _.map(sentSmsPerResource, (item, key) => {
                 const resource = resourcesById.get(parseInt(key));
                 const resourceName = resource ? resource.name : f(msg.noResource);

                 return (
                   <tbody key={key}>
                     <tr className="invoice-group">
                       <td><strong>{resourceName}</strong></td>
                       <td />
                       <td />
                     </tr>
                     {
                           _.map(item, (count, tag) => {
                             const cost = count * that.smsTagCost(tag, smsReport);
                             totalCount += count;
                             totalCost += cost;
                             return (
                               <tr className="invoice-item" key={tag}>
                                 <td>{that.smsTagString(tag)}</td>
                                 <td>{count}</td>
                                 <td className="text-right">{CurrencyUtil.accountCurrency(cost)}</td>
                               </tr>
                             );
                           })
                        }
                   </tbody>
                 );
               })
            }
        <tfoot>
          <tr>
            <td><strong>{f(msgLabels.total)}</strong></td>
            <td><strong>{totalCount}</strong></td>
            <td className="text-right"><strong>{CurrencyUtil.accountCurrency(totalCost)}</strong></td>
          </tr>
        </tfoot>
      </table>
    );
  }

  renderNoData(sentSmsPerResource) {
    const { intl: { formatMessage: f } } = this.props;
    return sentSmsPerResource ?
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> {f(msg.noSentSms)}
      </div> : null;
  }

  smsTagString(tag) {
    const { intl: { formatMessage: f } } = this.props;
    switch (tag) {
      case 'Confirm':
        return f(msg.confirm);

      case 'Reminder':
        return f(msg.reminder);

      case 'Bulk':
        return f(msg.bulk);

      case 'Pin':
        return f(msg.pin);

      case 'Free':
        return f(msg.free);

      default:
        return f(msg.sms);
    }
  }

  smsTagCost(tag, smsReport) {
    const { smsReminderPrice, smsPinPrice, smsBulkPrice } = smsReport.toJS();

    switch (tag) {
      case 'Confirm':
      case 'Reminder':
        return smsReminderPrice;

      case 'Bulk':
        return smsBulkPrice;

      case 'Pin':
        return smsPinPrice;

      default:
        return 0;
    }
  }
}

Sms.propTypes = {
  reportsViewState: PropTypes.object.isRequired,
  resourcesById: PropTypes.object.isRequired
};

export default injectIntl(Sms);
