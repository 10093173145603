import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renameGroup, deleteGroup, moveResource, m, moveGroup } from '../../state/resource-actions';
import { getResourceConfigUrl, navigate } from '../../utils/navigate';
import SortableList from '../admin/sortable-list';

class ResourceList extends Component {
  render() {
    const { filteredGroups, filteredResources, routeParams, sortable } = this.props;
    const { moveResource, moveGroup, updateResourceGroup, deleteResourceGroup } = this.props;

    return (
      <SortableList
        groups={filteredGroups}
        groupItemSelector="resourceIds"
        items={filteredResources}
        itemContent={this.itemContent}
        selectedId={parseInt(routeParams.id)}
        onItemClick={this.onItemClick}
        updateGroup={updateResourceGroup}
        deleteGroup={deleteResourceGroup}
        moveGroup={moveGroup}
        moveItem={moveResource}
        sortable={sortable}
      />
    );
  }

  onItemClick = (item) => {
    const { routeParams } = this.props;
    navigate(getResourceConfigUrl(routeParams, item.id, routeParams.subsection));
  };

  itemContent = (item) => {
    const { name } = item;

    return (
      <div>
        <span>{name}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById, orderedGroups } = state;

  const filteredResources = ownProps.filter ? resourcesById.filter((srv) => {
    return srv.name.toLowerCase().indexOf(ownProps.filter.toLowerCase()) !== -1;
  }) : resourcesById;

  const filteredGroups = ownProps.filter ? orderedGroups.filter((group) => {
    let match = false;
    group.get('resourceIds').forEach((srvId) => {
      if (filteredResources.has(srvId)) {
        match = true;
      }
    });
    return match;
  }) : orderedGroups;

  return {
    resourcesById,
    orderedGroups,
    filteredResources,
    filteredGroups
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveResource: (moveAction) => {
      return dispatch(moveResource(moveAction));
    },
    moveGroup: (moveAction) => {
      return dispatch(moveGroup(moveAction));
    },
    updateResourceGroup: (group) => {
      return dispatch(renameGroup(group));
    },
    deleteResourceGroup: (groupId) => {
      return dispatch(deleteGroup(groupId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceList);
