var moment = require('moment');
var CollectionView = require('../base/collection-view');
var ScheduleItemView = require('./item-view');
var ScheduleItemModalView = require('./item-modal-view');
var Block = require('../../models/schedule-block');

module.exports = CollectionView.extend({
   nowrap: true,
   autoRender: false,
   listSelector: '.schedule-items',
   fallbackSelector: '.schedule-no-items',

   initialize: function () {
      this.template = require('./day');
      this.collection.comparator = 'start';
      this.collection.sort();
      CollectionView.prototype.initialize.call(this, arguments);
   },

   events: {
      'click .add-item': 'addItem'
   },

   initItemView: function (item) {
      item.set('day', this.model.get('day'));
      item.set('week', this.model.get('week'));

      var itemView = new ScheduleItemView({model: item});
      this.listenTo(itemView, 'item:click', _.bind(this.itemClick, this));
      return itemView;
   },

   addItem: function (ev) {
      var week = this.model.get('week');
      var day = this.model.get('day');
      var blocks = this.collection.where({day: day, week: week});
      var start = moment({hour: 9, minute: 0});
      var end = moment({hour: 17, minute: 0});
      var durationHours = 8;

      if (blocks.length > 0) {
         durationHours = 1;
         _.each(blocks, function (slot) {
            if (slot.get('end') > start) {
               start = slot.get('end');
            }
         });
         end = start.clone().add(durationHours, 'hours');
      }

      var item = new Block({
         newItem: true,
         durationHours: durationHours,
         start: start,
         end: end,
         day: day,
         week: week
      });
      this.showModal(item);
      return false;
   },

   itemClick: function (item) {
      item.model.set('newItem');
      this.showModal(item.model);
   },

   showModal: function (item) {
      var modal = new ScheduleItemModalView({model: item});
      this.listenTo(modal, 'save', _.bind(this.saveItem, this));
      this.listenTo(modal, 'delete', _.bind(this.deleteItem, this));
      this.subview('modal', modal);
   },

   saveItem: function (item) {
      if (item.get('newItem')) {
         this.collection.add(item);
      }
      else {
         this.removeSubview("itemView:" + item.cid);
      }
      this.collection.sort();
      this.trigger('changed');
   },

   deleteItem: function (item) {
      this.collection.remove(item);
      this.trigger('changed');
   }
});

