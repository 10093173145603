import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateServiceGroup, deleteServiceGroup, moveService, moveGroup } from '../../state/services-actions';
import { getSectionUrl, navigate } from '../../utils/navigate';
import SortableList from '../admin/sortable-list';
import CurrencyUtil from '../../../utils/currency-util';

import { msgLabels } from '../../localization/messages/shared/labels';

class ServiceList extends Component {

  static propTypes = {
    filteredServiceGroups: PropTypes.object.isRequired,
    filteredServices: PropTypes.object.isRequired,
    selectedId: PropTypes.number,
    moveService: PropTypes.func.isRequired,
    moveGroup: PropTypes.func.isRequired,
    updateServiceGroup: PropTypes.func.isRequired,
    deleteServiceGroup: PropTypes.func.isRequired,
    sortable: PropTypes.bool,
    routeParams: PropTypes.object.isRequired
  };

  render() {
    const { filteredServiceGroups, filteredServices, selectedId, sortable, readOnly } = this.props;
    const { moveService, moveGroup, updateServiceGroup, deleteServiceGroup } = this.props;

    return (
      <SortableList
        groups={filteredServiceGroups}
        groupItemSelector="serviceIds"
        items={filteredServices}
        itemContent={this.itemContent}
        selectedId={selectedId}
        onItemClick={this.onItemClick}
        updateGroup={updateServiceGroup}
        deleteGroup={deleteServiceGroup}
        moveGroup={moveGroup}
        moveItem={moveService}
        sortable={sortable}
        readOnly={readOnly}
      />
    );
  }

  onItemClick = (item) => {
    navigate(getSectionUrl(this.props.routeParams, 'services', item.id));
  };

  itemContent = (item) => {
    const { intl: { formatMessage: f } } = this.props;
    const { name, serviceDuration, totalDuration, afterTime, price, priceFrom, currency } = item;

    const duration = serviceDuration || totalDuration - (afterTime || 0);
    const durationStr = f(msgLabels.durationSrt, { isAfterTime: afterTime > 0, duration, afterTime });
    const priceStr = f(msgLabels.priceSrt, { priceFrom, price: CurrencyUtil.accountCurrency(price, 0, currency) });

    return (
      <div>
        <span>{name}</span><br />
        <span className="text-muted">{durationStr}, {priceStr}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { servicesById, orderedServiceGroups } = state;

  const filteredServices = ownProps.filter ? servicesById.filter((srv) => {
    return srv.name.toLowerCase().indexOf(ownProps.filter.toLowerCase()) !== -1;
  }) : servicesById;

  const filteredServiceGroups = ownProps.filter ? orderedServiceGroups.filter((group) => {
    let match = false;
    group.get('serviceIds').forEach((srvId) => {
      if (filteredServices.has(srvId)) {
        match = true;
      }
    });
    return match;
  }) : orderedServiceGroups;

  return {
    servicesById,
    orderedServiceGroups,
    filteredServices,
    filteredServiceGroups
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveService: (moveAction) => {
      return dispatch(moveService(moveAction));
    },
    moveGroup: (moveAction) => {
      return dispatch(moveGroup(moveAction));
    },
    updateServiceGroup: (group) => {
      return dispatch(updateServiceGroup(group));
    },
    deleteServiceGroup: (groupId) => {
      return dispatch(deleteServiceGroup(groupId));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceList));
