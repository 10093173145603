import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate, getGroupUrl, getResourceUrl } from '../../utils/navigate';
import { toggleGridScrollability } from '../../state/view-actions';
import { hasPermission, VIEW_ALL_BOOKINGS } from '../../utils/permissions';
import { getPermissions } from '../../utils/selectors';
import ResourceSelector from './resource-selector/resource-selector';

class MobileResourceSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeParams !== this.props.routeParams && this.state.open) {
      setTimeout(() => this.hideSelector(), 100);
    }
  }

  showSelector = (ev) => {
    ev.preventDefault();
    this.props.toggleScrollability(false);
    this.setState({ open: true });
  };

  hideSelector = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.toggleScrollability(true);
    this.setState({ open: false });
  };

  render() {
    return (
      <Fragment>
        <div className={this.state.open ? 'mobile-resource-selector-backdrop show' : 'mobile-resource-selector-backdrop'} role="presentation" onClick={this.hideSelector} />
        <div className={this.state.open ? 'mobile-resource-selector show' : 'mobile-resource-selector'}>
          <a href="#" className="mobile-resource-selector-close" onClick={this.hideSelector}><i className="fa fa-times" /></a>
          {this.resourceSelector()}
        </div>
        <ul className="nav navbar-nav">
          <li>
            {this.renderFoo()}
          </li>
        </ul>
      </Fragment>
    );
  }

  renderFoo() {
    const { permissions } = this.props;
    const canUseFoo = hasPermission(permissions, VIEW_ALL_BOOKINGS);

    return canUseFoo ? (
      <a href="#" className="mobile-nav-item" onClick={this.showSelector}>
        <i className="fa fa-fw fa-lg fa-bars" /> {this.currentResourceName()}
      </a>
    ) : (
      <a className="mobile-nav-item" style={{ pointerEvents: 'none' }}>
        {this.currentResourceName()}
      </a>
    );
  }

  resourceSelector() {
    return (
      <ResourceSelector
        routeParams={this.props.routeParams}
        resourceTargetUrlResolver={this.resolveResourceTargetUrl}
        groupTargetUrlResolver={this.resolveGroupTargetUrl}
      />
    );
  }

  resolveResourceTargetUrl = (resourceId) => {
    return getResourceUrl(resourceId, 'week', this.props.routeParams);
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.props.routeParams);
  };

  currentResourceName() {
    const { resourcesById, entityId, entityType, orderedGroups } = this.props;
    if (entityType === 'group') {
      const entity = orderedGroups.find(g => g.get('id') === entityId);
      return entity ? entity.get('name') : '';
    } else if (entityType === 'resource') {
      const entity = resourcesById.get(entityId);
      return entity ? entity.name : '';
    }
  }
}

MobileResourceSelector.propTypes = {
  tabletMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourcesById: PropTypes.object.isRequired,
  orderedGroups: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeResource: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, orderedGroups, resourcesById } = state;
  const { viewDate, viewMode, entityType, entityId } = ownProps.routeParams;

  return {
    viewDate,
    viewMode,
    entityType,
    entityId,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    resourcesById,
    orderedGroups,
    permissions: getPermissions(state, { routeParams: ownProps.routeParams })
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeGroup: (groupId) => {
      navigate(getGroupUrl(groupId, ownProps.routeParams));
    },
    changeResource: (resId) => {
      const routeParams = ownProps.routeParams;
      navigate(getResourceUrl(resId, routeParams.viewMode, routeParams));
    },
    toggleScrollability: (scrollable) => {
      dispatch(toggleGridScrollability(scrollable));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileResourceSelector);

