import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { resetNetworkFailure } from '../../state/network-actions';
import Modal from '../common/modal';

import { msgButtons } from '../../localization/messages/shared/buttons';

class NetworkErrorAlert extends Component {

  render() {
    const { title, description } = this.props;
    if (!description) return null;

    return (
      <Modal
        titleText="Error"
        underlayClickExits={false}
        includeDefaultStyles={false}
        dialogClass="dialog-content"
        underlayClass="dialog-container"
        initialFocus=".dialog-content"
        onExit={this.onClose}
        mounted
      >
        <div>
          <h2><i className="fa fa-exclamation-triangle" /> {title}</h2>
          <hr />
          <p>{description}</p>
          <hr />
          <button onClick={this.onClose} className="btn btn-default">
            <FormattedMessage {...msgButtons.btnClose} />
          </button>
        </div>
      </Modal>
    );
  }

  onClose = (ev) => {
    ev.preventDefault();
    this.props.onClose();
  };
}

NetworkErrorAlert.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired
};


const mapStateToProps = (state) => {
  const { appState } = state;

  return {
    title: appState.get('networkErrorTitle'),
    description: appState.get('networkError')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(resetNetworkFailure());
    }
  };
};


const NetworkErrorAlertContainer = connect(
      mapStateToProps,
      mapDispatchToProps
)(NetworkErrorAlert);


export default NetworkErrorAlertContainer;
