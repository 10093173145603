// eslint-disable-next-line import/no-unresolved
import { changeAccountStatus } from '../state/account-actions';
import { createCustomer, updateCustomer } from '../state/customer-actions';
import { networkFailure } from '../state/network-actions';

let reduxStore;

export function getLocationConfig() {
  return reduxStore.getState().locationConfig.toJS();
}

export function buildChaplinToReduxBridges(store) {
  window.reduxStore = reduxStore = store;

  setupMessageHandlers();
  setupMessageForwards();
}

function setupMessageForwards() {
  chaplinSubscribe('redux:event:LOADING', () => {
    chaplinPublish('loadingOverlay:show');
  });

  chaplinSubscribe('redux:event:LOADING_DONE', () => {
    chaplinPublish('loadingComplete');
    chaplinPublish('loadingOverlay:hide');
  });

  chaplinSubscribe('redux:event:SUCCESS', (action) => {
    chaplinPublish('alertOverlay:showSuccess', action.message);
  });
}

/**
 * Chaplin Events -> Redux Actions
 */

function setupMessageHandlers() {
  chaplinSubscribe('redux:createCustomer', handleCreateCustomer);
  chaplinSubscribe('redux:updateCustomer', handleUpdateCustomer);

  chaplinSubscribe('redux:changeAccountStatus', handleChangeAccountStatus);
  chaplinSubscribe('redux:networkError', handleNetworkError);
}

function handleCreateCustomer(customer) {
  reduxStore.dispatch(createCustomer(customer));
}

function handleUpdateCustomer(id, customer) {
  reduxStore.dispatch(updateCustomer(id, customer));
}

function handleChangeAccountStatus(event) {
  reduxStore.dispatch(changeAccountStatus(event.accountStatus, event.trialStatus, event.trialUntil, event.features));
}

function handleNetworkError() {
  reduxStore.dispatch(networkFailure());
}

export function chaplinSubscribe(channel, listener) {
   // Unsubscribe any previous event handlers
   //
  Chaplin.mediator.unsubscribe(channel);
  Chaplin.mediator.subscribe(channel, listener);
}

export function chaplinPublish(channel, event) {
  Chaplin.mediator.publish(channel, event);
}
