var moment = require('moment');
var s = require('underscore.string');
var ModalView = require('../base/modal-view');

module.exports = ModalView.extend({
   noWrap: true,
   autoRender: true,
   durationHours: 8,

   initialize: function (options) {
      if (options.durationHours) {
         this.durationHours = options.durationHours;
      }
      this.template = require('./item-modal');
      ModalView.prototype.initialize.call(this, arguments);
   },

   show: function(){
      ModalView.prototype.show.call(this, arguments);
      this.delegate('submit', 'form', this.save);
      this.delegate('click', '.js-delete', this.delete);
      this.setTitle(s.capitalize(moment(this.model.get('day'), 'd').format('dddd')));
   },

   save: function(ev){
      ev.preventDefault();
      var formData = {start: this.parseTime(this.startTime.val()), end: this.parseTime(this.endTime.val())};
      this.model.set(formData);
      this.trigger('save', this.model);
      this.close();
      return false;
   },

   delete: function(ev){
      ev.preventDefault();
      this.trigger('delete', this.model);
      this.close();
      return false;
   },

   render: function(){
      ModalView.prototype.render.call(this, arguments);
      this.initTimePicker();
      if (this.model.get('newItem') !== true){
         this.$('.js-delete').removeClass('hide');
      }
      this.$('form').parsley();
   },

   initTimePicker: function () {
      var options = {
        timeFormat: 'HH:mm',
        interval: 30,
        minTime: '0:00',
        maxTime: '23:30',
        dynamic: false,
        dropdown: true,
        scrollbar: true,
        zindex: 10001
      };

      this.startTime = this.$('.start').timepicker(options);

      this.endTime = this.$('.end').timepicker(options);

      this.setTime();
   },

   formatTime: function (value) {
      return moment(value).format('HH:mm');
   },

   parseTime: function (value) {
      var time = value.split(':');
      return moment({hour: time[0], minute: time[1]});
   },

   setTime: function(){
      this.startTime.val(this.formatTime(this.model.get('start')));
      this.endTime.val(this.formatTime(this.model.get('end')));
   },

   dispose: function() {
      var startTimePicker = this.startTime && this.startTime.timepicker();
      if (startTimePicker && startTimePicker.destroy) {
        startTimePicker.destroy();
      }
      var endTimePicker = this.endTime && this.endTime.timepicker();
      if (endTimePicker && endTimePicker.destroy) {
        endTimePicker.destroy();
      }
      ModalView.prototype.dispose.call(this, arguments);
   }
});
