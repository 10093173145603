var moment = require('moment');

module.exports = {
  initialize: function() {

     window.ParsleyConfig.successClass = '';
     window.ParsleyConfig.errorClass = 'has-error';
     window.ParsleyConfig.errorsWrapper = '<div></div>';
     window.ParsleyConfig.errorTemplate = '<span class="help-block"></span>';
     window.ParsleyConfig.excluded = 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden';

     window.ParsleyConfig.errorsContainer = function(ParsleyField) {
        var inputGroup = ParsleyField.$element.parent('.input-group');
        return inputGroup.length > 0 ? inputGroup.parent() : ParsleyField.$element.parent();
     };

     window.ParsleyConfig.classHandler = function(ParsleyField) {
        return ParsleyField.$element.closest('.form-group');
     };

     window.Parsley.addValidator('timedelta', function (value, requirement) {
        var start = moment($(requirement).val(), 'HH:mm');
        var end = moment(value, 'HH:mm');
        return start < end;
     }, 32);

  }
};
