import shallowEqual from 'fbjs/lib/shallowEqual';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import DatePickerView from '../../../views/calendar/datepicker/datepicker';
import { getGroupUrl, getResourceUrl } from '../../utils/navigate';
import { hasPermission, VIEW_ALL_BOOKINGS } from '../../utils/permissions';
import { viewDateToStartEnd } from '../../utils/time-util';
import ResourceSelector from './resource-selector/resource-selector';

class CalendarSideBar extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { showDatePicker: true };
  }

  componentDidMount() {
    const { routeParams: { viewDate, viewMode }, locale } = this.props;
    const defaultDate = viewDateToStartEnd(viewMode, viewDate).start;
    if (this.datePicker) {
      this.datePicker.dispose();
    }
    this.datePicker = new DatePickerView({
      locale,
      container: '#datePicker',
      viewMode: (!viewMode ? 'day' : viewMode),
      defaultDate,
      autoRender: true
    });
    this.datePicker.changeViewDate(this.props.routeParams);
  }

  componentWillUnmount() {
    this.datePicker.dispose();
    this.datePicker = null;
  }

  componentWillReceiveProps(nextProps) {
    if (!shallowEqual(this.props.routeParams, nextProps.routeParams)) {
      this.datePicker.changeViewDate(nextProps.routeParams);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showDatePicker !== prevState.showDatePicker) {
      this.props.updateViewDimensions();
    }
  }

  render() {
    return (
      <div className="calendar-sidebar">
        {this.shouldShowResources() && this.renderResourceSelector()}
        {this.renderDatePicker()}
      </div>
    );
  }

  renderResourceSelector() {
    const customStyle = {
      height: this.calculateResSelheight()
    };

    return (
      <ResourceSelector
        routeParams={this.props.routeParams}
        resourceTargetUrlResolver={this.resolveResourceTargetUrl}
        groupTargetUrlResolver={this.resolveGroupTargetUrl}
        onToggleSortable={this.handleToggleSortable}
        customStyle={customStyle}
      />);
  }

  renderDatePicker() {
    const { showDatePicker } = this.state;
    const style = { display: showDatePicker ? 'block' : 'none' };
    return (<div id="datePicker" style={style} />);
  }

  calculateResSelheight = () => {
    return this.state.showDatePicker ? 'calc(100% - 260px)' : '100%';
  };

  shouldShowResources() {
    const { permissions } = this.props;
    return hasPermission(permissions, VIEW_ALL_BOOKINGS);
  }

  resolveResourceTargetUrl = (resourceId) => {
    return getResourceUrl(resourceId, 'week', this.props.routeParams);
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.props.routeParams);
  };
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale
  };
};

export default connect(mapStateToProps)(CalendarSideBar);
