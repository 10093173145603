import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import shallowEqual from 'fbjs/lib/shallowEqual';
import color from 'color';
import ReactTooltip from 'react-tooltip';
import { clearResourceServices } from '../../state/bkf-actions';
import { calculateGridPixelsPerRow, calcGridScrollHeight } from './grid-state-helper';
import { fetchViewData, updateViewDimensions } from '../../state/view-actions';
import ColumnHeadersContainer from './column-headers';
import RowHeadersContainer from './row-headers';
import RowRulers from './row-rulers';
import RowMarker from './row-marker';
import ColumnRulersContainer from './column-rulers';
import ColumnScheduleBlocksContainer from './column-schedule-blocks';
import Chips from './chips';
import GridMarkerContainer from './grid-marker';
import ScheduleEditBannerContainer from './schedule-edit-banner';
import ClipboardContainer from '../clipboard/clipboard-container';

class Grid extends Component {

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.routeParams, prevProps.routeParams)) {
      this.props.fetchViewData();
    }
    if (this.props.scheduleEditMode !== prevProps.scheduleEditMode) {
      this.props.updateViewDimensions();
    }
    if (this.props.showClipboard !== prevProps.showClipboard) {
      this.props.updateViewDimensions();
    }
  }

  componentDidMount() {
    this.props.clearResourceServices();
    this.props.fetchViewData();

    window.addEventListener('contextmenu', this.preventContextMenu);
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.preventContextMenu);
  }

  preventContextMenu = (ev) => {
    if (this.element && this.element.contains(ev.target)) {
      ev.preventDefault();
    }
  };

  handleScroll = () => {
    ReactTooltip.hide();
  };

  render() {
    const { highContrast, scheduleEditMode, phoneMode, gridScrollHeight, gridClientHeight, gridScrollable } = this.props;

    const gridStyle = {
      height: gridScrollHeight
    };

    const gridClasses = highContrast ? 'web-booking-closed-hc' : 'web-booking-closed';
    const timeColumnWidth = phoneMode ? 35 : 50;

    return (
      <div id="calendargrid" ref={(ref) => { this.element = ref; }}>
        <ClipboardContainer routeParams={this.props.routeParams} />
        <ScheduleEditBannerContainer routeParams={this.props.routeParams} />
        <ColumnHeadersContainer routeParams={this.props.routeParams} />

        <div id="gridcontainer" className={gridScrollable ? '' : 'noscroll'} style={{ height: gridClientHeight }} onScroll={this.handleScroll}>
          <table>
            <tbody>
              <tr>
                <td style={{ width: timeColumnWidth }}>
                  <RowHeadersContainer routeParams={this.props.routeParams} />
                </td>
                <td id="gridcontainercell">
                  <div id="grid" style={gridStyle} className={gridClasses}>
                    <ColumnScheduleBlocksContainer routeParams={this.props.routeParams} />
                    <ColumnRulersContainer routeParams={this.props.routeParams} />
                    <RowRulers routeParams={this.props.routeParams} />
                    <RowMarker routeParams={this.props.routeParams} />
                    <Chips routeParams={this.props.routeParams} />
                    <GridMarkerContainer routeParams={this.props.routeParams} />
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Grid.propTypes = {
  pixelsPerRow: PropTypes.number.isRequired,
  gridSize: PropTypes.oneOf(['xsmall', 'small', 'large']).isRequired,
  associatedResourceId: PropTypes.number,
  gridScrollHeight: PropTypes.number.isRequired,
  highContrast: PropTypes.bool.isRequired,
  scheduleEditMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  gridClientHeight: PropTypes.number.isRequired
};

const getRowsPerHour = state => state.gridViewState.get('rowsPerHour');
const getGridSize = state => state.gridViewState.get('gridSize');


const pixelsPerRow = createSelector(
      getRowsPerHour,
      getGridSize,
      (rowsPerHour, gridSize) => {
        return calculateGridPixelsPerRow(rowsPerHour, gridSize);
      }
);

const gridScrollHeight = createSelector(
      pixelsPerRow,
      getRowsPerHour,
      (pixelsPerRow, rowsPerHour) => calcGridScrollHeight(pixelsPerRow, rowsPerHour)
);

const mapStateToProps = (state) => {
  const { gridViewState, mainViewState, locationConfig, bookingsClipboard } = state;

  const gridSize = gridViewState.get('gridSize'),
    rowsPerHour = gridViewState.get('rowsPerHour');


  return {
    forceUpdateTs: mainViewState.get('forceUpdateTs'),
    associatedResourceId: locationConfig.get('resourceId'),
    scheduleEditMode: gridViewState.get('scheduleEditMode'),
    showClipboard: bookingsClipboard.size > 0,
    phoneMode: mainViewState.get('phoneMode'),
    pixelsPerRow: pixelsPerRow(state),
    rowsPerHour,
    gridSize,
    gridScrollHeight: gridScrollHeight(state),
    gridClientHeight: gridViewState.get('gridClientHeight'),
    highContrast: gridViewState.get('highContrast'),
    gridScrollable: gridViewState.get('gridScrollable')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchViewData: () => {
      const { viewDate, viewMode, entityType, entityId } = ownProps.routeParams;
      dispatch(fetchViewData(viewMode, viewDate, entityType, entityId));
    },
    clearResourceServices: () => {
      dispatch(clearResourceServices());
    },
    updateViewDimensions: () => {
      dispatch(updateViewDimensions());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(Grid);
