var moment = require('moment');
var CustomerBooking = require('../models/customer-booking');

module.exports = Chaplin.Collection.extend({
   url: function() {
      return '/customers/' + this.customerId + '/bookings';
   },
   model: CustomerBooking,
   customerId: null,

   initialize: function() {
      this.comparator = this._sortByStartTimeDesc;
   },
   sortByStartTimeAsc: function() {
      this.comparator = this._sortByStartTimeAsc;
      this.sort();
   },
   sortByStartTimeDesc: function() {
      this.comparator = this._sortByStartTimeDesc;
      this.sort();
   },
   _sortByStartTimeAsc: function(item) {
      return moment(item.get('startTime'));
   },
   _sortByStartTimeDesc: function(item) {
      return -moment(item.get('startTime'));
   },
	fetch: function(customerId){
		this.customerId = customerId;
		Chaplin.Collection.prototype.fetch.call(this, arguments);
	}
});
