const React = require('react');
const moment = require('moment');
const s = require('underscore.string');
const Handlebars = require('handlebars/runtime');
const CurrencyUtil = require('./currency-util');

const { formatMessage } = require('../redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../redux-dashl/localization/messages/handlebars/handlebars');

module.exports = {
  initialize() {
    Handlebars.registerHelper('Call', _.bind(this.call, this));
    Handlebars.registerHelper('i18n', _.bind(this.localize, this));
    Handlebars.registerHelper('Time', _.bind(this.formatTime, this));
    Handlebars.registerHelper('DateTime', _.bind(this.formatDate, this));
    Handlebars.registerHelper('RoleDescription', _.bind(this.roleDescription, this));
    Handlebars.registerHelper('SchedExceptionClosed', _.bind(this.scheduleExceptionClosed, this));
    Handlebars.registerHelper('SchedExceptionOpen', _.bind(this.scheduleExceptionOpen, this));
  },

  localize(id, options) {
    const messageObject = msgHandlebars[id] || msgHandlebars.fallback;
    const props = {};

    Object.keys(options.hash).forEach((key) => {
      props[key] = options.hash[key];
    });

    return formatMessage(messageObject, props);
  },

  call(func) {
    if (_.isFunction(func)) {
      return func.call();
    }
  },

  accountCurrency(value, decimals = 0, currencyCode) {
    if (value !== undefined) {
      return CurrencyUtil.accountCurrency(value, decimals, currencyCode);
    }
  },

  formatTime(value) {
    if (value) {
      return moment(value).format('HH:mm');
    }
  },

  formatDate(value, format) {
    if (value) {
      const _moment = moment(value);
      if (_moment.isSame('9999-01-01')) {
        return 'tillsvidare';
      }
      return s.capitalize(_moment.format(_.isString(format) ? format : 'LLLL'));
    }
  },

  roleDescription(userRole, orgRole) {
    if (orgRole && orgRole === 'OWNER') {
      return `<strong>${formatMessage(msgHandlebars.roleAccountOwner)}</strong> - ${formatMessage(msgHandlebars.descriptionAccountOwner)}`;
    }

    switch (userRole) {
      case 'Administrator':
        return `<strong>${formatMessage(msgHandlebars.roleAdministrator)}</strong> - ${formatMessage(msgHandlebars.descriptionAdministrator)}`;

      case 'FrontOfficeUser':
        return `<strong>${formatMessage(msgHandlebars.roleFrontOfficeUser)}</strong> - ${formatMessage(msgHandlebars.descriptionFrontOfficeUser)}`;

      case 'UnprivilegedUser':
        return `<strong>${formatMessage(msgHandlebars.roleUnprivilegedUser)}</strong> - ${formatMessage(msgHandlebars.descriptionUnprivilegedUser)}`;

      default:
        return userRole;
    }
  },

  scheduleExceptionClosed(exception) {
    let str = '';
    let dlm = '';
    _.each(exception.closed, (c) => {
      str = `${str + dlm + c.start} - ${c.end}`;
      dlm = ', ';
    }, this);
    return str;
  },

  scheduleExceptionOpen(exception) {
    if (exception.type === 'CLOSED_ALLDAY') {
      return `<span class="text-danger">${formatMessage(msgHandlebars.closed)}</span>`;
    }

    return `<span class="text-success">${exception.start} - ${exception.end}</span>`;
  }

};
