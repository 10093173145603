import moment from 'moment';
import s from 'underscore.string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { calendar } from '../../../utils/preference-keys';
import { getColumnsSelector, getGridProps, getScheduleBlockSelector } from './grid-selectors';
import ColumnWebIndicator from './column-web-indicator';

class ColumnHeaders extends Component {
  static propTypes = {
    viewMode: PropTypes.oneOf(['week', 'day']).isRequired,
    entityType: PropTypes.oneOf(['resource', 'group']).isRequired,
    timeColumnWidth: PropTypes.number.isRequired,
    phoneMode: PropTypes.bool.isRequired,
    scrollBars: PropTypes.bool.isRequired,
    scrollBarWidth: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    resourcesById: PropTypes.object.isRequired
  };

  componentDidMount() {
      // This will make the today indicator move to the next day at midnight..
      //
    this.timer = setInterval(() => {
      this.forceUpdate();
    }, 60000); /* run every minute */
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const { columns, scrollBars, scrollBarWidth, timeColumnWidth, labelColumnHeight, phoneMode } = this.props;
    const scrollBar = scrollBars ? <td width={scrollBarWidth} /> : null;
    const widthPct = 100 / columns.length;
    const style = i => ({
      left: `${i * widthPct}%`,
      width: `${widthPct}%`,
      fontSize: phoneMode ? '0.8em' : '1.0em'
    });

    return (
      <div id="columncontainer">
        <table>
          <tbody>
            <tr>
              <td className="column-time" style={{ width: timeColumnWidth }} />
              <td className="column-labels" style={{ height: labelColumnHeight }} >
                {columns.map((column, i) => (
                  <div id={`ch${i}`} key={`ch${i}`} style={style(i)} className="header-item vrule">
                    {this.columnLabel(column)}
                  </div>
                ))}
              </td>
              {scrollBar}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  columnLabel(column) {
    const { viewMode, entityType } = this.props;

    if (viewMode === 'week') {
      return this.weekDayLabel(column);
    }
    return entityType === 'group'
      ? this.groupLabel(column)
      : this.resourceDayLabel(column);
  }

  columnWebIndicator(column) {
    const { phoneMode, scheduleBlocks, showWebBookingIndicator } = this.props;

    return showWebBookingIndicator && (
      <ColumnWebIndicator
        column={column}
        phoneMode={phoneMode}
        scheduleBlocks={scheduleBlocks}
      />
    );
  }

  resourceDayLabel(column) {
    const label = this.props.viewMode === 'day'
      ? moment(column.date).format('ddd D MMM, YYYY')
      : moment(column.date).format('dd D');

    return (
      <div className="resource-label">
        {this.columnWebIndicator(column)}
        <span>{label}</span>
      </div>
    );
  }

  weekDayLabel(column) {
    const { viewMode, phoneMode } = this.props;
    const isToday = moment(column.date).isSame(new Date(), 'day');
    const hlToday = viewMode === 'week' && isToday;
    const dayFormat = phoneMode ? 'dd' : 'ddd';
    const circleSize = phoneMode ? 18 : 22;

    const style = {
      borderRadius: '50%',
      width: hlToday ? circleSize : '',
      height: circleSize,
      background: hlToday ? '#e28f6f' : '',
      display: 'inline-block',
      verticalAlign: 'middle',
      color: hlToday ? 'white' : 'black',
      margin: '-4px 0 -2px 2px',
      paddingTop: phoneMode ? 0 : 2
    };

    return (
      <div className="date-label">
        {this.columnWebIndicator(column)}
        <span>
          {s.capitalize(moment(column.date).format(dayFormat))}
          <span style={style}>{moment(column.date).format('D')}</span>
        </span>
      </div>
    );
  }

  groupLabel(column) {
    return (
      <div className="group-label">
        {this.columnWebIndicator(column)}
        <span>{this.props.resourcesById.get(column.resId).name}</span>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const { resourcesById, locationConfig } = state;
  const { viewMode, entityType } = ownProps.routeParams;

  const scheduleBlocks = getScheduleBlockSelector(state, ownProps);
  const columns = getColumnsSelector(state, ownProps);
  const gridProps = getGridProps(state, ownProps);
  const phoneMode = state.mainViewState.get('phoneMode');
  const timeColumnWidth = phoneMode ? 35 : 50;
  const labelColumnHeight = phoneMode ? 35 : 35;
  const showWebBookingIndicator = locationConfig.get(calendar.showWebBookingIndicator);

  return {
    viewMode,
    entityType,
    scrollBars: gridProps.scrollBars,
    scrollBarWidth: gridProps.scrollBarWidth,
    timeColumnWidth,
    labelColumnHeight,
    phoneMode,
    columns,
    scheduleBlocks,
    resourcesById,
    showWebBookingIndicator
  };
};

export default connect(
      mapStateToProps
)(ColumnHeaders);
