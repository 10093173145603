import moment from 'moment';
import { formatMessage } from '../intlContextInterseptor';

import { msgTemplates as msg } from '../localization/messages/utils/utils';

export function defaultSmsConfirmTemplate() {
  return formatMessage(msg.defaultSmsConfirmTemplate);
}

export function defaultSmsReminderTemplate() {
  return formatMessage(msg.defaultSmsReminderTemplate);
}

export function templatePreviewValues({ resourceName, serviceName, companyName, phoneNumber }) {
  return {
    '#TIDPUNKT': moment('2019-09-26 15:00').format('LLLL'),
    '#RESURS': resourceName || 'Maria Andersson',
    '#TJÄNST': serviceName || 'Klippning',
    '#FÖRETAG': companyName || 'Dashl',
    '#TEL': phoneNumber || '08-410 224 60',
    '#BOKNINGSREF': 'x1x2x3xy',
    '#KUNDNAMN': 'Lars Johansson',
    '#KUNDORGNR': '555555-5555',
    '#KUNDORGNAMN': 'Företaget AB',
    '#FORDONREG': 'ABC123'
  };
}

export function getPreviewText(value, replaceValues) {
  if (!value) {
    return value;
  }
  for (const key in replaceValues) {
    value = value.replace(new RegExp(key, 'gi'), replaceValues[key]);
  }
  return value;
}

export function getLongestName(entitiesById) {
  return entitiesById && entitiesById.size > 0
    ? entitiesById.reduce((a, b) => { return a.name.length > b.name.length ? a : b; }).name
    : null;
}
