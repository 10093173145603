var ModalView = require('../../base/modal-view');
var FormUtil = require('../../../utils/form-util');
var ConfirmDeletePopoverView = require('../../base/confirm-popover-view');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = ModalView.extend({

    initialize: function (options) {
        this.users = options.users;
        this.template = require('./edit-user-form');
        ModalView.prototype.initialize.call(this, arguments);

        this.getResources();
    },

    render: function () {
        ModalView.prototype.render.call(this, arguments);
        this.setTitle(this.model.get('username'));
        this.$('form').parsley();
    },

    events: {
        'submit form': 'edit',
        'click .js-delete': 'confirmDelete'
    },

    getFormData: function(){
        return this.$('form').serializeJSON();
    },

    edit: function (e) {
        e.preventDefault();

        if (!this.validate()){
            return;
        }

        var data = this.getFormData();
        data.resourceId = parseInt(data.resourceId);

        // unchanged user role if role field not visible
        if (!data.hasOwnProperty("userRole")){
            data.userRole = this.model.get('userRole');
        }

        $.ajax({
            type: 'PUT',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            url: '/users/' + this.model.id,
            data: JSON.stringify(data),
            success: _.bind(this.saved, this, data)
        });
    },

    saved: function (data) {
        this.model.set(data); // todo fix update list, pass() on list item view should work
        this.trigger('saved', this.model);
        this.close();
    },

    confirmDelete: function (e) {
        e.preventDefault();

        this.confirmationView = new ConfirmDeletePopoverView({
            title: formatMessage(msgHandlebars.deleteUsersTitle),
            confirmText: formatMessage(msgHandlebars.deleteUsersText),
            buttonText: formatMessage(msgHandlebars.deleteUsersTitle),
            callback: _.bind(this.delete, this),
            parent: $(e.currentTarget)
        });
        this.subview('popover', this.confirmationView);
    },

    delete: function () {
        this.confirmationView.close();

        $.ajax({
            type: 'DELETE',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            url: '/users/' + this.model.id,
            success: _.bind(this.deleted, this)
        });
    },

    deleted: function () {
        this.trigger('deleted', this.model);
        this.close();
    },

    getResources: function(){
        $.ajax({
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            url: '/resources/',
            success: _.bind(this.loadResources, this)
        });
    },

    validate: function() {
        var isUnprivilegedUser = this.$('.js-role-select').val() === 'UnprivilegedUser';
        var hasSelectedResource = this.$('.js-resource-select').val() !== '';

        if (isUnprivilegedUser && !hasSelectedResource){
            var content = formatMessage(msgHandlebars.userValidationText);
            this.$('.js-resource-select-section').addClass('has-error');
            this.$('#no-selected-resource-help').text(content);
            return false;
        }

        this.$('.js-resource-select-section').removeClass('has-error');
        this.$('#no-selected-resource-help').text('');
        return true;
    },

    loadResources: function(resources) {
        var model = this.model;
        var users = this.users;
        var select = this.$('.js-resource-select');
        select.empty().append(function() {
            var output = `<option value="">${formatMessage(msgHandlebars.noResource)}</option>`;
            $.each(resources, function(key, value) {
                if(users.where({resourceId: value.id}).length === 0 || model.get('resourceId') === value.id) {
                    output += '<option value="' + value.id + '">' + value.name + '</option>';
                }
            });
            return output;
        });
        FormUtil.populate(this.$('form'), this.model.attributes);
    }
});
