
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { msgConfirmResize as msg } from '../../localization/messages/components/grid';
import { msgButtons } from '../../localization/messages/shared/buttons';

const ConfirmResize = ({ duration, onClosePopover, onConfirmResize }) => {
  return (
    <div className="Popover-content">
      <div className="booking-form-block nopointer">
        <p><strong><FormattedMessage {...msg.question} values={{ duration }} /></strong></p>
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-default" onClick={onClosePopover}><FormattedMessage {...msgButtons.btnCancel} /></button> &nbsp;
        <button type="button" className="btn btn-success" onClick={onConfirmResize}><FormattedMessage {...msgButtons.btnChange} /></button>
      </div>
    </div>
  );
};

ConfirmResize.propTypes = {
  duration: PropTypes.number.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onConfirmResize: PropTypes.func.isRequired
};

export default ConfirmResize;
