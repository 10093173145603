var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"schedule-timeline-item\" style=\"left: "
    + alias3((helpers.GetItemOffset || (depth0 && depth0.GetItemOffset) || alias2).call(alias1,depth0,{"name":"GetItemOffset","hash":{},"data":data}))
    + "; width: "
    + alias3((helpers.GetItemWidth || (depth0 && depth0.GetItemWidth) || alias2).call(alias1,depth0,{"name":"GetItemWidth","hash":{},"data":data}))
    + ";\">\n    <p class=\"text-nowrap\">\n        <strong>"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</strong><br>\n        <em>"
    + alias3((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.validFrom : depth0),"LL",{"name":"DateTime","hash":{},"data":data}))
    + "</em>\n    </p>\n</div>";
},"useData":true});