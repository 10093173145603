var PopoverView = require('./popover-view');

module.exports = PopoverView.extend({
   placement: 'top',
   class: 'popover-sm',
   buttonClass: 'btn-danger',
   exclusive: false,
   alwaysOnTop: true,

   initialize: function (options) {
      if(options.buttonClass) {
         this.buttonClass = options.buttonClass;
      }
      this.confirmText = options.confirmText;
      this.additionalText = options.additionalText;
      this.buttonText = options.buttonText;
      this.callback = options.callback;
      this.template = require('./confirm-popover');
      PopoverView.prototype.initialize.call(this, arguments);
   },

   events: {
     'click .js-action': 'buttonClicked'
   },

   getTemplateData: function () {
      return {
         buttonClass: this.buttonClass,
         confirmText: this.confirmText,
         additionalText: this.additionalText,
         buttonText: this.buttonText
      };
   },

   getTitle: function () {
      return this.title;
   },

   render: function() {
      PopoverView.prototype.render.call(this, arguments);
      this.reRender();
   },

   update: function(options) {
      if(options.title) {
         this.title = options.title;
         this.setTitle(options.title);
      }
      if(options.buttonText) {
         this.buttonText = options.buttonText;
      }
      if(options.confirmText) {
         this.confirmText = options.confirmText;
      }
      if(options.additionalText) {
         this.additionalText = options.additionalText;
      }
      if(options.callback) {
         this.callback = options.callback;
      }
      this.reRender();
   },

   reRender: function() {
      this.$('.js-confirm-text').text(this.confirmText);
      this.$('.js-additional-text').text(this.additionalText);
      this.$('.js-action').text(this.buttonText).prop('disabled', !(this.callback && _.isFunction(this.callback)));
   },

   buttonClicked: function() {
      if(this.callback && _.isFunction(this.callback)) {
         this.callback();
      }
   }
});