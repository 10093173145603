import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { showSearch, resetSearch, searchBookings } from '../../state/booking-actions';

import { msgSearchField as msg } from '../../localization/messages/components/nav';

class SearchField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: props.query || ''
    };

    this.debouncedSearch = _.debounce(this.props.searchBookings, 200);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isShowingSearch) {
      this.setState({ query: '' });
    }
  }

  showSearch = () => {
    if (!this.props.isShowingSearch && this.state.query.length > 0) {
      this.props.showSearch();
    } else if (this.props.isShowingSearch && this.state.query.length == 0) {
      this.props.resetSearch();
    }
  };

  onChange = (ev) => {
    this.setState({ query: ev.target.value }, this.showSearch);
    this.debouncedSearch(ev.target.value);
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    this.debouncedSearch(this.state.query);
  };

  onBlur = () => {
    if (this.state.query.length === 0) {
      this.props.resetSearch();
    }
  };

  onKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      this.resetSearch(ev);
    }
  };

  resetSearch = (ev) => {
    ev.preventDefault();
    this.setState({ query: '' }, () => this.input.focus());
    this.props.resetSearch();
  };

  render() {
    const { intl: { formatMessage: f } } = this.props;
    const { query } = this.state;
    const inputClass = query.length > 0 ? 'form-control search focus' : 'form-control search';

    return (
      <div className="navbar-right">
        <form className="navbar-form" onSubmit={this.onSubmit}>
          {query && <a href="#" onClick={this.resetSearch} className="reset-search"><i className="fa fa-times-circle" /></a>}
          <span className="search-icon"><i className="fa fa-search" /></span>
          <input
            type="text"
            className={inputClass}
            placeholder={f(msg.search)}
            value={query}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            ref={(ref) => { this.input = ref; }}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bookingSearchResults } = state;

  return {
    query: bookingSearchResults.get('query'),
    isShowingSearch: bookingSearchResults.get('showSearch')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchBookings: (query) => {
      return dispatch(searchBookings(query));
    },
    showSearch: () => {
      dispatch(showSearch());
    },
    resetSearch: () => {
      dispatch(resetSearch());
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchField));
