import { defineMessages } from 'react-intl';

export const msgChangePassword = defineMessages({
  wrongPasswordText: {
    id: 'user-settings.ChangePassword.wrongPasswordText',
    defaultMessage: 'Du har angett felaktigt nuvarande lösenord.'
  },
  passwordPolicyText: {
    id: 'user-settings.ChangePassword.passwordPolicyText',
    defaultMessage: 'Lösenordet uppfyller inte lösenordspolicyn.'
  },
  changePassword: {
    id: 'user-settings.ChangePassword.changePassword',
    defaultMessage: 'Ändra lösenord'
  },
  changingPassword: {
    id: 'user-settings.ChangePassword.changingPassword',
    defaultMessage: 'Ändrar lösenord...'
  },
  passwordChanged: {
    id: 'user-settings.ChangePassword.passwordChanged',
    defaultMessage: 'Ditt lösenord har nu ändrats!'
  },
  chooseNewPassword: {
    id: 'user-settings.ChangePassword.chooseNewPassword',
    defaultMessage: 'Välj nytt lösenord'
  },
  enterCurrentPassword: {
    id: 'user-settings.ChangePassword.enterCurrentPassword',
    defaultMessage: 'Ange ditt nuvarande lösenord för att bekräfta lösenordsbytet'
  },
  currentPassword: {
    id: 'user-settings.ChangePassword.currentPassword',
    defaultMessage: 'Nuvarande lösenord'
  },
  policyMinLength: {
    id: 'user-settings.ChangePassword.policyMinLength',
    defaultMessage: 'Lösenordet måste vara minst {value} tecken'
  },
  policyCaps: {
    id: 'user-settings.ChangePassword.policyCaps',
    defaultMessage: 'Lösenordet måste innehålla minst {value} stora bokstäver'
  },
  policyDigits: {
    id: 'user-settings.ChangePassword.policyDigits',
    defaultMessage: 'Lösenordet måste innehålla minst {value} siffror'
  },
  policySpecials: {
    id: 'user-settings.ChangePassword.policySpecials',
    defaultMessage: 'Lösenordet måste innehålla minst {value} specialtecken'
  }
});

export const msgNotifications = defineMessages({
  emailNotifications: {
    id: 'user-settings.Notifications.emailNotifications',
    defaultMessage: 'E-postnotiser'
  },
  noMailing: {
    id: 'user-settings.Notifications.noMailing',
    defaultMessage: '<b>Av</b> - Inga utskick via e-post'
  },
  emailToWebBookings: {
    id: 'user-settings.Notifications.emailToWebBookings',
    defaultMessage: '<b>Webbokningar</b> - E-post vid nya webbokningar & avbokningar'
  },
  emailToAll: {
    id: 'user-settings.Notifications.emailToAll',
    defaultMessage: '<b>Alla bokningar</b> - E-post vid alla nya bokningar & avbokningar'
  },
  noticesWillBeSentTo: {
    id: 'user-settings.Notifications.noticesWillBeSentTo',
    defaultMessage: 'Notiser för dina bokningar skickas till <b>{username}</b>.'
  }
});

export const msgPasswordPolicy = defineMessages({
  passwordConsistsOf: {
    id: 'user-settings.PasswordPolicy.passwordConsistsOf',
    defaultMessage: 'Lösenordet måste bestå av'
  },
  policyMinLength: {
    id: 'user-settings.PasswordPolicy.policyMinLength',
    defaultMessage: 'minst {value} tecken'
  },
  policyCaps: {
    id: 'user-settings.PasswordPolicy.policyCaps',
    defaultMessage: 'minst {value} stora bokstäver'
  },
  policyDigits: {
    id: 'user-settings.PasswordPolicy.policyDigits',
    defaultMessage: 'minst {value} siffror'
  },
  policySpecials: {
    id: 'user-settings.PasswordPolicy.policySpecials',
    defaultMessage: 'minst {value} specialtecken'
  }
});

export const msgPasswordStrength = defineMessages({
  tip: {
    id: 'user-settings.PasswordStrength.tip',
    defaultMessage: 'Tips! Välj ett långt lösenord med 8 tecken eller mer. Blanda små/stora bokstäver med siffror och specialtecken (till exempel <withApos>#</withApos>, <withApos>@</withApos> och <withApos>!</withApos>) för ännu högre säkerhet.'
  },
  veryWeak: {
    id: 'user-settings.PasswordStrength.veryWeak',
    defaultMessage: 'Mycket svagt'
  },
  weak: {
    id: 'user-settings.PasswordStrength.weak',
    defaultMessage: 'Svagt'
  },
  medium: {
    id: 'user-settings.PasswordStrength.medium',
    defaultMessage: 'Medium'
  },
  strong: {
    id: 'user-settings.PasswordStrength.strong',
    defaultMessage: 'Starkt'
  },
  veryStrong: {
    id: 'user-settings.PasswordStrength.veryStrong',
    defaultMessage: 'Mycket starkt'
  }
});
