var View = require('../../base/view');
var PopoverView = require('../../base/popover-view');

var ConfirmView = View.extend({
   autoRender:true,

   initialize: function () {
      this.template = require('./confirm');
      View.prototype.initialize.call(this, arguments);
   },

   render: function () {
      View.prototype.render.call(this, arguments);
      if (this.model.get('duplicateCount') > 0){
         this.$('.js-duplicates').removeClass('hidden');
         this.$('.js-no-duplicates').addClass('hidden');
      }
      else{
         this.$('.js-duplicates').addClass('hidden');
         this.$('.js-no-duplicates').removeClass('hidden');
      }
   }
});

module.exports = PopoverView.extend({
   class: 'popover-sm',
   placement: 'bottom',

   initialize: function () {
      this.template = require('./merge');
      PopoverView.prototype.initialize.call(this, arguments);
   },

   events: {
      'submit form': 'save'
   },

   getTitle: function () {
      return 'Ta bort dubbletter';
   },

   render: function () {
      PopoverView.prototype.render.call(this, arguments);
      this.loadDuplicates();
   },

   loadDuplicates: function(){
      $.ajax({
         type: 'GET',
         contentType: 'application/json; charset=utf-8',
         dataType: 'json',
         url: '/customers/duplicates',
         success: _.bind(function(result) {
            this.loadSuccess(result);
         }, this)
      });
   },

   loadSuccess: function(result){
      this.$('.js-loading').addClass('hidden');
      this.duplicateData = result;
      var confirm = new ConfirmView({container:'.js-content', model: new Chaplin.Model({duplicateCount:result.duplicateCount})});
      this.subview('content', confirm);
   },

   save: function (e) {
      e.preventDefault();

      var data = [];

      _.each(this.duplicateData.duplicates, function(d) {
         console.log('adding one duplicate',d);
         data.push({
            id: d.id,
            duplicateCustomerIds: d.duplicateCustomerIds
         });
      }, this);

      console.log('saving ', data, this.duplicateData);

      var $btn = $(e.currentTarget);
      $btn.button('loading');

      $.ajax({
         type: 'PUT',
         contentType: 'application/json; charset=utf-8',
         dataType: 'json',
         url: '/customers/merge',
         data: JSON.stringify(data),
         success: _.bind(function(result) {
            this.saveSuccess(result);
         }, this)
      })
      .done(function(){
         $btn.button('reset');
      });

      return false;
   },

   saveSuccess: function(result, data) {
      this.publishEvent('customers:merged', this.duplicateData.duplicateCount);
      this.close();
   }
});