import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { msgAddGroupForm as msg } from '../../localization/messages/components/admin';
import { msgButtons } from '../../localization/messages/shared/buttons';

class AddItemForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    groups: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      group: props.groups.first().get('id'),
      progress: false
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  handleAdd = (ev) => {
    ev.preventDefault();
    this.setState({ progress: true });
    this.props.onAddItem(this.state.group, this.state.name)
      .catch(() => this.setState({ progress: false }));
  };

  render() {
    const { name, group, progress } = this.state;
    const { onClosePopover, groups, placeholder, intl: { formatMessage: f } } = this.props;
    const disabled = !name || progress;

    return (
      <div className="Popover-content-small">
        <form onSubmit={this.handleAdd}>
          <div className="form-group">
            <label className="control-label">{f(msg.lblName)}</label>
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              ref={(ref) => { this.input = ref; }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
          </div>

          <div className="form-group">
            <label className="control-label">{f(msg.lblGroup)}</label>
            <select name="group" className="form-control" tabIndex="2" value={group} onChange={ev => this.setState({ group: ev.target.value })}>
              {groups.map(group => (
                <option key={group.get('id')} value={group.get('id')}>{group.get('name')}</option>
              ))}
            </select>
          </div>

          <div className="text-right">
            <button type="button" className="btn btn-label-o" onClick={onClosePopover}>{f(msgButtons.btnCancel)}</button> &nbsp;
            <button type="submit" className="btn btn-label-success" disabled={disabled}>{f(msgButtons.btnAdd)}</button>
          </div>
        </form>
      </div>
    );
  }
}

export default injectIntl(AddItemForm);
