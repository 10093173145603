import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsSysAdmin } from '../../../utils/selectors';
import { setLocationFeature } from '../../../state/features-actions';
import { Popover } from '../../common/popover';
import { msgFeature as msg } from '../../../localization/messages/components/account-config';

class Feature extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  };

  state = {
    progress: false,
    showConfirm: false
  };

  showConfirm = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirm: true, progress: false });
  };

  hideConfirm = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirm: false });
  };

  enableFeature = (ev) => {
    ev.preventDefault();
    return this.setLocationFeature(true);
  };

  disableFeature = (ev) => {
    ev.preventDefault();
    return this.setLocationFeature(false);
  };

  setLocationFeature = (enabled) => {
    this.setState({ progress: true });
    return this.props.setLocationFeature(enabled)
      .then(() => this.setState({ showConfirm: false, progress: false }))
      .catch(() => this.setState({ progress: false }));
  };

  render() {
    const { showConfirm, progress } = this.state;
    const { name, icon, title, description, enabled, canEnable, usePopover, intl: { formatMessage: f } } = this.props;
    const popoverContent = enabled ? this.disablePopoverContent() : this.enablePopoverContent();

    return (
      <div className="feature">
        <Popover key={`${name}${enabled}`} isOpen={showConfirm} body={popoverContent} onOuterAction={this.hideConfirm}>
          {enabled ?
            <div className="pull-right text-center">
              <div className="label label-success"><i className="fa fa-check" /> {f(msg.lblEnabled)}</div>
              {canEnable && <a href="#" className="text-danger" onClick={usePopover ? this.showConfirm : this.disableFeature}>{f(msg.btnTurnOff)}</a>}
            </div> :
            <div className="pull-right">
              <button className="btn-save" onClick={usePopover ? this.showConfirm : this.enableFeature} disabled={progress || !canEnable}>{f(msg.btnTurnOn)}</button>
            </div>
          }
        </Popover>
        <h4 style={{ marginTop: 0 }}>
          <i className={icon} />
          <span style={{ margin: '0 10px 0 5px' }}>{title}</span>
        </h4>
        {description}
      </div>
    );
  }

  enablePopoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirm} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        {f(msg.contentEnable, { title: this.props.title, b: (...chunks) => <strong>{chunks}</strong> })}<br /><br />
        <button className="btn-save btn-block" onClick={this.enableFeature} disabled={this.state.progress}>{f(msg.btnTurnOn)}</button>
      </div>
    );
  }

  disablePopoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirm} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        {f(msg.contentDisable, { title: this.props.title, b: (...chunks) => <strong>{chunks}</strong> })}<br /><br />
        <button className="btn-delete btn-block" onClick={this.disableFeature} disabled={this.state.progress}>{f(msg.btnTurnOff)}</button>
      </div>
    );
  }
}

const mapStateToProps = (state, { name, dependency, sysAdminRequired }) => {
  const { locationFeatures } = state;
  const isSysAdmin = getIsSysAdmin(state);
  const hasRequiredDependency = !dependency || locationFeatures.get(dependency);
  const hasRequiredPermission = !sysAdminRequired || isSysAdmin;

  return {
    enabled: locationFeatures.get(name),
    canEnable: hasRequiredDependency && hasRequiredPermission
  };
};

const mapDispatchToProps = (dispatch, { name }) => {
  return {
    setLocationFeature: (enabled) => {
      return dispatch(setLocationFeature(name, enabled));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Feature));
