var s = require('underscore.string');

module.exports = function (number, countryCode, nddPrefix, isInternational) {
   this.number = number;
   this.countryCode = countryCode;
   this.nddPrefix = nddPrefix;
   this.isInternational = isInternational;

   this.localized = function () {
      if (this.isInternational) {
         return this.e164formatted();
      } else {
         return this.withNDD();
      }
   };

   this.withNDD = function () {
      return (this.number === undefined || s.isBlank(this.number)) ? "" : this.nddPrefix + this.number;
   };

   this.withCountryCode = function () {
      return (this.number === undefined || s.isBlank(this.number)) ? "" : this.countryCode + this.number;
   };

   this.e164formatted = function () {
      return '+' + this.withCountryCode();
   };
};
