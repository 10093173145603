import { defineMessages } from 'react-intl';

export const msgUndoButton = defineMessages({
  durationChanged: {
    id: 'undo-button.UndoButton.durationChanged',
    defaultMessage: 'Bokningens tid eller längd har ändrats, klicka på {icon} för att ångra den senaste ändringen.'
  },
  doNotShowAgain: {
    id: 'undo-button.UndoButton.doNotShowAgain',
    defaultMessage: 'Visa inte detta meddelande igen'
  }
});
