var View = require('../../base/view');
var CollectionView = require('../../base/collection-view');
var EditUserFormView = require('./edit-user-form-view');
var ConfirmDeletePopoverView = require('../../base/confirm-popover-view');
var CreateUserView = require('./create-user-view');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

var UserListItemView = View.extend({
   noWrap: true,
   autoRender: true,

   initialize: function(){
      this.template = require('./user-list-item');
      View.prototype.initialize.call(this, arguments);
   },

   itemClick: function(ev){
      ev.preventDefault();
      this.trigger('edit', this.model);
      return false;
   },

   deleteClick: function(ev){
      ev.preventDefault();
      this.confirmationView = new ConfirmDeletePopoverView({
         title: formatMessage(msgHandlebars.cancelInvitationTitle),
         confirmText: formatMessage(msgHandlebars.cancelInvitationText, { username: this.model.get('username') }),
         buttonText: formatMessage(msgHandlebars.cancelInvitationTitle),
         callback: _.bind(this.delete, this),
         parent: $(ev.currentTarget)
      });
      this.subview('popover', this.confirmationView);
      return false;
   },

   delete: function () {
      this.confirmationView.close();

      $.ajax({
         type: 'DELETE',
         url: '/users/invite/' + this.model.get('inviteId'),
         success: _.bind(this.deleted, this)
      });
   },

   deleted: function () {
      this.trigger('deleted', this.model);
   },

   render: function(){
      View.prototype.render.call(this, arguments);
      this.delegate('click', '.js-edit', this.itemClick);
      this.delegate('click', '.js-delete', this.deleteClick);
   }
});

module.exports = CollectionView.extend({
   noWrap: true,
   autoRender: true,
   containerMethod: 'html',
   listSelector: '.js-list-container',

   events:{
     'click .js-add': 'addUser'
   },

   initialize: function () {
      this.template = require('./users-list');
      this.collection.comparator = 'username';
      CollectionView.prototype.initialize.call(this, arguments);
   },

   initItemView: function (item) {
      var itemView = new UserListItemView({ model: item });
      this.listenTo(itemView, 'edit', this.edit);
      this.listenTo(itemView, 'deleted', this.deleted);
      return itemView;
   },

   addUser: function(e) {
      e.preventDefault();
      var createUser = new CreateUserView({ model: this.model, users: this.collection });
      this.listenTo(createUser, 'userCreated', this.created);
      this.subview('create-user', createUser);
   },

   edit: function (user) {
      var modal = new EditUserFormView({ model: user, users: this.collection });
      this.listenTo(modal, 'saved', this.saved);
      this.listenTo(modal, 'deleted', this.deleted);
      this.subview('modal', modal);
   },

   created: function(item) {
      this.collection.add(item);
      this.publishEvent('alertOverlay:showSuccess', formatMessage(msgHandlebars.nameHasBeenAdded, { username: item.username }));
   },

   saved: function (item) {
      this.collection.reset();
      this.collection.fetch();
   },

   deleted: function (item) {
      if(item.get('inviteId')) {
         var invite = this.collection.where({inviteId: item.get('inviteId')});
         this.collection.remove(invite);
      }
      else {
         this.collection.remove(item.id);
      }
   },

   render: function(){
      CollectionView.prototype.render.call(this, arguments);
      this.collection.sort();
   }
});
