import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResourceSubNav from './resource-subnav';
import ResourceSettings from './settings/resource-settings';
import ResourceServices from './services/resource-services';
import ResourceConfigChaplin from './chaplin-resource-config';

class ResourceContent extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    resourcesById: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="columns-content">
        <ResourceSubNav routeParams={this.props.routeParams} />
        <div className="columns-content-container">
          <div className="columns-content-body">
            {this.renderSubsection()}
          </div>
        </div>
      </div>
    );
  }

  renderSubsection() {
    const { subsection } = this.props.routeParams;
    switch (subsection) {
      case 'settings':
        return <ResourceSettings routeParams={this.props.routeParams} />;
      case 'services':
        return <ResourceServices routeParams={this.props.routeParams} />;
      case 'schedule':
        return <ResourceConfigChaplin routeParams={this.props.routeParams} />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById } = state;
  const { entityId: resourceId } = ownProps.routeParams;

  return {
    resourceId,
    resourcesById
  };
};

export default connect(mapStateToProps)(ResourceContent);
