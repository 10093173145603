/**
 * This method calculates the compensation time to be applied when week view is active and the week contains a day which needs to be adjusted for summer or winter time.
 *
 * @param isWeekView
 * @param day - a 'momentjs' object for which we should calculate the compensation time.
 * @returns {number} - compensation time in seconds
 */

import moment from 'moment';

export function calcWeekViewTimeOffsetForDay(isWeekView, day) {
  let compTime = 0;

  const msOnDay = moment(day).startOf('day').add(1, 'd') - moment(day).startOf('day');

   // if (isWeekView) {
      // Measure the day length
      // Note!! startOf is mutating the passed in date, so make defensive copy

      // const msOnDay = moment(day).startOf('day').add(1, 'd') - moment(day).startOf('day');

  if (msOnDay === 90000000) {
    compTime = -(60 * 60);
  } else if (msOnDay < 86400000) {
    compTime = 60 * 60;
  }
   // }
  return compTime;
}

export function calcHeightFromMinutes(gridProps, minutes, useIndent) {
  const { gridScrollHeight, chipIndentPixels } = gridProps;
  const minToPixelFactor = (gridScrollHeight / 24) / 60;
  return (minutes * minToPixelFactor) - (useIndent ? chipIndentPixels : 0);
}
/**
 * Calculates the time of day for a given grid y-coordinate on a given day
 *
 * @param gridProps - the current grid properties
 * @param ycoord - the y-coord (int)
 * @param day - a 'momentjs' object which represents the time of day for the passed in y-coord and day
 * @returns {*}
 */
export function calcTimeFromYCoord(gridProps, ycoord, day) {
  const minutesPerRow = 60 / gridProps.rowsPerHour;
  const minuteOffset = Math.floor(ycoord / gridProps.pixelsPerRow) * minutesPerRow;

  const compTime = calcWeekViewTimeOffsetForDay(gridProps.isWeekView, day);

  return moment(day).startOf('day').add(minuteOffset, 'minutes').subtract(compTime, 'seconds');
}

export function calcTimeFromXYCoord(gridProps, xCoord, yCoord, day) {
  if (gridProps.isWeekView) {
    const columnIdx = Math.round(xCoord / gridProps.columnWidth);

      // ISO week: 1 = monday, 7 = sunday
    day = moment(day).isoWeekday(columnIdx + 1);
  }

  return calcTimeFromYCoord(gridProps, yCoord, day);
}


/**
 * Translates a string rep of a viewmode/viewdate, e.g. week/2015-39 to an array of days. e.g. ['2015-09-21', '2015-09-22','2015-09-23','2015-09-24']
 *
 * @param viewMode 'week' or 'day'
 * @param viewDate 'YYYY-GG' or 'YYYY-MM-DD'
 * @param format
 * @returns [] of day strings. ['2015-09-21', '2015-09-22','2015-09-23','2015-09-24']

 */
function viewDateToDays(viewMode, viewDate, format = 'YYYY-MM-DD') {
  if (viewMode === 'week') {
    const days = [];
    const startOfWeek = moment(viewDate, 'GGGG-WW').startOf('isoWeek'); // will be 00:00

    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.format(format));
      startOfWeek.add(1, 'd');
    }
    return days;
  }
  return [{ viewDate }];
}


export function viewDateToMomentDays(viewMode, viewDate) {
  if (viewMode === 'week') {
    const days = [];
    const startOfWeek = moment(viewDate, 'GGGG-WW').startOf('isoWeek'); // will be 00:00

    for (let i = 0; i < 7; i++) {
      days.push(moment(startOfWeek)); // Defensive copy
      startOfWeek.add(1, 'd');
    }
    return days;
  }
  return [moment(viewDate, 'YYYY-MM-DD')];
}

/**
 * Translate the string rep to start/end of the viewDate period
 *
 * @param viewMode 'week' or 'day'
 * @param viewDate 'YYYY-GG' or 'YYYY-MM-DD'
 * @returns {Object} - start and end of the current viewDate as momentjs objects
 */
export function viewDateToStartEnd(viewMode, viewDate) {
  if (viewMode === 'week') {
    const startOfWeek = moment(viewDate, 'GGGG-WW').startOf('isoWeek');
    return {
      start: startOfWeek,
      end: moment(startOfWeek).add(7, 'd')
    };
  }
  const date = moment(viewDate, 'YYYY-MM-DD');

  return {
    start: date,
    end: moment(date).add(1, 'd')
  };
}


/**
 * Translate the date into a viewDate (e.g. 2015-39 for 'week' and 2015-10-10 for 'day')
 *
 * @param viewMode
 * @param date
 * @returns {*}
 */
export function viewDateFromDate(viewMode, date) {
  return viewMode === 'week' ? moment(date).startOf('isoWeek').format('GGGG-WW') : moment(date).format('YYYY-MM-DD');
}

export function viewDateToViewDate(newViewMode, previousViewMode, previousViewDate) {
  if (newViewMode === 'day' && previousViewMode === 'week') {
    const previousWeek = viewDateToStartEnd(previousViewMode, previousViewDate);
    const today = moment();

    if (previousWeek.start.isBefore(today) && previousWeek.end.isAfter(today)) {
         // show today since the first day of the week is passed
      return viewDateFromDate(newViewMode, today);
    }

      // show first day of the previously selected week
    return viewDateFromDate(newViewMode, previousWeek.start);
  }

  return viewDateFromDate(newViewMode, viewDateToStartEnd(previousViewMode, previousViewDate).start);
}

export function scheduleBlockToMomentDates(block) {
  return {
    dayStart: moment(block.day).startOf('day'),
    blStart: moment(`${block.day} ${block.start}`, 'YYYY-MM-DD HH:mm'),
    blEnd: moment(`${block.day} ${block.end}`, 'YYYY-MM-DD HH:mm')
  };
}

/**
 * Set the date to another date without affecting the time
 *
 * @param dateTime, moment
 * @param newDate, moment
 */
export function setNewDate(dateTime, newDate) {
  return dateTime.year(newDate.year()).month(newDate.month()).date(newDate.date());
}

/*
This method is used to handle the special case of 'today' in the url
 */
export function translateRouteParams(routeParams, hash) {
  const { viewDate, viewMode } = routeParams;

  let viewParams;
  if (viewDate === 'today') {
    viewParams = {
      viewDate: viewDateFromDate(viewMode, moment())
    };
  }

  let hashParams;
  if (hash) {
    hashParams = {
      bookingId: hash.replace(/[^0-9]/, '')
    };
  }

  return {
    ...routeParams,
    ...viewParams,
    ...hashParams
  };
}


export const pixelMultiplier = (rowsPerHour) => {
  // if(rowsPerHour === 1) return 0.5;
  if (rowsPerHour < 3) return 1;
  if (rowsPerHour > 6) return 4;
  return 2;
};
