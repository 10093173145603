import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CurrencyUtil from '../../../utils/currency-util';

import { msgBookingPrice as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editPrice: false
    };
  }

  componentDidMount() {
    if (this.state.editPrice) {
      this.focusPriceField();
    }
  }

  focusPriceField = () => {
    if (this.price) {
      const price = ReactDOM.findDOMNode(this.price);
      price.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editPrice: true }, () => this.focusPriceField());
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editPrice: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={{ position: 'absolute', visibility: 'hidden' }} />;
  }

  paymentProviderIcon = (paymentProvider) => {
    switch (paymentProvider) {
      case 'Credit':
        return <i className="fa fa-money pull-right text-muted" />;

      case 'Stripe':
        return <i className="fa fa-credit-card pull-right text-muted" />;

      case 'Klarna':
        return <i className="fa fa-file-text-o pull-right text-muted" />;

      case 'Swish':
        return <img src="/swish-logo.svg" className="swish" />;

      case 'PayPal':
        return <i className="fa fa-paypal pull-right text-muted" />;

      case 'ApplePay':
        return <i className="fa fa-apple pull-right text-muted" />;

      case 'IDEAL':
        return <img src="/ideal-logo.svg" className="ideal" />;

      default:
        return null;
    }
  }

  paymentStatusIcon = (paymentStatus) => {
    switch (paymentStatus) {
      case 'Paid':
        return 'fa fa-check-circle text-success';

      case 'Refunded':
        return 'fa fa-arrow-circle-left text-muted';

      case 'RefundFailed':
        return 'fa fa-exclamation-circle text-error';

      default:
        return 'fa fa-info-circle text-muted';
    }
  }

  number = val => parseFloat(val || 0);

  getCalculatedPrice = () => {
    // If a booking has been changed, and payment provider is AtLocation, amount will always be 0
    // Until this is fixed in the backend, we need to calculate the price manually
    // Subscriptions are not included in the calculation since we don't have the value

    const { payment, creditUsed, price } = this.props;
    const { voucherValue } = payment;

    return this.number(price) - this.number(creditUsed) - this.number(voucherValue);
  };

  render() {
    const { editPrice } = this.state;
    const { payment, creditUsed, price, status, intl: { formatMessage: f } } = this.props;
    const isCancelled = status === 'Cancelled';

    if (payment) {
      const { paymentProvider, paymentStatus, currency, amount, voucherCode, voucherValue, userSubscriptionIds } = payment;
      const isUnpaid = paymentStatus === 'Unpaid';
      const isAtLocation = paymentProvider === 'AtLocation';
      const subscriptionUsed = userSubscriptionIds && userSubscriptionIds.length > 0;
      const hasChangedAmountIssue = isAtLocation && isUnpaid && parseInt(amount) === 0;
      const totalPrice = hasChangedAmountIssue ? this.getCalculatedPrice() : amount;

      return (
        <div className="booking-form-block small cancelled">
          <h4>
            {this.paymentProviderIcon(paymentProvider)}
            {!isUnpaid && <i className={this.paymentStatusIcon(paymentStatus)} style={{ marginRight: 5 }} />}
            {CurrencyUtil.accountCurrency(totalPrice, 2, currency)}
          </h4>
          {amount !== price && <div>{f(msg.regularPrice, { price: CurrencyUtil.accountCurrency(price, 2, currency) })}</div>}
          {voucherValue && <div>{f(msg.discount, { voucherValue: CurrencyUtil.accountCurrency(voucherValue, 2, currency), voucherCode })}</div>}
          {creditUsed && <div>{f(msg.creditsUsed, { value: CurrencyUtil.accountCurrency(creditUsed, 2, currency) })}</div>}
          {subscriptionUsed && <div>{f(msg.subscriptionUsed)}</div>}
        </div>
      );
    }

    if (editPrice) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{f(msg.lblPrice)}</label>
              <label className="form-control-label">{CurrencyUtil.currencySymbol()}</label>
              <Field name="price" component="input" type="number" className="form-control" ref={(ref) => { this.price = ref; }} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{f(msgButtons.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!payment && typeof price !== 'undefined') {
      return (
        <div className="booking-form-block small cancelled">
          {CurrencyUtil.accountCurrency(price, 2)}
        </div>
      );
    }

    if (!price || price === 0) {
      return (
        <button className="booking-form-block button" onClick={this.handleClick}>
          <i className="fa fa-dollar" /> {f(msg.btnAddPrice)}
        </button>
      );
    }

    return (
      <div className={isCancelled ? 'booking-form-block small cancelled' : 'booking-form-block small'} onClick={!isCancelled ? this.handleClick : null}>
        <div className="text-center text-info">
          <h4>{CurrencyUtil.accountCurrency(price, 0)}</h4>
        </div>
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    initialValues: bkf.get('service'),
    payment: bkf.get('payment'),
    ...bkf.get('attributes'),
    ...getFormValues('bkf-price')(state)
  };
};

export default injectIntl(connect(mapStateToProps)(reduxForm({
  form: 'bkf-price',
  destroyOnUnmount: false
})(BookingPrice)));
