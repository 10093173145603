var moment = require('moment');
var View = require('../../base/view');
var ConfirmDeletePopoverView = require('../../base/confirm-popover-view');

const { formatMessage } = require('../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = View.extend({
	noWrap: true,
	autoRender: true,

	initialize: function () {
      this.template = require('./schedule-exception-item');
		View.prototype.initialize.call(this, arguments);
	},

	events: {
		'click .js-delete' : 'confirmDelete'
	},

	confirmDelete: function (e) {
		e.preventDefault();

		this.confirmationView = new ConfirmDeletePopoverView({
			title: formatMessage(msgHandlebars.removeExceptionsTitle),
			confirmText: formatMessage(msgHandlebars.removeExceptionsText, { dateString: moment(this.model.attributes.date).format('dddd LL') }),
			buttonText: formatMessage(msgHandlebars.removeExceptionsTitle),
			callback: _.bind(this.delete,  this),
			parent: $(e.currentTarget)
		});
		this.subview('popover', this.confirmationView);
		return false;
	},

	delete: function () {
		this.confirmationView.close();
		this.trigger('delete', this.model);
		return false;
	},

	render: function () {
		View.prototype.render.call(this, arguments);
   }
});
