import isRetryAllowed from 'is-retry-allowed';
import moment from 'moment';
import { prefixAjaxUrl } from './url-util';
import { clientBuildNumber } from './config';
import { versionMismatch, networkFailure } from '../state/network-actions';
import { changeAccountStatus } from '../state/account-actions';
import { NO_AUTH_TOKEN } from '../../redux-login/actions';

export function prefixUrl(url) {
  return prefixAjaxUrl(url);
}

export function prefixOrgUrl(url) {
  return prefixAjaxUrl(url, true);
}

export function logError(error) {
  console.error('Exception in fetch chain', error.message, error);
  Sentry.captureException(error);
}

export function fetchErrorHandler(error, callback) {
  logError(error);
  if (callback) callback();
  return networkFailure();
}

export function axiosErrorHandler(error, dispatch, callback) {
  if (error.response && error.response.status === 401) {
    dispatch({ type: NO_AUTH_TOKEN });
  } else {
    dispatch(fetchErrorHandler(error, callback));
  }
}

function checkVersion(response) {
  return (dispatch) => {
    const requiredVersion = response.headers.get('X-DashlClientVersion');
    const currentVersion = clientBuildNumber();

    if (currentVersion) {
      if (currentVersion !== 'LOCAL' && requiredVersion !== currentVersion) {
        console.warn(`New client version available, refresh required. Current version: ${currentVersion}, new version: ${requiredVersion}`);
        dispatch(versionMismatch(currentVersion, requiredVersion));
      }
    }
  };
}

function checkVersionAxios(headers) {
  return (dispatch) => {
    const requiredVersion = headers['x-dashlclientversion'];
    const currentVersion = clientBuildNumber();

    if (currentVersion) {
      if (currentVersion !== 'LOCAL' && requiredVersion != currentVersion) {
        console.warn(`New client version available, refresh required. Current version: ${currentVersion}, new version: ${requiredVersion}`);
        dispatch(versionMismatch(currentVersion, requiredVersion));
      }
    }
  };
}

function checkAccountStatusAxios(headers) {
  return (dispatch) => {
    const features = headers['x-dashl-features'];
    const trialUntil = headers['x-dashl-trial-until'];
    const trialStatus = headers['x-dashl-trial-status']; // Tri/al, TrialExpired
    const accountStatus = headers['x-dashl-account-status']; // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled

    if (accountStatus) {
      const trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
      dispatch(changeAccountStatus(accountStatus, trialStatus, trialUntilMoment, features));
    }
  };
}

export function checkStatusAxios(response) {
  return (dispatch) => {
    dispatch(checkAccountStatusAxios(response.headers));
    dispatch(checkVersionAxios(response.headers));

    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      dispatch({
        type: NO_AUTH_TOKEN
      });
    } else {
      const error = new Error(response.status);
      error.message = `Network request failed", type: ${response.type} status: ${response.status}, message: ${response.statusText}`;
      error.response = response;
      throw error;
    }
  };
}

function checkAccountStatus(response) {
  return (dispatch) => {
    const features = response.headers.get('X-Dashl-Features');
    const trialUntil = response.headers.get('X-Dashl-Trial-Until');
    const trialStatus = response.headers.get('X-Dashl-Trial-Status'); // Trial, TrialExpired
    const accountStatus = response.headers.get('X-Dashl-Account-Status'); // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled

    if (accountStatus) {
      const trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
      dispatch(changeAccountStatus(accountStatus, trialStatus, trialUntilMoment, features));
    }
  };
}


export function checkStatus(response) {
  return (dispatch) => {
    dispatch(checkAccountStatus(response));
    dispatch(checkVersion(response));

    if (response.ok) { /*  >= 200  < 300 */
      return response;
    } else if (response.status == 401) {
      dispatch({
        type: NO_AUTH_TOKEN
      });
    } else {
      const error = new Error(response.status);
      error.message = `Network request failed", type: ${response.type} status: ${response.status}, message: ${response.statusText}`;
      error.response = response;
      throw error;
    }
  };
}


function withDefaultParams(method, body, accept, contentType) {
  const accessToken = localStorage.getItem('accessToken');
  // This is due to some race condition where the pusherSocketId isn't set when we first wants to issue an ajax request.
  //
  const pusherSocketId = window.pusherSocketId ? window.pusherSocketId : '';

  return Object.assign({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: accept,
      'X-Client-Id': pusherSocketId,
      'Content-Type': contentType
    },
    mode: 'cors',
    cache: 'default'
  }, { method }, body);
}

function encodeFormData(obj) {
  const formData = [];
  for (const key in obj) {
    formData.push(`${key}=${encodeURIComponent(obj[key])}`);
  }
  return formData.join('&');
}

function jsonRequest(method, body) {
  const json = body !== undefined ? { body: JSON.stringify(body) } : {};
  return withDefaultParams(method, json, 'application/json', 'application/json');
}

function formRequest(method, body) {
  const form = body !== undefined ? { body: encodeFormData(body) } : {};
  return withDefaultParams(method, form, '*/*', 'application/x-www-form-urlencoded');
}

export function fetchDelete() {
  return jsonRequest('DELETE');
}

export function fetchPut(body) {
  return jsonRequest('PUT', body);
}

export function fetchPatch(body) {
  return jsonRequest('PATCH', body);
}

export function fetchPost(body) {
  return jsonRequest('POST', body);
}

export function fetchPostForm(body) {
  return formRequest('POST', body);
}

export function fetchGet() {
  return jsonRequest('GET');
}


export function axiosDefault() {
  const accessToken = localStorage.getItem('accessToken');
  const pusherSocketId = window.pusherSocketId ? window.pusherSocketId : '';

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'X-Client-Id': pusherSocketId,
      'Content-Type': 'application/json'
    }
  };
}


export function isNetworkError(error) {
  return !error.response
    && Boolean(error.code) // Prevents retrying cancelled requests
    && isRetryAllowed(error); // Prevents retrying unsafe errors
}

const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);

export function isRetryableError(error) {
  return (!error.response || (error.response.status >= 500 && error.response.status <= 599));
}

export function isSafeRequestError(error) {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return isRetryableError(error) && SAFE_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

export function isIdempotentRequestError(error) {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

export function isNetworkOrIdempotentRequestError(error) {
  return isNetworkError(error) || isIdempotentRequestError(error);
}

export function isNetworkOrSafeRequestError(error) {
  return isNetworkError(error) || isSafeRequestError(error);
}
