import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';
import { getPreviewText } from '../../utils/templates';
import TagsInfo from './tags-info';

import { msgSmsTemplateInput as msg } from '../../localization/messages/components/inputs';

class SmsTemplateInput extends Component {
  state = {
    isEditing: false
  };

  render() {
    const { isEditing } = this.state;
    const { input: { name, value }, replaceValues } = this.props;

    const previewText = getPreviewText(value, replaceValues);
    const messageLength = previewText.length;
    const messageCount = messageLength <= 160 ? 1 : Math.ceil(messageLength / 153);
    const showWarning = messageLength > 150;
    const warningLabelClass = messageCount > 1 ? 'text-danger' : 'text-warning';

    let messageClass = 'label label-success';
    if (messageCount > 1) {
      messageClass = 'label label-danger';
    } else if (showWarning) {
      messageClass = 'label label-warning ';
    }

    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="well well-sm">
            <Field name={name} component={this.textArea} />
            {isEditing && (
              <div>
                <TagsInfo time resource service customer />
                <a href="#" onClick={this.useDefaultSmsTemplate}><FormattedMessage {...msg.useStandard} /></a>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6">
          {isEditing && (
            <div>
              <div style={{ marginBottom: 5 }}>
                <strong><FormattedMessage {...msg.preview} /></strong><br />
                <div className="well well-sm" style={{ marginTop: 2, marginBottom: 5 }}>
                  <span className="text-wrap">{previewText}</span>
                </div>
                <span className={messageClass}>
                  <FormattedMessage {...msg.numberOfCharacters} values={{ messageLength, messageCount }} />
                </span>
                {showWarning && (
                  <div className={warningLabelClass} style={{ marginTop: 10 }}>
                    <FormattedMessage
                      {...msg.warning}
                      values={{
                        b: (...chunks) => <strong>{chunks}</strong>,
                        linebreak: <br />
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={3} className="form-control" onFocus={this.startEditing} />
   );

  startEditing = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ isEditing: true });
  };

  stopEditing = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ isEditing: false });
  };

  useDefaultSmsTemplate = (ev) => {
    ev.preventDefault();
    const { input: { onChange }, defaultTemplate } = this.props;

    onChange(defaultTemplate);
  };
}

SmsTemplateInput.propTypes = {
  defaultTemplate: PropTypes.string.isRequired,
  replaceValues: PropTypes.object.isRequired
};

export default SmsTemplateInput;
