import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { firstItemIdInGroup } from '../../state/action-helpers';
import { fetchGroupsAndResources, addGroup, addResource } from '../../state/resource-actions';
import { getResourceConfigUrl, navigate } from '../../utils/navigate';
import { CHANGE_RESOURCES, hasPermission } from '../../utils/permissions';
import { getPermissions } from '../../utils/selectors';
import ResourcesSidebar from './resources-sidebar';
import ResourceContent from './resource-content';
import Loader from '../common/loader';

class Resources extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { routeParams, resourceId, resource, resourcesById, orderedGroups, permissions, addGroup, addResource } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);


    if (loading && resourcesById.isEmpty()) {
      return <Loader withNavbar />;
    }

    if (!resourcesById.isEmpty() && (!resourceId || resourceId && !resource)) {
      return <Redirect to={getResourceConfigUrl(routeParams, firstItemIdInGroup(orderedGroups, 'resourceIds', resourcesById))} />;
    }

    return (
      <div className="columns-container">
        { canChangeResource && <ResourcesSidebar routeParams={routeParams} permissions={permissions} addGroup={addGroup} addResource={addResource} />}
        <ResourceContent routeParams={routeParams} />
      </div>
    );
  }


}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById, orderedGroups } = state;
  const resourceId = parseInt(ownProps.match.params.id);
  const routeParams = { ...ownProps.match.params, entityId: resourceId, entityType: 'resource' };
  const resource = resourcesById && resourceId && resourcesById.get(resourceId);

  return {
    resourceId,
    resource,
    resourcesById,
    orderedGroups,
    routeParams,
    permissions: getPermissions(state, { routeParams })
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const routeParams = ownProps.match.params;

  return {
    loadData: () => {
      return dispatch(fetchGroupsAndResources());
    },
    addGroup: (name) => {
      return dispatch(addGroup(name));
    },
    addResource: (resource) => {
      return dispatch(addResource(resource))
        .then(resource => navigate(getResourceConfigUrl(routeParams, resource.id)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
