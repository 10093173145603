import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';

import { msgPasswordPolicy as msg } from '../../localization/messages/components/user-settings';

class PasswordPolicy extends Component {
  render() {
    const { passwordPolicy, validationError, isValidating, intl: { formatMessage: f } } = this.props;

    return passwordPolicy ? (
      <div>
        <p style={{ marginBottom: 5 }}><strong>{f(msg.passwordConsistsOf)}</strong></p>
        {Object.keys(passwordPolicy).map((key) => {
          const isValid = !validationError || validationError.indexOf(key) === -1;
          const errorClass = isValidating ? 'text-danger' : 'text-muted';
          const errorIcon = isValidating ? 'fa fa-fw fa-times-circle' : 'fa fa-fw fa-circle-o';
          return passwordPolicy[key] > 0 ? (
            <div key={key} className={isValid ? 'text-success' : errorClass}>
              <i className={isValid ? 'fa fa-fw fa-check-circle' : errorIcon} style={{ marginRight: 5 }} />
              {this.getPolicyDescription(key)}
            </div>
          ) : null;
        })}
        <br />
      </div>
    ) : null;
  }

  getPolicyDescription = (key) => {
    const { passwordPolicy, intl: { formatMessage: f } } = this.props;
    const value = passwordPolicy[key];

    if (key == 'minLength') {
      return f(msg.policyMinLength, { value });
    }
    if (key == 'caps') {
      return f(msg.policyCaps, { value });
    }
    if (key == 'digits') {
      return f(msg.policyDigits, { value });
    }
    if (key == 'specials') {
      return f(msg.policySpecials, { value });
    }
  };
}

PasswordPolicy.propTypes = {
  passwordPolicy: PropTypes.object,
  validationError: PropTypes.array,
  isValidating: PropTypes.bool
};

export default injectIntl(PasswordPolicy);

