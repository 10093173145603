import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import CompanyInfoForm from './company-info-form';
import { msgCompanyInfo as msg } from '../../../localization/messages/components/account-config';
import { msgButtons } from '../../../localization/messages/shared/buttons';

class CompanyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false
    };
  }

  showForm = (ev) => {
    ev.preventDefault();
    this.setState({ showForm: true });
  };

  hideForm = () => {
    this.setState({ showForm: false });
  };

  onSubmit = (data) => {
    return this.props.onSubmit(data)
      .then(() => this.hideForm());
  };

  render() {
    const { showForm } = this.state;
    const { companyInfo, intl: { formatMessage: f } } = this.props;

    return (
      <div>
        <h4>{f(msg.heading)}</h4>
        <p className="text-muted" style={{ marginBottom: 20 }}>
          {f(msg.text, { br: <br /> })}
        </p>
        {companyInfo && showForm && (
          <CompanyInfoForm initialValues={companyInfo} onSubmit={this.onSubmit} onCancel={this.hideForm} />
        )}
        {companyInfo && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={{ width: '33%' }}>{f(msg.orgName)}</td>
                <td style={{ width: '66%' }}><strong>{companyInfo.orgName}</strong></td>
              </tr>
              <tr>
                <td>{f(msg.companyRegNo)}</td>
                <td><strong>{companyInfo.companyRegNo}</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>{f(msgButtons.btnChangeData)}</a></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default injectIntl(CompanyInfo);
