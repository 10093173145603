import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addResource } from '../../state/resource-actions';
import { hasPermission, VIEW_ALL_BOOKINGS } from '../../utils/permissions';

import { msgButtons } from '../../localization/messages/shared/buttons';
import { msgNoResourcePrompts as msg } from '../../localization/messages/components/dialogs';


class NoResourcePrompts extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      person: ''
    };
  }

  render() {
    const content = this.contentOrNot();

    return (
            !content ? <div /> :
            <div className="dialog-container">
              <div className="dialog-content">
                {content}
              </div>
            </div>
    );
  }

  contentOrNot() {
    const { phoneMode, resourceCount, noResourceAssociation, showCalendar, firstGroupId } = this.props;


    if (!(noResourceAssociation && showCalendar && firstGroupId > 0)) {
      return false;
    }


    if (resourceCount === 0) {
      if (this.haveCreateAccess()) {
        return this.addResource();
      }

      return this.noAccess();
    } else if (noResourceAssociation && !this.haveCreateAccess()) {
      if (phoneMode) {
        return this.phoneMode();
      }

      return this.noAccess();
    }

    return false;
  }

  haveCreateAccess() {
    const { permissions } = this.props;
    return hasPermission(permissions, VIEW_ALL_BOOKINGS);
  }

  phoneMode() {
    return (
      <div>
        <h2><FormattedMessage {...msg.noResourceHeading} /></h2>
        <hr />
        <p>
          <FormattedMessage {...msg.phoneModeUsage} />
        </p>
        <p>
          <FormattedMessage {...msg.phoneModeConnectByOther} />
        </p>
        <hr />
        <button onClick={this.logOut} className="button arrow"><FormattedMessage {...msgButtons.btnLogOut} /></button>
      </div>
    );
  }

  addResource() {
    const { person } = this.state;
    const { intl: { formatMessage: f } } = this.props;
    const buttonClass = person.length > 0 ? 'button arrow' : 'button arrow disabled';

    return (
      <div>
        <h2><FormattedMessage {...msg.addResourceHeading} /></h2>
        <hr />
        <p>
          <FormattedMessage {...msg.addResourceDescription} />
        </p>
        <br />
        <p>
          <FormattedMessage {...msg.addResourceLogIn} />
        </p>
        <hr />
        <form>
          <div className="row">
            <div className="col-xs-4" style={{ paddingRight: 0 }}>
              <input type="text" className="form-control" placeholder={f(msg.yourName)} onChange={this.nameChanged} />
            </div>
            <div className="col-xs-4">
              <button onClick={this.addPerson} className={buttonClass}>L<FormattedMessage {...msgButtons.btnAdd} /></button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  noAccess() {
    return (
      <div>
        <h2><FormattedMessage {...msg.noResourceHeading} /></h2>
        <hr />
        <p>
          <FormattedMessage {...msg.noAccessDescription} />
        </p>
        <hr />
        <button onClick={this.logOut} className="button arrow"><FormattedMessage {...msgButtons.btnLogOut} /></button>
      </div>
    );
  }

  addPerson = (e) => {
    e.preventDefault();

    const { person } = this.state;
      /* third parameter set to true, to create initial servicegroup and example services */
    this.props.addResource(this.props.firstGroupId, person, true);
  };

  logOut = (e) => {
    e.preventDefault();
    window.location.replace('/logout');
  };

  nameChanged = (event) => {
    this.setState({ person: event.target.value });
  };

}

NoResourcePrompts.propTypes = {
  permissions: PropTypes.array.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourceCount: PropTypes.number.isRequired,
  noResourceAssociation: PropTypes.bool.isRequired
};


const mapStateToProps = (state, ownProps) => {
  const { mainViewState, locationConfig, resourcesById, orderedGroups, locationOptions } = state;
  const { org, loc, section } = ownProps.match.params;
  const resourceCount = resourcesById != null ? resourcesById.count() : 0;
  const firstGroupId = orderedGroups != null && orderedGroups.get(0) != null ? orderedGroups.get(0).get('id') : 0;
  const permissions = locationOptions.size > 0 ? locationOptions.get(`${org}/${loc}`).permissions : [];

  return {
    permissions,
    phoneMode: mainViewState.get('phoneMode'),
    resourceCount,
    noResourceAssociation: locationConfig && permissions.length > 0 && !locationConfig.get('resourceId'),
    showCalendar: section === 'calendar',
    firstGroupId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addResource: (groupId, name, initialSetup) => {
      dispatch(addResource({
        name,
        groupId
      }, initialSetup));
    }
  };
};

export default injectIntl(withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
)(NoResourcePrompts)));
