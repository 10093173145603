var View = require('../base/view');

module.exports = View.extend({
   noWrap: true,
   autoRender: false,

   initialize: function() {
      this.template = require('./item');
      View.prototype.initialize.call(this, arguments);
   },

   itemClick: function() {
      this.trigger('item:click', this);
      return false;
   },

   render: function(){
      View.prototype.render.call(this, arguments);
      this.delegate('click', '.schedule-item.edit-mode', this.itemClick);
   }
});