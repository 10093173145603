import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CompanyInfo from './company-info';
import Contact from './contact';
import { fetchCompanyInfo, saveCompanyInfo, saveContactInfo, deleteContactInfo } from '../../../state/preferences-actions';
import { msgContactsConfig as msg } from '../../../localization/messages/components/account-config';

class ContactsConfig extends Component {
  componentWillMount() {
    if (!this.props.companyInfo) {
      this.props.fetchCompanyInfo();
    }
  }

  render() {
    const { companyInfo, contacts, saveCompanyInfo, saveContactInfo, stableId, intl: { formatMessage: f } } = this.props;

    const ownerContact = contacts && contacts.get('Owner');
    const billingContact = contacts && contacts.get('Billing');
    const privacyContact = contacts && contacts.get('PrivacyPolicy');

    return (
      <div className="row">
        <div className="col-xs-8">
          <fieldset>
            <CompanyInfo companyInfo={companyInfo && companyInfo.toJS()} onSubmit={saveCompanyInfo} />
            <br />

            <Contact
              contactType="Owner"
              contact={ownerContact}
              onSubmit={saveContactInfo}
              title={f(msg.ownerTitle)}
              description={f(msg.ownerDescription)}
            /><br />

            <Contact
              isOptional
              contactType="PrivacyPolicy"
              contact={privacyContact}
              onSubmit={saveContactInfo}
              title={f(msg.policyTitle)}
              description={f(msg.policyDescription)}
            /><br />

            <Contact
              isOptional
              contactType="Billing"
              contact={billingContact}
              onSubmit={saveContactInfo}
              title={f(msg.billingTitle)}
              description={f(msg.billingDescription)}
            />
          </fieldset>
        </div>
        <div className="col-xs-4">
          <h4>{f(msg.legalHeading)}</h4>
          <p><a href="https://dashl.se/" target="_blank"><i
            className="fa fa-file-pdf-o"
          /> {f(msg.legalLink)}</a></p>
          <p className="text-muted">
            {f(msg.legalContent)}
          </p>
          <br />

          <h4>{f(msg.informationHeading)}</h4>
          <p><a href="https://dashl.se/" target="_blank"><i
            className="fa fa-external-link"
          /> {f(msg.informationLink)}</a></p>
          <p className="text-muted">
            {f(msg.informationContent)}
          </p>
          <br />

          <h4>{f(msg.moreHeading)}</h4>
          <p><a href="https://www.verksamt.se/driva/gdpr-dataskyddsregler/" target="_blank"><i
            className="fa fa-external-link"
          /> {f(msg.moreLink)}</a></p>
          <p className="text-muted">
            {f(msg.moreContent, { br: <br /> })}
          </p>
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { preferencesViewState, locationConfig } = state;

  return {
    companyInfo: preferencesViewState.get('companyInfo'),
    contacts: preferencesViewState.get('contacts'),
    stableId: locationConfig.get('stableId')
  };
};

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    fetchCompanyInfo: () => {
      dispatch(fetchCompanyInfo());
    },
    saveCompanyInfo: (data) => {
      const orgLoc = `${match.params.org}/${match.params.loc}`;
      return dispatch(saveCompanyInfo(orgLoc, data));
    },
    saveContactInfo: (contactType, data) => {
      if (data.useContactInfo === 'true') {
        return dispatch(saveContactInfo(contactType, data));
      }
      return dispatch(deleteContactInfo(contactType));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContactsConfig));
