

export const chaplinEventDispatcher = store => next => (action) => {
   // Invoke the rest of the chain before publishing an event
   //
  const returnValue = next(action);

   // console.info('chaplinEventDispatcher', action);

  Chaplin.mediator.publish(`redux:event:${action.type}`, action);

  return returnValue;
};

