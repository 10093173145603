import React from 'react';
import { CheckMark } from './images';

const ChunkyCheckBox = ({ checked, style }) => {
  return checked ? (
    <div className="cb-checkbox checked" style={style}>
      <CheckMark />
    </div>
  ) : (
    <div className="cb-checkbox" style={style} />
  );
};

export default ChunkyCheckBox;
