import { defineMessages } from 'react-intl';

export const msgAddGroupForm = defineMessages({
  lblName: {
    id: 'admin.AddGroupForm.lblName',
    defaultMessage: 'Namn'
  },
  lblGroup: {
    id: 'admin.AddGroupForm. lblGroup',
    defaultMessage: 'Grupp'
  },
  placeholderName: {
    id: 'admin.AddGroupForm.placeholderName',
    defaultMessage: 'Ange namn på gruppen'
  }
});

export const msgSortableListBanner = defineMessages({
  description: {
    id: 'admin.SortableListBanner.description',
    defaultMessage: 'Dra och släpp för att ändra ordning i listan'
  }
});
