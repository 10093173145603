var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\""
    + alias4(((helper = (helper = helpers.statusClassNames || (depth0 != null ? depth0.statusClassNames : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusClassNames","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.statusDescription || (depth0 != null ? depth0.statusDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusDescription","hash":{},"data":data}) : helper)))
    + "</span>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-default active label-asked-for-person\">"
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"bookedPerson",{"name":"i18n","hash":{},"data":data}))
    + "</span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-default active label-drop-in\">"
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"dropIn",{"name":"i18n","hash":{},"data":data}))
    + "</span>";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return " - "
    + container.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<p class=\"text-muted\"><em>"
    + container.escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"note","hash":{},"data":data}) : helper)))
    + "</em></p>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n  <hr/>\n  <p>\n  <div class=\"pull-right\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.statusDescription : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.askedForPerson : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dropIn : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </div>\n  <p>\n    <strong>"
    + alias3((helpers.DateTime || (depth0 && depth0.DateTime) || alias2).call(alias1,(depth0 != null ? depth0.startTime : depth0),{"name":"DateTime","hash":{},"data":data}))
    + "</strong>\n  </p>\n  <p>\n    "
    + alias3(((helper = (helper = helpers.resourceName || (depth0 != null ? depth0.resourceName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data}) : helper)))
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </p>\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.note : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});