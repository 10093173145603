import { defineMessages } from 'react-intl';

export const msgLocationSelection = defineMessages({
  noAccounts: {
    id: 'location-selection.LocationSelection.noAccounts',
    defaultMessage: 'Inga konton är kopplade till ditt inlogg'
  },
  chooseAccount: {
    id: 'location-selection.LocationSelection.chooseAccount',
    defaultMessage: 'Välj konto'
  }
});
