var moment = require('moment');
var EditableView = require('../../../base/editable-view');
var ConfirmDeletePopoverView = require('../../../base/confirm-popover-view');

const { formatMessage } = require('../../../../../app/redux-dashl/intlContextInterseptor');
const { msgHandlebars } = require('../../../../../app/redux-dashl/localization/messages/handlebars/handlebars');

module.exports = EditableView.extend({
	noWrap: true,
	autoRender: true,

	events: {
		'click .js-delete': 'confirmDelete',
		'keyup .js-comment': 'commentKeyUp'
	},

	initialize: function () {
		this.detailsTemplate = require('./log-item');
		this.editTemplate = require('./log-item-edit');
		EditableView.prototype.initialize.call(this, arguments);
	},

	edit: function() {
		EditableView.prototype.edit.call(this, arguments);
		this.$('textarea').val(this.model.get('comment')).focus();
		this.enableOrDisableSaveLog();
	},

	commentKeyUp: function(ev) {
		this.enableOrDisableSaveLog();
	},

	enableOrDisableSaveLog: function() {
		var charCount = this.$('.js-comment').val().length;
		if (charCount === 0){
			this.$('.js-save').addClass('disabled');
		}
		else{
			this.$('.js-save').removeClass('disabled');
		}
	},

	save: function() {
		var data = {comment: this.$('textarea').val()};

      $.ajax({
         type: 'PUT',
         contentType: 'application/json; charset=utf-8',
         url: this.model.url(),
         data: JSON.stringify(data),
         success: _.bind(this.saveSuccess, this, data)
      });
   },

	saveSuccess: function (data) {
		this.model.set('comment', data.comment);
		this.model.set('isUpdated', true);
		this.model.set('lastUpdate', moment());
		EditableView.prototype.save.call(this, arguments);
	},

	confirmDelete: function (e) {
		e.preventDefault();

		this.confirmationView = new ConfirmDeletePopoverView({
			title: formatMessage(msgHandlebars.deleteNoteTitle),
			confirmText: formatMessage(msgHandlebars.deleteNoteText),
			buttonText: formatMessage(msgHandlebars.deleteNoteTitle),
			callback: _.bind(this.delete, this),
			parent: $(e.currentTarget)
		});
		this.subview('popover', this.confirmationView);

		return false;
	},

	delete: function () {
		this.model.destroy({
			wait: true,
			success: _.bind(this.deleteSuccess, this)
		});
	},

	deleteSuccess: function () {
		this.confirmationView.close();
	}
});
