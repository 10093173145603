import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import FocusTrap from 'focus-trap-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import s from 'underscore.string';
import { omit } from 'ramda';
import diff from 'object-diff';
import { connect } from 'react-redux';
import { bookingMoved, moveBooking, removeTempBooking } from '../../state/booking-actions';
import { clearAndCloseBKF, loadBKFBooking, updateBKFCoords } from '../../state/bkf-actions';
import Modal from '../common/modal';
import { Popover } from '../common/popover';
import BookingForm from '../booking/booking-form';
import { ChipDragHandler } from './chip-draghandler';
import { formatMessage } from '../../intlContextInterseptor';
import { styles, visualStylesHC, visualStylesNonHC } from './chip-styles';
import ConfirmMove from './confirm-move';
import ConfirmResize from './confirm-resize';
import { calcHeightFromMinutes } from '../../utils/time-util';
import { formatVehicleRegNo } from '../../../utils/vehicle-util';

import { msgChip as msg } from '../../localization/messages/components/grid';
//
class Chip extends Component {

  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    resizable: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    pendingMove: PropTypes.bool,
    note: PropTypes.string,
    reservation: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    smsStatus: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    customerPhoneNumber: PropTypes.string,
    customerEmail: PropTypes.string,
    orgNo: PropTypes.string,
    orgName: PropTypes.string,
    vehicleRegNo: PropTypes.string,
    vehicleDescription: PropTypes.string,
    bookedAs: PropTypes.string,
    description: PropTypes.string,
    highContrast: PropTypes.bool.isRequired,
    gridProps: PropTypes.object.isRequired,
    colIdx: PropTypes.number.isRequired,
    confirmMoveEnabled: PropTypes.bool,
    features: PropTypes.object.isRequired,

    deviceType: PropTypes.string.isRequired,

    onChipMove: PropTypes.func.isRequired,
    onChipClick: PropTypes.func.isRequired,
    onChipResize: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  createState(props) {
    return {
      coords: props.initialCoords,
      startTime: props.startTime,
      endTime: props.endTime,
      isDragging: false,
      isResizing: false,
      dragChipTopDiff: this.state ? this.state.dragChipTopDiff : 0,
      resizeChipOriginalHeight: this.state ? this.state.resizeChipOriginalHeight : 0,
      showForm: this.shouldShowForm(props)
    };
  }

  shouldShowForm(props) {
    return !props.pasteDragger && props.showForm; // Dont show form if the chip is a paste booking
  }

  makeInitialState(props) {
    const initState = props.id === 'DRAGGER' ? null : {
      id: props.id,
      startTime: props.startTime,
      endTime: props.endTime,
      resourceId: props.resourceId,
      afterTime: props.afterTime
    };

    return Object.assign({}, this.createState(props), { initState });
  }

  componentWillUpdate(nextProps, nextState) {

  }

  componentDidUpdate(prevProps, prevState) {
    const resizableChanged = prevProps.resizable !== this.props.resizable;

    const timeChanged = !(this.state.endTime.isSame(prevState.endTime) && this.state.startTime.isSame(prevState.startTime));
    const colChanged = this.state.coords.colIdx !== prevState.coords.colIdx;

    // Only do this if the form is open for this chip
    //
    if (this.state.showForm && (timeChanged || colChanged)) {
      this.props.onChipStateChange({
        id: this.props.id,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx
      });
    }

    if (resizableChanged) {
      if (this.props.resizable) {
        this.draggable();
      } else {
        this.undraggable();
      }
    }
  }

  componentDidMount() {
    if (this.props.resizable) {
      this.draggable();
    }
  }

  componentWillUnmount() {
    this.undraggable();
  }

  componentWillReceiveProps(nextProps) {
    if (this.chipDragHandler) {
      this.chipDragHandler.componentWillReceiveProps(nextProps);
    }

    // Dont update state (and rerender chip coord) for certain prop updates
    //
    if (this.shouldIgnorePropUpdate(nextProps)) {
      return;
    }
    // Update init state for the chip if the form is closed, or was closed
    if (!nextProps.showForm) {
      this.setState(this.makeInitialState(nextProps));
    } else {
      this.setState(this.createState(nextProps));
    }
  }

  shouldIgnorePropUpdate(nextProps) {
    // Handle if state change is a flag change..
    //
    const p1 = omit(['initialCoords'], this.props);
    const p2 = omit(['initialCoords'], nextProps);
    const _diff = Object.keys(diff.custom({ equal: this.comparator }, p1, p2));

    return _diff.length === 1 && (_diff.includes('status') || _diff.includes('askedForPerson') || _diff.includes('dropIn'));
  }

  comparator(a, b) {
    if (a instanceof Function && b instanceof Function) {
      return true;
    }
    return a === b;
  }

  render() {
    const { deviceType, externalKeyboard } = this.props;
    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';

    const chip = isMobile || (isTablet && !externalKeyboard) ?
      this.chipWithFormModal() :
      this.chipWithFormPopoverV2();

    return this.shouldRenderWithConfirm()
         ? this.wrapWithConfirmPopover(chip, this.confirmState())
         : chip;
  }

  shouldRenderWithConfirm() {
    return this.props.confirmMoveEnabled
      && (this.props.id !== 'DRAGGER' || this.props.pasteDragger);
  }

  wrapWithConfirmPopover(chip, confirmState) {
    const popoverContent = confirmState.moved ? this.confirmMovePopoverContent() : this.confirmResizePopoverContent();

    return (
      <Popover
        isOpen={confirmState.showConfirm}
        body={popoverContent}
        onOuterAction={this.handleClosePopover}
      >
        {chip}
      </Popover>
    );
  }

  confirmState() {
    const resized = this.state.isResizing === false
            && this.state.isDragging === false
            && !this.state.endTime.isSame(this.props.endTime);

    const moved = this.state.isDragging === false
            && (!this.state.startTime.isSame(this.props.startTime) || this.props.colIdx !== this.state.coords.colIdx);

    // Dont show the confirm when the form is open
    return {
      resized,
      moved,
      showConfirm: !this.props.showForm && (resized || moved)
    };
  }

  chipWithFormModal() {
    const modal = (
      <Modal
        titleText="Bokning"
        underlayClickExits
        includeDefaultStyles={false}
        dialogClass="booking-form-modal"
        underlayClass="booking-form-modal-underlay"
        mounted={this.state.showForm && !this.props.customerFormVisible && !this.props.showPrintModal}
        initialFocus=".booking-form"
        onExit={this.handleClosePopover}
      >
        {this.state.showForm && <BookingForm id={this.props.id} isModal onClose={this.handleClosePopover} />}
      </Modal>
    );
    const chip = this.chip();
    return (
      <Fragment>
        {chip}
        {modal}
      </Fragment>
    );
  }

  chipWithFormPopoverV2() {
    const popOverWidth = 350;
    const w = this.props.gridProps.gridClientWidth - popOverWidth;
    const poTrackerWidth = this.state.coords.width > (this.props.gridProps.gridClientWidth / 2) ? w : '100%';
    const focusTrapPaused = this.props.customerFormVisible || this.props.showPrintModal;

    const form = this.state.showForm && (
      <FocusTrap focusTrapOptions={{ initialFocus: '.booking-form' }} paused={focusTrapPaused}>
        <BookingForm id={this.props.id} onClose={this.handleClosePopover} />
      </FocusTrap>
    );

    const popover = (<Popover
      isOpen={this.state.showForm}
      body={form}
      onOuterAction={this.handleClosePopover}
      style={{ zIndex: 1000 }}
      className="Popover-booking"
      preferPlace="row"
      enterExitTransitionDurationMs={0}
      refreshIntervalMs={500}
    >
      <div
        className="popovertracker"
        style={{ position: 'absolute', width: poTrackerWidth, height: this.state.coords.height }}
      />
    </Popover>
    );

    return this.chip(popover);
  }

  handleChipClick = (ev) => {
    const { id, showForm } = this.props;

    if (id !== 'DRAGGER' && !showForm) {
      this.props.onChipClick(ev);
    }
  };

  handleClosePopover = (ev) => {
    // Ignore if CF is open - And dont preventDefault on the event..
    if (this.props.customerFormVisible || this.props.showPrintModal) return;

    if (ev) {
      ev.preventDefault();
      // Dont invoke close logic if the clicked element was this chip..
      // This is to handle onOuterAction of the popover, as its no wrapping the  chip but rather a smaller div within the chip
      if (this.chipEl.contains(ev.target)) return;
    }

    const state = Object.assign({}, this.state.initState, { showForm: false });
    this.setState(state);
    this.chipDragHandler.onDragEndCancel();
    this.props.closeForm(this.state.initState);
  };

  handleConfirmMove = (confirmOptions) => {
    this.chipDragHandler.onDragEndConfirm(confirmOptions);
  };

  confirmResize = (ev) => {
    ev.preventDefault();
    this.chipDragHandler.onResizeEndConfirm();
  };

  confirmMovePopoverContent() {
    const { customerEmail, customerPhoneNumber } = this.props;

    return (<ConfirmMove
      startTime={this.state.startTime}
      copyOnPaste={this.props.copyOnPaste}
      customerEmail={customerEmail}
      customerPhoneNumber={customerPhoneNumber}
      onClosePopover={this.handleClosePopover}
      onConfirmMove={this.handleConfirmMove}
    />);
  }

  confirmResizePopoverContent() {
    const duration = this.state.endTime.diff(this.state.startTime, 'minutes');
    return (<ConfirmResize
      duration={duration}
      onClosePopover={this.handleClosePopover}
      onConfirmResize={this.confirmResize}
    />);
  }

  chip(popover) {
    const { id } = this.props;
    return (
      <div id={`chip${id}`} style={this.outerChipStyle()} className={this.classes()} ref={(chipEl) => { this.chipEl = chipEl; }}>
        {popover}
        <div style={this.innerChipStyle()}>
          { this.icons() }
          { this.header() }
          { this.content() }
          { this.resizeHandle() }
        </div>
        { this.afterTime() }
      </div>
    );
  }

  innerChipStyle() {
    const { pending, type, status, pendingMove, resizable, highlight } = this.props;
    const { isDragging, isResizing, showForm } = this.state;

    const chipStatus = type === 'Reservation' ? 'Reservation' : status;
    const clipboardStyle = pendingMove ? { opacity: '0.5' } : {};
    const resizableStyle = !resizable ? { pointerEvents: 'none' } : {};
    const sdHeight = this.serviceChipHeight();
    const padding = this.isExtraSmall() ? '0 3px 0 3px' : '3px';
    const confirmState = this.confirmState();

    const structuralStyle = {
      position: 'relative',
      height: sdHeight,
      padding,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };
    const visualStyles = this.visualStyles();
    return Object.assign({},
      structuralStyle,
      styles.chips.base,
      clipboardStyle, resizableStyle,
      visualStyles.statuses[chipStatus],
      pending ? visualStyles.pending : {},
      isDragging || isResizing || showForm || highlight || confirmState.showConfirm ? visualStyles.statuses[`${chipStatus}Drag`] : {});
  }

  afterTime() {
    const { afterTime, gridProps, highContrast, status } = this.props;
    if (!afterTime || afterTime === 0) {
      return null;
    }

    if (status === 'Cancelled') {
      return null;
    }

    const atHeight = calcHeightFromMinutes(gridProps, afterTime, true);
    const className = highContrast ? 'after-time-hc' : 'after-time';
    const style = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: atHeight,
      zIndex: -1,
      overflow: 'hidden'
    };

    return (
      <div className={className} style={style}>
        <span>
          <FormattedMessage {...msg.duration} values={{ duration: afterTime }} />
        </span>
      </div>
    );
  }

  outerChipStyle() {
    const { isDragging, isResizing } = this.state;

    const lineHeight = this.isSmall() ? 1.3 : 1.43;
    const widthPct = this.state.coords.widthPct;
    const dragging = isDragging || isResizing ? styles.chips.dragging : {};

    return {
      position: 'absolute',
      top: this.state.coords.top,
      left: `${this.state.coords.leftPct}%`,
      width: `${widthPct}%`,
      height: this.state.coords.height,
      zIndex: this.state.coords.zIndex,
      lineHeight,
      ...dragging
    };
  }

  classes() {
    const { undone, highlight } = this.props;

    return undone || highlight ? 'animated pulse-sm' : '';
  }

  renderNoHeader() {
    return (this.isExtraSmall() && this.hasContent()) || this.props.pending;
  }

  formattedTime() {
    const showEndTime = this.state.coords.width > 140;

    const { afterTime } = this.props;
    const endTime = afterTime ? moment(this.state.endTime).subtract(afterTime, 'm') : this.state.endTime;

    return (<span>
      { this.state.startTime.format('HH:mm') }
      { showEndTime ? ` - ${endTime.format('HH:mm')}` : '' }
    </span>);
  }

  header() {
    return this.renderNoHeader() ? '' :
    <div >
      {this.formattedTime()}
    </div>;
  }

  icons() {
    const { note, reservation, smsStatus, channel, status, attributes } = this.props;

    if (status === 'Cancelled') {
      return (<div className="pull-right" />);
    }

    const notes = !(s.isBlank(note) || reservation);
    const sms = (smsStatus !== 'NEW' && smsStatus !== 'SEND_REJECTED');
    const web = channel === 'Web' || channel === 'App';
    const dashl = channel === 'WebDashl' || channel === 'WebDashlPopup';
    const klipptid = channel === 'WebKlippTid';
    const home = attributes && attributes.place === 'Home';

    return (
      <div className="pull-right">
        {home ? <i className="fa fa-home" style={styles.icon} title={formatMessage(msg.home)} /> : ''}
        {sms ? <i className="fa fa-check-circle" style={styles.icon} title={formatMessage(msg.smsSent)} /> : ''}
        {notes ? <i className="fa fa-info-circle" style={styles.icon} title={note} /> : ''}
        {web || dashl ? <i className="fa fa-globe" style={styles.icon} title={formatMessage(msg.webboking)} /> : ''}
        {klipptid ? <img src="/klipptid-logo.svg" style={styles.image} title="klipptid.nu" /> : ''}
        {this.label('dropIn')}
        {this.label('askedForPerson')}
      </div>
    );
  }

  content() {
    const { customerName, bookedAs, description, note, reservation, pending, status, vehicleRegNo, vehicleDescription, orgName } = this.props;
    const customerText = orgName || bookedAs || customerName;
    const vehicleText = vehicleRegNo && formatVehicleRegNo(vehicleRegNo);
    const chipHeader = [vehicleText, customerText].filter(v => v).join(' - ');

    if (pending) {
      return <strong><FormattedMessage {...msg.ongoingBooking} /></strong>;
    }
    if (status === 'Cancelled') {
      return <div><strong><FormattedMessage {...msg.canceled} /></strong> <span className="text-small">{chipHeader}</span></div>;
    }

    return (
      <div>
        {reservation && <strong>{note}</strong>}
        {!reservation && chipHeader && <strong>{chipHeader} </strong>}
        {!reservation && description && <span className="text-small">{description}</span>}
        {!reservation && vehicleDescription && <div style={{ opacity: 0.7, fontWeight: 'normal' }}><small>{vehicleDescription}</small></div>}
        {!reservation && note && <div style={{ opacity: 0.7, fontWeight: 'normal' }}><small>{note}</small></div>}
      </div>
    );
  }

  resizeHandle() {
    const scHeight = this.serviceChipHeight();
    const height = scHeight < 40 ? '25%' : 20;
    const handleStyle = {
      position: 'absolute',
      cursor: 'ns-resize',
      width: '100%',
      bottom: 0,
      left: 0,
      height
    };
    return (
      <div className="resize-handle" style={handleStyle}>
        {this.showResizeHandle() ? <span className="resize-handle-icon" /> : null}
      </div>
    );
  }

  label(label) {
    const visualStyles = this.visualStyles();

    const lblStyle = Object.assign({},
      styles.label,
      visualStyles[label]
    );
    return this.props[label] ? <div style={lblStyle} /> : '';
  }

  visualStyles() {
    const { highContrast } = this.props;
    return highContrast ? visualStylesHC : visualStylesNonHC;
  }


  showResizeHandle() {
    const { bookedAs, customerName, description, note, vehicleRegNo, vehicleDescription } = this.props;

    // Calculate when to show resize handle based on length of chip content
    //
    const chars = [bookedAs || customerName, description, note, vehicleRegNo, vehicleDescription].join('').length;
    const limit = chars >= 20 ? 60 : 40;

    return this.serviceChipHeight() > limit;
  }

  isSmall() {
    return this.serviceChipHeight() <= 40;
  }

  isExtraSmall() {
    return this.serviceChipHeight() <= 30;
  }

  serviceChipHeight() {
    const { afterTime } = this.props;
    let duration = this.state.endTime.diff(this.state.startTime, 'minutes');
    duration = afterTime ? duration - afterTime : duration;
    return calcHeightFromMinutes(this.props.gridProps, duration, true);
  }

  isCancelled() {
    return this.props.status === 'Cancelled';
  }

  hasContent() {
    const { customerName, description, note, reservation } = this.props;

    return (reservation && !s.isBlank(note)) || !s.isBlank(customerName + description);
  }

  duration(precision) {
    return this.state.endTime.diff(this.state.startTime, precision);
  }

  undraggable() {
    if (this.chipDragHandler) {
      this.chipDragHandler.dispose();
      this.chipDragHandler = undefined;
    }
  }

  draggable() {
    if (!this.chipDragHandler) {
      this.chipDragHandler = new ChipDragHandler(this);
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { routeParams } = ownProps;

  return {
    onChipStateChange: (event) => {
      dispatch(updateBKFCoords(event, routeParams));
    },
    onChipMove: (event) => {
      dispatch(moveBooking(event, routeParams));
    },
    onChipResize: (event) => {
      dispatch(moveBooking(event, routeParams));
    },
    onChipClick: (event) => {
      dispatch(loadBKFBooking(ownProps.id));
    },
    closeForm: (initState) => {
      if (ownProps.id !== 'DRAGGER') {
        dispatch(bookingMoved(initState));
      }
      dispatch(removeTempBooking('DRAGGER'));
      dispatch(clearAndCloseBKF());
    }
  };
};

function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

function getOverridesFromBkf(state, ownProps) {
  const { bkf } = state;
  const bkfService = bkf.get('service');
  const bkfServices = bkf.get('services');
  const bkfVehicle = bkf.get('vehicle');
  const bkfCompany = bkf.get('company');
  const bkfCustomer = bkf.get('customer');
  const bkfAttributes = bkf.get('attributes');

  return {
    afterTime: bkfService ? bkfService.afterTime : ownProps.afterTime,
    description: getDescription(bkfService && bkfService.name, bkfServices && bkfServices.toArray()),
    orgName: bkfCompany ? bkfCompany.orgName : '',
    customerName: bkfCustomer ? bkfCustomer.name : '',
    vehicleRegNo: bkfVehicle ? bkfVehicle.vehicleRegNo : '',
    vehicleDescription: bkfVehicle ? bkfVehicle.vehicleDescription : '',
    bookedAs: bkfCustomer && bkfCustomer.customerId === ownProps.customerId ? ownProps.bookedAs : null,
    attributes: bkfAttributes
  };
}

export default connect((state, ownProps) => {
  const { bkf, cf: customerForm, gridViewState, locationConfig, locationFeatures, bookingsClipboard, mainViewState } = state;

  // If there is a booking in the bkf that matches this chip, then render popover open
  //
  const bkfBkId = bkf.get('id');
  const showForm = !!bkfBkId && bkfBkId === ownProps.id;
  const override = showForm ? getOverridesFromBkf(state, ownProps) : {};
  const clip = bookingsClipboard.get(ownProps.id);
  const pendingMove = (clip !== undefined && !clip.copyOnPaste);
  const customerFormVisible = customerForm.get('formVisible');
  const showPrintModal = bkf.get('showPrintModal');

  return {
    showForm,
    customerFormVisible,
    showPrintModal,
    pendingMove,
    description: getDescription(ownProps.description, ownProps.services),
    customerName: ownProps.customerName || '',
    resizable: !(ownProps.pending || pendingMove),
    highContrast: gridViewState.get('highContrast'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    scheduleEditMode: gridViewState.get('scheduleEditMode'),
    confirmMoveEnabled: locationConfig.get('confirmMoveEnabled'),
    highlight: ownProps.id === ownProps.routeParams.bookingId,
    features: locationFeatures,
    deviceType: mainViewState.get('deviceType'),
    ...override
  };
}, mapDispatchToProps,
  null,
  { withRef: true }
)(Chip);

