import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import PhoneUtil from '../../../utils/phone-util';
import { getCalendarUrl, navigate } from '../../utils/navigate';
import { viewDateFromDate } from '../../utils/time-util';
import { styles, visualStylesHC, visualStylesNonHC } from '../grid/chip-styles';
import { resetSearch } from '../../state/booking-actions';

import { msgSearchResults as msg } from '../../localization/messages/components/calendar';

const SearchResultItem = ({
  bookingId, resourceName, resourceDeleted, startTime, serviceDescription, status, orgNo, orgName,
  name, phoneNumber, otherPhoneNumber, email, vehicleRegNo, vehicleDescription,
  visualStyles, onClick, selectedBookingId, search
}) => {
  const phoneNumbers = [];
  if (phoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(phoneNumber));
  }
  if (otherPhoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(otherPhoneNumber));
  }

  const selected = bookingId === selectedBookingId;
  const selectedStyle = selected ? visualStyles.statuses[`${status}Drag`] : null;
  const chipStyles = {
    ...styles.chips.base,
    ...visualStyles.statuses[status],
    ...selectedStyle
  };

  return (
    <div
      className={resourceDeleted ? 'search-result deleted' : 'search-result'}
      onClick={resourceDeleted ? null : onClick}
      style={chipStyles}
    >
      <section>
        <strong>{moment(startTime).format('LLL')}</strong><br />
        {serviceDescription && <span>{serviceDescription}{resourceName && ', '}</span>}
        <span className={resourceDeleted ? 'text-danger' : ''}>
          <FormattedMessage {...msg.resource} values={{ resourceName, resourceDeleted }} />
        </span>
      </section>
      {(vehicleRegNo || vehicleDescription) && (
        <section>
          {vehicleRegNo && <div><Highlighter searchWords={search} textToHighlight={vehicleRegNo} autoEscape /></div>}
          {vehicleDescription && <small><Highlighter searchWords={search} textToHighlight={vehicleDescription} autoEscape /></small>}
        </section>
      )}
      {(orgName || orgNo) && (
        <section>
          {orgName && <div><Highlighter searchWords={search} textToHighlight={orgName} autoEscape /></div>}
          {orgNo && <small><Highlighter searchWords={search} textToHighlight={orgNo} autoEscape /></small>}
        </section>
      )}
      {(name || phoneNumbers.length > 0 || email) && (
        <section>
          {name && <div><Highlighter searchWords={search} textToHighlight={name} autoEscape /></div>}
          {phoneNumbers.length > 0 && <div><small><Highlighter searchWords={search} textToHighlight={phoneNumbers.join(' / ')} autoEscape /></small></div>}
          {email && <div><small><Highlighter searchWords={search} textToHighlight={email} autoEscape /></small></div>}
        </section>
      )}
    </div>
  );
};

class SearchResults extends Component {
  bookingClick = (ev, booking) => {
    const { routeParams } = this.props;
    const startTime = moment(booking.get('startTime'));
    const viewDate = viewDateFromDate('week', startTime);
    const newRouteParams = {
      ...routeParams,
      viewMode: 'week',
      entityType: 'resource',
      entityId: booking.get('resourceId')
    };
    const calendarUrl = getCalendarUrl(viewDate, newRouteParams);
    navigate(`${calendarUrl}#${booking.get('bookingId')}`);
  };

  resetSearch = (ev) => {
    ev.preventDefault();
    this.props.resetSearch();
  };

  render() {
    const { showSearch, query, bookings, highContrast, selectedBookingId } = this.props;
    const search = query && query.split(' ');
    const matches = (bookings && bookings.size) || 0;
    const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;
    const isSearching = showSearch && !query;
    const grouped = bookings && bookings.groupBy(b => moment(b.get('startTime')).format('MMMM YYYY'));

    return (
      <div className={showSearch ? 'search-results show' : 'search-results'}>
        <div className="search-results-container">
          {query && <a href="#" onClick={this.resetSearch} className="reset-search"><i className="fa fa-times-circle" /></a>}
          {query && <p className="search-info"><FormattedMessage {...msg.matchQuery} values={{ matches, query, apos: <Fragment>&apos;</Fragment> }} /></p>}
          {isSearching && <p className="search-loading"><FormattedMessage {...msg.searching} /></p>}
          {grouped && grouped.entrySeq().map(([group, results]) => (
            <div key={group} className="search-group">
              <h5>{group}</h5>
              {results && results.map(result => (
                <SearchResultItem
                  {...result.toObject()}
                  key={result.get('bookingId')}
                  onClick={ev => this.bookingClick(ev, result)}
                  selectedBookingId={selectedBookingId}
                  visualStyles={visualStyles}
                  search={search}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { routeParams }) => {
  const { bookingSearchResults, gridViewState } = state;

  return {
    routeParams,
    query: bookingSearchResults.get('query'),
    showSearch: bookingSearchResults.get('showSearch'),
    bookings: bookingSearchResults.get('bookings'),
    highContrast: gridViewState.get('highContrast'),
    selectedBookingId: routeParams.bookingId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearch: () => {
      dispatch(resetSearch());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
