var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <form>\n                <div class=\"modal-header\">\n                    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n                        <span aria-hidden=\"true\">&times;</span>\n                        <span class=\"sr-only\">Close</span></button>\n                    <h4 class=\"modal-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n                </div>\n\n                <div class=\"modal-body\">\n                    <div class=\"row\">\n                        <div class=\"col-sm-6\">\n                            <div class=\"form-group has-feedback\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"start",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n\n                                <div class=\"input-group\">\n                                    <input name=\"start\" type=\"text\" class=\"form-control time start\"/>\n                                <span class=\"input-group-addon\">\n                                  <i class=\"fa fa-clock-o\"></i>\n                                </span>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-sm-6\">\n                            <div class=\"form-group has-feedback\">\n                                <label class=\"control-label\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"final",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n\n                                <div class=\"input-group\">\n                                    <input name=\"end\" type=\"text\" class=\"form-control time end\" data-parsley-timedelta=\".start\"\n                                           data-parsley-timedelta-message=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"mustBeLaterThanStart",{"name":"i18n","hash":{},"data":data}))
    + "\" />\n                                <span class=\"input-group-addon\">\n                                  <i class=\"fa fa-clock-o\"></i>\n                                </span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"modal-footer\">\n\n                    <div class=\"pull-right\">\n                        <a href=\"#\" class=\"btn-cancel\" data-dismiss=\"modal\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n                        <button type=\"submit\" class=\"btn-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"save",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                    </div>\n                    <div class=\"pull-left\">\n                        <button class=\"btn btn-danger js-delete hide\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"remove",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});