var View = require('./view');

module.exports = View.extend({
	noWrap: true,
	autoRender: true,
	container: 'body',
	viewport: {selector: 'body', padding: 20},
	placement: 'auto right',
	exclusive: true,
	alwaysOnTop: false,
	containerTemplate: require('./popover-template'),
	optionNames: View.prototype.optionNames.concat(['containerTemplate', 'title', 'parent', 'placement', 'viewport', 'newItemTitle', 'class', 'alwaysOnTop']),

	dispose: function () {

		if (this.$el) {
			var popoverId = this.$el.attr('id');
			$('body').off('click.' + popoverId);
			$('body').off('keydown.' + popoverId);
			this.$el.popover('destroy');
		}

		Chaplin.View.prototype.dispose.call(this, arguments);
	},

	cancel: function(e) {
		e.preventDefault();
		this.close();
	},

	close: function (options) {
		this.trigger('closed', options);
		this.dispose();
	},

	show: function () {
		this.$el.popover('show');
	},

	getTitle: function () {
		return this.model.isNew() ? this.newItemTitle : this.model.get('name');
	},

	render: function () {
		if(this.exclusive) {
			this.destroyPopovers();
		}

		var options = {
			container: this.container,
			viewport: this.viewport,
			placement: this.placement,
			trigger: 'manual',
			html: true,
			title: this.getTitle(),
			animation: false,
			content: this.template(this.getTemplateData()),
			template: this.containerTemplate({ class: this.class })
		};

		// the id of the popover is saved in attribute aria-describedby on parent
		var popover = this.parent.popover(options).popover('show');
		var popoverId = popover.attr('aria-describedby');
		this.setElement('#' + popoverId);

		// todo render subviews and call show again to re-position popover

		var keyDown = _.bind(this.keyDown, this);
		var bodyClick = _.bind(this.bodyClick, this);
		_.defer(function() {
			$('body').on('keydown.' + popoverId, keyDown);
			$('body').on('click.' + popoverId, bodyClick);
		});

		this.delegate('click', '.js-cancel', this.cancel);

		// Prevent popover click to trigger body click event
		this.$el.click(function(e) { e.stopPropagation(); });

		if(this.alwaysOnTop) {
			this.$el.css('z-index', 10000);
		}

		this.updatePlacement();
	},

	destroyPopovers: function () {
		_.each($('.popover'), function (popover) {
			$(popover).popover('destroy');
		});
	},

	bodyClick: function (e) {
		if (this.parent && !this.parent.is(e.target) && this.$el && this.$el.is(":visible")) {
			this.close();
		}
	},

	keyDown: function(e) {
		if(e.which === 27) {
			this.close();
		}
	},

	setTitle: function (title) {
		this.$('.popover-title').text(title);
		this.$('.popover-title').show();
	},

	updatePlacement: function () {
		var popover = this.$el.data('bs.popover');
		var $tip = popover.tip();

		// use below code to test moving popover
		// $tip.offset({top: $tip[0].offsetTop - 200, left: $tip[0].offsetLeft});

		var placement = this.placement;
		var parentPos = popover.getPosition(this.parent);
		var $containerParent = $("#react-root" );
		var containerParentPos = popover.getPosition($containerParent);

		var autoToken = /\s?auto?\s?/i;
		var autoPlace = autoToken.test(placement);
		if (autoPlace) {
			// center screen
			if (parentPos.left > containerParentPos.width / 2){
				placement = 'auto left';
			}
			else {
				placement = 'auto right';
			}
			placement = placement.replace(autoToken, '') || 'top';
		}

		// is this needed?
		$tip
			.detach()
			.css({ top: 0, left: 0, display: 'block' })
			.addClass(placement)
			.data('bs.popover', popover);

		if (popover.options.container) {
			$tip.appendTo(popover.options.container);
		}
		else {
			$tip.insertAfter(popover.$element);
		}

		var pos = popover.getPosition();

		var actualWidth = $tip[0].offsetWidth;
		var actualHeight = $tip[0].offsetHeight;

		if (autoPlace) {
			var orgPlacement = placement;

			this.placement = placement = placement == 'bottom' && pos.top + pos.height + actualHeight - containerParentPos.scroll > containerParentPos.height ? 'top' :
					placement == 'top' && pos.top - containerParentPos.scroll - actualHeight < 0 ? 'bottom' :
					placement == 'right' && pos.right + actualWidth > containerParentPos.width ? 'left' :
					placement == 'left' && pos.left - actualWidth < containerParentPos.left ? 'right' :
				placement;

			this.$el
				.removeClass(orgPlacement)
				.addClass(placement);
		}

		var calculatedOffset = popover.getCalculatedOffset(placement, pos, actualWidth, actualHeight);

		// fix for bootstrap bug
		// adjust offset if new position is outside parent
		// happens for calendar forms when only one grid column
		//
		var containerParentWidth = $containerParent.width();
		var padding = this.viewport ? this.viewport.padding : 20;
		// center if popover parent (e.g. gridMarker) is bigger than half the container
		var centerParent = (parentPos.width / containerParentWidth) > (1/2);

		// outside to the right?
		if ((calculatedOffset.left + actualWidth) > containerParentWidth) {
			if (centerParent){
				calculatedOffset.left =  parentPos.left + parentPos.width / 2 - actualWidth / 2;
			}
			else {
				calculatedOffset.left = containerParentWidth - actualWidth - padding;
			}
		}

		// outside to the left?
		if (calculatedOffset.left < 0){
			if (centerParent){
				calculatedOffset.left = parentPos.left + parentPos.width / 2 - actualWidth / 2;
			}
			else {
				calculatedOffset.left = padding;
			}
		}

		popover.applyPlacement(calculatedOffset, placement);
	}
});