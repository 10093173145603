import React from 'react';
import ReactPopover from '../../../utils/popover';

export const Popover = (props) => {
  return (
    <ReactPopover
      enterExitTransitionDurationMs={0}
      refreshIntervalMs={150}
      style={{ zIndex: 1000 }}
      {...props}
    >
      {props.children}
    </ReactPopover>
  );
};

