import { defineMessages } from 'react-intl';

export const msgLoginForm = defineMessages({
  logIn: {
    id: 'redux-login.LoginForm.logIn',
    defaultMessage: 'Logga in'
  },
  loggingIn: {
    id: 'redux-login.LoginForm.loggingIn',
    defaultMessage: 'Loggar in...'
  },
  missAuthData: {
    id: 'redux-login.LoginForm.missAuthData',
    defaultMessage: 'Felaktigt användarnamn eller lösenord'
  },
  connectionError: {
    id: 'redux-login.LoginForm.connectionError',
    defaultMessage: 'Problem att ansluta till servern {lastTrymessage}'
  },
  email: {
    id: 'redux-login.LoginForm.email',
    defaultMessage: 'E-postadress'
  },
  password: {
    id: 'redux-login.password',
    defaultMessage: 'Lösenord'
  }
});
