import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { exportCustomers } from '../../state/customer-actions';
import Modal from '../common/modal';

import { msgCustomerExportDialog as msg } from '../../localization/messages/components/customer';

class CustomerExportDialog extends Component {
  state = {
    resourceId: null,
    includeHistory: true,
    progress: false
  };

  onClose = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.onClose();
  };

  resourceChanged = (resourceId) => {
    this.setState({ resourceId });
  };

  includeHistoryChanged = (ev) => {
    this.setState({ includeHistory: ev.target.checked });
  };

  exportCustomers = (ev) => {
    ev.preventDefault();
    const { resourceId, includeHistory } = this.state;
    this.setState({ progress: true });
    this.props.exportCustomers(resourceId, includeHistory)
      .then(() => this.setState({ progress: false }));
  };

  render() {
    const { resourcesById } = this.props;
    const { includeHistory, progress } = this.state;

    return (
      <Modal
        titleText="Export"
        underlayClickExits={false}
        includeDefaultStyles={false}
        dialogClass="dashl-modal"
        underlayClass="dashl-modal-underlay"
        initialFocus=".modal-body"
        onExit={this.onClose}
        mounted
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={this.onClose}>&times;</button>
          <h4 className="modal-title"><FormattedMessage {...msg.heading} /></h4>
        </div>
        <div className="modal-body">
          <p>
            <FormattedMessage {...msg.description} />
          </p><br />
          <form className={progress ? 'form-horizontal disabled' : 'form-horizontal'}>
            <div className="form-group">
              <label className="control-label col-xs-4"><FormattedMessage {...msg.lblCustomers} /></label>
              <div className="col-xs-8">
                <div className="radio">
                  <label>
                    <input type="radio" name="resourceId" defaultChecked onChange={() => this.resourceChanged()} />
                    <FormattedMessage {...msg.lblAllCustomers} />
                  </label>
                </div><br />
                {resourcesById.valueSeq().map(({ id, name, hashId }) => (
                  <div className="radio" key={id}>
                    <label>
                      <input type="radio" name="resourceId" onChange={() => this.resourceChanged(hashId)} /> {name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-xs-4"><FormattedMessage {...msg.lblHistory} /></label>
              <div className="col-xs-8">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" checked={includeHistory} onChange={this.includeHistoryChanged} />
                    <FormattedMessage {...msg.lblIncludeHistory} />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="col-xs-offset-4 col-xs-8">
                <br />
                <button className="btn btn-primary" onClick={this.exportCustomers} disabled={progress}>
                  {progress ? <FormattedMessage {...msg.btnDownload} /> : <FormattedMessage {...msg.btnDownloading} />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    exportCustomers: (resourceId, includeHistory) => {
      return dispatch(exportCustomers(resourceId, includeHistory));
    }
  };
};

export default connect(null, mapDispatchToProps)(CustomerExportDialog);
