var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n    <br><br>\n    <button class=\"btn-add js-new-schedule pull-right\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"addSchedule",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n    <div id=\"schedule-timeline-container\"></div>\n    <br><br><br>\n    <div class=\"pull-right\">\n        <a href=\"#\" class=\"btn-delete js-delete-schedule\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"remove",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n        <button class=\"btn-cancel js-cancel-schedule\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"reset",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n        <button class=\"btn-save js-save-schedule\" data-loading-text=\""
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"savingSchedule",{"name":"i18n","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"saveScheduleChanges",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n    </div>\n    <div id=\"schedule-container\"></div>\n</div>\n";
},"useData":true});