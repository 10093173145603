import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getWebBookingPreferences, saveWebBookingPreferences } from '../../../state/preferences-actions';
import WebBookingForm from './web-booking-form';
import WebBookingInfo from './web-booking-info';
import { getSlotFinderParams } from '../../../utils/web-booking';

class WebBooking extends Component {

  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getWebBookingConfig();
    }
  }

  render() {
    const initialValues = this.props.preferences ? this.props.preferences.toJS() : null;
    const { smsPinPrice, org, loc, stableId, locationFeatures } = this.props;

    return (
      <div className="row">
        <div className="col-sm-6">
          <WebBookingForm
            initialValues={initialValues}
            onSubmit={this.submitForm}
            smsPinPrice={smsPinPrice}
            features={locationFeatures.toJS()}
          />
        </div>
        <div className="col-sm-6">
          <WebBookingInfo org={org} loc={loc} stableId={stableId} features={locationFeatures.toJS()} />
        </div>
      </div>
    );
  }

  submitForm = (values) => {
    values.cancelUpToMinutes = values.cancelUpToHours * 60;

    return this.props.saveWebBookingConfig(values);
  };
}

WebBooking.propTypes = {
  preferences: PropTypes.object,
  stableId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { preferencesViewState, locationConfig, locationFeatures } = state;
  const { org, loc } = ownProps.match.params;

  const smsPinPrice = locationConfig.get('smsPrices').find((smsPrice) => {
    return smsPrice.get('priceType') == 'Pin';
  });

  return {
    preferences: preferencesViewState.get('webBookingPreferences'),
    locationFeatures,
    smsPinPrice: smsPinPrice ? smsPinPrice.get('price') : null,
    stableId: locationConfig.get('stableId'),
    org,
    loc
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWebBookingConfig: () => {
      dispatch(getWebBookingPreferences());
    },
    saveWebBookingConfig: (data) => {
      data.slotFinderParams = getSlotFinderParams(data, data.slotFinderStrategy);
      return dispatch(saveWebBookingPreferences(data));
    }
  };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(WebBooking);
