import React, { Component } from 'react';

class CheckBox extends Component {

  render() {
    const { input, label, readOnly, meta: { warning } } = this.props;

    return (
      <div className="checkbox">
        <label>
          <input type="checkbox" disabled={readOnly} checked={input.value} {...input} />
          {' '}{label}{' '}
          {warning && <i className="fa fa-fw fa-info-circle text-highlight-warn" title={warning} />}
        </label>
      </div>
    );
  }
}

export default CheckBox;
