import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditGroupForm from './edit-group-form';
import { Popover } from '../common/popover';

export default class SortableList extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    groupItemSelector: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    itemContent: PropTypes.func.isRequired,
    selectedId: PropTypes.number,
    onItemClick: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    moveGroup: PropTypes.func.isRequired,
    moveItem: PropTypes.func.isRequired,
    sortable: PropTypes.bool.isRequired
  };

  state = {
    editGroupId: null,
    collapsedIds: []
  };

  onItemClick = (ev, item) => {
    ev.preventDefault();
    this.props.onItemClick(item);
  };

  collapseGroup = (ev, id) => {
    ev.preventDefault();
    const collapsedIds = [...this.state.collapsedIds, id];
    this.setState({ collapsedIds });
  };

  expandGroup = (ev, id) => {
    ev.preventDefault();
    const collapsedIds = [...this.state.collapsedIds];
    collapsedIds.splice(collapsedIds.indexOf(id), 1);
    this.setState({ collapsedIds });
  };

  editGroup = (ev, id) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editGroupId: id });
  };

  saveGroup = (name) => {
    const { editGroupId } = this.state;
    return this.props.updateGroup({ id: editGroupId, name })
      .then(() => this.setState({ editGroupId: null }));
  };

  deleteGroup = (ev, id) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.deleteGroup(id);
  };

  closeGroupPopover = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.setState({ editGroupId: null });
  };

  editGroupContent = (name) => {
    return (
      <EditGroupForm
        onClosePopover={this.closeGroupPopover}
        onEditGroup={this.saveGroup}
        name={name}
      />
    );
  };

  handleDragEnd = (result) => {
    if (result.destination == null) {
      return;
    }

    if (result.type === 'GROUP') {
      const groupId = parseInt(result.draggableId.substring(1));
      const destPos = result.destination.index;
      const srcPos = result.source.index;
      const moveAction = {
        groupId,
        srcPos,
        destPos
      };

      this.props.moveGroup(moveAction);

      return;
    }

    const itemId = result.draggableId;
    const srcGrpId = parseInt(result.source.droppableId.substring(1));
    const destGrpId = parseInt(result.destination.droppableId.substring(1));
    const destPos = result.destination.index;
    const srcPos = result.source.index;
    const moveAction = {
      destGrpId,
      srcGrpId,
      srcPos,
      destPos,
      itemId
    };

    this.props.moveItem(moveAction);
  };

  render() {
    return (
      <div className="columns-list">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="group" type="GROUP">
            {(provided, snapshot) => (
              <div
                style={{ backgroundColor: snapshot.isDraggingOver ? '#c5d2d9' : '#c5d2d9' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.renderList()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  renderList() {
    const { groups } = this.props;

    return groups && groups.map((group, index) => {
      const draggableId = `g${group.get('id')}`;

      return (
        <Draggable draggableId={draggableId} key={draggableId} index={index} isDragDisabled={!this.props.sortable}>
          {(provided, snapshot) => {
            const style = {
              filter: snapshot.isDragging ? 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3)' : 'none',
              ...provided.draggableProps.style
            };
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                {this.renderGroup(group)}
              </div>
            );
          }}
        </Draggable>
      );
    });
  }

  getGroupItems(group) {
    const { items, groupItemSelector } = this.props;
    const itemIds = group.get(groupItemSelector);

    return itemIds.map(id => items.get(id)).filter(v => v);
  }

  renderGroup(group) {
    const { collapsedIds } = this.state;
    const { id, name } = group.toJS();

    const isCollapsed = collapsedIds.indexOf(id) !== -1;
    const groupItems = this.getGroupItems(group);

    return (
      <Droppable droppableId={`g${id}`} key={`group${id}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ backgroundColor: snapshot.isDraggingOver ? '#c5d2d9' : '#c5d2d9' }}
          >
            {group.get('id') === 0 ? this.render0GroupHeader() : this.renderGroupHeader(group)}
            {!isCollapsed && groupItems && groupItems.map(this.renderItem)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  render0GroupHeader(group) {
    return (
      <div className="columns-list-header" style={{ pointerEvents: 'none' }}>
        <span />
      </div>
    );
  }

  renderGroupHeader(group) {
    const { sortable } = this.props;
    const { collapsedIds } = this.state;
    const { id, name } = group.toJS();
    const isCollapsed = collapsedIds.indexOf(id) !== -1;


    return (
      <div className="columns-list-header" onClick={ev => isCollapsed ? this.expandGroup(ev, id) : this.collapseGroup(ev, id)}>
        {sortable ? this.renderDragHandle(group) : this.renderGroupMenu(group)}
        <i className={isCollapsed ? 'fa fa-fw fa-caret-right' : 'fa fa-fw fa-caret-down'} />
        <span>{name}</span>
      </div>
    );
  }

  renderGroupMenu(group) {
    const { groupItemSelector, readOnly } = this.props;
    const { editGroupId } = this.state;

    const { id, name } = group.toJS();
    const isEmpty = group.get(groupItemSelector).count() === 0;
    const showEditGroup = editGroupId === id;

    if (isEmpty) {
      return (
        <a href="#" className="pull-right" onClick={ev => this.deleteGroup(ev, id)}>
          <i className="fa fa-fw fa-minus-circle text-danger" />
        </a>
      );
    }
    if (!readOnly) {
      return (
        <Popover
          isOpen={showEditGroup}
          body={this.editGroupContent(name)}
          onOuterAction={this.closeGroupPopover}
          preferPlace="above"
        >
          <a href="#" className="pull-right" onClick={ev => this.editGroup(ev, id)}>
            <i className="fa fa-fw fa-ellipsis-h" />
          </a>
        </Popover>
      );
    }
  }

  renderDragHandle() {
    return (
      <span className="pull-right">
        <i className="fa fa-align-justify" style={{ color: '#b0b0b0' }} />
      </span>
    );
  }

  renderItem = (item, index) => {
    const { id } = item;
    const { selectedId, sortable } = this.props;
    const className = id === selectedId ? 'columns-list-item selected' : 'columns-list-item';

    return (
      <Draggable draggableId={id} index={index} key={`item${id}`} isDragDisabled={!this.props.sortable}>
        {(provided, snapshot) => {
          const style = {
            filter: snapshot.isDragging ? 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))' : 'none',
            ...provided.draggableProps.style
          };

          return (
            <div
              className={className}
              onClick={ev => this.onItemClick(ev, item)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              { sortable && (<span className="pull-right"><i className="fa fa-align-justify" style={{ color: '#b0b0b0' }} /></span>)}

              {this.props.itemContent(item)}
            </div>
          );
        }}
      </Draggable>
    );
  }
}
