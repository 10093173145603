import axios from 'axios';
import * as FileSaver from 'file-saver';

import { prefixAjaxUrl } from '../utils/url-util';
import { axiosDefault, axiosErrorHandler, checkStatusAxios } from '../utils/ajax-util';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export function customerCreated(customer, source = 'local') {
  return {
    type: CREATE_CUSTOMER,
    customer,
    source
  };
}

export function customerUpdated(id, customer, source = 'local') {
  return {
    type: UPDATE_CUSTOMER,
    id,
    customer,
    source
  };
}

export function createCustomer(customer) {
  const url = prefixAjaxUrl('/customers/');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.post(url, { ...customer }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(customerCreated(res.data, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateCustomer(id, customer) {
  const url = prefixAjaxUrl(`/customers/${id}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, { ...customer }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(customerUpdated(id, res.data, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function exportCustomers(resourceId, includeHistory) {
  const params = [];
  if (resourceId) {
    params.push(`resourceId=${resourceId}`);
  }
  if (includeHistory) {
    params.push('includeHistory=true');
  }

  const query = params.length > 0 ? `?${params.join('&')}` : '';
  const url = prefixAjaxUrl(`/export/customers.csv${query}`);
  const config = {
    ...axiosDefault(),
    responseType: 'blob'
  };

  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then((res) => {
        let fileName = 'customers.csv';
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition) {
          const match = contentDisposition.match(/filename=([^;]+)/);
          if (match.length === 2) {
            fileName = match[1];
          }
        }
        FileSaver.saveAs(new Blob([res.data]), fileName);
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
