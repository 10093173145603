var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div>\n    <div class=\"schedule-item edit-mode\" style=\"height: "
    + ((stack1 = (helpers.GetHeight || (depth0 && depth0.GetHeight) || alias2).call(alias1,depth0,{"name":"GetHeight","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <strong>"
    + alias3((helpers.Time || (depth0 && depth0.Time) || alias2).call(alias1,(depth0 != null ? depth0.start : depth0),{"name":"Time","hash":{},"data":data}))
    + "-"
    + alias3((helpers.Time || (depth0 && depth0.Time) || alias2).call(alias1,(depth0 != null ? depth0.end : depth0),{"name":"Time","hash":{},"data":data}))
    + "</strong><br>\n      <em class=\"small\">"
    + alias3((helpers.TimeDiff || (depth0 && depth0.TimeDiff) || alias2).call(alias1,depth0,{"name":"TimeDiff","hash":{},"data":data}))
    + "</em>\n   </div>\n</div>\n";
},"useData":true});