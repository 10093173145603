var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "   <div class=\"user-list-item text-muted text-emphasize\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "   <div class=\"user-list-item\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "               <span>"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"userResource",{"name":"i18n","hash":{},"data":data}))
    + ": <strong>"
    + alias3(((helper = (helper = helpers.resourceName || (depth0 != null ? depth0.resourceName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data}) : helper)))
    + "</strong></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "               "
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"noResource",{"name":"i18n","hash":{},"data":data}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "               "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"invited",{"name":"i18n","hash":{},"data":data}))
    + " - <a class=\"js-delete\" href=\"#\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "               <a class=\"js-edit\" href=\"#\">"
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"change",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pendingInvite : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      <div class=\"row\">\n         <div class=\"col-sm-3\">\n            <strong>"
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</strong>\n         </div>\n         <div class=\"col-sm-4\">\n            <span>"
    + ((stack1 = (helpers.RoleDescription || (depth0 && depth0.RoleDescription) || alias2).call(alias1,(depth0 != null ? depth0.userRole : depth0),(depth0 != null ? depth0.organisationRole : depth0),{"name":"RoleDescription","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n         </div>\n         <div class=\"col-sm-3\" >\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.resourceName : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "         </div>\n         <div class=\"col-sm-2 text-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pendingInvite : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "         </div>\n      </div>\n   </div>\n</li>";
},"useData":true});