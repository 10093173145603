import axios from 'axios';
import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '../utils/ajax-util';

import { getCurrentActiveServices } from '../utils/selectors';

export const SERVICES_LOADED = 'SERVICES_LOADED';
export const RES_SRV__MAPPINGS_LOADED = 'RES_SRV__MAPPINGS_LOADED';
export const SERVICE_MAPPING_ADDED = 'SERVICE_MAPPING_ADDED';
export const SERVICE_MAPPING_DELETED = 'SERVICE_MAPPING_DELETED';
export const SERVICE_MAPPINGS_SET = 'SERVICE_MAPPINGS_SET';

export const SERVICE_GROUP_ADDED = 'SERVICE_GROUP_ADDED';
export const SERVICE_GROUP_UPDATED = 'SERVICE_GROUP_UPDATED';
export const SERVICE_GROUP_DELETED = 'SERVICE_GROUP_DELETED';
export const SERVICE_ADDED = 'SERVICE_ADDED';
export const SERVICE_UPDATED = 'SERVICE_UPDATED';
export const SERVICE_DELETED = 'SERVICE_DELETED';
export const CUSTOM_VALUES_UPDATED = 'CUSTOM_VALUES_UPDATED';
export const SERVICE_MOVED = 'SERVICE_MOVED';
export const SERVICE_GROUP_MOVED = 'SERVICE_GROUP_MOVED';

export const serviceMappingValues = [
  'serviceDuration', 'afterTime', 'price', 'priceFrom',
  'webEnabled', 'webShowDuration', 'webShowPrice'
];

export const isServiceMappingDiff = (values, mappings, prop) => {
  return mappings.find((mapping) => {
    if (!mapping.get('customValues')) {
      return false;
    }
    return String(mapping.get(prop)) !== String(values[prop]);
  });
};

export function fetchServices() {
  return (dispatch) => {
    const url = prefixUrl('/services/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((services) => {
        dispatch({ type: SERVICES_LOADED, services });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchAllResourceMappings() {
  return (dispatch) => {
    const url = prefixUrl('/services/resource-mappings');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((responseData) => {
        dispatch({ type: RES_SRV__MAPPINGS_LOADED, ...responseData });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addServiceGroup(group) {
  return (dispatch) => {
    const url = prefixUrl('/servicegroup/');
    const config = axiosDefault();
    const data = { name: group.name };

    return axios.post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((group) => {
        dispatch({ type: SERVICE_GROUP_ADDED, group });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateServiceGroup(group) {
  return (dispatch) => {
    const url = prefixUrl(`/servicegroup/${group.id}`);
    const config = axiosDefault();
    const data = { name: group.name };

    return axios.put(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: SERVICE_GROUP_UPDATED, group });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceGroup(groupId) {
  return (dispatch) => {
    const url = prefixUrl(`/servicegroup/${groupId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_GROUP_DELETED, groupId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addService(service) {
  return (dispatch) => {
    const groupId = parseInt(service.groupId);
    const url = prefixUrl(`/services/group/${groupId}`);
    const config = axiosDefault();

    return axios.post(url, service, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((service) => {
        dispatch({ type: SERVICE_ADDED, service, groupId });
        return service;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateService(service) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${service.id}`);
    const config = axiosDefault();

    return axios.put(url, service, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: SERVICE_UPDATED, service });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteService(serviceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_DELETED, serviceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceMapping(serviceId, resourceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/resource/${resourceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MAPPING_DELETED, serviceId, resourceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addServiceMapping(serviceId, resourceId) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const { serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom } = srv;
    const mapping = { serviceId, customValues: false, serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom };

    const url = prefixUrl(`/services/${serviceId}/resource/${resourceId}/enabled`);
    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MAPPING_ADDED, serviceId, resourceId, mapping });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addMultipleServicesMapping(serviceIds, resourceId) {
  return (dispatch, getState) => {
    const services = serviceIds.map(id => getState().servicesById.get(id));
    const mapping = services.map(s => ({
      serviceId: s.id,
      customValues: false,
      serviceDuration: s.serviceDuration,
      afterTime: s.afterTime,
      price: s.price,
      webEnabled: s.webEnabled,
      webShowDuration: s.webShowDuration,
      webShowPrice: s.webShowPrice,
      priceFrom: s.priceFrom
    }));

    const previousSelected = getCurrentActiveServices(getState(), resourceId);
    const previousAndNewUnique = [...new Set([...serviceIds, ...previousSelected])];

    const url = prefixUrl(`/services/resource/${resourceId}/enabled/${previousAndNewUnique.join(',')}`);
    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        mapping.forEach((service) => {
          dispatch({ type: SERVICE_MAPPING_ADDED, serviceId: service.serviceId, resourceId, mapping: service });
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteMultipleServicesMapping(serviceIds, resourceId) {
  return (dispatch, getState) => {
    const previousSelected = getCurrentActiveServices(getState(), resourceId);
    const previousWithoutNewUnique = previousSelected.filter(id => !serviceIds.includes(id));

    const url = prefixUrl(`/services/resource/${resourceId}/enabled/${previousWithoutNewUnique.join(',')}`);
    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        serviceIds.forEach((id) => {
          dispatch({ type: SERVICE_MAPPING_DELETED, serviceId: id, resourceId });
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setServiceMappings(serviceId, listOfResIds) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const { serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom } = srv;
    const mapping = { serviceId, customValues: false, serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom };


    const url = prefixUrl(`/services/${serviceId}/enabled/${listOfResIds.join(',')}`);

    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MAPPINGS_SET, serviceId, resourceIds: listOfResIds, mapping });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateCustomValues(serviceId, resourceId, customValues) {
  return (dispatch) => {
    const mapping = Object.assign({}, { serviceId, customValues: true }, customValues);

    const url = prefixUrl(`/services/resource/${resourceId}/customvalues/${serviceId}`);
    const config = axiosDefault();

    return axios.put(url, customValues, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: CUSTOM_VALUES_UPDATED, serviceId, resourceId, mapping });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteCustomValues(serviceId, resourceId) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const { serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom } = srv;
    const mapping = { serviceId, customValues: false, serviceDuration, afterTime, price, webEnabled, webShowDuration, webShowPrice, priceFrom };

    const url = prefixUrl(`/services/resource/${resourceId}/customvalues/${serviceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: CUSTOM_VALUES_UPDATED, serviceId, resourceId, mapping });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function moveGroup(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/services/group/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: SERVICE_GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: SERVICE_GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveService(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/services/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: SERVICE_MOVED, moveAction });

    const { srcGrpId, destGrpId: dstGrpId, srcPos: srcIdx, destPos: dstIdx, itemId } = moveAction;
    const body = {
      serviceId: itemId, srcGrpId, dstGrpId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { srcGrpId, destGrpId, srcPos, destPos, serviceId } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          serviceId
        };

        dispatch({ type: SERVICE_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}
