import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { msgPeriodInput as msg } from '../../localization/messages/components/inputs';

class PeriodInput extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.input.value && nextProps.input.value) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  getInitialState = (props) => {
    const values = this.getPeriodValues(props.input.value);
    return {
      amount: values[1],
      period: values[2]
    };
  };

  getPeriodValues = (value) => {
    const regex = /^P([0-9]+)([A-Z])$/g;
    return regex.exec(value) || ['', '0', 'D'];
  };

  getPeriodString = (amount, period) => {
    return `P${amount}${period}`;
  };

  changeAmount = (ev) => {
    ev.preventDefault();
    this.setState({ amount: ev.target.value });
    this.props.input.onChange(this.getPeriodString(ev.target.value, this.state.period));
  };

  changePeriod = (ev) => {
    ev.preventDefault();
    this.setState({ period: ev.target.value });
    this.props.input.onChange(this.getPeriodString(this.state.amount, ev.target.value));
  };

  render() {
    const { amount, period } = this.state;
    const { input: { name }, label, labelAfter, description, meta: { touched, error }, intl: { formatMessage: f } } = this.props;
    const isSingleAmount = parseInt(amount, 10) === 1;

    return (
      <div className={touched && error ? 'form-group has-error' : 'form-group'}>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-6">
          <input
            type="number"
            name={`${name}-amount`}
            value={amount}
            className="form-control inline-xs"
            onChange={this.changeAmount}
          />
          <select
            name={`${name}-period`}
            value={period}
            className="form-control inline-md"
            onChange={this.changePeriod}
          >
            <option value="D">{isSingleAmount ? f(msg.day) : f(msg.days)}</option>
            <option value="W">{isSingleAmount ? f(msg.week) : f(msg.weeks)}</option>
            <option value="M">{isSingleAmount ? f(msg.month) : f(msg.months)}</option>
            <option value="Y">{f(msg.year)}</option>
          </select>
          <span className="form-control-static">
            {labelAfter}
          </span>
          {touched && error && <span className="help-block">{error}</span>}
          {description && <span className="form-control-description">{description}</span>}
        </div>
      </div>
    );
  }
}

PeriodInput.propTypes = {
  label: PropTypes.string.isRequired
};

export default injectIntl(PeriodInput);
