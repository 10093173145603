import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import CurrencyUtil from '../../../utils/currency-util';
import TextInput from '../inputs/text-input';
import { createValidator, required, number, minValue } from '../../utils/validation';
import { formatMessage } from '../../intlContextInterseptor';
import SubmitButton from '../common/submit-button';
import CheckBox from '../inputs/checkbox';
import { isServiceMappingDiff, serviceMappingValues } from '../../state/services-actions';
import { msgEditServiceForm as msg } from '../../localization/messages/components/services';
import { msgValidation } from '../../localization/messages/utils/utils';
import { msgButtons } from '../../localization/messages/shared/buttons';
import { msgLabels } from '../../localization/messages/shared/labels';
import { Popover } from '../common/popover';

class EditServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProgress: false,
      showConfirmDelete: false
    };
  }

  showConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: false });
  };

  onDelete = (ev) => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props.onDelete(this.props.serviceId)
      .catch(() => this.setState({ deleteProgress: false }));
  };

  render() {
    const { handleSubmit, readOnly, intl: { formatMessage: f } } = this.props;

    return (
      <form onSubmit={handleSubmit}>

        <Field name="name" component={TextInput} label={f(msg.lblName)} readOnly={readOnly} />

        <div className="form-group">
          <label className="control-label">{f(msg.description)}</label>
          <Field component="textarea" name="description" rows={3} className="form-control" readOnly={readOnly} />
        </div>

        <Field name="serviceDuration" component={TextInput} label={f(msg.lblLength)} width={120} unitLabel={f(msgLabels.minute)} helpText={f(msg.helpLength)} readOnly={readOnly} />
        <Field name="afterTime" component={TextInput} label={f(msg.lblPauseAfter)} width={120} unitLabel={f(msgLabels.minute)} helpText={f(msg.helpPauseAfter)} readOnly={readOnly} />
        <Field name="price" component={TextInput} label={f(msg.lblPrice)} width={120} unitLabel={CurrencyUtil.currencySymbol()} readOnly={readOnly} />
        <Field name="priceFrom" component={CheckBox} label={f(msg.lblShowFromPrice)} readOnly={readOnly} />

        <hr />
        <div className="form-group">
          <label className="control-label">{f(msg.webBookingSettings)}</label>
          <Field name="webEnabled" component={CheckBox} label={f(msg.lblReservation)} />
          <Field name="webShowDuration" component={CheckBox} label={f(msg.lblShowLength)} readOnly={readOnly} />
          <Field name="webShowPrice" component={CheckBox} label={f(msg.lblShowPrice)} readOnly={readOnly} />
        </div>
        <hr />
        <div className="form-group">
          <SubmitButton {...this.props} /> &nbsp; &nbsp;
          {!readOnly && (
            <Popover isOpen={this.state.showConfirmDelete} body={this.popoverContent()} onOuterAction={this.hideConfirmDelete}>
              <button className="btn-delete" tabIndex="4" onClick={this.showConfirmDelete}>{f(msgButtons.btnRemove)}</button>
            </Popover>
          )}
        </div>
      </form>
    );
  }

  popoverContent() {
    const { intl: { formatMessage: f } } = this.props;
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        {f(msg.confirmDelete)}<br /><br />
        <button className="btn-delete btn-block" onClick={this.onDelete} disabled={this.state.deleteProgress}>{f(msg.btnDeleteService)}</button>
      </div>
    );
  }
}

const validator = createValidator({
  name: [required],
  serviceDuration: [required, number, minValue(5)],
  afterTime: [number, minValue(0)],
  price: [number, minValue(0)]
});

const warnings = (values, { resourceMappings }) => {
  if (resourceMappings.size === 0) {
    return null;
  }
  const warnings = {};
  const message = formatMessage(msgValidation.resourceWarnings);
  for (let i = 0; i < serviceMappingValues.length; i++) {
    if (isServiceMappingDiff(values, resourceMappings, serviceMappingValues[i])) {
      warnings[serviceMappingValues[i]] = message;
    }
  }
  return warnings;
};

export default reduxForm({
  form: 'edit-service-form',
  validate: validator,
  warn: warnings,
  shouldWarn: () => true
})(injectIntl(EditServiceForm));
