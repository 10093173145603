import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import RichTextEditorField from '../../inputs/rich-text-editor';
import EmailIcsConfig from '../../inputs/email-ics-config';
import SubmitButton from '../../common/submit-button';
import { msgEmailConfig as msg } from '../../../localization/messages/components/account-config';

const EmailConfigForm = (props) => {
  const { formatMessage: f } = useIntl();
  const { handleSubmit, features } = props;

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <br />

      {(features.emailOnBookOnline || features.emailOnCancelOnline) ? (
        <div className="form-group">
          <label className="col-sm-3 control-label">{msg.confirmation}</label>
          <div className="col-sm-4">
            {(features.emailOnBookOnline) ? (
              <div className="checkbox">
                <label>
                  <Field name="emailOnBookOnline" component="input" type="checkbox" />
                  {msg.emailOnBookOnline}
                </label>
              </div>
                     ) : null }
            {(features.emailOnCancelOnline) ? (
              <div className="checkbox">
                <label>
                  <Field name="emailOnCancelOnline" component="input" type="checkbox" />
                  {msg.emailOnCancelOnline}
                </label>
              </div>
                     ) : null }
          </div>
        </div>
            ) : null }

      {(features.defaultOptionEmailConfirm) ? (
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-7">
            <div className="checkbox">
              <label>
                <Field name="defaultOptionEmailConfirm" component="input" type="checkbox" />
                {msg.defaultOptionEmailConfirm}
              </label>
            </div>
          </div>
        </div>
            ) : null }

      {(features.emailConfirmExtraInfo) ? (
        <div>
          <hr />
          <div className="form-group">
            <label className="col-sm-3 control-label">{msg.extra}</label>
            <div className="col-sm-6">
              <Field name="emailConfirmExtraInfo" component={RichTextEditorField} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-offset-3 col-sm-5">
              <p className="text-muted">
                {msg.extraText}
              </p>
            </div>
          </div>
        </div>
            ) : null }

      <hr />

      {(features.emailIcsConfirm) ? (
        <div>
          <div className="form-group">
            <div className="col-sm-offset-3 col-sm-9">
              <EmailIcsConfig {...props} />
            </div>
          </div>
          <hr />
        </div>
            ) : null }

      <div className="form-group">
        <div className="col-sm-offset-3 col-sm-5">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const selector = formValueSelector('email-config');

const mapStateToProps = (state) => {
  return {
    includeIcs: selector(state, 'includeIcs'),
    icsHeader: selector(state, 'icsHeader'),
    icsLocation: selector(state, 'icsLocation'),
    icsNotes: selector(state, 'icsNotes')
  };
};

export default reduxForm({
  form: 'email-config'
})(connect(mapStateToProps)(EmailConfigForm));
