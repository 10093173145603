import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ResourceServiceItem from './resource-service-item';

import { msgResourceServiceGroup as msg } from '../../../localization/messages/components/resources';

export default class ResourceServiceGroup extends React.Component {
  static propTypes = {
    groupName: PropTypes.string.isRequired,
    services: PropTypes.object.isRequired,
    serviceMappings: PropTypes.object.isRequired
  };

  state = {
    progress: false
  };

  selectServiceGroup = (ev) => {
    const { services } = this.props;
    const serviceIds = services.map(s => s.id);
    const resetProgress = () => this.setState({ progress: false });

    this.setState({ progress: true });
    return this.props.selectServiceGroup(serviceIds, ev.target.checked)
      .then(resetProgress, resetProgress);
  }

  render() {
    const { progress } = this.state;
    const { groupId, groupName, services, resourceId, serviceMappings, showCustomValues } = this.props;
    const mappingIds = services.map(s => `${resourceId}:${s.id}`);
    const servicesMappings = mappingIds.map(id => !!serviceMappings.get(id));
    const allCheckedInGroup = !servicesMappings.some(s => s === false);
    const groupSlug = groupId + groupName.toLowerCase().replace(/[^a-z0-9]+/ig, '');

    return (
      <div className="resource-service-group">
        <div className="group-caption">
          <h4 style={{ marginBottom: 0 }}>{groupName}</h4>
          <div className={progress ? 'group-caption-control disabled' : 'group-caption-control'}>
            <label htmlFor={`${groupSlug}-checkbox`}>
              <FormattedMessage {...msg.chooseAll} />
            </label>
            <input
              className="cb-checkbox"
              id={`${groupSlug}-checkbox`}
              type="checkbox"
              onChange={this.selectServiceGroup}
              checked={allCheckedInGroup}
            />
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" />
              <th scope="col" style={{ width: '50%' }} />
              <th scope="col" />
              {showCustomValues && <th scope="col" />}
            </tr>
          </thead>
          <tbody>
            {services.map((service) => {
              const mappingId = `${resourceId}:${service.id}`;
              const serviceMapping = serviceMappings.get(mappingId);
              const customValues = serviceMapping && serviceMapping.toJS();

              return (
                <ResourceServiceItem
                  key={service.id}
                  active={!!serviceMapping}
                  selectService={this.props.selectService}
                  setCustomValues={this.props.setCustomValues}
                  showCustomValues={showCustomValues}
                  {...service}
                  {...customValues}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4">
                {showCustomValues && (
                  <span className="pull-right">
                    <i className="fa fa-exclamation-circle text-info" /> <FormattedMessage {...msg.valuesForService} />
                  </span>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
