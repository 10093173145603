import shallowEqual from 'fbjs/lib/shallowEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { clearAndCloseBKF } from '../../state/bkf-actions';
import { fetchViewData, storeViewState, updateViewDimensions } from '../../state/view-actions';
import { getPermissions, routeParams } from '../../utils/selectors';
import { translateRouteParams } from '../../utils/time-util';
import { removeWakeListener, setWakeListener } from '../../utils/wake-me-up';
import CalendarSideBar from './calendar-sidebar';
import CalendarTabBar from './calendar-tabbar';
import Grid from '../grid/grid';
import BookingPrintModal from '../booking/booking-print-modal';
import ChaplinCustomerForm from '../customer/cf-chaplin';
import SearchResults from './search-results';
import { getCalendarUrl, navigate } from '../../utils/navigate';

class Calendar extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    storeViewState: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.reloadViewData = _.debounce(this.props.fetchLatestViewData, 500);
  }

  componentDidMount() {
    this.props.updateViewDimensions();
    const viewState = this.getViewState(this.props);
    this.props.storeViewState(viewState);
  }

  handleOnline = () => {
    console.info("Browser reported 'online' will try to reload viewData");
    setTimeout(() => this.reloadViewData(this.props.routeParams), 500);
  };

  handleWake = () => {
    console.info('Awake from sleep detected - will try to reload viewData');

    setTimeout(() => {
      if (window.navigator.onLine) {
        this.reloadViewData(this.props.routeParams);
      } else {
        console.info('Browser not online as reported by window.navigator.onLine, skipping reloadViewData');
      }
    }, 500);
  };

  componentWillMount() {
    window.addEventListener('online', this.handleOnline);
    setWakeListener(this.handleWake);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    removeWakeListener();

    // Clear booking form state on navigation away from calendar
    this.props.clearAndCloseBKF();
  }

  componentWillReceiveProps(nextProps) {
    const { routeParams, showSearch } = this.props;

    if (!shallowEqual(routeParams, nextProps.routeParams)) {
      const viewState = this.getViewState(nextProps);
      this.props.storeViewState(viewState);

      // Clear booking form state on navigation within calendar
      this.props.clearAndCloseBKF();
    }
    if (showSearch !== nextProps.showSearch) {
      navigate(getCalendarUrl(routeParams.viewDate, routeParams));
      setTimeout(() => this.props.updateViewDimensions(), 300);
    }
  }

  render() {
    const { routeParams, showSearch } = this.props;

    return (
      <div className="view-static">
        <BookingPrintModal />
        <ChaplinCustomerForm />
        <CalendarSideBar {...this.props} />
        <div id="canvas" className={showSearch ? 'show-search' : ''}>
          <CalendarTabBar routeParams={routeParams} />
          <Grid routeParams={routeParams} />
        </div>
        <SearchResults routeParams={routeParams} />
        <ReactTooltip
          effect="solid"
          place="bottom"
          className="web-indicator-tooltip"
          globalEventOff="mousedown touchstart"
        />
      </div>
    );
  }

  getViewState(props) {
    const { org, loc, viewDate, viewMode, entityType, entityId } = props.routeParams;

    return {
      org,
      loc,
      viewMode,
      viewDate,
      entityType,
      entityId
    };
  }
}

const mapStateToProps = (state, { match, location }) => {
  const rp = routeParams(translateRouteParams(match.params, location.hash));
  const permissions = getPermissions(state, { routeParams: rp });
  const { bookingSearchResults } = state;

  return {
    routeParams: rp,
    permissions,
    showSearch: bookingSearchResults.get('showSearch')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeViewState: (lastView) => {
      dispatch(storeViewState(lastView));
    },
    updateViewDimensions: () => {
      dispatch(updateViewDimensions());
    },
    fetchLatestViewData: (rp) => {
      const { viewDate, viewMode, entityType, entityId } = rp;
      if (viewDate && viewMode && entityType && entityId) {
        console.info(`Refreshing view data for ${viewMode}, ${viewDate}, ${entityType}, ${entityId}`);
        dispatch(fetchViewData(viewMode, viewDate, entityType, entityId));
      } else {
        console.warn(`NOT reloading view data, routeParams not complete: viewMode: ${viewMode}, viewDate: ${viewDate}, enitytType: ${entityType}, entityId: ${entityId}`);
      }
    },
    clearAndCloseBKF: () => {
      dispatch(clearAndCloseBKF());
    }
  };
};

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(Calendar);
