const TIMEOUT = 60000;

let lastTime = Date.now();
let callback = null;

setInterval(() => {
  const currentTime = Date.now();
  const sleepTime = currentTime - lastTime;
  if (sleepTime > (TIMEOUT)) {
    console.log(`Wake from sleep detected, sleepTime: ${sleepTime} - calling callback`);
    if (callback) callback();
  }
  lastTime = currentTime;
}, 10000);

export function setWakeListener(listener) {
  callback = listener;
}

export function removeWakeListener() {
  callback = null;
}
