import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import React, { Component, Fragment } from 'react';
import zxcvbn from 'zxcvbn';

import { msgPasswordStrength as msg } from '../../localization/messages/components/user-settings';

class PasswordStrength extends Component {

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      passwordStrength: 0
    };
  }

  render() {
    const { newPassword, intl: { formatMessage: f } } = this.props;

    return (
      <div style={newPassword.length == 0 ? { display: 'none' } : {}}>
        <p className="text-muted small">
          {f(msg.tip, { withApos: (...chunks) => <Fragment>&apos;{chunks}&apos;</Fragment> })}<br />
        </p>
        <div className="progress">
          <div className={this.getProgressClass()} style={this.getProgressStyle()}>
            {this.getProgressText()}
          </div>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.newPassword != nextProps.newPassword) {
      const passwordStrength = zxcvbn(nextProps.newPassword);
      this.setState({
        passwordStrength: passwordStrength.score
      });
    }
  }

  getProgressStyle() {
    const { passwordStrength } = this.state;
    return {
      width: `${(parseInt(passwordStrength) + 1) * 20}%`
    };
  }

  getProgressClass() {
    const { passwordStrength } = this.state;
    switch (passwordStrength) {
      case 2:
        return 'progress-bar progress-bar-warning';
      case 3:
      case 4:
        return 'progress-bar progress-bar-success';
      default:
        return 'progress-bar progress-bar-danger';
    }
  }

  getProgressText() {
    const { passwordStrength } = this.state;
    const { intl: { formatMessage: f } } = this.props;

    switch (passwordStrength) {
      case 1:
        return f(msg.weak);
      case 2:
        return f(msg.medium);
      case 3:
        return f(msg.strong);
      case 4:
        return f(msg.veryStrong);
      default:
        return f(msg.veryWeak);
    }
  }
}

PasswordStrength.propTypes = {
  newPassword: PropTypes.string.isRequired
};

export default injectIntl(PasswordStrength);
