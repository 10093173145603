const moment = require('moment');
const ModalView = require('../base/modal-view');

module.exports = ModalView.extend({
  noWrap: true,
  autoRender: true,
  weeks: 1,

  events: {
    'click .js-weeks': 'changeWeeks',
    'change .js-no-end-date': 'changeNoEndDate',
    'submit form': 'save'
  },

  initialize(options) {
    this.datePickerConfig = {
      calendarWeeks: true,
      startView: 0,
      todayBtn: 'linked',
      todayHighlight: true,
      language: 'sv',
      weekStart: 1,
      selectWeek: true
    };
    this.template = require('./new-schedule-modal');
    ModalView.prototype.initialize.call(this, arguments);
  },

  changeWeeks(e) {
    e.preventDefault();
    const selectedWeeks = $(e.currentTarget);
    this.weeks = parseInt(selectedWeeks.data('weeks'));
    this.$('.js-weeks').removeClass('active');
    selectedWeeks.addClass('active');
  },

  changeNoEndDate(e) {
    const noEndDate = e.currentTarget.checked;
    const endDate = this.$('#new-schedule-end-date');
    endDate.toggleClass('disabled', noEndDate);
  },

  save(ev) {
    ev.preventDefault();
    const hasEndDate = !this.$('.js-no-end-date').prop('checked');

    const formData = {
      name: this.$('input[name=name]').val(),
      validFrom: this.startDate,
      validUntil: hasEndDate ? this.endDate : null,
      weeks: this.weeks,
      blocks: this.getBlocks()
    };
    this.trigger('schedule:create', formData);
  },

  getBlocks() {
    const blocks = [];
    for (let week = 1; week <= this.weeks; week++) {
      for (let day = 1; day <= 5; day++) {
        blocks.push({ week, day, start: '08:00', end: '17:00' });
      }
    }
    return blocks;
  },

  dateChanged() {
    const start = moment(this.$('#new-schedule-start-date').datepicker('getDate')).startOf('isoWeek');
    let end = moment(this.$('#new-schedule-end-date').datepicker('getDate')).endOf('isoWeek');
    if (end < start) {
      end = start.clone().endOf('week');
    }

    this.startDate = start.format('YYYY-MM-DD');
    this.endDate = end.format('YYYY-MM-DD');

    this.$('#new-schedule-start-date').datepicker('update', start.toDate());
    this.$('#new-schedule-end-date').datepicker('update', end.toDate());
  },

  render() {
    ModalView.prototype.render.call(this, arguments);
    this.$('#new-schedule-start-date').datepicker(this.datePickerConfig).on('changeDate', _.bind(this.dateChanged, this));
    this.$('#new-schedule-end-date').datepicker(this.datePickerConfig).on('changeDate', _.bind(this.dateChanged, this));
    this.$('#new-schedule-start-date').datepicker('update', moment().startOf('isoWeek').toDate());
    this.$('#new-schedule-end-date').datepicker('update', moment().endOf('isoWeek').toDate());
    this.dateChanged();
    this.$('form').parsley();
  },

  close() {
    this.$('#new-schedule-start-date').datepicker('remove');
    this.$('#new-schedule-end-date').datepicker('remove');
    ModalView.prototype.close.call(this, arguments);
  }
});
