import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResourceConfigController from '../../../views/resource-config/resource-config-controller';

class ChaplinResourceConfig extends Component {

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    subsection: PropTypes.string.isRequired
  };

  render() {
    return (
      <div id="chaplin-view-container" />
    );
  }

  componentDidMount() {
    this.resourceConfigController = new ResourceConfigController(this.props.routeParams);
    this.resourceConfigController.open({ id: this.props.resourceId, section: this.props.subsection });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.subsection !== prevProps.subsection || this.props.resourceId !== prevProps.resourceId) {
      this.resourceConfigController.open({ id: this.props.resourceId, section: this.props.subsection });
    }
  }

  componentWillUnmount() {
    this.resourceConfigController.dispose();
  }
}

const mapStateToProps = (state, ownProps) => {
  const { entityId: resourceId, subsection } = ownProps.routeParams;
  return {
    resourceId,
    subsection
  };
};

export default connect(
   mapStateToProps
)(ChaplinResourceConfig);
