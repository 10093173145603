import * as R from 'ramda';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStoragePreferences, saveStoragePreferences } from '../../../state/preferences-actions';
import StorageConfigForm from './storage-config-form';

class StorageConfig extends Component {

  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getStorageConfig();
    }
  }

  render() {
    const preferences = this.props.preferences ? this.props.preferences.toJS() : null;
    const initialValues = preferences ? R.omit(['preferencesUpdated'], preferences) : null;

    return (
      <StorageConfigForm
        match={this.props.match}
        initialValues={initialValues}
        onSubmit={this.props.saveStorageConfig}
      />
    );
  }
}

StorageConfig.propTypes = {
  preferences: PropTypes.object
};

const mapStateToProps = (state) => {
  const { preferencesViewState } = state;

  return {
    preferences: preferencesViewState.get('storagePreferences')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStorageConfig: () => {
      dispatch(getStoragePreferences());
    },
    saveStorageConfig: (data) => {
      return dispatch(saveStoragePreferences(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StorageConfig);
