import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { msgSocialContactContent as msg } from '../../localization/messages/components/dialogs';

export default class SocialContactContent extends Component {
  render() {
    return (
      <div className="social-contact-content">
        <p><FormattedMessage {...msg.followUs} /></p><br />
        <div className="row">
          <div className="col-xs-1">
            <a href="https://www.facebook.com/Dashl.se/" target="_blank">
              <i className="fa fa-fw fa-facebook-official fa-2x fb-blue" />
            </a>
          </div>
          <div className="col-xs-3">
            <a href="https://www.facebook.com/Dashl.se/" target="_blank"><h3>Facebook</h3></a>
            <a href="https://www.facebook.com/Dashl.se/" target="_blank"><small>@Dashl.se</small></a>
          </div>

          <div className="col-xs-1">
            <a href="https://dashl.se/" target="_blank">
              <i className="fa fa-fw fa-twitter fa-2x twitter-blue" />
            </a>
          </div>
          <div className="col-xs-3">
            <a href="https://www.facebook.com/Dashl.se/" target="_blank"><h3>Twitter</h3></a>
            <a href="https://www.facebook.com/Dashl.se/" target="_blank"><small>@Dashl.se</small></a>
          </div>
        </div>
      </div>
    );
  }
}
