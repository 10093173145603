import { defineMessages } from 'react-intl';

export const msgCustomerExportDialog = defineMessages({
  heading: {
    id: 'customer.CustomerExportDialog.heading',
    defaultMessage: 'Ladda ner kundregister'
  },
  description: {
    id: 'customer.CustomerExportDialog.description',
    defaultMessage: 'Ladda ner kundregister med samtliga kunder eller för enskilda resurser. Inkludera historik för att få med kolumner för kundloggar och bokningar.'
  },
  lblCustomers: {
    id: 'customer.CustomerExportDialog.lblCustomers',
    defaultMessage: 'Kunder'
  },
  lblAllCustomers: {
    id: 'customer.CustomerExportDialog.lblAllCustomers',
    defaultMessage: 'Samtliga kunder'
  },
  lblHistory: {
    id: 'customer.CustomerExportDialog.lblHistory',
    defaultMessage: 'Historik'
  },
  lblIncludeHistory: {
    id: 'customer.CustomerExportDialog.lblIncludeHistory',
    defaultMessage: 'Inkludera loggar och bokningar'
  },
  btnDownload: {
    id: 'customer.CustomerExportDialog.btnDownload',
    defaultMessage: 'Ladda ner'
  },
  btnDownloading: {
    id: 'customer.CustomerExportDialog.btnDownloading',
    defaultMessage: 'Laddar ner...'
  }
});
