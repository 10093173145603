var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pull-right text-muted\" style=\"margin: 9px 5px 0 0\">\n          "
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"customerNumber",{"name":"i18n","hash":{},"data":data}))
    + "<span id=\"customer-id\"></span>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "             <li><a href=\"#customer-log-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"customerLog",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n             <li><a href=\"#customer-bookings-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"reservations",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "             <div class=\"text-center text-muted\">\n               <br />\n               <small><em><span id=\"customer-updated\"></span></em></small>\n             </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <a href=\"#\" class=\"btn-delete js-delete\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"removeCustomer",{"name":"i18n","hash":{},"data":data}))
    + "</a>\n                <div class=\"pull-right\">\n                    <button type=\"button\" class=\"btn-cancel js-cancel\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"cancel",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                    <button type=\"submit\" class=\"btn-save\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"save",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"pull-right\">\n                    <button type=\"submit\" class=\"btn-save\">"
    + container.escapeExpression((helpers.i18n || (depth0 && depth0.i18n) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"add",{"name":"i18n","hash":{},"data":data}))
    + "</button>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n   <div class=\"col-sm-12\">\n      <form class=\"form-horizontal\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <ul class=\"nav nav-tabs customer-nav-tabs\">\n         <li class=\"active\"><a href=\"#customer-info-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"customerInformation",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n         <li><a href=\"#customer-address-tab\" data-toggle=\"tab\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"address",{"name":"i18n","hash":{},"data":data}))
    + "</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </ul>\n      <div class=\"tab-content customer-tab-content\">\n         <div class=\"tab-pane active\" id=\"customer-info-tab\">\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"name",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control js-new-customer-name\" name=\"name\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"phoneForSms",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"phoneNumber\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"phoneAlternative",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"otherPhoneNumber\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"email",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"email\"/></div>\n             </div>\n             <br>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"dateOfBirth",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"dateOfBirth\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"gender",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\">\n                     <label class=\"radio-inline\"><input name=\"gender\" value=\"Male\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderMale",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                     <label class=\"radio-inline\"><input name=\"gender\" value=\"Female\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderFemale",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                     <label class=\"radio-inline\"><input name=\"gender\" value=\"None\" type=\"radio\"/> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"genderNone",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 </div>\n             </div>\n             <br>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"other",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><textarea rows=\"3\" class=\"form-control\" name=\"notes\"></textarea></div>\n             </div>\n             <br>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"settings",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\">\n                     <input type=\"hidden\" value=\"true\" name=\"emailReminders\" />\n                     <label class=\"checkbox-inline\"><input name=\"smsReminders\" type=\"checkbox\" value=\"true\"> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"smsReminders",{"name":"i18n","hash":{},"data":data}))
    + "</label><br>\n                     <label class=\"checkbox-inline\"><input name=\"allowMarketing\" type=\"checkbox\" value=\"true\"> "
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"allowMarketing",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 </div>\n             </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "         </div>\n         <div class=\"tab-pane\" id=\"customer-address-tab\">\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"stereetAddress",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"address1\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <div class=\"col-sm-6 col-sm-offset-4\"><input class=\"form-control\" name=\"address2\"/></div>\n             </div>\n             <div class=\"form-group hide\">\n                 <div class=\"col-sm-6 col-sm-offset-4\"><input class=\"form-control\" name=\"address3\"/></div>\n             </div>\n             <div class=\"form-group\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"postCode",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\">\n                     <div class=\"row\">\n                         <div class=\"col-xs-4\" style=\"padding-right: 0\"><input class=\"form-control\" name=\"postCode\"/></div>\n                         <div class=\"col-xs-8\"><input class=\"form-control\" name=\"area\"/></div>\n                     </div>\n                 </div>\n             </div>\n             <div class=\"form-group hide\">\n                 <label class=\"control-label col-sm-4\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"country",{"name":"i18n","hash":{},"data":data}))
    + "</label>\n                 <div class=\"col-sm-6\"><input class=\"form-control\" name=\"country\"/></div>\n             </div>\n         </div>\n         <div class=\"tab-pane\" id=\"customer-log-tab\">\n            <div id=\"customer-log-container\"></div>\n         </div>\n         <div class=\"tab-pane\" id=\"customer-bookings-tab\">\n            <div id=\"customer-bookings-container\"></div>\n         </div>\n      </div>\n      <div class=\"form-group\">\n         <div class=\"col-sm-12\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "         </div>\n      </div>\n      </form>\n   </div>\n</div>\n";
},"useData":true});