import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getBookingReport, getSmsReport } from '../state/report-actions';
import { fetchGroupsAndResources } from '../state/resource-actions';
import FeatureNotAvailable from '../components/dialogs/feature-not-available';
import Bookings from '../components/reports/bookings';
import Sms from '../components/reports/sms';

import { msgReports as msg } from '../localization/messages/containers/containers';
import { msgButtons } from '../localization/messages/shared/buttons';

class Reports extends Component {

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  componentDidMount() {
    if (this.props.resourcesById.isEmpty()) {
      this.props.fetchGroupsAndResources();
    }

    this.loadReport();
  }

  makeInitialState(props) {
    return {
      currentReport: this.reports[0],
      currentResource: props.resourcesById.first(),
      startDate: moment().startOf('month'),
      period: 'month'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resourcesById.isEmpty() && !nextProps.resourcesById.isEmpty() && !this.state.currentResource) {
      this.setState({
        currentResource: nextProps.resourcesById.first()
      }, this.loadReport);
    }
  }

  reports = [
      { id: 'bookings', name: this.props.intl.formatMessage(msg.bookingReport), component: Bookings, showResources: true },
      { id: 'sms', name: this.props.intl.formatMessage(msg.smsReport), component: Sms, showResources: false }
  ];

  showReport(ev, report) {
    ev.preventDefault();
    this.setState({
      currentReport: report
    }, this.loadReport);
  }

  resourceChanged(ev, resource) {
    ev.preventDefault();
    this.setState({
      currentResource: resource
    }, this.loadReport);
  }

  periodChanged = (ev, period) => {
    ev.preventDefault();
    this.setState({
      period,
      startDate: moment().startOf(period)
    }, this.loadReport);
  };

  navigatePrevious = (ev) => {
    ev.preventDefault();
    const { period, startDate } = this.state;

    this.setState({
      startDate: moment(startDate).subtract(1, period)
    }, this.loadReport);
  };

  navigateNext = (ev) => {
    ev.preventDefault();
    const { period, startDate } = this.state;

    this.setState({
      startDate: moment(startDate).add(1, period)
    }, this.loadReport);
  };

  print = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  render() {
    const { enableReports, intl: { formatMessage: f } } = this.props;

    if (enableReports) {
      return this.renderFeature();
    }

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <FeatureNotAvailable leadText={f(msg.reportsNotAvailable)} showLink />
      </div>
    );
  }

  renderFeature() {
    const { currentReport, currentResource } = this.state;
    const { reportsViewState, resourcesById, enableVehicleBooking, enableCompanyBooking, intl: { formatMessage: f } } = this.props;
    const Report = currentReport.component;

    let headerText = currentReport.name;
    if (currentReport.showResources && currentResource) {
      headerText += `, ${currentResource.name}`;
    }
    headerText += `, ${this.getDateText()}`;

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <div className="hidden-print">
          <div className="pull-right">
            <div className="btn-group">
              <button type="button" className="btn btn-default" onClick={this.navigatePrevious}>
                <i className="fa fa-chevron-left" />
              </button>
              <div className="btn-group">
                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                  {this.getDateText()} &nbsp; <span className="caret" />
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#" onClick={ev => this.periodChanged(ev, 'month')}>{f(msg.thisMonth)}</a></li>
                  <li><a href="#" onClick={ev => this.periodChanged(ev, 'week')}>{f(msg.thisWeek)}</a></li>
                  <li><a href="#" onClick={ev => this.periodChanged(ev, 'day')}>{f(msg.today)}</a></li>
                </ul>
              </div>
              <button type="button" className="btn btn-default" onClick={this.navigateNext}>
                <i className="fa fa-chevron-right" />
              </button>
            </div>
            <div className="btn-group" style={{ marginLeft: 10 }}>
              <button onClick={this.print} className="btn btn-default"><i className="fa fa-print" /> {f(msgButtons.btnPrint)}</button>
            </div>

          </div>
          <div className="btn-group">
            <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
              {currentReport.name} &nbsp; <span className="caret" />
            </button>
            <ul className="dropdown-menu">
              {this.reports.map((report) => {
                return (
                  <li key={report.id}><a href="#" onClick={ev => this.showReport(ev, report)}>{report.name}</a></li>
                );
              })}
            </ul>
          </div>

          {currentReport.showResources ?
            <div className="btn-group" style={{ marginLeft: 10 }}>
              <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                {currentResource ? currentResource.name : ''} &nbsp; <span className="caret" />
              </button>
              <ul className="dropdown-menu">
                {resourcesById.valueSeq().map((resource) => {
                  return (
                    <li key={resource.id}><a href="#" onClick={ev => this.resourceChanged(ev, resource)}>{resource.name}</a></li>
                  );
                })}
              </ul>
            </div> : null
               }
          <br />
          <hr />
          <br />
        </div>
        <div className="visible-print">
          <h3>{headerText}</h3>
          <br />
        </div>
        <Report
          reportsViewState={reportsViewState}
          resourcesById={resourcesById}
          enableVehicleBooking={enableVehicleBooking}
          enableCompanyBooking={enableCompanyBooking}
        />
      </div>
    );
  }

  getDateText() {
    const { period, startDate } = this.state;

    switch (period) {
      case 'month':
        return startDate.clone().format('MMMM YYYY');

      case 'week':
        return `vecka ${startDate.clone().format('WW YYYY')}`;

      case 'day':
        return startDate.clone().format('LL');
    }
  }

  loadReport() {
    const { currentReport, startDate, period, currentResource } = this.state;
    const start = moment(startDate).startOf(period).format('YYYY-MM-DD');
    const end = moment(startDate).endOf(period).format('YYYY-MM-DD');

    switch (currentReport.id) {
      case 'sms':
        this.props.getSmsReport(start, end);
        break;

      case 'bookings':
        if (currentResource) {
          this.props.getBookingReport(currentResource.id, start, end);
        }
        break;
    }
  }
}

Reports.propTypes = {
  reportsViewState: PropTypes.object.isRequired,
  resourcesById: PropTypes.object.isRequired,
  enableReports: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const { reportsViewState, resourcesById, locationFeatures } = state;

  return {
    reportsViewState,
    resourcesById,
    enableReports: locationFeatures.get('EnableReports'),
    enableVehicleBooking: locationFeatures.get('EnableVehicleBooking'),
    enableCompanyBooking: locationFeatures.get('EnableCompanyBooking')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupsAndResources: () => {
      dispatch(fetchGroupsAndResources());
    },
    getSmsReport: (start, end) => {
      dispatch(getSmsReport(start, end));
    },
    getBookingReport: (resourceId, start, end) => {
      dispatch(getBookingReport(resourceId, start, end));
    }
  };
};

const ReportsContainer = injectIntl(connect(
   mapStateToProps,
   mapDispatchToProps
)(Reports));

export default ReportsContainer;
