import React from 'react';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Nav from './nav';
import General from './general';
import WebBooking from './web-booking';
import SmsConfig from './sms-config';
import EmailConfig from './email-config';
import PaymentConfig from './payment-config';
import FeaturesConfig from './features-config';
import StorageConfig from './storage-config';
import ContactsConfig from './contacts-config';
import AccountConfigChaplin from './chaplin';
import { getSectionUrl } from '../../utils/navigate';
import { translateRouteParams } from '../../utils/time-util';
import { msgLinks as msg } from '../../localization/messages/shared/links';

const AccountConfig = (props) => {
  const routeParams = translateRouteParams(props.match.params);
  const { formatMessage: f } = useIntl();

  return (
    <div>
      <Route path="/:org/:loc/:section/:subsection" component={Nav} />
      <div className="container account-config-container">

        <Switch>
          <Route exact path="/:org/:loc/account-config/" render={() => <Redirect to={getSectionUrl(routeParams, 'account-config', 'general')} />} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(general)" component={General} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(web-booking)" component={WebBooking} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(sms)" component={SmsConfig} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(email)" component={EmailConfig} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(payment)" component={PaymentConfig} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(features)" component={FeaturesConfig} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(schedule)" component={AccountConfigChaplin} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(notifications)" component={AccountConfigChaplin} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(users)" component={AccountConfigChaplin} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(storage)" component={StorageConfig} />
          <Route path="/:org/:loc/:section(account-config)/:subsection(contacts)" component={ContactsConfig} />
        </Switch>

        <footer>
          <br /><br />
          <div className="text-center">
            <Link to={getSectionUrl(routeParams, 'account-config', 'contacts')} className="text-muted">{f(msg.lnkTermsConditions)}</Link>{' | '}
            <Link to={getSectionUrl(routeParams, 'account-config', 'contacts')} className="text-muted">{f(msg.lnkPersonalData)}</Link>
          </div>
          <br />
        </footer>
      </div>
    </div>
  );
};

export default AccountConfig;
