
export function formatVehicleRegNo(value) {
  if (!value) {
    return null;
  }
  const normalized = value.toUpperCase().replace(/\s/, '');
  return /^[A-Z]{3}[0-9]{3}$/.test(normalized)
    ? `${normalized.substr(0, 3)} ${normalized.substr(3)}`
    : normalized;
}
