import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import color from 'color';
import moment from 'moment';
import { calendar } from '../../../utils/preference-keys';
import { getScheduleBlockSelector, getGridProps } from './grid-selectors';
import { blockClosed } from './grid-util';

const style = (block, renderClosed) => {
  const bg = renderClosed ? 'rgba(255, 255, 255, 0.59)' : 'white';

  return {
    width: `${block.widthPct}%`,
    left: `${block.leftPosPct}%`,
    top: block.topPx,
    height: block.heightPx,
    pointerEvents: 'none',
    position: 'absolute',
    background: bg,
    zIndex: 3
  };
};

const ScheduleBlock = ({ props, block }) => {
  const { darkenClosedWebBookingDays } = props;
  const { day } = block;
  const dayPast = moment(day).isBefore(moment(), 'day');
  const closed = blockClosed(block);
  const renderClosed = dayPast || (darkenClosedWebBookingDays && closed);
  return (
    <div
      style={style(block, renderClosed)}
      className="block"
    />
  );
};


class ColumnScheduleBlocks extends Component {

  static propTypes = {
    scheduleBlocks: PropTypes.array.isRequired,
    scheduleEditMode: PropTypes.bool.isRequired,
    highContrast: PropTypes.bool.isRequired
  };

  render() {
    const { scheduleBlocks } = this.props;

    return (
      <div>
        {scheduleBlocks.map((block, i) =>
          (
            <ScheduleBlock
              props={this.props}
              block={block}
              id={`block${i}`}
              key={`block${i}`}
            />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { gridViewState, mainViewState, locationConfig } = state;
  const { pixelsPerRow, columnWidth } = getGridProps(state, ownProps);

  try {
    const scheduleBlocks = getScheduleBlockSelector(state, ownProps);
    const darkenClosedWebBookingDays = locationConfig.get(calendar.darkenClosedWebBookingDays);

    return {
      scheduleBlocks,
      scheduleEditMode: gridViewState.get('scheduleEditMode'),
      highContrast: gridViewState.get('highContrast'),
      useWideLabel: mainViewState.get('useWideLabel'),
      rowsPerHour: gridViewState.get('rowsPerHour'),
      pixelsPerRow,
      columnWidth,
      darkenClosedWebBookingDays
    };
  } catch (e) {
    console.log(e);
  }
};


export default connect(
      mapStateToProps
)(ColumnScheduleBlocks);
