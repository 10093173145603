import axios from 'axios';
import { axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl } from '../utils/ajax-util';

export const SET_LOCATION_FEATURE = 'SET_LOCATION_FEATURE';

export function setLocationFeature(name, enabled) {
  const action = enabled ? 'enable-feature' : 'disable-feature';
  const url = prefixUrl(`/features/${action}/${name}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: SET_LOCATION_FEATURE, name, enabled }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
