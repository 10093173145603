import { createSelector } from 'reselect';
/* THIS IS A VERY IMPORTANT PERF OPTIMIZATION - DONT MESS */

export const routeParams = createSelector(
      params => params.org,
      params => params.loc,
      params => params.section,
      params => params.subsection,
      params => params.viewMode,
      params => params.viewDate,
      params => params.entityType,
      params => params.entityId,
      params => params.bookingId,
      (org, loc, section, subsection, viewMode, viewDate, entityType, entityId, bookingId) => {
        return {
          org,
          loc,
          section,
          subsection,
          viewMode,
          viewDate,
          entityType,
          entityId: parseInt(entityId),
          bookingId: parseInt(bookingId)
        };
      }
);

const getConfig = state => state.locationConfig;
const getOptions = state => state.locationOptions;
const trialStatus = state => state.appState.get('trialStatus');

const getServiceMappings = state => state.resourceServiceMappingsByCombinedId;
const getServicesById = state => state.servicesById;

const getLocationProperty = (state, props) => state.locationConfig.get(props.key);

export const getBooking = (state, props) => state.bookingsById.get(props.id);
export const getIsSysAdmin = state => state.authState.get('systemRole') === 'SystemAdmin';

export const getRouteParams = (state, props) => props.routeParams;

export const getCurrentLocation = (state, props) => {
  const orgLocSlug = `${props.routeParams.org}/${props.routeParams.loc}`;
  return state.locationOptions.get(orgLocSlug);
};

export const getFeatures = createSelector(
      state => state.locationFeatures,
      features => features.toJS()
);

const emptyArray = [];
export const getPermissions = createSelector(
      getRouteParams,
      getOptions,
      (routeParams, locationOptions) => {
        const { org, loc } = routeParams;
        return locationOptions.size > 0 ? locationOptions.get(`${org}/${loc}`).permissions : emptyArray;
      }
);

export const getSmsEnabled = createSelector(
  getConfig,
  trialStatus,
  (locationConfig, trialStatus) => {
    return locationConfig.get('smsBillingEnabled') || trialStatus === 'Trial';
  }
);

export const enableDashlFeatures = createSelector(
  getFeatures,
  features => features.EnableDashlFeatures
);

export const getCurrentActiveServices = createSelector(
  getServiceMappings,
  getServicesById,
  (state, props) => props,
  (serviceMappings, services, resourceId) => {
    const servicesIds = Object.keys(services.toJS());
    const mappingIds = servicesIds.map(id => ({ mappingId: `${resourceId}:${id}`, id }));
    const currentActiveServices = mappingIds.filter(id => !!serviceMappings.get(id.mappingId) === true);
    return currentActiveServices.map(s => Number(s.id));
  }
);

export const getStylistType = createSelector(
  getConfig,
  getOptions,
  (locationConfig, locationOptions) => {
    const { orgCtxName: org, ctxName: loc } = locationConfig.get('location').toJS() || {};
    return locationOptions.size > 0 ? locationOptions.get(`${org}/${loc}`).stylistType : 'Freelance';
  }
);

export const getbookingExtendedWithKey = createSelector(
    getBooking,
    getLocationProperty,
    (state, props) => props.key,
    (booking, value, key) => {
      return { ...booking, [key]: value };
    }
);
