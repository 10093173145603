import { defineMessages } from 'react-intl';

export const msgBookingCompany = defineMessages({
  lblOrgName: {
    id: 'booking.BookingCompany.lblOrgName',
    defaultMessage: 'Företag'
  },
  lblOrgNo: {
    id: 'booking.BookingCompany.lblOrgNo',
    defaultMessage: 'Org. nr.'
  }
});

export const msgBookingConfirmation = defineMessages({
  confirmQuestion: {
    id: 'booking.BookingConfirmation.confirmQuestion',
    defaultMessage: 'Vill du skicka bokningsbekräftelse till kund?'
  },
  confirmQuestionExtended: {
    id: 'booking.BookingConfirmation.confirmQuestionExtended',
    defaultMessage: 'Bokningen har ändrats, vill du skicka bokningsbekräftelse till kund?'
  },
  lblSendSmsConfirmation: {
    id: 'booking.BookingConfirmation.lblSendSmsConfirmation',
    defaultMessage: 'Skicka SMS {sendSmsEnabled, select, true {till {phoneNumber}} other {}}'
  },
  lblSendEmailConfirmation: {
    id: 'booking.BookingConfirmation.lblSendEmailConfirmation',
    defaultMessage: 'Skicka mejl {emailExist, select, true {till {email}} other {}}'
  }
});

export const msgBookingCustomer = defineMessages({
  lblContact: {
    id: 'booking.BookingCustomer.lblContact',
    defaultMessage: 'Kontaktperson'
  },
  lblName: {
    id: 'booking.BookingCustomer.lblName',
    defaultMessage: 'Namn'
  },
  lblPhone: {
    id: 'booking.BookingCustomer.lblPhone',
    defaultMessage: 'Mobiltelefon'
  },
  lblEmail: {
    id: 'booking.BookingCustomer.lblEmail',
    defaultMessage: 'E-post'
  },
  lblOtherPhone: {
    id: 'booking.BookingCustomer.lblOtherPhone',
    defaultMessage: 'Annan telefon'
  },
  lblClient: {
    id: 'booking.BookingCustomer.lblClient',
    defaultMessage: 'Kund'
  },
  placeholderClient: {
    id: 'booking.BookingCustomer.placeholderClient',
    defaultMessage: 'Sök eller lägg till kund'
  }
});

export const msgBookingDetails = defineMessages({
  reservationNumber: {
    id: 'booking.BookingDetails.reservationNumber',
    defaultMessage: 'Bokningsnr. {id}'
  },
  bookedAs: {
    id: 'booking.BookingDetails.bookedAs',
    defaultMessage: 'Bokad som {bookedAs}'
  },
  bookingAction: {
    id: 'booking.BookingDetails.bookingAction',
    defaultMessage: '{action, select, create {Skapad} other {Avbokad}} {dateTime} {channel, select, App {i appen} Web {på dashl.se} WebDashl {på dashl.se} WebDashlPopup {i kiosk} other {i Dashl}}'
  },
  updated: {
    id: 'booking.BookingDetails.updated',
    defaultMessage: 'Uppdaterad {lastUpdateTime}'
  },
  paid: {
    id: 'booking.BookingDetails.paid',
    defaultMessage: 'Betald {paidTs}'
  },
  refunded: {
    id: 'booking.BookingDetails.refunded',
    defaultMessage: 'Återbetald {refundTs}'
  },
  smsStatus: {
    id: 'booking.BookingDetails.smsStatus',
    defaultMessage: '{smsStatus, select, SENT_PENDING {SMS skickat {smsStatusUpdateTime}} DELIVERED {SMS levererat {smsStatusUpdateTime}} other {}}'
  }
});

export const msgBookingFlags = defineMessages({
  show: {
    id: 'booking.BookingFlags.show',
    defaultMessage: 'Show'
  },
  noShow: {
    id: 'booking.BookingFlags.noShow',
    defaultMessage: 'No Show'
  },
  bookedPerson: {
    id: 'booking.BookingFlags.bookedPerson',
    defaultMessage: 'Bokat person'
  },
  dropIn: {
    id: 'booking.BookingFlags.dropIn',
    defaultMessage: 'Drop in'
  }
});

export const msgBookingForm = defineMessages({
  titleNewBooking: {
    id: 'booking.BookingForm.titleNewBooking',
    defaultMessage: 'Ny bokning'
  },
  titleChangeBooking: {
    id: 'booking.BookingForm. titleChangeBooking',
    defaultMessage: 'Ändra bokning'
  },
  bookingTime: {
    id: 'booking.BookingForm.bookingTime',
    defaultMessage: 'Blockera tid...'
  },
  createBooking: {
    id: 'booking.BookingForm.createBooking',
    defaultMessage: 'Skapa bokning...'
  },
  btnAlternative: {
    id: 'booking.BookingForm.btnAlternative',
    defaultMessage: 'Alternativ...'
  },
  btnDelete: {
    id: 'booking.BookingForm.btnDelete',
    defaultMessage: 'Radera...'
  }
});

export const msgBookingInfo = defineMessages({
  minutes: {
    id: 'booking.BookingInfo.minutes',
    defaultMessage: 'min'
  },
  heading: {
    id: 'booking.BookingInfo.heading',
    defaultMessage: 'Kl {startTime} - {endTime} {hasAfterTime, select, true {<small>+ {afterTime} min</small>} other {}}'
  },
  lblLength: {
    id: 'booking.BookingInfo.lblLength',
    defaultMessage: 'Längd'
  },
  lblPauseAfter: {
    id: 'booking.BookingInfo.lblPauseAfter',
    defaultMessage: 'Paus efter'
  },
  btnHideDetails: {
    id: 'booking.BookingInfo.btnHideDetails',
    defaultMessage: 'Göm detaljer'
  },
  btnShowDetails: {
    id: 'booking.BookingInfo.btnShowDetails',
    defaultMessage: 'Visa detaljer'
  }
});

export const msgBookingNotes = defineMessages({
  note: {
    id: 'booking.BookingNotes.note',
    defaultMessage: 'Anteckning'
  },
  addNote: {
    id: 'booking.BookingNotes.addNote',
    defaultMessage: 'Lägg till anteckning...'
  }
});

export const msgBookingOptions = defineMessages({
  isPastBooking: {
    id: 'booking.BookingOptions.isPastBooking',
    defaultMessage: 'Observera att tiden för bokningen är passerad.'
  },
  customerDeleted: {
    id: 'booking.BookingOptions.customerDeleted',
    defaultMessage: 'Kunden är borttagen.'
  },
  btnSendConfirmation: {
    id: 'booking.BookingOptions.btnSendConfirmation',
    defaultMessage: 'Skicka bekräftelse...'
  },
  btnSendReceipt: {
    id: 'booking.BookingOptions.btnSendReceipt',
    defaultMessage: 'Skicka kvitto...'
  },
  btnMarkAsPaid: {
    id: 'booking.BookingOptions.btnSMarkAsPaid',
    defaultMessage: 'Markera som betald'
  },
  btnPrint: {
    id: 'booking.BookingOptions.btnPrint',
    defaultMessage: 'Skriv ut...'
  },
  btnCopy: {
    id: 'booking.BookingOptions.btnCopy',
    defaultMessage: 'Kopiera'
  },
  btnMove: {
    id: 'booking.BookingOptions.btnMove',
    defaultMessage: 'Flytta'
  },
  btnCancel: {
    id: 'booking.BookingOptions.btnCancel',
    defaultMessage: 'Boka av'
  },
  btnDelete: {
    id: 'booking.BookingOptions.btnDelete',
    defaultMessage: 'Radera'
  },
  callTo: {
    id: 'booking.BookingOptions.callTo',
    defaultMessage: 'Ring {phoneNumber, number}'
  },
  alternative: {
    id: 'booking.BookingOptions.alternative',
    defaultMessage: 'Alternativ'
  }
});

export const msgBookingPlace = defineMessages({
  lblAddress: {
    id: 'booking.BookingPlace.lblAddress',
    defaultMessage: 'Adress'
  },
  lblPostCode: {
    id: 'booking.BookingPlace.lblZipCode',
    defaultMessage: 'Postnr'
  },
  lblArea: {
    id: 'booking.BookingPlace.lblArea',
    defaultMessage: 'Ort'
  },
  btnMakeHomeVisit: {
    id: 'booking.BookingPlace.btnMakeHomeVisit',
    defaultMessage: 'Gör hembesök...'
  },
  homeVisit: {
    id: 'booking.BookingPlace.homeVisit',
    defaultMessage: 'Hembesök'
  },
  addressMissing: {
    id: 'booking.BookingPlace.addressMissing',
    defaultMessage: 'Adress saknas'
  }
});

export const msgBookingPrice = defineMessages({
  regularPrice: {
    id: 'booking.BookingPrice.regularPrice',
    defaultMessage: 'Ordinarie pris: {price}'
  },
  discount: {
    id: 'booking.BookingPrice.discount',
    defaultMessage: 'Rabatt: {voucherValue} ({voucherCode})'
  },
  creditsUsed: {
    id: 'booking.BookingPrice.creditsUsed',
    defaultMessage: 'Tillgodobelopp: {value}'
  },
  subscriptionUsed: {
    id: 'booking.BookingPrice.subscriptionUsed',
    defaultMessage: 'Kunden har en prenumeration'
  },
  lblPrice: {
    id: 'booking.BookingPrice.lblPrice',
    defaultMessage: 'Pris'
  },
  btnAddPrice: {
    id: 'booking.BookingPrice.btnAddPrice',
    defaultMessage: 'Lägg till pris...'
  }
});

export const msgBookingPrintModal = defineMessages({
  bookingAt: {
    id: 'booking.BookingPrintModal.bookingAt',
    defaultMessage: 'Bokning hos {companyName}'
  },
  service: {
    id: 'booking.BookingPrintModal.service',
    defaultMessage: 'Tjänst: {serviceName}'
  },
  resource: {
    id: 'booking.BookingPrintModal.resource',
    defaultMessage: 'Resurs: {resourceName}'
  },
  customerDetails: {
    id: 'booking.BookingPrintModal.customerDetails',
    defaultMessage: 'Kunduppgifter'
  },
  name: {
    id: 'booking.BookingPrintModal.name',
    defaultMessage: 'Namn: {name}'
  },
  phoneNumber: {
    id: 'booking.BookingPrintModal.phoneNumber',
    defaultMessage: 'Telefon: {phoneNumber}'
  },
  email: {
    id: 'booking.BookingPrintModal.email',
    defaultMessage: 'E-post: {email}'
  },
  orgName: {
    id: 'booking.BookingPrintModal.orgName',
    defaultMessage: 'Företag: {orgName}'
  },
  note: {
    id: 'booking.BookingPrintModal.note',
    defaultMessage: 'Anteckning'
  }
});

export const msgBookingService = defineMessages({
  lblDescription: {
    id: 'booking.BookingService.lblDescription',
    defaultMessage: 'Beskrivning'
  },
  btnAddService: {
    id: 'booking.BookingService.btnAddService',
    defaultMessage: 'Lägg till tjänst...'
  },
  duration: {
    id: 'booking.BookingService.duration',
    defaultMessage: '{duration} min'
  },
  price: {
    id: 'booking.BookingService.price',
    defaultMessage: '{priceFrom, select, true {Från } other {}}{price}'
  }
});

export const msgBookingVehicle = defineMessages({
  lblRegNo: {
    id: 'booking.BookingVehicle.lblRegNo',
    defaultMessage: 'Reg.nr'
  },
  lblDescription: {
    id: 'booking.BookingVehicle.lblDescription',
    defaultMessage: 'Beskrivning'
  },
  searchInformation: {
    id: 'booking.BookingVehicle.searchInformation',
    defaultMessage: 'Sök fordonsuppgifter'
  },
  searchNotFound: {
    id: 'booking.BookingVehicle.searchNotFound',
    defaultMessage: 'Hittade inget fordon'
  },
  addVehicle: {
    id: 'booking.BookingVehicle.addVehicle',
    defaultMessage: 'Lägg till fordon...'
  }
});

export const msgConfirmCancel = defineMessages({
  question: {
    id: 'booking.ConfirmCancel.question',
    defaultMessage: 'Är du säker på att du vill boka av?'
  },
  sendConfirmationTo: {
    id: 'booking.ConfirmCancel.sendConfirmationTo',
    defaultMessage: 'Skicka bekräftelse till:'
  },
  deleteBooking: {
    id: 'booking.ConfirmCancel.deleteBooking',
    defaultMessage: 'Radera bokningen'
  },
  refundPayment: {
    id: 'booking.ConfirmCancel.refundPayment',
    defaultMessage: 'Återbetala/makulera betalning'
  },
  btnSubmit: {
    id: 'booking.ConfirmCancel.btnSubmit',
    defaultMessage: 'Boka av'
  },
  btnSubmitting: {
    id: 'booking.ConfirmCancel.btnSubmitting',
    defaultMessage: 'Bokar av...'
  }
});

export const msgConfirmDelete = defineMessages({
  question: {
    id: 'booking.ConfirmDelete.question',
    defaultMessage: 'Är du säker på att du vill radera bokningen?'
  },
  btnSubmit: {
    id: 'booking.ConfirmDelete.btnSubmit',
    defaultMessage: 'Radera'
  },
  btnSubmitting: {
    id: 'booking.ConfirmDelete.btnSubmitting',
    defaultMessage: 'Raderar...'
  }
});

export const msgMarkAsPaid = defineMessages({
  question: {
    id: 'booking.MarkAsPaid.question',
    defaultMessage: 'Vill du markera bokningen som betald?'
  },
  btnSubmit: {
    id: 'booking.MarkAsPaid.btnSubmit',
    defaultMessage: 'Bekräfta'
  },
  btnSubmitting: {
    id: 'booking.MarkAsPaid.btnSubmitting',
    defaultMessage: 'Bekräftar...'
  }
});

export const msgSearchCustomer = defineMessages({
  customer: {
    id: 'booking.SearchCustomer.customer',
    defaultMessage: '{customer, select, Vehicle {fordon} Company {företag} other {kund}}'
  },
  latestBooking: {
    id: 'booking.SearchCustomer.latestBooking',
    defaultMessage: 'Senaste bokning {latestBookingTs}'
  },
  registryInfo: {
    id: 'booking.SearchCustomer.registryInfo',
    defaultMessage: 'Uppgifterna hämtas från bilregistret'
  },
  addNew: {
    id: 'booking.SearchCustomer.addNew',
    defaultMessage: 'Lägg till {scope}'
  },
  searchOrAdd: {
    id: 'booking.SearchCustomer.searchOrAdd',
    defaultMessage: 'Sök eller lägg till {scope}'
  },
  select: {
    id: 'booking.SearchCustomer.select',
    defaultMessage: 'Välj {scope}'
  }
});

export const msgSearchService = defineMessages({
  duration: {
    id: 'booking.SearchService.duration',
    defaultMessage: '{isAfterTimePositive, select, true {{duration} min + {afterTime} min paus} other {{duration} min}}'
  },
  price: {
    id: 'booking.BookingService.price',
    defaultMessage: '{priceFrom, select, true {Från } other {}}{price}'
  },
  addService: {
    id: 'booking.BookingService.addService',
    defaultMessage: 'Lägg till tjänst'
  },
  searchOrAdd: {
    id: 'booking.BookingService.searchOrAdd',
    defaultMessage: 'Sök eller lägg till ny tjänst'
  },
  selectMultiple: {
    id: 'booking.BookingService.selectMultiple',
    defaultMessage: 'Välj flera tjänster'
  }
});

export const msgSendConfirmation = defineMessages({
  heading: {
    id: 'booking.SendConfirmation.heading',
    defaultMessage: 'Skicka bokningsbekräftelse till:'
  },
  sendError: {
    id: 'booking.SendConfirmation.sendError',
    defaultMessage: 'Kan inte skicka bekräftelse, telefonnummer och mejladress saknas.'
  }
});

export const msgSendReceipt = defineMessages({
  heading: {
    id: 'booking.SendReceipt.heading',
    defaultMessage: 'Skicka kvitto till:'
  },
  name: {
    id: 'booking.SendReceipt.name',
    defaultMessage: 'Namn'
  },
  email: {
    id: 'bbooking.SendReceipt.email',
    defaultMessage: 'E-post'
  }
});
