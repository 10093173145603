import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ContactForm from './contact-form';

import { msgContact as msg } from '../../../localization/messages/components/account-config';
import { msgButtons } from '../../../localization/messages/shared/buttons';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false
    };
  }

  showForm = (ev) => {
    ev.preventDefault();
    this.setState({ showForm: true });
  };

  hideForm = () => {
    this.setState({ showForm: false });
  };

  onSubmit = (data) => {
    return this.props.onSubmit(this.props.contactType, data)
      .then(() => this.hideForm());
  };

  render() {
    const { showForm } = this.state;
    const { contact, contactType, title, description, isOptional, intl: { formatMessage: f } } = this.props;
    const contactInfo = contact && contact.toJS();
    const formName = `contact-info-${contactType.toLowerCase()}`;

    const initialValues = {
      useContactInfo: contactInfo ? 'true' : 'false',
      ...contactInfo
    };

    return (
      <div>
        <h4>{title}</h4>
        <p className="text-muted" style={{ marginBottom: 20 }}>{description}</p>
        {showForm && (
          <ContactForm form={formName} initialValues={initialValues} onSubmit={this.onSubmit} onCancel={this.hideForm} isOptional={isOptional} />
        )}
        {contact && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={{ width: '33%' }}>{f(msg.name)}</td>
                <td style={{ width: '66%' }}><strong>{contactInfo.name}</strong></td>
              </tr>
              <tr>
                <td>{f(msg.email)}</td>
                <td><strong>{contactInfo.email}</strong></td>
              </tr>
              <tr>
                <td>{f(msg.phone)}</td>
                <td><strong>{contactInfo.phone}</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>{f(msgButtons.btnChangeData)}</a></td>
              </tr>
            </tbody>
          </table>
        )}
        {!contact && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={{ width: '33%' }}>{f(msg.name)}</td>
                <td style={{ width: '66%' }}><strong>{f(msg.nameSameContact)}</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>{f(msgButtons.btnChangeData)}</a></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default injectIntl(Contact);
