import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import ConfirmDeleteDialog from './confirm-delete-dialog';

import { msgList as msg } from '../../localization/messages/components/campaign';

export default class List extends Component {

  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      showDeleteDialog: false,
      deleteCampaignId: null
    };
  }

  render() {
    const { showDeleteDialog } = this.state;
    const { campaignViewState, showSend } = this.props;
    const campaigns = campaignViewState.get('campaigns');

    return (
      <div>
        {campaigns && !campaigns.isEmpty() ?
          <div className="pull-right" style={{ marginTop: -10 }}>
            <a href="#" onClick={showSend} className="btn btn-lg btn-success"><FormattedMessage {...msg.createMessage} /></a>
          </div> : null}
        <h3><FormattedMessage {...msg.heading} /></h3>
        <br /><br />
        {campaigns && !campaigns.isEmpty() ? this.renderCampaigns(campaigns) : this.renderNoCampaigns(campaigns)}
        {showDeleteDialog ? <ConfirmDeleteDialog onClose={this.hideConfirmDelete} onConfirm={this.deleteCampaign} /> : null}
      </div>
    );
  }

  renderCampaigns(campaigns) {
    return (
      <div>
        {
               campaigns.map((campaign) => {
                 const { id, name, deliveryTime, smsText, sender, recipientCount, status } = campaign.toJS();
                 const date = moment(deliveryTime).format('LLLL');
                 const isSent = status == 'DONE';

                 return (
                   <div className="panel panel-default" key={id}>
                     <div className="panel-heading">
                       {!isSent && (
                       <a className="btn btn-danger pull-right" href="#" onClick={ev => this.showConfirmDelete(ev, id)}>
                         <FormattedMessage {...msg.cancelAndDelete} values={{ ampersand: <Fragment>&apos;</Fragment> }} />
                       </a>
                        )}
                       {name && <h4 style={{ marginTop: 0, marginBottom: 7 }}>{name}</h4>}
                       <p style={{ marginBottom: 3 }}>
                         <strong>
                           <FormattedMessage {...msg.campaignsExplanation} values={{ sender, recipientCount }} />
                         </strong>
                       </p>
                       {isSent ?
                         <em className="text-success"><span className="glyphicon glyphicon-ok-circle" /> <FormattedMessage {...msg.isSent} values={{ date }} /></em> :
                         <em className="text-primary"><span className="glyphicon glyphicon-play-circle" /> <FormattedMessage {...msg.notSent} values={{ date }} /></em>
                           }
                     </div>
                     <div className="panel-body text-wrap">
                       {smsText}
                     </div>
                   </div>
                 );
               })
            }
      </div>
    );
  }

  renderNoCampaigns(campaigns) {
    return campaigns ?
      <div className="well text-center">
        <p className="lead"><FormattedMessage {...msg.noCampaigns} /></p><br />
        <a href="#" className="btn btn-lg btn-success" onClick={this.props.showSend}><FormattedMessage {...msg.createMessage} /></a>
      </div> :
         null;
  }

  showConfirmDelete = (ev, id) => {
    ev.preventDefault();
    this.setState({ showDeleteDialog: true, deleteCampaignId: id });
  };

  hideConfirmDelete = () => {
    this.setState({ showDeleteDialog: false, deleteCampaignId: null });
  };

  deleteCampaign = () => {
    this.props.deleteCampaign(this.state.deleteCampaignId);
    this.setState({ showDeleteDialog: false, deleteCampaignId: null });
  };
}

List.propTypes = {
  campaignViewState: PropTypes.object.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  showSend: PropTypes.func.isRequired
};
