import React from 'react';
import { useIntl } from 'react-intl';

import { msgMarkAsPaid as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

const MarkAsPaid = ({ onCancel, onConfirm, progress }) => {
  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{f(msg.question)}</strong>
      </p>
      <br />
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{f(msgButtons.btnCancel)}</button>{' '}
        <button type="submit" className="btn-save" tabIndex="3">{progress ? f(msg.btnSubmitting) : f(msg.btnSubmit)}</button>
      </div>
    </form>
  );
};

export default MarkAsPaid;
