import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Chip from './chip';
import { createPositionedChips } from './grid-util';

class ChipColumn extends Component {

  static propTypes = {

    routeParams: PropTypes.object.isRequired,
    bookings: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    columnIndex: PropTypes.number.isRequired,
    gridProps: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.routeParams !== nextProps.routeParams) {
      return true;
    }
    if (this.props.columnIndex !== nextProps.columnIndex) {
      return true;
    }
    if (this.props.gridProps !== nextProps.gridProps) {
      return true;
    }
    if (this.props.column !== nextProps.column) {
      return true;
    }
    if (!this.props.bookings.equals(nextProps.bookings)) {
      return true;
    }
    return false;
  }

  render() {
    const { routeParams, bookings, columnIndex, gridProps } = this.props;

    const chips = bookings.valueSeq().toJS();
    const chipsWithCoordinates = createPositionedChips(chips, columnIndex, gridProps, routeParams.viewMode);

    return (
      <div>
        {chipsWithCoordinates.map((chip, i) => {
          return (<Chip
            {...chip}
            key={chip.id}
            gridProps={gridProps}
            ref={chip.refCallback !== undefined ? chip.refCallback : null}
            routeParams={routeParams}
            colIdx={columnIndex}
          />);
        })}
      </div>
    );
  }
}


const getBookingsById = state => state.bookingsById;
const getResId = (state, props) => props.column.resId;
const getMomentDate = (state, props) => props.column.date;

const makeGetFilteredBookings = () => {
  return createSelector(
         getBookingsById,
         getResId,
         getMomentDate,
         (bookingsById, resId, momentDate) => {
           // console.warn("const makeGetFilteredBookings ", resId, momentDate, bookingsById);
           const bookings = bookingsById.filter((booking) => {
             if (booking.resourceId !== resId) {
               return false;
             }
             return booking.startTime.isSame(momentDate, 'day');
           });
           return bookings;
         }
   );
};

const makeMapStateToProps = () => {
  const getFilteredBookings = makeGetFilteredBookings();

  const mapStateToProps = (state, ownProps) => {
    return {
      bookings: getFilteredBookings(state, ownProps)
    };
  };
  return mapStateToProps;
};

export default connect(
      makeMapStateToProps
)(ChipColumn);
