import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmailPreferences, saveEmailPreferences } from '../../../state/preferences-actions';
import { getLongestName, templatePreviewValues } from '../../../utils/templates';
import EmailConfigForm from './email-config-form';

class EmailConfig extends Component {

  componentWillMount() {
    if (!this.props.preferences) {
      this.props.getEmailConfig();
    }
  }

  render() {
    const initialValues = this.props.preferences ? this.props.preferences.toJS() : null;
    const features = this.props.locationFeatures.toJS();

    return (
      <EmailConfigForm
        initialValues={initialValues}
        previewValues={this.props.previewValues}
        onSubmit={this.submitForm}
        features={features}
      />
    );
  }

  submitForm = (values) => {
    return this.props.saveEmailConfig(values);
  };
}

EmailConfig.propTypes = {
  preferences: PropTypes.object
};

const mapStateToProps = (state) => {
  const { preferencesViewState, locationFeatures, locationConfig, resourcesById, servicesById } = state;

  const resourceName = getLongestName(resourcesById);
  const serviceName = getLongestName(servicesById);
  const companyName = locationConfig.get('companyName');
  const phoneNumber = locationConfig.get('contactNumber');
  const previewValues = templatePreviewValues({ resourceName, serviceName, companyName, phoneNumber });

  return {
    preferences: preferencesViewState.get('emailPreferences'),
    locationFeatures,
    previewValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmailConfig: () => {
      dispatch(getEmailPreferences());
    },
    saveEmailConfig: (data) => {
      return dispatch(saveEmailPreferences(data));
    }
  };
};

const EmailConfigContainer = connect(
   mapStateToProps,
   mapDispatchToProps
)(EmailConfig);

export default EmailConfigContainer;
