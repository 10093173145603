import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import RichTextEditorField from '../../inputs/rich-text-editor';
import WebBookingStrategy from '../../inputs/web-booking-strategy';
import SubmitButton from '../../common/submit-button';
import CurrencyUtil from '../../../../utils/currency-util';
import { msgWebBookingForm as msg } from '../../../localization/messages/components/account-config';

class WebBookingForm extends Component {

  componentWillReceiveProps(nextProps) {
    if (!this.props.requireNotes && nextProps.requireNotes) {
      this.props.setFormValue('hideNotes', false);
    }
  }

  render() {
    const { handleSubmit, smsPinPrice, features, requireNotes, allowCancellation, intl: { formatMessage: f } } = this.props;

    return (
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <br />

        <div className="form-group" style={{ marginBottom: 0 }}>
          <label className="col-sm-4 control-label">{f(msg.groupAlgorithm)}</label>
          <div className="col-sm-8">
            <WebBookingStrategy propertyName="slotFinderStrategy" {...this.props} />
          </div>
        </div>

        <hr />

        <div className="form-group">
          <label className="col-sm-4 control-label">{f(msg.lblMinTimeBeforeBooking)}</label>
          <div className="col-sm-4">
            <Field name="minTimeBeforeBooking" component="input" type="number" className="form-control" />
          </div>
          <p className="col-sm-4 form-control-static">{f(msg.minutesBefore)}</p>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{f(msg.lblBookingMaxDaysInAdvance)}</label>
          <div className="col-sm-4">
            <Field name="bookingMaxDaysInAdvance" component="input" type="number" className="form-control" />
          </div>
          <p className="col-sm-4 form-control-static">{f(msg.daysBefore)}</p>
        </div>

        {features.ShowBookingRules && (
          <div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <div className="checkbox">
                  <label>
                    <Field name="allowCancellation" component="input" type="checkbox" />
                    {f(msglblAllowCancellation)}
                  </label>
                </div>
              </div>
            </div>

            {allowCancellation && (
              <div className="form-group">
                <label className="col-sm-4 control-label">{f(msg.lblCancelUpToHours)}</label>
                <div className="col-sm-4">
                  <Field name="cancelUpToHours" component="input" type="number" className="form-control" />
                </div>
                <p className="col-sm-4 form-control-static">t{f(msg.hoursBefore)}</p>
              </div>
            )}

            <hr />

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.lblResourceLabel)}</label>
              <div className="col-sm-4">
                <Field name="resourceLabel" component="input" type="text" className="form-control" />
              </div>

              <div className="col-sm-offset-4 col-sm-8" style={{ marginBottom: 10, marginTop: 10 }}>
                <span className="text-muted">
                  {f(msg.descriptionResourceLabel)}
                </span>
              </div>
            </div>


            <hr />

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.groupFeatures)}</label>
              <div className="col-sm-8">
                {features.EnableSmsPin && !features.EnableStripePayments && (
                  <div className="checkbox">
                    <label>
                      <Field name="useSmsVerification" component="input" type="checkbox" />
                      {f(msg.lblUseSmsVerification, { smsPinPrice: CurrencyUtil.accountCurrency(smsPinPrice) })}
                    </label>
                  </div>
                )}
                <div className="checkbox">
                  <label>
                    <Field name="allowMultipleServices" component="input" type="checkbox" />
                    {f(msg.lblAllowMultipleServices)}
                  </label>
                </div>
                <br />
                <div className="checkbox">
                  <label>
                    <Field name="autoResourceSelection" component="input" type="checkbox" />
                    {f(msg.lblAutoResourceSelection)}
                  </label>
                  <div className="checkbox-description">
                    {f(msg.descriptionAutoResourceSelection)}
                  </div>
                </div>
                <div className="checkbox">
                  <label>
                    <Field name="alwaysShowDescription" component="input" type="checkbox" />
                    {f(msg.lblAlwaysShowDescription)}
                  </label>
                  <div className="checkbox-description">
                    {f(msg.descriptionAlwaysShowDescription)}
                  </div>
                </div>
                <div className="checkbox">
                  <label>
                    <Field name="collapseGroups" component="input" type="checkbox" />
                    {f(msg.lblCollapseGroups)}
                  </label>
                  <div className="checkbox-description">
                    {f(msg.descriptionCollapseGroups)}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.groupCustomerDetails)}</label>
              <div className="col-sm-8">
                <div className="checkbox">
                  <label>
                    <Field name="requirePno" component="input" type="checkbox" />
                    {f(msg.lblRequirePno)}
                  </label>
                </div>
                <div className="checkbox">
                  <label>
                    <Field name="requireEmail" component="input" type="checkbox" />
                    {f(msg.lblRequireEmail)}
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.groupMessage)}</label>
              <div className="col-sm-8">
                <div className={requireNotes ? 'checkbox disabled' : 'checkbox'}>
                  <label>
                    <Field name="hideNotes" component="input" type="checkbox" />
                    {f(msg.lblHideNotes)}
                  </label>
                </div>
                <div className="checkbox">
                  <label>
                    <Field name="requireNotes" component="input" type="checkbox" />
                    {f(msg.lblRequireNotes)}
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.lblCustomMessageLabel)}</label>
              <div className="col-sm-4">
                <Field name="customMessageLabel" component="input" type="text" className="form-control" />
              </div>
              <div className="col-sm-offset-4 col-sm-8" style={{ marginBottom: 10, marginTop: 10 }}>
                <span className="text-muted">
                  {f(msg.descriptionCustomMessageLabel)}
                </span>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.lblMessageExtraInfo)}</label>
              <div className="col-sm-8">
                <Field name="messageExtraInfo" component={this.textArea} />
              </div>

              <div className="col-sm-offset-4 col-sm-8" style={{ marginBottom: 10, marginTop: 10 }}>
                <span className="text-muted">
                  {f(msg.descriptionMessageExtraInfo)}
                </span>
              </div>
            </div>

            <hr />
            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.lblBookingExtraInfo)}</label>
              <div className="col-sm-8">
                <Field name="bookingExtraInfo" component={RichTextEditorField} />
              </div>

              <div className="col-sm-offset-4 col-sm-8" style={{ marginBottom: 10, marginTop: 10 }}>
                <span className="text-muted">
                  {f(msg.descriptionBookingExtraInfo)}
                </span><br /><br />
                <div className="checkbox">
                  <label>
                    <Field name="showTCBox" component="input" type="checkbox" />
                    {f(msg.lblShowTCBox)}
                  </label>
                  <div className="checkbox-description">
                    {f(msg.descriptionShowTCBox)}
                  </div>
                </div>

                <div className="checkbox">
                  <label>
                    <Field name="showExtraInfoOnConfirmPage" component="input" type="checkbox" />
                    {f(msg.lblShowExtraInfoOnConfirmPage)}
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}

        {features.customerMatchingCriteria && (
          <div>
            <div className="form-group">
              <label className="col-sm-4 control-label">{f(msg.groupLinkToExisting)}</label>
              <div className="col-sm-8">
                <div className="radio">
                  <label>
                    <Field name="customerMatchingCriteria" component="input" type="radio" value="PhoneNumber" />
                    {f(msg.lblMatchingPhoneNumber)}
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <Field name="customerMatchingCriteria" component="input" type="radio" value="PhoneNumberAndName" />
                    {f(msg.lblMatchingPhoneNumberAndName)}
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}

        <div className="form-group">
          <div className="col-sm-offset-4 col-sm-8">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={2} className="form-control" />
  );
}

const selector = formValueSelector('web-booking-preferences');

const mapStateToProps = (state) => {
  return {
    slotFinderStrategy: selector(state, 'slotFinderStrategy'),
    requireNotes: selector(state, 'requireNotes'),
    allowCancellation: selector(state, 'allowCancellation')
  };
};

const mapDispatchToProps = dispatch => ({
  setFormValue: (property, value) => {
    dispatch(change('web-booking-preferences', property, value));
  }
});

export default reduxForm({
  form: 'web-booking-preferences'
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(WebBookingForm)));
