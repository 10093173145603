import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editSchedule } from '../../state/schedule-actions';
import { dragSelected } from '../../state/view-actions';
import { hasPermission, VIEW_ALL_BOOKINGS } from '../../utils/permissions';
import { getPermissions } from '../../utils/selectors';

import { GridMarkerDragHandler } from './grid-marker-draghandler';
import { getGridProps } from './grid-selectors';

class GridMarker extends Component {

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    try {
      return this.isCurrentViewBookable() && (
        <div id="gridMarker" style={this.style()} className={this.className()}>
          {this.formattedTime()}
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }

  formattedTime() {
    return (<span>
      {this.state.startTime.format('HH:mm')}
      {` - ${this.state.endTime.format('HH:mm')}`}
    </span>);
  }

  className() {
    return this.props.scheduleEditMode ? 'schedule' : `chip ${this.state.type}`;
  }

  style() {
    return {
      overflow: 'hidden',
      display: 'initial',
      visibility: this.state.visible ? 'visible' : 'hidden',
      position: 'absolute',
      top: this.state.coords.top,
      left: `${this.state.coords.leftPct}%`,
      width: `${this.state.coords.widthPct}%`,
      height: this.state.coords.height,
      zIndex: this.state.coords.zIndex
    };
  }

  makeInitialState(props) {
    return {
      coords: {
        top: 0,
        leftPct: 0,
        widthPct: 0,
        height: 0,
        zIndex: 1000
      },
      visible: props.show,
      startTime: moment(),
      endTime: moment(),
      dragChipTopDiff: 0,
      resizeChipOriginalHeight: 0,
      type: 'SimpleBooking'
    };
  }

  undraggable() {
    if (this.gridMarkerDragHandler) {
      this.gridMarkerDragHandler.dispose();
      this.gridMarkerDragHandler = undefined;
    }
  }

  draggable() {
    if (!this.gridMarkerDragHandler) {
      this.gridMarkerDragHandler = new GridMarkerDragHandler(this);
    }
  }

  componentDidMount() {
    this.draggable();
  }

  componentWillUnmount() {
    this.undraggable();
  }

  componentWillReceiveProps(nextProps) {
    this.gridMarkerDragHandler.componentWillReceiveProps(nextProps);

    this.setState({
      visible: nextProps.show
    });
  }

  isCurrentViewBookable() {
    const { associatedResourceId, permissions, entityId, gridProps } = this.props;

    if (gridProps.columnCount === 0) return false;

    if (entityId > 0 && (entityId === associatedResourceId || hasPermission(permissions, VIEW_ALL_BOOKINGS))) {
      return true;
    }
    return false;
  }
}

GridMarker.propTypes = {
  singleClickSelectMode: PropTypes.oneOf(['row', 'hour']).isRequired,
  gridProps: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
  scheduleEditMode: PropTypes.bool.isRequired,
  associatedResourceId: PropTypes.number,
  permissions: PropTypes.array.isRequired,
  entityId: PropTypes.number.isRequired,
  editSchedule: PropTypes.func.isRequired,
  onDragSelect: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  try {
    const { entityId } = ownProps.routeParams;
    const { gridViewState, locationConfig } = state;
    const gridProps = getGridProps(state, ownProps);
    const scheduleEditMode = gridViewState.get('scheduleEditMode');

    return {
      entityId,
      scheduleEditMode,
      show: gridViewState.get('showGridMarker'),
      duration: gridViewState.get('gridMarkerDuration'),
      gridProps,
      singleClickSelectMode: scheduleEditMode ? 'row' : 'hour',
      associatedResourceId: locationConfig.get('resourceId'),
      permissions: getPermissions(state, ownProps)
    };
  } catch (e) {
    console.error(e);
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const routeParams = ownProps.routeParams;

  return {
    editSchedule: (event) => {
      dispatch(editSchedule(event, routeParams));
    },
    onDragSelect: (event) => {
      dispatch(dragSelected(event, routeParams));
    }
  };
};


export default connect(
      mapStateToProps,
      mapDispatchToProps
)(GridMarker);
