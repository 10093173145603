import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { viewDateFromDate } from '../../utils/time-util';

import { msgLocationSelection as msg } from '../../localization/messages/components/location-selection';

const LocationOption = ({ orgCtxName, locCtxName, locName, orgName, orgLoc, resourceId }) => {
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');


  const url = resourceId == null ? `/${orgCtxName}/${locCtxName}/calendar/day/${today}/group/0` :
         `/${orgCtxName}/${locCtxName}/calendar/week/${thisWeek}/resource/${resourceId}`;

  return (
    <tr>
      <td><Link to={url}>{orgName} / {locName}</Link></td>
    </tr>
  );
};

LocationOption.propTypes = {
  locName: PropTypes.string.isRequired,
  locCtxName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  orgCtxName: PropTypes.string.isRequired,
  locationRole: PropTypes.string.isRequired,
  organisationRole: PropTypes.string.isRequired,
  orgLoc: PropTypes.string.isRequired,
  resourceId: PropTypes.number
};

const Locations = ({ locations }) => {
  return (
    <div className="dialog-container">
      <div className="container">
        <h1 className="text-center"><FormattedMessage {...msg.chooseAccount} /></h1><br />
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <table className="table">
              <tbody>
                {locations.map(loc =>
                  <LocationOption key={loc.orgLoc} {...loc} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Locations;
