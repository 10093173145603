import Immutable from 'immutable';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { checkStatus, fetchErrorHandler, fetchPut, prefixUrl } from '../utils/ajax-util';
import { scheduleBlockToMomentDates } from '../utils/time-util';

import { isTimeInSchedule, resourceIdFromColIdx } from '../components/grid/grid-state-helper';

const moment = extendMoment(Moment);

export const OPEN_SCHEDULE_BLOCK = 'OPEN_SCHEDULE_BLOCK';
export const CLOSE_SCHEDULE_BLOCK = 'CLOSE_SCHEDULE_BLOCK';
export const SET_SCHEDULE_BLOCKS = 'SET_SCHEDULE_BLOCKS';


export function editSchedule(event, routeParams) {
  return (dispatch, getState) => {
    const { orderedGroups, schedulesByResource } = getState();
    const { entityType, entityId } = routeParams;

    const resId = resourceIdFromColIdx(orderedGroups, entityType, entityId, event.colIdx);
    const open = !isTimeInSchedule(schedulesByResource, event.startTime, resId);

    if (open) {
      dispatch(markOpen({
        startTime: event.startTime,
        endTime: event.endTime,
        resourceId: resId
      }));
    } else {
      dispatch(markClosed({
        startTime: event.startTime,
        endTime: event.endTime,
        resourceId: resId
      }));
    }
  };
}


export function markOpen(block) {
  return (dispatch, getState) => {
    const { schedulesByResource } = getState();
    const { resourceId } = block;
    const day = block.startTime.format('YYYY-MM-DD');
    const schedule = schedulesByResource.get(resourceId);
    const scheduleBlocks = addOpenBlock(schedule, block);
    const affectedBlocks = scheduleBlocks.filter((bl) => {
      return bl.day === day;
    });
    const { bookingMaxDaysInAdvance } = schedule;

    dispatch(setScheduleBlocks({
      resourceId,
      bookingMaxDaysInAdvance,
      blocks: scheduleBlocks
    }));

    const payLoad = {
      resourceId,
      blocks: affectedBlocks
    };

    const url = prefixUrl(`/schedules/resource/${resourceId}/exceptions/`);

    return fetch(url, fetchPut(payLoad))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function markClosed(block) {
  return (dispatch, getState) => {
    const { schedulesByResource } = getState();
    const { resourceId } = block;
    const day = block.startTime.format('YYYY-MM-DD');
    const schedule = schedulesByResource.get(resourceId);
    const scheduleBlocks = closeBlock(schedule, block);
    const affectedBlocks = scheduleBlocks.filter((bl) => {
      return bl.day === day;
    });
    const { bookingMaxDaysInAdvance } = schedule;

    dispatch(setScheduleBlocks({
      resourceId,
      bookingMaxDaysInAdvance,
      blocks: scheduleBlocks
    }));

    const blocks = affectedBlocks.length == 0 ? [{ day, closed: true }] : affectedBlocks;
    const payLoad = {
      resourceId,
      blocks
    };

    const url = prefixUrl(`/schedules/resource/${resourceId}/exceptions/`);
    return fetch(url, fetchPut(payLoad))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}


function setScheduleBlocks(schedule) {
  return {
    type: SET_SCHEDULE_BLOCKS,
    ...schedule
  };
}

function closeBlock(schedule, block) {
  const newRange = moment.range(block.startTime, block.endTime),
    ranges = [];

  for (const sb of schedule.blocks) {
    const mb = scheduleBlockToMomentDates(sb);
    const range = moment.range(mb.blStart, mb.blEnd);
    if (newRange.overlaps(range)) {
      ranges.push(...range.subtract(newRange));
    } else {
      ranges.push(range);
    }
  }
  return rangesToBlocks(ranges, schedule);
}

function addOpenBlock(schedule, block) {
  let newRange = moment.range(block.startTime, block.endTime);
  const ranges = [];

  if (schedule != null) {
    for (const sb of schedule.blocks) {
      const mb = scheduleBlockToMomentDates(sb);
      const range = moment.range(mb.blStart, mb.blEnd);

      if (newRange.overlaps(range)) {
        newRange = newRange.add(range);
      } else if (newRange.end.isSame(range.start)) {
        newRange = moment.range(newRange.start, range.end);
      } else if (newRange.start.isSame(range.end)) {
        newRange = moment.range(range.start, newRange.end);
      } else {
        ranges.push(range);
      }
    }
  }
  ranges.push(newRange);

  return rangesToBlocks(ranges, schedule);
}

function rangesToBlocks(ranges, schedule) {
  let newBlocks = Immutable.Set();

  for (const range of ranges) {
    const refDataBlock = schedule.blocks.find(a => moment(a.day).isSame(range.start, 'day'));
    const defaultDataBlock = { vipOpen: false, webOpen: true, closedMax: false };
    const { closedMax, vipOpen, webOpen } = refDataBlock || defaultDataBlock;

    const block = {
      day: range.start.format('YYYY-MM-DD'),
      start: range.start.format('HH:mm'),
      end: range.end.format('HH:mm'),
      vipOpen,
      webOpen,
      closedMax
    };

    newBlocks = newBlocks.add(block);
  }
  return newBlocks.toJS();
}

