var CustomerLogItem = require('../models/customer-log-item');

module.exports = Chaplin.Collection.extend({

   url: function() {
      return '/customers/' + this.customerId + '/logentries';
   },
   model: CustomerLogItem,
   customerId: null,

   fetch: function(customerId){
      this.customerId = customerId;
      Chaplin.Collection.prototype.fetch.call(this, arguments);
   }
});