import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { formatPhoneNumberE164 } from '../../../utils/phone-util';
import { getFeatures, getStylistType, enableDashlFeatures } from '../../utils/selectors';
import {
  bookingMoved, bookingTypeChanged, removeTempBooking,
  sendBookingConfirmation
} from '../../state/booking-actions';
import {
  addBooking, clearAndCloseBKF, deleteBooking, setBKFVehicle,
  setBKFBookingTime, setBKFCompany, setBKFAttributes,
  setBKFCustomer, setBKFService, addBKFService, removeBKFService,
  updateBooking
} from '../../state/bkf-actions';

import BookingFlags from './booking-flags';
import BookingInfo from './booking-info';
import BookingVehicle from './booking-vehicle';
import BookingCompany from './booking-company';
import BookingCustomer from './booking-customer';
import BookingService from './booking-service';
import BookingPrice from './booking-price';
import BookingPlace from './booking-place';
import BookingNotes from './booking-notes';
import BookingOptions from './booking-options';
import BookingConfirmation from './booking-confirmation';
import SearchCustomer from './search-customer';
import SearchService from './search-service';

import { msgBookingForm as msg } from '../../localization/messages/components/booking';
import { msgButtons } from '../../localization/messages/shared/buttons';

class BookingForm extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    resourceId: PropTypes.number.isRequired,
    startTime: PropTypes.object.isRequired,
    endTime: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateBooking: PropTypes.func.isRequired,
    onDeleteBooking: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    const initState = props.id === 'DRAGGER' ? null : {
      id: props.id,
      startTime: props.startTime,
      endTime: props.endTime,
      resourceId: props.resourceId,
      afterTime: props.afterTime,
      customer: props.customer
    };

    this.state = {
      showOptions: false,
      isSaving: false,
      initState,
      searchCustomer: false,
      searchCustomerValue: '',
      searchService: false,
      editNotes: false
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  getMaxHeight() {
    return this.props.gridClientHeight + 43;
  }

  getBodyHeight() {
    return 400;
  }

  render() {
    if (this.state.searchCustomer) {
      return this.renderSearchCustomer();
    }
    if (this.state.searchService) {
      return this.renderSearchService();
    }
    if (this.state.editNotes) {
      return this.renderEditNotes();
    }
    return this.renderViewBooking();
  }

  renderSearchService() {
    return (
      <SearchService
        deviceType={this.props.deviceType}
        deviceOs={this.props.deviceOs}
        externalKeyboard={this.props.externalKeyboard}
        onSelected={this.handleServiceSelect}
        onAdd={this.handleServiceAdd}
        onRemove={this.handleServiceRemove}
        onCancel={() => { this.setState({ searchService: false }); }}
        maxHeight={this.getMaxHeight()}
        height={!this.props.isModal && this.state.bodyHeight}
      />
    );
  }

  renderSearchCustomer() {
    return (
      <SearchCustomer
        deviceType={this.props.deviceType}
        deviceOs={this.props.deviceOs}
        externalKeyboard={this.props.externalKeyboard}
        value={this.state.searchCustomerValue}
        onSelected={this.handleCustomerSelect}
        onCancel={() => { this.setState({ searchCustomer: false }); }}
        maxHeight={this.getMaxHeight()}
        height={!this.props.isModal && this.state.bodyHeight}
        scope={this.state.searchCustomerScope}
        stylistType={this.props.stylistType}
      />
    );
  }

  renderEditNotes() {
    return (
      <BookingNotes
        fullScreen
        onCancel={() => { this.setState({ editNotes: false }); }}
        maxHeight={this.getMaxHeight()}
      />
    );
  }

  renderViewBooking() {
    const { id, attributes, customer, company, vehicle, isModal, enableDashlFeatures,
      enableCompanyBooking, enableVehicleBooking, intl: { formatMessage: f } } = this.props;
    const { showOptions, isSaving } = this.state;

    const isNew = id === 'DRAGGER';
    const isBooking = attributes.type === 'SimpleBooking';
    const saveText = isSaving ? f(msgButtons.btnSaving) : f(msgButtons.btnSave);

    const showCompany = company || (enableCompanyBooking && customer);
    const showVehicle = vehicle || (enableVehicleBooking && (customer || company));
    const showConfirmations = this.shouldSendConfirmations();

    return (
      <div className={isSaving ? 'booking-form disabled' : 'booking-form'}>
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.handleClose}>{f(msgButtons.btnCancel)}</a>
          </div>
          <h4 className="title">{isNew ? f(msg.titleNewBooking) : f(msg.titleChangeBooking)}</h4>
          <div className="save">
            <a href="#" onClick={this.handleSave}>{saveText}</a>
          </div>
        </div>

        <div className="booking-form-body" ref={(ref) => { this.bookingFormBody = ref; }} style={{ maxHeight: this.getMaxHeight() + 48 }}>
          {isNew && (
            <div className={isBooking ? 'pull-right' : 'text-right'}>
              {isBooking ?
                <a href="#" tabIndex={-1} onClick={this.setTypeReservation}>{f(msg.bookingTime)}</a> :
                <a href="#" tabIndex={-1} onClick={this.setTypeBooking}>{f(msg.createBooking)}</a>
              }
            </div>
          )}

          {isBooking && <BookingFlags />}

          <BookingInfo />

          {isBooking && (
            <Fragment>
              {showVehicle && <BookingVehicle onSearchStart={this.handleVehicleSearchStart} onClearVehicle={this.handleClearVehicle} />}
              {showCompany && <BookingCompany onSearchStart={this.handleCompanySearchStart} onClearCompany={this.handleClearCompany} />}
              <BookingCustomer onSearchStart={this.handleCustomerSearchStart} onClearCustomer={this.handleClearCustomer} />
              <BookingService onSearchStart={this.handleServiceSearchStart} onClearService={this.handleClearService} />
              <BookingPrice />
              {enableDashlFeatures && <BookingPlace onChangePlace={this.handleChangePlace} />}
            </Fragment>
          )}

          <BookingNotes isModal={isModal} onEditNotes={this.handleEditNotes} />

          {showConfirmations && <BookingConfirmation />}

          <div className="booking-form-footer">
            <div className="options">
              {!isNew && isBooking && <button type="button" className="btn btn-default" onClick={this.showOptions} tabIndex={12}>{f(msg.btnAlternative)}</button>}
              {!isNew && !isBooking && <button type="button" className="btn btn-danger" onClick={this.showOptions} tabIndex={12}>{f(msg.btnDelete)}</button>}
            </div>
            <div className="buttons">
              <button type="button" className="btn-cancel" onClick={this.handleClose} tabIndex={11}>{f(msgButtons.btnCancel)}</button>{' '}
              <button type="submit" className="btn-save" onClick={this.handleSave} tabIndex={10}>{saveText}</button>
            </div>
          </div>
        </div>
        {showOptions && <BookingOptions onClose={this.hideOptions} />}
      </div>
    );
  }

  showOptions = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showOptions: true });
  };

  hideOptions = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showOptions: false });
  };

  handleDelete = (e) => {
    e.preventDefault();
    this.props.onDeleteBooking(this.props.id);
  };

  setTypeBooking = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.props.onTypeChange(this.props.id, 'SimpleBooking');
  };

  setTypeReservation = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.props.onTypeChange(this.props.id, 'Reservation');
  };

  shouldSendConfirmations() {
    const { initState } = this.state;
    const { id, customer, startTime, resourceId, attributes } = this.props;

    const isNew = id === 'DRAGGER' && customer;
    const isBooking = attributes.type === 'SimpleBooking';
    const isCancelled = attributes.status === 'Cancelled';
    const isPastBooking = isBooking && moment().isAfter(startTime);

    const isCustomerDetailsChanged = this.isCustomerDetailsChanged(initState, customer);
    const isMoved = !!(initState && (!initState.startTime.isSame(startTime) || initState.resourceId !== resourceId)) && customer;

    return !!(isBooking && !isCancelled && !isPastBooking && (isNew || isMoved || isCustomerDetailsChanged));
  }

  isCustomerDetailsChanged(initState, customer) {
    return !!(initState && customer && (!initState.customer ||
      initState.customer.customerId !== customer.customerId ||
      initState.customer.phoneNumber !== customer.phoneNumber ||
      initState.customer.email !== customer.email
    ));
  }

  getCompany() {
    const { company, companyForm, enableCompanyBooking } = this.props;
    const companyId = company && company.companyId;
    const { orgNo, orgName } = companyForm || company || {};

    return enableCompanyBooking ? {
      companyId,
      orgNo: orgNo || '',
      orgName: orgName || ''
    } : null;
  }

  getCustomer() {
    const { customer, customerForm } = this.props;
    const customerId = customer && customer.customerId;
    const { name, phoneNumber, otherPhoneNumber, email } = customerForm || customer || {};

    return {
      customerId,
      customerName: name,
      customerPhoneNumber: formatPhoneNumberE164(phoneNumber),
      customerOtherPhoneNumber: formatPhoneNumberE164(otherPhoneNumber),
      customerEmail: email
    };
  }

  getService() {
    const { price } = this.props.priceForm || this.props.service || {};
    const { name } = this.props.serviceForm || this.props.service || {};
    const { serviceDuration, afterTime } = this.props.timeForm || this.props.service || {};

    const { startTime, endTime } = this.props;
    const totalDuration = endTime.diff(startTime, 'minutes');

    return {
      name,
      afterTime: parseInt(afterTime),
      serviceDuration: parseInt(serviceDuration),
      totalDuration,
      price: parseInt(price)
    };
  }

  getServiceProps(service) {
    const { services } = this.props;
    const mapService = s => ({
      id: s.id,
      name: s.name,
      duration: s.duration || s.serviceDuration,
      price: s.price,
      priceFrom: s.priceFrom
    });

    const hasServices = services && services.size > 0;
    const nameChanged = services && services.size === 1 && services.first().name !== service.name;
    const saveDescription = !hasServices || nameChanged;

    return {
      services: services ? [...services.valueSeq().map(mapService)] : [],
      description: saveDescription ? service.name : undefined,
      price: service.price,
      afterTime: service.afterTime
    };
  }

  getAttributes(isNew) {
    const { attributes, addressForm, enableDashlFeatures } = this.props;
    const { address1, address2, address3, area, postCode } = addressForm || attributes || {};
    const { place } = attributes;

    const dashlAttributes = enableDashlFeatures && isNew ? {
      addDashlBooking: true
    } : {};

    const homeAttributes = place === 'Home' ? {
      place,
      address1,
      address2,
      address3,
      postCode,
      area
    } : {};

    return {
      ...dashlAttributes,
      ...homeAttributes
    };
  }

  getVehicle() {
    const { vehicleForm, vehicle, enableVehicleBooking } = this.props;
    const { vehicleRegNo, vehicleDescription } = vehicleForm || vehicle || {};

    return enableVehicleBooking ? {
      vehicleRegNo,
      vehicleDescription
    } : {};
  }

  getNotes() {
    const { notesForm, attributes } = this.props;
    const { note } = notesForm || attributes || {};
    return note;
  }

  getEndTime(service) {
    const totalDuration = service ? service.serviceDuration + service.afterTime : 0;
    const newEndTime = moment(this.props.startTime).add(totalDuration, 'minutes');

    return totalDuration > 0 ? newEndTime : this.props.endTime;
  }

  getConfirmations() {
    const { sendSmsConfirmation, sendEmailConfirmation } = this.props.confirmationForm || {};

    return this.shouldSendConfirmations() ? {
      sms: sendSmsConfirmation,
      email: sendEmailConfirmation
    } : {};
  }

  updateBooking() {
    this.setState({ isSaving: true });

    const vehicle = this.getVehicle();
    const company = this.getCompany();
    const customer = this.getCustomer();
    const service = this.getService();
    const note = this.getNotes();
    const endTime = this.getEndTime(service);
    const confirmations = this.getConfirmations();
    const attributes = this.getAttributes(false);
    const serviceProps = this.getServiceProps(service);

    const booking = {
      id: this.props.id,
      startTime: this.props.startTime,
      endTime,
      resourceId: this.props.resourceId,
      type: this.props.attributes.type,
      note,
      ...vehicle,
      ...company,
      ...customer,
      ...serviceProps,
      attributes
    };

    const isBooking = this.props.attributes.type === 'SimpleBooking';

    this.props.onUpdateBooking(booking)
      .then(() => {
        if (isBooking && (confirmations.sms || confirmations.email)) {
          this.props.onSendConfirms(booking.id, confirmations);
        }
        this.props.onClearAndClose();
      })
      .catch(() => this.setState({ isSaving: false }));
  }

  addBooking() {
    this.setState({ isSaving: true });

    const vehicle = this.getVehicle();
    const company = this.getCompany();
    const customer = this.getCustomer();
    const service = this.getService();
    const note = this.getNotes();
    const endTime = this.getEndTime(service);
    const confirmations = this.getConfirmations();
    const attributes = this.getAttributes(true);
    const serviceProps = this.getServiceProps(service);

    const booking = {
      resourceId: this.props.resourceId,
      startTime: this.props.startTime,
      endTime,
      type: this.props.attributes.type,
      sendSmsConfirmation: confirmations.sms,
      sendEmailConfirmation: confirmations.email,
      dropIn: this.props.attributes.dropIn,
      askedForPerson: this.props.attributes.askedForPerson,
      note,
      ...vehicle,
      ...company,
      ...customer,
      ...serviceProps,
      attributes
    };

    this.props.onAddBooking(booking)
      .catch(() => this.setState({ isSaving: false }));
  }

  handleSave = (e) => {
    e.preventDefault();

    if (this.props.id === 'DRAGGER') {
      this.addBooking();
    } else {
      this.updateBooking();
    }
  };

  handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClose();
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      if (this.state.showOptions) {
        this.setState({ showOptions: false });
      } else {
        this.handleClose(ev);
      }
    }
  };

  handleServiceSelect = (service) => {
    const { afterTime, serviceDuration } = service;
    const { id, startTime, endTime } = this.props;
    const newEndTime = serviceDuration ? moment(startTime).add(serviceDuration + (afterTime || 0), 'm') : endTime;

    const newTime = {
      startTime,
      endTime: newEndTime
    };

    this.props.onServiceSelect(id, service, newTime);
    this.setState({ searchService: false });
  };

  updateTimeFromServices = (services) => {
    const { id, startTime, endTime, resourceServices, resourceId } = this.props;
    const hasServices = services && services.length > 0;
    const resSrvs = resourceServices.get(resourceId);

    const fAfterTime = (aSrv) => {
      const srv = resSrvs ? resSrvs.find((srv) => {
        return srv.id === aSrv.id;
      }) : null;
      return srv ? srv.afterTime : 0;
    };

    const sum = (prev, next) => prev + next;
    const name = hasServices ? services.map(s => s.name).join(', ') : 0;
    const price = hasServices ? services.map(s => s.price).reduce(sum) : 0;
    const afterTime = hasServices ? Math.max(...services.map(s => fAfterTime(s))) : 0;
    const serviceDuration = hasServices ? services.map(s => s.duration || s.serviceDuration).reduce(sum) : 0;
    const newEndTime = serviceDuration ? moment(startTime).add(serviceDuration + (afterTime || 0), 'm') : endTime;

    const service = hasServices ? {
      name,
      serviceDuration,
      afterTime,
      price
    } : null;

    const newTime = {
      startTime,
      endTime: newEndTime
    };

    this.props.onServiceSelect(id, service, newTime);
  };

  handleServiceAdd = (service) => {
    const { services } = this.props;
    const newServices = services ? [...services.values()] : [];
    newServices.push(service);

    this.props.onServiceAdd(service);
    this.updateTimeFromServices(newServices);
  };

  handleServiceRemove = (service) => {
    const { services } = this.props;
    const newServices = [...services.delete(service.id).values()];

    this.props.onServiceRemove(service);
    this.updateTimeFromServices(newServices);
  };

  handleCustomerSelect = ({ customer, company, vehicle }) => {
    if (customer) {
      this.props.onCustomerSelect(customer);
    }
    if (company) {
      this.props.onCompanySelect(company);
    }
    if (vehicle) {
      this.props.onVehicleSelect(vehicle);
    }
    this.setState({ searchCustomer: false });
  };

  handleClearService = () => {
    const { startTime, endTime } = this.props;
    const serviceDuration = endTime.diff(startTime, 'minutes');

    this.handleServiceSelect({
      afterTime: 0,
      serviceDuration
    });
  };

  handleClearVehicle = () => {
    this.props.onVehicleSelect(null);
  };

  handleClearCompany = () => {
    this.props.onCompanySelect(null);
  };

  handleClearCustomer = () => {
    this.props.onCustomerSelect(null);
  };

  handleChangePlace = (place) => {
    this.props.onPlaceChange(place);
  };

  handleVehicleSearchStart = () => {
    this.setState({
      searchCustomer: true,
      searchCustomerValue: '',
      searchCustomerScope: ['Vehicle'],
      bodyHeight: this.getBodyHeight()
    });
  };

  handleCompanySearchStart = () => {
    this.setState({
      searchCustomer: true,
      searchCustomerValue: '',
      searchCustomerScope: ['Company'],
      bodyHeight: this.getBodyHeight()
    });
  };

  handleCustomerSearchStart = (value) => {
    const { company, customer, vehicle, enableCompanyBooking, enableVehicleBooking } = this.props;

    const searchCustomerScope = [];
    if (!customer) {
      searchCustomerScope.push('Customer');
    }
    if (enableCompanyBooking && !company) {
      searchCustomerScope.push('Company');
    }
    if (enableVehicleBooking && !vehicle) {
      searchCustomerScope.push('Vehicle');
    }

    this.setState({
      searchCustomer: true,
      searchCustomerValue: value,
      searchCustomerScope,
      bodyHeight: this.getBodyHeight()
    });
  };

  handleServiceSearchStart = () => {
    this.setState({ searchService: true, bodyHeight: this.getBodyHeight() });
  };

  handleEditNotes = () => {
    this.setState({ editNotes: true });
  };
}

const mapStateToProps = (state) => {
  const { bkf, mainViewState, gridViewState, locationFeatures, resourceServices } = state;

  return {
    resourceServices,
    service: bkf.get('service'),
    services: bkf.get('services'),
    vehicle: bkf.get('vehicle'),
    company: bkf.get('company'),
    customer: bkf.get('customer'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    resourceId: bkf.get('resourceId'),
    features: getFeatures(state),
    attributes: bkf.get('attributes'),
    customerForm: getFormValues('bkf-customer')(state),
    serviceForm: getFormValues('bkf-service')(state),
    priceForm: getFormValues('bkf-price')(state),
    timeForm: getFormValues('bkf-time')(state),
    addressForm: getFormValues('bkf-address')(state),
    notesForm: getFormValues('bkf-notes')(state),
    vehicleForm: getFormValues('bkf-vehicle')(state),
    companyForm: getFormValues('bkf-company')(state),
    confirmationForm: getFormValues('bkf-confirmation')(state),
    deviceType: mainViewState.get('deviceType'),
    deviceOs: mainViewState.get('deviceOs'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    gridClientHeight: gridViewState.get('gridClientHeight'),
    enableDashlFeatures: enableDashlFeatures(state),
    enableCompanyBooking: locationFeatures.get('EnableCompanyBooking'),
    enableVehicleBooking: locationFeatures.get('EnableVehicleBooking'),
    stylistType: getStylistType(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTypeChange: (id, type) => {
      dispatch(setBKFAttributes({ type }));
      dispatch(setBKFService(null));
      dispatch(setBKFCustomer(null));
      if (id === 'DRAGGER') {
        dispatch(bookingTypeChanged({ bookingId: id, type }));
      }
    },
    onAddBooking: (booking) => {
      return dispatch(addBooking(booking))
        .then(() => dispatch(removeTempBooking('DRAGGER')))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    onUpdateBooking: (booking) => {
      return dispatch(updateBooking(booking));
    },
    onDeleteBooking: (bkId) => {
      dispatch(deleteBooking(bkId));
    },
    onClearAndClose: () => {
      dispatch(clearAndCloseBKF());
    },
    onSendConfirms: (bookingId, options) => {
      dispatch(sendBookingConfirmation({ bookingId, options }));
    },
    onServiceSelect: (id, service, newTime) => {
      dispatch(setBKFService(service));
      dispatch(setBKFBookingTime({ id, ...newTime }));
      dispatch(bookingMoved({ id, ...newTime }));
    },
    onServiceAdd: (service) => {
      dispatch(addBKFService(service));
    },
    onServiceRemove: (service) => {
      dispatch(removeBKFService(service));
    },
    onVehicleSelect: (vehicle) => {
      dispatch(setBKFVehicle(vehicle));
    },
    onCompanySelect: (company) => {
      dispatch(setBKFCompany(company));
    },
    onCustomerSelect: (customer) => {
      dispatch(setBKFCustomer(customer));
    },
    onPlaceChange: (place) => {
      dispatch(setBKFAttributes({ place }));
    }
  };
};

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingForm));
