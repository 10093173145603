import { Map } from 'immutable';
import { RESET_BKF } from './bkf-actions';
import {
  SET_CF_STATE
} from './cf-actions';
import { CLEAR_LOCATION_STATE } from './account-actions';

function clearState(state, action) {
  return state.clear().withMutations((map) => {
    map.set('state', 'NOT_LOADED');
    map.set('formVisible', false);
  });
}

function setState(state, action) {
  return state.clear().withMutations((map) => {
    map.set('state', 'LOADED');
    map.set('formVisible', action.formVisible);
    if (action.customer) {
      map.set('customer', action.customer);
    }
  });
}

export function cf(state = Map({
  state: 'NOT_LOADED',
  formVisible: false,
  customer: null
}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
    case RESET_BKF:
      return clearState(state, action);
    case SET_CF_STATE:
      return setState(state, action);
    default:
      return state;
  }
}
